import { useState } from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import {
  FILTER_DATE_TYPES,
  FILTER_END_DATE_KEY,
  FILTER_START_DATE_KEY,
  ISSUE_TYPES_OPTIONS,
} from '../../FilterDateRange/contants';
import { formatToUnix } from '../../FilterDateRange/utils';

const useDateRange = ({ value, parameters, updateCallback } = {}) => {
  const [localDateTypes, setLocalDateTypes] = useState([]);
  const [localDateTypesOptions, setLocalDateTypesOptions] = useState([]);

  useEffect(() => {
    if (!value?.types) {
      setLocalDateTypesOptions([]);
      setLocalDateTypes([]);
      return;
    }

    const _types = value.types.split(',');
    const _localDateTypesOptions = ISSUE_TYPES_OPTIONS.filter((type) =>
      _types.includes(type.value)
    );
    setLocalDateTypesOptions(_localDateTypesOptions);
    setLocalDateTypes(_types);
  }, [value?.types]);

  const localStartDate = useMemo(() => {
    return value?.from ? dayjs.unix(value.from).toDate() : null;
  }, [value?.from]);

  const localEndDate = useMemo(() => {
    return value?.to ? dayjs.unix(value.to).toDate() : null;
  }, [value.to]);

  const handleStartDateChange = (date) => {
    const mutatedPersistedFiltersParams = { ...parameters };
    const startOfDayDate = dayjs(date).startOf('day');
    const startOfDayLocalEndDate = dayjs(localEndDate).startOf('day');
    const isSameOrAfterEndDate =
      startOfDayDate.isSame(startOfDayLocalEndDate) ||
      startOfDayDate.isAfter(startOfDayLocalEndDate);

    const { startDateUnix, endDateUnix } = formatToUnix(
      date,
      startOfDayDate.add(1, 'day').toDate()
    );

    mutatedPersistedFiltersParams[FILTER_START_DATE_KEY] =
      startDateUnix.toString();

    if (isSameOrAfterEndDate) {
      mutatedPersistedFiltersParams[FILTER_END_DATE_KEY] =
        endDateUnix.toString();
    }

    updateCallback(mutatedPersistedFiltersParams);
  };

  const handleEndDateChange = (date) => {
    const mutatedPersistedFiltersParams = { ...parameters };
    const startOfDayDate = dayjs(date).startOf('day');
    const startOfDayLocalStartDate = dayjs(localStartDate).startOf('day');

    const isSameOrBeforeStartDate =
      startOfDayDate.isSame(startOfDayLocalStartDate) ||
      startOfDayDate.isBefore(startOfDayLocalStartDate);

    const { startDateUnix, endDateUnix } = formatToUnix(startOfDayDate, date);

    mutatedPersistedFiltersParams[FILTER_END_DATE_KEY] = endDateUnix.toString();

    if (isSameOrBeforeStartDate) {
      mutatedPersistedFiltersParams[FILTER_START_DATE_KEY] =
        startDateUnix.toString();
    }

    updateCallback(mutatedPersistedFiltersParams);
  };

  const handleTypesChange = (value) => {
    const mutatedPersistedFiltersParams = { ...parameters };

    const newTypes = localDateTypes.includes(value)
      ? localDateTypes.filter((item) => item !== value)
      : [...localDateTypes, value];

    if (newTypes.length) {
      mutatedPersistedFiltersParams[FILTER_DATE_TYPES] = newTypes.join(',');
    } else {
      delete mutatedPersistedFiltersParams[FILTER_DATE_TYPES];
    }

    updateCallback(mutatedPersistedFiltersParams);
  };

  const handleReset = () => {
    const mutatedPersistedFiltersParams = { ...parameters };
    if (FILTER_START_DATE_KEY in mutatedPersistedFiltersParams)
      delete mutatedPersistedFiltersParams[FILTER_START_DATE_KEY];
    if (FILTER_END_DATE_KEY in mutatedPersistedFiltersParams)
      delete mutatedPersistedFiltersParams[FILTER_END_DATE_KEY];

    if (FILTER_DATE_TYPES in mutatedPersistedFiltersParams) {
      delete mutatedPersistedFiltersParams[FILTER_DATE_TYPES];
    }

    updateCallback(mutatedPersistedFiltersParams);
  };

  return {
    handleStartDateChange,
    handleEndDateChange,
    handleTypesChange,
    handleReset,
    localStartDate,
    localEndDate,
    localDateTypes,
    localDateTypesOptions,
  };
};

export default useDateRange;
