export const formatWithNoEmptyValues = (data) => {
  const clonedData = { ...data };

  for (const [key, value] of Object.entries(clonedData)) {
    if (value === null || value === undefined || value === '') {
      delete clonedData[key];
    }
  }

  return clonedData;
};
