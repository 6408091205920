import dayjs from 'dayjs';

export const formatDateForQuery = (date = new Date()) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }`;
};

export const formatStartDateFromQueryParam = (date) => {
  return dayjs(date).startOf('day').toDate();
};

export const droppableElementStyles = (style, snapshot) => {
  if (!snapshot.isDropAnimating) return style;
  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};
