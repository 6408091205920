import vest, { enforce, test } from 'vest';
import { INPUT_MAX_LENGTH } from '../../constants';

export const formValidation = vest.create((data = {}) => {
  const { workspaceName } = data;

  test('workspaceName', `TMP_workspace_empty`, () => {
    enforce(workspaceName).isNotEmpty();
  });

  test('workspaceName', `TMP_workspace_max_length`, () => {
    enforce(workspaceName).shorterThanOrEquals(INPUT_MAX_LENGTH);
  });
});
