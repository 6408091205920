import {
  blackThatWeNeedToName,
  gradientBlue,
  red,
  greenButtonColor,
  pink,
  white,
} from '../../config/colors';

export const COLOR = {
  default: 'default',
  gray: 'gray',
  red: 'red',
  green: 'green',
  pink: 'pink',
  white: 'white',
};

export const hoverColorMap = {
  default: 'rgb(88, 72, 251, 0.7)',
};

export const transformColor = (color) => {
  switch (color) {
    case COLOR.gray:
      return blackThatWeNeedToName;
    case COLOR.red:
      return red;
    case COLOR.green:
      return greenButtonColor;
    case COLOR.pink:
      return pink;
    case COLOR.white:
      return white;
    default:
      return gradientBlue;
  }
};

export const transformHoverColor = (color) => {
  switch (color) {
    // case COLOR.gray:
    //   return blackThatWeNeedToName;
    // case COLOR.red:
    //   return red;
    // case COLOR.green:
    //   return greenButtonColor;
    // case COLOR.pink:
    //   return pink;
    default:
      return hoverColorMap['default'];
  }
};
