import { fromJS } from 'immutable';
import { actionStrings } from './actions';
import { actionStrings as authActionStrings } from '../auth/actions';
import { entities } from '../../config/config';

const initState = fromJS({
  qr_code: '',
  log: [],
  error: '',
  QrScanlogLoader: false,
});

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.LOAD_QRSCAN_LOG:
      return state
        .set(entities.ISSUE_QRSCAN_LOG.reduxProp, [])
        .set('qr_code', action.payload.code);
    case actionStrings.LOAD_QRSCAN_LOG_SUCCESS:
      return state.set(
        entities.ISSUE_QRSCAN_LOG.reduxProp,
        action.payload.data
      );
    case actionStrings.LOAD_QRSCAN_LOG_ERROR:
      return state.set(entities.ERROR.reduxProp, action.payload);
    case authActionStrings.LOGOUT:
      return initState;
    case actionStrings.QR_SCANLOG_LOADER:
      return state.set('QrScanlogLoader', !state.get('QrScanlogLoader'));
    default:
      return state;
  }
};
