import styled, { css } from 'styled-components/macro';
import { borderGray, darkerGray, violet, red } from '../../config/colors';

export const QuickFilterBasicStyles = css`
  align-items: center;
  color: ${darkerGray};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${borderGray};
  height: 36px;
`;
export const QuickFilterWrapperStyle = css`
  display: flex;

  .filter-menu-button {
    display: flex;
    width: 100%;

    .icon {
      margin-left: auto;
    }

    .purple {
      color: ${violet};
    }
  }

  .menu-wrapper {
    width: 100%;
    position: relative;
    text-align: left;
    cursor: pointer;
  }

  .menu {
    position: absolute;
    top: 35px;
    left: -10px;

    &.xs {
      width: 100px;
      left: 15px;
    }

    &.sm {
      width: 150px;
      left: 10px;
    }
  }

  .menuitem {
    text-transform: none;
  }

  .selected {
    color: ${violet};
  }

  .menuitem-no-separator {
    border-bottom: none;
  }

  .menuitem-archived {
    color: ${red};
  }
`;
export const QuickFilterWrapper = styled.div`
  ${({ hasCustomButton }) => (hasCustomButton ? '' : QuickFilterBasicStyles)}
  ${QuickFilterWrapperStyle}
`;
