import styled from 'styled-components/macro';

const MenuItems = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
  max-height: ${({ height }) => (height ? height : '280px')};
  overflow-y: auto;
`;

export default MenuItems;
