import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import GalleryLite from './gallery';

import {
  getImages,
  getDocuments,
  getAttachments,
  getSelectedImage,
  canNavigateNext,
  canNavigatePrev,
  galleryHasChanges,
} from '../../redux/gallery/selectors';
import dict from '../../config/multilang';
import {
  addAttachment,
  selectAttachment,
  removeAttachment,
  changeDescription,
  undoChanges,
  confirmChanges,
} from '../../redux/gallery/actions';
import { actions as appActions } from '../../redux/app/actions';
import {
  onUpload,
  onHandlePopupConfirm,
  onHandleDescriptionChange,
  onPopupCancel,
  onNavigateNext,
  onNavigatePrev,
  onHandleRemoveItem,
  onSortEnd,
  onDeadlineChange,
  onPriorityChange,
} from '../../redux/gallery/action-creators';
import './gallery.scss';
import { attachPermissionsToDocument } from './helper';
import { getMediaMaxSize } from '../../redux/config/selectors';

const GalleryLiteWrapped = (props) => {
  const [showListView, setShowListView] = useState(false);

  const imagesNotLoading = () => !props.galleryLoading;

  const hasAttachment = () => {
    return imagesNotLoading() && props.allAttachments.size > 0;
  };

  const hasImages = () => {
    return imagesNotLoading() && props.images.size > 0;
  };

  const hasDocuments = () => {
    return imagesNotLoading() && props.documents.size > 0;
  };

  useEffect(() => {
    if (props.isGalleryOpen && !hasImages() && !hasDocuments()) {
      props.toggleGalleryPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.images.size, props.documents.size]);

  const countAttachments = () => {
    const total = props.images.size + props.documents.size;

    return total;
  };

  const handleGalleryChanged = () => {
    if (props.onGalleryChanged) {
      props.onGalleryChanged();
    }
  };

  const handleItemSelect = (item) => {
    setShowListView(false);
    props.selectCurrentImage(item, props.id);
    props.toggleGalleryPopup();
  };

  const handleListItemSelect = (item) => {
    props.selectCurrentImage(item, props.id);
    setShowListView(false);
  };

  const handleItemRemoved = () => {
    props.onHandleRemoveItem();
    handleGalleryChanged();
  };

  const handleListItemRemoved = (index) => {
    props.removeAttachment(index);
    handleGalleryChanged();
  };

  const toggleListView = () => {
    setShowListView(!showListView);
  };

  const handleShowListView = (item) => {
    setShowListView(true);
    props.selectCurrentImage(item, props.id);
    props.toggleGalleryPopup();
  };

  const onHandleItemDescription = (event) => {
    props.onHandleDescriptionChange(event);
    handleGalleryChanged();
  };

  const onHandleListItemDescription = (event, itemId) => {
    const description = event.target.value;
    props.changeDescription(itemId, description);
    handleGalleryChanged();
  };

  const handleSaveDescription = () => {
    props.toggleGalleryPopup();
    props.onSaveDescriptionClick();
  };

  const handlePostGalleryComment = (attachmentId) => (comment) => {
    props.onPostGalleryComment(comment, attachmentId);
  };

  const sortErrorLabel = props.intl.formatMessage({
    id: 'notification_error_general',
    defaultMessage: dict.notification_error_general,
  });

  const onPriorityChange = (value) => {
    props.onPriorityChange(value.value.id);
    handleGalleryChanged();
  };

  const onDeadlineChange = (value) => {
    handleGalleryChanged();

    if (value) {
      return props.onDeadlineChange(value.getTime() / 1000);
    }

    return props.onDeadlineChange(null);
  };

  const handleDragAndDropSort = (oldIndex, newIndex) => {
    props.onSortEnd({
      oldIndex,
      newIndex,
      issueId: props.id,
      sortErrorLabel,
      onlyLocalSort: props.isNewItem,
      refetchIssue: props.refetchIssue,
    });
  };

  const selectedDocument = props.getSelectedDocument();

  return (
    <GalleryLite
      // props
      user={props.user}
      enableOrdering={props.enableOrdering}
      onUpload={props.onUpload}
      permissions={props.permissions}
      isSubtaskPopup={props.isSubtaskPopup}
      images={props.images}
      id={props.id}
      documents={props.documents}
      intl={props.intl}
      isGalleryOpen={props.isGalleryOpen}
      toggleGalleryPopup={props.toggleGalleryPopup}
      canNavigatePrev={props.canNavigatePrev()}
      canNavigateNext={props.canNavigateNext()}
      onNavigatePrev={props.onNavigatePrev}
      onNavigateNext={props.onNavigateNext}
      onPopupCancel={props.onPopupCancel}
      galleryChanged={() => {
        handleGalleryChanged();
        props.galleryChanged();
      }}
      showSaveOnDescription={props.showSaveOnDescription}
      issueAttachmentsComments={props.issueAttachmentsComments}
      isNewItem={props.isNewItem}
      props={props}
      // functions/variables here:
      hasImages={hasImages()}
      imagesNotLoading={imagesNotLoading()}
      handleItemSelect={handleItemSelect}
      handleShowListView={handleShowListView}
      hasAttachment={hasAttachment()}
      selectedDocument={selectedDocument}
      showListView={showListView}
      onHandleItemDescription={onHandleItemDescription}
      handleItemRemoved={handleItemRemoved}
      toggleListView={toggleListView}
      countAttachments={countAttachments()}
      handleSaveDescription={handleSaveDescription}
      handlePostGalleryComment={handlePostGalleryComment}
      handleListItemSelect={handleListItemSelect}
      handleListItemRemoved={handleListItemRemoved}
      handleDragAndDropSort={handleDragAndDropSort}
      onHandleListItemDescription={onHandleListItemDescription}
      onPriorityChange={onPriorityChange}
      onDeadlineChange={onDeadlineChange}
      isUsingAttachmentPriority={props.isUsingAttachmentPriority}
      isUsingAttachmentDueDate={props.isUsingAttachmentDueDate}
      customPdfMaxSize={props.customPdfMaxSize}
      triggerUploadRefElement={props.triggerUploadRefElement}
      handleGalleryChanged={handleGalleryChanged}
      rawAttachments={props.allAttachments}
      showOnlyPreview={props.showOnlyPreview}
    />
  );
};

GalleryLiteWrapped.propTypes = {
  attachments: PropTypes.object,
  permissions: PropTypes.object,
  onGalleryChanged: PropTypes.func,
  user: PropTypes.shape({
    profileImageUrl: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  addAttachment: PropTypes.func.isRequired,
  selectCurrentImage: PropTypes.func.isRequired,
  toggleGalleryPopup: PropTypes.func.isRequired,
  id: PropTypes.string,
  showSaveOnDescription: PropTypes.bool,
  onSaveDescriptionClick: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const attachPermissions = attachPermissionsToDocument(ownProps.permissions);
  const images = getImages(state, ownProps.id).map(attachPermissions);
  const attachments = getAttachments(state, ownProps.id).map(attachPermissions);
  const isGalleryDirty = galleryHasChanges(state, ownProps.id);
  const customPdfMaxSize = getMediaMaxSize(state);

  const issueAttachments =
    ownProps.issue &&
    ownProps.issue.attachments &&
    ownProps.issue.attachments.length
      ? ownProps.issue.attachments.length
      : 0;

  const galleryLoading =
    !isGalleryDirty &&
    (!attachments || (attachments && attachments.size < issueAttachments));

  return {
    galleryLoading,
    isGalleryOpen: state.app.toJS().galleryPopupPresented,
    images,
    allAttachments: attachments,
    documents: getDocuments(state, ownProps.id),
    getSelectedDocument: () =>
      attachPermissions(getSelectedImage(state, ownProps.id)),
    galleryChanged: () => galleryHasChanges(state, ownProps.id),
    canNavigateNext: () => canNavigateNext(state, ownProps.id),
    canNavigatePrev: () => canNavigatePrev(state, ownProps.id),
    customPdfMaxSize,
  };
};

const mapDispatchToProps = (dispatch, { id, onGalleryChanged }) => ({
  addAttachment: (attachment, hostId) =>
    dispatch(addAttachment(attachment, hostId)),
  selectCurrentImage: (imageInfo) => dispatch(selectAttachment(imageInfo, id)),
  toggleGalleryPopup: () => dispatch(appActions.toggleGalleryPopup()),
  removeAttachment: (index) => dispatch(removeAttachment(index, id)),
  changeDescription: (index, newDescription) =>
    dispatch(changeDescription(index, newDescription, id)),
  undoChanges: () => dispatch(undoChanges(id)),
  confirmChanges: () => dispatch(confirmChanges(id)),
  onUpload: (props) => dispatch(onUpload(id)(props)),
  onHandlePopupConfirm: () => dispatch(onHandlePopupConfirm(onGalleryChanged)),
  onHandleRemoveItem: () => dispatch(onHandleRemoveItem(id)),
  onHandleDescriptionChange: (event) =>
    dispatch(onHandleDescriptionChange(id)(event)),
  onPopupCancel: () => dispatch(onPopupCancel()),
  onNavigateNext: () => dispatch(onNavigateNext(id)),
  onNavigatePrev: () => dispatch(onNavigatePrev(id)),
  onSortEnd: (args) => dispatch(onSortEnd(args, id)),
  onPriorityChange: (priorityId) => dispatch(onPriorityChange(priorityId, id)),
  onDeadlineChange: (date) => dispatch(onDeadlineChange(date, id)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(GalleryLiteWrapped)
);
