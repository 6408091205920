import vest, { test, enforce } from 'vest';

const formValidation = vest.create((data = {}) => {
  const { note } = data;

  test('note', 'Note is mandatory', () => {
    enforce(note.trim()).isNotEmpty();
  });
});

const limitNumberOfCharacters = (str, limit) => {
  return str.length > limit
    ? str.substring(0, limit - 3) + '...'
    : str.substring(0, limit);
};

export { formValidation, limitNumberOfCharacters };
