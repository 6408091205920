import React from 'react';
import { useDispatch } from 'react-redux';

import Tooltip from '../../../../../../components_new/Tooltip';
import {
  WowIcon,
  WowTooltipWrapper,
} from '../../../../../../components_new/WowBasicElements';

import { ActionButtons } from '../styles';

import { gray } from '../../../../../../config/colors';
import { setActionItemInfo } from '../../../slice';

const ItemActions = ({ item }) => {
  const { assetTooltip, assetGroupTooltip, scheduleId } = item;

  const dispatch = useDispatch();

  return (
    <ActionButtons>
      {assetGroupTooltip || assetTooltip ? (
        <Tooltip
          tooltipContent={
            <WowTooltipWrapper width="150">
              {assetGroupTooltip || assetTooltip}
            </WowTooltipWrapper>
          }
        >
          <WowIcon
            className="icon-assets"
            color={gray}
            spaceRight="0"
            size="14"
            display="flex"
          />
        </Tooltip>
      ) : null}

      <WowIcon
        className="icon-scheduling"
        color={gray}
        spaceRight="0"
        size="14"
        display="flex"
        onClick={(e) => {
          e.stopPropagation();
          const { x, y } = e.target.getBoundingClientRect();
          dispatch(
            setActionItemInfo({
              data: {
                scheduleId,
              },
              position: {
                x,
                y,
              },
            })
          );
        }}
        clickable
      />
    </ActionButtons>
  );
};

export default ItemActions;
