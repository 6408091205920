export const MIN_CHARS_TO_INVOKE_SEARCH = 2;
export const HEADER_HEIGHT = 55;
export const SIDEBAR_WIDTH = 250;
export const MAX_USERS_SHOWN = 4;
export const VISIBILITY_ICONS = {
  visible: 'icon-visible',
  invisible: 'icon-invisible',
};
export const VISIBILITY_TYPES = {
  organisation: {
    id: '1',
    name: 'web_mob.organisation',
    key: 'organisation',
    type: 'ORGANISATION',
  },
  workspace: {
    id: '2',
    key: 'workspace',
    name: 'web_mob.workspace',
    type: 'WORKSPACE',
  },
  user: {
    id: '3',
    key: 'user',
    name: 'web_mob.user',
    type: 'USER',
  },
  public: {
    id: '4',
    key: 'public',
    name: 'web_mob.public',
    type: 'PUBLIC',
  },
};

export const RTK_HOOK_CONFIG = {
  refetchOnMountOrArgChange: true,
  refetchOnFocus: false,
};

export const BASE_URL = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;

export const INFINITE_SCROLL_INITIAL_STATE = {
  query: {
    page: 1,
    paginate_by: 10,
    model_ids: [],
  },
  itemsList: [],
  hasMore: true,
  lastPage: null,
};

export const DEFAULT_PROFILE_IMAGE =
  'https://cdn.wowflow.com/shared/media/default-profile-photo.jpg';
export const MODAL_OVERLAY_STYLE_OVERRIDE = {
  overflow: 'auto',
};

export const MODAL_CONTENT_STYLE_OVERRIDE = {
  top: '70px',
  transform: 'translate(-50%, 0)',
};

export const DENSITY = {
  comfortable: 'comfortable',
  compact: 'compact',
  table: 'table',
};

export const DENSITY_OPTIONS = [
  {
    id: 1,
    value: DENSITY.comfortable,
    key: 'detailed_view',
  },
  {
    id: 2,
    value: DENSITY.compact,
    key: 'compact_view',
  },
  {
    id: 3,
    value: DENSITY.table,
    key: 'list_view',
  },
];

// megabyte
export const MB = 1e6;

export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HTTP_NOT_FOUND = 404;
export const HTTP_INTERNAL_SERVER_ERROR = 500;
export const HTTP_UNPROCESSABLE = 422;

export const STRINGIFY_URL_DEFAULT_CONFIG = {
  skipEmptyString: true,
  skipNull: true,
  arrayFormat: 'comma',
};

export const NESTED_INFINITY_HOOK_DEFAULT_CONFIG = {
  skip: true,
  preventReset: true,
};
