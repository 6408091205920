import { useIntl } from 'react-intl';

import { MONTH_KEYS } from '../../../../../../../config/config';
import { darkGray } from '../../../../../../../config/colors';

import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../../../components_new/WowBasicElements';
import Space from '../../../../../../../components_new/Space';
import { CalendarHeaderDay } from '../../../../styles';
import { OuterWrapper, InnerWrapper } from './styles';

const DateWrapper = ({ currentTime = new Date() }) => {
  const intl = useIntl();

  return (
    <OuterWrapper>
      <InnerWrapper>
        <WowDisplayFlex justify="space-around">
          <WowDisplayFlex direction="column">
            <CalendarHeaderDay>{currentTime.getDate()}</CalendarHeaderDay>
            <Space height="5" />
            <WowColorizeText
              size="12"
              color={darkGray}
              uppercase
              fWeight="bold"
            >
              {intl.formatMessage({
                id: MONTH_KEYS?.[currentTime.getMonth() + 1],
              })}
            </WowColorizeText>
          </WowDisplayFlex>
        </WowDisplayFlex>
      </InnerWrapper>
    </OuterWrapper>
  );
};

export default DateWrapper;
