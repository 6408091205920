import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../redux/utils';
import qs from 'query-string';
import { BASE_URL } from '../../../config/constants';

// TODO: Use from constants instead of defining here

//REFACTOR, split to smaller pieces, move some stuff to utils/constants
export const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      /* headers.set('Content-Type', `application/json`); */
      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    'Workspace',
    'WorkspaceClean',
    'QrCodeScanlog',
    'Users',
    'WorkspacesShort',
  ],
  endpoints: (builder) => ({
    getWorkspaces: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          {
            url: 'workspace/',
            query: { prune_offline: 1, include_collabs: 0, ...params },
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Workspace', id })),
              { type: 'Workspace', id: 'LIST' },
            ]
          : [{ type: 'Workspace', id: 'LIST' }],
    }),

    getWorkspacesClean: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          {
            url: 'workspace_clean',
            query: params,
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'WorkspaceClean', id })),
              { type: 'WorkspaceClean', id: 'LIST' },
            ]
          : [{ type: 'WorkspaceClean', id: 'LIST' }],
    }),

    getWorkspacesShort: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'workspace/short', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' }
        );

        return url;
      },
      providesTags: [{ type: 'WorkspacesShort', id: 'LIST' }],
    }),

    getWorkspace: builder.query({
      query: (id) => {
        if (!id) return;

        const url = `workspace/${id}`;

        return url;
      },

      // TODO: This has not been tested yet. (Groups and types was)
      providesTags: (result) =>
        result
          ? [{ type: 'Workspace', id: result.id }]
          : [{ type: 'Workspace', id: 'LIST' }],
    }),

    createWorkspace: builder.mutation({
      query(body) {
        return {
          url: `workspace`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        const parentId = result?.workspace?.parent_id;

        return [
          { type: 'Workspace', parentId },
          { type: 'Workspace', id: 'LIST' },
        ];
      },
    }),

    updateWorkspace: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `workspace/${id}`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Workspace', id },
        { type: 'WorkspaceClean', id },
      ],
    }),

    updateWorkspaceAttachments: builder.mutation({
      query(data) {
        const { id, formData } = data;

        return {
          url: `workspace/${id}/upload`,
          method: 'POST',
          body: formData,
        };
      },

      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [{ type: 'Workspace', id }],
    }),

    deleteWorkspace: builder.mutation({
      query({ id }) {
        return {
          url: `workspace/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { id, shouldInvalidate }) => {
        return shouldInvalidate
          ? [
              { type: 'WorkspaceClean', id },
              { type: 'Workspace', id: 'LIST' },
            ]
          : [];
      },
    }),

    makeFavourite: builder.mutation({
      query(id) {
        return {
          url: `workspace/${id}/favorite`,
          method: 'POST',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Workspace', id: id },
      ],
    }),

    unmakeFavourite: builder.mutation({
      query(id) {
        return {
          url: `workspace/${id}/favorite`,
          method: 'DELETE',
        };
      },
      // invalidatesTags: [{ type: 'Workspace', id: id }],
      invalidatesTags: (result, error, { id }) => [{ type: 'Workspace', id }],
    }),

    updateDynamicForm: builder.mutation({
      query({ body, id }) {
        return {
          url: `workspace/${id}/form`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Workspace', id: id }];
      },
    }),
    getQrCodeScanlog: builder.query({
      query: (payload) => {
        const { id, ...restPayload } = payload;
        const url = qs.stringifyUrl(
          { url: `qr_code/scan_log/${id}`, query: restPayload },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'QrCodeScanlog',
                id,
              })),
              { type: 'QrCodeScanlog', id: 'LIST' },
            ]
          : [{ type: 'QrCodeScanlog', id: 'LIST' }],
    }),
    getQrCodeTimelog: builder.query({
      query: (payload) => {
        const { id, ...restPayload } = payload;
        const url = qs.stringifyUrl(
          { url: `/workspace/${id}/presence/logs`, query: restPayload },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
    }),

    // used to get main users from organization
    getMainOrgUsers: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'users/main_org', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),

    syncMainOrgUsers: builder.mutation({
      query(payload) {
        const { workspaceId, collabId, ...rest } = payload;
        return {
          url: `collaboration/${collabId}/workspaces/${workspaceId}/sync_users`,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (result, error, { workspaceId }) => [
        { type: 'Workspace', id: workspaceId },
      ],
    }),

    removeCollaboratingOrg: builder.mutation({
      query(payload) {
        const { workspaceId, collabId } = payload;
        return {
          url: `collaboration/${collabId}/workspaces/remove_access`,
          method: 'PUT',
          body: {
            workspace_ids: [workspaceId],
          },
        };
      },
      invalidatesTags: (result, error, { workspaceId }) => [
        { type: 'Workspace', id: workspaceId },
      ],
    }),

    addUsersToWorkspace: builder.mutation({
      query(payload) {
        const { id, ...body } = payload;
        return {
          url: `workspace/${id}/users`,
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const {
  // users from main org
  useGetMainOrgUsersQuery,
  useSyncMainOrgUsersMutation,
  useRemoveCollaboratingOrgMutation,

  useGetWorkspacesQuery,
  useGetWorkspacesCleanQuery,
  useLazyGetWorkspacesQuery,
  useLazyGetWorkspacesCleanQuery,
  useGetWorkspacesShortQuery,
  useLazyGetWorkspacesShortQuery,
  useGetWorkspaceQuery,
  useCreateWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useUpdateWorkspaceAttachmentsMutation,
  useDeleteWorkspaceMutation,
  useMakeFavouriteMutation,
  useUnmakeFavouriteMutation,
  useUpdateDynamicFormMutation,
  useGetQrCodeScanlogQuery,
  useGetQrCodeTimelogQuery,
  useAddUsersToWorkspaceMutation,
} = workspaceApi;
