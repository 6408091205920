import React from 'react';
import range from 'lodash/range';

import ItemActions from './ItemActions';
import Cell from './Cell';

import { Row, TitleColumn, CellWrapper } from '../styles';
import Tooltip from '../../../../../../components_new/Tooltip';
import { WowTooltipWrapper } from '../../../../../../components_new/WowBasicElements';

const YearlyTimelineRow = ({
  currentTime = new Date(),
  schedule = {},
  index,
  // style object provided from react-virutalized
  style = {},
}) => {
  const { scheduleTitle, events = [] } = schedule;
  const TITLE_MAX_LIMIT = 30;

  return (
    <Row className="schedule-row" style={style}>
      <TitleColumn>
        <Tooltip
          tooltipContent={
            scheduleTitle.length > TITLE_MAX_LIMIT ? (
              <WowTooltipWrapper width="400">{scheduleTitle}</WowTooltipWrapper>
            ) : null
          }
        >
          <span className="title">{scheduleTitle}</span>
        </Tooltip>
        <ItemActions item={schedule} />
      </TitleColumn>

      {range(0, 12).map((num) => {
        const isCurrent = currentTime.getMonth() === num;
        const isLast = num === 11;

        const month = num + 1;

        const cellData = events.find((evt) => +evt.month === month);

        return (
          <CellWrapper key={num} $isCurrent={isCurrent} $isLast={isLast}>
            <Cell
              month={month}
              cellData={cellData}
              schedule={schedule}
              index={index}
            />
          </CellWrapper>
        );
      })}
    </Row>
  );
};

export default YearlyTimelineRow;
