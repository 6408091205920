import { fromJS } from 'immutable';
import { actionStrings as _ } from './actions';

const initState = fromJS({
  loginSent: false,
  loggedIn: false,
  token: '',
  isLoading: false,
  error: null, // { error: 'message', time: new Date() }
  rememberMe: false,
});

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case _.LOGIN:
      return state;
    case _.LOGIN_START:
      return state.set('loginSent', true);
    case _.LOGIN_ERROR:
      return state
        .set('error', {
          error: action.payload.message,
          time: new Date(),
        })
        .set('loginSent', false);
    case _.LOGIN_SUCCESS:
      return state.set('loggedIn', true).set('loginSent', false);
    case _.PASSWORD_CHANGE_START:
      return state.set('passwordRequestSent', true);
    case _.PASSWORD_CHANGE_ERROR:
      return state.set('error', {
        error: action.payload.message,
        time: new Date(),
      });
    case _.PASSWORD_CHANGE_TOKEN_RECEIVED:
      return state.set('passwordChangeToken', action.payload.token);
    case _.PASSWORD_RESET_START:
      return state.set('passwordResetSent', true);
    case _.PASSWORD_RESET_ERROR:
      return state.set('error', {
        error: action.payload.message,
        time: new Date(),
      });
    case _.REMEMBER_ME:
      return state.set('rememberMe', action.payload);
    case _.LOGOUT:
      return initState;
    default:
      return state;
  }
};
