import React from 'react';

import Tooltip from '../Tooltip';

import useElementDimensions from '../../hooks/useElementDimensions';

const TextOverflowWithTooltip = ({
  children,
  tooltipContent,
  displayTooltip = false, // used to display tooltip always
}) => {
  const { elRef, width, height } = useElementDimensions();

  // indicator is component mounted
  const isMounted = React.useRef(false);

  const [overflowActive, setOverflowActive] = React.useState(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (elRef.current && isMounted.current) {
      const node = elRef.current;
      setOverflowActive(
        () =>
          node.offsetHeight < node.scrollHeight ||
          node.offsetWidth < node.scrollWidth
      );
    }
  }, [width, height, elRef]);

  return (
    <Tooltip
      tooltipContent={overflowActive || displayTooltip ? tooltipContent : null}
    >
      {children(elRef)}
    </Tooltip>
  );
};

export default TextOverflowWithTooltip;
