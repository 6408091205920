import { forwardRef } from 'react';

import { OuterWrapper, InnerWrapper } from './styles';

const ScrollTrack = forwardRef(({ ...restProps }, forwardedRef) => {
  return (
    <OuterWrapper>
      <InnerWrapper>
        <div
          ref={forwardedRef}
          {...restProps}
          className="__customCalendarScrollTrackStyles"
        ></div>
      </InnerWrapper>
    </OuterWrapper>
  );
});

export default ScrollTrack;
