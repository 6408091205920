import styled from 'styled-components/macro';
import { newRed } from '../../config/colors';

export const CounterWrapper = styled.div`
  font-size: 10px;
  font-weight: bold;
  vertical-align: top;
  background-color: ${newRed};
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: -1px;
  margin-left: 5px;
`;
