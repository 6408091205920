// util function to format data for dropdowns etc.
export const formatData = ({ data = [], customLabel, disabledIds = [] }) => {
  return data.map((item) => {
    return {
      ...item,
      label: item[customLabel] || item.name || item.full_name || item.title,
      value: item.id,
      image: item.profile_image,
      disabled: disabledIds.includes(item.id),
      children: item.children?.map((child) => {
        return {
          ...child,
          label: child.name,
          value: child.id,
          image: item.profile_image,
          disabled: disabledIds.includes(item.id),
        };
      }),
    };
  });
};

export const mergeData = (currData, data, addFirst, idKey = 'id') => {
  const currentIdsSet = new Set(currData.map((curr) => curr?.[idKey]));
  let itemsAdded = 0;

  for (const item of data) {
    // if item already exist skip it
    if (!currentIdsSet.has(item[idKey])) {
      if (addFirst) {
        currData.unshift(item);
      } else {
        currData.push(item);
      }
      currentIdsSet.add(item[idKey]);
      itemsAdded++;
    }
  }

  return {
    itemsAdded,
  };
};
