const STORAGE_OPTIONS = {
  none: {
    id: 0,
    labelKey: 'none',
    value: 'NONE',
  },
  workspace: {
    id: 1,
    labelKey: 'workspace',
    value: 'WORKSPACE',
  },
  custom: {
    id: 2,
    labelKey: 'custom',
    value: 'CUSTOM',
  },
};

const FORM_DEFAULT_VALUES = {
  title: '',
  workspace_id: null,
  storage_workspace_id: null,
  storage_workspace: null,
  status: null,
  storage: STORAGE_OPTIONS.none.value,
  location: null,
  group_id: null,
  description: '',
  serial_number: '',
  type_id: null,
  reference_number: null,
  handout_requires_approval: false,
  manager_ids: [],
  attachments: [],
  return_date: null,
  links: [],
};

export { FORM_DEFAULT_VALUES, STORAGE_OPTIONS };
