import React from 'react';
import TimelineItem from '../timeline-item/timeline-item.component';
import { NewComment } from '../new-comment/new-comment.component';
import { RenderIf } from '../../components/render-if/render-if.component';
import styled from 'styled-components/macro';
import { breakpoint } from '../../styles/styledComponents/media-breakpoints';

const CommentListWrapper = styled.ul`
  list-style-type: none;
  margin-bottom: 40px;
  position: relative;
  padding: 0;

  ${({ inGallery }) =>
    inGallery &&
    ` padding-left: 20px;
      padding-right: 20px;
      max-height: 350px;
      overflow-y: auto;

      @media only screen and ${breakpoint.xs} {
        padding-left: 40px;
      }
    `};

  & > :last-child {
    h4::before {
      border-left: none;
    }
  }
`;

const CommentList = (props) =>
  props.comments && props.comments.length > 0
    ? props.comments.map((comment, key) => (
        <TimelineItem
          key={key}
          firstName={comment.user.first_name}
          lastName={comment.user.last_name}
          message={comment.comment}
          createdAt={comment.created_at}
          locale={comment.user.language}
          img={comment.user.profile_image}
          isComment
        />
      ))
    : null;

export const Comments = (props) => (
  <div className={props.className} inGallery={props.inGallery}>
    <CommentListWrapper inGallery={props.inGallery}>
      <CommentList comments={props.comments} />
    </CommentListWrapper>
    <RenderIf
      if={props.canCommentIssue}
      then={() => (
        <NewComment
          user={props.user}
          onSubmit={props.onSubmit}
          inGallery={props.inGallery}
        />
      )}
    />
  </div>
);
