import React from 'react';
import styled from 'styled-components/macro';

import { blackThatWeNeedToName, outline } from '../../config/colors';
import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';
import { MenuItems, Menu as MenuDefault } from '../../components_new/Menu';
import { transformColor } from './util';

const Menu = styled(MenuDefault)`
  ${({ position }) => (position === 'right' ? 'right: 0px' : 'left: 0px')};
  z-index: 50;
  width: max-content;
`;

const QuickFilterWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const QuickFilterButton = styled.button`
  font-size: 12px;
  border-radius: ${({ shape }) => (shape === 'oval' ? '20px' : '50%')};
  padding: ${({ shape, padding }) =>
    padding ? padding : shape === 'oval' ? '6px 14px' : '12px'};
  width: ${({ shape }) => (shape === 'oval' ? 'auto' : '28px')};
  height: ${({ shape }) => (shape === 'oval' ? 'auto' : '28px')};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  letter-spacing: 2px;
  color: ${blackThatWeNeedToName};
  background-color: transparent;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${outline};
    ${({ padding }) => (padding ? `padding: ${padding}` : '')};
  }

  > * {
    margin-right: 6px;
  }

  > *:last-child {
    margin-right: 0px;
  }
`;

const Icon = styled.i.attrs(({ icon }) => ({ className: `icon ${icon}` }))`
  color: ${({ color }) => transformColor(color)};

  font-size: 22px;
`;

const ActionsMenu = ({
  icon,
  color,
  text,
  position = 'left',
  shape = 'oval',
  height,
  children,
  distanceY,
  distanceX,
  customButton,
  padding,
  dataTestIds,
  callbacks,
}) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  useKeyPress('Escape', () => setIsVisible(false));

  const handleClick = () => {
    setIsVisible(!isVisible);
    if (callbacks?.handleClick) callbacks.handleClick();
  };

  const CustomButton = customButton;

  return (
    <QuickFilterWrapper ref={ref}>
      {customButton ? (
        <CustomButton onClick={handleClick} />
      ) : (
        <QuickFilterButton
          onClick={handleClick}
          shape={shape}
          data-testid={dataTestIds?.actionsMenuButton ?? 'actionsMenuButton'}
          type="button"
          padding={padding}
        >
          <Icon icon={icon} color={color} />
          {text ? <span>{text}</span> : null}
        </QuickFilterButton>
      )}

      {isVisible ? (
        <Menu
          position={position}
          distanceY={distanceY}
          distanceX={distanceX}
          data-testid="menuDropdown"
        >
          <MenuItems onClick={handleClick} height={height}>
            {children}
          </MenuItems>
        </Menu>
      ) : null}
    </QuickFilterWrapper>
  );
};

export default ActionsMenu;
