import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu } from './../menu/menu.component';
import { MenuItem } from '../menu/menu-item/menu-item.component';
import { ESC_KEY } from '../../config/config';
import './dropdown.component.scss';
import { RenderIf } from '../render-if/render-if.component';

export class DropDown extends Component {
  constructor(props) {
    super(props);
    const selectedValue = this.getSelectedValue(props);

    this.state = {
      dropdownHidden: true,
      selectedValue: selectedValue,
      shouldResetChanges: props.shouldResetChanges,
    };
  }

  getSelectedValue = (props) => {
    /* eslint-disable-next-line no-prototype-builtins */
    if (props.hasOwnProperty('initialySelectedId') && props.values) {
      return props.values
        .reverse()
        .find((x) => x?.id === props.initialySelectedId);
    }

    return null;
  };

  componentDidUpdate(previousProps) {
    if (
      this.props.liveUpdate &&
      previousProps.initialySelectedId !== this.props.initialySelectedId
    ) {
      this.setState({
        selectedValue: this.getSelectedValue(this.props),
        shouldResetChanges: false,
      });
    }
    if (
      previousProps.values &&
      previousProps.values.length !== this.props.values.length
    ) {
      this.setState({
        selectedValue: this.getSelectedValue(this.props),
        shouldResetChanges: false,
      });
    }

    if (previousProps.shouldResetChanges && this.state.shouldResetChanges) {
      this.setState({
        selectedValue: this.getSelectedValue(this.props),
        shouldResetChanges: false,
      });
    }
  }

  // handle esc key press or click outside component
  escFunc = (e) => {
    if (e.keyCode === ESC_KEY && !this.state.dropdownHidden) {
      this.toggleDropdown(e);
    }

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target) &&
      !this.state.dropdownHidden
    ) {
      this.toggleDropdown(e);
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunc, false);
    document.addEventListener('mousedown', this.escFunc, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunc, false);
    document.removeEventListener('mousedown', this.escFunc, false);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  toggleDropdown(e) {
    if (this.props.disabled) {
      return;
    }

    this.setState({
      ...this.state,
      dropdownHidden: !this.state.dropdownHidden,
    });
  }

  onItemChanged = (e, id, text) => {
    e.preventDefault();

    const value = {
      id: id,
      text: text,
    };

    this.setState(
      (prevState) => ({
        selectedValue: value,
        dropdownHidden: !prevState.dropdownHidden,
      }),
      () => {
        if (this.props.onChange) {
          const changeValue = this.props.parseValue
            ? this.props.parseValue(value)
            : value;

          this.props.onChange({ name: this.props.name, value: changeValue });
        }
      }
    );
  };

  getItemText = (item) => {
    return item?.text;
  };

  getItemId = (item) => {
    return item?.id;
  };

  shouldRender(value, props) {
    if (props.hideCurrentlySelected && value?.id === props.initialySelectedId) {
      return false;
    }

    return true;
  }

  render() {
    const className = classNames(
      'drop-down',
      this.props.className,
      this.props.color,
      {
        disabled: this.props.disabled,
      }
    );

    const dropDownClassNames = classNames(
      'drop-down__elements',
      this.props.color,
      {
        disabled: this.props.disabled,
        inactive: !this.props.categoryChanged && this.props.notSet,
      }
    );

    const sortedValues = this.props.isStatusSelector
      ? this.props.values.sort((a, b) => b.id - a.id)
      : this.props.values;

    return (
      <div className="menu-selector">
        {this.props.heading && (
          <div className="menu-selector__heading-wrapper">
            <h5 className="menu-selector__h">{this.props.heading}</h5>
          </div>
        )}

        <div className="drop-down-wrapper" ref={this.setWrapperRef}>
          <div className={className}>
            <div
              className={dropDownClassNames}
              onClick={this.toggleDropdown.bind(this)}
            >
              <i className={`icon icon-${this.props.type}`} />
              {this.state.selectedValue && this.state.selectedValue?.text}
            </div>
          </div>
          {!this.state.dropdownHidden && !this.props.disabled ? (
            <Menu className="menu--position-change">
              {sortedValues.map((value, index) => (
                <RenderIf
                  key={`dropdown-val-${value}-${index}`}
                  if={this.shouldRender(value, this.props)}
                >
                  <MenuItem
                    key={`dropdown-val-${value}-${index}`}
                    itemKey={this.getItemId(value)}
                    text={this.getItemText(value)}
                    isFilterComponent={true}
                    onChangeFilter={this.onItemChanged}
                    extraHigh={this.props.extraHigh}
                    isSelected={value?.id === this.state.selectedValue?.id}
                    noTheme={this.props.noTheme}
                    noThemeText
                  />
                </RenderIf>
              ))}
            </Menu>
          ) : null}
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  initialySelectedId: PropTypes.any,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      text: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func,
  parseValue: PropTypes.func,
  extraHigh: PropTypes.bool,
  type: PropTypes.string,
  categoryChanged: PropTypes.func,
  notSet: PropTypes.bool,
};

DropDown.defaultProps = {
  heading: '',
  values: [],
  color: 'standard',
  disabled: false,
};
