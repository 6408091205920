import styled from 'styled-components/macro';

import { darkerGray } from '../../../../../config/colors';

export const CheckboxLabel = styled.span`
  color: ${darkerGray};
  position: relative;
  bottom: 3.7px;
  font-size: 14px;
  margin-left: 12px;
`;

export const MultiselectTitlesWrapper = styled.div`
  margin-bottom: 35px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 60px);
    height: 12px;
    background-color: green;
    left: -30px;
    right: 0;
    bottom: 0;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #808080 100%)
      0% 0% no-repeat padding-box;
    opacity: 0.25;
  }
`;

export const MultiselectTitlesInner = styled.div`
  max-height: 200px;
  overflow-y: visible;
  overflow-x: auto;
`;

export const InputRow = styled.div`
  position: relative;
`;
