import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Button from '../../components_new/Button';
import Modal, { SIZE } from '../../components_new/Modal';
import { InfoWrapper, StyledSecondaryActionButton } from './styles';
import CustomFormatedMessage from '../CustomFormattedMessage';
import { ACTION_TYPE, ACTION_BUTTON_COLOR, TITLE_KEY } from './constants';
import { ModalFooter, ModalFooterButtonWrapper } from '../WowModalElements';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';
import Space from '../Space';
import { WowBlock } from '../WowBasicElements';

export const ModalConfirm = ({
  isOpen,
  handleClose,
  handleAction,
  handleSecondaryAction,
  intlValues,
  actionType = ACTION_TYPE,
  disableAction = false,
  actionButtonColor = ACTION_BUTTON_COLOR,
  titleKey,
  title,
  dataTestIds,
  modalClasses,
  formId,
  children,
  hideCloseIcon,
  secondaryActionType,
}) => {
  const [isProcessing, setIsProcessing] = useState();
  const { messageKey: key, ...values } = intlValues ?? {};

  const _handleAction = async (e) => {
    if (disableAction) return;
    setIsProcessing(true);
    const response = await handleAction(e);
    if (response) setIsProcessing(false);
    if (response?.data?.success) handleClose();
  };

  const actionButtonProps = formId
    ? { form: formId, type: 'submit' }
    : { onClick: _handleAction, type: 'button' };

  return (
    <Modal
      title={
        title ? (
          title
        ) : (
          <FormattedMessage id={titleKey ? titleKey : TITLE_KEY} />
        )
      }
      isOpen={isOpen}
      onClose={handleClose}
      size={SIZE.small}
      classes={modalClasses}
      hideCloseIcon={hideCloseIcon}
    >
      <WowBlock data-testid="modalConfirm">
        <InfoWrapper data-testid="message">
          {key ? (
            <CustomFormatedMessage id={key} values={{ ...values }} />
          ) : children ? (
            children
          ) : null}
        </InfoWrapper>
        <Space height="30" />
        <ModalFooter>
          <ModalFooterButtonWrapper>
            <Button
              {...actionButtonProps}
              color={actionButtonColor}
              appearance="contained"
              size="small"
              disabled={disableAction || isProcessing}
              data-testid={dataTestIds?.confirmButton ?? 'actionButton'}
            >
              <FormattedMessage id={actionType} />
            </Button>
            {isProcessing && <LoadingSpinner size="small" />}
          </ModalFooterButtonWrapper>
          <Space width="20" />
          <ModalFooterButtonWrapper>
            <StyledSecondaryActionButton
              onClick={handleSecondaryAction ?? handleClose}
              appearance="text"
              size="small"
              type="button"
              data-testid={dataTestIds?.cancelButton ?? 'cancelButton'}
            >
              <FormattedMessage id={secondaryActionType ?? 'cancel'} />
            </StyledSecondaryActionButton>
          </ModalFooterButtonWrapper>
        </ModalFooter>
      </WowBlock>
    </Modal>
  );
};

ModalConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  intlValues: PropTypes.object,
  actionType: PropTypes.string,
  disableAction: PropTypes.bool,
  actionButtonColor: PropTypes.string,
  titleKey: PropTypes.string,
  formId: PropTypes.string,
  children: PropTypes.any,
};

export default ModalConfirm;
