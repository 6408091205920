import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { NoticeWithIcon } from '../../../../../../components_new/Notice';
import Space from '../../../../../../components_new/Space';
import {
  WowBlock,
  WowDisplayFlex,
  WowIcon,
} from '../../../../../../components_new/WowBasicElements';
import { LinkWithArrowWrapper } from '../../../../../../components_new/WowModalElements';
import { INIT_QUERY_PARAMS } from '../../../constants';
import { internalRoutePaths } from '../../../../../../config/config';
import { openInNewTab } from '../../../../../../utils/core';

const InfoMessage = ({ duplicated }) => {
  const intl = useIntl();
  const { completed, notCompleted } = duplicated;

  const handleViewAll = () => {
    const stringified = qs.stringify(
      {
        ...INIT_QUERY_PARAMS,
        ids: Object.values(notCompleted).map((item) => item.id),
      },
      { arrayFormat: 'comma' }
    );

    openInNewTab(`${internalRoutePaths.ISSUES}?${stringified}`);
  };

  return (
    <NoticeWithIcon
      icon="icon-important-round"
      variant="important"
      color="white"
      alignItems="flex-start"
      paddingV="20"
      paddingH="20"
      data-testid="errorMessage"
    >
      <WowBlock>
        {completed.length > 0 ? (
          <>
            {' '}
            {intl
              .formatMessage({ id: 'count_issue_duplicated' })
              .replace(':count', completed.length)}
            <Space height="10" />
          </>
        ) : null}
        {intl
          .formatMessage({ id: 'duplicated_but_not_assigned' })
          .replace(':count', notCompleted.length)}
        <WowDisplayFlex>
          <LinkWithArrowWrapper onClick={handleViewAll} color="white">
            <FormattedMessage id="view_all" />
            <WowIcon className="icon icon-arrowupleft"></WowIcon>
          </LinkWithArrowWrapper>
        </WowDisplayFlex>
      </WowBlock>
    </NoticeWithIcon>
  );
};

InfoMessage.propTypes = {
  duplicated: PropTypes.object.isRequired,
};

export default InfoMessage;
