import { FormattedMessage } from 'react-intl';
import { vestResolver } from '@hookform/resolvers/vest';
import { FormProvider, useForm } from 'react-hook-form';
import Space from '../../../../../../components_new/Space';
import {
  WowBlock,
  WowColorizeText,
  WowLabelText,
} from '../../../../../../components_new/WowBasicElements';
import { CtaWrapper, Footer, SectionLeft, SubTitle, Title } from '../../styles';
import Input from '../../../../../../components_new/Input';
import { FORM_DEFAULT_VALUES } from './constants';
import Button from '../../../../../../components_new/Button';
import { formValidation } from './utils';
import { newRed, userPillGray } from '../../../../../../config/colors';
import useWelcome from './hooks/useWelcome';
import { INPUT_COMMON_CONFIG } from '../../constants';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';
import { NameWrapper } from './styles';

const Welcome = () => {
  const formMethods = useForm({
    resolver: vestResolver(formValidation),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, formState, register } = formMethods;
  const { errors, isValid } = formState;

  const { handleContinue, isLoading } = useWelcome({ formMethods });

  return (
    <SectionLeft>
      <Title>
        <FormattedMessage id="welcome_to_wowflow" />
      </Title>
      <SubTitle>
        <FormattedMessage id="to_tailor_the_experience_like_to_know_more" />
      </SubTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleContinue)}>
          <NameWrapper>
            <WowBlock>
              <WowLabelText bold color={!!errors.firstName ? newRed : null}>
                <FormattedMessage id="first_name" />
              </WowLabelText>
              <Input
                {...register('firstName')}
                error={errors.firstName}
                data-testid="firstNameInput"
                {...INPUT_COMMON_CONFIG}
              />
            </WowBlock>
            <Space width="65" />
            <WowBlock>
              <WowLabelText bold color={!!errors.lastName ? newRed : null}>
                <FormattedMessage id="last_name" />
              </WowLabelText>
              <Input
                {...register('lastName')}
                error={errors.lastName}
                data-testid="lastNameInput"
                {...INPUT_COMMON_CONFIG}
              />
            </WowBlock>
          </NameWrapper>
          <WowBlock>
            <WowLabelText bold color={!!errors.company ? newRed : null}>
              <FormattedMessage id="company" />
            </WowLabelText>
            <Input
              {...register('company')}
              error={errors.company}
              data-testid="companyInput"
              {...INPUT_COMMON_CONFIG}
            />
          </WowBlock>
          <WowColorizeText color={userPillGray} size="12">
            * <FormattedMessage id="is_this_your_company_name" />
          </WowColorizeText>
          <Space height="30" />
          <Footer>
            <CtaWrapper data-testid="ctaWrapper">
              <Button
                size="small"
                color="blue"
                disabled={!isValid || isLoading}
                name="continue"
              >
                <FormattedMessage id="continue" />
              </Button>
              {isLoading ? <LoadingSpinner small /> : null}
            </CtaWrapper>
          </Footer>
        </form>
      </FormProvider>
    </SectionLeft>
  );
};

export default Welcome;
