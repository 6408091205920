import { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';

import {
  getMobileOS,
  isTabletWidth,
  isMobileWidth,
} from '../../../utils/utility';
import {
  MOBILE_OS,
  MOBILE_APP_LINKS,
  MOBILE_APP_REDIRECT_WITELIST,
} from '../../../config/config';
import useRawWindowLocation from '../../../hooks/useRawWindowLocation';

import ModalConfirm from '../../../components_new/ModalConfirm';

const MobileAppRedirect = (props) => {
  const { children } = props;

  const [showModal, setShowModal] = useState(false);
  const { getPathName } = useRawWindowLocation();
  const history = useHistory();
  const historyListener = useRef(null);

  const triggerRedirect = useCallback(() => {
    setShowModal(false);
    const os = getMobileOS();
    window.setTimeout(() => {
      if (os === MOBILE_OS.ANDROID)
        window.location.href = MOBILE_APP_LINKS.ANDROID;
      if (os === MOBILE_OS.IOS) window.location.href = MOBILE_APP_LINKS.IOS;
    }, 100);
  }, []);

  useEffect(() => {
    //Skip redirect for all "public" routes.

    const path = getPathName();

    const check = (path) => {
      if (!path.includes('/public') && !MOBILE_APP_REDIRECT_WITELIST[path]) {
        const currentWidth =
          window?.screen?.width ||
          window.innerWidth ||
          document?.documentElement?.clientWidth;
        /*
        Tablet redirect logic (in case if we ever get tablet optimised, uncomment this block and comment block bellow)
        const isTablet = isTabletWidth(currentWidth);
        const isMobile = isMobileWidth(currentWidth);
        if (isTablet) {
          // The only reason why we have this timeout is to have a little bit better UI experience. We don't want to show modal automatically when users enter web.
          window.setTimeout(() => setShowModal(true), 1000);
        } else if (isMobile) {
          triggerRedirect();
        }
        */
        const isTablet = isTabletWidth(currentWidth);
        const isMobile = isMobileWidth(currentWidth);
        if (isMobile || isTablet) triggerRedirect();
      }
    };

    historyListener.current = history.listen((route) => {
      const path = route?.pathname;
      check(path);
    });

    check(path);

    return historyListener.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {children}
      {showModal && (
        <ModalConfirm
          isOpen={showModal}
          handleClose={() => setShowModal(false)}
          handleAction={triggerRedirect}
          actionButtonColor="green"
          actionType="download"
          titleKey="wowflow_app"
          intlValues={{
            messageKey: 'download_wowflow_app',
          }}
        />
      )}
    </>
  );
};

export default MobileAppRedirect;
