import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { RenderIf } from '../../render-if/render-if.component';
import { white, darkGray, borderGray, offWhite } from '../../../config/colors';
import { themeColour } from '../../../helpers/theme';
import { NoItemsLinkElem } from './styles';

const OptionListWrapper = styled.ul`
  position: absolute;
  width: 100%;
  max-width: 1000px;
  top: ${(props) => props.top}px;
  margin-top: 5px;
  border-radius: 6px;
  padding-left: 0;
  background-color: ${white};
  z-index: 50;
  max-height: ${(props) => {
    switch (props.size) {
      case 'modal':
        return '390px;';
      default:
        return '300px;';
    }
  }};
  overflow-y: auto;
  ${({ withShadow }) =>
    withShadow && 'box-shadow: 0 2px 10px 0 rgba(55, 70, 95, .2);'};

  ${(props) => {
    if (props.alignRight) {
      return 'right: 0px';
    }

    return 'left: 0px';
  }}

  ${({ isSmallView }) =>
    isSmallView &&
    `
      top: auto;
      bottom: 60px;
      max-height: 150px;
      min-width: 100%;
  `}
`;
const OptionWrapper = styled.li`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  min-height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: ${(props) => {
    switch (props.size) {
      case 'large':
        return '10px 10px;';
      case 'modal':
        return '0px;';
      case 'small':
      default:
        return '5px 10px;';
    }
  }};
  margin: ${(props) => {
    switch (props.size) {
      case 'modal':
        return '30px 0 0 0;';
      default:
        return '0';
    }
  }};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: ${darkGray};
  border-top: ${(props) =>
    props.withBorders ? `1px solid ${borderGray}` : null};
  font-size: ${(props) => {
    switch (props.size) {
      case 'large':
        return '18px';
      case 'modal':
        return '18px';
      case 'small':
      default:
        return '14px';
    }
  }};
  background-color: ${(props) => (props.active ? offWhite : 'none')};

  &:first-child {
    border-top: none;
  }

  img {
    border-radius: 50%;
    ${(props) => {
      switch (props.size) {
        case 'large':
          return `
          width: 40px;
          height: 40px;
          `;
        case 'modal':
          return `
          width: 40px;
          height: 40px;
          `;
        case 'small':
        default:
          return `
          width: 20px;
          height: 20px;
          `;
      }
    }};
  }
`;
const OptionValue = styled.span`
  padding-left: 10px;
  display: inline-block;
  flex: 2;
  justify-content: end;
  word-break: break-all;
`;

const NoWrapOptionValue = styled(OptionValue)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionValueSelectedCheck = styled.span`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  color: ${({ isSelected, ...rest }) =>
    isSelected ? themeColour(rest) : darkGray};
`;

const AutocompleteOptionValueSelectedCheck = styled.span`
  display: flex;
  color: ${({ isSelected, ...rest }) =>
    isSelected ? themeColour(rest) : borderGray};
`;

const AutocompleteOptionValue = styled(OptionValue)`
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
`;

const NoItems = styled.div`
  padding: 0 15px 15px 15px;
  text-align: center;
  padding-top: 15px;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : ''}
`;

export const Option = ({
  value,
  isSelected,
  isDisabled,
  onClick,
  index,
  withBorders,
  size,
}) => (
  <OptionWrapper
    selected={!!isSelected}
    disabled={!!isDisabled}
    onClick={onClick}
    withBorders={withBorders}
    size={size}
  >
    <RenderIf if={value.profile_image}>
      <img src={value.profile_image} alt={`Profile ${value.value}`} />
    </RenderIf>
    <NoWrapOptionValue>{value.value}</NoWrapOptionValue>
    <OptionValueSelectedCheck
      mainAccent
      className="icon-check"
      isSelected={isSelected}
    />
  </OptionWrapper>
);

export const AutocompleteOption = ({
  value,
  isSelected = true,
  isDisabled,
  onClick,
  index,
  isActive = false,
}) => (
  <OptionWrapper
    selected={!!isSelected}
    disabled={!!isDisabled}
    active={!!isActive}
    onClick={onClick}
  >
    <RenderIf if={value.profile_image}>
      <img src={value.profile_image} alt={`User profile ${value.value}`} />
    </RenderIf>
    <AutocompleteOptionValue isSelected={isActive}>
      {value.value}
    </AutocompleteOptionValue>
    <AutocompleteOptionValueSelectedCheck
      mainAccent
      className="icon-arrowupleft"
      isSelected={isActive}
    />
  </OptionWrapper>
);

const PageNavigationWrapper = styled.li`
  display: flex;
  justify-content: space-between;
`;
const PageNavigate = styled.span`
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
`;

const PageNavigation = ({
  page,
  totalItems,
  limit,
  onNavigatePage = () => {},
}) => {
  const pages = Math.ceil(totalItems / limit);

  return (
    <PageNavigationWrapper>
      <PageNavigate
        disabled={page === 0}
        onClick={() => onNavigatePage(page - 1)}
        className="icon-arrowleft"
      />
      <div>
        {' '}
        {page + 1} {'/'} {pages}{' '}
      </div>
      <PageNavigate
        disabled={page === pages - 1}
        onClick={() => onNavigatePage(page + 1)}
        className="icon-arrowright"
      />
    </PageNavigationWrapper>
  );
};

export const OptionList = ({
  top,
  alignRight = false,
  dropdownRef,
  items,
  selectedItems,
  optionsOpened,
  onSelect,
  canUnselect,
  OptionItem,
  withPagination,
  withShadow,
  withBorders,
  size,
  limit,
  page,
  onNavigatePage,
  selectedIndex = -1,
  isSmallView,
  noItemsText,
  noItemsLink,
  noItemsBackgroundColor,
}) => {
  const selectedItemRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (selectedItemRef && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedIndex]);

  if (!optionsOpened) {
    return null;
  }

  let itemsToRender = items;

  if (withPagination && limit) {
    itemsToRender = items.slice(page * limit, limit);
  }

  // Scroll list to selected item.
  if (
    dropdownRef.current &&
    selectedIndex >= 0 &&
    dropdownRef.current.childNodes.length > selectedIndex
  ) {
    dropdownRef.current.childNodes[selectedIndex].scrollTop = 0;
  }

  return (
    <OptionListWrapper
      top={top}
      alignRight={alignRight}
      data-test="options"
      ref={dropdownRef}
      isSmallView={isSmallView}
      withShadow={withShadow}
      size={size}
    >
      {itemsToRender.length === 0 ? (
        <NoItems backgroundColor={noItemsBackgroundColor}>
          {noItemsText ? (
            noItemsLink ? (
              <NoItemsLinkElem onClick={() => history.push(noItemsLink)}>
                {noItemsText}
              </NoItemsLinkElem>
            ) : (
              <div>{noItemsText}</div>
            )
          ) : (
            <FormattedMessage id="no_items" />
          )}
        </NoItems>
      ) : (
        itemsToRender.map((v, index) => {
          const isSelected = selectedItems.find(({ id }) => id === v.id);
          const isActive = index === selectedIndex;

          return (
            <OptionItem
              withBorders={withBorders}
              size={size}
              isSelected={isSelected}
              isDisabled={!canUnselect && isSelected}
              isActive={isActive}
              key={v.id || index}
              index={index}
              onClick={() => {
                if (!canUnselect && isSelected) {
                  return;
                }

                onSelect(v, isSelected);
              }}
              value={v}
            />
          );
        })
      )}
      {withPagination ? (
        <PageNavigation
          onNavigatePage={onNavigatePage}
          page={page}
          totalItems={items.length}
          limit={limit}
        />
      ) : null}
    </OptionListWrapper>
  );
};
