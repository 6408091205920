import { useIntl } from 'react-intl';
import _ from 'lodash-es';

import { ID_ERROR_MESSAGE } from './constants';

const CustomFormattedMessage = ({ id, values, capitalize }) => {
  if (!id) throw new Error(ID_ERROR_MESSAGE);

  const intl = useIntl();

  let str = intl.formatMessage({
    id,
  });

  if (!values) return str;

  for (let key of Object.keys(values)) {
    const find = `:${_.snakeCase(key)}`;
    str = str.replace(
      new RegExp(find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'),
      values[key]
    ); //str.replaceAll(`:${_.snakeCase(key)}`, values[key]);
  }

  return capitalize ? _.capitalize(str) : str;
};

export default CustomFormattedMessage;
