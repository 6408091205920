import { useHistory } from 'react-router';
import { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../../../../hooks/useClickOutside';
import { internalRoutePaths } from '../../../../../../../config/config';
import useSwitchClient from './useSwitchClient';

const useProfileMenu = ({ skipQuery } = {}) => {
  const {
    isLoadingSwitchClient,
    handleSwitchClient,
    collabFeatureActive,
    loggedUser,

    parsedOrganizations,
    userStatistics,
  } = useSwitchClient({ skipQuery });

  const isAdmin = loggedUser?.role === 'admin';

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef();

  useOnClickOutside(containerRef, () => setIsOpen(false), isOpen);

  const handleMenuOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const _handleSwitchClient = useCallback(
    (id, url) => {
      const _callback = () => setIsOpen(false);
      handleSwitchClient(id, _callback, url);
    },
    [handleSwitchClient]
  );

  const handleGoToProfile = useCallback(() => {
    if (!parsedOrganizations.current.is_main) {
      const url = internalRoutePaths.USER_DETAIL.replace(
        ':id',
        userStatistics?.id
      );
      _handleSwitchClient(parsedOrganizations.main.id, url);
      return;
    }
    history.push(`/dashboard/profile/detail/${userStatistics?.id}`);
    setIsOpen(false);
  }, [userStatistics, parsedOrganizations, history, _handleSwitchClient]);

  const handleGoToCreateCollaborator = () => {
    setIsOpen(false);
    history.push({
      pathname: internalRoutePaths.COLLABORATORS,
      state: { showAddModal: true },
    });
  };

  const handleGoToSettings = () => {
    setIsOpen(false);
    history.push({
      pathname: internalRoutePaths.GENERAL,
    });
  };

  return {
    containerRef,
    handleMenuOpen,
    isLoading: isLoadingSwitchClient,
    user: userStatistics,
    currentOrganisation: parsedOrganizations.current,
    isOpen,
    availableOrganisations: parsedOrganizations.available,
    handleGoToProfile,
    handleSwitchClient: _handleSwitchClient,
    isAdmin,
    handleAction: collabFeatureActive
      ? handleGoToCreateCollaborator
      : handleGoToSettings,
    collabFeatureActive,
  };
};

export default useProfileMenu;
