import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from '../../../../../components_new/InfiniteScroll';

import { borderLighterGray } from '../../../../../config/colors';
import { useDebounce } from '../../../../../hooks/useDebounce';
import Tooltip from '../../../../../components_new/Tooltip';
import { limitNumberOfCharacters } from '../../../IssuesV2/Issue/components/AddEditWrapper/components/IssueTabs/Notes/utils';

import { Search } from '../../../../../components_new/StickyHeader';
import {
  WowIcon,
  WowTooltipWrapper,
} from '../../../../../components_new/WowBasicElements';
import { WowCheckbox } from '../../../../../components/wow-checkbox';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';

import {
  OuterWrapper,
  Wrapper,
  Title,
  SearchWrapper,
  FilterActionButtonsWrapper,
  FilterActionButton,
  ListItemWrapper,
  ListItemLeft,
  ListItemRight,
  ListItemText,
  CheckboxWrapper,
  ListItemsWrapper,
  SpinnerWrapper,
} from './styles';
import useElementDimensions from '../../../../../hooks/useElementDimensions';
import {
  setMainDraggableFiltersClickedClearAllButton,
  setMainDraggableFiltersClickedSelectAll,
  setMainDraggableFiltersClickedSelectAllUnderSearchSession,
} from '../../slice';
import { getFilters } from '../../selectors';

const MainDraggableFilter = (props) => {
  const {
    title = '',
    searchValueOnChange = () => {},
    listData = [],
    /*
      // listData example
      [
        {
          id: <string>
          display: <string>
          checked: <boolean>
          metadata: <any>
        }
      ]
    */
    listDataOnChange = () => {},
    isLoading = false,
    isDragDisabled,
    showLoadMore,
    handleLoadMore = () => {},
    activeView,
    clearAllWithoutSearchClicked,
    searchParamSessionStarted,
    checkingWithSearchParamStarted,
  } = props;

  const intl = useIntl();
  const [localListData, setLocalListData] = useState([]);
  const [localSearchValue, setLocalSearchValue] = useState('');
  const dispatch = useDispatch();
  const filters = useSelector(getFilters) ?? {};
  const { calendar_search } = filters;

  const debouncedSearchValue = useDebounce(localSearchValue);

  useEffect(() => {
    searchValueOnChange(debouncedSearchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (listData) setLocalListData(listData);
  }, [listData]);

  useEffect(() => {
    setLocalSearchValue(calendar_search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDragEnd = (result) => {
    if (
      !result ||
      !result.destination ||
      result.destination.index === result.source.index
    )
      return;
    const mutatedState = [...localListData];
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [removed] = mutatedState.splice(startIndex, 1);
    mutatedState.splice(endIndex, 0, removed);
    listDataOnChange(mutatedState);
  };

  const handleSelectAll = () => {
    dispatch(
      setMainDraggableFiltersClickedClearAllButton({
        data: false,
        type: activeView,
      })
    );
    if (localSearchValue === '') {
      searchParamSessionStarted.current = false;
      dispatch(
        setMainDraggableFiltersClickedSelectAll({
          data: true,
          type: activeView,
        })
      );
    } else {
      dispatch(
        setMainDraggableFiltersClickedSelectAllUnderSearchSession({
          data: true,
          type: activeView,
        })
      );
    }
    const mutatedState = [...localListData];
    mutatedState.forEach((item) => (item.checked = true));
    listDataOnChange(mutatedState);
  };

  const handleCheck = (index = null, e) => {
    if (index === null) return;
    const mutatedState = [...localListData];
    if (mutatedState[index]) {
      mutatedState[index].checked = e.target.checked;
    }
    listDataOnChange(mutatedState);
    dispatch(
      setMainDraggableFiltersClickedSelectAll({
        data: false,
        type: activeView,
      })
    );
  };

  const handleClearAll = () => {
    setLocalSearchValue('');
    dispatch(
      setMainDraggableFiltersClickedSelectAllUnderSearchSession({
        data: false,
        type: activeView,
      })
    );
    dispatch(
      setMainDraggableFiltersClickedSelectAll({
        data: false,
        type: activeView,
      })
    );
    searchParamSessionStarted.current = false;
    checkingWithSearchParamStarted.current = false;
    window.setTimeout(() => {
      dispatch(
        setMainDraggableFiltersClickedClearAllButton({
          data: true,
          type: activeView,
        })
      );
      if (!calendar_search || calendar_search === '')
        clearAllWithoutSearchClicked.current = true;
      const mutatedState = [...localListData];
      mutatedState.forEach((item) => (item.checked = false));
      listDataOnChange(mutatedState, true);
    }, 0);
  };

  const { height, elRef: sidabarRef } = useElementDimensions();
  const scrollMaxHeight =
    height -
    (window.innerHeight > 1700
      ? window.innerHeight / 1.7
      : window.innerHeight > 1200
      ? window.innerHeight / 2
      : window.innerHeight > 1150
      ? 320
      : window.innerHeight > 1100
      ? 280
      : window.innerHeight > 900
      ? 210
      : 210);

  //#region on view/change move scroll to top
  const refItemsWrapper = useRef();

  useEffect(() => {
    const itemsWrapper = refItemsWrapper.current;
    if (itemsWrapper) {
      itemsWrapper.scrollIntoView({
        block: 'start',
      });
    }
  }, [activeView]);
  //#endregion

  return (
    <OuterWrapper data-testid="mainDraggable">
      <Wrapper ref={sidabarRef}>
        <Title>{title}</Title>
        {isLoading ? (
          <SpinnerWrapper>
            <LoadingSpinner size="small" />
          </SpinnerWrapper>
        ) : (
          <>
            <SearchWrapper>
              <Search
                value={localSearchValue}
                onChange={(e) => setLocalSearchValue(e?.target?.value ?? '')}
                onClear={() => setLocalSearchValue('')}
                style={{ width: '100%' }}
              />
            </SearchWrapper>
            <FilterActionButtonsWrapper>
              <FilterActionButton
                style={{ marginRight: 20 }}
                onClick={handleSelectAll}
              >
                {intl.formatMessage({
                  id: 'select_all',
                })}
              </FilterActionButton>
              <FilterActionButton onClick={handleClearAll}>
                {intl.formatMessage({
                  id: 'clear_all',
                })}
              </FilterActionButton>
            </FilterActionButtonsWrapper>
            <InfiniteScroll
              dataLength={localListData?.length ?? 0}
              handleLoadMore={handleLoadMore}
              hasMore={showLoadMore}
              height={-1}
              maxHeight={scrollMaxHeight ?? 550}
            >
              {localListData?.length > 0 && (
                <ListItemsWrapper ref={refItemsWrapper}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {localListData.map((item, index) => (
                            <Draggable
                              key={String(item.id)}
                              draggableId={String(item.id)}
                              index={index}
                              isDragDisabled={isDragDisabled}
                            >
                              {(provided) => (
                                <ListItemWrapper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListItemLeft>
                                    <CheckboxWrapper>
                                      <WowCheckbox
                                        checked={item?.checked}
                                        size={20}
                                        borderColor={borderLighterGray}
                                        onChange={(e) => handleCheck(index, e)}
                                        data-testid="checkboxInput"
                                      />
                                    </CheckboxWrapper>
                                    <Tooltip
                                      key={item?.id}
                                      tooltipContent={
                                        item?.display?.length < 20 ? (
                                          ''
                                        ) : (
                                          <WowTooltipWrapper width="250">
                                            {item.display}
                                          </WowTooltipWrapper>
                                        )
                                      }
                                    >
                                      <ListItemText>
                                        {item?.display
                                          ? limitNumberOfCharacters(
                                              item.display,
                                              20
                                            )
                                          : ''}
                                      </ListItemText>
                                    </Tooltip>
                                  </ListItemLeft>
                                  {!isDragDisabled && (
                                    <ListItemRight>
                                      <WowIcon
                                        className="icon icon-sort-drag"
                                        color={borderLighterGray}
                                        mRight="0"
                                        spaceRight="0"
                                      />
                                    </ListItemRight>
                                  )}
                                </ListItemWrapper>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </ListItemsWrapper>
              )}
            </InfiniteScroll>
          </>
        )}
      </Wrapper>
    </OuterWrapper>
  );
};

export default MainDraggableFilter;
