import React from 'react';
import styled from 'styled-components/macro';

import { darkerGray, hardWhite, outline, purple500 } from '../../config/colors';
import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';
import { Menu } from '../Menu';
import { themeColour, ThemedIcon } from '../../helpers/theme';
import { QuickFilterWrapper } from '../../components/quick-filter/style';
import { ISSUE_STATUSES } from '../../views/internal/IssuesV2/constants';
import { isNil } from 'lodash-es';

const StatusFilterWrapper = styled.div`
  position: relative;
  width: 100%;

  .status-dropdown-menu {
    margin-left: 1px;
  }
`;

const StatusFilterButton = styled.button`
  padding: 6px 14px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  color: ${darkerGray};

  &:focus,
  &:hover {
    outline: none;
    background-color: ${({ dropdown }) => (dropdown ? 'transparent' : outline)};
  }
  ${({ dropdown }) =>
    dropdown &&
    `
  padding:0;
  width:100%;
  `}
`;

const Selection = styled.span`
  color: ${(props) => themeColour(props) ?? purple500};
`;

export const MenuItem = styled.li`
  font-size: 14px;
  font-weight: 600;
  color: ${({ selected, ...restProps }) =>
    selected ? themeColour(restProps) ?? purple500 : darkerGray};
  padding: 12px 14px;
  border-bottom: 1px solid ${outline};
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &:focus {
    background-color: ${hardWhite};
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
  ${({ parent }) =>
    !isNil(parent) &&
    `
   border-bottom: none;
   padding-left:32px
  `}
`;

const MenuItems = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
  overflow-y: auto;

  .main {
    &:last-child {
      border-top: 1px solid ${outline};
    }

    &:not(:first-child):not(:last-child) {
      border-top: 1px solid ${outline};
      border-bottom: 1px solid ${outline};
    }
  }
`;

const StatusFilterItem = ({
  onClick,
  children,
  parent,
  selectedValues,
  value,
}) => {
  const selected = selectedValues?.includes?.(value);

  return (
    <MenuItem
      onClick={onClick}
      selected={selected}
      tabIndex={0}
      parent={parent}
      className={isNil(parent) && 'main'}
      mainAccent
    >
      {children} {selected ? <i className="icon icon-check" /> : null}
    </MenuItem>
  );
};

const StatusFilterDropdown = ({
  placeholder,
  onChange,
  options,
  value,
  dropdown,
}) => {
  const selectedActiveValues = value?.active?.split?.(',') ?? [];
  const selectedArchivedValues = value?.archived?.split?.(',') ?? [];

  const { ref, isVisible, setIsVisible } = useVisible(false);
  useKeyPress('Escape', () => setIsVisible(false));

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleChange = (value, parent) => () => {
    // when it's clicked on parent
    if (isNil(parent)) {
      setIsVisible(false);
      return onChange(value);
    }

    const selectedValues =
      parent === ISSUE_STATUSES.ALL_ACTIVE
        ? selectedActiveValues
        : selectedArchivedValues;

    if (selectedValues.includes(value)) {
      return onChange(
        selectedValues.filter((item) => item !== value).join(','),
        parent
      );
    }

    onChange([...selectedValues, value].join(','), parent);
  };

  const selected = options.find(
    (option) => option.value === value?.issue_state
  );

  const getButtonText = () => {
    if (selected.value === '')
      return (
        <>
          {placeholder}{' '}
          <Selection mainAccent>
            <i className="icon icon-arrowdown-02" />
          </Selection>
        </>
      );

    return (
      <>
        {`${placeholder}: `}
        <Selection mainAccent>
          {selected.label} <i className="icon icon-arrowdown-02" />
        </Selection>
      </>
    );
  };

  const getDropdownButton = () => {
    /** `selected` values labels */
    const selectedValues = [
      ...selectedActiveValues,
      ...selectedArchivedValues,
    ].map((value) => options.find((option) => option.value === value)?.label);

    if (selected?.value === '') {
      return (
        <QuickFilterWrapper>
          <span
            className="menu-wrapper"
            data-test="menu-wrapper"
            onClick={handleClick}
          >
            <div className="filter-menu-button">
              <div className="ellipsis" data-test="filter-caption">
                {`${placeholder}${
                  selectedValues.length ? ':' : ''
                } ${selectedValues.join(',')}`}
              </div>
              <ThemedIcon mainAccent className="icon icon-arrowdown-02" />
            </div>
          </span>
        </QuickFilterWrapper>
      );
    }

    return (
      <QuickFilterWrapper>
        <span
          className="menu-wrapper"
          data-test="menu-wrapper"
          onClick={handleClick}
        >
          <div className="filter-menu-button">
            <div className="ellipsis" data-test="filter-caption">
              {selected?.label}
            </div>
            <ThemedIcon mainAccent className="icon icon-arrowdown-02" />
          </div>
        </span>
      </QuickFilterWrapper>
    );
  };

  const resolveSelectedValues = (option) => {
    if (!isNil(option.parent)) {
      return option.parent === ISSUE_STATUSES.ALL_ACTIVE
        ? selectedActiveValues
        : selectedArchivedValues;
    }
    if (
      [ISSUE_STATUSES.ALL_ACTIVE, ISSUE_STATUSES.ARCHIVED].includes(
        option.value
      )
    ) {
      return [
        ...[selectedActiveValues?.length && ISSUE_STATUSES.ALL_ACTIVE],
        ...[selectedArchivedValues?.length && ISSUE_STATUSES.ARCHIVED],
      ];
    }
    return value?.issue_state;
  };

  return (
    <StatusFilterWrapper ref={ref}>
      <StatusFilterButton onClick={handleClick} dropdown={dropdown}>
        {dropdown ? getDropdownButton() : getButtonText()}
      </StatusFilterButton>

      {isVisible ? (
        <Menu hardcodedWidth="99%" className="status-dropdown-menu">
          <MenuItems>
            {options.map((option) => (
              <StatusFilterItem
                onClick={handleChange(option.value, option.parent)}
                key={`${option.value}-${option.parent}`}
                parent={option.parent}
                selectedValues={resolveSelectedValues(option)}
                value={option.value}
              >
                {option.label}
              </StatusFilterItem>
            ))}
          </MenuItems>
        </Menu>
      ) : null}
    </StatusFilterWrapper>
  );
};

export default StatusFilterDropdown;
