import { Item, Wrapper } from './styles';

const InvalidFilesList = ({ items }) => {
  if (items.length < 2) return null;

  return (
    <Wrapper>
      {items.map((item, idx) => {
        const key = item?.tempId ?? `${item?.name}-${idx}`;
        return <Item key={key}>{item.name}</Item>;
      })}
    </Wrapper>
  );
};

export default InvalidFilesList;
