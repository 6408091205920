import styled from 'styled-components/macro';
import {
  darkerGray,
  purple500,
  newBorder,
  gradientBlue,
  newRed,
  white,
} from '../../../../config/colors';
import { TextWrapper } from '../../../../components_new/Activity/styles';
import { WowTabsList } from '../../../../components_new/WowTabs';

export const Wrapper = styled.div`
  flex-grow: 1;
  margin-top: 2rem;
  margin: 32px 64px 0;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FiltersWrapper = styled.div`
  display: flex;

  > div:first-of-type button {
    > span {
      color: ${darkerGray};
      > i {
        color: ${purple500};
      }
    }
  }
`;

export const TimelineWrapper = styled.div`
  padding-left: 24px;
  padding-bottom: 30px;
  border-left: ${({ isLastItem }) =>
    isLastItem ? 'none' : `1px solid ${newBorder}`};
  position: relative;
  margin-left: 8px;

  &::before {
    width: 15px;
    height: 15px;
    margin-left: -32px;
    content: '';
    position: absolute;
    display: inline-block;
    background-color: white;
    border: 3px solid ${gradientBlue};
    border-radius: 100%;

    box-shadow: 0 0 0.1px 0 rgba(0, 0, 0, 0.2),
      inset 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.5);
  }
`;

export const TimelineTextWrapperStyled = styled(TextWrapper)`
  background-color: ${({ shouldBeMarked }) =>
    shouldBeMarked ? newRed : white};
  word-break: break-word;
  box-shadow: 0px 2px 10px #37465f1a;
  ${({ isBreak }) => (isBreak ? 'padding-right: 60px;' : null)};
`;

export const TimelineHeading = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${darkerGray};
  margin-bottom: 10px;
`;

export const TabsList = styled(WowTabsList)`
  border-top: none;
`;
