import { fromJS } from 'immutable';
import { actionStrings as notificationsAction } from './actions';
import { entities } from '../../config/config';

const initState = fromJS({});

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case notificationsAction.GET_NOTIFICATIONS:
      return state.set(
        entities.NOTIFICATION.reduxProp,
        action.payload.notifications
      );
    case notificationsAction.MARK_NOTIFICATION_AS_READ:
      return state.set(
        entities.NOTIFICATION.reduxProp,
        action.payload.notifications
      );
    case notificationsAction.MARK_ALL_ASS_READ:
      return state.set(
        entities.NOTIFICATION.reduxProp,
        action.payload.notifications,
        action.payload.notificationClassName
      );
    default:
      return state;
  }
};
