import { forwardRef, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import ButtonAdd from '../../../../../../../../../../components_new/ButtonAdd';
import { WowIcon } from '../../../../../../../../../../components_new/WowBasicElements';
import { DateInputWrapper } from '../../styles';
// Lift up
import DatePicker from '../../../../../../../../scheduling/schedule/components/DatePicker/DatePicker';
import { useMemo } from 'react';
import dayjs from 'dayjs';

const DueDateItem = forwardRef((props, ref) => {
  const {
    disabled,
    pickerPositionTop,
    value: dueDate,
    popperPlacement,
    showRemove,
  } = props;

  const { watch, setValue } = useFormContext();
  const startDate = watch('start_date');
  const wrapperRef = useRef(null);

  const unsetValue = () => {
    setValue('due_date', null, { shouldDirty: true, shouldValidate: true });
  };

  let defaultDueDate = Date.now();
  if (startDate) {
    defaultDueDate = new Date(startDate);
    defaultDueDate.setDate(defaultDueDate.getDate() + 1);
    defaultDueDate = Date.parse(defaultDueDate);
  }

  const _getIsDueDateEqualToOrBeforeStartDate = (_startDate, _dueDate) => {
    return _dueDate.isSame(_startDate) || _dueDate.isBefore(_startDate);
  };

  const isDueDateEqualToOrBeforeStartDate = useMemo(() => {
    if (!startDate || !dueDate) return;
    const _startDate = dayjs(startDate);
    const _dueDate = dayjs(dueDate);
    return _getIsDueDateEqualToOrBeforeStartDate(_startDate, _dueDate);
  }, [dueDate, startDate]);

  return props.value ? (
    <DateInputWrapper>
      <WowIcon
        className="icon icon-calendar"
        style={{
          marginLeft: 7,
          position: 'relative',
          bottom: 1,
          left: 1,
        }}
      />
      <DatePicker
        modalTestId="issueDueDateModal"
        {...props}
        value={dueDate}
        onCalendarClose={() => {
          const _startDate = dayjs(startDate);
          const _dueDate = dayjs(dueDate);
          const _isDueDateEqualToOrBeforeStartDate =
            _getIsDueDateEqualToOrBeforeStartDate(_startDate, _dueDate);
          if (_isDueDateEqualToOrBeforeStartDate) {
            unsetValue();
          }
        }}
        minDate={startDate}
        wrapperRef={wrapperRef}
        customInput={null}
        pickerPositionTop={pickerPositionTop}
        disableDoneButton={isDueDateEqualToOrBeforeStartDate}
        popperPlacement={popperPlacement}
      />
      {!disabled && showRemove && (
        <WowIcon className="icon icon-close" onClick={unsetValue} />
      )}
    </DateInputWrapper>
  ) : (
    !disabled && (
      <ButtonAdd
        data-testid="issueDueDateAddButton"
        onClick={() => {
          setValue('due_date', defaultDueDate, { shouldDirty: true });
          window.setTimeout(() => {
            if (wrapperRef.current) {
              const inputElem = wrapperRef.current.querySelector('input');
              if (inputElem) inputElem.click();
            }
          }, 0);
        }}
      />
    )
  );
});

export default DueDateItem;
