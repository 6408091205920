import styled from 'styled-components/macro';
import {
  darkerGray,
  hardWhite,
  newBorder,
  purple500,
  userPillGray,
} from '../../../../../../config/colors';
import { breakPoints } from '../../../../../../config/config';
import { Footer } from '../../styles';

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;

  @media (max-width: ${breakPoints.tabletWidth}px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  border-radius: 4px;
  border: 1px solid ${newBorder};
  padding: 15px 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  ${({ isSelected }) =>
    isSelected ? `border: 2px solid ${purple500};` : null};

  label {
    position: absolute;
    left: -2px;
    top: -2px;
  }

  :hover {
    background-color: ${hardWhite};
    cursor: pointer;
  }
`;

export const CardTitle = styled.div`
  color: ${darkerGray};
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
`;

export const CardDescription = styled.div`
  color: ${userPillGray};
  font-size: 12px;
  word-break: break-word;
`;

export const FooterDivider = styled.div`
  height: 30px;

  @media (max-width: ${breakPoints.tabletWidth}px) {
    height: 115px;
  }
`;

export const FooterWrapper = styled(Footer)`
  @media (max-width: ${breakPoints.tabletWidth}px) {
    position: fixed !important;
  }
  background-color: white;
  padding-bottom: 25px;
`;
