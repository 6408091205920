import { useState, useEffect } from 'react';
import useModalState from './useModalState';
import { useAuthorization } from '.';

const useReservations = ({ item } = {}) => {
  const [defaultData, setDefaultData] = useState();
  const [editState, setEditState] = useState('idle');

  const handleEdit = (reservation) => {
    const isExternalHolder = reservation.holder.is_public_user;
    const initDefaultData = {
      returnAt: reservation.return_at,
      reservationDate: reservation.reservation_start_date,
      isComingFromReservationsModal: true,
      reservationId: reservation.id,
      isEdit: true,
    };

    if (isExternalHolder) {
      setDefaultData({
        ...initDefaultData,
        email: reservation.holder.email,
        name: reservation.holder.full_name,
        note: reservation.note,
        external_holder: { ...reservation.holder },
        external_holder_id: reservation.holder?.id,
        external_holder_email_id: reservation.holder?.id,
        phone: reservation.holder?.phone,
      });
      setEditState('external');
    } else {
      setDefaultData({
        ...initDefaultData,
        holderId: reservation.holder.id,
      });

      setEditState('internal');
    }
  };

  const {
    isVisible: isVisibleModalReserve,
    toggleModalState: toggleModalReserve,
  } = useModalState(false);

  const { loggedInUser, isAdmin, isManager } = useAuthorization(item);

  const isUserAuthorized = (item) => {
    if (isAdmin || isManager || item.holder_id === loggedInUser.id) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (editState === 'external' || editState === 'internal') {
      toggleModalReserve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState]);

  return {
    defaultData,
    setDefaultData,
    editState,
    setEditState,
    handleEdit,
    isVisibleModalReserve,
    toggleModalReserve,
    isUserAuthorized,
  };
};

export default useReservations;
