import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  ContentWrapper,
  BellIcon,
  Button,
  UnreadCount,
  LoaderWrapper,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getClientState } from './../../../users/selectors';
import { getTabsByPermissions } from './utils';
import NotificationsTabs from '../../../../../components_new/NotificationsTabs/NotificationsTabs';
import { useTabs } from './../../../../../hooks/useTabs';
import NotificationsHeader from './NotificationHeader/NotificationsHeader';
import NotificationTabContent from './NotificationTabContent/NotificationTabContent';
import {
  useTogglePauseNotificationsMutation,
  useReadAllNotificationsMutation,
} from '../../service';
import { useHistory } from 'react-router-dom';
import useNotifications from './hooks/useNotifications';
import useVisible from './../../../../../hooks/useVisible';
import { getNotificationsUnreadCount } from '../../selectors';
import { setNotificationsUnreadCount } from '../../slice';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';
import { getUser } from '../../../../../redux/user/selectors';

const NotificationBell = ({ handleRedirectToEquipmentListing }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const notificationBellIconElem = useRef();
  const {
    ref,
    isVisible: isVisibleDropdown,
    setIsVisible: setIsVisibleDropdown,
  } = useVisible(false, { skipHideOnElements: [notificationBellIconElem] });

  const { activeTab, handleTabChange } = useTabs();
  const { settings } = useSelector(getClientState);
  const loggedInUser = useSelector(getUser);
  const tabs = useMemo(() => getTabsByPermissions(settings), [settings]);
  const notificationsUnreadCount = useSelector(getNotificationsUnreadCount);

  const [togglePauseNotifications] = useTogglePauseNotificationsMutation();
  const [readAllNotifications] = useReadAllNotificationsMutation();

  const [isPaused, setIsPaused] = useState();

  useEffect(() => {
    setIsPaused(
      loggedInUser?.settings?.pause_fcm_notifications_until ? true : false
    );
  }, [loggedInUser]);

  const handleTogglePauseNotifications = async () => {
    const response = await togglePauseNotifications(!isPaused);
    if (response.data.success) {
      setIsPaused(!isPaused);
    }
  };

  const handleOpenSettings = () => {
    setIsVisibleDropdown(false);
    history.push({
      pathname: '/dashboard/settings/profile',
      state: {
        // indicator for setting page to scroll to notifications
        // and expand checkboxes
        notifications: true,
      },
    });
  };

  const {
    scrollState,
    handleCustomQuery,
    handleLoadMoreWithPaginateBy,
    isFetching,
    toggleShowOnlyUnread,
    showOnlyUnread,
    refetch,
  } = useNotifications();

  useEffect(() => {
    if (scrollState?.query.category !== tabs[activeTab].value) {
      handleCustomQuery({
        category: tabs[activeTab].value,
      });
    }
  }, [tabs, activeTab, handleCustomQuery, scrollState?.query?.category]);

  const handleReadAllNotifications = async () => {
    await readAllNotifications();
    dispatch(setNotificationsUnreadCount(0));
    handleCustomQuery();
  };

  const { itemsList, hasMore } = scrollState;

  return (
    <>
      <Button
        ref={notificationBellIconElem}
        onClick={() => {
          refetch();
          setIsVisibleDropdown(!isVisibleDropdown);
        }}
      >
        <BellIcon />
        {!isVisibleDropdown && notificationsUnreadCount ? (
          <UnreadCount>{notificationsUnreadCount}</UnreadCount>
        ) : null}
      </Button>
      {isVisibleDropdown ? (
        <ContentWrapper ref={ref}>
          <NotificationsHeader
            pausedNotfs={isPaused}
            handleMarkAll={handleReadAllNotifications}
            handlePause={handleTogglePauseNotifications}
            goToSettings={handleOpenSettings}
            toggleShowUnread={toggleShowOnlyUnread}
            showOnlyUnread={showOnlyUnread}
            unreadCount={notificationsUnreadCount}
          />

          <NotificationsTabs
            tabs={tabs}
            onTabClick={handleTabChange}
            activeTab={activeTab}
            customStyles={{ paddingRight: 115 }}
          />
          {!itemsList.length && isFetching ? (
            <LoaderWrapper>
              <LoadingSpinner size="small" />
            </LoaderWrapper>
          ) : (
            <NotificationTabContent
              itemsList={itemsList}
              hasMore={hasMore}
              query={scrollState.query}
              handleLoadMoreWithPaginateBy={handleLoadMoreWithPaginateBy}
              unreadCount={notificationsUnreadCount}
              isFetching={isFetching}
              handleRedirectToEquipmentListing={
                handleRedirectToEquipmentListing
              }
              closeDropdown={() => setIsVisibleDropdown(false)}
              activeTab={activeTab}
            />
          )}
        </ContentWrapper>
      ) : null}
    </>
  );
};

export default NotificationBell;
