import styled from 'styled-components/macro';
import { darkerGray, hardWhite } from '../../../../../../config/colors';
import { WowDisplayFlex } from '../../../../../../components_new/WowBasicElements';
import { breakPoints } from '../../../../../../config/config';

export const EmailWrapper = styled(WowDisplayFlex)`
  width: 330px;

  @media (max-width: ${breakPoints.tabletWidth}px) {
    width: 100%;
  }
`;

export const AssigneeLabel = styled.div`
  color: ${darkerGray};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const AssigneeWrapper = styled.div`
  display: flex;
  border-radius: 18px;
  background-color: ${hardWhite};
  height: 36px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px 0 10px;
`;

export const AssigneeAvatar = styled.div`
  width: 24px;
  height: 24px;
  background-color: #ed47e5;
  border-radius: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 10px;
  text-transform: uppercase;
`;
