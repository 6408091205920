import DateRange from './components/DateRange';
import ItemsMultiselect from './components/ItemsMultiselect';
import Position from './components/Position';
import Priority from './components/Priority';
import Status from './components/Status';
import Type from './components/Type';

//NOTE > we need to map per BE keys or transform keys
export const FILTER_COMPONENTS = {
  workspaces_params: ItemsMultiselect,
  schedule_id_params: ItemsMultiselect,
  asset_ids_params: ItemsMultiselect,
  asset_group_ids_params: ItemsMultiselect,
  asset_system_ids_params: ItemsMultiselect,
  issue_type: Type,
  issue_state: Status,
  users_any_role_params: ItemsMultiselect,
  assignees_params: ItemsMultiselect,
  worked_on_by_params: ItemsMultiselect,
  watchers_params: ItemsMultiselect,
  reporters_params: ItemsMultiselect,
  collabs_any_role_params: ItemsMultiselect,
  assignees_companies_params: ItemsMultiselect,
  worked_on_by_companies_params: ItemsMultiselect,
  watchers_companies_params: ItemsMultiselect,
  reporters_companies_params: ItemsMultiselect,
  priority_ids: Priority,
  categories_params: ItemsMultiselect,
  filter_by_job_positions: Position,
  reporters_externals_params: ItemsMultiselect,
  date: DateRange,
};
