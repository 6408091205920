/* eslint-disable quote-props */
import { statusColors, red, pdfBlue, priorityColors, orange } from './colors';

export const LEGACY_colorHelper = {
  to_do: 'gray',
  in_progress: 'orange',
  in_review: 'purple',
  complete: 'green',
  high: red,
  medium: 'orange',
  normal: 'green',
  low: pdfBlue,
  urgent: red,

  'priority.urgent': red,
  'priority.low': pdfBlue,
  'priority.normal': 'green',
  'priority.medium': 'orange',
  'priority.high': 'orange',

  'status.to_do': 'gray',
  'status.in_review': 'purple',
  'status.in_progress': 'orange',
  'status.completed': 'green',
};

export const colorHelper = {
  to_do: statusColors['status.to_do'],
  in_progress: statusColors['status.in_progress'],
  in_review: statusColors['status.in_review'],
  complete: priorityColors['priority.normal'],
  high: orange,
  medium: statusColors['status.in_progress'],
  normal: priorityColors['priority.normal'],
  low: pdfBlue,
  urgent: red,

  'priority.urgent': priorityColors['priority.urgent'],
  'priority.low': priorityColors['priority.low'],
  'priority.normal': priorityColors['priority.normal'],
  'priority.medium': priorityColors['priority.medium'],
  'priority.high': priorityColors['priority.high'],

  'status.to_do': statusColors['status.to_do'],
  'status.in_review': statusColors['status.in_review'],
  'status.in_progress': statusColors['status.in_progress'],
  'status.completed': statusColors['status.completed'],
  'status.draft': statusColors['status.draft'],
};
