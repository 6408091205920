import Modal, { SIZE } from '../../components_new/Modal/index';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { WowLabelText } from '../../components_new/WowBasicElements';
import { InputsWrapper, ErrorMessage, StyledInput, Label } from './styles';
import { vestResolver } from '@hookform/resolvers/vest';
import { formValidation } from './utils';
import { getUserByEmail } from '../../views/internal/users/apiService';
import { useEffect } from 'react';
import { Dropdown } from '../Dropdown';
import { useEditUserMutation } from '../../views/internal/users/service';
import {
  ModalFooter,
  ModalFooterButtonWrapper,
  StyledButton,
} from '../WowModalElements';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';
import Space from '../Space';

const getDefaultValues = (user) => ({
  first_name: user.first_name,
  last_name: user.last_name,
  email: user.email,
  phone: user.phone === 'null' ? '' : user.phone,
  role: user.role,
  job_position: user.job_position,
});

const EditUserModal = ({ user, isOpen, toggleModal }) => {
  const intl = useIntl();

  const roleOptions = [
    { value: 'admin', label: intl.formatMessage({ id: 'admin' }) },
    { value: 'user', label: intl.formatMessage({ id: 'user' }) },
  ];

  const isArchived = user.status === 'PAUSED';

  const formMethods = useForm({
    defaultValues: getDefaultValues(user),
    resolver: vestResolver(formValidation),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const {
    register,
    handleSubmit,
    formState,
    setError,
    watch,
    clearErrors,
    control,
  } = formMethods;

  const { isValid, dirtyFields } = formState;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;
  const canSubmit = hasDirtyFields && isValid;

  const getMessage = (field) => {
    if (!formState.errors[field]) return;

    return intl.formatMessage({ id: formState.errors[field].message });
  };

  const getIsDirtyAndValid = (field) => {
    return formState.dirtyFields[field] && !formState.errors[field];
  };

  const [editUser, { isLoading }] = useEditUserMutation();

  const handleFormSubmit = async (data) => {
    try {
      await editUser({ id: user.id, body: data });
      toggleModal();
    } catch {}
  };

  const email = watch('email');

  useEffect(() => {
    if (!email || email === user.email || formState.errors.email) return;

    const fetchUserAndUpdateError = async () => {
      try {
        const response = await getUserByEmail(email);

        if (response.data.length) {
          setError('email', {
            type: 'manual',
            message: 'form_msg_email_already_added',
          });
        } else clearErrors('email');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetchUserAndUpdateError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleStatusChange = () => {
    const status = isArchived ? 'ACTIVE' : 'PAUSED';

    editUser({ id: user.id, body: { status } });

    toggleModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      size={SIZE.large}
      title={<FormattedMessage id="edit_profile" />}
      onClose={toggleModal}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <InputsWrapper>
            <Label>
              <WowLabelText>
                <FormattedMessage id="first_name" />
              </WowLabelText>
              <StyledInput
                isDirtyAndValid={getIsDirtyAndValid('first_name')}
                {...register('first_name')}
                error={getMessage('first_name')}
                maxLength={64}
              />
              <ErrorMessage>{getMessage('first_name')}</ErrorMessage>
            </Label>
            <Label>
              <WowLabelText>
                <FormattedMessage id="last_name" />
              </WowLabelText>
              <StyledInput
                isDirtyAndValid={getIsDirtyAndValid('last_name')}
                {...register('last_name')}
                error={getMessage('last_name')}
                maxLength={64}
              />
              <ErrorMessage>{getMessage('last_name')}</ErrorMessage>
            </Label>

            <Label>
              <WowLabelText>
                <FormattedMessage id="email" />
              </WowLabelText>
              <StyledInput
                isDirtyAndValid={getIsDirtyAndValid('email')}
                {...register('email')}
                error={getMessage('email')}
                maxLength={255}
              />
              <ErrorMessage>{getMessage('email')}</ErrorMessage>
            </Label>

            <Label>
              <WowLabelText>
                <FormattedMessage id="phone" />
              </WowLabelText>
              <StyledInput
                isDirtyAndValid={getIsDirtyAndValid('phone')}
                {...register('phone')}
                maxLength={15}
              />
            </Label>

            <Label>
              <WowLabelText>
                <FormattedMessage id="position" />
              </WowLabelText>
              <StyledInput {...register('job_position')} maxLength={64} />
            </Label>

            <Label>
              <WowLabelText>
                <FormattedMessage id="role" />
              </WowLabelText>
              <Controller
                name="role"
                control={control}
                render={({ field }) => {
                  return (
                    <Dropdown
                      {...field}
                      size="responsive"
                      options={roleOptions}
                      onChange={(value) => field.onChange(value.value)}
                    />
                  );
                }}
              />
            </Label>
          </InputsWrapper>

          <ModalFooter>
            <ModalFooterButtonWrapper>
              <StyledButton
                type="submit"
                size="small"
                disabled={!canSubmit || isLoading}
              >
                <FormattedMessage id="save" defaultMessage="Save" />
              </StyledButton>
              {isLoading ? <LoadingSpinner size="small" /> : null}
            </ModalFooterButtonWrapper>

            <Space width="20" />

            <ModalFooterButtonWrapper>
              <StyledButton
                color="red"
                appearance="text"
                size="small"
                onClick={toggleModal}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </StyledButton>
            </ModalFooterButtonWrapper>

            <Space width="20" />

            <ModalFooterButtonWrapper>
              <StyledButton
                color={isArchived ? 'green' : 'red'}
                appearance={isArchived ? 'contained' : 'outlined'}
                size="small"
                type="button"
                onClick={handleStatusChange}
              >
                <FormattedMessage id={isArchived ? 'activate' : 'archive'} />
              </StyledButton>
            </ModalFooterButtonWrapper>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditUserModal;
