import { FormattedMessage } from 'react-intl';
import { darkerGray, purple500 } from '../../../../config/colors';
import { WowColorizeText, WowIcon } from '../../../WowBasicElements';
import { InfoMessageWrapper } from '../../styles';

const InfoMessage = ({ messageKey, children }) => {
  return (
    <InfoMessageWrapper>
      <WowIcon
        className="icon icon-important-round"
        color={purple500}
        size="20"
        display="flex"
      />
      {children ? (
        <>{children}</>
      ) : (
        <WowColorizeText size="14" color={darkerGray}>
          <FormattedMessage id={messageKey} />
        </WowColorizeText>
      )}
    </InfoMessageWrapper>
  );
};

export default InfoMessage;
