import PropTypes from 'prop-types';
import {
  WowColorizeText,
  WowIcon,
} from '../../../../../../../components_new/WowBasicElements';
import { darkerGray, gray } from '../../../../../../../config/colors';
import {
  OrganizationItemContainer,
  OrganizationInfo,
  RightItems,
  UnreadCount,
} from '../styles';
import { FormattedMessage } from 'react-intl';
import { ID_KEYS } from '../../../../constants';
import TextOverflowWithTooltip from '../../../../../../../components_new/TextOverflowWithTooltip';

const OrganizationItem = (props) => {
  const {
    isCurrent,
    isMain,
    isLast,
    isClickable,
    item,
    onClick,
    collabFeatureActive,
  } = props;

  const { name, total_unread_notifications: totalNotifications } = item;

  // const displaySettingsIcon = isCurrent;
  const displayNotificationIcon = !isCurrent;

  const displayText = (isCurrent || isMain) && collabFeatureActive;
  const noNotifications = totalNotifications === 0;
  return (
    <OrganizationItemContainer
      $isCurrent={isCurrent && collabFeatureActive}
      $isClickable={isClickable}
      $isLast={isLast}
      onClick={() => onClick(item.id)}
      data-testid={ID_KEYS.organisationWrapper}
    >
      <OrganizationInfo $isCurrent={isCurrent && collabFeatureActive}>
        <TextOverflowWithTooltip tooltipContent={name}>
          {(ref) => (
            <span className="name" ref={ref}>
              {name}
            </span>
          )}
        </TextOverflowWithTooltip>
        {/* <div className="role">Consultant</div> */}
      </OrganizationInfo>
      <RightItems $isCurrent={isCurrent && collabFeatureActive}>
        {displayText && (
          <div className="text">
            {isCurrent ? (
              <FormattedMessage id="current" />
            ) : isMain ? (
              <FormattedMessage id="main" />
            ) : (
              ''
            )}
          </div>
        )}
        {/* {displaySettingsIcon && (
          <WowIcon
            className="icon icon-settings"
            color={collabFeatureActive ? 'white': filtersAdvancedGray}
            clickable
          />
        )} */}
        {displayNotificationIcon ? (
          <>
            <WowIcon
              className="icon icon-activity"
              color={gray}
              spaceRight={noNotifications ? '5' : '15'}
              spaceLeft="10"
              data-testid={ID_KEYS.notificationIcon}
            />
            {noNotifications ? (
              <WowColorizeText color={darkerGray} size="14">
                {totalNotifications}
              </WowColorizeText>
            ) : (
              <UnreadCount>{totalNotifications}</UnreadCount>
            )}
          </>
        ) : null}
      </RightItems>
    </OrganizationItemContainer>
  );
};

OrganizationItem.propTypes = {
  isCurrent: PropTypes.bool,
  isMain: PropTypes.bool,
  isLast: PropTypes.bool,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OrganizationItem;
