import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { LEFT_ARROW_KEY, RIGHT_ARROW_KEY } from '../../../config/config';
import { RenderIf } from '../../render-if/render-if.component';
import { MediaRenderer } from '../media-renderer/media-renderer';
import {
  transparentBlack,
  red,
  confirmGreen,
  white,
  galleryGray,
} from '../../../config/colors';
import { downloadImage } from '../../../utils/utility';
import { breakpoint } from '../../../styles/styledComponents/media-breakpoints';

const Arrow = styled.div`
  background-color: ${transparentBlack};
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 36px);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  i {
    display: flex;
    &:before {
      color: ${white};
      font-size: 26px;
      opacity: 1;
    }
  }
  @media print {
    display: none !important;
  }
`;

const LeftArrow = styled(Arrow)`
  left: 20px;
  i {
    margin-right: 2px;
  }
`;

const RightArrow = styled(Arrow)`
  right: 20px;
  i {
    margin-left: 2px;
  }
`;

const IconWrapper = styled.div`
  background-color: ${transparentBlack};
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: none;
  i {
    font-size: 22px;
    color: ${(props) => props.color};
  }
  @media print {
    display: none !important;
  }
`;

const ListViewIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${transparentBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
  i {
    display: flex;
    &:before {
      color: ${white};
      font-size: 18px;
    }
  }
`;
const FileNameWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: none;
  align-items: center;
`;

const GalleryItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  > img,
  > video,
  > audio {
    border-radius: 8px;
    width: auto;
    height: auto;
    max-height: calc(100% - 75px);
  }
  &:hover ${IconWrapper} {
    display: flex;
  }
  &:hover ${FileNameWrapper} {
    display: flex;
  }
  &:hover ${Arrow} {
    display: flex;
  }

  @media only screen and ${breakpoint.xs} {
    ${IconWrapper} {
      display: flex;
    }
    ${FileNameWrapper} {
      display: flex;
    }
    ${Arrow} {
      display: flex;
    }
  }
`;

const GalleryItemToolbar = styled.div`
  display: flex;
  position: absolute;
  left: 20px;
  top: 20px;
`;

const MediaWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CurrentFileNumber = styled.span`
  color: ${white};
  font-size: 14px;
  margin: 0 10px;
`;

const FileName = styled.span`
  color: ${galleryGray};
  font-size: 14px;
`;

const GallerySingleItem = (props) => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  });

  const handleKeyPress = (e) => {
    if (e.keyCode === LEFT_ARROW_KEY) {
      if (props.canNavigatePrev) {
        props.navigatePrev();
      }
    } else if (e.keyCode === RIGHT_ARROW_KEY) {
      if (props.canNavigateNext) {
        props.navigateNext();
      }
    }
  };

  const handleDeleteItem = () => {
    if (props.onRemoveItem) {
      props.onRemoveItem();
    }
  };

  const handleDownloadItem = () => {
    downloadImage(props.downloadUrl, props.name);
  };

  const printDocument = () => {
    if (!props.mimeType.includes('pdf')) {
      window.print();

      return;
    }

    window.open(props.url, 'PRINT', 'height=400,width=600');
  };

  return (
    <GalleryItem>
      <RenderIf if={props.canNavigatePrev}>
        <LeftArrow onClick={props.navigatePrev}>
          <i className="icon icon-arrowleft-02" />
        </LeftArrow>
      </RenderIf>
      <RenderIf if={props.canNavigateNext}>
        <RightArrow onClick={props.navigateNext}>
          <i className="icon icon-arrowright-02" />
        </RightArrow>
      </RenderIf>
      <GalleryItemToolbar>
        <IconWrapper onClick={handleDownloadItem} color={confirmGreen}>
          <i className="icon icon-download" />
        </IconWrapper>
        <IconWrapper onClick={printDocument} color={confirmGreen}>
          <i className="icon icon-print-1" />
        </IconWrapper>
        <RenderIf if={props.canRemove}>
          <IconWrapper onClick={handleDeleteItem} color={red}>
            <i className="icon icon-trash" />
          </IconWrapper>
        </RenderIf>
      </GalleryItemToolbar>
      <MediaWrapper>
        <MediaRenderer
          mimeType={props.mimeType}
          url={props.url}
          description={props.description}
          fullSize
        />
      </MediaWrapper>

      <FileNameWrapper>
        <ListViewIcon onClick={props.handleListViewToggle}>
          <i className="icon icon-categories" />
        </ListViewIcon>

        <CurrentFileNumber>
          {props.index + 1}/{props.attachmentCount}
        </CurrentFileNumber>

        <FileName>{props.name}</FileName>
      </FileNameWrapper>
    </GalleryItem>
  );
};

GallerySingleItem.propTypes = {
  url: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  description: PropTypes.string,
  canRemove: PropTypes.bool,
  onRemoveItem: PropTypes.func,
  user: PropTypes.shape({
    profileImageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}.isRequired;

export default GallerySingleItem;
