const useRawWindowLocation = () => {
  const getPathName = () => {
    return window?.location?.pathname;
  };

  return {
    getPathName,
  };
};

export default useRawWindowLocation;
