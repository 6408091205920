import dayjs from 'dayjs';
import { SUPPORTED_PARAMS_ADVANCED_FILTER } from '../IssuesV2/constants';
import Daily from './components/Daily';
import Weekly from './components/Weekly';
import Yearly from './components/Yearly';

export const CALENDAR_VIEW_TYPES = {
  dailyUsers: 'dailyUsers',
  dailyWorkspaces: 'dailyWorkspaces',
  weeklyUsers: 'weeklyUsers',
  weeklyWorkspaces: 'weeklyWorkspaces',
  yearly: 'yearly',
};

export const CALENDAR_VIEW = {
  [CALENDAR_VIEW_TYPES.dailyUsers]: (props) => <Daily {...props} />,
  [CALENDAR_VIEW_TYPES.dailyWorkspaces]: (props) => <Daily {...props} />,
  [CALENDAR_VIEW_TYPES.weeklyUsers]: (props) => <Weekly {...props} />,
  [CALENDAR_VIEW_TYPES.weeklyWorkspaces]: (props) => <Weekly {...props} />,
  [CALENDAR_VIEW_TYPES.yearly]: (props) => <Yearly {...props} />,
};

export const CALENDAR_PERSIST_KEYS = {
  CALENDAR: 'calendar',
  YEARLY: 'yearly-calendar',
};

export const CALENDAR_FILTERS_PERSIST_KEY = {
  [CALENDAR_VIEW_TYPES.dailyUsers]: CALENDAR_PERSIST_KEYS.CALENDAR,
  [CALENDAR_VIEW_TYPES.dailyWorkspaces]: CALENDAR_PERSIST_KEYS.CALENDAR,
  [CALENDAR_VIEW_TYPES.weeklyUsers]: CALENDAR_PERSIST_KEYS.CALENDAR,
  [CALENDAR_VIEW_TYPES.weeklyWorkspaces]: CALENDAR_PERSIST_KEYS.CALENDAR,
  [CALENDAR_VIEW_TYPES.yearly]: CALENDAR_PERSIST_KEYS.YEARLY,
};

export const SUPPORTED_PARAMS = {
  start_date: {
    defaultValue: dayjs().format('YYYY-MM-DD'),
  },
  end_date: {
    defaultValue: dayjs().format('YYYY-MM-DD'),
  },
  calendar_search: {
    defaultValue: ``,
  },
  ...SUPPORTED_PARAMS_ADVANCED_FILTER,
};

// used to map keys from filter to adequate query params
export const YEARLY_QUERY_PARAMS_MAP = {
  assignees: 'assignees',
  replacements: 'replacements_ids',
  watchers: 'watchers_ids',
  categories: 'categories_ids',
  workspaces: 'workspaces_ids',
  assets: 'assets_ids',
  equipment: 'equipments_ids',
};

export const SUPPORTED_PARAMS_ADVANCED_FILTER_YEARLY = {
  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'title', 'upcoming'],
  },
  sort_direction: {
    defaultValue: 'desc',
    allowedValues: ['asc', 'desc'],
  },
  status: {
    defaultValue: null,
    allowedValues: null,
  },
  users_any_role: {
    defaultValue: null,
    allowedValues: null,
  },
  collabs_any_role: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  priority_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspaces: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_group_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_system_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  categories: {
    defaultValue: null,
    allowedValues: null,
  },
  filter_by_job_positions: {
    defaultValue: [],
    allowedValues: null,
  },
  equipments_ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const SUPPORTED_PARAMS_YEARLY = {
  year: {
    defaultValue: `${dayjs().year()}`,
  },
  calendar_search: {
    defaultValue: ``,
  },
  sort_by: {
    defaultValue: 'title',
    allowedValues: ['title', 'created', 'updated', 'upcoming'],
  },
  sort_direction: {
    defaultValue: 'desc',
    allowedValues: ['asc', 'desc'],
  },
  ...SUPPORTED_PARAMS_ADVANCED_FILTER_YEARLY,
};

export const INIT_QUERY_PARAMS = {
  start_date: dayjs().format('YYYY-MM-DD'),
  end_date: dayjs().format('YYYY-MM-DD'),
  paginate_by: 25,
  page: 1,
};

export const INIT_QUERY_PARAMS_YEARLY = {
  year: `${dayjs().year()}`,
  sort_by: 'title',
  sort_direction: 'desc',
  paginate_by: 50,
  page: 1,
};
export const CALENDAR_VIEW_ANALYTICS_KEY = {
  [CALENDAR_VIEW_TYPES.dailyUsers]: 'wf__calendar_day_user',
  [CALENDAR_VIEW_TYPES.dailyWorkspaces]: 'wf__calendar_day_workspace',
  [CALENDAR_VIEW_TYPES.weeklyUsers]: 'wf__calendar_week_user',
  [CALENDAR_VIEW_TYPES.weeklyWorkspaces]: 'wf__calendar_week_workspace',
  [CALENDAR_VIEW_TYPES.yearly]: 'wf__calendar_year',
};

export const BLACKLISTED_FILTERS = {
  [CALENDAR_VIEW_TYPES.dailyUsers]: ['users'],
  [CALENDAR_VIEW_TYPES.dailyWorkspaces]: ['workspaces'],
  [CALENDAR_VIEW_TYPES.weeklyUsers]: ['users'],
  [CALENDAR_VIEW_TYPES.weeklyWorkspaces]: ['workspaces'],
  [CALENDAR_VIEW_TYPES.yearly]: [],
};
