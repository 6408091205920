import { useEffect } from 'react';

import { useGetEquipmentTypesQuery } from '../service';
import { INFINITE_SCROLL_INITIAL_STATE, RTK_HOOK_CONFIG } from '../constants';

import useInfiniteScrollData from '../hooks/useInfiniteScrollData';

const useTypes = (skipQuery) => {
  const {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    id: 'types',
  });

  const { data: fetchedData, isLoading } = useGetEquipmentTypesQuery(
    { ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    isLoading,
    mergeStateItems,
  };
};

export default useTypes;
