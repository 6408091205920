export const SUPPORT_TYPES = {
  menu: 'menu',
  support: 'support',
  tutorials: 'tutorials',
  feedback: 'feedback',
};

export const SUPPORT_CONFIG = {
  [SUPPORT_TYPES.menu]: {
    logKey: 'wf__customer_support_menu_click',
  },
  [SUPPORT_TYPES.support]: {
    url: 'https://wowflow.freshdesk.com/support/home',
    logKey: 'wf__customer_support_menu_center_click',
  },
  [SUPPORT_TYPES.tutorials]: {
    url: 'https://www.youtube.com/@wowflow-software',
    logKey: 'wf__customer_support_menu_tutorials_click',
  },
  [SUPPORT_TYPES.feedback]: {
    url: 'https://wowflow.freshdesk.com/support/tickets/new',
    logKey: 'wf__customer_support_contact_click',
  },
};
