export const configPath = (state) => state.config;
export const flowConfigPath = (state) => configPath(state).flowConfig;
export const themePath = (state) => configPath(state).theme;
export const getClientFromConfig = (state) => configPath(state).client;
export const wsFormDropdownsDataSourcePath = (state) =>
  configPath(state).wsFormDropdownsDataSource;
export const getMediaMaxSize = (state) => configPath(state).media_max_size;
export const getIncludeEquipment = (state) =>
  configPath(state).includeEquipment;
export const getIncludeSchedules = (state) =>
  configPath(state).includeSchedules;
export const getIncludeAssets = (state) => configPath(state).includeAssets;
export const getIncludeCollab = (state) => configPath(state).includeCollab;
export const getIsEquipmentReturnStrict = (state) =>
  configPath(state).isEquipmentReturnStrict;

export const getManagerCanManageWorkspaces = (state) =>
  !!configPath(state).editWorkspace?.includes('manager');

export const getAvailablePdfNamesParams = (state, target) =>
  configPath(state).availablePdfNamesParams;

export const getConfigPersistedFilters = (state, target) =>
  configPath(state)?.filters?.[target];

export const getSessionDurations = (state) =>
  configPath(state).sessionDurations;

export const getOnlyAdminsCanSeeUsers = (state) =>
  configPath(state).onlyAdminsCanSeeUsers;

export const getOnboardingProgress = (state) =>
  configPath(state).onboardingProgress;
