import styled from 'styled-components/macro';
import { darkerGray, hardWhite, outline } from '../../config/colors';

export const ItemPreview = styled.div`
  height: 80px;
  padding: 10px 14px 10px 12px;
  display: flex;
  box-shadow: 0px 2px 10px #37465f26;
  border-radius: 4px;
  border-left: 4px solid
    ${({ borderColor }) => (borderColor ? borderColor : outline)};

  &:hover {
    background-color: ${hardWhite};
    cursor: pointer;
  }
`;

export const ItemPreviewImage = styled.img`
  width: 85px;
  height: 60px;
  object-fit: cover;
  margin-right: 12px;
`;

export const ItemPreviewContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

export const ItemPreviewContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemPreviewContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemPreviewAvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ItemPreviewTitle = styled.span`
  color: ${darkerGray};
  font-size: 18px;
  font-weight: 600;
`;

export const ItemPreviewSubtitle = styled.span`
  color: ${darkerGray};
  font-size: 12px;
  font-weight: 400;
`;

export const ItemPreviewElement = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-size: 12px;
  color: ${({ color }) => (color ? color : darkerGray)};
  margin-right: 8px;
`;

export const ItemPreviewElementIcon = styled.i`
  margin-right: 4px;
`;

export const ItemPreviewAvatar = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
  object-fit: cover;
  align-self: end;
`;

export const ItemPreviewStatus = styled.span`
  font-size: 12px;
  color: ${({ color }) => (color ? color : darkerGray)};
`;
