export const FILTER_START_DATE_KEY = 'date_events_from';
export const FILTER_END_DATE_KEY = 'date_events_to';
export const FILTER_DATE_TYPES = 'date_events_types';

export const INPUTS = {
  from: 'from',
  to: 'to',
};

export const ISSUE_TYPES_OPTIONS = [
  {
    id: 1,
    value: 'created',
    labelKey: 'creation',
  },
  {
    id: 2,
    value: 'updated',
    labelKey: 'updated',
  },
  {
    id: 3,
    value: 'start_date',
    labelKey: 'start',
  },
  {
    id: 4,
    value: 'due_date',
    labelKey: 'due_new',
  },
];
