import IssueCardInfo from './components/IssueCardInfo';
import WorkspaceCardInfo from './components/WorkspaceCardInfo';
import IssueBoxCardInfo from './components/IssueBoxCardInfo';

export const CALENDAR_CARD_TYPES = {
  ISSUE: {
    key: 'ISSUE',
    component: (props) => <IssueCardInfo {...props} />,
  },
  WORKSPACE: {
    key: 'WORKSPACE',
    component: (props) => <WorkspaceCardInfo {...props} />,
  },
  ISSUE_BOX: {
    key: 'ISSUE_BOX',
    component: (props) => <IssueBoxCardInfo {...props} />,
  },
};
