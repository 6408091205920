import { Wrapper } from './styles';

const IssueBoxCardInfo = ({ item = {} }) => {
  return (
    <Wrapper>
      <div data-testid="weeklyCalendarGroupItemCardCounter">
        +{item?.issues?.length ?? 0}
      </div>
    </Wrapper>
  );
};

export default IssueBoxCardInfo;
