import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../../../../config/constants';

export const publicEquipmentApi = createApi({
  reducerPath: 'publicEquipmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/public`,
  }),
  tagTypes: ['PublicEquipment', 'PublicEquipmentGroup'],
  endpoints: (builder) => ({
    getPublicEquipmentOrGroup: builder.query({
      query: (params = {}) => {
        const url = params.isGroup ? `equipment/group` : 'equipment';
        return `${url}/${params.id}`;
      },
      providesTags: (result, error, params) => [
        {
          type: params?.isGroup ? 'PublicEquipmentGroup' : 'PublicEquipment',
          id: params?.id,
        },
      ],
    }),
    editPublicEquipmentOrGroup: builder.mutation({
      query(payload) {
        const { id, isGroup, ...rest } = payload;
        const url = isGroup
          ? `equipment/group/${id}/found`
          : `equipment/${id}/found`;
        return {
          url,
          method: 'POST',
          body: rest,
        };
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: arg?.isGroup ? 'PublicEquipmentGroup' : 'PublicEquipment',
          id: arg?.id,
        },
      ],
    }),
  }),
});

export const {
  useGetPublicEquipmentOrGroupQuery,
  useEditPublicEquipmentOrGroupMutation,
} = publicEquipmentApi;
