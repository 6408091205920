import { FormattedMessage } from 'react-intl';

export const INPUT_MAX_LENGTH = 255;

export const MODE = {
  single: 'single',
  multi: 'multi',
};

export const TABS = [
  {
    value: 'users',
    label: <FormattedMessage id="employee" />,
  },
  {
    value: 'collaborators',
    label: <FormattedMessage id="externals" />,
  },
];

export const MODAL_STEPS = {
  list: 'list',
  addUser: 'addUser',
  addCollaborator: 'addCollaborator',
  addToWorkspace: 'addToWorkspace',
  addSuccess: 'addSuccess',
};

export const DEFAULT_USER = {
  id: 1,
  isActive: true,
  fields: [
    {
      fieldId: 1,
      name: 'email',
      value: '',
      labelKey: 'email',
      isOptional: false,
    },
    {
      fieldId: 2,
      name: 'first_name',
      value: '',
      labelKey: 'first_name',
      isOptional: true,
    },
    {
      fieldId: 3,
      name: 'last_name',
      value: '',
      labelKey: 'last_name',
      isOptional: true,
    },
  ],
};

export const DEFAULT_COLLABORATOR = {
  id: 1,
  isActive: true,
  fields: [
    {
      fieldId: 1,
      name: 'email',
      value: '',
      labelKey: 'email',
      isOptional: false,
    },
    {
      fieldId: 2,
      name: 'company_alias',
      value: '',
      labelKey: 'company_name',
      isOptional: true,
    },
  ],
};

export const ERROR_MESSAGES_KEYS = {
  alreadyAdded: 'email_already_added',
  invalidFormat: 'invalid_format',
  existing: 'user_already_exists',
};

export const COLLABORATORS_PERMISSION_TYPE_VALUES = {
  full: 'full',
  limited: 'limited',
};

export const COLLABORATORS_PERMISSION_TYPE_ICONS = {
  full: 'icon-handshake-o',
  limited: 'icon-mail',
};

export const COLLABORATORS_PERMISSION_TYPE_OPTIONS = [
  {
    id: 1,
    label: '',
    value: COLLABORATORS_PERMISSION_TYPE_VALUES.full,
    labelKey: 'full_access_recommended',
  },
  {
    id: 2,
    label: '',
    value: COLLABORATORS_PERMISSION_TYPE_VALUES.limited,
    labelKey: 'limited_access',
  },
];

export const COLLABORATORS_PERMISSION_TYPE_INFO_KEYS = {
  [COLLABORATORS_PERMISSION_TYPE_VALUES.full]: 'full_access_details',
  [COLLABORATORS_PERMISSION_TYPE_VALUES.limited]: 'limited_access_details',
};

export const ADD_TO_WORKSPACE_CONFIG = {
  [MODE.single]: {
    titleKey: 'add_user_to_workspace',
  },
  [MODE.multi]: {
    titleKey: 'add_users_to_workspace',
  },
};

export const ADD_TO_WORKSPACE_INFO_MESSAGE_KEYS = {
  [MODE.single]: {
    messageKey: 'user_not_part_of_workspace',
    secondaryMessageKey: 'want_to_add_user_to_workspace',
  },
  [MODE.multi]: {
    messageKey: 'users_not_in_workspace',
    secondaryMessageKey: 'want_to_add_users_to_workspace',
  },
};
