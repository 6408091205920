import { DEFAULT_LANG } from '../config/config';

export const YU_LANGS = ['rs', 'hr', 'ba'];
export const YU_LANGS_WITH_ALIAS = [...YU_LANGS, 'bs', 'sr'];
export const YU = 'yu';

// Dictionaries
export const yuDict = {
  [YU]: '🇧🇦/🇷🇸/🇭🇷 Bosnian/Serbian/Croatian',
};

export const yuLanguageDict = {
  ba: '🇧🇦 Bosnian',
  rs: '🇷🇸 Serbian',
  hr: '🇭🇷 Croatian',
};

export const easternSlavsLanguageDict = {
  ru: '🇷🇺 Russian',
  uk: '🇺🇦 Ukraian',
  it: '🇮🇹 Italian',
  hu: '🇭🇺 Hungarian',
  ro: '🇷🇴 Romanian',
};

export const languageDict = {
  de: '🇩🇪 German',
  en: '🇬🇧 English',
  pl: '🇵🇱 Polish',
  tr: '🇹🇷 Turkish',
};

// Reverse dictionaries
export const yuDictReverse = {
  '🇧🇦/🇷🇸/🇭🇷 Bosnian/Serbian/Croatian': YU,
};

export const yuLanguageDictReverse = {
  '🇧🇦 Bosnian': 'ba',
  '🇷🇸 Serbian': 'rs',
  '🇭🇷 Croatian': 'hr',
};

export const flags = {
  de: '🇩🇪',
  en: '🇬🇧',
  pl: '🇵🇱',
  tr: '🇹🇷',
  yu: '🇧🇦/🇷🇸/🇭🇷',
  uk: '🇺🇦',
  ru: '🇷🇺',
  it: '🇮🇹',
  hu: '🇭🇺',
  ro: '🇷🇴',
};

export const languageDictReverse = {
  '🇩🇪 German': 'de',
  '🇬🇧 English': 'en',
  '🇵🇱 Polish': 'pl',
  '🇹🇷 Turkish': 'tr',
};

export const appLanguageDict = {
  ...languageDict,
  ...yuLanguageDict,
};

export const appLanguageDictReverse = {
  ...languageDictReverse,
  ...yuLanguageDictReverse,
};

export const settingsLanguageDict = {
  ...languageDict,
  ...easternSlavsLanguageDict,
  ...yuDict,
};

export const settingsLanguageDictReverse = {
  ...languageDictReverse,
  ...yuDictReverse,
};

// Helpers

export const getLocaleForLanguage = (language) => {
  if (language === YU) {
    return 'bs';
  }

  if (language === 'rs') {
    return 'sr';
  }

  if (language === 'ba') {
    return 'bs';
  }

  return language;
};

const supportedLanguages = [
  ...Object.keys(settingsLanguageDict),
  ...YU_LANGS_WITH_ALIAS,
];

export const verifyLanguage = (_language) => {
  if (!_language) return null;

  const parsedLanguage = _language.split('-');

  const language =
    parsedLanguage && parsedLanguage[0] ? parsedLanguage[0] : DEFAULT_LANG;

  return supportedLanguages.find((l) => l === language);
};
