import { createSlice } from '@reduxjs/toolkit';
import { INIT_ADVANCED_FILTERS_DATA, INIT_QUERY_PARAMS } from './constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_GROUPS } from './Groups/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_GROUPS_SYSTEMS } from './Systems/constants';

const INIT_STATE = {
  filters: {
    assets: INIT_QUERY_PARAMS,
    groups: INIT_QUERY_PARAMS_GROUPS,
    systems: INIT_QUERY_PARAMS_GROUPS_SYSTEMS,
  },
  filtersData: {
    assets: {
      ...INIT_ADVANCED_FILTERS_DATA,
    },
    groups: {
      managers: [],
      workspaces: [],
    },
  },
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: INIT_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { target, filterData } = action.payload;
      state.filters = {
        ...state.filters,
        [target]: filterData,
      };
    },
    //TMP > adding data as well until we adjust on BE everything as discussed
    setFiltersData: (state, action) => {
      const { target, filterData } = action.payload;
      state.filtersData = {
        ...state.filtersData,
        [target]: {
          ...state.filtersData[target],
          ...filterData,
        },
      };
    },
    setIsAdvancedFilterOpen: (state, action) => {
      state.filters.assets.isAdvancedFilterOpen = action.payload;
      return state;
    },
  },
});

export const { setFilters, setFiltersData, setIsAdvancedFilterOpen } =
  assetsSlice.actions;

export default assetsSlice.reducer;
