import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '../styles';
import { InfoContainer } from './styles';
import { InfoSingleItemWrapper, InfoItemName, InfoValue } from '../styles';

const UserAdditionalInfo = ({ user }) => {
  const intl = useIntl();

  return (
    <div>
      <Heading>
        <FormattedMessage
          id="profile_details"
          defaultMessage="Profile Details"
        />
      </Heading>

      <InfoContainer>
        <InfoSingleItemWrapper>
          <InfoItemName>
            <FormattedMessage id="company" defaultMessage="Company" />
          </InfoItemName>
          <InfoValue width="auto">{user.client.name}</InfoValue>
        </InfoSingleItemWrapper>

        <InfoSingleItemWrapper>
          <InfoItemName>
            <FormattedMessage id="position" defaultMessage="Position" />
          </InfoItemName>
          <InfoValue>
            {user.job_position ??
              intl.formatMessage({
                id: 'not_specified',
                defaultMessage: 'Not Specified',
              })}
          </InfoValue>
        </InfoSingleItemWrapper>

        <InfoSingleItemWrapper>
          <InfoItemName>
            <FormattedMessage id="role" defaultMessage="Role" />
          </InfoItemName>
          <InfoValue>
            {intl.formatMessage({
              id: user.role ? user.role : 'user',
              defaultMessage: 'User',
            })}
          </InfoValue>
        </InfoSingleItemWrapper>

        <InfoSingleItemWrapper>
          <InfoItemName>
            <FormattedMessage id="phone" defaultMessage="Phone" />
          </InfoItemName>
          <InfoValue>
            {user.phone && user.phone !== 'null'
              ? user.phone
              : intl.formatMessage({
                  id: 'not_specified',
                  defaultMessage: 'Not Specified',
                })}
          </InfoValue>
        </InfoSingleItemWrapper>
      </InfoContainer>
    </div>
  );
};

export default UserAdditionalInfo;
