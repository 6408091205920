import { FormattedMessage } from 'react-intl';
import happyIcon from './assets/happy.png';
import indifferentIcon from './assets/indifferent.png';
import lovelyIcon from './assets/lovely.png';
import neutralIcon from './assets/neutral.png';
import sadIcon from './assets/sad.png';
import Space from '../../../Space';
import { COLORS } from './constants';
import { WowCheckbox } from '../../../../components/wow-checkbox';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import Button from '../../../Button';
import { ButtonWrappers } from '../../styles';
import {
  SelectedBox,
  Box,
  CheckmarkBox,
  IconBox,
  AlignItemBox,
  LabelBox,
} from './styles';

export const SmileyScale = (props) => {
  const {
    value,
    onChange = () => {},
    next = () => {},
    disableNext = false,
    isLast = false,
    isProcessing,
    labels = {
      left: 'not_at_all',
      right: 'yes_a_lot',
    },
  } = props;

  const iconLookup = {
    1: sadIcon,
    2: indifferentIcon,
    3: neutralIcon,
    4: lovelyIcon,
    5: happyIcon,
  };

  const colorsLookup = {
    1: COLORS.red,
    2: COLORS.orange,
    3: COLORS.yellow,
    4: COLORS.lime,
    5: COLORS.green,
  };

  const items = [];
  for (let i = 1; i <= 5; i++) {
    if (value === i) {
      items.push(
        <SelectedBox key={i} onClick={() => onChange(i)}>
          <CheckmarkBox>
            <WowCheckbox
              borderRadius={13}
              checked={true}
              iconPadding="1.2px"
              shadowColor="#fff"
              shadowScale="1.23"
              onChange={() => {}}
            />
          </CheckmarkBox>
          <IconBox>
            <img src={iconLookup[i]} alt={iconLookup[i]} />
          </IconBox>
        </SelectedBox>
      );
    } else {
      items.push(
        <Box key={i} color={colorsLookup[i]} onClick={() => onChange(i)}>
          <IconBox>
            <img src={iconLookup[i]} alt={iconLookup[i]} />
          </IconBox>
        </Box>
      );
    }
  }

  return (
    <>
      <Space height="30" />
      <AlignItemBox>{items}</AlignItemBox>
      <Space height="18" />
      <LabelBox>
        <div>
          <FormattedMessage id={labels.left} />
        </div>
        <div>
          <FormattedMessage id={labels.right} />
        </div>
      </LabelBox>
      <Space height="40" />
      <ButtonWrappers>
        <Button
          color="green"
          appearance="contained"
          size="small"
          style={{ width: 170 }}
          onClick={next}
          disabled={disableNext || isProcessing}
        >
          {isLast ? (
            <FormattedMessage id={'complete'} />
          ) : (
            <FormattedMessage id={'next'} />
          )}
          {isProcessing && <LoadingSpinner />}
        </Button>
      </ButtonWrappers>
      <Space height="20" />
    </>
  );
};

export default SmileyScale;
