import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Modal, { SIZE } from '../../components_new/Modal';
import SmileyScale from './surveyInputTypes/SmileyScale';
import TenScale from './surveyInputTypes/TenScale';
import TextArea from './surveyInputTypes/TextArea';
import Start from './Start';
import Finish from './Finish';
import { QUESTIONS, INPUT_TYPES } from './constants';

export const Survey = ({
  isOpen,
  handleClose = () => {},
  handleAction = () => {},
  questions = [],
}) => {
  const [isProcessing, setIsProcessing] = useState();

  const [surveyState, setSurveyState] = useState({
    currentQuestionIndex: -1,
    answers: [],
    isDone: false,
  });

  const _handleAction = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    const mappedData = {};
    surveyState.answers.forEach((answer, index) => {
      if (questions[index])
        mappedData[questions[index]] =
          answer !== undefined && answer !== null ? String(answer) : '';
    });
    const response = await handleAction(mappedData);
    if (response) setIsProcessing(false);
    if (response?.data?.success)
      setSurveyState((prevState) => ({ ...prevState, isDone: true }));
  };

  const handleChange = (value) => {
    setSurveyState((prevState) => {
      const newState = { ...prevState };
      if (
        typeof newState.answers[newState.currentQuestionIndex] !== undefined
      ) {
        newState.answers[newState.currentQuestionIndex] = value ? value : null;
      } else {
        newState.answers.push(value ? value : null);
      }
      return newState;
    });
  };

  const isNextDisabled = () => {
    if (
      !surveyState.answers[surveyState.currentQuestionIndex] &&
      QUESTIONS[questions[surveyState.currentQuestionIndex]] &&
      QUESTIONS[questions[surveyState.currentQuestionIndex]].required
    )
      return true;
    return false;
  };

  const isLast = () => {
    if (questions.length === surveyState.currentQuestionIndex + 1) return true;
    return false;
  };

  const handleNext = () => {
    if (isLast()) {
      _handleAction();
    } else {
      setSurveyState((prevState) => ({
        ...prevState,
        currentQuestionIndex: ++prevState.currentQuestionIndex,
      }));
    }
  };

  const renderInputType = (inputType) => {
    if (
      QUESTIONS[inputType] &&
      QUESTIONS[inputType].inputType === INPUT_TYPES.SMILEY_SCALE
    ) {
      return (
        <SmileyScale
          value={surveyState.answers[surveyState.currentQuestionIndex]}
          onChange={handleChange}
          next={handleNext}
          disableNext={isNextDisabled()}
          isLast={isLast()}
          isProcessing={isProcessing}
          labels={QUESTIONS[inputType].labels}
        />
      );
    } else if (
      QUESTIONS[inputType] &&
      QUESTIONS[inputType].inputType === INPUT_TYPES.TEN_SCALE
    ) {
      return (
        <TenScale
          value={surveyState.answers[surveyState.currentQuestionIndex]}
          onChange={handleChange}
          next={handleNext}
          disableNext={isNextDisabled()}
          isLast={isLast()}
          isProcessing={isProcessing}
        />
      );
    } else if (
      QUESTIONS[inputType] &&
      QUESTIONS[inputType].inputType === INPUT_TYPES.TEXTAREA
    ) {
      return (
        <TextArea
          value={surveyState.answers[surveyState.currentQuestionIndex]}
          onChange={handleChange}
          next={handleNext}
          disableNext={isNextDisabled()}
          isLast={isLast()}
          isProcessing={isProcessing}
        />
      );
    }
    return <></>;
  };

  let modalTitleId;
  if (surveyState.currentQuestionIndex === -1) {
    modalTitleId = 'help_improve_wowflow';
  } else {
    modalTitleId =
      QUESTIONS[questions[surveyState.currentQuestionIndex]] &&
      QUESTIONS[questions[surveyState.currentQuestionIndex]].questionKey
        ? QUESTIONS[questions[surveyState.currentQuestionIndex]].questionKey
        : '';
  }

  if (surveyState.isDone) modalTitleId = null;

  return (
    <Modal
      title={modalTitleId ? <FormattedMessage id={modalTitleId} /> : ''}
      isOpen={isOpen}
      onClose={handleClose}
      size={SIZE.medium}
      centerTile={true}
      hideCloseIcon={true}
      centerTitlePadding="0 40px"
    >
      <>
        {surveyState.currentQuestionIndex === -1 ? (
          <Start
            enterButtonAction={handleNext}
            remindButtonAction={handleClose}
          />
        ) : surveyState.isDone ? (
          <Finish action={handleClose} />
        ) : (
          renderInputType(questions[surveyState.currentQuestionIndex])
        )}
      </>
    </Modal>
  );
};

export default Survey;
