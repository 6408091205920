import { actionStrings, attachmentState } from './actions';
import { actionStrings as authActionStrings } from '../auth/actions';
import { fromJS } from 'immutable';
import { getGalleryId } from './utils';
import { sortAttachments } from './selectors';

const adjustAttachmentIndex = (att) => {
  if (att.index >= 0) {
    att.index = att.index + 1;
  }

  return att;
};
const mapUndoChanges = (att) => {
  att.state = att.originalState;
  att.description = att.originalDescription;
  att.index = att.originalIndex;

  return att;
};
const mapConfirmChanges = (att) => {
  if (att.description !== att.originalDescription) {
    att.originalDescription = att.description;
    att.state =
      att.state !== attachmentState.NEW ? attachmentState.MODIFIED : att.state;
  }

  // User added and confirmed image
  if (att.state === attachmentState.NEW) {
    att.originalState = att.state;
  }

  att.originalIndex = att.index;

  return att;
};

const galleryState = fromJS({
  selectedAttachmentIndex: null,
  attachments: [],
  issue_id: null,
});

const initState = fromJS({
  gallery_default: galleryState,
});

export const reducer = (state = initState, action) => {
  const galleryId = getGalleryId(action.payload ? action.payload.hostId : null);

  switch (action.type) {
    case actionStrings.ADD_ATTACHMENTS: {
      const indexSelected = state.getIn([galleryId, 'selectedAttachmentIndex']);

      state = state.set(galleryId, galleryState);

      state = state.setIn(
        [galleryId, 'selectedAttachmentIndex'],
        indexSelected
      );
      state = state.setIn([galleryId, 'issue_id'], action.payload.hostId);

      for (let i = 0; i < action.payload.attachments.length; i++) {
        state = state.setIn(
          [galleryId, 'attachments'],
          state
            .getIn([galleryId, 'attachments'])
            .insert(0, action.payload.attachments[i])
        );
      }

      return state;
    }
    case actionStrings.RESET_ATTACHMENTS: {
      return state.setIn([galleryId, 'attachments'], fromJS([]));
    }
    case actionStrings.ADD_ATTACHMENT:
      return state
        .setIn(
          [galleryId, 'attachments'],
          state.getIn([galleryId, 'attachments']).map(adjustAttachmentIndex)
        )
        .updateIn([galleryId, 'attachments'], (att) =>
          att.concat(action.payload.attachment)
        );
    case actionStrings.SELECT_ATTACHMENT:
      return state.setIn(
        [galleryId, 'selectedAttachmentIndex'],
        action.payload.attachment.index
      );
    case actionStrings.REMOVE_ATTACHMENT: {
      // Decrease image index for all indexes higher
      // than removed and set removed attachment status to deleted
      const indexToDelete = state
        .getIn([galleryId, 'attachments'])
        .findIndex((x) => x.index === action.payload.index);

      const newAttachments = state
        .getIn([galleryId, 'attachments'])
        .map((att) => {
          if (att.index > action.payload.index) {
            att.index = att.index - 1;
          } else if (att.index === action.payload.index) {
            att.index = -1;
          }

          return att;
        });

      return state
        .setIn([galleryId, 'attachments'], newAttachments)
        .setIn(
          [galleryId, 'attachments', indexToDelete, 'state'],
          attachmentState.DELETED
        );
    }
    case actionStrings.CHANGE_DESCRIPTION: {
      const indexToUpdate = state
        .getIn([galleryId, 'attachments'])
        .findIndex((x) => x.index === action.payload.index);

      state = state.setIn(
        [galleryId, 'attachments', indexToUpdate, 'description'],
        action.payload.description
      );

      if (
        state.getIn([galleryId, 'attachments', indexToUpdate, 'state']) !==
        attachmentState.NEW
      ) {
        state = state.setIn(
          [galleryId, 'attachments', indexToUpdate, 'state'],
          attachmentState.MODIFIED
        );
      }

      return state;
    }
    case actionStrings.CHANGE_DEADLINE: {
      const indexToUpdate = state
        .getIn([galleryId, 'attachments'])
        .findIndex((x) => x.index === action.payload.index);

      state = state.setIn(
        [galleryId, 'attachments', indexToUpdate, 'due_date'],
        action.payload.date
      );

      if (
        state.getIn([galleryId, 'attachments', indexToUpdate, 'state']) !==
        attachmentState.NEW
      ) {
        state = state.setIn(
          [galleryId, 'attachments', indexToUpdate, 'state'],
          attachmentState.MODIFIED
        );
      }

      return state;
    }
    case actionStrings.CHANGE_PRIORITY: {
      const indexToUpdate = state
        .getIn([galleryId, 'attachments'])
        .findIndex((x) => x.index === action.payload.index);

      state = state.setIn(
        [galleryId, 'attachments', indexToUpdate, 'priority_id'],
        action.payload.priorityId
      );

      if (
        state.getIn([galleryId, 'attachments', indexToUpdate, 'state']) !==
        attachmentState.NEW
      ) {
        state = state.setIn(
          [galleryId, 'attachments', indexToUpdate, 'state'],
          attachmentState.MODIFIED
        );
      }

      return state;
    }
    case actionStrings.UPDATE_THUMBNAIL_PRESENCE: {
      const indexToUpdate = state
        .getIn([galleryId, 'attachments'])
        .findIndex((x) => x.index === action.payload.index);

      return state.setIn(
        [galleryId, 'attachments', indexToUpdate, 'thumbnailPresent'],
        action.payload.thumbnailPresent
      );
    }
    case actionStrings.UNDO_CHANGES:
      return state.setIn(
        [galleryId, 'attachments'],
        state.getIn([galleryId, 'attachments']).map(mapUndoChanges)
      );
    case actionStrings.CONFIRM_CHANGES:
      return state.setIn(
        [galleryId, 'attachments'],
        state.getIn([galleryId, 'attachments']).map(mapConfirmChanges)
      );
    case actionStrings.CLEAR_GALLERY:
      if (action.payload.hostId) {
        return state.set(galleryId, galleryState);
      }

      return initState;
    case actionStrings.CHANGE_SORT: {
      const indexToUpdate = state
        .getIn([galleryId, 'attachments'])
        .findIndex((x) => x.index === action.payload.oldIndex);

      state = state.setIn(
        [galleryId, 'attachments', indexToUpdate, 'index'],
        action.payload.newIndex
      );

      const changedAttachmentId = state.getIn([
        galleryId,
        'attachments',
        indexToUpdate,
        'id',
      ]);

      const newOrder = state
        .getIn([galleryId, 'attachments'])
        .map((att) => {
          if (
            action.payload.oldIndex <= action.payload.newIndex &&
            att.index >= action.payload.oldIndex &&
            att.index <= action.payload.newIndex &&
            att.id !== changedAttachmentId
          ) {
            att.index -= 1;
          } else if (
            action.payload.oldIndex > action.payload.newIndex &&
            att.index <= action.payload.oldIndex &&
            att.index >= action.payload.newIndex &&
            att.id !== changedAttachmentId
          ) {
            att.index += 1;
          }

          return att;
        })
        .sort(sortAttachments);

      return state.setIn([galleryId, 'attachments'], newOrder);
    }
    case actionStrings.ADD_LOCAL_IDS: {
      const attachmentsWithLocalIds = state
        .getIn([galleryId, 'attachments'])
        .map((att, key) => {
          att.id = key;

          return att;
        });

      return state.setIn([galleryId, 'attachments'], attachmentsWithLocalIds);
    }

    case authActionStrings.LOGOUT:
      return initState;
    default:
      return state;
  }
};
