import { categoriesFilterKeys } from '../views/internal/categories/categories.config';
import {
  DEFAULT_PAGE,
  QUERY_PARAM_CONST,
  PAGE_SIZE,
  sortOrder,
  sortFields,
} from '../config/config';
import {
  issuesFilterKeys,
  issuesPriorityFilterKeys,
  issuesSortFieldFilterKeys,
} from '../config/issueFilter';
import {
  workspacesSortDirectionFilterKeys,
  workspaceSortFieldFilterKeys,
  workspaceFilterKeys,
  workspaceSubSortFieldFilterKeys,
} from '../views/internal/LEGACY_workspaces/workspace-detail/workspace-detail-helper';

export const DEFAULT_PAGING = {
  page: DEFAULT_PAGE,
  pageSize: PAGE_SIZE,
};
export const DEFAULT_SORTING = {
  sortField: sortFields.NAME,
  sortOrder: sortOrder.DESC,
  // sortOrder: sortOrder.ASC, // TODO: Check if this messes up things, this is Bake's change for WS
};
export const DEFAULT_FILTERS = {
  ...DEFAULT_PAGING,
  ...DEFAULT_SORTING,
  searchTerm: '',
};

export const DEFAULT_PARAMS = {
  WORKSPACE: {
    ...DEFAULT_FILTERS,
    favouriteFilter: 'non-favourite',
    statusFilter: workspaceFilterKeys.OPTION_ALL,
    sortField: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_TITLE,
    sortOrder: workspacesSortDirectionFilterKeys.SORT_DIRECTION_OPTION_ASC,
  },
  CATEGORY: { statusFilter: categoriesFilterKeys.QF_OPTION_ACTIVE },
  USERS: { statusFilter: '' },
  FAV_WORKSPACE: {
    favouriteFilter: QUERY_PARAM_CONST.FAVORITE,
    page: DEFAULT_PAGE,
  },
  WS_ISSUE_LIST: {
    ...DEFAULT_FILTERS,
    statusFilter: issuesFilterKeys.QF_OPTION_ALL,
    sortField: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_UPDATED,
    sortOrder: 'desc',
    useServerSort: true,
  },
  SUB_WS_LIST: {
    searchTerm: '',
    statusFilter: workspaceFilterKeys.OPTION_ALL,
    sortOrder: workspacesSortDirectionFilterKeys.SORT_DIRECTION_OPTION_ASC,
    sortField: workspaceSubSortFieldFilterKeys.SORT_FIELD_OPTION_TITLE,
  },
  ISSUE_LIST: {
    ...DEFAULT_FILTERS,
    statusFilter: issuesFilterKeys.QF_OPTION_ALL_ACTIVE,
    priority: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_ALL,
    sortField: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_UPDATED,
    issueType: issuesFilterKeys.QF_OPTION_ALL_ACTIVE,
    useServerSort: true,
  },
  AUTOCOMPLETE_LIST: {
    searchTerm: '',
  },
};
