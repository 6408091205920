import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../redux/user/selectors';

// TODO: Change how we get loggedInUser when we do app refactor
// import { getUser } from '../redux/user/selectors';

// This has started to get really ugly
// TODO: Refactor
const useAuthorization = (data) => {
  const loggedInUser = useSelector(getUser);
  const [localState, setLocalState] = useState({
    disableEdit: false,
    isAdmin: false,
    isManager: false,
    _isGroupManager: false,
    _isManager: false,
  });

  const { disableEdit, isAdmin, isManager, _isGroupManager, _isManager } =
    localState;
  const canCreateEquipment = loggedInUser?.permissions?.global.includes(
    'can_create_equipment'
  );

  const handleAuthorization = useCallback(
    (data = {}) => {
      //admin
      const isAdmin = loggedInUser?.role === 'admin';

      //manager
      const managerIds = data?.manager_ids || data?.managers_ids || [];
      const isManager = managerIds.some((id) => id === loggedInUser.id);

      const _managerIds = data?.manager_ids || data?.managers_ids || [];
      const _isManager = _managerIds.some((id) => id === loggedInUser.id);

      const _groupManagerIds =
        data?.group_managers_ids || data?.group?.manager_ids || [];
      const _isGroupManager = _groupManagerIds.some(
        (id) => id === loggedInUser.id
      );

      //workspace manager
      const workspaceManagers = data?.workspace?.managers_ids || [];
      const typeWorkspaceManagers = data?.type?.workspace?.managers_ids || [];
      const workspaceManagerIds = workspaceManagers.concat(
        typeWorkspaceManagers.filter(
          (item) => workspaceManagers.indexOf(item) < 0
        )
      );
      const isWorkspaceManager = workspaceManagerIds.some(
        (id) => id === loggedInUser.id
      );

      //group manager
      const groupManagers =
        data?.workspace?.group_managers_ids || data?.group?.manager_ids || [];
      const typeWorkspaceGroupManagers =
        data?.type?.workspace?.group_managers_ids || [];
      const groupManagersOfWorkspaceOrTypeWorkspace = groupManagers.concat(
        typeWorkspaceGroupManagers.filter(
          (item) => groupManagers.indexOf(item) < 0
        )
      );
      const isGroupManagerOfWorkspaceOrTypeWorkspace =
        groupManagersOfWorkspaceOrTypeWorkspace.some(
          (id) => id === loggedInUser.id
        );

      const disableEditForUnauthorized = !(
        isAdmin ||
        isManager ||
        isWorkspaceManager ||
        isGroupManagerOfWorkspaceOrTypeWorkspace
      );

      setLocalState((prevState) => ({
        ...prevState,
        disableEdit:
          Object.keys(data).length > 0 &&
          (disableEditForUnauthorized || data?.archived),
        isAdmin,
        isManager:
          isManager ||
          isWorkspaceManager ||
          isGroupManagerOfWorkspaceOrTypeWorkspace,
        _isManager,
        _isGroupManager,
      }));
    },
    [loggedInUser]
  );

  useEffect(() => {
    handleAuthorization(data);
  }, [data, handleAuthorization]);

  return {
    loggedInUser,
    disableEdit,
    isAdmin,
    isManager,
    _isManager,
    _isGroupManager,
    permissions: loggedInUser?.permissions?.global,
    canCreateEquipment,
    isRegularUser: !isAdmin && !isManager,
  };
};

export default useAuthorization;
