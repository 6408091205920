import styled from 'styled-components/macro';
import { darkerGray } from '../../../../config/colors';

const ItemsBox = styled.div`
  display: flex;
  justify-content: space-around;
`;

const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${darkerGray};
  font-size: 0.75em;
`;

const ColorWrapper = styled.div`
  height: 50px;
`;

const PointLabel = styled.div`
  text-align: center;
  font-size: 0.9em;
  color: ${darkerGray};
`;

const ColorBox = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ color }) => color};
  cursor: pointer;
  border-radius: 50px;
`;

export { ItemsBox, LabelBox, ColorWrapper, PointLabel, ColorBox };
