// Imports a polyfill for canvast.toBlob
require('blueimp-canvas-to-blob');

export const JPEG = 'image/jpeg';
export const PNG = 'image/png';
export const GIF = 'image/gif';
export const WEBP = 'image/gif';
export const PDF = 'application/pdf';
export const MSG = 'application/vnd.ms-outlook';

// TODO: remove everything of this when old gallery is deleted
export const DOC = 'application/msword';
export const DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLS = 'application/vnd.ms-excel';
export const XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV = 'text/csv';
export const CSV2 = '.csv';
export const EML = 'message/rfc822';
export const MSG2 = '.msg';

export const compressableFileTypes = [JPEG, PNG, GIF, WEBP];
export const uncompressableFileTypes = [
  PDF,
  DOC,
  DOCX,
  XLS,
  XLSX,
  CSV,
  CSV2,
  EML,
  MSG,
  MSG2,
];

export const acceptedFileTypes = [
  ...compressableFileTypes,
  ...uncompressableFileTypes,
].join(', ');
// 'image/jpeg, image/png, image/gif, image/x-webp';

// As requested by Aco video and audio uploading should be enabled later.
// audio/mp3, audio/mpeg, video/mp4, application/mp4, video/quicktime

export const acceptedFileTypesArr = acceptedFileTypes
  .split(',')
  .map((item) => item.trim());

export const MAX_SIZE = 20e6; // 20MB // Max size for image before compression
export const MAX_PDF_SIZE = 2e6; // 2MB
export const IMAGE_MAX_WIDTH = 1024;
export const THUMB_MAX_WIDTH = 200;

const getImageBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      return resolve(reader.result);
    });
  });

export const shouldCompress = (file) =>
  !file || (file && compressableFileTypes.includes(file.type));

export const createFile = (blob, fileName, type, lastModified) => {
  if (!navigator.msSaveBlob) {
    return new File(blob, fileName, { type, lastModified });
  }

  const file = new Blob(blob, { type });
  file.lastModifiedDate = lastModified;
  file.name = fileName;

  return file;
};

export const compress = (maxWidth) => (file, readerResult) => {
  return new Promise((resolve, reject) => {
    const fileName = file.name;
    const img = new Image();
    img.src = readerResult;
    img.onload = () => {
      const elem = document.createElement('canvas');
      const scaleFactor = maxWidth / img.width;
      elem.width = maxWidth;
      elem.height = img.height * scaleFactor;
      const ctx = elem.getContext('2d');

      ctx.drawImage(img, 0, 0, elem.width, elem.height);

      ctx.canvas.toBlob(
        async (blob) => {
          const file = createFile([blob], fileName, JPEG, Date.now());

          const imageString = await getImageBase64(file);

          return resolve({
            file,
            result: imageString,
          });
        },
        JPEG,
        1
      );
    };
  });
};

export const compressImage = compress(IMAGE_MAX_WIDTH);
export const compressThumb = compress(THUMB_MAX_WIDTH);
