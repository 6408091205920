import { FormattedMessage } from 'react-intl';

import { WowIcon } from '../../../../../../../components_new/WowBasicElements';
import { Card, TextWrapper, Wrapper } from './styles';
import { gradientBlue } from '../../../../../../../config/colors';

const ViewMoreCard = (props) => {
  const {
    id,
    items = [],
    width,
    detailsComponent = <></>,
    onClick = () => {},
    showDetailsComponent,
  } = props;

  return (
    <Wrapper>
      <Card width={width ?? 0} left={0} onClick={() => onClick(id)}>
        <TextWrapper>
          <span style={{ position: 'relative', bottom: 3 }}>
            +{items?.length} <FormattedMessage id="more" />
          </span>
          <WowIcon
            className="icon-arrowright"
            color={gradientBlue}
            size="20"
            spaceRight="0"
            spaceLeft="0"
          />
        </TextWrapper>
      </Card>
      {showDetailsComponent && detailsComponent}
    </Wrapper>
  );
};

export default ViewMoreCard;
