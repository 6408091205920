import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../../../redux/utils';
import { BASE_URL } from '../../../../../config/constants';

export const dynamicFormsApi = createApi({
  reducerPath: 'dynamicFormsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      if (token) headers.set('Authorization', `Bearer ${token}`);
      headers.set('Accept', `application/json`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    insertIssueDynamicForm: builder.mutation({
      query(data) {
        const { issueId, body } = data;
        return {
          url: `forms/issue/${issueId}/store`,
          method: 'POST',
          body: {
            form: body,
          },
        };
      },
    }),
    insertIssueScheduleForm: builder.mutation({
      query(data) {
        const { scheduleId, body } = data;
        return {
          url: `forms/schedule/${scheduleId}/store`,
          method: 'POST',
          body: {
            form: body,
          },
        };
      },
    }),
    updateIssueDynamicForm: builder.mutation({
      query(data) {
        const { formId, body } = data;
        return {
          url: `forms/issue_form/${formId}`,
          method: 'PUT',
          body: {
            form: body,
          },
        };
      },
    }),
    updateIssueScheduleForm: builder.mutation({
      query(data) {
        const { formId, body } = data;
        return {
          url: `forms/schedule_form/${formId}`,
          method: 'PUT',
          body: {
            form: body,
          },
        };
      },
    }),
    deleteIssueDynamicForm: builder.mutation({
      query(data) {
        const { formId } = data;
        return {
          url: `forms/issue_form/${formId}`,
          method: 'DELETE',
        };
      },
    }),
    deleteIssueScheduleForm: builder.mutation({
      query(data) {
        const { formId } = data;
        return {
          url: `forms/schedule_form/${formId}`,
          method: 'DELETE',
        };
      },
    }),
    uploadAttachments: builder.mutation({
      query(data) {
        const { formData } = data;
        return {
          url: `forms/upload_attachments`,
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useInsertIssueDynamicFormMutation,
  useInsertIssueScheduleFormMutation,
  useUpdateIssueDynamicFormMutation,
  useUpdateIssueScheduleFormMutation,
  useDeleteIssueDynamicFormMutation,
  useDeleteIssueScheduleFormMutation,
  useUploadAttachmentsMutation,
} = dynamicFormsApi;
