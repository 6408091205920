import styled from 'styled-components/macro';

import {
  darkerGray,
  gray,
  hardWhite,
  gradientBlue,
  orange,
  iconWowListItemLightGray,
  outline,
} from '../../config/colors';

export const StatusPill = styled.span`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  background-color: ${({ color }) => (color ? color : 'transparent')};
  border-radius: 15px;
  height: 25px;
  align-items: center;
  padding: ${({ isTransparent }) => (isTransparent ? '0' : '0 10px')};
  color: ${({ isTransparent }) => (isTransparent ? darkerGray : 'white')};

  i {
    padding-right: ${({ isTransparent }) => (isTransparent ? '10px' : '3px')};
  }
`;

export const StatusWithIcon = styled.div`
  display: flex;
  align-items: center;

  i {
    padding-right: 12px;
  }
`;

export const CharactersCount = styled.div`
  position: ${({ absolute }) => (absolute ? 'absolute' : 'relative')};
  font-size: 12px;
  padding: 0;
  color: ${gray};
  text-align: ${({ position }) => position || 'right'};
  align-self: ${({ position }) =>
    position === 'left' ? 'flex-start' : 'flex-end'};
`;

export const WowIcon = styled.i`
  display: ${({ display }) => display || 'inline-block'};
  padding-right: ${({ spaceRight }) =>
    spaceRight ? spaceRight + 'px' : '10px'} !important;
  padding-left: ${({ spaceLeft }) =>
    spaceLeft ? spaceLeft + 'px' : '0px'} !important;
  color: ${({ color }) => (color ? color : iconWowListItemLightGray)};
  margin-left: ${({ mLeft }) => (mLeft ? mLeft + 'px' : '0')};
  margin-right: ${({ mRight }) => (mRight ? mRight + 'px' : '0')};
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'none')};

  :before {
    font-size: ${({ size }) => (size ? size + 'px' : 'inherit')};
  }

  ${({ clickable }) => (clickable ? `&:hover { cursor: pointer;}` : null)};
`;

export const WowTextItalic = styled.span`
  font-style: italic;
`;

export const WowTooltipWrapper = styled.div`
  max-width: ${({ width }) => (width ? width + 'px' : '200px')};
  font-size: ${({ size }) => (size ? size + 'px' : '12px')};
  word-break: break-word;
`;

export const WowTextCapitalize = styled.span`
  text-transform: capitalize;
  display: inline-block;
`;

export const WowTooltipContent = styled.div`
  display: flex;
  max-width: 100%;
`;

export const WowLoadMoreButton = styled.div`
  height: 40px;
  background-color: ${hardWhite};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '25px;'};
  color: ${({ color }) => color || gradientBlue};
  font-size: 15px;
  width: ${({ width }) => width || '100%'};

  &:hover {
    cursor: pointer;
  }
`;

export const WowLabelText = styled.span`
  text-transform: uppercase;
  font-size: 11px;
  color: ${({ color }) => color || darkerGray};
  margin-bottom: ${({ spaceBottom }) =>
    spaceBottom ? spaceBottom + 'px' : '5px'};
  display: block;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export const WowInfoText = styled(WowLabelText)`
  text-transform: none;
  font-size: ${(props) => `${props?.$fontSize ?? 11}px;`};
`;

export const WowDisplayFlex = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  align-items: ${({ align }) => align || 'center'};
  min-width: ${({ minWidth }) => (minWidth ? minWidth + 'px' : '0')};
  ${({ position }) => (position ? `position: ${position};` : '')}
  ${({ left }) => (left ? `left: ${left};` : '')}

  ${({ flex }) => (flex ? `flex: ${flex}` : '')};
  ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
  ${({ width }) => (width ? `width: ${width}` : null)};
  ${({ height }) => (height ? `height: ${height}` : null)};
  ${({ gap }) => (gap ? `gap: ${gap}px` : null)};
  ${({ shrink }) => (shrink ? `flex-shrink: ${shrink};` : null)};
`;

export const WowTextBold = styled.b`
  font-size: ${({ size }) => (size ? size + 'px ' : '14px')};
  font-weight: ${({ fWeight }) => fWeight || '600'};
`;

export const WowColorizeText = styled.span`
  color: ${({ color }) => color};
  font-size: ${({ size }) => (size ? size + 'px ' : 'inherit')};
  white-space: pre-wrap;
  word-break: break-word;

  ${({ fWeight }) => (fWeight ? `font-weight: ${fWeight}` : null)};
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : null)};
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : null)};

  ${({ reduceText }) =>
    reduceText
      ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
      : null}
`;

export const WowTextCenter = styled.div`
  text-align: center;
`;

export const WowJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const WowAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const WowBetaLabel = styled.span`
  margin-left: 10px;
  background-color: ${orange};
  color: #fff;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
`;

export const WowElementWithLinkWrapper = styled.div`
  display: flex;
  :hover {
    cursor: pointer;
  }
`;

export const WowImage = styled.img`
  width: ${({ width }) => (width ? width + 'px' : '20px')};
`;

export const WowLink = styled.span`
  font-size: ${({ size }) => (size ? size + 'px ' : '14px')};
  color: ${({ color }) => color || gradientBlue};
  ${({ fWeight }) => (fWeight ? `font-weight: ${fWeight}` : null)};
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize' : null)};
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase' : null)};

  &:hover {
    cursor: pointer;
  }
`;

export const WowBlock = styled.div`
  width: ${({ width }) => width ?? '100%'};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : '100%')};
`;

export const WowPill = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px;
  background-color: ${hardWhite};
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  align-items: center;
`;

export const WowClose = styled.div`
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  width: 24px;
  height: 24px;
  min-width: 24px;
  right: ${({ relative }) => (relative ? 'auto' : '10px')};
  z-index: 10;
  border-radius: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  i {
    width: 14px;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background-color: ${outline};
  }
`;

export const WowResizableInputHidden = styled.span`
  font-size: ${({ size }) => (size ? size + 'px ' : '30px')};
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
`;
