import { PRESENCE_ALARMS_MAPPER } from '../../constants';

export const getHoursAndMinutes = (value) => {
  const hours = value / 60;
  const formatedHours = Math.floor(hours);
  const minutes = (hours - formatedHours) * 60;
  const formatedMinutes = Math.round(minutes);

  return {
    hours: formatedHours,
    minutes: formatedMinutes,
  };
};

export const getIsPresenceAlarmEnabled = (timeLimits) => {
  if (!timeLimits) return;

  let isEnabled = false;

  for (const item of PRESENCE_ALARMS_MAPPER) {
    if (timeLimits[item.dataKey] !== null) {
      isEnabled = true;
      break;
    }
  }

  return isEnabled;
};
