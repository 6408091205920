export const entities = {
  WORKSPACE: {
    name: 'WORKSPACE',
    reduxProp: 'workspaces',
  },
  ISSUE: {
    name: 'ISSUE',
    reduxProp: 'issues',
  },
  USERS: {
    name: 'USERS',
    reduxProp: 'users',
  },
  USER: {
    name: 'USER',
    reduxProp: 'user',
  },
  USER_ACTIVITY: {
    name: 'USER_ACTIVITY',
    reduxProp: 'activities',
  },
  COMMENT: {
    name: 'COMMENT',
    reduxProp: 'comments',
  },
  CATEGORY: {
    name: 'CATEGORY',
    reduxProp: 'categories',
  },
  LANGUAGE: {
    name: 'LANGUAGE',
    reduxProp: 'language',
  },
  CLIENT: {
    name: 'CLIENT',
    reduxProp: 'client',
  },
  NOTIFICATION: {
    name: 'NOTIFICATION',
    reduxProp: 'notifications',
  },
  TEMPLATE: {
    name: 'TEMPLATE',
    reduxProp: 'templates',
  },
  // Calculated from WORKSPACE
  STATUS: {
    name: 'STATUS',
    reduxProp: 'statuses',
  },
  PRIORITY: {
    name: 'PRIORITY',
    reduxProp: 'priorities',
  },
  ASSIGNEE: {
    name: 'ASSIGNEE',
    prop: 'available_assignees',
    reduxProp: 'assignees',
  },
  PERMISSION: {
    name: 'PERMISSION',
    reduxProp: 'permissions',
  },
  LOADING: {
    name: 'LOADING',
    reduxProp: 'loading',
  },
  SEARCHING: {
    name: 'SEARCHING',
    reduxProp: 'searching',
  },
  ERROR: {
    name: 'ERROR',
    reduxProp: 'error',
  },
  ISSUE_ACTIVITY_LOG: {
    name: 'ISSUE_ACTIVITY_LOG',
    reduxProp: 'activityLog',
  },
  ISSUE_ATTACHMENTS_COMMENTS: {
    name: 'ISSUE_ATTACHMENTS_COMMENTS',
    reduxProp: 'issueAttachmentsComments',
  },
  ISSUE_QRSCAN_LOG: {
    name: 'ISSUE_QRSCAN_LOG',
    reduxProp: 'log',
  },
  FILE: {
    name: 'FILE',
    reduxProp: 'file',
  },
  CONFIG: {
    name: 'CONFIG',
    reduxProp: 'config',
  },
  WORKSPACE_FORM: {
    name: 'WORKSPACE_FORM',
    reduxProp: 'workspaceForm',
  },
  ISSUE_TIME_LOG: {
    name: 'ISSUE_TIME_LOG',
    reduxProp: 'timeLog',
  },
};
