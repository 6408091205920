import { useCallback } from 'react';
import { isFunction, isString } from '../../../utils/core';
import { ERRORS } from './constants';
import { useInfiniteScrollData } from '../useInfiniteScrollData';

export const useInfiniteLazyQuery = ({
  skip,
  customParams,
  preferCacheValue = false,
  useLazyQuery,
  entityKey,
  preventReset,
  idKey,
} = {}) => {
  if (!isFunction(useLazyQuery)) {
    throw new Error(ERRORS.useLazyQuery);
  }

  if (!isString(entityKey)) {
    throw new Error(ERRORS.entityKey);
  }

  const [getData] = useLazyQuery();

  const getItems = useCallback(
    async (params) => {
      if (skip) return;

      const result = await getData(params, preferCacheValue).unwrap();

      return result;
    },
    [skip, getData, preferCacheValue]
  );

  const {
    data,
    hasMore,
    isLoading,
    handleLoadMore,
    total,
    handleSearch,
    handleDelete,
    handleAdd,
    query,
    handleUpdateQueryParams,
    handleUpdate,
    handleEdit,
  } = useInfiniteScrollData({
    entityKey,
    idKey,
    getItems,
    skip,
    preventReset,
    customParams,
  });

  return {
    data,
    hasMore,
    isLoading,
    handleLoadMore,
    total,
    handleSearch,
    handleDelete,
    handleAdd,
    query,
    handleUpdateQueryParams,
    handleUpdate,
    handleEdit,
  };
};
