//import { fetchData } from '../../../../../../redux/common/flows';
import { getLoggedUserData } from '../../../../../../redux/user/action-creator';

export const parseOgranizations = (items) => {
  const main = items.find((item) => item.is_main);
  const current = items.find((item) => item.is_current);
  const available = items.filter((item) => !item.is_current);

  return {
    main,
    current,
    available,
  };
};

export const getNewUser = (callback) => async (dispatch) => {
  //await dispatch(fetchData({ forceFetchUsers: true }));
  await dispatch(getLoggedUserData());
  if (callback) callback();
};
