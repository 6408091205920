import { createSlice } from '@reduxjs/toolkit';
import { INIT_PARAMS } from './constants';

const INITIAL_STATE = {
  filters: INIT_PARAMS,
};

export const users = createSlice({
  name: 'users',
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = users.actions;

export default users.reducer;
