import { useIntl } from 'react-intl';

import ModalInfo from '../../../../../components_new/ModalInfo';

const EmaiActionsAcceptedModal = (props) => {
  const { clientName, setActionInfoModalConfig } = props;

  const intl = useIntl();

  return (
    <ModalInfo
      isOpen={true}
      handleAction={() => setActionInfoModalConfig(null)}
      handleClose={() => {}}
      icon="icon-sad-emoji"
      intlValues={{
        messageKey: 'successfully_rejected_to_collaborate_with_client',
        clientName,
      }}
      heading={intl.formatMessage({ id: 'collaboration_invitation_rejected' })}
      hideCloseIcon={true}
    />
  );
};

export default EmaiActionsAcceptedModal;
