import {
  apiCallWithFormData,
  authGET,
  notificationApi,
  authPOST,
  authPUT,
} from './ApiService';
import { config } from './config';
const userRoute = `${config.URL}/api/${config.API_VERSION}/${config.API.USER}`;
const notificationUrl = `${config.URL}/api/v4/notification`;

const getRoute = (id) => `${userRoute}/${id}`;
const getActivitiesRoute = (userId, page, pageSize, activityType) => {
  let activitiesRoute = `${userRoute}/${userId}/${config.API.USER_ACTIVITY}?page=${page}&paginate_by=${pageSize}`;

  if (activityType) {
    activitiesRoute += `&activity_type=${activityType}`;
  }

  return activitiesRoute;
};

export const multipartUserRequest = (method, token, entityId, data) => {
  return apiCallWithFormData(
    method,
    getRoute(entityId),
    data,
    token,
    config.CONTENT_TYPES.MULTIPART
  );
};

export const notificationsApi = (token, httpReqType, id) => {
  if (httpReqType === 'GET') {
    return notificationApi(notificationUrl, httpReqType, token);
  } else if (httpReqType === 'PUT' && id) {
    return notificationApi(
      `${notificationUrl}/${id}/web/mark_read`,
      httpReqType,
      token
    );
  } else {
    return notificationApi(
      `${notificationUrl}/web/mark_read`,
      httpReqType,
      token
    );
  }
};

export const getUser = (token, id) => {
  return authGET(getRoute(id), config.CONTENT_TYPES.APPLICATION_JSON, token);
};

export const pauseNotificationsApi = (token, isPause) => {
  return authPOST(
    `${notificationUrl}/${isPause ? 'pause' : 'unpause'}`,
    config.CONTENT_TYPES.APPLICATION_JSON,
    { pause_for: 1 },
    token
  );
};
export const unreadNotificationsApi = (token, id) => {
  return authPUT(
    `${config.URL}/api/v4/notification/${id}/mark_unread`,
    config.CONTENT_TYPES.APPLICATION_JSON,
    {},
    token
  );
};

export const getActivities = (token, userId, page, pageSize, activityType) => {
  return authGET(
    getActivitiesRoute(userId, page, pageSize, activityType),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};
