import { LogoImage, Wrapper } from './styles';
import Logo from '../../../../assets/img/logo-wowflow.svg';
import useOnboarding from './hooks/useOnboarding';
import { STEPS } from './constants';
import { WowBlock } from '../../../../components_new/WowBasicElements';

const Onboarding = () => {
  const { currentStep, wrapperHeight } = useOnboarding();

  return (
    <Wrapper data-testid="onboardingWrapper" height={wrapperHeight}>
      <WowBlock>
        <LogoImage src={Logo} />
      </WowBlock>
      {STEPS[currentStep]()}
    </Wrapper>
  );
};

export default Onboarding;
