import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonAdd, { StyledButton } from '../../../../components_new/ButtonAdd';
import useVisible from '../../../../hooks/useVisible';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { getIsGroupManagerEnabled } from '../../users/selectors';
import { MenuItems } from '../../../../components_new/Menu';
import {
  StyledMenuItem,
  StyledMenu,
  IconCheck,
  IconClose,
  Text,
} from './styles';

const AssignToWorkspaceButton = ({
  workspacePosition,
  handleChange,
  workspaceId,
}) => {
  const intl = useIntl();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  });
  const isGroupManagerEnabled = useSelector(getIsGroupManagerEnabled);

  const { ref, isVisible, setIsVisible } = useVisible(false);

  useEffect(() => {
    if (attributes?.popper && attributes.popper['data-popper-reference-hidden'])
      setIsVisible(false);
  }, [attributes, setIsVisible]);

  const userRoleOptions = [
    {
      label: intl.formatMessage({
        id: 'member',
        defaultMessage: 'Worker',
      }),
      value: 'user',
    },
    {
      label: intl.formatMessage({ id: 'manager', defaultMessage: 'Manager' }),
      value: 'manager',
    },
    {
      label: intl.formatMessage({
        id: 'group_manager',
        defaultMessage: 'Group Manager',
      }),
      value: 'group_manager',
    },
  ];

  const getUserRoleOptions = () => {
    if (isGroupManagerEnabled) return userRoleOptions;

    return userRoleOptions.filter((option) => option.value !== 'group_manager');
  };

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };

  const handleOptionChange = (role) => () => {
    handleChange({ workspace_id: workspaceId, workspace_role: role });
    setIsVisible(false);
  };

  const removeFromWorkspace = (e) => {
    e.stopPropagation();

    handleChange({ workspace_id: workspaceId, workspace_role: 'none' });
  };

  const translationsMap = {
    user: intl.formatMessage({
      id: 'as_worker',
      defaultMessage: 'As a worker',
    }),
    manager: intl.formatMessage({
      id: 'as_manager',
      defaultMessage: 'As a manager',
    }),
    group_manager: intl.formatMessage({
      id: 'as_group_manager',
      defaultMessage: 'As a group manager',
    }),
  };

  const isInWorkspace = workspacePosition !== 'none';

  return (
    <div ref={ref}>
      <div ref={setReferenceElement}>
        {isInWorkspace ? (
          <StyledButton
            appearance="contained"
            onClick={handleButtonClick}
            color="gray"
          >
            <Text>
              <FormattedMessage
                id={workspacePosition === 'user' ? 'member' : workspacePosition}
              />
              <IconClose onClick={removeFromWorkspace} />
            </Text>
          </StyledButton>
        ) : (
          <ButtonAdd appearance="contained" onClick={handleButtonClick} />
        )}
      </div>
      {isVisible ? (
        <StyledMenu
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <MenuItems>
            {getUserRoleOptions().map((option) => (
              <StyledMenuItem
                key={option.value}
                onClick={handleOptionChange(option.value)}
                selected={isInWorkspace && option.value === workspacePosition}
                tabIndex={0}
              >
                {isInWorkspace && option.value === workspacePosition ? (
                  <IconCheck />
                ) : null}
                {isInWorkspace ? option.label : translationsMap[option.value]}
              </StyledMenuItem>
            ))}
          </MenuItems>
        </StyledMenu>
      ) : null}
    </div>
  );
};

export default AssignToWorkspaceButton;
