import { fromJS } from 'immutable';
import { actionStrings as auth } from '../auth/actions';
import { actionStrings as userActions } from '../user/actions';

const initState = fromJS({
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  client_id: null,
  phone: null,
  status: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
  permissions: {
    global: [],
    workspace: [],
  },
  full_name: '',
  status_nice: '',
  profile_image: '',
  language: '',
  activities_last: {},
  activities: [],
});

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case auth.SAVE_LOGIN_DATA:
      return fromJS(action.payload.user);
    case auth.LOGOUT:
      return initState;
    case userActions.MULTIPART_USER_REQUEST:
      return fromJS(action.payload.user);
    case userActions.GET_USER:
      return fromJS(action.payload.user);
    case userActions.UPDATE_USER_SETTINGS:
      return state.set('settings', action.payload);
    case userActions.LOCALE_SET:
      return state.set('language', action.payload.language);
    case userActions.USER_ACTIVITY_SUCCESS: {
      let activitiesState = state.get('activities')
        ? state.get('activities')
        : [];

      // Clear accumulated activities if we displaying log from start.
      activitiesState =
        action.payload.current_page === 1 ? [] : activitiesState;

      return state
        .set('activities_last', action.payload)
        .set('activities', [...activitiesState, ...action.payload.data]);
    }
    case userActions.USER_ACTIVITY_CLEAR: {
      return state.set('activities_last', []).set('activities', []);
    }
    case userActions.UPDATE_USER_AFTER_POLLING: {
      const { permissions, ...rest } = action.payload;
      return state
        .setIn(['permissions'], fromJS(permissions))
        .mergeDeep(fromJS(rest));
    }
    case userActions.UPDATE_USER: {
      const user = state.toJS();
      return state.mergeDeep(fromJS({ ...user, ...action.payload }));
    }
    default:
      return state;
  }
};
