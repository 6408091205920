import { FormattedMessage } from 'react-intl';
import { getIsActiveSection } from '../utils';
import { SectionName, Subsection } from './styles';
import { useLocation } from 'react-router';

const SubsectionItem = ({ item }) => {
  const { pathname } = useLocation();

  const isSelected = getIsActiveSection(pathname, item.route);

  return (
    <Subsection>
      <SectionName isSubsection isSelected={isSelected} mainAccent>
        <FormattedMessage id={item.id} />
      </SectionName>
    </Subsection>
  );
};
export default SubsectionItem;
