export const FEATURE_KEY = 'externalHolders';

export const EMPTY_SCREEN_SETTINGS = {
  icon: 'icon-people',
  keys: {
    title: 'no_external_holders',
    text: 'no_external_holders_text',
  },
};

export const INIT_QUERY = {
  search: '',
  page: '1',
  paginate_by: '20',
  status: 'active',
  sort_by: 'name',
  sort_direction: 'asc',
};

export const SUPPORTED_PARAMS = {
  page: {
    defaultValue: INIT_QUERY.page,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: INIT_QUERY.paginate_by,
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  status: {
    defaultValue: INIT_QUERY.status,
    allowedValues: ['active', 'archived'],
  },
  sort_by: {
    defaultValue: INIT_QUERY.sort_by,
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: INIT_QUERY.sort_direction,
    allowedValues: ['asc', 'desc'],
  },
  search: {
    defaultValue: INIT_QUERY.search,
    allowedValues: null,
  },
};

export const STATUSES = [
  { value: 'active', label: 'active' },
  { value: 'archived', label: 'archived' },
];

export const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'name',
    label: 'name',
  },
];
