import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

const useElementDimensions = () => {
  const elRef = useRef();

  const [dimensions, setDimensions] = useState({ width: 0, height: 20 });

  useEffect(() => {
    function getDimensions() {
      const { current } = elRef;

      if (current) {
        setDimensions({
          width: current.clientWidth,
          height: current.clientHeight,
        });
      }
    }

    const debouncedGetDimensions = debounce(getDimensions, 200);

    window.addEventListener('resize', debouncedGetDimensions);

    getDimensions();

    return () => window.removeEventListener('resize', debouncedGetDimensions);
  }, []);

  return {
    ...dimensions,
    elRef,
  };
};

export default useElementDimensions;
