import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useUsersByWorkspaceAccessInfinite from '../../../../../../views/internal/users/hooks/useUsersByWorkspaceAccessInfinite';
import SubHeader from '../SubHeader';
import Search from '../Search';
import { Content, LoaderWrapper, NoItems } from '../../../../styles';
import useWindowSizeDebounced from '../../../../../../hooks/useWindowSizeDebounced';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';
import InfiniteScroll from '../../../../../InfiniteScroll';
import { DEFAULT_PROFILE_IMAGE } from '../../../../../../config/constants';
import { WowIcon } from '../../../../../WowBasicElements';
import TextOverflowWithTooltip from '../../../../../TextOverflowWithTooltip';
import { gradientBlue } from '../../../../../../config/colors';
import {
  ArrowUp,
  GroupOptionContainer,
  GroupOptionLabel,
  Option,
  OptionLabel,
  OptionPhoto,
} from '../../styles';
import Space from '../../../../../Space';

const Users = (props) => {
  const {
    handleSelect,
    selectedItems,
    handleTriggerAddModal,
    testIds,
    isAdmin,
    search,
    setSearch,
    initQueryParams,
    shouldShowTabs,
  } = props;

  const { data, isLoading, handleLoadMore, hasMore, handleSearch } =
    useUsersByWorkspaceAccessInfinite({
      entityKey: 'users',
      customParams: initQueryParams,
    });

  const { height: windowHeight } = useWindowSizeDebounced();

  const [isCollapsedNotInWorkspace, setIsCollapsedNotInWorkspace] =
    useState(false);

  const [isFirstInTheWorkspaceId, setIsFirstInTheWorkspaceId] = useState(null);

  useEffect(() => {
    if (isFirstInTheWorkspaceId || !data?.length) return;
    const _isFirstInTheWorkspace = data?.find((item) => item.isNotInWorkspace);
    if (_isFirstInTheWorkspace?.id)
      setIsFirstInTheWorkspaceId(_isFirstInTheWorkspace?.id);
  }, [data, isFirstInTheWorkspaceId]);

  return (
    <>
      {!isAdmin && shouldShowTabs ? <Space height="20" /> : null}
      {isAdmin ? (
        <SubHeader
          buttonKey="add_user"
          handleTriggerAddModal={handleTriggerAddModal}
        />
      ) : null}

      <Search
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />

      <Content
        data-testid={testIds?.modalDataTestId}
        height={windowHeight > 800 ? '300px' : 'auto'}
      >
        {data.length === 0 ? (
          isLoading ? (
            <LoaderWrapper>
              <LoadingSpinner small />
            </LoaderWrapper>
          ) : (
            <NoItems>
              <FormattedMessage id="no_items" />
            </NoItems>
          )
        ) : (
          <InfiniteScroll
            dataLength={data.length}
            handleLoadMore={handleLoadMore}
            hasMore={hasMore}
            height={windowHeight > 800 ? 300 : -1}
            maxHeight={windowHeight > 800 ? 300 : 200}
          >
            {data.map((item) => {
              const { id, image, label, isNotInWorkspace } = item;

              const isSelected = selectedItems.find(
                (selectedItem) => selectedItem.id === item.id
              );

              return (
                <Fragment key={id}>
                  {isFirstInTheWorkspaceId === id ? (
                    <GroupOptionContainer
                      onClick={() =>
                        setIsCollapsedNotInWorkspace((prevState) => !prevState)
                      }
                    >
                      <GroupOptionLabel size="14" isBold>
                        <FormattedMessage id="not_in_this_workspace" />
                      </GroupOptionLabel>

                      <ArrowUp isCollapsed={isCollapsedNotInWorkspace} />
                    </GroupOptionContainer>
                  ) : null}

                  {!isNotInWorkspace || !isCollapsedNotInWorkspace ? (
                    <Option
                      onClick={() => handleSelect(item)}
                      lowOpacity={isNotInWorkspace}
                    >
                      <OptionPhoto
                        src={image || DEFAULT_PROFILE_IMAGE}
                        isSelected={isSelected}
                      />
                      <TextOverflowWithTooltip tooltipContent={label}>
                        {(ref) => <OptionLabel ref={ref}>{label}</OptionLabel>}
                      </TextOverflowWithTooltip>

                      {isSelected ? (
                        <WowIcon
                          className="icon-check"
                          color={gradientBlue}
                          spaceRight="10"
                          spaceLeft="0"
                        />
                      ) : null}
                    </Option>
                  ) : null}
                </Fragment>
              );
            })}
          </InfiniteScroll>
        )}
      </Content>
    </>
  );
};

export default Users;
