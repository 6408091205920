import styled from 'styled-components/macro';
import { WowIcon } from '../../../WowBasicElements';

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: ${({ align }) => align ?? 'flex-start'};
  position: relative;

  i {
    margin-top: 12px;
  }
`;

export const AvatarWrapper = styled.div`
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

export const EditIcon = styled(WowIcon)`
  position: absolute;
  right: 30px;
  margin-top: 9px !important;
`;
