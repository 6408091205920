import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  workspaces: {},
  issues: {},
  scheduling: {},
  equipment: {},
  equipmentGroups: {},
  equipmentExternalHolders: {},
  assets: {},
  assetGroups: {},
  assetSystems: {},
  users: {},
  checklists: {},
};

export const multiselectSlice = createSlice({
  name: 'multiselect',
  initialState: INIT_STATE,
  reducers: {
    updateSelectedItems: (state, action) => {
      const { target, items } = action.payload;
      state[target] = items;
    },
  },
});

export const { updateSelectedItems } = multiselectSlice.actions;

export default multiselectSlice.reducer;
