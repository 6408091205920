import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { vestResolver } from '@hookform/resolvers/vest';
import Space from '../../../../../../../../components_new/Space';
import Button from '../../../../../../../../components_new/ButtonAdd';
import { SectionTitle } from '../../../../../../scheduling/schedule/styles';
import {
  Checkbox,
  FormBody,
  MediaDescription,
  MediaDescriptionOverlay,
  MediaPhoto,
  MediaPhotoOverlay,
  MediaPhotoWrapper,
  MediaPhotoWrapperLabel,
  MediaRow,
  CheckboxLabel,
  InputRow,
} from '../../styles';
import { getDefaultValues, EXPORT_TYPES } from '../../utils';
import formValidation from '../../formValidation';
import { WowCheckbox } from '../../../../../../../../components/wow-checkbox';
import Input from '../../../../../../../../components_new/Input';
import { WowLabelText } from '../../../../../../../../components_new/WowBasicElements';
import { gradientBlue, hardWhite } from '../../../../../../../../config/colors';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../../../../redux/user/selectors';

const Custom = ({ submitHandler, issue, renderActionButtons }) => {
  const intl = useIntl();

  const loggedInUser = useSelector(getUser);
  const settings = loggedInUser?.client?.settings;

  const formMethods = useForm({
    defaultValues: getDefaultValues(issue, EXPORT_TYPES.custom, settings),
    resolver: vestResolver(formValidation),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { control, watch, setValue, handleSubmit, getValues, trigger } =
    formMethods;

  const includeMedia = watch('include_media');
  const media = watch('media');
  const sendEmailCopy = watch('send_email_copy');
  const email_addresses = watch('email_addresses');
  const hasMedia = issue.attachments?.length > 0;

  useEffect(() => {
    if (sendEmailCopy) {
      setValue('email_addresses', [{ id: 1, value: '' }]);
    } else {
      setValue('email_addresses', []);
    }
  }, [sendEmailCopy, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitHandler)} data-testid="customTabForm">
        <FormBody>
          <Space height="10" />
          <Controller
            control={control}
            name="send_email_copy"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'send_copy' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="sendCopy"
                />
              );
            }}
          />
          {sendEmailCopy ? (
            <>
              {email_addresses.map((item, index) => {
                return (
                  <InputRow key={item.id}>
                    <WowLabelText bold>
                      {intl.formatMessage({ id: 'email_address' })}
                    </WowLabelText>
                    <Controller
                      control={control}
                      name="email_addresses"
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            value={field?.value[index]?.value}
                            style={{ width: 280 }}
                            onChange={(e) => {
                              const value = e?.target?.value;
                              const email_addresses =
                                getValues('email_addresses');
                              email_addresses[index].value = value;
                              setValue('email_addresses', email_addresses);
                              trigger();
                            }}
                            data-testid="emailInput"
                          />
                        );
                      }}
                    />
                  </InputRow>
                );
              })}
              {email_addresses.length <= 10 && (
                <Button
                  size="small"
                  appearance="text"
                  color="blue"
                  style={{ marginLeft: -10, marginTop: -5 }}
                  onClick={() => {
                    const email_addresses = getValues('email_addresses');
                    email_addresses.push({
                      id: email_addresses.length + 1,
                      value: '',
                    });
                    setValue('email_addresses', email_addresses);
                    trigger();
                  }}
                  data-testid="addEmailButton"
                >
                  <FormattedMessage id="add_another_email" />
                </Button>
              )}
            </>
          ) : (
            ''
          )}
          <Space height="30" />
          <SectionTitle>
            <FormattedMessage id="include" />
          </SectionTitle>
          <Space height="18" />
          <Controller
            control={control}
            name="include_header"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'header' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_header_checkbox"
                />
              );
            }}
          />
          <Space height="8" />

          <Controller
            control={control}
            name="include_title"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'title' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_title_checkbox"
                />
              );
            }}
          />
          <Space height="8" />

          <Controller
            control={control}
            name="include_details"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'issue_details' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_details_checkbox"
                />
              );
            }}
          />
          <Space height="8" />

          <Controller
            control={control}
            name="include_description"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'description' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_description_checkbox"
                />
              );
            }}
          />
          <Space height="8" />

          {hasMedia ? (
            <>
              <Controller
                control={control}
                name="include_media"
                render={({ field }) => {
                  return (
                    <WowCheckbox
                      label={
                        <CheckboxLabel>
                          {intl.formatMessage({ id: 'media' })}
                        </CheckboxLabel>
                      }
                      {...field}
                      checked={field.value}
                      size={18}
                      borderColor={gradientBlue}
                      data-testid="include_media_checkbox"
                    />
                  );
                }}
              />
              <Space height="8" />
            </>
          ) : null}

          <Controller
            control={control}
            name="include_comments"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'notes' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_comments_checkbox"
                />
              );
            }}
          />
          <Space height="8" />

          <Controller
            control={control}
            name="include_activity_log"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'activity_log' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_activity_log_checkbox"
                />
              );
            }}
          />
          <Space height="8" />
          <Controller
            control={control}
            name="include_ws_reference_number"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'ws_reference_number' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_ws_reference_number_checkbox"
                />
              );
            }}
          />
          <Space height="8" />
          <Controller
            control={control}
            name="include_forms"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'forms' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_forms_checkbox"
                />
              );
            }}
          />

          <Space height="8" />
          <Controller
            control={control}
            name="include_checklists_on_report"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'checklists' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="include_checklists_on_report_checkbox"
                />
              );
            }}
          />

          {includeMedia ? (
            <>
              <Space height="36" />
              <SectionTitle>
                <FormattedMessage id="media" />
              </SectionTitle>
              <Space height="18" />

              {issue.attachments.map((m, index) => {
                const isLast = issue.attachments.length - 1 === index;
                return (
                  <MediaRow isLast={isLast} key={m.id}>
                    <Controller
                      control={control}
                      name={`media.${m.id}.include`}
                      render={({ field }) => {
                        return (
                          <MediaPhotoWrapperLabel>
                            <Checkbox
                              {...field}
                              checked={field.value}
                              size={18.5}
                              borderColor={gradientBlue}
                              checkedBorderColor={hardWhite}
                              onChange={(e) => {
                                field.onChange(e);
                                if (m.description) {
                                  setValue(
                                    `media.${m.id}.include_description`,
                                    !field.value
                                  );
                                }
                              }}
                            />
                            <MediaPhotoWrapper>
                              {!field.value ? <MediaPhotoOverlay /> : null}
                              <MediaPhoto
                                src={m.web_mob_thumbnail_url}
                                alt={m.original_filename}
                              />
                            </MediaPhotoWrapper>
                          </MediaPhotoWrapperLabel>
                        );
                      }}
                    />

                    <Space width="18" />

                    {m.description ? (
                      <Controller
                        control={control}
                        name={`media.${m.id}.include_description`}
                        render={({ field }) => {
                          return (
                            <MediaPhotoWrapper>
                              <Checkbox
                                {...field}
                                onChange={(e) => {
                                  if (media[m.id].include) {
                                    field.onChange(e);
                                  }
                                }}
                                checked={field.value}
                                size={18}
                                borderColor={gradientBlue}
                              />
                              <MediaPhotoWrapper>
                                {!field.value ? (
                                  <MediaDescriptionOverlay />
                                ) : null}
                                <MediaDescription>
                                  {m.description}
                                </MediaDescription>
                              </MediaPhotoWrapper>
                            </MediaPhotoWrapper>
                          );
                        }}
                      />
                    ) : null}
                  </MediaRow>
                );
              })}
            </>
          ) : null}
          <Space height="36" />
        </FormBody>
        {renderActionButtons()}
      </form>
    </FormProvider>
  );
};

export default Custom;
