import axios from 'axios';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actionCreator } from '../../../../../redux/entities/action-creators';
import { EXPORT_CANCELED } from '../constants';

export const useExportIssueAsPdfAndDownload = (isPublic, toggle) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const cancelationSource = useRef();

  const dispatch = useDispatch();
  const intl = useIntl();

  const exportIssue = async (data, fileName) => {
    return new Promise(async (resolve, reject) => {
      setIsError(false);
      setIsLoading(true);

      const cancelToken = axios.CancelToken;
      cancelationSource.current = cancelToken.source();

      const temp = {
        id: data.id,
        fileName: fileName,
        fileType: 'pdf',
        requestBody: {
          ...data,
          language: intl.locale,
        },
        cancelationSource: cancelationSource.current,
        isPublic,
      };

      try {
        await dispatch(actionCreator.downloadReport(temp));
        setIsLoading(false);
        resolve();
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        if (error?.message === EXPORT_CANCELED) toggle();
        reject();
      }
    });
  };

  const handleCancel = () => {
    if (cancelationSource.current) {
      cancelationSource.current.cancel(EXPORT_CANCELED);
    }
  };

  return { exportIssue, isLoading, isError, handleCancel };
};
