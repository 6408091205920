export const actionStrings = {
  LOCALE_SET: 'LOCALE_SET',
  MULTIPART_USER_REQUEST: 'MULTIPART_USER_REQUEST',
  MULTIPART_USER_REQUEST_ERROR: 'MULTIPART_USER_REQUEST_ERROR',
  USER_ACTIVITY_REQUEST: 'USER_ACTIVITY_REQUEST',
  USER_ACTIVITY_SUCCESS: 'USER_ACTIVITY_SUCCESS',
  USER_ACTIVITY_CLEAR: 'USER_ACTIVITY_CLEAR',
  USER_ACTIVITY_ERROR: 'USER_ACTIVITY_ERROR',
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  UPDATE_USER_AFTER_POLLING: 'UPDATE_USER_AFTER_POLLING',
  UPDATE_USER: 'UPDATE_USER',
};

export const actions = {
  localeSet: (lang) => ({
    type: actionStrings.LOCALE_SET,
    payload: {
      language: lang,
    },
  }),
  userUpdateSuccess: (data) => ({
    type: actionStrings.MULTIPART_USER_REQUEST,
    payload: data,
  }),
  userUpdateError: (e) => ({
    type: actionStrings.MULTIPART_USER_REQUEST_ERROR,
    payload: { error: e },
  }),
  userActivityRequest: (userId) => ({
    type: actionStrings.USER_ACTIVITY_REQUEST,
    payload: userId,
  }),
  userActivitySuccess: (data) => ({
    type: actionStrings.USER_ACTIVITY_SUCCESS,
    payload: data,
  }),
  userActivityError: (e) => ({
    type: actionStrings.USER_ACTIVITY_ERROR,
    payload: { error: e },
  }),
  userActivityClear: () => ({
    type: actionStrings.USER_ACTIVITY_CLEAR,
  }),
  updateUserAfterPolling: (user) => ({
    type: actionStrings.UPDATE_USER_AFTER_POLLING,
    payload: user,
  }),
  updateUser: (payload) => ({
    type: actionStrings.UPDATE_USER,
    payload,
  }),
};

export const updateUserSettings = (settings) => ({
  type: actionStrings.UPDATE_USER_SETTINGS,
  payload: settings,
});
