export const actionStrings = {
  LOAD_ENTITY: 'LOAD_ENTITY',
  LOAD_ENTITY_PAGED: 'LOAD_ENTITY_PAGED',
  SEARCH_ENTITY: 'SEARCH_ENTITY',
  LOAD_ENTITY_SUCCESS: 'LOAD_ENTITY_SUCCESS',
  ENTITY_LOADED: 'ENTITY_LOADED',
  LOAD_ENTITY_ERROR: 'LOAD_ENTITY_ERROR',
  UPDATE_SINGLE_ENTITY: 'UPDATE_SINGLE_ENTITY',

  SELECT_CURRENT_WORKSPACE: 'SELECT_CURRENT_WORKSPACE',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
  CLEAR_CATEGORY: 'CLEAR_CATEGORY',

  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',

  ENTITY_REQUEST: 'ENTITY_REQUEST',
  ENTITY_REQUEST_SUCCESS: 'ENTITY_REQUEST_SUCCESS',
  ENTITY_REQUEST_WITH_PAGING_SUCCESS: 'ENTITY_REQUEST_WITH_PAGING_SUCCESS',
  ENTITY_REQUEST_ERROR: 'ENTITY_REQUEST_ERROR',
  MULTIPART_ENTITY_REQUEST: 'MULTIPART_ENTITY_REQUEST',

  MULTIPART_USER_REQUEST: 'MULTIPART_USER_REQUEST',
  MULTIPART_USER_REQUEST_ERROR: 'MULTIPART_USER_REQUEST_ERROR',

  UPDATE_ENTITY: 'UPDATE_ENTITY',
  ADD_TO_COLLECTION: 'ADD_TO_COLLECTION',
  ADD_SINGLE_TO_COLLECTION: 'ADD_SINGLE_TO_COLLECTION',
  SET_CURRENT_PARAMETERS: 'SET_CURRENT_PARAMETERS',
  ADD_ITEM_TO_COLLECTION: 'ADD_ITEM_TO_COLLECTION',
  REMOVE_ITEM_FROM_COLLECTION: 'ADD_ITEM_TO_COLLECTION',
  INVALIDATE_COLLECTION: 'INVALIDATE_COLLECTION',
  UPDATE_IN_ALL: 'UPDATE_IN_ALL',
};

export const actions = {
  loadEntity: (entityType, entityId, meta) => ({
    type: actionStrings.LOAD_ENTITY,
    payload: {
      entityType,
      entityId,
      meta,
    },
  }),
  entityLoaded: (entityType) => ({
    type: actionStrings.ENTITY_LOADED,
    payload: {
      entityType,
    },
  }),
  loadEntityPaged: (entityType, page, pageSize) => ({
    type: actionStrings.LOAD_ENTITY_PAGED,
    payload: {
      entityType,
      page,
      pageSize,
    },
  }),
  searchEntity: (entityType, searchTerm) => ({
    type: actionStrings.SEARCH_ENTITY,
    payload: {
      entityType,
      searchTerm,
    },
  }),
  loadEntitySuccess: (entityType, payload) => ({
    type: actionStrings.LOAD_ENTITY_SUCCESS,
    payload: {
      entityType,
      payload,
    },
  }),
  loadEntityError: (entityType, payload) => ({
    type: actionStrings.LOAD_ENTITY_ERROR,
    payload: {
      entityType,
      payload,
    },
  }),
  updateSingleEntity: (entityType, payload) => ({
    type: actionStrings.UPDATE_SINGLE_ENTITY,
    payload: {
      entityType,
      payload,
    },
  }),

  selectCurrentWorkspace: (id) => ({
    type: actionStrings.SELECT_CURRENT_WORKSPACE,
    payload: {
      id,
    },
  }),

  selectCurrentCategory: (id) => ({
    type: actionStrings.SELECT_CATEGORY,
    payload: {
      id,
    },
  }),

  clearCurrentCategory: () => ({
    type: actionStrings.CLEAR_CATEGORY,
    payload: {},
  }),

  entityRequest: (entityType, entityId, reqType, data) => ({
    type: actionStrings.ENTITY_REQUEST,
    payload: {
      data,
      entityType,
      entityId,
      reqType,
    },
  }),
  multipartEntityRequest: (entityType, entityId, reqType, data) => ({
    type: actionStrings.MULTIPART_ENTITY_REQUEST,
    payload: {
      data,
      entityType,
      entityId,
      reqType,
    },
  }),
  entityRequestSuccess: (entityType, entityId, data) => ({
    type: actionStrings.ENTITY_REQUEST_SUCCESS,
    payload: {
      data,
      entityType,
      entityId,
    },
  }),
  entityRequestWithPagingSuccess: (entityType, entityId, data) => ({
    type: actionStrings.ENTITY_REQUEST_WITH_PAGING_SUCCESS,
    payload: {
      data,
      entityType,
      entityId,
    },
  }),
  entityRequestError: (entityType, entityId, reqType, error) => ({
    type: actionStrings.ENTITY_REQUEST_ERROR,
    payload: {
      error,
      entityType,
      entityId,
      reqType,
    },
  }),
  updateEntity: (entityType, payload) => ({
    type: actionStrings.UPDATE_ENTITY,
    payload: {
      entityType,
      payload,
    },
  }),
  addToCollection: (entityType, data, key, parameters) => ({
    type: actionStrings.ADD_TO_COLLECTION,
    payload: {
      entityType,
      data,
      key,
      parameters,
    },
  }),
  addSingleToCollection: (entityType, data, key, parameters) => ({
    type: actionStrings.ADD_SINGLE_TO_COLLECTION,
    payload: {
      entityType,
      data,
      key,
      parameters,
    },
  }),
  setCurrentParameters: (entityType, parameters, key) => ({
    type: actionStrings.SET_CURRENT_PARAMETERS,
    payload: {
      entityType,
      parameters,
      key,
    },
  }),
  addItemToCollection: (entityType, item, key) => ({
    type: actionStrings.ADD_ITEM_TO_COLLECTION,
    payload: {
      entityType,
      item,
      key,
    },
  }),
  removeItemFromCollection: (entityType, item, key) => ({
    type: actionStrings.REMOVE_ITEM_FROM_COLLECTION,
    payload: {
      entityType,
      item,
      key,
    },
  }),
  invalidateCollection: (entityType) => ({
    type: actionStrings.INVALIDATE_COLLECTION,
    payload: {
      entityType,
    },
  }),
  updateInAll: (entityType, item) => ({
    type: actionStrings.UPDATE_IN_ALL,
    payload: {
      entityType,
      item,
    },
  }),
};
