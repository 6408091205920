export const TABS = [
  { id: 0, value: 'issue', labelKey: 'issues', iconLabel: 'icon-task' },
  {
    id: 1,
    value: 'schedule',
    labelKey: 'scheduling',
    iconLabel: 'icon-scheduling',
  },
  {
    id: 2,
    value: 'equipment',
    labelKey: 'equipment',
    iconLabel: 'icon-equipment',
  },
  { id: 3, value: 'asset', labelKey: 'assets', iconLabel: 'icon-assets' },
];
