import { CALENDAR_CARD_TYPES } from '../CalendarTimelineCard/constants';

export const TYPES = {
  USERS: {
    key: 'USERS',
    cardTitle: 'users',
    timelineCardType: CALENDAR_CARD_TYPES.ISSUE.key,
  },
  WORKSPACES: {
    key: 'WORKSPACES',
    cardTitle: 'workspaces',
    timelineCardType: CALENDAR_CARD_TYPES.WORKSPACE.key,
  },
};

export const CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD = 200; //Tweak if needed.
