import { useEffect, useState } from 'react';
import { RTK_HOOK_CONFIG } from '../../../../config/constants';
import {
  useGetCalendarWeeklyUsersEventCountQuery,
  useGetCalendarWeeklyWorkspacesEventCountQuery,
} from '../service';

const useWeeklyDataCounter = (props) => {
  const {
    searchParams,
    skipCalendarWeeklyUsersEventCountData = false,
    skipCalendarWeeklyWorkspacesEventCountData = false,
  } = props;

  const {
    data: calendarWeeklyUsersEventCountFetchData,
    isFetching: isFetchingCalendarWeeklyUsersEventCount,
  } = useGetCalendarWeeklyUsersEventCountQuery(
    { ...searchParams },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipCalendarWeeklyUsersEventCountData,
    }
  );

  const [
    calendarWeeklyUsersEventCountData,
    setCalendarWeeklyUsersEventCountData,
  ] = useState([]);
  const handleSetCalendarWeeklyUsersEventCountData = (
    data = [],
    hardReset = false
  ) => {
    setCalendarWeeklyUsersEventCountData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };

  useEffect(() => {
    handleSetCalendarWeeklyUsersEventCountData(
      calendarWeeklyUsersEventCountFetchData,
      true
    );
  }, [calendarWeeklyUsersEventCountFetchData]);

  const {
    data: calendarWeeklyWorkspacesEventCountFetchData,
    isFetching: isFetchingCalendarWeeklyWorkspacesEventCount,
  } = useGetCalendarWeeklyWorkspacesEventCountQuery(
    { ...searchParams },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipCalendarWeeklyWorkspacesEventCountData,
    }
  );

  const [
    calendarWeeklyWorkspacesEventCountData,
    setCalendarWeeklyWorkspacesEventCountData,
  ] = useState([]);
  const handleSetCalendarWeeklyWorkspacesEventCountData = (
    data = [],
    hardReset = false
  ) => {
    setCalendarWeeklyWorkspacesEventCountData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };

  useEffect(() => {
    handleSetCalendarWeeklyWorkspacesEventCountData(
      calendarWeeklyWorkspacesEventCountFetchData,
      true
    );
  }, [calendarWeeklyWorkspacesEventCountFetchData]);

  return {
    calendarWeeklyUsersEventCountData,
    isFetchingCalendarWeeklyUsersEventCount,

    calendarWeeklyWorkspacesEventCountData,
    isFetchingCalendarWeeklyWorkspacesEventCount,
  };
};

export default useWeeklyDataCounter;
