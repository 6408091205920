import { useSelector } from 'react-redux';
import Collapsible from '../../../Collapsible';
import { getEntity } from '../../../../../../selectors';
import useFilters from '../../../../../../hooks/useFilters';
import { useMemo } from 'react';
import Item from '../../../Collapsible/Item';

const Position = (props) => {
  const { value, labelKey, filterKey } = props;
  const entity = useSelector(getEntity);

  const { handleSelectMultipleAsString } = useFilters({
    entity,
    skip: true,
  });

  const items = useMemo(() => {
    return value?.split(',').map((item) => ({ value: item, name: item }));
  }, [value]);

  return (
    <Collapsible labelKey={labelKey} count={items?.length ?? 0}>
      {items.map((item) => (
        <Item
          key={item}
          item={item}
          handleItemChange={(item) =>
            handleSelectMultipleAsString(filterKey, item.value)
          }
        />
      ))}
    </Collapsible>
  );
};

export default Position;
