import dayjs from 'dayjs';
import { useState } from 'react';
import {
  formatDateToHours,
  formatDateToStandardFormat,
} from '../../../../../../../helpers/date.helper';

import { WowIcon } from '../../../../../../../components_new/WowBasicElements';
import Tooltip from '../../../../../../../components_new/Tooltip';

import { Card } from './styles';

const TimelinePresenceCards = ({ data = [], startDayTime }) => {
  const [customTooltipPositionX, setCustomTooltipPositionX] = useState(null);
  const [customTooltipPositionY, setCustomTooltipPositionY] = useState(null);

  return data.map((data, index) => {
    const isLoggedIn = !data?.data?.logout_at;
    const loginHours = data?.data?.logged_at
      ? `${
          dayjs(data.data.logged_at).isSame(startDayTime, 'day') &&
          !dayjs(new Date()).isSame(startDayTime, 'day')
            ? formatDateToStandardFormat(data.data.logged_at)
            : !dayjs(data.data.logged_at).isSame(startDayTime, 'day')
            ? formatDateToStandardFormat(data.data.logged_at)
            : ''
        } ${formatDateToHours(data.data.logged_at)}`
      : '';
    const logoutHours = data?.data?.logout_at
      ? `-${
          !dayjs(data.data.logout_at).isSame(startDayTime, 'day')
            ? formatDateToStandardFormat(data.data.logout_at)
            : ''
        } ${formatDateToHours(data?.data?.logout_at)}`
      : '';
    return (
      <Tooltip
        tooltipContent={
          <div>
            <div>{data?.data?.workspace?.name}</div>
            <div>
              {loginHours}
              {logoutHours}
            </div>
          </div>
        }
        enableCustomTooltip={true}
        customTooltipLeft={
          customTooltipPositionX ? `${customTooltipPositionX}px` : null
        }
        customTooltipTop={
          customTooltipPositionY ? `${customTooltipPositionY - 70}px` : null
        }
        customOnMouseEnter={(e) => {
          setCustomTooltipPositionY(e?.clientY ?? null);
          setCustomTooltipPositionX(e?.clientX ?? null);
        }}
        customOnMouseLeave={() => {
          setCustomTooltipPositionY(null);
          setCustomTooltipPositionX(null);
        }}
      >
        <Card
          key={data?.id ?? index}
          width={data?.width ?? 0}
          left={data?.left ?? 0}
          isLoggedIn={isLoggedIn}
        >
          {isLoggedIn ? (
            <WowIcon
              className="icon-people"
              color="#fff"
              size="13"
              spaceRight="5"
              spaceLeft="0"
            />
          ) : (
            ''
          )}
          {data?.data?.workspace?.name}
        </Card>
      </Tooltip>
    );
  });
};

export default TimelinePresenceCards;
