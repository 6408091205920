import styled from 'styled-components/macro';
import {
  gray,
  outline,
  hardWhite,
  newRed,
  gradientBlue,
  newGrayDarker,
  newGrayEvenLighter,
  darkerGray,
  white,
} from '../../config/colors';
import { WowBlock } from '../WowBasicElements';

export const OrderWrapper = styled(WowBlock)`
  ${({ order }) => (order ? `order: ${order}` : null)};
`;

export const ItemWrapper = styled.li`
  position: relative;
  margin-bottom: ${({ pinned, mBottom }) =>
    pinned ? '30px' : mBottom || '20px'};
  padding-bottom: ${({ pinned }) => (pinned ? '30px' : '0')};
  display: flex;
  align-items: center;
  border-bottom: ${({ pinned }) => (pinned ? `1px solid ${outline}` : 'none')};
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  width: calc(100% - 40px);
  padding-top: ${({ pTop }) => pTop || '5px'};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 7px;
  font-size: 15px;
  color: ${darkerGray};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    left: 20px;
    top: 15px;
    border-left: 1px solid
      ${({ isLastItem }) => (isLastItem ? 'white' : outline)};
    height: 100%;
    width: 1px;
  }

  button {
    padding: 12px !important;
  }

  i {
    color: ${gray};
  }
`;

export const HeaderName = styled.b`
  padding-right: 5px;
  color: ${darkerGray};
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderDate = styled.span`
  font-size: 12px;
  text-align: right;
  padding-right: 10px;
  color: #8399ab;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: unset;
  box-shadow: unset;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(${({ imageUrl }) => imageUrl});
  z-index: 10;
  background-color: white;
  border-radius: 100%;
  align-self: flex-start;
  flex-shrink: 0;
`;

export const TextWrapper = styled.div`
  background-color: ${({ shouldBeMarked }) =>
    shouldBeMarked ? newRed : hardWhite};
  border-radius: 6px;
  padding: 10px 15px;
  color: ${({ shouldBeMarked }) => (shouldBeMarked ? white : darkerGray)};
  font-size: ${({ size }) => (size ? size + 'px' : '15px')};
  letter-spacing: 0px;
  word-break: break-all;
  ${({ shouldBeMarked }) => (shouldBeMarked ? `padding-right: 0` : null)};
  ${({ isLink }) => (isLink ? `&:hover {cursor: pointer}` : null)};
  ${({ background }) =>
    background ? `background-color: ${background}` : null};
`;

export const ActivityText = styled.div`
  background-color: ${hardWhite};
  border-radius: 6px;
  padding: 10px 15px;
  letter-spacing: 0px;
  word-break: break-word;
  color: ${newGrayDarker};

  font-size: 15px;

  a {
    font-weight: bold;
    color: inherit !important;
    text-decoration: none;

    &:hover {
      color: ${gradientBlue} !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .line-through {
    text-decoration: line-through;
  }
`;

export const Text = styled.span`
  color: ${newGrayDarker};
  font-size: 15px;
  letter-spacing: 0px;

  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
`;

export const Status = styled.span`
  color: ${newGrayEvenLighter};

  font-size: 12px;
  line-height: 16px;
`;

export const ShowMoreLoaderWrapper = styled.div`
  position: relative;
  height: 25px;
`;
