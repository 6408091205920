import { transformFile } from '../../../../../../../components_new/MediaInput/util';
import { ERROR_MESSAGE_NO_ITEMS } from './constants';

const MB = 1024;
const MAX_FILE_SIZE = 10; //MB
const TOTAL_BATCH_SIZE = 20; //MB

export const mbToBytes = (size) => size * MB * MB;

export const validatedFiles = ({ files, config }) => {
  const totalFiles = files?.length;

  if (!totalFiles) throw new Error(ERROR_MESSAGE_NO_ITEMS);

  const maxFileSize = config?.maxFileSize ?? MAX_FILE_SIZE;
  const maxFileSizeInBytes =
    config?.maxFileSizeInBytes ?? mbToBytes(maxFileSize);
  const maxTotalSize = config?.totalBatchSize ?? TOTAL_BATCH_SIZE;
  const maxTotalSizeInBytes = mbToBytes(maxTotalSize);
  const invalidFiles = [];
  const validFiles = [];
  let totalSize = 0;
  let i = 0;

  while (i < totalFiles) {
    const file = files[i];

    totalSize += file.size;

    const formatedFile = transformFile(file);

    if (file.size > maxFileSizeInBytes) {
      invalidFiles.push(formatedFile);
    } else {
      validFiles.push(formatedFile);
    }

    i++;
  }

  const isMaxLimitExceeded = totalSize > maxTotalSizeInBytes;

  return {
    totalSize,
    invalidFiles,
    isMaxLimitExceeded,
    validFiles,
    hasValidFiles: validFiles.length,
  };
};

export const validationModalMessageWithSize = (
  intl,
  largeFiles,
  isMaxLimitExceeded,
  fileSize = 10
) => {
  if (isMaxLimitExceeded)
    return intl.formatMessage({ id: 'upload_above_total_limit' });

  const isSingleFile = largeFiles.length === 1;

  const message = isSingleFile
    ? intl
        .formatMessage({ id: 'upload_one_file_above_limit' })
        .replace(':file_name', largeFiles[0].name)
        .replace(':size', fileSize)
    : intl
        .formatMessage({ id: 'upload_multiple_files_above_limit' })
        .replace(':size', fileSize);

  return message;
};
