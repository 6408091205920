import axios from 'axios';
import qs from 'query-string';

import { getToken } from '../../../redux/utils';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`,
});

api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getToken()}`,
  };

  return config;
});

export const getWorkspaceRoles = async (id) =>
  await api.get(`/user/${id}/workspace_roles`);

export const checkPermissionsForAddingUsersToWorkspaces = async (body) =>
  await api.post('/workspace/check_availability', body);

export const getUserByEmail = async (email) => {
  const url = qs.stringifyUrl(
    { url: 'users/', query: { search: email } },
    { skipEmptyString: true, skipNull: true }
  );

  return await api.get(url);
};

export const getIssue = async (id) => await api.get(`/issue/${id}`);
