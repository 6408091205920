import React from 'react';
import styled from 'styled-components/macro';

import { darkerGray, outline, purple500, white } from '../../config/colors';
import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';
import { MenuItem, MenuItems, Menu } from '../Menu';
import { themeColour } from '../../helpers/theme';
import { WowIcon, WowTooltipWrapper } from '../WowBasicElements';
import Tooltip from '../Tooltip';
import { FormattedMessage } from 'react-intl';

const SortWrapper = styled.div`
  position: relative;
`;

const SortButton = styled.div`
  padding: ${({ disableOrder }) =>
    disableOrder ? '6px 14px' : '2px 12px 2px 2px'};
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  color: ${darkerGray};
  display: flex;
  align-items: center;
  > * {
    margin-left: 4px;
  }
  > *:first-child {
    margin-left: 0px;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: ${outline};
  }
`;

const StyledOrderButton = styled.button`
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  color: ${(props) => themeColour(props) ?? purple500};
  padding: 6px 8px;

  &:focus,
  &:hover,
  &:focus-within {
    outline: none;
    background-color: ${white};
  }
`;

const StyledDropdownArrow = styled.i`
  color: ${(props) => themeColour(props) ?? purple500};
`;

const OrderButton = ({ order, onClick }) => {
  return (
    <StyledOrderButton
      onClick={onClick}
      mainAccent
      data-testid="sortOrderButton"
      type="button"
    >
      {
        <i
          className={`icon ${
            order === 'asc' ? 'icon-arrowup' : 'icon-arrowdown'
          }`}
        />
      }
    </StyledOrderButton>
  );
};

const SortItem = ({ selected, onClick, children }) => {
  return (
    <MenuItem onClick={onClick} selected={selected} tabIndex={0} mainAccent>
      {children} {selected ? <i className="icon icon-check" /> : null}
    </MenuItem>
  );
};

const Sort = ({
  onChange,
  options,
  selectedOption,
  order,
  onOrderChange,
  menuPosition,
  disableOrder = false,
}) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  useKeyPress('Escape', () => setIsVisible(false));

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleChange = (value) => () => {
    setIsVisible(false);

    onChange(value);
  };

  const handleOrderChange = (e) => {
    e.stopPropagation();

    onOrderChange();
  };

  const selected = options.find((option) => option.value === selectedOption);

  return (
    <SortWrapper ref={ref}>
      <SortButton onClick={handleClick} disableOrder={disableOrder}>
        {!disableOrder && (
          <OrderButton order={order} onClick={handleOrderChange} />
        )}
        {selected?.infoData?.show && (
          <Tooltip
            tooltipContent={
              <WowTooltipWrapper width="150">
                <FormattedMessage
                  id={selected?.infoData?.tooltipMessageKey ?? ' '}
                />
              </WowTooltipWrapper>
            }
          >
            <WowIcon
              className="icon-important-round"
              color={selected?.infoData?.iconColor ?? '#fff'}
              size="18"
              spaceRight="15"
              spaceLeft="0"
            />
          </Tooltip>
        )}
        {selected?.label}{' '}
        <StyledDropdownArrow mainAccent className="icon icon-arrowdown-02" />
      </SortButton>

      {isVisible ? (
        <Menu position={menuPosition}>
          <MenuItems>
            {options
              .filter((item) => !item.hideOnDropdown)
              .map((option) => (
                <SortItem
                  onClick={handleChange(option.value)}
                  key={option.value}
                  selected={option.value === selected?.value}
                >
                  {option.label}
                </SortItem>
              ))}
          </MenuItems>
        </Menu>
      ) : null}
    </SortWrapper>
  );
};

export default Sort;
