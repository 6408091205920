import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from '../../render-if/render-if.component';
import { MediaRenderer } from '../media-renderer/media-renderer';
import { LoadingSpinner } from '../../loading-spinner/loading-spinner.component';
import { IconCircle } from '../../icon-circle/icon-circle.component';
import { Menu } from '../../menu/menu.component';
import { MenuItem } from '../../menu/menu-item/menu-item.component';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { white, darkerGray, outline } from '../../../config/colors';
import dict from '../../../config/multilang';
import { downloadImage } from '../../../utils/utility';
import { AutocompleteTextArea } from '../../form/text-area/autocomplete-text-area';
import { breakpoint } from '../../../styles/styledComponents/media-breakpoints';

const Image = styled.div`
  width: 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
  img {
    max-width: none;
    max-height: none;
    width: 100%;
    height: auto;
  }
`;

const Info = styled.div`
  width: 60%;
  padding: 0 20px 20px;
  height: 125px;

  @media only screen and ${breakpoint.xs} {
    padding: 0 10px 10px;
  }
`;

const InfoTitle = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${outline};
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media only screen and ${breakpoint.xs} {
    height: 40px;
    margin-top: 5px;
  }
`;

const ItemButtons = styled.div`
  position: relative;
  margin-left: auto;
  @media print {
    display: none !important;
  }
`;

const ListItem = styled.div`
  width: 60%;
  min-width: 600px;
  max-width: 680px;
  height: 180px;
  display: flex;
  background-color: ${white};
  margin: 30px auto;
  border-radius: 10px;

  @media only screen and ${breakpoint.xs} {
    max-width: 100%;
    min-width: 0;
    width: 100%;
    height: 140px;
    margin: 20px auto;
  }
`;

const ResizeIcon = styled.span`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  i {
    color: ${white};
  }
`;

const UserImage = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 15px;
  margin-top: 0;
  border-radius: 50%;

  @media only screen and ${breakpoint.xs} {
    width: 25px;
    margin-right: 10px;
  }
`;

const UserName = styled.span`
  color: ${darkerGray};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and ${breakpoint.xs} {
    font-size: 12px;
  }
`;

const TextAreaWrapper = styled.div`
  position: relative;
`;

export const GalleryListItem = (props) => {
  const [hasDescChanged, setDescAsChanged] = useState(false);

  const t = useIntl();

  const handleSelectItem = () => {
    props.onSelectItem(props.item);
  };

  const handleDescChange = (event) => {
    setDescAsChanged(true);
    props.handleDescChange(event, props.index);
  };

  const handleListItemDownload = () => {
    downloadImage(props.url, props.name);
  };

  const handleListItemRemoved = () => {
    props.handleListItemRemoved(props.index);
  };

  const toggleOptionMenu = () => {
    props.setOpenMenu(props.id);
  };

  const handlePrint = () => {
    window.print();
  };

  const menuShown = props.id === props.openMenu;

  if (!props.mediaPresent) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <RenderIf if={props.mediaPresent}>
        <ListItem>
          <Image onClick={handleSelectItem}>
            <MediaRenderer
              mimeType={props.mimeType}
              url={props.url}
              description={props.description}
            />
            <RenderIf if={props.canResizeItem}>
              <ResizeIcon>
                <i className="icon icon-expand" />
              </ResizeIcon>
            </RenderIf>
          </Image>
          <Info>
            <InfoTitle>
              <UserImage
                src={props.user?.profileImageUrl ?? props.user?.profile_image}
              />
              <UserName>{props.user?.name ?? props.user?.full_name}</UserName>

              <ItemButtons>
                <IconCircle
                  icon="icon-dots"
                  onClick={toggleOptionMenu}
                  color="gray"
                />
                <RenderIf if={menuShown}>
                  <Menu className="menu--position-change" extraWide={true}>
                    <MenuItem
                      key={'menu-option-export-item'}
                      text={t
                        .formatMessage({
                          id: 'download',
                          defaultMessage: dict.download,
                        })
                        .toUpperCase()}
                      extraHigh={true}
                      icon="icon-download"
                      isFilterComponent
                      onChangeFilter={handleListItemDownload}
                      secondaryAccent
                      noThemeText
                    />
                    <MenuItem
                      key={'menu-option-print-item'}
                      text={t
                        .formatMessage({
                          id: 'print',
                          defaultMessage: dict.print,
                        })
                        .toUpperCase()}
                      extraHigh={true}
                      icon="icon-print-1"
                      isFilterComponent
                      onChangeFilter={handlePrint}
                      secondaryAccent
                      noThemeText
                    />
                    <RenderIf if={props.canRemove}>
                      <MenuItem
                        key="menu-option-delete-item"
                        text={t
                          .formatMessage({
                            id: 'delete_issue',
                            defaultMessage: dict.delete_issue,
                          })
                          .toUpperCase()}
                        extraHigh
                        icon="icon-trash"
                        isFilterComponent
                        onChangeFilter={handleListItemRemoved}
                        noTheme
                        red
                        noThemeText
                      />
                    </RenderIf>
                  </Menu>
                </RenderIf>
              </ItemButtons>
            </InfoTitle>
            <TextAreaWrapper style={{ height: '100%' }}>
              <AutocompleteTextArea
                name="description"
                placeholder={props.placeholder}
                noBorder
                handleChange={handleDescChange}
                value={props.description || ''}
                maxLength={255}
                enableAutocomplete={true}
                backgroundColor={white}
                showSaveButton={hasDescChanged && props.showSaveOnDescription}
                onSaveText={props.onSaveDescriptionClick}
                isSmallView
                disabled={!props.canEdit}
                showCharactersLeft={props.canEdit}
              />
            </TextAreaWrapper>
          </Info>
        </ListItem>
      </RenderIf>
    </Fragment>
  );
};

export const sliderItemShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  mediaPresent: PropTypes.bool,
  mimeType: PropTypes.string.isRequired,
  mediaType: PropTypes.string,
  index: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  description: PropTypes.string,
  showAvatar: PropTypes.bool,
  canResizeItem: PropTypes.bool,
  onSelectItem: PropTypes.func,
  user: PropTypes.shape({
    profileImageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  canRemove: PropTypes.bool,
  canEdit: PropTypes.bool,
});

GalleryListItem.defaultProps = {
  mediaPresent: true,
};

GalleryListItem.propTypes = PropTypes.shape(sliderItemShape).isRequired;
