import { createSelector } from '@reduxjs/toolkit';
import { equipmentApi } from './service';

const getEquipmentState = (state) => state.equipment;

const getAdvancedFilterState = createSelector(
  getEquipmentState,
  (state) => state.listing.isAdvancedFilterOpen
);

const getItemDensity = createSelector(
  getEquipmentState,
  (state) => state.listing.density
);

const getQueryParams = (target) =>
  createSelector(getEquipmentState, (state) => state[target].query);

const getFilters = (target) =>
  createSelector(getEquipmentState, (state) => state[target].filters);

const getSelectedItems = (target) =>
  createSelector(getEquipmentState, (state) => state[target].selectedItems);

const getUngroupState = createSelector(
  getEquipmentState,
  (state) => state.groups.ungroup
);

const selectEquipmentResult = (id) =>
  equipmentApi.endpoints.getEquipment.select(id);

const selectEquipment = (id) =>
  createSelector(
    selectEquipmentResult(id),
    (equipmentResult) => equipmentResult?.data
  );

export {
  getAdvancedFilterState,
  getItemDensity,
  getQueryParams,
  getFilters,
  getUngroupState,
  getSelectedItems,
  selectEquipment,
};
