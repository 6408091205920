import { WORKSPACE_NAME } from '../contants';
import useOnboarding from '../../../hooks/useOnboarding';
import { STEP_TYPES } from '../../../constants';
import useTrialRedirect from '../../../hooks/useTrialRedirect';

const useCreateWorkspace = ({ formMethods } = {}) => {
  const { watch } = formMethods;
  const workspaceName = watch(WORKSPACE_NAME);

  const { handleUpdateSteps, isLoading } = useOnboarding();

  const { handleRedirectTo } = useTrialRedirect();

  const handleContinue = (data) => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.createWorkspace,
      ...data,
    });
  };

  const handleSkip = () => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.createWorkspace,
      isSkipped: true,
    });
    handleRedirectTo();
  };

  return {
    workspaceName,
    handleContinue,
    handleSkip,
    isLoading,
  };
};

export default useCreateWorkspace;
