import styled from 'styled-components/macro';
import {
  confirmGreen,
  darkerGray,
  filtersAdvancedGray,
} from '../../config/colors';
import Input from '../../components_new/Input/index';

export const InputsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  gap: 34px;
  margin-bottom: 24px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  > button {
    padding-left: 0px;
    padding-right: 0px;
    width: 136px;
  }
`;

export const InfoText = styled.div`
  color: ${filtersAdvancedGray};
  margin-bottom: 24px;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  left: 0;
  bottom: -18px;
  color: red;
  font-size: 12px;
`;

export const Label = styled.label`
  position: relative;
`;

export const StyledInput = styled(Input)`
  color: ${darkerGray};

  ${({ isDirtyAndValid }) =>
    isDirtyAndValid ? `border-color: ${confirmGreen}` : null};
`;
