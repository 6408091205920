import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TYPES } from '../../constants';
import {
  setCurrentActiveIssueDetailsModal,
  setCurrentActiveViewMoreModal,
  setTimelineIssueDraggableElementId,
} from '../../../../slice';
import { getCurrentActiveIssueDetailsModal } from '../../../../selectors';

import CalendarTimelineCard from '../../../CalendarTimelineCard';
import IssueDetailsModal from '../../../IssueDetailsModal';

const CalendarTimelineCardWrapper = (props) => {
  const {
    type,
    timelineData = [],
    handleUpdateDataItem = () => {},
    handleDeleteDataItem = () => {},
    removeItemsFromUnscheduledSidebar = () => {},
    draggableTimelineIssueRevertData,
  } = props;

  const dispatch = useDispatch();
  const [presetModalValue, setPresetModalValue] = useState(0);

  const currentActiveIssueDetailsModal = useSelector(
    getCurrentActiveIssueDetailsModal
  );

  const handleToggleDetailsComponent = ({ id, isSetup, presetLeftValue }) => {
    setPresetModalValue(0);
    dispatch(
      setCurrentActiveIssueDetailsModal(
        currentActiveIssueDetailsModal === id ? null : id
      )
    );
    if (isSetup) handleDeleteDataItem(id);
    else dispatch(setTimelineIssueDraggableElementId(id));
    if (presetLeftValue) setPresetModalValue(presetLeftValue);
    dispatch(setCurrentActiveViewMoreModal(null));
    if (draggableTimelineIssueRevertData?.current) {
      const existingData = timelineData.find((item) => item.id === id);
      if (existingData)
        handleUpdateDataItem(
          {
            id,
            ...existingData.data,
            ...draggableTimelineIssueRevertData.current,
          },
          { sameAssignee: true }
        );
    }
  };

  return timelineData.map((data, index) => {
    if (!data.showInCalendar) return <></>;

    const issueDetailsLeftValue = presetModalValue - (data?.left ?? 0);

    return (
      <CalendarTimelineCard
        key={data.id ?? index}
        id={data?.id}
        left={data?.left ? data.left : 0}
        width={data?.width ? data.width : 0}
        height={data?.height ? data.height : 'auto'}
        top={data?.top ? data.top : 0}
        item={data?.data}
        type={TYPES[type].timelineCardType}
        dataAttributeId={data?.id ?? null}
        titleMaxLength={data?.titleMaxLength}
        workspaceTitleMaxLength={data?.workspaceTitleMaxLength}
        hours={data?.hours}
        allowHoverDetailsTrigger={false}
        detailsComponent={
          <IssueDetailsModal
            id={data?.id}
            overrideData={{ ...(data?.data ?? {}) }}
            isSetup={data?.data?._setup}
            onClose={handleToggleDetailsComponent}
            handleUpdateDataItem={handleUpdateDataItem}
            handleDeleteDataItem={handleDeleteDataItem}
            removeItemsFromUnscheduledSidebar={
              removeItemsFromUnscheduledSidebar
            }
            leftValue={
              issueDetailsLeftValue < 100 ? 0 : `${issueDetailsLeftValue}px`
            }
            isScheduledPreparedTask={data?.data?.is_scheduled_prepared_task}
            draggableTimelineIssueRevertData={draggableTimelineIssueRevertData}
          />
        }
        showDetailsComponent={currentActiveIssueDetailsModal === data.id}
        handleToggleDetailsComponent={handleToggleDetailsComponent}
        draggableTimelineIssueRevertData={draggableTimelineIssueRevertData}
      />
    );
  });
};

export default CalendarTimelineCardWrapper;
