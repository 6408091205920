import { getData, saveData } from '../redux/utils';

export const setFiltersPersist = (key, filterData, isPrimitive) => {
  const persistedFilters = getData('https://staging.wowflow.org-filters');

  const formatedFilters = {
    ...persistedFilters,
    [key]: isPrimitive
      ? filterData
      : {
          ...(persistedFilters?.[key] || {}),
          ...filterData,
        },
  };

  saveData(formatedFilters, 'https://staging.wowflow.org-filters');
};

export const formatMultiselectIdsWithChildren = (
  selectedIds,
  item,
  shouldIncludeChildren = true
) => {
  const isSelected = selectedIds.includes(String(item.id));

  let newIds = isSelected
    ? selectedIds.filter((id) => id !== String(item.id))
    : [...selectedIds, String(item.id)];

  if (item.children?.length && shouldIncludeChildren) {
    const childrenIds = item.children.map((child) => String(child.id));

    if (isSelected) {
      newIds = newIds.filter((id) => !childrenIds.includes(id));
    } else {
      for (const childId of childrenIds) {
        if (!newIds.includes(childId)) newIds.push(childId);
      }
    }
  }

  return newIds;
};

export const formatMultiselectItemsWithChildren = (
  selectedItems,
  item,
  shouldIncludeChildren = true
) => {
  const isSelected = !!selectedItems.filter(
    (_item) => Number(_item.id) === Number(item.id)
  ).length;

  let newItems = isSelected
    ? selectedItems.filter((_item) => _item.id !== item.id)
    : [...selectedItems, item];

  if (item.children?.length && shouldIncludeChildren) {
    const childrenIds = new Set(item.children.map((child) => child.id));

    if (isSelected) {
      newItems = newItems.filter((_item) => !childrenIds.has(_item.id));
    } else {
      const newItemsIds = new Set(newItems.map((item) => item.id));
      for (const child of item.children) {
        if (!newItemsIds.has(child.id)) newItems.push(child);
      }
    }
  }

  return newItems;
};
