import { useEffect, useMemo, useRef, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { ERROR_MESSAGES_KEYS, INPUT_MAX_LENGTH } from '../constants';
import { cloneDeep } from 'lodash-es';

const useAdd = (defaultUser, validationCallback) => {
  const _formatInitUser = () => {
    return { ...defaultUser, fields: [...defaultUser.fields] };
  };

  const refModalIsActive = useRef();
  const [users, setUsers] = useState([_formatInitUser()]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSetActive = (id) => {
    const clonedUsers = cloneDeep(users).map((item) => ({
      ...item,
      isActive: false,
    }));
    const index = clonedUsers.findIndex((item) => item.id === id);

    clonedUsers[index].isActive = true;

    setUsers(clonedUsers);
  };

  const handleAdd = () => {
    const lastUserId = users[users.length - 1].id;
    const newUserId = lastUserId + 1;

    setUsers([
      ...users.map((item) => ({ ...item, isActive: false })),
      { ..._formatInitUser(), id: newUserId, isActive: true },
    ]);
  };

  const handleReset = () => setUsers([_formatInitUser()]);

  const handleChange = (id, newItem) => {
    const clonedUsers = cloneDeep(users);
    const index = clonedUsers.findIndex((item) => item.id === id);
    const fieldIndex = clonedUsers[index].fields.findIndex(
      (item) => item.fieldId === newItem.fieldId
    );

    clonedUsers[index].fields[fieldIndex] = newItem;

    setUsers(clonedUsers);
  };

  useEffect(() => {
    if (!refModalIsActive?.current) {
      refModalIsActive.current = true;
      return;
    }

    const _canSubmit = Object.keys(errors).length === 0;

    setCanSubmit(_canSubmit);
  }, [errors]);

  const [showContactWowflow, setShowContactWowflow] = useState();
  const [collaboratorHasAccount, setCollaboratorHasAccount] = useState({});

  const _handleIfUserExistCheck = async (currentUser) => {
    try {
      const response = await validationCallback({
        email: currentUser.fields[0].value,
      }).unwrap();

      setShowContactWowflow(false);
      setCollaboratorHasAccount((prevState) => ({
        ...prevState,
        [currentUser.id]: false,
      }));

      if (response?.id) {
        setErrors((prevState) => ({
          ...prevState,
          [currentUser.id]: ERROR_MESSAGES_KEYS.existing,
        }));
        return;
      }

      const clonedUsers = cloneDeep(users);
      const index = clonedUsers.findIndex((item) => item.id === currentUser.id);

      //handle if client exists
      if (response?.client) {
        const { logo, name } = response.client;

        clonedUsers[index].fields[1] = {
          ...clonedUsers[index].fields[1],
          logo,
          value: name,
        };

        if (!response.is_user_admin) {
          setCollaboratorHasAccount((prevState) => ({
            ...prevState,
            [currentUser.id]: true,
          }));
        }
      } else {
        clonedUsers[index].fields[1] = {
          ...clonedUsers[index].fields[1],
          logo: null,
          value: '',
        };
      }

      setUsers(clonedUsers);

      setErrors((prevState) => {
        const { [currentUser.id]: removedField, ...rest } = prevState;
        return rest;
      });
    } catch (error) {
      if (error?.data?.error_key) {
        setErrors((prevState) => ({
          ...prevState,
          [currentUser.id]: error.data.error_key,
        }));
        return;
      }

      if (error) setShowContactWowflow(true);
    }
  };

  const handleValidateUserEmail = (currentUser) => {
    const { id, fields } = currentUser;
    const _value = fields[0].value;
    const isAlreadyAdded =
      !!_value.length &&
      users.some((user) => user.id !== id && user.fields[0].value === _value);

    if (
      _value.length > INPUT_MAX_LENGTH ||
      !isEmail(_value) ||
      isAlreadyAdded
    ) {
      const errorMessageKey = isAlreadyAdded
        ? ERROR_MESSAGES_KEYS.alreadyAdded
        : ERROR_MESSAGES_KEYS.invalidFormat;
      setErrors((prevState) => ({ ...prevState, [id]: errorMessageKey }));
      return;
    }

    _handleIfUserExistCheck(currentUser);

    if (errors[id] && errors[id] !== ERROR_MESSAGES_KEYS.existing) {
      const clonedErrors = { ...errors };
      delete clonedErrors[id];
      setErrors(clonedErrors);
    }
  };

  const handleDelete = (id) => {
    const newUsers = users.filter((item) => item.id !== id);
    setUsers(newUsers);
  };

  const formatNewUsersPayload = () => {
    const newUsers = users.map((user) => {
      const { fields } = user;
      return {
        ...fields.reduce((acc, field) => {
          acc[field.name] = field.value;
          return acc;
        }, {}),
      };
    });

    return newUsers;
  };

  const showCollaboratorHasAccount = useMemo(() => {
    return !!Object.values(collaboratorHasAccount).find(
      (item) => item === true
    );
  }, [collaboratorHasAccount]);

  return {
    users,
    canSubmit,
    errors,
    handleAdd,
    handleReset,
    handleChange,
    handleValidateUserEmail,
    handleDelete,
    formatNewUsersPayload,
    showContactWowflow,
    showCollaboratorHasAccount,
    collaboratorHasAccount,
    handleSetActive,
  };
};

export default useAdd;
