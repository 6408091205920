import { useEffect } from 'react';
import styled from 'styled-components/macro';
import Modal from '../Modal';
import { WowIcon } from '../WowBasicElements';
import { greenButtonColor } from '../../config/colors';

const IconWrapper = styled.div`
  width: 135px;
  height: 135px;
  background-color: ${greenButtonColor};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalSuccess = ({ isOpen, onClose }) => {
  const CLOSE_TIMEOUT_MS = 300;
  const SET_TIMEOUT_MS = 800;

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      onClose();
    }, SET_TIMEOUT_MS);
  }, [onClose, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseIcon
      contentStyleOverride={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
      }}
      closeTimeoutMS={CLOSE_TIMEOUT_MS}
      overlayClassName="with-fade"
    >
      <IconWrapper>
        <WowIcon
          className="icon icon-complete"
          size="100"
          color="white"
          spaceRight="0"
        />
      </IconWrapper>
    </Modal>
  );
};

export default ModalSuccess;
