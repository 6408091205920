export const FORM_DEFAULT_VALUES = {
  title: '',
  description: '',
  attachments: [],
  workspace_id: '',
  status_id: 1,
  priority_id: 3,
  category_id: '',
  start_date: null,
  due_date: null,
  watchers: [],
  assignee_id: '',
  external_email_address: '',
  equipment_ids: [],
  equipments: [],
  asset_id: '',
  asset: null,
  asset_group_id: '',
  asset_group: null,
  accessibility_id: 2,
  is_attachment_required: false,
  links: [],
};

export const STATUS_ICONS = {
  'status.to_do': 'to-do',
  'status.in_review': 'review',
  'status.in_progress': 'in-progress',
  'status.completed': 'complete',
};

export const STATUS_OPTIONS = [
  {
    key: 'status.to_do',
    value: 3,
  },

  {
    key: 'status.declined', // ? does this exist properly
    value: 4,
  },
  {
    key: 'status.to_do', // accepted ?
    value: 5,
  },

  {
    key: 'status.in_progress',
    value: 6,
  },

  {
    key: 'status.in_review',
    value: 7,
  },
  {
    key: 'status.completed',
    value: 8,
  },
];

export const EXPORT_CANCELED = 'canceled';

export const RAW_ISSUE_STATUSES = {
  1: {
    id: 1,
    name: 'status.to_do',
    type: 'CREATED',
  },
  2: {
    id: 2,
    name: 'status.to_do',
    type: 'TO_DO',
  },
  3: {
    id: 3,
    name: 'status.to_do',
    type: 'DECIDING',
  },
  4: {
    id: 4,
    name: 'status.declined',
    type: 'DECLINED',
  },
  5: {
    id: 5,
    name: 'status.to_do',
    type: 'ACCEPTED',
  },
  6: {
    id: 6,
    name: 'status.in_progress',
    type: 'IN_PROGRESS',
  },
  7: {
    id: 7,
    name: 'status.in_review',
    type: 'IN_REVIEW',
  },
  8: {
    id: 8,
    name: 'status.completed',
    type: 'COMPLETED',
  },
};

export const STATUS_IDS = {
  CREATED: 1,
  TO_DO: 2,
  DECIDING: 3,
  DECLINED: 4,
  ACCEPTED: 5,
  IN_PROGRESS: 6,
  IN_REVIEW: 7,
  COMPLETED: 8,
};

export const PHOTO_REQUIRED_AVAILABLE_STATUSES = [
  STATUS_IDS.CREATED,
  STATUS_IDS.TO_DO,
  STATUS_IDS.DECIDING,
  STATUS_IDS.ACCEPTED,
  STATUS_IDS.DECLINED,
];
