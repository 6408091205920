import styled from 'styled-components/macro';
import {
  filesGrayTransparent,
  gray,
  iconGray,
  newRed,
  white,
  darkerGray,
  filtersAdvancedGray,
  gradientBlue,
  hardWhite,
} from '../../../../../config/colors';

export const Button = styled.button`
  margin-right: 14px;
  padding: 6px;
  background-color: ${white};
  position: relative;
  cursor: pointer;
`;

export const BellIcon = styled.i.attrs({ className: 'icon icon-activity' })`
  color: ${gray};
  font-size: 24px;
  height: 24px;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 500px;
  background: ${white};
  border-radius: 10px;
  box-shadow: 0px 4px 20px #3b4a7433;
  border: 1px solid #d6dde3;
`;

export const UnreadCount = styled.span`
  position: absolute;
  background-color: ${newRed};
  top: 3px;
  right: -2px;
  min-width: 18px;
  border-radius: 50%;
  color: ${white};
  letter-spacing: -0.5px;
  padding: 2px;
  font-size: 10px;
`;

export const NoNotificationsText = styled.h3`
  color: ${filesGrayTransparent};
  margin-left: 24px;
`;

export const NoMoreNotificationsText = styled.div`
  margin: 22px auto 42px;
  text-align: center;
  color: ${filesGrayTransparent};
  font-size: 14px;

  > i {
    font-size: 46px;
    color: ${iconGray};
    display: block;
  }
`;

export const Title = styled.span`
  font-size: 21px;
  color: ${darkerGray};
  font-weight: 600;
  display: block;
  margin: 17px 0;
`;
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const GearIcon = styled.i`
  font-size: 18px;
  color: ${filtersAdvancedGray};
  margin-right: 14px;
  cursor: pointer;
`;
export const Dots = styled.i`
  font-size: 20px;
  color: #4a5b6d;
  cursor: pointer;
`;
export const MenuWrapper = styled.div`
  height: 100%;
  position: relative;
`;
export const MenuContainer = styled.div`
  position: absolute;
  width: 200px;
  right: -40px;
  top: -10px;
`;
export const MenuText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${darkerGray};
  letter-spacing: 0px;
`;

export const ShowUnreadContainer = styled.div`
  height: 40px;
  background-color: ${hardWhite};
  border-radius: 6px;
  width: 100%;
  margin-bottom: ${({ smallBottom }) => (smallBottom ? 10 : 25)}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const OuterContainer = styled.div`
  padding: 0 20px;
`;

export const UnreadText = styled.span`
  font-size: 15px;
  color: ${darkerGray};
  letter-spacing: 0px;
  display: block;
`;
export const CancelText = styled.span`
  font-size: 15px;
  color: ${gradientBlue};
  font-weight: 600;
  display: block;
  margin-left: 3px;
  cursor: pointer;
`;

export const Container = styled.div`
  padding-bottom: 20px;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 700px;
`;
