import { useDispatch, useSelector } from 'react-redux';
import { getEntity } from '../selectors';
import useFilters from './useFilters';
import { useMemo } from 'react';
import { updatePersistedFilters } from '../../../redux/config/actions';

const useStaticFilterMultiselect = (filterKey, filterValues) => {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);

  const { persistedFilters, handleFilterReset, formatStringFilter } =
    useFilters({ entity, skip: true });

  const selected = persistedFilters?.parameters?.[filterKey] ?? '';

  const isResetDisabled = !selected;

  const countSelected = useMemo(() => {
    if (!selected?.length) return 0;
    return selected.split(',').length;
  }, [selected]);

  const handleFilterChange = (value) => {
    const currentString = persistedFilters.parameters?.[filterKey] ?? '';

    const allPriorities = Object.values(filterValues);
    const isSelectAll = value === 'all';
    const newString = isSelectAll
      ? allPriorities.toString() !== currentString
        ? allPriorities.toString()
        : null
      : formatStringFilter(currentString, value);

    const mutatedParameters = { ...persistedFilters.parameters };

    if (newString?.length) {
      mutatedParameters[filterKey] = newString;
    } else {
      if (filterKey in mutatedParameters) delete mutatedParameters[filterKey];
    }

    dispatch(updatePersistedFilters(mutatedParameters, entity));
  };

  const filterValuesArray = useMemo(() => {
    return Object.values(filterValues);
  }, [filterValues]);

  return {
    selected,
    isResetDisabled,
    countSelected,
    handleFilterChange,
    filterValuesArray,
    handleFilterReset,
  };
};

export default useStaticFilterMultiselect;
