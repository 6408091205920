import { createSlice } from '@reduxjs/toolkit';
import { INIT_QUERY_PARAMS } from './constants';

const INIT_STATE = {
  filters: INIT_QUERY_PARAMS,
};

export const userSlice = createSlice({
  name: 'profile',
  initialState: INIT_STATE,
  reducers: {
    setFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

export const { setFilters } = userSlice.actions;

export default userSlice.reducer;
