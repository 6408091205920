import styled from 'styled-components/macro';

import { darkerGray } from '../../../../../../../config/colors';

export const Wrapper = styled.div`
  padding: 15px;
  padding-top: 0;
`;

export const FieldRow = styled.div`
  margin-bottom: 15px;
  display flex;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
  justify-content: space-between;
`;

export const InputFieldLabel = styled.div`
  flex: 1;
  font-size: 16px;
  color: ${darkerGray};
  max-width: 130px;
`;

export const InputFieldBox = styled.div`
  flex: 1;
  max-width: 190px;
`;
