import { pdfBlue, pdfRed, userGray, xslGreen } from '../../config/colors';

export const FILE_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
  CSV2: '.csv',
  EML: 'message/rfc822',
  MSG: 'application/vnd.ms-outlook',
  MSG2: '.msg',
};

export const ACCEPTED_FILE_TYPES = Object.values(FILE_TYPES);

export const IMAGE_FILE_TYPES = [
  FILE_TYPES.JPEG,
  FILE_TYPES.PNG,
  FILE_TYPES.GIF,
];

// TODO: Use lib instead
export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const filterToImages = (files) => {
  return files.filter((file) =>
    IMAGE_FILE_TYPES.some((type) => type === file.type)
  );
};

export const splitToImagesAndDocuments = (files) => {
  let images = [];
  let documents = [];

  files.forEach((file) => {
    if (file.deleted) return;

    if (IMAGE_FILE_TYPES.some((type) => type === file.type)) {
      images.push(file);
    } else {
      documents.push(file);
    }
  });

  return { images, documents };
};

export const getFileIcon = (type) => {
  switch (type) {
    case FILE_TYPES.DOC:
    case FILE_TYPES.DOCX:
      return { name: 'icon-doc', color: pdfBlue };
    case FILE_TYPES.XLS:
    case FILE_TYPES.XLSX:
      return { name: 'icon-xls', color: xslGreen };

    case FILE_TYPES.JPEG:
    case FILE_TYPES.PNG:
    case FILE_TYPES.GIF:
      return { name: 'icon-photo', color: userGray };
    case FILE_TYPES.PDF:
      return { name: 'icon-pdf', color: pdfRed };
    case FILE_TYPES.CSV:
    case FILE_TYPES.CSV2:
      return { name: 'icon-csv', color: xslGreen };

    case FILE_TYPES.EML:
    case FILE_TYPES.MSG:
    case FILE_TYPES.MSG2:
      return { name: 'icon-mail', color: userGray };

    default:
      return { name: '', color: '' };
  }
};

export const downloadFile = async (file) => {
  let url = file.preview;

  if (file.id) {
    const responseBlob = await fetch(file.url).then((resp) => resp.blob());

    url = window.URL.createObjectURL(new Blob([responseBlob]));
  }

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', file.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const printFile = (file) => {
  const pdfWindow = window.open(
    file.preview,
    'PRINT',
    'toolbar=no,status=no,width=900,height=1000'
  );

  if (file.id) {
    const element = document.getElementById(file.preview);

    element.style.maxWidth = '100%';
    element.style.maxHeight = '100%';

    pdfWindow.document.write(element.outerHTML);

    // pdfWindow.document.close() doesn't seem to work so
    // I added pdfWindow.close() after pdfWindow.print()
    pdfWindow.document.close();
    setTimeout(() => {
      pdfWindow.focus();
      pdfWindow.print();
      pdfWindow.close();
    }, 1000);
  } else {
    pdfWindow.onload = () => {
      setTimeout(() => {
        pdfWindow.focus();
        pdfWindow.print();
        pdfWindow.close();
      }, 1000);
    };
  }
};

export const VIEW_MODES = {
  gallery: 'gallery',
  list: 'list',
  documents: 'documents',
  closed: 'closed',
};

export const getSlideSize = (slides) => {
  if (slides.length > 2) {
    return 'small';
  }

  return 'big';
};

export const SLIDE_DIMENSIONS_BIG = {
  width: '352px',
  height: '198px',
};

export const SLIDE_DIMENSIONS_SMALL = {
  width: '272px',
  height: '153px',
};

export const SLIDE_DIMENSIONS_EXTRA_SMALL = {
  width: '103px',
  height: '64px',
};

export const getSlideDimensions = (size = 'small') => {
  if (size === 'small') {
    return SLIDE_DIMENSIONS_SMALL;
  } else if (size === 'extra-small') {
    return SLIDE_DIMENSIONS_EXTRA_SMALL;
  } else {
    return SLIDE_DIMENSIONS_BIG;
  }
};

export const POSITION = {
  left: 'left',
  right: 'right',
};

export const MAX_FILES_SIZE = 20e6; // 20MB

export const bytesToMb = (sizeInBytes) =>
  (sizeInBytes / (1024 * 1024)).toFixed(2);

export const isFileTooBig = (file, customMaxFileSize) => {
  if (customMaxFileSize) {
    if (file.size > customMaxFileSize) {
      return true;
    }
  } else {
    return false;
  }
};

const isFileImage = (file) => {
  return IMAGE_FILE_TYPES.some((type) => type === file.type);
};

export const compressImage = (file, callback, maxWidth) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.addEventListener(
    'load',
    () => {
      const img = new Image();

      img.src = reader.result;

      img.onload = () => {
        const elem = document.createElement('canvas');
        const scaleFactor = maxWidth / img.width;
        elem.width = maxWidth;
        elem.height = img.height * scaleFactor;
        const ctx = elem.getContext('2d');

        ctx.drawImage(img, 0, 0, elem.width, elem.height);

        ctx.canvas.toBlob(async (blob) => {
          const compressedFile = new File([blob], file.name, {
            type: FILE_TYPES.JPEG,
            lastModified: Date.now(),
          });

          callback(compressedFile);
        }, FILE_TYPES.JPEG);
      };
    },
    false
  );
};

const getCompressedFileWithRecursion = async (
  file,
  maxWidth = 1920,
  customMaxFileSize
) => {
  const compressedFile = await new Promise((resolve) => {
    compressImage(
      file,
      (compressedFile) => {
        resolve(compressedFile);
      },
      maxWidth
    );
  });

  if (isFileTooBig(compressedFile, customMaxFileSize)) {
    return await getCompressedFileWithRecursion(
      compressedFile,
      (maxWidth * 2) / 3,
      customMaxFileSize
    );
  }

  return compressedFile;
};

export const validateFiles = async (
  files,
  intl,
  customMaxFileSize,
  withAlert = false
) => {
  const filesTooBig = [];
  const result = await files.reduce(async (accumulator, currentValue) => {
    const file = currentValue;
    const goodFiles = await accumulator;

    if (isFileTooBig(file, customMaxFileSize)) {
      if (isFileImage(file)) {
        const compressedFile = await getCompressedFileWithRecursion(
          file,
          undefined,
          customMaxFileSize
        );

        return [...goodFiles, transformFile(compressedFile)];
      } else {
        filesTooBig.push(file);

        return goodFiles;
      }
    } else {
      return [...goodFiles, transformFile(file)];
    }
  }, []);

  if (filesTooBig.length && withAlert) {
    alert(
      intl
        .formatMessage({
          id: 'file_too_big',
        })
        .concat(`\n${filesTooBig.join('\n')}`)
    );
  }

  return [result, filesTooBig];
};

export const transformFile = (file) => {
  return Object.assign(file, {
    preview: URL.createObjectURL(file),
    tempId: uuidv4(),
    icon: getFileIcon(file.type),
    description: '',
  });
};

export const transformAttachments = (attachments = []) => {
  const formattedAttachments = attachments.map((attachment) => ({
    ...attachment,
    icon: getFileIcon(attachment.mime_type),
    name: attachment.original_filename,
    preview: attachment.url,
    type: attachment.mime_type,
  }));

  return formattedAttachments;
};

export const appendFormDataWithAttachments = (formData, _attachments) => {
  const attachments = _attachments.filter((file) => !file.hack);

  //TO DO > refactor!
  for (const innerKey in attachments) {
    const attachment = attachments[innerKey];

    // send index that is used for ordering
    if (
      attachment['index'] !== null &&
      typeof attachment['index'] !== 'undefined'
    )
      formData.append(`attachments[${innerKey}][index]`, attachment['index']);

    // skip uploading file if not new attachment (New attachments have tempId)
    if (attachment['tempId']) {
      formData.append(`attachments[${innerKey}][file]`, attachment);
    }

    if (attachment['file_name']) {
      formData.append(
        `attachments[${innerKey}][file_name]`,
        attachment.file_name
      );
    }

    // delete attachment if it's marked for deletion
    if (attachment['deleted'] && attachment['id']) {
      formData.append(`delete_attachments[${innerKey}]`, attachment['id']);
    } else {
      // else send id
      if (attachment['id']) {
        formData.append(`attachments[${innerKey}][id]`, attachment['id']);
      }

      //add custom type
      if (attachment['custom_type']) {
        formData.append(
          `attachments[${innerKey}][custom_type]`,
          attachment['custom_type']
        );
      }

      // and update its description
      formData.append(
        `attachments[${innerKey}][description]`,
        attachment['description']
      );
    }
  }
};
