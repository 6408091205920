import axios from 'axios';
import qs from 'query-string';

import { getToken } from '../../../redux/utils';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`,
});

api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getToken()}`,
  };

  return config;
});

export const getScheduleIssues = async (params) => {
  const url = qs.stringifyUrl(
    { url: '/schedule/', query: params },
    { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
  );

  return await api.get(url);
};

export const createScheduleIssue = async (data) =>
  await api.post('/schedule/', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const editSchedulingIssue = async (id, data) =>
  // Method must be "post" because of FormData
  // We set FormData property "_method" to "PUT" so that the backend knows it's PUT
  await api.post(`/schedule/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const addEquipmentsToSchedule = async (id, payload) =>
  await api.post(`/schedule/${id}/equipments`, payload);

export const getScheduleIssue = async (id) => await api.get(`/schedule/${id}`);

export const getScheduleActivities = async (id) =>
  await api.get(`/schedule/${id}/activity_log`);

export const getScheduleQRCodeScanlog = async (code) =>
  await api.get(`/qr_code/scan_log/${code}`);

export const removeSchedule = async (id) => await api.delete(`/schedule/${id}`);

export const archiveSchedule = async (id) =>
  await api.put(`/schedule/${id}`, { archived: true });

export const activateSchedule = async (id) =>
  await api.put(`/schedule/${id}`, { archived: false });

export const getIssuesByScheduleId = async (id, params) =>
  await api.get('/issue', {
    params: {
      schedule_id: id,
      paginate_by: 3,
      sort_by: 'created',
      sort_direction: 'desc',
      persist_filters: 0,
      ...params,
    },
  });

const scheduleDecide = async (id, decision) => {
  try {
    await api.put(`/schedule/${id}/assignee_decision`, decision);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const acceptSchedule = async (id) =>
  scheduleDecide(id, { status_id: 5 });

export const declineSchedule = async (id, note) =>
  scheduleDecide(id, { status_id: 4, note });

export const duplicateSchedule = async (id, data) => {
  await api.post(`/schedule/${id}/duplicate`, data);
};

export const checkEqGroupWs = async (data) => {
  const response = await api.post('/equipment/group/same_ws', data);
  return response.data.data;
};
