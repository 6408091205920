import { DateInput, DateInputDynamicForm } from './DatePicker';

export const CUSTOM_INPUT_TYPES = {
  DEFAULT: {
    key: 'DEFAULT',
    component: (props) => <DateInput {...props} />,
  },
  DYNAMIC_FORMS: {
    key: 'DYNAMIC_FORMS',
    component: (props) => <DateInputDynamicForm {...props} />,
  },
};
