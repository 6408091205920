import { Fragment, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { vestResolver } from '@hookform/resolvers/vest';
import { WowCheckbox } from '../../../../../../components/wow-checkbox';
import Input from '../../../../../../components_new/Input';
import Modal, { SIZE } from '../../../../../../components_new/Modal';
import Space from '../../../../../../components_new/Space';
import {
  WowBlock,
  WowColorizeText,
  WowLabelText,
} from '../../../../../../components_new/WowBasicElements';
import { darkerGray, gradientBlue } from '../../../../../../config/colors';
import useMultiselect from '../../../../../../hooks/useMultiselect';
import AssignToUserItem from '../components/AssignToUserItem';
import CopyCommentsItem from '../components/CopyCommentsItem';
import Footer from '../components/Footer';
import WorkspaceItem from '../components/WorkspaceItem';
import {
  CheckboxLabel,
  InputRow,
  MultiselectTitlesInner,
  MultiselectTitlesWrapper,
} from '../styles';
import { FORM_DEFAULT_VALUES } from './constants';
import { formValidation, parseIssuesData } from './utils';
import { useIssueMultipleDuplicateMutation } from '../../../service';
import InfoMessage from './InfoMessage';

const ModalMultiselectDuplicate = ({ isOpen, handleClose = () => {} }) => {
  const intl = useIntl();
  const { selectedItems, deselectAllItems } = useMultiselect();

  const formMethods = useForm({
    defaultValues: FORM_DEFAULT_VALUES,
    resolver: vestResolver(formValidation),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, control, formState, watch, setValue, getValues } =
    formMethods;
  const { isValid } = formState;
  const canSubmit = isValid;
  const [hackyDropdownPadding, setHackyDropdownPadding] = useState();
  const updateTitles = watch('update_titles');
  const issues = watch('issues');

  const [issueMultipleDuplicate, { isLoading }] =
    useIssueMultipleDuplicateMutation();

  const [duplicated, setDuplicated] = useState({
    completed: [],
    notCompleted: [],
  });

  const submitHandler = async (data) => {
    const { update_titles, ...rest } = data;
    const response = await issueMultipleDuplicate({ ...rest, copy_links: 1 });

    if (response?.data?.not_completed?.length > 0) {
      setDuplicated({
        completed: response.data.completed,
        notCompleted: response.data.not_completed,
      });
    } else {
      deselectAllItems();
    }
  };

  useEffect(() => {
    if (selectedItems && Object.keys(selectedItems)?.length) {
      setValue('issues', parseIssuesData(intl, selectedItems), {
        shouldDirty: true,
      });
    }
  }, [selectedItems, setValue, intl]);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'duplicate_issue',
      })}
      isOpen={isOpen}
      onClose={handleClose}
      size={SIZE.small}
      maxHeight="725px"
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(submitHandler)}
          data-testid="modalDuplicate"
        >
          {!updateTitles ? (
            <>
              <WowBlock>
                <WowColorizeText color={darkerGray} size="14">
                  <i data-testid="infoText">
                    <FormattedMessage id="titles_stay_same" />
                  </i>
                </WowColorizeText>
              </WowBlock>
              <Space height="15" />
            </>
          ) : null}

          <Controller
            control={control}
            name="update_titles"
            render={({ field }) => (
              <WowCheckbox
                label={
                  <CheckboxLabel>
                    {intl.formatMessage({ id: 'update_issue_titles' })}
                  </CheckboxLabel>
                }
                {...field}
                checked={field.value}
                size={18}
                borderColor={gradientBlue}
                data-testid="updateIssueTitles"
              />
            )}
          />
          <Space height={updateTitles ? '16' : '24'} />
          {updateTitles ? (
            <MultiselectTitlesWrapper data-testid="titlesWrapper">
              <MultiselectTitlesInner>
                {issues.map((item, index) => {
                  return (
                    <InputRow key={item.id}>
                      <Controller
                        control={control}
                        name="issues"
                        render={({ field }) => (
                          <Input
                            {...field}
                            data-testid="inputTitle"
                            value={field?.value[index]?.title}
                            onChange={(e) => {
                              const _value = e?.target?.value;
                              const _issues = getValues('issues');
                              _issues[index].title = _value;
                              setValue('issues', _issues, {
                                shouldDirty: true,
                              });
                            }}
                          />
                        )}
                      />
                      {index === issues.length - 1 ? (
                        <Space height="26" />
                      ) : issues.length > 1 ? (
                        <Space height="6" />
                      ) : null}
                    </InputRow>
                  );
                })}
              </MultiselectTitlesInner>
            </MultiselectTitlesWrapper>
          ) : null}

          <WowLabelText>
            <FormattedMessage id="workspaces" />
          </WowLabelText>
          <Controller
            name="workspace_id"
            control={control}
            render={({ field }) => {
              return (
                <WorkspaceItem
                  {...field}
                  onChange={(value) => field.onChange(value.value)}
                  setHackyDropdownPadding={setHackyDropdownPadding}
                />
              );
            }}
          />
          {/* WARNING!
                  Be careful if you add any elements in this modal.
                  hackyDropdownPadding exists to enlarge the modal
                  so that the equipment dropdown is properly shown
              */}
          <Space height={hackyDropdownPadding ? '265' : '35'} />
          <WowBlock>
            <Controller
              control={control}
              name="copy_involved_users"
              render={({ field }) => <AssignToUserItem {...field} />}
            />
          </WowBlock>
          <Space height="5" />
          <Controller
            control={control}
            name="copy_comments"
            render={({ field }) => <CopyCommentsItem {...field} />}
          />
          {duplicated.notCompleted.length > 0 ? (
            <>
              <Space height="34" />
              <InfoMessage duplicated={duplicated} />
            </>
          ) : null}
          <Space height="34" />
          <Footer
            canSubmit={canSubmit}
            isLoading={isLoading}
            handleClose={handleClose}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ModalMultiselectDuplicate;
