import { useCallback, useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { DragDropContext } from 'react-beautiful-dnd';
import useCalendar from '../../hooks/useCalendar';
import { formatDateForQuery } from '../../utils';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';
import DateWrapper from './components/DateWrapper';
import ModalConfirm from '../../../../../components_new/ModalConfirm';
import ModalInfo from '../../../../../components_new/ModalInfo';
import {
  getActiveView,
  getLastDailyDate,
  getMainDraggableFiltersHiddenData,
} from '../../selectors';
import {
  Content,
  Page,
  Wrapper,
} from '../../../../../components_new/WowListPage';
import { CalendarWrapper } from '../../styles';
import Header from '../Header';
import MainDraggableFilter from '../MainDraggableFilter';
import Space from '../../../../../components_new/Space';
import DailyTimeline from '../DailyTimeline';
import { CALENDAR_VIEW_TYPES } from '../../constants';
import { TYPES } from '../DailyTimeline/constants';
import UnscheduledSidebar from '../UnscheduledSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUnscheduledMenuOpen } from '../../selectors';
import useNoDate from '../UnscheduledSidebar/hooks/useNoDate';
import useOverdue from '../UnscheduledSidebar/hooks/useOverdue';
import {
  setCurrentActiveIssueDetailsModal,
  setUnscheduledIssueCurrentDragId,
  setMainDraggableFiltersHiddenData,
  handleLoadMoreHideData,
  setTimelineIssueCurrentDragId,
  setTimelineIssueDraggableElementId,
} from '../../slice';
import useWindowSizeDebounced from '../../../../../hooks/useWindowSizeDebounced';
import {
  getDefaultUnscheduledModalConfirmData,
  getDefaultUnscheduledModalData,
} from './utils';

const Daily = (props) => {
  const { setActiveView = () => {} } = props;

  const activeView = useSelector(getActiveView);
  const lastDailyDate = useSelector(getLastDailyDate);

  const intl = useIntl();
  const draggableTimelineIssueRevertData = useRef(null); //Used for DND inside timeline itself. Existing issues, when user cancel their date update, we will revert data with this ones saved here.
  const isUnscheduledMenuOpen = useSelector(getIsUnscheduledMenuOpen);

  const dispatch = useDispatch();
  const [unscheduledModalConfirmData, setUnscheduledModalConfirmData] =
    useState(getDefaultUnscheduledModalConfirmData());
  const [unscheduledModalData, setUnscheduledModalData] = useState(
    getDefaultUnscheduledModalData()
  );

  const {
    updateSameStartDateEndDate,
    calendarDailyUsersData,
    calendarDailyUsersCalendarData,
    calendarDailyUsersLastPage,
    isFetchingCalendarDailyUsers,
    handleMoveToCalendarDailyUsersData,
    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarData,
    calendarDailyWorkspacesLastPage,
    isFetchingCalendarDailyWorkspaces,
    handleMoveToCalendarDailyWorkspacesData,
    updateCalendarSearchValue,
    loadMore,
    calendar_search,
    page,
    handleUpdateDataItem,
    handleDeleteDataItem,
    handleNewItemSetup,
    startDate: currentTime,
    isUninitializedCalendarDailyUsers,
    isUninitializedCalendarDailyWorkspaces,
    isLoadingFirstTimeCalendarDailyUsers,
    isLoadingFirstTimeCalendarDailyWorkspaces,
    queryParams,
  } = useCalendar({
    calendarView: activeView,
  });

  const { width } = useWindowSizeDebounced();
  const isQHDResolution = width >= 2560;
  const shouldShowOnlyOneSidebar = width < 1300;
  const hiddenData = useSelector(getMainDraggableFiltersHiddenData);
  const checkingWithSearchParamStarted = useRef(false);
  const searchParamSessionStarted = useRef(false);
  const clearAllWithoutSearchClicked = useRef(false);
  const shouldShowUnscheduledSidebar =
    isUnscheduledMenuOpen && !shouldShowOnlyOneSidebar && !isQHDResolution;

  // Used for unscheduled sidebar. We needed to lift this because in some cases we need to update local state and we need to have these hooks lifted up.
  // TODO: Maybe lift this to redux later on and avoid RTK for this scenario.
  const [unscheduledSidebarSkipQuery, setUnscheduledSidebarSkipQuery] =
    useState(true);
  useEffect(() => {
    if (isUnscheduledMenuOpen && unscheduledSidebarSkipQuery)
      setUnscheduledSidebarSkipQuery(false);
  }, [isUnscheduledMenuOpen, unscheduledSidebarSkipQuery]);
  const noDateHookData = useNoDate({
    skipQuery: unscheduledSidebarSkipQuery || shouldShowOnlyOneSidebar,
    calendarQueryParams: queryParams,
  });
  const overdueHookData = useOverdue({
    skipQuery: unscheduledSidebarSkipQuery || shouldShowOnlyOneSidebar,
    calendarQueryParams: queryParams,
  });
  const removeItemsFromUnscheduledSidebar = (ids = []) => {
    overdueHookData.deleteManuallyFromListItems(ids);
    noDateHookData.deleteManuallyFromListItems(ids);
  };

  const handleChangeCalendarSearch = (value) => {
    updateCalendarSearchValue(value);
  };

  const handleHiddenData = (data) => {
    const mappedData = data.map((item) => ({
      id: item.id,
      hide: !item.checked,
      data: item?.metaData ?? {},
    }));
    const mutatedState = JSON.parse(JSON.stringify({ ...hiddenData }));
    if (calendar_search[0] && !checkingWithSearchParamStarted.current) {
      searchParamSessionStarted.current = true;
      checkingWithSearchParamStarted.current = true;
      if (!clearAllWithoutSearchClicked.current) {
        Object.keys(mutatedState).forEach((key) => {
          mutatedState[key].hide = true;
        });
      }
      clearAllWithoutSearchClicked.current = false;
    }
    if (!calendar_search[0]) checkingWithSearchParamStarted.current = false;
    mappedData.forEach((item) => {
      if (mutatedState[item.id]) mutatedState[item.id].hide = item.hide;
      else
        mutatedState[item.id] = {
          hide: item.hide,
          id: item.id,
          data: item.metaData,
        };
    });
    dispatch(
      setMainDraggableFiltersHiddenData({
        type: activeView,
        data: mutatedState,
      })
    );
    handleMoveToCalendarDailyUsersData();
    handleMoveToCalendarDailyWorkspacesData();
  };

  const handleSetCurrentTime = (date) => {
    updateSameStartDateEndDate(formatDateForQuery(date));
  };

  useEffect(() => {
    if (lastDailyDate) handleSetCurrentTime(new Date(lastDailyDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    isFetchingCalendarDailyUsers || isFetchingCalendarDailyWorkspaces;
  const isInitialLoading =
    (activeView === CALENDAR_VIEW_TYPES.dailyUsers &&
      isLoadingFirstTimeCalendarDailyUsers) ||
    (activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces &&
      isLoadingFirstTimeCalendarDailyWorkspaces);

  const dailyTimelineType =
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ? TYPES.WORKSPACES.key
      : activeView === CALENDAR_VIEW_TYPES.dailyUsers
      ? TYPES.USERS.key
      : null;
  const data =
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ? calendarDailyWorkspacesData
      : activeView === CALENDAR_VIEW_TYPES.dailyUsers
      ? calendarDailyUsersData
      : null;
  const last_page =
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ? calendarDailyWorkspacesLastPage
      : activeView === CALENDAR_VIEW_TYPES.dailyUsers
      ? calendarDailyUsersLastPage
      : null;
  const calendarData =
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ? calendarDailyWorkspacesCalendarData
      : activeView === CALENDAR_VIEW_TYPES.dailyUsers
      ? calendarDailyUsersCalendarData
      : null;

  const isUninitializedData =
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ? isUninitializedCalendarDailyWorkspaces
      : activeView === CALENDAR_VIEW_TYPES.dailyUsers
      ? isUninitializedCalendarDailyUsers
      : null;

  const finalDragElementLeftPosition = useRef(null);
  const lastCursorXPosition = useRef(null);
  const lastDragElementXPosition = useRef(null);
  const clearDnd = () => {
    finalDragElementLeftPosition.current = null;
    lastCursorXPosition.current = null;
    lastDragElementXPosition.current = null;
    const droppableItems = document.querySelectorAll('[data-rbd-droppable-id]');
    for (let item of droppableItems) item.onmousemove = null;
  };

  const dndOnDragEndHandler = useCallback(
    ({ destination, draggableId }) => {
      const resetDndReduxData = () => {
        dispatch(setUnscheduledIssueCurrentDragId(null));
        dispatch(setTimelineIssueCurrentDragId(null));
        dispatch(setTimelineIssueDraggableElementId(null));
      };
      const destinationDroppableId = destination?.droppableId;
      if (!destinationDroppableId) return resetDndReduxData();

      let leftPosition = finalDragElementLeftPosition.current ?? 0;
      const lastCursorPosition = lastCursorXPosition.current;
      const lastDragElementPosition = lastDragElementXPosition.current;
      const id = parseInt(draggableId?.split('-')?.[1] ?? null);
      const dragFromType = draggableId?.split('-')?.[0] ?? null;
      const destionationId = parseInt(
        destinationDroppableId?.split('-')?.[1] ?? null
      );

      clearDnd();

      if (lastDragElementPosition < 0 || lastDragElementPosition === null)
        return resetDndReduxData();

      if (lastDragElementPosition && lastCursorPosition) {
        leftPosition =
          leftPosition + (lastDragElementPosition - lastCursorPosition);
      }

      let targetFromData;
      if (dragFromType === 'unschedule') {
        targetFromData = noDateHookData?.scrollState?.itemsList?.find(
          (item) => item.id === id
        );
      } else if (dragFromType === 'overdue') {
        targetFromData = overdueHookData?.scrollState?.itemsList?.find(
          (item) => item.id === id
        );
      }

      const handleParamsData = {
        id,
        presetData: {},
        presetPosition: leftPosition,
        timelineId: destionationId,
        activeView,
      };

      if (dragFromType === 'timeline') {
        dispatch(setCurrentActiveIssueDetailsModal(id));
        return handleNewItemSetup(handleParamsData, {
          dndTimelineUpdate: true,
        });
      }

      const destinationData = data.find((item) => item.id === destionationId);
      if (destinationData) {
        const parsedData = JSON.parse(JSON.stringify(destinationData));
        delete parsedData.issues;
        if (
          activeView === CALENDAR_VIEW_TYPES.dailyUsers &&
          parsedData.id &&
          targetFromData?.workspace?.available_assignees_ids &&
          !targetFromData.workspace.available_assignees_ids.find(
            (id) => id === parsedData.id
          )
        ) {
          const modalData = {
            isOpen: true,
            intlValues: {
              messageKey: 'calendar_reschedule_to_user_not_in_workspace',
              user_name: parsedData?.full_name,
              workspace_name: targetFromData?.workspace?.name,
            },
          };
          setUnscheduledModalData((prevState) => ({
            ...prevState,
            ...modalData,
          }));
        } else if (
          activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces &&
          targetFromData.workspace_id &&
          parsedData.id !== targetFromData.workspace_id
        ) {
          const modalData = {
            titleKey: 'are_you_sure',
            intlValues: {
              messageKey: 'calendar_are_you_sure_move_to_new_workspace',
              workspace_name: targetFromData?.workspace?.name,
              new_workspace_name: parsedData?.name,
            },
            isOpen: true,
            handleAction: () => {},
          };
          handleParamsData.presetData.workspace_id = parsedData.id;
          handleParamsData.presetData.workspace = { ...parsedData };
          modalData.handleAction = () => handleNewItemSetup(handleParamsData);
          setUnscheduledModalConfirmData((prevState) => ({
            ...prevState,
            ...modalData,
          }));
        } else if (
          activeView === CALENDAR_VIEW_TYPES.dailyUsers &&
          targetFromData.assignee_id &&
          parsedData.id !== targetFromData.assignee_id
        ) {
          const modalData = {
            titleKey: 'are_you_sure',
            intlValues: {
              messageKey: 'calendar_reassign_task_are_you_sure',
              user_name: targetFromData?.assignee?.full_name,
              new_user_name: parsedData?.full_name,
            },
            isOpen: true,
            handleAction: () => {},
          };
          handleParamsData.presetData.assignee_id = parsedData.id;
          handleParamsData.presetData.assignee = { ...parsedData };
          modalData.handleAction = () => handleNewItemSetup(handleParamsData);
          setUnscheduledModalConfirmData((prevState) => ({
            ...prevState,
            ...modalData,
          }));
        } else {
          if (activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
            handleParamsData.presetData.workspace_id = parsedData.id;
            handleParamsData.presetData.workspace = { ...parsedData };
          } else if (activeView === CALENDAR_VIEW_TYPES.dailyUsers) {
            handleParamsData.presetData.assignee_id = parsedData.id;
            handleParamsData.presetData.assignee = { ...parsedData };
          }
          handleNewItemSetup(handleParamsData);
        }
      } else {
        handleNewItemSetup(handleParamsData);
      }
    },
    [
      handleNewItemSetup,
      data,
      activeView,
      dispatch,
      overdueHookData,
      noDateHookData,
    ]
  );

  const dndOnDragUpdateHandler = useCallback(
    (context) => {
      const droppableId = context?.destination?.droppableId;
      const draggableId = context?.draggableId;
      const dragFromType = draggableId?.split('-')?.[0] ?? null;
      if (dragFromType === 'timeline') return;

      if (droppableId) {
        if (draggableId)
          dispatch(setUnscheduledIssueCurrentDragId(draggableId));
        const destinationParentElem = document.querySelector(
          `[data-rbd-droppable-id="${droppableId}"]`
        );
        const draggableItem = document.querySelector(
          `[data-rbd-draggable-id="${draggableId}"]`
        );
        if (destinationParentElem) {
          const position = destinationParentElem.getBoundingClientRect();
          destinationParentElem.onmousemove = (e) => {
            lastDragElementXPosition.current =
              draggableItem.getBoundingClientRect().x;

            const { clientX } = e;
            const fromLeft = clientX - position.x ?? 0;
            finalDragElementLeftPosition.current = fromLeft <= 0 ? 0 : fromLeft;
            lastCursorXPosition.current = clientX;
          };
        }
      }
    },
    [dispatch]
  );

  const dndOnDragStartHandler = useCallback(
    ({ draggableId, source }) => {
      const droppableId = source?.droppableId;
      const dragFromType = draggableId?.split('-')?.[0] ?? null;
      dispatch(setCurrentActiveIssueDetailsModal(null));
      clearDnd();
      if (dragFromType === 'timeline') {
        if (droppableId) {
          dispatch(setTimelineIssueCurrentDragId(draggableId));
          const destinationParentElem = document.querySelector(
            `[data-rbd-droppable-id="${droppableId}"]`
          );
          const draggableItem = document.querySelector(
            `[data-rbd-draggable-id="${draggableId}"]`
          );

          const intialStartDate = draggableItem.getAttribute('data-start_date');
          const intialDueDate = draggableItem.getAttribute('data-due_date');

          draggableTimelineIssueRevertData.current = {
            start_date: intialStartDate ? parseInt(intialStartDate) : null,
            due_date: intialDueDate ? parseInt(intialDueDate) : null,
          };

          if (destinationParentElem && draggableItem) {
            const position = destinationParentElem.getBoundingClientRect();
            destinationParentElem.onmousemove = (e) => {
              lastDragElementXPosition.current =
                draggableItem.getBoundingClientRect().x;

              const { clientX } = e;
              const fromLeft = clientX - position.x ?? 0;
              finalDragElementLeftPosition.current =
                fromLeft <= 0 ? 0 : fromLeft;
              lastCursorXPosition.current = clientX;
            };
          }
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (data?.length)
      dispatch(
        handleLoadMoreHideData(data, {
          searchParamSessionStarted: searchParamSessionStarted.current,
        })
      );
  }, [dispatch, data, data?.length]);

  const galleryPopupPresented = useSelector(
    (state) => state?.app?.toJS()?.galleryPopupPresented
  );

  return (
    <Page>
      <DragDropContext
        onDragEnd={dndOnDragEndHandler}
        onDragUpdate={dndOnDragUpdateHandler}
        onDragStart={dndOnDragStartHandler}
      >
        <Content data-testid="dailyCalendarWrapper">
          {isInitialLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Header
                setActiveView={setActiveView}
                currentTime={currentTime}
                setCurrentTime={handleSetCurrentTime}
              />
              <Wrapper style={{ zIndex: galleryPopupPresented ? 200 : null }}>
                <CalendarWrapper>
                  <DateWrapper currentTime={currentTime} />
                  <Space height="15" />
                  <DailyTimeline
                    type={dailyTimelineType}
                    data={calendarData}
                    startDayTime={currentTime}
                    handleUpdateDataItem={handleUpdateDataItem}
                    handleDeleteDataItem={handleDeleteDataItem}
                    isUninitializedData={isUninitializedData}
                    removeItemsFromUnscheduledSidebar={
                      removeItemsFromUnscheduledSidebar
                    }
                    draggableTimelineIssueRevertData={
                      draggableTimelineIssueRevertData
                    }
                  />
                </CalendarWrapper>
              </Wrapper>
            </>
          )}
        </Content>
        {(!isUnscheduledMenuOpen && !shouldShowOnlyOneSidebar) ||
        isQHDResolution ? (
          <MainDraggableFilter
            title={
              dailyTimelineType && TYPES[dailyTimelineType]
                ? intl.formatMessage({ id: TYPES[dailyTimelineType].cardTitle })
                : ''
            }
            listData={data?.map((item) => ({
              id: item.id,
              display: item.full_name ?? item.name,
              checked:
                hiddenData[item.id] && hiddenData[item.id].hide === true
                  ? false
                  : calendar_search?.[0] &&
                    typeof hiddenData[item.id] === 'undefined'
                  ? false
                  : true,
              metadata: item,
            }))}
            isDragDisabled={true}
            listDataOnChange={handleHiddenData}
            searchValueOnChange={handleChangeCalendarSearch}
            // eslint-disable-next-line eqeqeq
            showLoadMore={!isLoading && page != last_page}
            handleLoadMore={loadMore}
            activeView={activeView}
            clearAllWithoutSearchClicked={clearAllWithoutSearchClicked}
            searchParamSessionStarted={searchParamSessionStarted}
            checkingWithSearchParamStarted={checkingWithSearchParamStarted}
          />
        ) : null}
        {shouldShowUnscheduledSidebar ? (
          <UnscheduledSidebar
            noDateHookData={noDateHookData}
            overdueHookData={overdueHookData}
          />
        ) : null}
      </DragDropContext>
      {unscheduledModalConfirmData?.isOpen && (
        <ModalConfirm
          isOpen={unscheduledModalConfirmData.isOpen}
          handleClose={() => {
            setUnscheduledModalConfirmData(
              getDefaultUnscheduledModalConfirmData()
            );
            dispatch(setUnscheduledIssueCurrentDragId(null));
          }}
          handleAction={() => {
            unscheduledModalConfirmData.handleAction();
            window.setTimeout(
              () =>
                setUnscheduledModalConfirmData(
                  getDefaultUnscheduledModalConfirmData()
                ),
              0
            );
          }}
          actionButtonColor="green"
          actionType="confirm"
          intlValues={unscheduledModalConfirmData.intlValues ?? {}}
        />
      )}
      {unscheduledModalData?.isOpen && (
        <ModalInfo
          isOpen={unscheduledModalData?.isOpen}
          handleAction={() => {
            setUnscheduledModalData(getDefaultUnscheduledModalData());
            dispatch(setUnscheduledIssueCurrentDragId(null));
          }}
          handleClose={() => {
            setUnscheduledModalData(getDefaultUnscheduledModalData());
            dispatch(setUnscheduledIssueCurrentDragId(null));
          }}
          icon="icon-important-round"
          intlValues={unscheduledModalData?.intlValues ?? {}}
        />
      )}
    </Page>
  );
};

export default Daily;
