export const actionStrings = {
  SELECT_ATTACHMENT: 'SELECT_ATTACHMENT',
  ADD_ATTACHMENTS: 'ADD_ATTACHMENTS',
  RESET_ATTACHMENTS: 'RESET_ATTACHMENTS',
  ADD_ATTACHMENT: 'ADD_ATTACHMENT',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION',
  UNDO_CHANGES: 'UNDO_CHANGES',
  CONFIRM_CHANGES: 'CONFIRM_CHANGES',
  CLEAR_GALLERY: 'CLEAR_GALLERY',
  UPDATE_THUMBNAIL_PRESENCE: 'UPDATE_THUMBNAIL_PRESENCE',
  CHANGE_SORT: 'CHANGE_SORT',
  ADD_LOCAL_IDS: 'ADD_LOCAL_IDS',
  CHANGE_DEADLINE: 'CHANGE_DEADLINE',
  CHANGE_PRIORITY: 'CHANGE_PRIORITY',
};

export const attachmentState = {
  NEW: 'NEW',
  DELETED: 'DELETED',
  MODIFIED: 'MODIFIED',
  ORIGINAL: 'ORIGINAL',
};

export const selectAttachment = (attachment, hostId) => ({
  type: actionStrings.SELECT_ATTACHMENT,
  payload: {
    attachment,
    hostId,
  },
});

export const addAttachments = (attachments, hostId) => ({
  type: actionStrings.ADD_ATTACHMENTS,
  payload: {
    attachments,
    hostId,
  },
});

export const resetAttachments = (hostId) => ({
  type: actionStrings.RESET_ATTACHMENTS,
  payload: {
    hostId,
  },
});

export const addAttachment = (attachment, hostId) => ({
  type: actionStrings.ADD_ATTACHMENT,
  payload: {
    attachment,
    hostId,
  },
});

export const removeAttachment = (index, hostId) => ({
  type: actionStrings.REMOVE_ATTACHMENT,
  payload: {
    index,
    hostId,
  },
});

export const changeDescription = (index, description, hostId) => ({
  type: actionStrings.CHANGE_DESCRIPTION,
  payload: {
    index,
    description,
    hostId,
  },
});

export const updateThumbnailPresence = (index, thumbnailPresent, hostId) => ({
  type: actionStrings.UPDATE_THUMBNAIL_PRESENCE,
  payload: {
    index,
    thumbnailPresent,
    hostId,
  },
});

export const undoChanges = (hostId) => ({
  type: actionStrings.UNDO_CHANGES,
  payload: {
    hostId,
  },
});

export const confirmChanges = (hostId) => ({
  type: actionStrings.CONFIRM_CHANGES,
  payload: {
    hostId,
  },
});

export const navigateNext = () => ({
  type: actionStrings.NAVIGATE_NEXT,
  payload: {},
});

export const clearGallery = (hostId) => ({
  type: actionStrings.CLEAR_GALLERY,
  payload: {
    hostId,
  },
});

export const changeSort = (oldIndex, newIndex, hostId) => ({
  type: actionStrings.CHANGE_SORT,
  payload: {
    oldIndex,
    newIndex,
    hostId,
  },
});

export const addLocalIds = (hostId) => ({
  type: actionStrings.ADD_LOCAL_IDS,
  payload: {
    hostId,
  },
});

export const changeDeadline = (index, date, id) => ({
  type: actionStrings.CHANGE_DEADLINE,
  payload: {
    index,
    date,
    hostId: id,
  },
});

export const changePriority = (index, priorityId, id) => ({
  type: actionStrings.CHANGE_PRIORITY,
  payload: {
    index,
    priorityId,
    hostId: id,
  },
});
