import ReactDatePicker from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomInput from './CustomInput';
import { CheckboxesGroupWrapper, DateInputsWrapper } from './styles';
import { WowCheckbox } from '../../../../../../components/wow-checkbox';
import Space from '../../../../../Space';
import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../WowBasicElements';
import { DatePickerStyleResetWrapper } from '../../../FilterDateRange/styles';
import { ResetButton } from '../../../../styles';

const DateRange = (props) => {
  const {
    handleStartDateChange,
    handleEndDateChange,
    handleTypesChange,
    localStartDate,
    localEndDate,
    localDateTypes,
    localDateTypesOptions,
    handleReset,
  } = props;

  const intl = useIntl();

  return (
    <>
      <DateInputsWrapper
        borderBottom={!!localDateTypes?.length}
        direction="column"
      >
        <WowDisplayFlex justify="flex-end" width="100%">
          <ResetButton onClick={handleReset}>
            <FormattedMessage id="reset" />
          </ResetButton>
        </WowDisplayFlex>
        <WowDisplayFlex width="100%" justify="space-between">
          <WowDisplayFlex direction="column" align="flex-start">
            <WowColorizeText size="12" capitalize fWeight={600}>
              <FormattedMessage id="from" />
            </WowColorizeText>
            <DatePickerStyleResetWrapper fullWidth>
              <ReactDatePicker
                selected={localStartDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={localStartDate}
                endDate={localEndDate}
                customInput={<CustomInput />}
              />
            </DatePickerStyleResetWrapper>
          </WowDisplayFlex>
          <WowDisplayFlex direction="column" align="flex-start">
            <WowColorizeText size="12" capitalize fWeight={600}>
              <FormattedMessage id="to_time" />
            </WowColorizeText>
            <DatePickerStyleResetWrapper fullWidth>
              <ReactDatePicker
                selected={localEndDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={localStartDate}
                endDate={localEndDate}
                customInput={<CustomInput />}
              />
            </DatePickerStyleResetWrapper>
          </WowDisplayFlex>
        </WowDisplayFlex>
      </DateInputsWrapper>

      {localDateTypes?.length ? (
        <CheckboxesGroupWrapper>
          {localDateTypesOptions?.map(({ id, labelKey, value }) => {
            return (
              <WowDisplayFlex align="center" key={id} shrink="0">
                <WowCheckbox
                  checked={localDateTypes.includes(value)}
                  onChange={() => handleTypesChange(value)}
                  size="16"
                />
                <Space width="5" />
                <WowColorizeText size="12">
                  {intl.formatMessage({ id: labelKey })}
                </WowColorizeText>
              </WowDisplayFlex>
            );
          })}
        </CheckboxesGroupWrapper>
      ) : null}
    </>
  );
};

export default DateRange;
