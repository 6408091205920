import styled from 'styled-components/macro';
import { newRed } from '../../config/colors';

export const Notices = styled.div`
  padding-bottom: 48px;

  > * {
    margin-bottom: 12px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export const NoticeContent = styled.div`
  ${({ textColor }) => (textColor ? `color: ${textColor}` : null)};
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
`;

export const NoticeText = styled.div`
  font-weight: bold;
  display: flex;
`;

export const NoticeControllsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SmallTextNotice = styled.span`
  margin: 0 4px;
  font-weight: normal;
`;

export const NoticeButton = styled.button.attrs({ type: 'button' })`
  color: ${({ color }) => color ?? newRed};
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;
