import { useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getActionItemInfo } from '../../../selectors';
import { setActionItemInfo } from '../../../slice';

import useKeyPress from '../../../../../../hooks/useKeyPress';
import { useOnClickOutside } from '../../../../../../hooks/useClickOutside';

import { ID_KEYS } from '../../../../main/constants';
import { LIST_ID } from '../constants';

const useActions = () => {
  const actionItemInfo = useSelector(getActionItemInfo);
  const dispatch = useDispatch();

  const actionRef = useRef();
  const scrollPosition = useRef({ scrollTop: 0 });

  // clear modal state after unmount
  useEffect(() => {
    return () => dispatch(setActionItemInfo({ data: null, position: null }));
  }, [dispatch]);

  const actionItem = useMemo(() => actionItemInfo.data, [actionItemInfo.data]);
  const position = useMemo(
    () => actionItemInfo.position,
    [actionItemInfo.position]
  );

  const updateActionMenuPosition = useCallback(
    ({ scrollTop, clientHeight }) => {
      // track scroll position
      // when modal is not opened
      if (!actionItem) {
        scrollPosition.current.scrollTop = scrollTop;
      }

      // logic for position of form
      if (actionRef.current) {
        // calculate scroll position
        // as difference between scroll position when modal was opened
        // and current scroll value
        const relativeScrollPosition =
          scrollTop - scrollPosition.current.scrollTop;

        const topPosition = position.y - 150 - relativeScrollPosition;
        actionRef.current.style.top = `${topPosition}px`;

        if (topPosition > clientHeight) {
          actionRef.current.style.display = 'none';
        } else {
          actionRef.current.style.display = 'block';
        }
      }
    },
    [actionItem, position]
  );

  const clearActionData = useCallback(() => {
    dispatch(setActionItemInfo({ data: null, position: null }));
    scrollPosition.current.scrollTop =
      document.getElementById(LIST_ID).scrollTop;
  }, [dispatch]);

  useKeyPress('Escape', clearActionData);

  useOnClickOutside(actionRef, clearActionData, !!actionItem);

  // #endregion

  return {
    actionRef,
    scrollPosition,
    actionItem,
    position,
    updateActionMenuPosition,
    clearActionData,

    topValue: `${position?.y - 150}px`,
    leftValue: `${
      position?.x -
      55 -
      180 -
      document.getElementById(ID_KEYS.mainMenu).clientWidth
    }px`,
  };
};

export default useActions;
