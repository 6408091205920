import styled from 'styled-components/macro';
import {
  borderLightGray,
  darkerGray,
  darkGray,
  filtersAdvancedGray,
  gradientBlue,
} from '../../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
`;

export const InfoWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(55, 70, 95, 0.1);
  flex-basis: 470px;
  height: 80%;
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding: 32px 28px 18px;
  flex-shrink: 0;
`;

export const Heading = styled.h2`
  color: ${darkGray};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const InfoSingleItemWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3.5fr 6fr;
  border-bottom: 1px solid ${borderLightGray};
  padding: 15px 0;
  button {
    border: none;
  }
`;

export const InfoItemName = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${filtersAdvancedGray};
  white-space: nowrap;
`;

export const InfoValue = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  ${({ pLeft }) => (pLeft ? `padding-left: ${pLeft}px` : null)};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  width: ${({ width }) => (width ? width : 'max-content')};
  color: ${({ isClickable }) => (isClickable ? gradientBlue : darkerGray)};
`;
