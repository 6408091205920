export const API_TAG_TYPES_SCHEDULE_ASSETS = [
  'ScheduleAssets',
  'ScheduleAssetGroups',
];

export const ALL_ASSET_IDS = 'all_asset_ids';
export const ASSETS = 'assets';
export const ASSETS_DIRTY_ITEMS = 'assets_dirty_items';
export const ALL_ASSET_GROUP_IDS = 'all_asset_group_ids';
export const ASSET_GROUPS = 'asset_groups';
export const ASSET_GROUPS_DIRTY_ITEMS = 'asset_groups_dirty_items';
export const IS_ASSETS_SAME_WORKSPACE = 'is_assets_same_workspace';

export const ENTITY_KEYS = {
  scheduleAssets: 'scheduleAssets',
  scheduleAssetGroups: 'scheduleAssetGroups',
};

export const MAX_NUMBER_OF_ASSETS = 200;
