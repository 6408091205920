import styled from 'styled-components/macro';
import {
  darkerGray,
  gray,
  iconWowListItemLightGray,
  newRed,
} from '../../config/colors';
import { WowListItem } from './shared';
import { outline } from '../../config/colors';

export const WowListItemSmallElement = styled.div`
  display: flex;
  align-items: stretch;
  min-width: 0px;

  // This is not extracted this to its own styled component because
  // we need to target icon that is inside "DateTooltip" component
  .icon {
    font-style: normal;
  }
`;

// WowListItemSmall has 6 "sections"
// 1 big - issue title and details below
// 5 smaller ones - 4 icon + text, 1 dots + checkbox

export const WowListItemSmall = styled(WowListItem)`
  display: grid;
  gap: 12px;
  height: 65px;

  padding: 0px 22px 0px 18px;
`;

export const WowListItemSmallTitle = styled.span`
  color: ${({ color }) => color ?? darkerGray};
  font-size: ${({ fontSize }) => fontSize ?? '18px'};
  text-align: left;
  display: block;
  ${({ width }) => (width ? `width: ${width}` : '')};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WowListItemSmallInfo = styled.div`
  color: ${gray};
  font-size: 13px;
  font-weight: 400;
  text-align: left;
`;

export const WowListItemSmallElementVertical = styled(WowListItemSmallElement)`
  flex-direction: column;
  justify-content: center;
`;

export const WowListItemSmallElementVerticalCenter = styled(
  WowListItemSmallElement
)`
  align-items: center;
  ${({ justify }) => (justify ? `justify-content: ${justify}` : null)};
  ${({ position }) => (position ? `position: ${position};` : '')}
  ${({ left }) => (left ? `left: ${left};` : '')}
`;

export const WowListItemSmallActions = styled.div`
  display: flex;
`;

export const WowListItemSmallElementIcon = styled.i`
  display: ${({ display }) => display ?? 'inline-block'};
  padding-right: 10px;
  color: ${({ color }) => (color ? color : iconWowListItemLightGray)};
  ${({ size }) => (size ? `size: ${size}` : null)};
`;

// TODO: Add ellipsis text overflow (e.g. when email too long - requires fixed width - make different width on different breakpoints)
export const WowListItemSmallElementText = styled.div`
  color: ${({ color }) => (color ? color : darkerGray)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${({ size }) => (size ? size + 'px' : '14px')};
`;

export const WowListItemSmallElementTextSmall = styled.span`
  color: ${darkerGray};
  font-size: 12px;
`;

export const WowListItemSmallStatusMarkerLeft = styled.div`
  position: absolute;
  width: 8px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${({ color }) => color || newRed};
  border-radius: 10px 0 0 10px;
`;

export const ShowSubWs = styled.div`
  cursor: pointer;
  padding: 4px 5px;
  background-color: ${(props) =>
    props.showChildren ? outline : 'transparent'};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 13px;

  transition: all 0.2s;
`;
