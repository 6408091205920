import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  isNewChecklistCreated: false, //used when new checklist is created to show add new item
};

export const checklistsSlice = createSlice({
  name: 'checklists',
  initialState: INIT_STATE,
  reducers: {
    updateIsNewChecklistCreated: (state, action) => {
      state.isNewChecklistCreated = action.payload ?? false;
    },
  },
});

export const { updateIsNewChecklistCreated } = checklistsSlice.actions;

export default checklistsSlice.reducer;
