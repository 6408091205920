import React from 'react';
import PropTypes from 'prop-types';
import { SliderItem } from '../slider-item/slider-item.component';
import './gallery-slider.component.scss';
import { compressableFileTypes } from '../../upload-area/upload-helper';
import styled from 'styled-components/macro';

const GallerySliderStrip = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  align-items: center;

  ${({ isSubtaskPopup }) =>
    isSubtaskPopup &&
    `
    width: calc(400px - 1rem);
  `}

  & > div {
    min-width: 15rem;
    max-height: 100%;
  }

  @media only screen and (max-width: 480px) {
    width: calc(100% - 2rem);
  }
`;

export const GallerySlider = (props) => {
  const showImageDetails = (item) => {
    if (props.onItemSelect) {
      props.onItemSelect(item);
    }
  };

  return (
    <div className="gallery-slider">
      <GallerySliderStrip isSubtaskPopup={props.isSubtaskPopup}>
        {props.items
          .filter(
            (item) =>
              compressableFileTypes.includes(item.mimeType) ||
              item.media_type === 'picture'
          )
          .map((item) => (
            <SliderItem
              key={`${item.index}-${item.url}`}
              item={item}
              url={item.thumbnailUrl || item.web_mob_thumbnail_url}
              mediaPresent={item.thumbnailPresent}
              mimeType={item.mimeType ? item.mimeType : item.mime_type}
              mediaType={item.mediaType}
              description={item.description}
              state={item.state}
              user={item.user}
              showAvatar={item.showAvatar}
              index={item.index}
              onSelectItem={showImageDetails}
              canRemoveItem={item.allowedToRemove}
              canResizeItem={true}
              showListView={props.showListView}
            />
          ))}
      </GallerySliderStrip>
    </div>
  );
};

GallerySlider.propTypes = {
  items: PropTypes.object,
  onGalleryChanged: PropTypes.func,
  onItemSelect: PropTypes.func,
  id: PropTypes.string,
  galleryChanged: PropTypes.func,
};
