import { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { validateValuesType, validateAllowedParams } from './utils';

const useSearchQuery = (_initialState, supportedParams, skip = false) => {
  const [initialState] = useState(_initialState);

  const [initialStateUsed, setInitialStateUsed] = useState(false);

  const history = useHistory();
  const { search, pathname } = useLocation();

  const queryParams = useMemo(
    () =>
      validateAllowedParams(
        search ? qs.parse(search) : initialState,
        supportedParams
      ),
    [search, supportedParams, initialState]
  );

  const updateQueryParams = useCallback(
    (query) => {
      if (query) validateValuesType(query);

      const onlyAllowedQueryParams = validateAllowedParams(
        query,
        supportedParams
      );

      const stringified = qs.stringify(onlyAllowedQueryParams, {
        skipNull: true,
        skipEmptyString: true,
      });

      history.replace(`${pathname}?${stringified}`);

      return onlyAllowedQueryParams;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, supportedParams, skip]
  );

  useEffect(() => {
    if (!initialStateUsed && !skip) {
      if (initialState) {
        updateQueryParams({
          ...initialState,
          ...queryParams,
        });

        setInitialStateUsed(true);
      }
    }
  }, [initialState, updateQueryParams, queryParams, initialStateUsed, skip]);

  return {
    updateQueryParams,
    queryParams,
  };
};

export default useSearchQuery;
