import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CounterWrapper } from './styles';
import './icon-circle.component.scss';

//TO DO - this is old and getting ugly => refactor
export const IconCircle = forwardRef((props, forwardedRef) => {
  const wrapperClasses = classnames(
    'icon-wrapper',
    {
      'space-right': props.spaceRight,
      clickable: typeof props.onClick === 'function' && !props.disabled,
      [`hover--${props.color}`]: !props.isFilled,
      [`filled--${props.color}`]: props.isFilled,
      'is-starred': props.isStarred,
      'not-starred': !props.isStarred,
      disabled: props.disabled,
      'with-counter': props.counter,
      small: props.small,
    },
    props.className
  );
  const iconClasses = classnames('icon-wrapper__icon', {
    [props.icon]: props.icon,
  });

  const handleComponentClick = (e) => {
    if (!props.disabled) {
      props.onClick(e);
    }
  };

  const _counter = props.counter;

  return (
    <div
      className={wrapperClasses}
      onClick={handleComponentClick}
      data-testid="iconCircleButton"
    >
      <i className={iconClasses} />
      {_counter && (
        <CounterWrapper data-testid="iconCircleCounter">
          {_counter}
        </CounterWrapper>
      )}
    </div>
  );
});

IconCircle.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  spaceRight: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  isStarred: PropTypes.bool,
  isFilled: PropTypes.bool,
};

IconCircle.defaultProps = {
  className: '',
  spaceRight: false,
  color: 'green',
  isStarred: false,
};
