import styled, { keyframes } from 'styled-components/macro';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { forwardRef, useState } from 'react';

const Tooltip = forwardRef(
  (
    {
      tooltipContent,
      asChild = true,
      children,
      arrowProps,
      enableCustomTooltip = false,
      customTooltipLeft,
      customTooltipTop,
      customOnMouseEnter = () => {},
      customOnMouseLeave = () => {},
      ...props
    },
    forwardedRef
  ) => {
    const [customTooltipHoverActive, setCustomTooltipHoverActive] =
      useState(false);

    if (enableCustomTooltip) {
      return (
        <>
          <div
            onMouseEnter={(e) => {
              setCustomTooltipHoverActive(true);
              customOnMouseEnter(e);
            }}
            onMouseLeave={(e) => {
              setCustomTooltipHoverActive(false);
              customOnMouseLeave(e);
            }}
          >
            {children}
          </div>
          {customTooltipHoverActive && (
            <CustomTooltipContainer
              left={customTooltipLeft}
              top={customTooltipTop}
            >
              <CustomStyledContent>
                {tooltipContent}
                <CustomStyledArrow />
              </CustomStyledContent>
            </CustomTooltipContainer>
          )}
        </>
      );
    }

    return (
      <RadixTooltip.Root delayDuration={0} {...props}>
        {children?.length > 1 ? (
          <StyledTrigger>{children}</StyledTrigger>
        ) : (
          <RadixTooltip.Trigger asChild={asChild}>
            {children}
          </RadixTooltip.Trigger>
        )}

        {tooltipContent ? (
          <StyledContent side="top">
            {tooltipContent}
            <StyledArrow {...arrowProps} />
          </StyledContent>
        ) : null}
      </RadixTooltip.Root>
    );
  }
);

export default Tooltip;

const StyledArrow = styled(RadixTooltip.Arrow)`
  fill: rgba(63, 87, 110, 0.8);
`;

const StyledTrigger = styled(RadixTooltip.Trigger)`
  all: unset;
  all: inherit;
  font-family: 'nunitosans';
  min-width: 0;
`;

const scaleIn = keyframes({
  '0%': { opacity: 0, transform: 'scale(0)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});

const StyledContent = styled(RadixTooltip.Content)`
  z-index: 5;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgba(63, 87, 110, 0.8);
  color: white;

  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: ${scaleIn} 0.15s ease-out;
`;

const CustomStyledContent = styled.div`
  z-index: 5;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgba(63, 87, 110, 0.8);
  color: white;

  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: ${scaleIn} 0.15s ease-out;
`;

const CustomStyledArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(63, 87, 110, 0.8);
  position: absolute;
  bottom: -7px;
  left: 50%;
  transform: translateY(-50%);
`;

const CustomTooltipContainer = styled.div`
  position: fixed;
  z-index: 100000;
  top: ${({ top }) => top ?? 0};
  left: ${({ left }) => left ?? 0};
  transform: translateX(-50%);
`;
