import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import { buttonDisabled, gradientBlue } from '../../config/colors';

const Switch = forwardRef(({ value, onChange, ...props }, ref) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };
  return (
    <Wrapper>
      <Input
        type="checkbox"
        onChange={handleChange}
        checked={value}
        {...props}
      />
      <Slider disabled={props.disabled} />
    </Wrapper>
  );
});

export default Switch;

const Wrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 22px;
  min-width: 34px;
`;

const Slider = styled.span`
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${buttonDisabled};
  border-radius: 12px;

  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;

    transition: 0.4s;
  }
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  display: block;

  &:checked + ${Slider} {
    background-color: ${({ color, disabled }) =>
      !disabled && (color || gradientBlue)};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${({ color }) => color || gradientBlue};
  }

  &:checked + ${Slider}:before {
    transform: translateX(12px);
  }
`;
