import { schedulesApi } from '../../../services';

export const checklistsApi = schedulesApi.injectEndpoints({
  endpoints: (builder) => ({
    getChecklists: builder.query({
      query: (scheduleId) => {
        const url = `schedule/${scheduleId}/checklists`;

        return url;
      },
      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'ScheduleChecklists',
                id,
              })),
              { type: 'ScheduleChecklists', id: 'LIST' },
            ]
          : [{ type: 'ScheduleChecklists', id: 'LIST' }];
      },
    }),
    createChecklist: builder.mutation({
      query(payload) {
        const { id, ...restPayload } = payload;
        return {
          url: `schedule/${id}/checklists`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    updateChecklist: builder.mutation({
      query(payload) {
        const { id, checklistId, ...restPayload } = payload;
        return {
          url: `schedule/${id}/checklists/${checklistId}`,
          method: 'PUT',
          body: restPayload,
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    deleteChecklist: builder.mutation({
      query(payload) {
        const { id, checklistId } = payload;
        return {
          url: `schedule/${id}/checklists/${checklistId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    saveChecklistToTemplate: builder.mutation({
      query(checklistId) {
        return {
          url: `checklist-templates/save-checklist-as-template/${checklistId}`,
          method: 'POST',
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    assignChecklistFromTemplate: builder.mutation({
      query(payload) {
        const { id, checklistTemplateId } = payload;
        return {
          url: `schedule/${id}/assign-checklist-from-template/${checklistTemplateId}`,
          method: 'POST',
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    createChecklistItem: builder.mutation({
      query(payload) {
        const { id, checklistId, ...restPayload } = payload;
        return {
          url: `schedule/${id}/checklist/${checklistId}/items`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: (result, error, payload) => {
        if (payload.attachments?.length > 0) return [];
        return [{ type: 'ScheduleChecklists', id: 'LIST' }];
      },
    }),
    updateChecklistItem: builder.mutation({
      query(payload) {
        const { id, checklistId, checklistItemId, ...restPayload } = payload;
        return {
          url: `schedule/${id}/checklist/${checklistId}/items/${checklistItemId}`,
          method: 'PUT',
          body: restPayload,
        };
      },
      invalidatesTags: (result, error, payload) => {
        if (payload.attachments?.length > 0) return [];
        return [{ type: 'ScheduleChecklists', id: 'LIST' }];
      },
    }),
    deleteChecklistItem: builder.mutation({
      query(payload) {
        const { id, checklistId, checklistItemId, ...restPayload } = payload;
        return {
          url: `schedule/${id}/checklist/${checklistId}/items/${checklistItemId}`,
          method: 'DELETE',
          body: restPayload,
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    convertChecklistItem: builder.mutation({
      query(payload) {
        const { checklistItemId, ...restPayload } = payload;
        return {
          url: `convert-checklist-item-to-issue-or-subtask/${checklistItemId}`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
    uploadItemAttachments: builder.mutation({
      query(payload) {
        const { id, checklistId, checklistItemId, formData } = payload;

        return {
          url: `schedule/${id}/checklist/${checklistId}/items/${checklistItemId}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: () => [{ type: 'ScheduleChecklists', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetChecklistsQuery,
  useCreateChecklistMutation,
  useDeleteChecklistMutation,
  useUpdateChecklistMutation,
  useSaveChecklistToTemplateMutation,
  useAssignChecklistFromTemplateMutation,
  useCreateChecklistItemMutation,
  useUpdateChecklistItemMutation,
  useDeleteChecklistItemMutation,
  useConvertChecklistItemMutation,
  useUploadItemAttachmentsMutation,
} = checklistsApi;
