export const ROLE = 'role';
export const ASSIGNEE_EMAIL = 'assigneeEmail';
export const MYSELF = 'MYSELF';
export const MEMBER = 'MEMBER';
export const EMPLOYEE = 'EMPLOYEE';

export const FORM_DEFAULT_VALUES = {
  role: EMPLOYEE,
  assigneeEmail: '',
};

export const ROLES = [
  {
    id: 1,
    label: 'Myself',
    value: MYSELF,
  },
  /* TMP > removed {
    id: 2,
    label: 'Member',
    value: MEMBER,
  }, */
  {
    id: 3,
    label: 'Employee',
    value: EMPLOYEE,
  },
];
