import styled from 'styled-components/macro';
import { WowDisplayFlex } from '../../../../../../components_new/WowBasicElements';
import {
  darkerGray,
  gradientBlue,
  userPillGray,
} from '../../../../../../config/colors';
import Button from '../../../../../../components_new/Button';

export const TrialWrapper = styled(WowDisplayFlex)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;

  i {
    margin-left: -5px;
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  color: ${darkerGray};
  font-size: 22px;
  letter-spacing: 0px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  color: ${userPillGray};
  font-size: 14px;
  margin-bottom: 5px;
`;

export const StyledButton = styled(Button)`
  margin-top: 30px;
  width: 100%;
`;

export const StyledLink = styled.a`
  color: ${gradientBlue};
`;
