import styled from 'styled-components/macro';
import { darkerGray } from '../../../../config/colors';
import { COLORS } from './constants';

const SelectedBox = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border-color: ${COLORS.purple};
    border-style: solid;
    border-width: 7px;
    transform: scale(1.35, 1.35);
    z-index: -2;
  }
`;

const Box = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 50px;
  cursor: pointer;
`;

const CheckmarkBox = styled.div`
  position: absolute;
  top: -15px;
  left: 20px;
`;

const IconBox = styled.div`
  width: 35px;
  height: 35px;
`;

const AlignItemBox = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${darkerGray};
  font-size: 0.9em;
  padding: 0 29px;
`;

export { SelectedBox, Box, CheckmarkBox, IconBox, AlignItemBox, LabelBox };
