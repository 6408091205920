import { FormattedMessage } from 'react-intl';
import { WowIcon } from '../../../../../WowBasicElements';
import { SubHeaderWrapper, SubHeaderButton } from '../../../../styles';

const SubHeader = ({ buttonKey, handleTriggerAddModal }) => {
  return (
    <SubHeaderWrapper>
      <SubHeaderButton onClick={handleTriggerAddModal}>
        <WowIcon
          className="icon icon-plus"
          size="18"
          display="flex"
          spaceRight="7"
          color="white"
        />
        <FormattedMessage id={buttonKey} />
      </SubHeaderButton>
    </SubHeaderWrapper>
  );
};

export default SubHeader;
