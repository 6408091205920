import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { appendFormDataWithAttachments } from '../../../components_new/MediaInput/util';

import { DENSITY } from '../../../config/config';
import * as apiService from './apiService';
import { pageStatus, INIT_FILTERS } from './constants';
import { formatTime } from './schedule/utils';

const PERSISTED_FILTERS = JSON.parse(
  localStorage.getItem('https://staging.wowflow.org-filters')
);

export const initState = {
  data: [],
  selectedItems: {},
  status: pageStatus.initial,
  isAdvancedFilterOpen:
    PERSISTED_FILTERS?.scheduling?.isAdvancedFilterOpen || false,
  itemDensity:
    PERSISTED_FILTERS?.scheduling?.itemDensity || DENSITY.comfortable,
  filter: {
    search: '',
    status: PERSISTED_FILTERS?.scheduling?.status || INIT_FILTERS.status,
    priority_id:
      PERSISTED_FILTERS?.scheduling?.priority_id || INIT_FILTERS.priority_id,
    sort_direction:
      PERSISTED_FILTERS?.scheduling?.sort_direction ||
      INIT_FILTERS.sort_direction,
    sort_by: PERSISTED_FILTERS?.scheduling?.sort_by || INIT_FILTERS.sort_by,
    assignees:
      PERSISTED_FILTERS?.scheduling?.assignees || INIT_FILTERS.assignees,
    assignees_companies:
      PERSISTED_FILTERS?.scheduling?.assignees_companies ||
      INIT_FILTERS.assignees_companies,
    assignees_externals:
      PERSISTED_FILTERS?.scheduling?.assignees_externals ||
      INIT_FILTERS.assignees_externals,
    watchers: PERSISTED_FILTERS?.scheduling?.watchers || INIT_FILTERS.watchers,
    watchers_companies:
      PERSISTED_FILTERS?.scheduling?.watchers_companies ||
      INIT_FILTERS.watchers_companies,
    watchers_externals:
      PERSISTED_FILTERS?.scheduling?.watchers_externals ||
      INIT_FILTERS.watchers_externals,
    replacements:
      PERSISTED_FILTERS?.scheduling?.replacements || INIT_FILTERS.replacements,
    categories:
      PERSISTED_FILTERS?.scheduling?.categories || INIT_FILTERS.categories,
    workspaces:
      PERSISTED_FILTERS?.scheduling?.workspaces || INIT_FILTERS.workspaces,
    include_sub_ws:
      PERSISTED_FILTERS?.scheduling?.include_sub_ws ||
      INIT_FILTERS.include_sub_ws,
    assets: PERSISTED_FILTERS?.scheduling?.assets || INIT_FILTERS.assets,
    asset_system_ids:
      PERSISTED_FILTERS?.scheduling?.asset_system_ids ||
      INIT_FILTERS.asset_system_ids,
    asset_group_ids:
      PERSISTED_FILTERS?.scheduling?.asset_group_ids ||
      INIT_FILTERS.asset_group_ids,
    equipment:
      PERSISTED_FILTERS?.scheduling?.equipment || INIT_FILTERS.equipment,
  },
  budget: {
    id: null,
    isEditingActive: false,
  },
  pagination: {
    page: 1,
    totalPages: 0,
    paginate_by: PERSISTED_FILTERS?.scheduling?.paginate_by || 20,
    prevPage: null,
    nextPage: null,
  },
  isAddChecklistFormOpen: false, //used to show/hide add checklist form
  assetsViewType: 'assets',
  disableAssets: false,
};

export const fetchSchedulingIssues = createAsyncThunk(
  'scheduling/fetchSchedulingIssues',
  async (_, { getState }) => {
    const { filter, pagination } = getState().scheduling;

    const {
      assignees,
      watchers,
      replacements,
      categories,
      workspaces,
      ...filterRest
    } = filter;
    const { prevPage, nextPage, total, totalPages, ...paginationRest } =
      pagination;

    const assignees_ids = assignees.map((assigne) => assigne.id);
    const watchers_ids = watchers.map((watcher) => watcher.id);
    const categories_ids = categories.map((category) => category.id);
    const replacements_ids = replacements.map((replacement) =>
      Number(replacement.id)
    );
    const workspaces_ids = workspaces.map((workspace) => workspace.id);

    const response = await apiService.getScheduleIssues({
      assignees_ids,
      watchers_ids,
      replacements_ids,
      categories_ids,
      workspaces_ids,
      ...filterRest,
      ...paginationRest,
    });

    return response.data;
  }
);

export const fetchSchedulingActivityLog = createAsyncThunk(
  'scheduling/fetchSchedulingActivities',
  async () => {
    const response = await apiService.getScheduleActivities();

    return response.data;
  }
);

export const createSchedulingIssue = createAsyncThunk(
  'scheduling/createSchedulingIssue',
  async ({ issue, shouldSaveEquipments }) => {
    const formData = new FormData();

    for (const key in issue) {
      if (Object.hasOwnProperty.call(issue, key)) {
        const value = issue[key];

        if (key === 'attachments') {
          appendFormDataWithAttachments(formData, value);

          continue;
        }

        if (key === 'time_slots') {
          value.forEach((slot, i) => {
            Object.keys(slot).forEach((item) => {
              formData.append(
                `time_slots[${i}][${item}]`,
                formatTime(slot[item])
              );
            });
          });

          continue;
        }

        // TODO: Refactor or add comments
        if (typeof value === 'object') {
          for (const innerKey in value) {
            formData.append(`${key}[${innerKey}]`, value[innerKey]);
          }

          continue;
        }

        // FormData doesn't know a concept of boolean so we need to send 0 for false and 1 for true
        if (typeof value === 'boolean') {
          const booleanValue = value ? 1 : 0;
          formData.append(key, booleanValue);

          continue;
        }
        formData.append(key, value);
      }
    }

    try {
      const response = await apiService.createScheduleIssue(formData);

      if (response?.status === 200 && shouldSaveEquipments) {
        const equipmentIds = Object.keys(issue['equipments']).map(
          (key) => issue['equipments'][key].id
        );
        const scheduleId = response?.data?.schedule?.id;
        await apiService.addEquipmentsToSchedule(scheduleId, {
          equipment_ids: equipmentIds,
        });
      }

      return response.data;
    } catch (error) {}
  }
);

export const editSchedulingIssue = createAsyncThunk(
  'scheduling/editSchedulingIssue',
  async ({ id, data: issue, callback, shouldSaveEquipments }) => {
    const formData = new FormData();

    for (const key in issue) {
      if (Object.hasOwnProperty.call(issue, key)) {
        const value = issue[key];

        if (key === 'attachments') {
          appendFormDataWithAttachments(formData, value);

          continue;
        }

        if (key === 'time_slots') {
          value.forEach((slot, i) => {
            Object.keys(slot).forEach((item) => {
              formData.append(
                `time_slots[${i}][${item}]`,
                formatTime(slot[item])
              );
            });
          });

          continue;
        }

        // TODO: Refactor or add comments
        if (typeof value === 'object') {
          if (Array.isArray(value) && value.length === 0) {
            formData.append(key, null);
            continue;
          }
          if (value === null) {
            formData.append(key, null);
          }
          for (const innerKey in value) {
            formData.append(`${key}[${innerKey}]`, value[innerKey]);
          }
          continue;
        }

        // FormData doesn't know a concept of boolean so we need to send 0 for false and 1 for true
        if (typeof value === 'boolean') {
          const booleanValue = value ? 1 : 0;
          formData.append(key, booleanValue);

          continue;
        }

        formData.append(key, value);
      }
    }

    formData.append('_method', 'PUT');

    try {
      const response = await apiService.editSchedulingIssue(id, formData);
      if (response?.status === 200) {
        if (shouldSaveEquipments) {
          const equipmentIds = Object.keys(issue.equipments).map(
            (key) => issue.equipments[key].id
          );
          await apiService.addEquipmentsToSchedule(id, {
            equipment_ids: equipmentIds,
          });
        }
        if (callback) callback();
      }
      return response.data;
    } catch (error) {}
  }
);

export const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState: initState,
  reducers: {
    toggleAdvancedFilter: (state) => {
      state.isAdvancedFilterOpen = !state.isAdvancedFilterOpen;
    },
    toggleItemDensity: (state, action) => {
      state.itemDensity = action.payload.density;
    },
    setFilter: (state, action) => {
      const currentState = current(state);
      state.filter = { ...currentState.filter, ...action.payload };
    },
    setPagination: (state, action) => {
      if (action.payload.page) {
        state.pagination.page = action.payload.page;
      }
      if (action.payload.paginate_by) {
        state.pagination.paginate_by = action.payload.paginate_by;
      }
    },
    searchSchedules: (state, action) => {
      state.filter.search = action.payload.search;
      state.pagination.page = 1;
    },
    setBudgetEditing: (state, action) => {
      const { editing, id = null } = action?.payload ?? {};
      state.budget.isEditingActive = editing;
      state.budget.id = id;
    },
    clearFilters: (state) => {
      state.filter.search = '';
      state.filter.status = INIT_FILTERS.status;
      state.filter.priority_id = INIT_FILTERS.priority_id;
      state.filter.sort_direction = INIT_FILTERS.sort_direction;
      state.filter.sort_by = INIT_FILTERS.sort_by;
      state.filter.assignees = INIT_FILTERS.assignees;
      state.filter.assignees_companies = INIT_FILTERS.assignees_companies;
      state.filter.assignees_externals = INIT_FILTERS.assignees_externals;
      state.filter.watchers = INIT_FILTERS.watchers;
      state.filter.watchers_companies = INIT_FILTERS.watchers_companies;
      state.filter.watchers_externals = INIT_FILTERS.watchers_externals;
      state.filter.replacements = INIT_FILTERS.replacements;
      state.filter.categories = INIT_FILTERS.categories;
      state.filter.workspaces = INIT_FILTERS.workspaces;
      state.filter.include_sub_ws = INIT_FILTERS.include_sub_ws;
      state.filter.assets = INIT_FILTERS.assets;
      state.filter.asset_system_ids = INIT_FILTERS.asset_system_ids;
      state.filter.asset_group_ids = INIT_FILTERS.asset_group_ids;
      state.filter.equipment = INIT_FILTERS.equipment;

      state.pagination.page = 1;
    },
    updateSelectedItems: (state, action) => {
      const { items } = action.payload;
      state.selectedItems = items;
    },
    updateIsAddChecklistFormOpen: (state, action) => {
      state.isAddChecklistFormOpen = action.payload ?? false;
    },
    updateAssetsViewType: (state, action) => {
      state.assetsViewType = action.payload;
    },
    updateDisableAssets: (state, action) => {
      state.disableAssets = action.payload ?? false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchedulingIssues.pending, (state) => {
      state.status =
        state.status === pageStatus.initial ? pageStatus.loading : 'idle';
    });

    builder.addCase(fetchSchedulingIssues.fulfilled, (state, action) => {
      const { data, ...pagination } = action.payload;

      state.data = data;

      state.pagination.total = pagination.total;
      state.pagination.page = pagination.current_page;
      state.pagination.totalPages = pagination.last_page;
      state.pagination.prevPage = pagination.prev_page_url;
      state.pagination.nextPage = pagination.next_page_url;

      state.status = pageStatus.idle;
    });

    builder.addCase(fetchSchedulingIssues.rejected, (state) => {
      state.isLoading = false;
      state.status = pageStatus.error;
    });

    builder.addCase(createSchedulingIssue.fulfilled, (state, action) => {
      state.data.push(action.payload);
    });

    builder.addCase(editSchedulingIssue.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        // TODO: Handle error (payload will be undefined when there's error)
        (shedule) => shedule.id === action.payload?.id
      );

      if (index !== -1 && state.payload) state.data[index] = state.payload;
    });
  },
});

export const {
  toggleAdvancedFilter,
  toggleItemDensity,
  setFilter,
  setPagination,
  searchSchedules,
  clearFilters,
  setBudgetEditing,
  updateSelectedItems,
  updateIsAddChecklistFormOpen,
  updateAssetsViewType,
  updateDisableAssets,
} = schedulingSlice.actions;

export default schedulingSlice.reducer;
