import styled from 'styled-components/macro';
import { darkerGray, purple } from '../../../../../config/colors';
import { themeColour } from '../../../../../helpers/theme';

export const Wrapper = styled.div`
  border-left: ${({ isSelected, ...restProps }) =>
    isSelected ? `3px solid ${themeColour(restProps) ?? purple}` : ''};
  padding: ${({ isSelected }) =>
    isSelected ? '6px 24px 6px 21px' : '6px 24px'};
  cursor: pointer;

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  ${({ isExactRoute }) =>
    isExactRoute ? `a { pointer-events: none; display: block; }` : null}
`;

export const Section = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Icon = styled.i`
  margin-right: 16px;
  color: ${({ isSelected, ...restProps }) =>
    isSelected ? themeColour(restProps) ?? purple : darkerGray};
`;

export const SectionName = styled.div`
  font-size: ${({ isSubsection }) => (isSubsection ? '13px' : '16px')};
  font-weight: bold;
  letter-spacing: 0.2px;
  white-space: nowrap;
  color: ${({ isSelected, ...restProps }) =>
    isSelected ? themeColour(restProps) ?? purple : darkerGray};
`;

export const Subsection = styled.div`
  padding: 12px 24px 12px 56px;
  color: ${({ isSelected, ...restProps }) =>
    isSelected ? themeColour(restProps) ?? purple : darkerGray};
  cursor: pointer;
`;

export const SubsectionsWrapper = styled.div`
  margin-top: 6px;
`;

export const SubsectionWrapper = styled.div`
  cursor: pointer;
  ${({ isExactRoute }) =>
    isExactRoute ? `a { pointer-events: none; display: block; }` : null}
`;
