import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  newRed,
  purple500,
  greenButtonColor,
  white,
  gray,
  newRedBg,
  confirmGreen,
  buttonDisabled,
} from '../../config/colors';

const APPEARANCE = {
  contained: 'contained',
  outlined: 'outlined',
  text: 'text',
};

const COLOR = {
  blue: 'blue',
  green: 'green',
  red: 'red',
  gray: 'gray',
};

const SIZE = {
  auto: 'auto',
  small: 'small',
};

const getSizeStyles = (size) => {
  if (size === SIZE.small) {
    return smallButtonStyles;
  }

  return null;
};

const getColor = (color, disabled) => {
  if (disabled) {
    return buttonDisabled;
  } else {
    switch (color) {
      case COLOR.blue:
        return purple500;
      case COLOR.red:
        return newRed;
      case COLOR.gray:
        return gray;
      case COLOR.green:
      default:
        return greenButtonColor;
    }
  }
};

const getBackgroundColor = (color, appearance, disabled, customColor) => {
  if (appearance !== APPEARANCE.contained) {
    return 'transparent';
  } else {
    return customColor && !disabled ? customColor : getColor(color, disabled);
  }
};

// This color logic is getting ugly. Can't really use getColor anymore because of hover.
// Consider removing getColor completely and make color logic more
// explicit even though that could result in more lines of code.
const getBackgroundColorOnHover = (color, appearance, disabled) => {
  if (disabled) {
    return buttonDisabled;
  } else if (appearance === APPEARANCE.text) {
    if (color === COLOR.gray) {
      return gray;
    }

    if (color === COLOR.red) {
      return newRed;
    }

    return 'transparent';
  } else {
    switch (color) {
      case COLOR.blue:
        return purple500;
      case COLOR.red:
        return newRedBg;
      case COLOR.green:
      default:
        return confirmGreen;
    }
  }
};

const getBorderColorOnHover = (color, appearance, disabled) => {
  if (disabled) {
    return buttonDisabled;
  } else if (appearance === APPEARANCE.text) {
    return 'transparent';
  } else if (appearance === APPEARANCE.contained && color === COLOR.red) {
    return newRedBg;
  } else {
    switch (color) {
      case COLOR.blue:
        return purple500;
      case COLOR.red:
        return newRed;
      case COLOR.green:
      default:
        return confirmGreen;
    }
  }
};

const getTextColor = (color, appearance) => {
  if (appearance === APPEARANCE.contained) {
    return white;
  } else {
    return getColor(color);
  }
};

const getBorderColor = (color, appearance, disabled, customColor) => {
  if (appearance === APPEARANCE.text) {
    return 'transparent';
  } else {
    return customColor && !disabled ? customColor : getColor(color, disabled);
  }
};

const smallButtonStyles = css`
  width: 155px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 12px;
  font-family: 'nunitosans', 'Roboto', sans-serif;
`;

const BasicButton = styled.button`
  cursor: pointer;
  font-weight: 600;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 1.33px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  text-transform: uppercase;
  border: 2px solid;
`;

const StyledButton = styled(BasicButton)`
  & + ${BasicButton} {
    margin-left: 16px;
  }

  ${({ size }) => getSizeStyles(size)};
  background-color: ${({ color, appearance, disabled, customColor }) =>
    getBackgroundColor(color, appearance, disabled, customColor)};
  color: ${({ color, appearance }) => getTextColor(color, appearance)};
  border-color: ${({ color, appearance, disabled, customColor }) =>
    getBorderColor(color, appearance, disabled, customColor)};

  :hover {
    color: ${white};
    background-color: ${({ color, appearance, disabled, customColor }) =>
      customColor && !disabled
        ? customColor
        : getBackgroundColorOnHover(color, appearance, disabled)};
    border-color: ${({ color, appearance, disabled, customColor }) =>
      customColor && !disabled
        ? customColor
        : getBorderColorOnHover(color, appearance, disabled)};
    ${({ customColor }) => (customColor ? 'opacity: 0.8' : '')}
  }
`;

const Button = ({
  appearance = APPEARANCE.contained,
  color = COLOR.green,
  size = SIZE.auto,
  children,
  ...props
}) => {
  return (
    <StyledButton appearance={appearance} color={color} size={size} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
