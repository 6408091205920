import vest, { test, enforce } from 'vest';
import dayjs from 'dayjs';
import { transformAttachments } from '../../../../components_new/MediaInput/util';

import { FORM_DEFAULT_VALUES } from './constants';

const formValidation = vest.create((data = {}) => {
  const {
    title,
    type_id,
    isReturnDateMandatory,
    return_date,
    reference_number,
    serial_number,
  } = data;

  test('title', 'Enter longer schedule title', () => {
    enforce(title).longerThan(0);
  });

  test('title', "Title can't be too long", () => {
    enforce(title).shorterThan(256);
  });

  if (reference_number) {
    test('reference_number', "Reference number can't be too long", () => {
      enforce(reference_number).shorterThan(256);
    });
  }

  if (serial_number) {
    test('serial_number', "Serial number can't be too long", () => {
      enforce(serial_number).shorterThan(256);
    });
  }

  if (isReturnDateMandatory) {
    test('return_date', 'Return date is mandatory.', () => {
      enforce(return_date).isNotEmpty();
    });
  }

  test('type_id', 'Type is mandatory.', () => {
    enforce(type_id).isNotEmpty();
  });
});

const parseEquipmentData = (equipment) => {
  const parseTargetFields = FORM_DEFAULT_VALUES;

  const targetFields = Object.keys(parseTargetFields);
  let parsedData = {};

  for (let field of targetFields) {
    if (equipment[field] !== null) {
      parsedData[field] = equipment[field];
    } else {
      parsedData[field] = parseTargetFields[field];
    }
  }

  parsedData = {
    ...parsedData,
    attachments: transformAttachments(equipment.attachments),
    links: equipment.links_v2 ?? [],
  };

  return parsedData;
};

const optionsWithIntl = (options, intl) => {
  return options.map((option) => {
    return { ...option, label: intl.formatMessage({ id: option.labelKey }) };
  });
};

const parseReturnPeriod = (period) => {
  if (!period) return;

  const { type, value } = period;

  let days = 1;
  switch (type) {
    case 'DAYS':
      days = Number(value);
      break;
    case 'WEEKS':
      days = 7 * Number(value);
      break;
    case 'MONTHS':
      days = 30 * Number(value);
      break;
    default:
      break;
  }

  return dayjs().add(days - 1, 'day');
};

export {
  formValidation,
  parseEquipmentData,
  optionsWithIntl,
  parseReturnPeriod,
};
