import styled from 'styled-components/macro';
import { darkerGray } from '../../../../config/colors';
import { themeColour } from '../../../../helpers/theme';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 46px;
`;

export const EditProfile = styled.span`
  background: ${themeColour};
  border-radius: 8px;
  padding: 12px 32px;
  width: fit-content;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 1.33px;
  line-height: 10px;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 14px;

  &:hover {
    background: ${(props) =>
      themeColour({
        ...props,
        isHovering: true,
        reverseGradient: true,
      })};
  }
`;

export const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 26px;
`;

export const Name = styled.h2`
  font-size: 24px;
  margin: 0;
  letter-spacing: 0px;
  color: ${darkerGray};
  font-weight: 600;
`;

export const Email = styled.h4`
  margin: 0;
  color: ${darkerGray};
  margin-bottom: 14px;
  font-weight: normal;
`;
