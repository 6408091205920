import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ActionsMenu from '../../../../components/sticky-header/ActionsMenu';
import ActionButton from '../../../../components/sticky-header/ActionButton';
import ModalConfirm from '../../../../components_new/ModalConfirm';
import ModalInfo from '../../../../components_new/ModalInfo';
import useModalState from '../../../../hooks/useModalState';
import { ISSUE_ITEM_ACTION_OPTIONS } from '../constants';
import {
  useDeleteIssueMutation,
  useIssueQuickCompleteMutation,
  useUpdateIssueMutation,
} from '../service';
import PdfExportModal from '../Issue/components/PdfExportModal.js'; // TO DO > see about placement
import ModalDuplicate from './ModalDuplicate';
import ModalReassign from './ModalReassign';

const ItemActions = ({
  item,
  actionCallbacks,
  permissions = {},
  isDraft,
  showOptionList,
  modalClasses = {},
}) => {
  const intl = useIntl();
  const { id, archived } = item;

  const canArchiveIssue = useMemo(() => {
    let value =
      typeof permissions.canArchiveIssue === 'undefined'
        ? true
        : permissions.canArchiveIssue;

    if (showOptionList && Array.isArray(showOptionList)) {
      value = false;
      const target = showOptionList.find(
        (item) => item === ISSUE_ITEM_ACTION_OPTIONS.ARCHIVE_ISSUE
      );
      if (target) value = true;
    }

    return value;
  }, [permissions, showOptionList]);

  const canDeleteIssue = useMemo(() => {
    let value =
      typeof permissions.canDeleteIssue === 'undefined'
        ? true
        : permissions.canDeleteIssue;

    if (showOptionList && Array.isArray(showOptionList)) {
      value = false;
      const target = showOptionList.find(
        (item) => item === ISSUE_ITEM_ACTION_OPTIONS.DELETE_ISSUE
      );
      if (target) value = true;
    }

    return value;
  }, [permissions, showOptionList]);

  const canQuickCompleteIssue = useMemo(() => {
    let value = false;

    if (showOptionList && Array.isArray(showOptionList)) {
      value = false;
      const target = showOptionList.find(
        (item) => item === ISSUE_ITEM_ACTION_OPTIONS.QUICK_COMPLETE_ISSUE
      );
      if (target) value = true;
    }

    value =
      typeof permissions.canQuickCompleteIssue === 'undefined'
        ? true
        : permissions.canQuickCompleteIssue;

    return value;
  }, [permissions, showOptionList]);

  const canExportToPdf = useMemo(() => {
    let value =
      typeof permissions.canExportToPdf === 'undefined'
        ? true
        : permissions.canExportToPdf;

    if (showOptionList && Array.isArray(showOptionList)) {
      value = false;
      const target = showOptionList.find(
        (item) => item === ISSUE_ITEM_ACTION_OPTIONS.EXPORT_PDF
      );
      if (target) value = true;
    }

    return value;
  }, [permissions, showOptionList]);

  const canDuplicateIssue = useMemo(() => {
    let value =
      typeof permissions.canDuplicateIssue === 'undefined'
        ? true
        : permissions.canDuplicateIssue;

    if (showOptionList && Array.isArray(showOptionList)) {
      value = false;
      const target = showOptionList.find(
        (item) => item === ISSUE_ITEM_ACTION_OPTIONS.DUPLICATE_ISSUE
      );
      if (target) value = true;
    }

    return value;
  }, [permissions, showOptionList]);

  const canReassignIssue = useMemo(() => {
    let value =
      typeof permissions.canReassignIssue === 'undefined'
        ? true
        : permissions.canReassignIssue;

    if (showOptionList && Array.isArray(showOptionList)) {
      value = false;
      const target = showOptionList.find(
        (item) => item === ISSUE_ITEM_ACTION_OPTIONS.REASSIGN_ISSUE
      );
      if (target) value = true;
    }

    return value;
  }, [permissions, showOptionList]);

  //#region quick complete
  const [issueQuickComplete] = useIssueQuickCompleteMutation();

  const [
    isVisibleModalConfirmQuickComplete,
    setIsVisibleModalConfirmQuickComplete,
  ] = useState();

  const handleToggleModalConfirmQuickComplete = () =>
    setIsVisibleModalConfirmQuickComplete((prevState) => !prevState);

  const [isVisibleModalQuickCompleteInfo, setIsVisibleModalQuickCompleteInfo] =
    useState();

  const handleToggleModalQuickCompleteInfo = () =>
    setIsVisibleModalQuickCompleteInfo((prevState) => !prevState);

  const handleQuickComplete = async () => {
    const response = await issueQuickComplete([id]);

    if (response?.data?.success && response?.data?.not_completed?.length > 0) {
      setIsVisibleModalQuickCompleteInfo(true);
    }
    if (
      actionCallbacks?.quickCompleteSuccessCallback &&
      response?.data?.not_completed?.length === 0
    ) {
      actionCallbacks.quickCompleteSuccessCallback(item);
    }
    return response;
  };
  //#endregion

  const {
    isVisible: isVisibleModalConfirmArchive,
    toggleModalState: toggleModalConfirmArchive,
  } = useModalState(false);

  const {
    isVisible: isVisibleModalConfirmActivate,
    toggleModalState: toggleModalConfirmActivate,
  } = useModalState(false);

  const {
    isVisible: isVisibleModalConfirmDelete,
    toggleModalState: toggleModalConfirmDelete,
  } = useModalState(false);

  const [updateIssue] = useUpdateIssueMutation();
  const [deleteIssue] = useDeleteIssueMutation();

  const handleToggleArchived = async () => {
    await updateIssue({
      id,
      body: {
        archived: !archived,
      },
    });
    toggleModalConfirmArchive();
    if (actionCallbacks?.archiveSuccessCallback) {
      actionCallbacks.archiveSuccessCallback();
    }
  };
  const handleDelete = async () => {
    await deleteIssue(id);
    toggleModalConfirmDelete();
    if (actionCallbacks?.deleteSuccessCallback) {
      actionCallbacks.deleteSuccessCallback(item);
    }
  };

  const {
    isVisible: isPdfExportModalVisible,
    toggleModalState: togglePdfExportModal,
  } = useModalState();

  const {
    isVisible: isVisibleModalDuplicate,
    toggleModalState: toggleModalDuplicate,
  } = useModalState(false);

  const {
    isVisible: isVisibleModalReassign,
    toggleModalState: toggleModalReassign,
  } = useModalState(false);

  return (
    <>
      <ActionsMenu
        icon="icon-dots"
        color="gray"
        position="right"
        shape="round"
        height="auto"
      >
        {canReassignIssue && (
          <ActionButton
            uppercase
            icon="icon-peoples"
            variant="menu-item"
            onClick={toggleModalReassign}
          >
            <FormattedMessage id="reassign" />
          </ActionButton>
        )}
        {canDuplicateIssue && !isDraft && (
          <ActionButton
            icon="icon-duplicate"
            variant="menu-item"
            onClick={toggleModalDuplicate}
            uppercase
            data-testid="duplicate"
          >
            <FormattedMessage id="duplicate_issue" />
          </ActionButton>
        )}
        {canExportToPdf && !isDraft && (
          <ActionButton
            icon="icon-export"
            variant="menu-item"
            onClick={togglePdfExportModal}
            uppercase
            data-testid="exportToPdf"
          >
            <FormattedMessage id="export_to_pdf" />
          </ActionButton>
        )}
        {canQuickCompleteIssue && !isDraft && (
          <ActionButton
            uppercase
            icon="icon-complete"
            variant="menu-item"
            color="green"
            onClick={handleToggleModalConfirmQuickComplete}
            data-testid="quickComplete"
          >
            <FormattedMessage id="quick_complete" />
          </ActionButton>
        )}
        {canArchiveIssue && (
          <ActionButton
            uppercase
            icon="icon-archive"
            color="gray"
            variant="menu-item"
            onClick={toggleModalConfirmArchive}
          >
            <FormattedMessage id="archive" />
          </ActionButton>
        )}
        {canDeleteIssue && (
          <ActionButton
            uppercase
            icon="icon-trash"
            color="red"
            variant="menu-item"
            onClick={toggleModalConfirmDelete}
            data-testid="deleteIssue"
          >
            <FormattedMessage id="delete" />
          </ActionButton>
        )}
      </ActionsMenu>
      {/* //#region modals */}
      {isVisibleModalConfirmQuickComplete && (
        <ModalConfirm
          isOpen={isVisibleModalConfirmQuickComplete}
          handleClose={handleToggleModalConfirmQuickComplete}
          handleAction={handleQuickComplete}
          intlValues={{
            messageKey: 'quick_complete_confirm',
            issueName: item.title,
          }}
          modalClasses={modalClasses?.quickComplete}
        />
      )}
      {isVisibleModalQuickCompleteInfo ? (
        <ModalInfo
          isOpen={isVisibleModalQuickCompleteInfo}
          handleAction={handleToggleModalQuickCompleteInfo}
          handleClose={handleToggleModalQuickCompleteInfo}
          icon="icon-important-round"
          heading={intl.formatMessage({ id: 'open_subtasks' })}
          intlValues={
            item.statistics?.subtasks_active === 1
              ? {
                  messageKey: 'info_open_subtask_single',
                }
              : {
                  messageKey: 'info_open_subtasks_mulitple',
                  count: item.statistics?.subtasks_active,
                }
          }
        />
      ) : null}
      {isVisibleModalConfirmArchive ? (
        <ModalConfirm
          isOpen={isVisibleModalConfirmArchive}
          handleClose={toggleModalConfirmArchive}
          handleAction={handleToggleArchived}
          actionButtonColor="red"
          actionType="archive"
          intlValues={{
            messageKey: 'archive_item',
            itemName: item.title,
          }}
        />
      ) : null}
      {isVisibleModalConfirmActivate ? (
        <ModalConfirm
          isOpen={isVisibleModalConfirmActivate}
          handleClose={toggleModalConfirmActivate}
          handleAction={handleToggleArchived}
          actionButtonColor="green"
          actionType="activate"
          intlValues={{
            messageKey: 'activate_archived_item',
          }}
        />
      ) : null}
      {isVisibleModalConfirmDelete ? (
        <ModalConfirm
          isOpen={isVisibleModalConfirmDelete}
          handleClose={toggleModalConfirmDelete}
          handleAction={handleDelete}
          actionButtonColor="red"
          actionType="delete"
          intlValues={{
            messageKey: 'delete_item',
            itemName: item.title,
          }}
          modalClasses={modalClasses?.delete}
        />
      ) : null}
      {isPdfExportModalVisible ? (
        <PdfExportModal toggle={togglePdfExportModal} issue={item} />
      ) : null}
      {isVisibleModalDuplicate ? (
        <ModalDuplicate
          isOpen={isVisibleModalDuplicate}
          handleClose={toggleModalDuplicate}
          issue={item}
          duplicateSuccessCallback={actionCallbacks?.duplicateSuccessCallback}
        />
      ) : null}
      {isVisibleModalReassign ? (
        <ModalReassign onClose={toggleModalReassign} item={item} />
      ) : null}
    </>
  );
};

ItemActions.propTypes = {
  item: PropTypes.object.isRequired,
  actionCallbacks: PropTypes.object,
  isDraft: PropTypes.bool,
};

export default ItemActions;
