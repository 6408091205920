import styled from 'styled-components/macro';
import { WowList } from '../../../../../../../../../components/wowlist/shared';
import {
  darkerGray,
  hardWhite,
  gray,
  filtersAdvancedGray,
} from '../../../../../../../../../config/colors';
import {
  Title as TitleBase,
  HeaderSection as HeaderSectionBase,
} from '../../../../../../../../../components_new/StickyHeader';

export const IconTask = styled.i.attrs({ className: 'icon icon-task' })``;
export const IconAsset = styled.i.attrs({ className: 'icon icon-assets' })``;
export const IconClose = styled.i.attrs({ className: 'icon icon-close' })``;

export const IconCloseWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 12px;
`;

export const Card = styled.div`
  display: flex;
  background-color: white;
  padding: 10px 17px 10px 3px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #37465f26;
  margin-bottom: 17px;
  background-color: ${hardWhite};
  position: relative;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 85px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
  margin-left: 9px;
`;

export const CardCenterContent = styled.div`
  margin-left: 12px;
  flex: 1;
`;

export const CardRightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const IssueStatistic = styled.span`
  color: ${darkerGray};
  font-size: 12px;
`;

export const GroupText = styled.span`
  font-size: 12px;
  color: ${darkerGray};
`;

export const Circle = styled.div`
  width: 3px;
  height: 3px;
  margin: 0px 6px 0px 6px;
  background-color: ${darkerGray};
  border-radius: 9999px;
`;

export const TypeText = styled.span`
  font-size: 12px;
  color: ${gray};
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleText = styled.span`
  color: ${darkerGray};
  font-size: 17px;
  font-weight: 600;
`;

export const UserImage = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 9999px;
`;

export const Wrapper = styled.div`
  flex-grow: 1;
`;

export const Content = styled(WowList)`
  margin-bottom: 10px;
  min-height: 430px;

  .infinite-scroll-component {
    max-height: 430px;

    i.icon-check:before {
      font-size: 18px;
    }
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 26px;
`;

export const FooterButtons = styled.div`
  display: flex;
`;

export const FooterText = styled.span`
  color: ${filtersAdvancedGray};
  font-size: 12px;
`;

export const NoItems = styled.div`
  padding: 0 15px 15px 15px;
  text-align: center;
  padding-top: 15px;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  padding-top: 15px;

  div:first-child {
    position: relative;
    width: 20px;
  }
`;

export const EquipmentWrapper = styled.div`
  > * {
    margin-bottom: 15px;
  }
`;

export const HeaderSection = styled(HeaderSectionBase)`
  padding-bottom: 30px;
`;

export const Title = styled(TitleBase)`
  margin-top: 0px;
  margin-bottom: 0px;
`;
