import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

import { darkerGray, darkGray, outline } from '../../config/colors';
import { ThemedIcon } from '../../helpers/theme';
import { WowIcon, WowTooltipWrapper } from '../WowBasicElements';
import Tooltip from '../Tooltip';
import { FormattedMessage } from 'react-intl';

export const SIZE = {
  small: '390px',
  medium: '520px',
  large: '900px',
  extraLarge: '1100px',
};

export const VISIBILITY = {
  visible: 'visible',
  hidden: 'hidden',
};

const Modal = ({
  title,
  isOpen,
  onClose,
  children,
  size = SIZE.small,
  visibility = VISIBILITY.visible,
  hasTitleIcon,
  iconClass,
  maxHeight = '550px',
  contentStyleOverride = {},
  onModalVisibilityChange,
  callbackOnTitleIcon,
  overlayStyleOverride = {},
  centerTile,
  hideCloseIcon,
  centerTitlePadding,
  classes = [],
  dataTestId,
  titleInfoTooltipKey,
  closeTimeoutMS,
  overlayClassName,
}) => {
  useEffect(() => {
    // For when we want to hide another modal if a new one is visible

    if (onModalVisibilityChange) {
      onModalVisibilityChange(isOpen);
    }

    return () => {
      if (onModalVisibilityChange) {
        onModalVisibilityChange(false);
      }
    };
  }, [isOpen, onModalVisibilityChange]);

  const renderHeader = () => (
    <>
      {onClose && !hideCloseIcon ? (
        <ButtonClose onClick={onClose} data-testid="closeButton">
          <i className="icon icon-close" />
        </ButtonClose>
      ) : null}

      {hasTitleIcon && (
        <TitleIconWrapper>
          <ThemedIcon
            mainAccent
            className={`icon ${iconClass}`}
            onClick={callbackOnTitleIcon ? callbackOnTitleIcon : onClose}
          />
        </TitleIconWrapper>
      )}

      {title ? (
        <Title
          hasTitleIcon={hasTitleIcon}
          data-testid="modalTitle"
          centerTile={centerTile}
        >
          {title}

          {!!titleInfoTooltipKey ? (
            <Tooltip
              tooltipContent={
                <WowTooltipWrapper width={250}>
                  <FormattedMessage id={titleInfoTooltipKey} />
                </WowTooltipWrapper>
              }
            >
              <WowIcon
                className="icon-important-round"
                spaceLeft="5"
                display="flex"
                size="16"
              />
            </Tooltip>
          ) : null}
        </Title>
      ) : null}
    </>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        ...style(
          size,
          visibility,
          maxHeight,
          overlayStyleOverride,
          contentStyleOverride
        ),
      }}
      onRequestClose={onClose}
      ariaHideApp={false}
      className={classes.join(' ')}
      closeTimeoutMS={closeTimeoutMS}
      overlayClassName={overlayClassName}
    >
      <div data-testid={dataTestId ?? 'modal'}>
        {centerTile ? (
          <TitleWrapper centerTitlePadding={centerTitlePadding}>
            {renderHeader()}
          </TitleWrapper>
        ) : (
          renderHeader()
        )}
        <div>{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;

const Title = styled.div`
  color: ${darkGray};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: ${({ hasTitleIcon }) => (hasTitleIcon ? '29px' : '24px')};
  line-height: normal;
  line-height: 1;
  position: relative;
  display: flex;
  align-items: center;
  text-align: ${({ centerTile }) => (centerTile ? 'center' : 'left')};
  margin-top: ${({ hasTitleIcon }) => (hasTitleIcon ? '35px' : '0px')};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ centerTitlePadding }) =>
    centerTitlePadding ? centerTitlePadding : '0px'};
`;

const ButtonClose = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 12px;
  top: 20px;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  outline: none;
  color: ${darkerGray};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  i {
    display: flex;
  }

  &:hover,
  &:focus {
    background-color: ${outline};
  }
`;

const TitleIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 10;

  .icon:before {
    font-size: 30px;
  }

  .icon:hover {
    cursor: pointer;
  }
`;

const style = (
  size,
  visibility,
  maxHeight,
  overlayStyleOverride,
  contentStyleOverride
) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 999,
    visibility: visibility,
    ...overlayStyleOverride,
  },
  content: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    boxShadow: '0px 8px 20px #37465F0F',
    background: '#fff',
    borderRadius: '8px',
    outline: 'none',
    border: 'none',
    padding: '25px',
    width: size,
    visibility: visibility,
    transition: 'height 0.15s ease-out',
    maxHeight: maxHeight,
    ...contentStyleOverride,
  },
});
