import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  isLoadingSwitchClient: false,
  isLoadingUser: false,
  notificationsUnreadCount: 0,
  showOnboardingSuccess: false,
  showOnboardingProcess: false,
  showOnboardingSuccessMobile: false,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState: INIT_STATE,
  reducers: {
    setIsLoadingSwitchClient: (state, action) => {
      state.isLoadingSwitchClient = action.payload ?? false;
    },
    setNotificationsUnreadCount: (state, action) => {
      state.notificationsUnreadCount = action.payload ?? 0;
    },
    setShowOnboardingSuccess: (state, action) => {
      state.showOnboardingSuccess = action.payload ?? false;
    },
    setShowOnboardingProcess: (state, action) => {
      state.showOnboardingProcess = action.payload ?? false;
    },
    setShowOnboardingSuccessMobile: (state, acttion) => {
      state.showOnboardingSuccessMobile = acttion.payload ?? false;
    },
    setIsLoadingUser: (state, acttion) => {
      state.isLoadingUser = acttion.payload ?? false;
    },
  },
});

export const {
  setIsLoadingSwitchClient,
  setNotificationsUnreadCount,
  setShowOnboardingSuccess,
  setShowOnboardingProcess,
  setShowOnboardingSuccessMobile,
  setIsLoadingUser,
} = mainSlice.actions;

export default mainSlice.reducer;
