import vest, { enforce, test } from 'vest';
import { ASSIGNEE_EMAIL, MYSELF } from './constants';
import { INPUT_MAX_LENGTH } from '../../constants';
import { isEmail } from '../../../../../../utils/validators';
enforce.extend({
  isValidEmail: (value) => isEmail(value),
});

export const formValidation = vest.create((data = {}) => {
  const { assigneeEmail, role } = data;

  if (role !== MYSELF) {
    test(ASSIGNEE_EMAIL, `TMP_assigne_email_not_empty`, () => {
      enforce(assigneeEmail).isNotEmpty();
    });

    test(ASSIGNEE_EMAIL, 'form_msg_email_not_valid', () => {
      enforce(assigneeEmail).isValidEmail();
    });

    test(ASSIGNEE_EMAIL, `TMP_assignee_email_max_length`, () => {
      enforce(assigneeEmail).shorterThanOrEquals(INPUT_MAX_LENGTH);
    });
  }
});

export const getAvatarInitials = (value) => {
  if (!value.length) return '';
  const values = value.split('@');
  if (values.length === 2) {
    return `${values[0].charAt(0)}${values[1].charAt(0)}`;
  }

  return values[0].charAt(0);
};
