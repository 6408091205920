import styled from 'styled-components/macro';

import { darkGray } from '../../config/colors';

const Title = styled.h2`
  color: ${darkGray};
  font-size: 26px;
  font-weight: normal;
  ${({ isClickable }) => (isClickable ? 'cursor: pointer' : null)}
`;

export default Title;
