import { useIntl } from 'react-intl';
import { SearchIcon, SearchInput, SearchWrapper } from '../../../../styles';
import { useEffect } from 'react';
import { useDebounce } from '../../../../../../hooks/useDebounce';

const Search = (props) => {
  const { search, setSearch, handleSearch } = props;

  const intl = useIntl();
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    handleSearch(debouncedSearch);
  }, [debouncedSearch, handleSearch]);

  return (
    <SearchWrapper>
      <SearchIcon />
      <SearchInput
        placeholder={intl.formatMessage({
          id: 'search',
        })}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
    </SearchWrapper>
  );
};

export default Search;
