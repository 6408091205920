import styled from 'styled-components/macro';
import { darkGray, gradientBlue, hardWhite } from '../../../../config/colors';

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 0;
  cursor: pointer;
  ${({ lowOpacity }) => (lowOpacity ? 'opacity: 0.75' : null)};
`;

export const OptionPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  ${(props) =>
    props.isSelected
      ? `
    padding: 2px;
    border: 2px solid ${gradientBlue};
  `
      : null}
`;

export const OptionLabel = styled.span`
  flex-grow: 1;
  font-size: 18px;
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`;

//#region collaborators
export const Container = styled.div`
  display: flex;

  flex-direction: column;
  margin-bottom: 15px;
`;

export const GroupOptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 18px;

  background-color: ${hardWhite};

  &:hover {
    cursor: pointer;
  }
`;

export const GroupOptionPhoto = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
  min-width: 26px;
`;

export const GroupOptionIconWrapper = styled.div`
  background-color: #b2bfcd;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
`;

export const GroupOptionLabel = styled.span`
  flex-grow: 1;
  margin-left: 16px;

  color: ${darkGray};
  font-weight: ${({ isBold }) => (isBold ? '700' : '600')};
  font-size: ${({ size }) => (size ? size + 'px' : '15px')};
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`;

export const ArrowUp = styled.i.attrs({ className: 'icon icon-arrowdown-02' })`
  color: ${darkGray};
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(180deg)' : '')};
  transition: transform 0.2s;

  cursor: pointer;
  font-size: 15px;

  margin-right: 10px;
`;
