import styled from 'styled-components/macro';

import { darkerGray, hardWhite, outline, purple500 } from '../../config/colors';
import { themeColour } from '../../helpers/theme';

const SubMenuItem = styled.li`
  font-size: 13px;
  font-weight: 400;
  color: ${({ selected, ...restProps }) =>
    selected ? themeColour(restProps) ?? purple500 : darkerGray};
  padding: 12px 14px;
  padding-left: 34px;

  border-bottom: 1px solid ${outline};
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &:focus {
    background-color: ${hardWhite};
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export default SubMenuItem;
