import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  height: 35px;
  position: relative;
  width: 100%;
  overflow: scroll hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HourLabelWrapper = styled.div`
  display: flex;
  padding-bottom: 10.5px;
  padding-top: 10px;
  z-index: 200;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
