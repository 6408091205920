const showDirections = (destination, userLocation) => {
  const origin = userLocation
    ? `&origin=${userLocation.lat},${userLocation.lng}`
    : '';
  return window.open(
    `https://www.google.com/maps/dir/?api=1${origin}&destination=${destination.lat},${destination.lng}`,
    '_blank'
  );
};
export default showDirections;
