import { createSelector } from 'reselect';
import {
  WORKSPACE_LIST,
  FAVORITE_WORKSPACES,
  USER_LIST,
  CATEGORY_LIST,
  WS_ISSUE_LIST,
  SUB_WS_LIST,
  ISSUE_LIST,
  ISSUE_CATEGORY,
  AUTOCOMPLETE_LIST,
} from './filters';
import { getUser } from '../user/selectors';

const selectorPath = (state) => state.filter.toJS();
export const getFilterParameters = (state) =>
  // TODO imam problem sa ovim. inicijalno dobijem
  // parametes objekt a prelasku na neku drugu stranicu dobijem ArrayMap ili nesto slicno.
  // get  In(state, ['filter', 'parameters'])
  state.filter.toJS().parameters;

export const getFilter = (filterName) =>
  createSelector(selectorPath, (filters) => filters[filterName]);

export const getFilterForWorkspaces = getFilter(WORKSPACE_LIST);
export const getFilterForFavorites = getFilter(FAVORITE_WORKSPACES);
export const getFilterForUsers = getFilter(USER_LIST);
export const getFilterForCategory = getFilter(CATEGORY_LIST);
export const getFilterForWsIssues = getFilter(WS_ISSUE_LIST);
export const getSubWSfilters = getFilter(SUB_WS_LIST);
export const getIssueListFilters = getFilter(ISSUE_LIST);
export const getIssueConfigFilter = getFilter(ISSUE_CATEGORY);
export const getFilterForAutocomplete = getFilter(AUTOCOMPLETE_LIST);
export const getAdvancedFiltersState = createSelector(
  selectorPath,
  (filters) => filters['showAdvancedFilter']
);

export const getFilterWithUserId = (filter) =>
  createSelector(getUser, (user) => ({
    ...filter,
    userId: user.id,
  }));

export const getIssuesAdvancedFilterCount = createSelector(
  getIssueListFilters,
  (filter) => {
    const keys = [
      'issueType',
      'categories',
      'reporters',
      'assignees',
      'workspaces',
      'watchers',
      'workers',
      'startDate',
      'endDate',
      'rating',
      'scheduling_issues',
      'asset_ids',
    ];

    let count = 0;

    keys.forEach((key) => {
      if (filter[key] && filter[key].length > 0) {
        count++;
      }
    });

    return count;
  }
);
