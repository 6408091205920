import { forwardRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { selectIssue } from '../../../../../../selectors';
import ModalUsers from '../../../../../../../../../../components_new/ModalUsers';
import useAssigneeItem from './hooks/useAssigneeItem';
import { getUser } from '../../../../../../../../../../redux/user/selectors';

const AssigneeItem = forwardRef((props, ref) => {
  const intl = useIntl();
  const { watch } = useFormContext();
  const historyParams = useParams();
  const issueId = historyParams?.id;
  const issue = useSelector(selectIssue(issueId));
  const workspace = props?.workspace ?? watch('workspace');
  const loggedInUser = useSelector(getUser);

  const { handleOnChangeCallback } = useAssigneeItem();

  const workspaceIds = useMemo(() => {
    return workspace?.id ? [workspace.id] : [];
  }, [workspace?.id]);

  const shouldShowTabs = useMemo(() => {
    return loggedInUser?.client?.id === loggedInUser?.main_client_id;
  }, [loggedInUser?.client?.id, loggedInUser?.main_client_id]);

  return (
    <ModalUsers
      {...props}
      mode="single"
      title={intl.formatMessage({ id: 'select_assignee' })}
      shouldShowTabs={shouldShowTabs}
      onChange={(value, items) => {
        if (props.onChangeCallback) {
          props.onChangeCallback({ value, items });
          return;
        }

        handleOnChangeCallback({ value, workspace, issue, items });
      }}
      workspaceIds={workspaceIds}
      workspaceName={workspace?.name}
    />
  );
});

export default AssigneeItem;
