import { useFormContext } from 'react-hook-form';
import { forwardRef, useRef } from 'react';

import ButtonAdd from '../../../../../../../../../../components_new/ButtonAdd';
import { WowIcon } from '../../../../../../../../../../components_new/WowBasicElements';
import { DateInputWrapper } from '../../styles';
// Lift up
import DatePicker from '../../../../../../../../scheduling/schedule/components/DatePicker/DatePicker';

const StartDateItem = forwardRef((props, ref) => {
  const { disabled, pickerPositionTop, isAdmin, popperPlacement } = props;

  const { watch, setValue } = useFormContext();
  const due_date = watch('due_date');
  const wrapperRef = useRef(null);

  const unsetValue = () => {
    setValue('start_date', null, { shouldDirty: true });
  };

  return props.value ? (
    <DateInputWrapper>
      <WowIcon
        className="icon icon-calendar"
        style={{
          marginLeft: 7,
          position: 'relative',
          bottom: 1,
          left: 1,
        }}
      />
      <DatePicker
        modalTestId="issueStartDateModal"
        {...props}
        maxDate={due_date}
        wrapperRef={wrapperRef}
        customInput={null}
        pickerPositionTop={pickerPositionTop}
        popperPlacement={popperPlacement}
        {...(isAdmin ? { minDate: null } : {})}
      />
      {!disabled && (
        <WowIcon className="icon icon-close" onClick={unsetValue} />
      )}
    </DateInputWrapper>
  ) : (
    !disabled && (
      <ButtonAdd
        data-testid="issueStartDateAddButton"
        onClick={() => {
          const value = Date.now();
          setValue('start_date', value, { shouldDirty: true });
          if (value && due_date && value >= due_date) {
            setValue('due_date', null, { shouldDirty: true });
          }
          window.setTimeout(() => {
            if (wrapperRef.current) {
              const inputElem = wrapperRef.current.querySelector('input');
              if (inputElem) inputElem.click();
            }
          }, 0);
        }}
      />
    )
  );
});

export default StartDateItem;
