import styled from 'styled-components/macro';
import {
  gray,
  white,
  purple500,
  darkerGray,
  newBorder,
} from '../../config/colors';
import { themeColour } from '../../helpers/theme';

export const StyledDropdown = styled.div`
  position: relative;
  ${({ size }) =>
    size === 'responsive'
      ? `
  width: 100%;
  `
      : ''};
`;

export const LabelButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LabelButtonInner = styled.div`
  ${({ limitLabelWidth }) =>
    limitLabelWidth
      ? `overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 40px);
  text-align: left;
  `
      : null}
`;

export const Button = styled.button`
  overflow-wrap: break-word;
  word-break: break-word;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ color }) => (color ? color : darkerGray)};
  background-color: ${({ backgroundColor }) => backgroundColor || white};
  border: 1px solid ${({ color }) => (color ? color : newBorder)};
  border-radius: ${({ borderRadius }) => borderRadius || '6px'};
  font-size: 14px;
  padding: ${({ size }) => (size === 'responsive' ? '8px 12px' : '6px 8px')};
  ${({ size }) =>
    size === 'responsive'
      ? `
  width: 100%;
  position: relative;
  `
      : ''};

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.i`
  color: ${({ color }) => (color ? color : gray)};
  margin-right: 10px;
  font-size: 14px;
`;

export const DropdownIcon = styled.i.attrs({
  className: 'icon icon-arrowdown-02',
})`
  color: ${({ color }) => color ?? purple500};
  margin-left: 10px;
  ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg)' : '')};
  ${({ size }) =>
    size === 'responsive'
      ? `
  right: 10px;
  position: absolute;
  `
      : ''};
`;

export const ClearIcon = styled.i.attrs({
  className: 'icon icon-close',
})`
  color: ${(props) => themeColour(props) ?? purple500};
  margin-left: 10px;
  ${({ size }) =>
    size === 'responsive'
      ? `
  right: 30px;
  position: absolute;
  `
      : ''};
`;

export const NoItems = styled.div`
  padding: 15px 15px 15px 15px;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  margin-top: 25px;
  margin-bottom: 10px;

  div:first-child {
    position: relative;
    width: 20px;
  }
`;

export const MenuWrapper = styled.div`
  position: relative;

  input {
    color: ${darkerGray};

    ::placeholder {
      color: ${gray};
    }
  }

  .infinite-scroll-component li {
    justify-content: start;
    align-items: center;
    word-break: break-all;
  }

  > div:first-child {
    top: 10px;
    width: ${({ width }) => (width ? width : '245px')};

    > div:first-child {
      border-bottom: 1px solid #e6edf4;

      ${({ removeSearch }) => (!removeSearch ? `padding: 5px` : null)};

      > div:first-child {
        border: none;

        > i {
          display: none;
        }
      }
    }
  }
`;

export const LabelWrapper = styled.div`
  width: 100%;
`;
