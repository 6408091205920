import dict from './multilang';

export const issuesFilterKeys = {
  QF_OPTION_ALL_ACTIVE: '',
  QF_OPTION_TODO: 'TO_DO',
  QF_OPTION_DECLINED: 'DECLINED',
  QF_OPTION_ACCEPTED: 'ACCEPTED',
  QF_OPTION_IN_PROGRESS: 'IN_PROGRESS',
  QF_OPTION_IN_REVIEW: 'IN_REVIEW',
  QF_OPTION_COMPLETED: 'COMPLETED',
  QF_OPTION_ARCHIVED: 'archived',
  QF_OPTION_ALL: 'all',
};

export const issuesPriorityFilterKeys = {
  PRIORITY_FILTER_OPTION_ALL: '',
  PRIORITY_FILTER_OPTION_LOW: '2',
  PRIORITY_FILTER_OPTION_NORMAL: '3',
  PRIORITY_FILTER_OPTION_HIGH: '4',
  PRIORITY_FILTER_OPTION_URGENT: '5',
};

export const issuesSortFieldFilterKeys = {
  PRIORITY_SORT_FIELD_OPTION_UPDATED: 'updated',
  PRIORITY_SORT_FIELD_OPTION_COMPLETED: 'completed',
  PRIORITY_SORT_FIELD_OPTION_CREATED: 'created',
  PRIORITY_SORT_FIELD_OPTION_START: 'start_date',
  PRIORITY_SORT_FIELD_OPTION_END: 'due_date',
};

export const issueStatuses = [
  {
    value: issuesFilterKeys.QF_OPTION_ALL_ACTIVE,
    label: {
      id: 'active',
      defaultMessage: dict.active,
    },
  },
  {
    value: issuesFilterKeys.QF_OPTION_TODO,
    label: {
      id: 'to_do',
      defaultMessage: dict.to_do,
    },
    parent: 1,
  },
  {
    value: issuesFilterKeys.QF_OPTION_ACCEPTED,
    label: {
      id: 'accepted',
      defaultMessage: dict.accepted,
    },
    parent: 1,
  },
  {
    value: issuesFilterKeys.QF_OPTION_DECLINED,
    label: {
      id: 'declined',
      defaultMessage: dict.declined,
    },
    parent: 1,
  },
  {
    value: issuesFilterKeys.QF_OPTION_IN_PROGRESS,
    label: {
      id: 'in_progress',
      defaultMessage: dict.in_progress,
    },
    parent: 1,
  },
  {
    value: issuesFilterKeys.QF_OPTION_IN_REVIEW,
    label: {
      id: 'in_review',
      defaultMessage: dict.in_review,
    },
    parent: 1,
  },
  {
    value: issuesFilterKeys.QF_OPTION_COMPLETED,
    label: {
      id: 'completed',
      defaultMessage: dict.completed,
    },
    parent: 1,
  },
  {
    value: issuesFilterKeys.QF_OPTION_ARCHIVED,
    label: {
      id: 'archived',
      defaultMessage: dict.archived,
    },
  },
  {
    value: issuesFilterKeys.QF_OPTION_ALL,
    label: {
      id: 'filter_all',
      defaultMessage: dict.filter_all,
    },
  },
];

export const issuePriorities = [
  {
    value: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_ALL,
    label: {
      id: 'filter_all',
      defaultMessage: dict.filter_all,
    },
  },
  {
    value: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_LOW,
    label: {
      id: 'priority.low',
      defaultMessage: dict['priority.low'],
    },
  },
  {
    value: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_NORMAL,
    label: {
      id: 'priority.normal',
      defaultMessage: dict['priority.normal'],
    },
  },
  {
    value: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_HIGH,
    label: {
      id: 'priority.high',
      defaultMessage: dict['priority.high'],
    },
  },
  {
    value: issuesPriorityFilterKeys.PRIORITY_FILTER_OPTION_URGENT,
    label: {
      id: 'priority.urgent',
      defaultMessage: dict['priority.urgent'],
    },
  },
];

export const issuesSortOptions = [
  {
    value: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_UPDATED,
    label: {
      id: 'updated',
      defaultMessage: dict.updated,
    },
  },
  {
    value: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_COMPLETED,
    label: {
      id: 'completion',
      defaultMessage: dict.completion,
    },
  },
  {
    value: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_CREATED,
    label: {
      id: 'creation',
      defaultMessage: dict.creation,
    },
  },
  {
    value: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_START,
    label: {
      id: 'start_date',
      defaultMessage: dict.start_date,
    },
  },
  {
    value: issuesSortFieldFilterKeys.PRIORITY_SORT_FIELD_OPTION_END,
    label: {
      id: 'due_date',
      defaultMessage: dict.due_date,
    },
  },
];
export const issuesTypeFilterKeys = {
  ISSUE_TYPE_OPTION_ALL: '',
  ISSUE_TYPE_OPTION_ISSUES: 'issue',
  ISSUE_TYPE_OPTION_TASK: 'task',
};
