import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  CollapsibleContent,
  CollapsibleHeader,
  CollapsibleWrapper,
} from './styles';
import { WowColorizeText, WowIcon } from '../../../../../WowBasicElements';
import { useState } from 'react';
import { darkerGray, purple500 } from '../../../../../../config/colors';
import Item from './Item';

const Collapsible = (props) => {
  const {
    labelKey,
    labelPrefixKey,
    children,
    count = 0,
    isDisabled,
    items,
    handleItemChange,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <CollapsibleWrapper>
      <CollapsibleHeader
        onClick={() => {
          if (isDisabled) return;
          setIsVisible((prevState) => !prevState);
        }}
        isDisabled={isDisabled}
      >
        <WowColorizeText color={darkerGray} size="12" fWeight="700">
          {labelPrefixKey ? (
            <>
              <FormattedMessage id={labelPrefixKey} /> {' - '}
            </>
          ) : null}
          <FormattedMessage id={labelKey} />
          <WowColorizeText color={purple500}>{` (${count})`}</WowColorizeText>
        </WowColorizeText>
        {!isDisabled ? (
          <WowIcon
            className={`icon ${
              isVisible ? 'icon-arrowtop-02' : 'icon-arrowdown-02'
            }`}
            spaceRight="0"
            size="18"
            display="flex"
          />
        ) : null}
      </CollapsibleHeader>

      {isVisible ? (
        <CollapsibleContent>
          {items?.length && !children
            ? items.map((item) => (
                <Item
                  key={item.id}
                  item={item}
                  handleItemChange={handleItemChange}
                />
              ))
            : null}

          {children ? children : null}
        </CollapsibleContent>
      ) : null}
    </CollapsibleWrapper>
  );
};

Collapsible.propTypes = {
  labelKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  count: PropTypes.number,
  isDisabled: PropTypes.bool,
  items: PropTypes.array,
  handleItemChange: PropTypes.func,
};

export default Collapsible;
