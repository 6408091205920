import { baseApi } from '../../config/rtk';

export const filtersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuickFilters: builder.query({
      query: () => {
        return `/filters/issue`;
      },
    }),
    saveFilter: builder.mutation({
      query(body) {
        return {
          url: '/filters',
          method: 'POST',
          body,
        };
      },
    }),
    updateFilter: builder.mutation({
      query(payload) {
        const { id, ...body } = payload;
        return {
          url: `/filters/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    deleteFilter: builder.mutation({
      query(id) {
        return {
          url: `/filters/${id}`,
          method: 'DELETE',
        };
      },
    }),
    duplicateFilter: builder.mutation({
      query(id) {
        return {
          url: `/filters/duplicate/${id}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetQuickFiltersQuery,
  useSaveFilterMutation,
  useUpdateFilterMutation,
  useDeleteFilterMutation,
  useDuplicateFilterMutation,
} = filtersApi;
