import React, { forwardRef, useRef, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components/macro';

import { CUSTOM_INPUT_TYPES } from './constants';
import DatePickerTimeInput from '../../../../../../components_new/DatePickerTimeInput';
import {
  borderLightGray,
  darkerGray,
  darkGray,
  gray,
  greenButtonColor,
  purple,
  purple500,
  white,
} from '../../../../../../config/colors';
import { dateFormats } from '../../../../../../config/config';
import de from 'date-fns/locale/de';
import hr from 'date-fns/locale/hr';
import tr from 'date-fns/locale/tr';
import pl from 'date-fns/locale/pl';
import { getDateLocaleForCurrentLanguage } from '../../utils';
import { mainAccent } from '../../../../../../helpers/theme';
registerLocale('de', de);
registerLocale('hr', hr);
registerLocale('tr', tr);
registerLocale('pl', pl);

const DatePicker = forwardRef(
  (
    {
      value,
      onChange,
      inline,
      disableTime,
      onlyDate,
      pickerPositionTop,
      modalTestId,
      unsetValueHandler,
      wrapperRef,
      disableDoneButton: disableDoneButtonOverride,
      customInputType = CUSTOM_INPUT_TYPES.DEFAULT.key,
      ...props
    },
    forwardedRef
  ) => {
    const parsedValue = value ? new Date(Number(value)) : null;

    const [disableDoneButton, setDisableDoneButton] = useState(false);

    let datePickerRef = useRef(null);

    const handleDateChange = (date) => {
      if (date?.toString() === 'Invalid Date') return;

      onChange(date?.getTime());
    };

    const closeDatePicker = () => {
      datePickerRef.setOpen(false);
    };

    const intl = useIntl();

    return (
      <StyleResetWrapper
        disableTime={disableTime}
        pickerPositionTop={pickerPositionTop}
        data-testid={modalTestId}
        ref={wrapperRef}
      >
        <ReactDatePicker
          minDate={new Date()}
          locale={getDateLocaleForCurrentLanguage(intl)}
          showTimeInput={!(inline || disableTime) ? true : false} //refactor
          shouldCloseOnSelect={false}
          selected={parsedValue} // Ensure timestamp is number
          onChange={handleDateChange}
          customInput={CUSTOM_INPUT_TYPES[customInputType].component({
            unsetValueHandler,
          })}
          customTimeInput={
            onlyDate ? (
              <TimeInputPlaceholder />
            ) : (
              <TimeInput setDisableDoneButton={setDisableDoneButton} />
            )
          }
          dateFormat={
            onlyDate
              ? dateFormats.STANDARD_DATE_ONLY_DATE_DATEPICKER
              : disableTime
              ? dateFormats.WORKSPACE_DYNAMIC_FORM_DAY_MONTH_YEAR
              : dateFormats.STANDARD_DATE_DATEPICKER
          }
          timeFormat={dateFormats.STANDARD_TIME_DATEPICKER}
          ref={(ref) => (datePickerRef = ref)}
          type="button"
          inline={inline}
          {...props}
        >
          {!inline && (
            <DoneButton
              disabled={disableDoneButton || disableDoneButtonOverride}
              type="button"
              onClick={closeDatePicker}
            >
              <FormattedMessage id="done" />
            </DoneButton>
          )}
        </ReactDatePicker>
      </StyleResetWrapper>
    );
  }
);

export default DatePicker;

export const DateInput = forwardRef(
  ({ value, onClick, unsetValueHandler }, ref) => (
    <div style={{ position: 'relative' }}>
      <StyledDateInput
        onClick={onClick}
        ref={ref}
        unsetValueHandler={unsetValueHandler}
      >
        <CalendarIcon />
        {value}
      </StyledDateInput>
      {unsetValueHandler && (
        <UnsetButtonWrapper onClick={unsetValueHandler}>
          <i className="icon icon-close"></i>
        </UnsetButtonWrapper>
      )}
    </div>
  )
);

export const DateInputDynamicForm = forwardRef(({ value, onClick }, ref) => (
  <StyledDateInput
    onClick={onClick}
    ref={ref}
    style={{
      fontSize: 16,
      backgroundColor: '#fff',
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 10,
      paddingRight: 5,
    }}
  >
    {value ? (
      value
    ) : (
      <span style={{ color: borderLightGray, fontSize: 14 }}>
        DD / MM / YYYY
      </span>
    )}
    <CalendarIcon style={{ color: purple500, marginLeft: 10, fontSize: 22 }} />
  </StyledDateInput>
));

export const TimeInput = (props) => (
  <DatePickerTimeInput {...props} customInput={StyledTimeInput} />
);

const UnsetButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
`;

export const StyleResetWrapper = styled.div`
  /*  */
  .react-datepicker {
    font-family: 'nunitosans', 'Roboto', sans-serif;
    font-weight: 700;
    border: none;
    font-size: 13px;
    ${({ pickerPositionTop }) =>
      pickerPositionTop ? `top: ${pickerPositionTop}` : ''}
  }

  .react-datepicker__current-month {
    color: ${darkerGray};
    font-size: 15px;
  }

  .react-datepicker__header {
    background: transparent;
    border: none;
  }

  .react-datepicker__day,
  .react-datepicker__day:hover {
    position: relative;
    background: none;
  }

  .react-datepicker__day::before {
    content: '';
  }

  .react-datepicker__day--outside-month {
    color: ${gray};
  }

  .react-datepicker__day--disabled {
    color: ${borderLightGray};
  }

  .react-datepicker__day--today::before {
    content: '';

    position: absolute;
    background-color: #39c0ff;
    height: 4px;
    width: 4px;
    border-radius: 999px;
    bottom: 0px;
    left: 50%;
  }

  .react-datepicker__month-container,
  .react-datepicker,
  .react-datepicker__week {
    width: 100%;
  }

  .react-datepicker__day-names {
    padding-top: 34px;
  }

  .react-datepicker__day-name {
    color: ${darkGray};
  }

  /*  */

  .react-datepicker {
    font-family: 'nunitosans', 'Roboto', sans-serif;
    box-shadow: 0px 2px 10px #3b4a7440;
    border-radius: 8px;
    border: none;
  }

  .react-datepicker-time__caption,
  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__input-time-container,
  .react-datepicker-time__input {
    margin: 0 !important;
  }

  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input {
    width: 72px;
  }

  // Footer with time and done button
  .react-datepicker__input-time-container {
    padding: 0 24px 24px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0;
    font-size: 14px;
    margin-right: 10px;
  }

  .react-datepicker__day {
    color: ${darkGray};
    font-weight: 600;
    z-index: 1;
  }

  .react-datepicker__day-name {
    color: ${gray};
    font-weight: 400;
  }

  .react-datepicker__day:last-of-type,
  .react-datepicker__day-name:last-of-type {
    margin: 0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    color: ${white};
  }

  .react-datepicker__day--selected::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: ${mainAccent};
    border-radius: 9999px;
  }

  .react-datepicker__day--outside-month {
    color: ${gray};
  }

  .react-datepicker__day--disabled {
    color: ${borderLightGray};
  }

  .react-datepicker__month {
    margin: 0;
    padding: ${({ disableTime }) =>
      disableTime ? '18px 24px 72px' : '18px 24px'};
  }

  .react-datepicker__week {
    margin-bottom: 18px;
  }

  .react-datepicker__week:last-of-type {
    margin-bottom: 0;
  }

  // Had to change position of left and right arrow
  // because of additional padding in header
  .react-datepicker__header {
    padding-top: 24px;
  }

  .react-datepicker__navigation {
    top: 26px;
  }

  .react-datepicker__navigation--next {
    right: 20px;
  }

  .react-datepicker__navigation--previous {
    left: 21px;
  }

  .react-datepicker__close-icon::after {
    background: transparent;
    color: ${darkGray};
    font-size: 16px;
  }

  .react-datepicker__day--highlighted-custom-calendar-weekly-datepicker {
    color: #fff;
    background-color: ${purple};
    border-radius: 50%;
    position: relative;
  }

  .react-datepicker__day--highlighted-custom-calendar-weekly-datepicker.react-datepicker__day:hover {
    background-color: ${purple} !important;
  }
`;

export const StyledTimeInput = styled.input`
  text-align: center;
  border-radius: 6px;
  outline: none;
  border: 1px solid ${gray};
  padding: 6px 0;
  color: ${darkGray};
  font-size: 14px;
`;

export const StyledDateInput = styled.button.attrs({ type: 'button' })`
  color: ${darkerGray};
  border: 1px solid ${gray};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 14px;
  height: 30px;
  cursor: pointer;
  ${({ unsetValueHandler }) =>
    unsetValueHandler ? 'padding-right: 35px;' : ''}
`;

const CalendarIcon = styled.i.attrs({ className: 'icon icon-calendar' })`
  font-size: 16px;
`;

export const DoneButton = styled.button`
  position: absolute;
  bottom: 24px;
  right: 24px;
  background-color: ${greenButtonColor};
  color: ${white};
  text-transform: capitalize;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 24px;
  cursor: pointer;
  &:disabled {
    background-color: ${gray};
  }
`;

export const TimeInputPlaceholder = styled.div`
  margin-top: 30px;
`;
