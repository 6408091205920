import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from '../render-if/render-if.component';

import { FormattedMessage, injectIntl } from 'react-intl';
import dict from '../../config/multilang';

import { formatTimeAndDate } from '../../helpers/date.helper';
import {
  CommentItem,
  TimelineItemWrapper,
  TimelineTitle,
  CommentMessage,
  TimelineMessage,
  CommentTitle,
  CommentItemTitleWrapper,
  TimelineItemTitleWrapper,
  CommentTime,
  MessageText,
  CommentedSpan,
  DirectionArrow,
} from './timeline-item.style';
import { LinkifyWrapper } from '../linkify-wrapper/linkify-wrapper.component';
import {
  WowDisplayFlex,
  WowIcon,
  WowTooltipWrapper,
} from '../../components_new/WowBasicElements';
import Tooltip from '../../components_new/Tooltip';
import { white } from '../../config/colors';

export const TimelineItem = (props) => {
  const Item = props.isComment ? CommentItem : TimelineItemWrapper;
  const Title = props.isComment ? CommentTitle : TimelineTitle;
  const Message = props.isComment ? CommentMessage : TimelineMessage;
  const TitleWrapper = props.isComment
    ? CommentItemTitleWrapper
    : TimelineItemTitleWrapper;

  return (
    <Item secondaryAccent url={props.img}>
      <TitleWrapper isUserActivity={props.isUserActivity}>
        <Title>
          {props.firstName} {props.lastName}
          <RenderIf
            if={props.isComment}
            then={() => (
              <CommentedSpan>
                {props.intl.formatMessage({
                  id: 'issue_commented',
                  defaultMessage: dict.issue_commented,
                })}
              </CommentedSpan>
            )}
          />
        </Title>
        <RenderIf
          if={props.isUserActivity}
          then={() => (
            <Title isUserActivity={props.isUserActivity}>{props.title}</Title>
          )}
        />
        <RenderIf
          if={props.isComment}
          then={() => (
            <CommentTime>
              {formatTimeAndDate(props.createdAt, props.locale)}
            </CommentTime>
          )}
        />
      </TitleWrapper>

      {props.gpsDisabled ? (
        <Message
          hasBoxShadow={props.hasBoxShadow}
          wrongLocation={props.wrongLocation}
          gpsDisabled={props.gpsDisabled}
          onClick={() => {}}
        >
          <WowDisplayFlex flex="1">
            <MessageText wrongLocation={props.wrongLocation}>
              {props.message}
            </MessageText>
            {props.gpsDisabled ? (
              <Tooltip
                tooltipContent={
                  <WowTooltipWrapper width="150">
                    <FormattedMessage id="Hover_scan_without_gps_allowance" />
                  </WowTooltipWrapper>
                }
              >
                <WowIcon
                  className="icon-important-round"
                  color={white}
                  size="18"
                  spaceRight="15"
                  spaceLeft="15"
                />
              </Tooltip>
            ) : null}
          </WowDisplayFlex>
        </Message>
      ) : (
        <Message
          hasBoxShadow={props.hasBoxShadow}
          wrongLocation={props.wrongLocation}
          onClick={props.wrongLocation && props.onWrongLocationPress}
        >
          <LinkifyWrapper>
            <MessageText wrongLocation={props.wrongLocation}>
              {props.message}
            </MessageText>
          </LinkifyWrapper>
          {props.wrongLocation ? (
            <DirectionArrow className="icon icon-directions" />
          ) : null}
        </Message>
      )}
    </Item>
  );
};

TimelineItem.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isComment: PropTypes.bool,
  createdAt: PropTypes.string,
  locale: PropTypes.string,
  img: PropTypes.string,
};

TimelineItem.defaultProps = {
  title: '',
  message: '',
  isComment: false,
  locale: 'en',
};

export default injectIntl(TimelineItem);
