import styled from 'styled-components/macro';
import {
  borderLightGray,
  purple500,
  hardWhite,
  filtersAdvancedGray,
  gradientBlue,
  newRed,
  darkerGray,
  white,
  purple,
  newBorder,
} from '../../../../../../config/colors';

import { WowDisplayFlex } from '../../../../../../components_new/WowBasicElements';

export const MenuToggler = styled(WowDisplayFlex)`
  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  height: 38px;
  border-radius: 20px;

  border: 1px solid ${borderLightGray};

  padding: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  padding: 50px 0;
`;

export const AvatarWrapper = styled.div`
  height: ${(props) => props?.$height || '30px'};
  width: ${(props) => props?.$width || '30px'};
`;

export const CurrentOrganization = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: ${darkerGray};

  margin-left: 12px;
  margin-right: 12px;
`;

export const ArrowDown = styled.i.attrs({
  className: 'icon icon-arrowdown-02',
})`
  color: ${purple500};

  background-color: ${hardWhite};

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  cursor: pointer;
  font-size: 14px;
`;

export const Menu = styled.div`
  position: absolute;

  top: 40px;
  right: 0;

  width: 350px;
  border-radius: 10px;
  border: 1px solid ${newBorder};

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 20px #3b4a7433;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px 20px 15px;

  border-bottom: 1px solid ${newBorder};
`;

export const UserInformation = styled.div`
  word-break: break-all;
  text-align: center;
  &.full-name {
    color: ${darkerGray};
    font-size: 21px;
    line-height: 28px;
    font-weight: 600;
    margin-top: 10px;
  }

  &.email {
    color: ${filtersAdvancedGray};
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;

export const ViewProfileButton = styled.button`
  background-color: ${gradientBlue};
  color: white;
  font-size: 14px;
  padding: 2px 14px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  cursor: pointer;

  margin-bottom: 15px;
`;

export const OrganisationsContainer = styled.div`
  padding: 15px 20px 20px 20px;
  ${(props) => (props?.$withoutPadding ? 'padding-top: 0px;' : null)}
`;

export const ActionsButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isAdmin }) => (isAdmin ? 'space-between' : 'flex-end')};

  margin-top: ${({ marginTop }) => (marginTop ? '15px' : '0')};
`;

export const ActionButton = styled.button`
  background-color: transparent;

  cursor: pointer;

  color: ${(props) => props?.$color || gradientBlue};
  font-size: 14px;
  line-height: 19px;

  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`;

export const OrganizationItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 11px;

  border-radius: 5px;

  background-color: ${(props) => (props?.$isCurrent ? purple : hardWhite)};
  color: ${(props) => (props?.$isCurrent ? 'white' : darkerGray)};

  margin-bottom: ${(props) => (props?.$isLast ? '0' : '10px')};

  ${({ $isClickable }) =>
    $isClickable
      ? `&:hover {
    cursor: pointer;
  }`
      : null};
`;

export const OrganizationInfo = styled.div`
  display: grid;
  flex-direction: column;

  .name {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: ${(props) => (props?.$isCurrent ? 'white' : darkerGray)};
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
  }

  .role {
    font-size: 11px;
    line-height: 15px;
    color: ${(props) => (props?.$isCurrent ? 'white' : filtersAdvancedGray)};
  }
`;

export const RightItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .text {
    font-size: 11px;
    line-height: 15px;
    color: ${(props) => (props?.$isCurrent ? 'white' : filtersAdvancedGray)};
    ${({ $isCurrent }) => ($isCurrent ? 'opacity: 0.7;' : null)};
  }
`;

export const UnreadCount = styled.span`
  background-color: ${(props) => props?.$bg || newRed};
  min-width: 18px;
  border-radius: 50%;
  color: ${(props) => props?.$color || white};
  letter-spacing: -0.5px;
  padding: 2px;
  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
