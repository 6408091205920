import vest, { enforce, test } from 'vest';
import { TEXTAREA_MAX_LENGTH } from './constants';

export const formValidation = vest.create((data = {}) => {
  const { goals } = data;

  if (goals?.length) {
    test('goals', `TMP_goals_max_length`, () => {
      enforce(goals).shorterThanOrEquals(TEXTAREA_MAX_LENGTH);
    });
  }
});
