import vest, { test, enforce } from 'vest';
import { FORM_DEFAULT_VALUES } from './constants';

export const getInitFormData = (intl, issue) => {
  if (!issue) {
    return FORM_DEFAULT_VALUES;
  }

  const ISSUE_DUPLICATE_COPY = ` (${intl.formatMessage({
    id: 'copy',
  })})`;

  const { workspace_id, title } = issue;

  return {
    ...FORM_DEFAULT_VALUES,
    workspace_id,
    title: title + ISSUE_DUPLICATE_COPY,
  };
};

export const formValidation = vest.create((data = {}) => {
  const { title } = data;

  test('title', 'Title is mandatory', () => {
    enforce(title).longerThan(2).shorterThan(256);
  });
});
