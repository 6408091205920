import React, { useCallback } from 'react';
import YearlyTimelineRow from '../components/YearlyTimelineRow';

const useList = ({
  currentTime,
  data,
  handleLoadMore,
  updatePosition,
  isLoading,
  hasMore,
}) => {
  // react-virtualized
  // callback used for row render
  const renderRow = useCallback(
    ({ index, key, style }) => {
      return (
        <YearlyTimelineRow
          key={key}
          index={index}
          style={style}
          currentTime={currentTime}
          schedule={data[index]}
        />
      );
    },
    [currentTime, data]
  );
  const onScroll = useCallback(
    ({ scrollTop, clientHeight, scrollHeight }) => {
      const scrollPositionFromBottom = scrollHeight - scrollTop - clientHeight;

      updatePosition({ clientHeight, scrollTop });

      if (isLoading || !hasMore) return;

      if (scrollPositionFromBottom <= 25) {
        handleLoadMore();
      }
    },
    [handleLoadMore, hasMore, isLoading, updatePosition]
  );

  return {
    renderRow,
    onScroll,
  };
};

export default useList;
