import React from 'react';
import { FormattedMessage } from 'react-intl';

import { newRed, gradientBlue } from '../../../../../../../config/colors';

import {
  WowIcon,
  WowBlock,
} from '../../../../../../../components_new/WowBasicElements';

import { ActionButton, ActionsButtons } from '../styles';
import { useDispatch } from 'react-redux';
import { equipmentApi } from '../../../../../Equipment/service';
import { workspaceApi } from '../../../../../workspaces/service';
import { baseApi } from '../../../../../../../config/rtk';
import { schedulesApi } from '../../../../../scheduling/services';
import { assetsApi } from '../../../../../Assets/service';
import { categoryApi } from '../../../../../categories/service';
import { issuesApi } from '../../../../../IssuesV2/service';
import { usersApi } from '../../../../../users/service';

const ActionButtons = ({
  isAdmin,
  actionClick,
  logout,
  marginTop,
  collabFeatureActive,
}) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    //NOTE > due to old LEGACY hooks that are used for inifite scroll we may need to clear some cache here
    //NOTE > we may need to clear cache for some RTK state we want to preserve
    dispatch(equipmentApi.util.resetApiState());
    dispatch(workspaceApi.util.resetApiState());
    dispatch(baseApi.util.resetApiState());
    dispatch(schedulesApi.util.resetApiState());
    dispatch(assetsApi.util.resetApiState());
    dispatch(categoryApi.util.resetApiState());
    dispatch(issuesApi.util.resetApiState());
    dispatch(usersApi.util.resetApiState());

    logout();
  };

  return (
    <ActionsButtons marginTop={marginTop} isAdmin={isAdmin}>
      {isAdmin ? (
        <ActionButton onClick={actionClick} type="button">
          {collabFeatureActive ? (
            <>
              <WowIcon
                className="icon icon-plus"
                color={gradientBlue}
                spaceRight="0"
              />
              <WowBlock>
                <FormattedMessage id="add_new" />{' '}
                <FormattedMessage id="organisation" />
              </WowBlock>
            </>
          ) : (
            <FormattedMessage id="settings" />
          )}
        </ActionButton>
      ) : null}

      <ActionButton onClick={handleLogout} $color={newRed}>
        <FormattedMessage id="log_out" />
      </ActionButton>
    </ActionsButtons>
  );
};

export default ActionButtons;
