import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import Space from '../../../../../../components_new/Space';
import TextArea from '../../../../../../components_new/TextArea';
import {
  WowLabelText,
  WowLink,
} from '../../../../../../components_new/WowBasicElements';
import { CtaWrapper, SectionLeft, SubTitle, Title } from '../../styles';
import useFeatures from './hooks/useFeatures';
import {
  Card,
  CardDescription,
  CardTitle,
  CardsWrapper,
  FooterDivider,
  FooterWrapper,
} from './styles';
import Button from '../../../../../../components_new/Button';
import { purple500, userPillGray } from '../../../../../../config/colors';
import { FORM_DEFAULT_VALUES, TEXTAREA_MAX_LENGTH } from './constants';
import { formValidation } from './utils';
import { WowCheckbox } from '../../../../../../components/wow-checkbox';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';

const Features = () => {
  const intl = useIntl();

  const formMethods = useForm({
    resolver: vestResolver(formValidation),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, register } = formMethods;

  const {
    formatedOptions,
    selected,
    handleChange,
    handleContinue,
    handleSkip,
    isLoading,
    isDisabled,
  } = useFeatures({ formMethods });

  return (
    <SectionLeft maxWidth="770">
      <Title>
        <FormattedMessage id="what_achieve_with_wowflow" />
      </Title>
      <SubTitle>
        <FormattedMessage id="select_goals" />
      </SubTitle>
      <Space height="35" />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleContinue)}>
          <CardsWrapper>
            {formatedOptions.map((item) => {
              const isSelected = selected.includes(item.name);

              return (
                <Card
                  isSelected={isSelected}
                  key={item.id}
                  onClick={() => handleChange(item)}
                >
                  {isSelected ? (
                    <WowCheckbox
                      checked
                      onChange={() => {}}
                      color={purple500}
                      name={`${item.name}Input`}
                    />
                  ) : null}
                  <CardTitle>{item.label}</CardTitle>
                  <CardDescription>{item.description}</CardDescription>
                </Card>
              );
            })}
          </CardsWrapper>
          <Space height="35" />
          <WowLabelText bold>
            <FormattedMessage id="not_seeing_goals" />
          </WowLabelText>
          <TextArea
            {...register('goals')}
            placeholder={intl.formatMessage({ id: 'eg_micromanagement' })}
            maxLength={TEXTAREA_MAX_LENGTH}
            rows={2}
          />
          <FooterDivider />
          <FooterWrapper>
            <CtaWrapper data-testid="ctaWrapper">
              <Button size="small" color="blue" disabled={isDisabled}>
                <FormattedMessage id="continue" />
              </Button>
              {isLoading ? <LoadingSpinner small /> : null}
            </CtaWrapper>
            <Space width="25" />
            <WowLink color={userPillGray} size="13" onClick={handleSkip}>
              <FormattedMessage id="skip" />
            </WowLink>
          </FooterWrapper>
        </form>
      </FormProvider>
    </SectionLeft>
  );
};

export default Features;
