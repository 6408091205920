import { FormattedMessage } from 'react-intl';
import {
  WowDisplayFlex,
  WowIcon,
  WowLink,
} from '../../../../../../components_new/WowBasicElements';
import { LeftSide, RightSide, Wrapper } from './styles';
import useOnboardingBanner from './hooks/useOnboardingBanner';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';

const OnboardingBanner = () => {
  const { handleDismiss, isLoading, handleShowOnboarding } =
    useOnboardingBanner();

  return (
    <Wrapper>
      <LeftSide>
        <WowLink onClick={handleShowOnboarding}>
          <FormattedMessage id="did_not_finish_onboarding" />
        </WowLink>
      </LeftSide>
      <RightSide>
        <WowDisplayFlex onClick={handleDismiss} overflow="visible">
          {isLoading ? <LoadingSpinner small /> : null}
          <FormattedMessage id="dismiss" />
          <WowIcon
            className="icon icon-close"
            size="15"
            display="flex"
            color="white"
            spaceLeft="10"
            spaceRight="0"
          />
        </WowDisplayFlex>
      </RightSide>
    </Wrapper>
  );
};

export default OnboardingBanner;
