import styled from 'styled-components/macro';
import {
  darkerGray,
  gray,
  hardWhite,
  newRed,
  outline,
} from '../../config/colors';

const backgroundColors = {
  default: hardWhite,
  important: newRed,
  info: outline,
};

export const Wrapper = styled.div`
  background-color: ${({ variant }) =>
    variant ? backgroundColors[variant] : backgroundColors.default};
  padding: ${({ paddingV }) => (paddingV ? paddingV + 'px' : '12px')}
    ${({ paddingH }) => (paddingH ? paddingH + 'px' : '16px')};
  color: ${({ color }) => color || darkerGray};
  font-size: 14px;
  border-radius: 6px;
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

export const Icon = styled.i`
  color: ${({ color }) => color ?? gray};
  font-size: ${({ size }) => (size ? `${size}px` : '16px')};
  margin-right: 12px;
`;
