import { useSelector } from 'react-redux';

import { getUser } from '../../../../redux/user/selectors';
import { useMemo } from 'react';

const useAuthorization = (issue) => {
  const loggedInUser = useSelector(getUser);

  const isAdmin = loggedInUser?.role === 'admin';
  const isAssignee = loggedInUser.id === issue?.assignee_id;
  const managerIds =
    issue?.workspace_short_info?.managers_ids ||
    issue?.workspace?.managers_ids ||
    []; // TO DO > do we still need this fallback due to different BE ?
  const isManager = managerIds.some((id) => id === loggedInUser.id);
  const groupManagerIds =
    issue?.workspace_short_info?.group_managers_ids ||
    issue?.workspace?.group_managers_ids ||
    []; // TO DO > do we still need this fallback due to different BE ?
  const isGroupManager = groupManagerIds.some((id) => id === loggedInUser.id);
  const showQuickComplete =
    (isAdmin && issue?.status?.id !== 8) ||
    ((isManager || isGroupManager) && isAssignee && issue?.status?.id !== 8) ||
    (isAssignee && issue?.status?.id === 5);

  const isCollabEnabled = loggedInUser?.client?.settings?.include_collab;

  const canManageUsers = useMemo(() => {
    return (
      isAdmin ||
      ((isManager || isGroupManager) &&
        loggedInUser?.client?.settings?.user_management_permission ===
          'manager_admins')
    );
  }, [
    isAdmin,
    isGroupManager,
    isManager,
    loggedInUser?.client?.settings?.user_management_permission,
  ]);

  const canInviteLimitedCollaboratorsOnly = useMemo(() => {
    return !isAdmin && (isManager || isGroupManager);
  }, [isAdmin, isGroupManager, isManager]);

  return {
    isAdmin,
    isAssignee,
    isManager: isManager || isGroupManager,
    isRegularUser: !isAdmin && !isManager && !isGroupManager,
    showQuickComplete,
    isCollabEnabled,
    canManageUsers,
    canInviteLimitedCollaboratorsOnly,
  };
};

export default useAuthorization;
