import { FormattedMessage } from 'react-intl';
import CustomFormattedMessage from '../../../../../components_new/CustomFormattedMessage';

const ErrorMessage = (props) => {
  const workspaceName = props?.values?.workspace_name;

  if (workspaceName) return <FormattedMessage {...props} />;

  return <CustomFormattedMessage {...props} />;
};

export default ErrorMessage;
