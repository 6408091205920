import AssignIssue from './components/AssignIssue';
import CreateIssue from './components/CreateIssue';
import CreateWorkspace from './components/CreateWorkspace';
import Features from './components/Features';
import Indrustry from './components/Industry';
import MobileTrial from './components/MobileTrial';
import NumberOfEmployees from './components/NumberOfEmployees';
import Welcome from './components/Welcome';

export const STEP_TYPES = {
  welcome: 'WELCOME_STEP',
  numberOfEmployees: 'NUMBER_OF_EMPLOYEES_STEP',
  industry: 'INDUSTRY_STEP',
  features: 'FEATURES_STEP',
  createWorkspace: 'CREATE_WORKSPACE_STEP',
  createIssue: 'CREATE_ISSUE_STEP',
  assignIssue: 'ASSIGN_ISSUE_STEP',
  //this is a custom step to show on mobile divices only
  mobileTrial: 'MOBILE_TRIAL',
};

export const STEPS = {
  [STEP_TYPES.welcome]: (props) => <Welcome {...props} />,
  [STEP_TYPES.numberOfEmployees]: (props) => <NumberOfEmployees {...props} />,
  [STEP_TYPES.industry]: (props) => <Indrustry {...props} />,
  [STEP_TYPES.features]: (props) => <Features {...props} />,
  [STEP_TYPES.createWorkspace]: (props) => <CreateWorkspace {...props} />,
  [STEP_TYPES.createIssue]: (props) => <CreateIssue {...props} />,
  [STEP_TYPES.assignIssue]: (props) => <AssignIssue {...props} />,
  [STEP_TYPES.mobileTrial]: (props) => <MobileTrial {...props} />,
};

export const INPUT_MAX_LENGTH = 255;

export const INPUT_COMMON_CONFIG = {
  enableFocus: false,
  autoComplete: 'off',
  maxLength: INPUT_MAX_LENGTH,
};
