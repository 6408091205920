import Collapsible from '../../../Collapsible';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviewFilter } from '../../../../../../selectors';
import DateRange from '../../../DateRange';
import useDateRange from '../../../../hooks/useDateRange';
import {
  setIsPreviewFilterDirty,
  setPreviewFilter,
} from '../../../../../../slice';

const DateRangeWrapper = (props) => {
  const { value, labelKey } = props;
  const dispatch = useDispatch();
  const previewFilter = useSelector(getPreviewFilter);

  const updateCallback = (params) => {
    dispatch(setPreviewFilter({ ...previewFilter, params }));
    dispatch(setIsPreviewFilterDirty(true));
  };

  const {
    handleStartDateChange,
    handleEndDateChange,
    handleTypesChange,
    handleReset,
    localStartDate,
    localEndDate,
    localDateTypes,
    localDateTypesOptions,
  } = useDateRange({
    value,
    parameters: previewFilter?.params ?? {},
    updateCallback,
  });

  return (
    <Collapsible labelKey={labelKey} count={1}>
      <DateRange
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        localStartDate={localStartDate}
        localEndDate={localEndDate}
        localDateTypes={localDateTypes}
        localDateTypesOptions={localDateTypesOptions}
        handleTypesChange={handleTypesChange}
        handleReset={handleReset}
      />
    </Collapsible>
  );
};

export default DateRangeWrapper;
