import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { usePopper } from 'react-popper';

import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';

import { MenuItem, MenuItems, MenuWithSearch } from '../Menu';
import { Button, DropdownIcon, Icon, StyledDropdown } from './styles';
import { FormattedMessage } from 'react-intl';
import { RenderIf } from '../RenderIf';
import styled from 'styled-components/macro';
import { darkerGray } from '../../config/colors';

const WsTitle = styled.span`
  font-size: 14px;
  color: ${darkerGray};
  display: block;
`;

const SubWsTitle = styled.span`
  color: ${darkerGray};
  font-size: 12px;
  display: block;
`;

const DropdownWorkspace = forwardRef(
  ({ onChange, options, ws, isDisabled }, forwardedRef) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    });

    let { ref, isVisible, setIsVisible } = useVisible(false);
    const [search, setSearch] = useState('');

    useKeyPress('Escape', () => setIsVisible(false));

    const handleClick = () => {
      setIsVisible(!isVisible);
    };

    const handleChange = (e, value) => {
      setIsVisible(false);

      onChange(e, value);
    };

    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    };

    const getSelectedNames = () => {
      let wss = [];
      if (ws.parent_id) {
        const parent = options.find((option) => option.id === ws.parent_id);
        if (parent) {
          wss.push(parent.name);
        }
      }
      wss.push(ws.name);
      return wss;
    };

    const wsNamesRender = () => {
      const names = getSelectedNames();

      return (
        <div>
          <WsTitle>{names[0]}</WsTitle>
          <RenderIf if={names[1]}>
            <SubWsTitle>{names[1]}</SubWsTitle>
          </RenderIf>
        </div>
      );
    };
    useImperativeHandle(forwardedRef, () => ref.current, [ref]);

    return (
      <StyledDropdown ref={ref}>
        <Button
          type="button"
          onClick={handleClick}
          ref={setReferenceElement}
          disabled={isDisabled}
        >
          <Icon className={`icon icon-workspace`} />
          {ws ? wsNamesRender() : <FormattedMessage id="select" />}
          {!isDisabled ? <DropdownIcon /> : null}
        </Button>

        {isVisible ? (
          <MenuWithSearch
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            value={search}
            onChange={handleSearchChange}
          >
            <MenuItems>
              {options
                .filter((ws) => {
                  const { name, reference_number } = ws;

                  return (
                    name.toLowerCase().includes(search.toLowerCase()) ||
                    reference_number?.includes(search.toLowerCase())
                  );
                })
                .map((option) => {
                  const isSelected = ws && option.id === ws.id;
                  return (
                    <MenuItem
                      onClick={(e) => handleChange(e, option)}
                      selected={isSelected}
                      tabIndex={0}
                      key={option.id}
                    >
                      {option.name}
                      {isSelected ? <i className="icon icon-check" /> : null}
                    </MenuItem>
                  );
                })}
            </MenuItems>
          </MenuWithSearch>
        ) : null}
      </StyledDropdown>
    );
  }
);

export default DropdownWorkspace;
