import styled from 'styled-components/macro';

import {
  newBorder,
  darkerGray,
  boxShadow,
} from '../../../../../../../../config/colors';
import { breakpoint } from '../../../../../../../../styles/styledComponents/media-breakpoints';

export const TextAreaWrapper = styled.div`
  padding: 2rem;
  padding-top: 0;
  margin-bottom: 10px;
  color: ${darkerGray};
  word-break: break-word;
  margin-top: 1rem;
`;

export const TextAreaInner = styled.div`
  position: relative;
  ul {
    box-shadow: 0px 2px 10px ${boxShadow};
  }
`;

export const DropAreaWrapper = styled.div`
  margin-top: ${({ isEdit }) => (isEdit ? '1rem' : '1rem')};
`;

export const EquipmentWrapper = styled.div`
  padding: 2rem;
`;

export const SectionHeaderWithBorder = styled.div`
  padding-bottom: 8px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${newBorder};
`;

export const SectionTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: ${darkerGray};
  text-transform: uppercase;
`;

export const LeftTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IssueFormContentLeft = styled.div`
  max-width: 60%;
  flex: 60%;
  position: relative;

  @media only screen and ${breakpoint.sm} {
    max-width: 100%;
  }
`;

export const IssueFormContentBottom = styled.div`
  margin-top: ${({ isEdit }) => (isEdit ? '1rem' : '0')};
  display: flex;
  flex-direction: column;
`;

export const ProcessNumberContainer = styled.div`
  margin: 2.5rem 2rem 0;
`;
