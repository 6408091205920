import { useEffect, useMemo } from 'react';

import { useGetEquipmentGroupsQuery } from '../service';
import { INFINITE_SCROLL_INITIAL_STATE, RTK_HOOK_CONFIG } from '../constants';

import useInfiniteScrollData from '../hooks/useInfiniteScrollData';

const useGroups = (skipQuery, customParams) => {
  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    id: 'groups',
  });

  const params = useMemo(() => {
    return {
      ...scrollState.query,
      ...customParams,
    };
  }, [scrollState?.query, customParams]);

  const { data: fetchedData, isFetching } = useGetEquipmentGroupsQuery(params, {
    ...RTK_HOOK_CONFIG,
    skip: skipQuery || false,
  });

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    handleSearch,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
  };
};

export default useGroups;
