import { VIEW_MODES } from '../../../../../components_new/MediaInput/util';

export const SORT_OPTIONS = [
  {
    value: 'name',
    label: 'name',
  },
  {
    value: 'upload_date',
    label: 'upload_date',
  },
];

export const API_TAG_TYPES_WORKSPACE_DOCUMENTS = [
  'Documents',
  'DocumentCategories',
];

export const INIT_MODAL_STATE = {
  viewMode: VIEW_MODES.closed,
  index: null,
};
