import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Button from '../Button';
import Modal, { SIZE } from '../Modal';
import CustomFormatedMessage from '../CustomFormattedMessage';
import {
  ModalBody,
  ModalFooter,
  InfoWrapper,
  ModalHeading,
} from '../WowModalElements';
import { WowIcon, WowTextCenter, WowJustifyCenter } from '../WowBasicElements';
import Space from '../Space';
import {
  ACTION_BUTTON_COLOR,
  ACTION_BUTTON_APPEREANCE,
  ACTION_BUTTON_SIZE,
} from './constants';

export const ModalInfo = ({
  isOpen,
  handleAction,
  handleClose,
  intlValues,
  icon,
  actionButtonColor = ACTION_BUTTON_COLOR,
  actionButtonappearance = ACTION_BUTTON_APPEREANCE,
  actionButtonSize = ACTION_BUTTON_SIZE,
  disableAction,
  heading,
  children,
  hideCloseIcon = false,
}) => {
  const { messageKey: key, ...values } = intlValues ?? {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size={SIZE.small}
      hideCloseIcon={hideCloseIcon}
    >
      <ModalBody data-testid="modalInfo">
        <WowTextCenter>
          <WowIcon className={`icon ${icon}`} size="54" spaceRight="0" />
          {heading ? (
            <>
              <Space height="20" />
              <ModalHeading data-testid="heading">{heading}</ModalHeading>
            </>
          ) : null}
          {key ? <Space height="15" /> : null}
          <InfoWrapper
            data-testid="message"
            paddingBottom={children && key ? '10px' : null}
          >
            {key ? (
              <CustomFormatedMessage id={key} values={{ ...values }} />
            ) : null}
          </InfoWrapper>
          {children}
        </WowTextCenter>
      </ModalBody>
      <ModalFooter data-testid="modalInfoFooter">
        <WowJustifyCenter>
          <Button
            type="button"
            appearance={actionButtonappearance}
            color={actionButtonColor}
            size={actionButtonSize}
            onClick={handleAction}
            disabled={disableAction}
            data-testid="actionButton"
          >
            <FormattedMessage id="ok" />
          </Button>
        </WowJustifyCenter>
      </ModalFooter>
    </Modal>
  );
};

ModalInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  intlValues: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  actionButtonColor: PropTypes.string,
  actionButtonappearance: PropTypes.string,
  actionButtonSize: PropTypes.string,
  disableAction: PropTypes.bool,
  heading: PropTypes.string,
  children: PropTypes.node,
};

export default ModalInfo;
