import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { getEntity, getPreviewFilter } from '../../../../../selectors';
import {
  setIsPreviewFilterDirty,
  setIsPreviewModalOpen,
  setPreviewFilter,
} from '../../../../../slice';
import { useUpdateFilterMutation } from '../../../../../service';
import { FILTER_COMPONENTS } from '../contants';
import useFilters from '../../../../../hooks/useFilters';
import { isObject } from 'lodash-es';

const usePreviewAction = () => {
  const dispatch = useDispatch();
  const previewFilter = useSelector(getPreviewFilter);
  const entity = useSelector(getEntity);
  const { formatStringFilter } = useFilters({ entity, skip: true });

  const handleMultiselectChange = (persistedFiltersKey, item) => {
    const mutatedParameters = { ...(previewFilter.params ?? {}) };

    const mutatedArray = [...(mutatedParameters[persistedFiltersKey] ?? [])];

    const index = mutatedArray.findIndex(
      (currentItem) => currentItem.id === item.id
    );

    if (index !== -1) {
      mutatedArray.splice(index, 1);
    } else {
      mutatedArray.push(item);
    }

    if (mutatedArray.length === 0) {
      delete mutatedParameters[persistedFiltersKey];
    } else {
      mutatedParameters[persistedFiltersKey] = mutatedArray;
    }

    dispatch(setPreviewFilter({ ...previewFilter, params: mutatedParameters }));
    dispatch(setIsPreviewFilterDirty(true));
  };

  const handleSingleSelectChange = (key, value) => {
    const mutatedParameters = { ...(previewFilter.params ?? {}) };

    if (value === 'all') {
      if (key in mutatedParameters) delete mutatedParameters[key];
    } else {
      mutatedParameters[key] = value;
    }

    dispatch(setPreviewFilter({ ...previewFilter, params: mutatedParameters }));
    dispatch(setIsPreviewFilterDirty(true));
  };

  const handleStaticFilterMultiselectChange = ({
    filterKey,
    value,
    filterValues,
  }) => {
    const currentString = previewFilter.params?.[filterKey] ?? '';

    const allPriorities = Object.values(filterValues);
    const isSelectAll = value === 'all';
    const newString = isSelectAll
      ? allPriorities.toString() !== currentString
        ? allPriorities.toString()
        : null
      : formatStringFilter(currentString, value);

    const mutatedParameters = { ...(previewFilter.params ?? {}) };

    if (newString?.length) {
      mutatedParameters[filterKey] = newString;
    } else {
      if (filterKey in mutatedParameters) delete mutatedParameters[filterKey];
    }

    dispatch(setPreviewFilter({ ...previewFilter, params: mutatedParameters }));
    dispatch(setIsPreviewFilterDirty(true));
  };

  const handleChangeDescription = (description) => {
    const mutatedPreviewFilter = { ...previewFilter, description };
    dispatch(setPreviewFilter(mutatedPreviewFilter));
    dispatch(setIsPreviewFilterDirty(true));
  };

  const [updateFilter, { isLoading }] = useUpdateFilterMutation();

  const formatParams = (parameters, primitiveKeys) => {
    return Object.keys(parameters).reduce((acc, key) => {
      if (key in FILTER_COMPONENTS) {
        if (key === 'issue_state' && isObject(parameters[key])) {
          Object.entries(parameters[key]).forEach(
            ([stateGroup, stateValue]) => {
              acc[`issue_state[${stateGroup}]`] =
                stateValue?.split?.(',') ?? [];
            }
          );
        } else {
          const formattedKey = key.replace('_params', '');
          acc[formattedKey] = Array.isArray(parameters[key])
            ? parameters[key].map((item) => item.id)
            : parameters[key];
        }
      } else if (primitiveKeys.includes(key)) {
        acc[key] = parameters[key];
      }
      return acc;
    }, {});
  };

  const handleSave = async () => {
    const parameters = previewFilter?.params ?? {};
    const primitiveKeys = [
      'search',
      'page',
      'paginate_by',
      'sort_by',
      'sort_direction',
      'date_events_from',
      'date_events_to',
      'date_events_types',
    ];

    const newParameters = formatParams(parameters, primitiveKeys);

    const newQueryString = qs.stringify(newParameters, {
      arrayFormat: 'comma',
    });

    const payload = {
      id: previewFilter.id,
      name: previewFilter.name,
      description: previewFilter.description,
      query_string: newQueryString,
    };

    await updateFilter(payload);

    dispatch(setIsPreviewFilterDirty(false));
    dispatch(setIsPreviewModalOpen(false));
  };

  return {
    handleMultiselectChange,
    handleSingleSelectChange,
    handleStaticFilterMultiselectChange,
    handleChangeDescription,
    handleSave,
    isLoading,
  };
};

export default usePreviewAction;
