import { useIntl } from 'react-intl';
import ModalInfo from '../../../../../components_new/ModalInfo';

const CollaboatorAddedSuccessModal = (props) => {
  const { clientName, setActionInfoModalConfig } = props;

  const intl = useIntl();

  return (
    <ModalInfo
      isOpen={true}
      handleAction={() => setActionInfoModalConfig(null)}
      handleClose={() => {}}
      icon="icon-smiley-emoji"
      intlValues={{
        messageKey: 'success_collaboration_accepted',
        clientName,
      }}
      heading={intl.formatMessage({ id: 'new_collaborator_added' })}
      hideCloseIcon={true}
    />
  );
};

export default CollaboatorAddedSuccessModal;
