import { useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import InfiniteScroll from '../../../../../components_new/InfiniteScroll';
import { WowIcon } from '../../../../../components_new/WowBasicElements';
import {
  filtersAdvancedGray,
  statusColors,
} from '../../../../../config/colors';
import { formatDateToHours } from '../../../../../helpers/date.helper';
import IssueDetailsModal from '../IssueDetailsModal';

import {
  Wrapper,
  DetailsWrapper,
  HeaderWrapper,
  CloseIconWrapper,
  ItemWrapper,
  DateStringWrapper,
  YearStringWrapper,
  LoaderWrapper,
} from './styles';
import {
  DataItemWrapper,
  DataItemStatus,
  DataItemLabel,
} from '../UnscheduledSidebar/styles';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';

const ViewMoreModal = forwardRef((props, ref) => {
  const {
    onClose = () => {},
    leftValue = null,
    topValue = null,
    bottomValue = null,
    items = [],
    handleUpdateDataItem = () => {},
    handleDeleteDataItem = () => {},
    isWeeklyBoxModal,
    dateString,
    monthString,
    yearString,
    startDayTimes,
    rightValue = null,
    isLoading,
    detailsModalPosition,
  } = props;

  const [acitveModal, setActiveModal] = useState(null);
  const galleryPopupPresented = useSelector(
    (state) => state?.app?.toJS()?.galleryPopupPresented
  );

  return (
    <Wrapper
      left={leftValue}
      right={rightValue}
      top={topValue}
      bottom={bottomValue}
      position={galleryPopupPresented ? 'static' : 'absolute'}
      ref={ref}
    >
      <DetailsWrapper>
        <HeaderWrapper>
          {dateString && <DateStringWrapper>{dateString}</DateStringWrapper>}
          {monthString && <DateStringWrapper>{monthString}</DateStringWrapper>}
          {yearString && <YearStringWrapper>{yearString}</YearStringWrapper>}
          <CloseIconWrapper onClick={() => onClose({})}>
            <WowIcon
              className="icon-close"
              color={filtersAdvancedGray}
              size="15"
              spaceRight="0"
              spaceLeft="0"
            />
          </CloseIconWrapper>
        </HeaderWrapper>
        {isLoading ? (
          <LoaderWrapper>
            <LoadingSpinner small />
          </LoaderWrapper>
        ) : (
          <InfiniteScroll
            dataLength={items?.length ?? 0}
            handleLoadMore={() => {}}
            hasMore={false}
            height={250}
          >
            {items.map((item, index) => {
              const data = isWeeklyBoxModal ? item : item?.data ?? {};
              const id = data?.id;

              let timeString = '';
              if (data?.due_date && data?.start_date) {
                timeString = `${formatDateToHours(
                  data?.start_date * 1000
                )} - ${formatDateToHours(data?.due_date * 1000)}`;
              } else if (data?.start_date) {
                timeString = `${formatDateToHours(data?.start_date * 1000)}`;
              } else if (data?.due_date) {
                timeString = `${formatDateToHours(data?.due_date * 1000)}`;
              }

              return (
                <ItemWrapper key={id || index}>
                  <DataItemWrapper onClick={() => setActiveModal(data)}>
                    <DataItemStatus
                      backgroundColor={
                        statusColors[data?.status?.name] ?? '#000'
                      }
                    />
                    <DataItemLabel>
                      {timeString && (
                        <span style={{ fontWeight: 'bold' }}>
                          {timeString}{' '}
                        </span>
                      )}
                      {data?.title}
                    </DataItemLabel>
                  </DataItemWrapper>
                </ItemWrapper>
              );
            })}
          </InfiniteScroll>
        )}
      </DetailsWrapper>
      {acitveModal && (
        <IssueDetailsModal
          id={acitveModal?.id}
          overrideData={{ ...(acitveModal ?? {}) }}
          onClose={() => setActiveModal(null)}
          handleUpdateDataItem={(data) =>
            handleUpdateDataItem(data, {
              updateFromViewMoreModal: true,
              startDayTimes,
            })
          }
          handleDeleteDataItem={handleDeleteDataItem}
          leftValue={detailsModalPosition?.leftValue ?? '200px'}
          bottomValue={detailsModalPosition?.bottomValue ?? '0'}
          rightValue={detailsModalPosition?.rightValue ?? null}
          topValue={detailsModalPosition?.topValue ?? null}
          isScheduledPreparedTask={acitveModal?.is_scheduled_prepared_task}
        />
      )}
    </Wrapper>
  );
});

export default ViewMoreModal;
