import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { purple500 } from '../../../config/colors';
import useKeyPress from '../../../hooks/useKeyPress';
import useVisible from '../../../hooks/useVisible';
import { Menu, MenuItems } from '../../Menu';
import { WowIcon } from '../../WowBasicElements';
import { MenuItem } from '../StatusFilterDropdown';
import { ButtonToggler, Wrapper } from './styles';

const MainDropdown = ({ options, placeholder, position, value, onChange }) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const intl = useIntl();

  useKeyPress('Escape', () => setIsVisible(false));

  const handleToggle = () => {
    setIsVisible((prevState) => !prevState);
  };

  const selected = options.find((option) => option.value === value);

  const handleChange = (value) => {
    setIsVisible(false);
    onChange(value);
  };

  return (
    <Wrapper ref={ref}>
      <ButtonToggler
        onClick={handleToggle}
        isMenuOpen={isVisible}
        data-testid="calendarMainTypeMenuBtn"
      >
        {selected?.translationKey
          ? intl.formatMessage({ id: selected.translationKey })
          : selected?.label ?? placeholder}
        <WowIcon
          className={`icon ${
            isVisible ? 'icon-arrowtop-02' : 'icon-arrowdown-02'
          }`}
          size="20"
          spaceRight="0"
          spaceLeft="5"
          color={purple500}
        />
      </ButtonToggler>
      {isVisible ? (
        <Menu position={position}>
          <MenuItems>
            {options.map((option) => {
              const isSelected = selected?.value === option.value;
              return (
                <MenuItem
                  key={option.id ?? option.value}
                  onClick={() => handleChange(option.value)}
                  selected={isSelected}
                  tabIndex={0}
                  mainAccent
                  data-testid="calendarMainTypeMenuItem"
                >
                  {option?.translationKey
                    ? intl.formatMessage({ id: option.translationKey })
                    : option.label}{' '}
                  {isSelected ? (
                    <WowIcon className="icon icon-check" color={purple500} />
                  ) : null}
                </MenuItem>
              );
            })}
          </MenuItems>
        </Menu>
      ) : null}
    </Wrapper>
  );
};

MainDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  position: PropTypes.string,
  value: PropTypes.string,
};

export default MainDropdown;
