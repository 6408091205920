import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import { getToken } from '../../../redux/utils';
import { BASE_URL } from '../../../config/constants';

export const assetsApi = createApi({
  reducerPath: 'assetsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    'Assets',
    'AssetsGroups',
    'AssetsSystems',
    'AssetManufacturers',
    'AssetModels',
    'AssetActivityLog',
    'AssetSchedules',
    'AssetIssues',
  ],
  endpoints: (builder) => ({
    getAssets: builder.query({
      query: (params) => {
        const { density, isAdvancedFilterOpen, ...restParams } = params;
        const url = qs.stringifyUrl(
          { url: 'asset', query: restParams },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Assets', id })),
              { type: 'Assets', id: 'LIST' },
            ]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    createAsset: builder.mutation({
      query(body) {
        return {
          url: `asset`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }],
    }),
    getAsset: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `asset/${id}`;
        return url;
      },
      providesTags: (result) =>
        result
          ? [{ type: 'Assets', id: result.id }]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    editAsset: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `asset/${id}`,
          method: 'PUT',
          body: restData,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Assets', id }],
    }),
    deleteAsset: builder.mutation({
      query(id) {
        return {
          url: `asset/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }],
    }),
    uploadAssetAttachments: builder.mutation({
      query(data) {
        const { id, formData } = data;
        return {
          url: `asset/${id}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getAsset` will be re-run. `getAssets` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [{ type: 'Assets', id }],
    }),
    removeAssetFromGroup: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `asset/group/${id}/remove`,
          method: 'POST',
          body: restData,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Assets', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    addAssetsToGroup: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `asset/group/${id}/add`,
          method: 'POST',
          body: restData,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    deleteMultipleAssets: builder.mutation({
      query(payload) {
        return {
          url: `asset/multi_delete`,
          method: 'DELETE',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }],
    }),
    archiveMultipleAssets: builder.mutation({
      query(payload) {
        return {
          url: `asset/multi_archive`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }],
    }),
    addRegulatory: builder.mutation({
      query(payload) {
        const { id, formData } = payload;
        return {
          url: `asset/${id}/regulatory`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Assets', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    addRegulatoryMultiselect: builder.mutation({
      query(formData) {
        return {
          url: `asset/regulatory`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    getAssetActivityLog: builder.query({
      query: (payload) => {
        const { id, ...restPayload } = payload;
        const url = qs.stringifyUrl(
          { url: `asset/${id}/activity_log`, query: restPayload },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'AssetActivityLog',
                id,
              })),
              { type: 'AssetActivityLog', id: 'LIST' },
            ]
          : [{ type: 'AssetActivityLog', id: 'LIST' }],
    }),
    getAssetSchedules: builder.query({
      query: (payload) => {
        const { id, ...restPayload } = payload;
        const url = qs.stringifyUrl(
          { url: `asset/${id}/schedules`, query: restPayload },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'AssetSchedules',
                id,
              })),
              { type: 'AssetSchedules', id: 'LIST' },
            ]
          : [{ type: 'AssetSchedules', id: 'LIST' }],
    }),
    getAssetTasks: builder.query({
      query: (payload) => {
        const { id, ...restPayload } = payload;
        const url = qs.stringifyUrl(
          { url: `asset/${id}/tasks`, query: restPayload },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'AssetSchedules',
                id,
              })),
              { type: 'AssetSchedules', id: 'LIST' },
            ]
          : [{ type: 'AssetSchedules', id: 'LIST' }],
    }),
    getAssetIssues: builder.query({
      query: (payload) => {
        const { id, ...restPayload } = payload;
        const url = qs.stringifyUrl(
          { url: `asset/${id}/issues`, query: restPayload },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'AssetIssues',
                id,
              })),
              { type: 'AssetIssues', id: 'LIST' },
            ]
          : [{ type: 'AssetIssues', id: 'LIST' }],
    }),
    checkAssetGroupsWs: builder.mutation({
      query(body) {
        return {
          url: `asset/group/same_ws`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (response) => response.data,
      providesTags: [{ type: 'AssetGroupSameWs', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useLazyGetAssetQuery,
  useLazyGetAssetsQuery,
  useCreateAssetMutation,
  useGetAssetQuery,
  useEditAssetMutation,
  useDeleteAssetMutation,
  useUploadAssetAttachmentsMutation,
  useRemoveAssetFromGroupMutation,
  useAddAssetsToGroupMutation,
  useDeleteMultipleAssetsMutation,
  useArchiveMultipleAssetsMutation,
  useAddRegulatoryMutation,
  useAddRegulatoryMultiselectMutation,
  useGetAssetActivityLogQuery,
  useGetAssetSchedulesQuery,
  useGetAssetTasksQuery,
  useGetAssetIssuesQuery,
  useCheckAssetGroupsWsMutation,
} = assetsApi;
