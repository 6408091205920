import Space from '../../../Space';
import { FormattedMessage, useIntl } from 'react-intl';
import TextAreaInput from '../../../../components_new/TextArea';
import { ButtonWrappers } from '../../styles';
import Button from '../../../Button';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';

export const TextArea = (props) => {
  const {
    value,
    onChange = () => {},
    next = () => {},
    disableNext = false,
    isLast = false,
    isProcessing,
  } = props;

  const t = useIntl();

  return (
    <>
      <Space height="25" />
      <TextAreaInput
        maxLength={3000}
        rows={5}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t.formatMessage({
          id: 'click_here_start_typing',
          defaultMessage: '',
        })}
        value={value}
        displayCharacterCount={true}
      />
      <Space height="40" />
      <ButtonWrappers>
        <Button
          color="green"
          appearance="contained"
          size="small"
          style={{ width: 170 }}
          onClick={next}
          disabled={disableNext || isProcessing}
        >
          {isLast ? (
            <FormattedMessage id={'complete'} />
          ) : (
            <FormattedMessage id={'next'} />
          )}
          {isProcessing && <LoadingSpinner />}
        </Button>
      </ButtonWrappers>
      <Space height="20" />
    </>
  );
};

export default TextArea;
