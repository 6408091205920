import { useState } from 'react';

const useMouseHover = () => {
  const [isHover, setIsHover] = useState();

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);

  return { isHover, onMouseEnter, onMouseLeave };
};

export default useMouseHover;
