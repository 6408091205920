import { FormattedMessage } from 'react-intl';
import Space from '../../Space';
import {
  InfoWrapper,
  IconBox,
  CenterAlignedTextBox,
  SecondaryButton,
  ThankyouMessage,
} from './styles';
import { ButtonWrappers } from '../styles';
import { purple } from '../../../config/colors';
import Button from '../../../components_new/Button';
import { WowIcon } from '../../../components_new/WowBasicElements';

export const Finish = (props) => {
  const { action = () => {} } = props;

  return (
    <>
      <Space height="25" />
      <IconBox>
        <WowIcon className="icon icon-smiley-emoji" size={73} color="#DCE2E8" />
      </IconBox>
      <Space height="20" />
      <CenterAlignedTextBox>
        <ThankyouMessage>
          <FormattedMessage id={'thank_you'} />
        </ThankyouMessage>
      </CenterAlignedTextBox>
      <Space height="13" />
      <InfoWrapper>
        <CenterAlignedTextBox>
          <FormattedMessage id={'survey_thank_you_for_taking_time'} />
        </CenterAlignedTextBox>
      </InfoWrapper>
      <Space height="30" />
      <ButtonWrappers>
        <Button
          color="green"
          appearance="outlined"
          size="small"
          onClick={action}
        >
          <FormattedMessage id={'complete'} />
        </Button>
      </ButtonWrappers>
      <Space height="8" />
      <ButtonWrappers>
        <SecondaryButton
          color={purple}
          onClick={() => (window.location = 'mailto:hello@wowflow.com')}
        >
          <FormattedMessage id={'email_us'} />
        </SecondaryButton>
      </ButtonWrappers>
      <Space height="15" />
    </>
  );
};

export default Finish;
