import { actions, updateUserSettings } from './actions';
import { getToken } from '../auth/selectors';
import { saveData, localStorageKey } from '../utils';
import {
  multipartUserRequest,
  getUser,
  getActivities,
} from '../../services/UserService';
import { config } from '../../services/config';
import { jsonRequest } from '../service/action-creator';
import { NotificationManager } from 'react-notifications';
import { setIsLoadingUser } from '../../views/internal/main/slice';

const DEFAULT_PAGE_SIZE = 10;

const fetchLoggedUser = async (dispatch) => {
  const url = `/${config.API.USER}/me`;

  const user = await dispatch(jsonRequest(config.REQ_TYPES.GET, url, {}));

  return user;
};

const updateLocaleStorage = (user, token) => {
  saveData(
    {
      token,
      user: { ...user },
    },
    localStorageKey
  );
};

export const actionCreator = {
  userMultipartRequest:
    (method, id, data, shouldUpdateState = true) =>
    async (dispatch, getState) => {
      return multipartUserRequest(method, getToken(), id, data)
        .then((resp) => resp.json())
        .then(async (resp) => {
          if (resp.error) {
            throw new Error(resp.error);
          }

          if (shouldUpdateState) {
            const language = resp.user.language || 'en';

            await Promise.all([
              await dispatch(actions.userUpdateSuccess(resp)),
            ]);
            const token = getToken();

            updateLocaleStorage(resp.user, token);
            localStorage.wowFlowLang = language;
          }

          return resp;
        });
    },
  getUser: (id) => (dispatch, getState) => {
    return (
      getUser(id)
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    );
  },

  getActivities:
    (userId, page, activityType, pageSize = DEFAULT_PAGE_SIZE) =>
    (dispatch, getState) => {
      dispatch(actions.userActivityRequest(userId));

      return getActivities(getToken(), userId, page, pageSize, activityType)
        .then((response) => {
          dispatch(actions.userActivitySuccess(response));

          return response;
        })
        .catch((error) => actions.userActivityError(error));
    },

  clearActivities: () => (dispatch) => {
    dispatch(actions.userActivityClear());
  },
};

export const fetchNotificationSettings = () => async (dispatch, getState) => {
  const result = await fetchLoggedUser(dispatch);

  if (typeof result.settings === 'undefined') {
    return;
  }

  dispatch(updateUserSettings(result.settings));

  const token = getToken();

  updateLocaleStorage(result, token);
};

export const assignUserToAllWs =
  ({ role, userId, userAddedToWsLabel, errorMessage }) =>
  async (dispatch) => {
    try {
      const url = `/${config.API.USER}/${userId}/all_ws?role=${role}`;

      const result = await dispatch(jsonRequest(config.REQ_TYPES.PUT, url, {}));

      if (!result.success) {
        NotificationManager.error(errorMessage);
        throw new Error();
      }

      if (result.success) {
        NotificationManager.success(userAddedToWsLabel);
      }
    } catch (error) {
      NotificationManager.error(errorMessage);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

export const getLoggedUserData = () => async (dispatch, getState) => {
  const state = getState();
  dispatch(setIsLoadingUser(true));
  const user = await fetchLoggedUser(dispatch);

  const { language } = user;

  dispatch(setIsLoadingUser());

  if (
    state.user.get('language') !== language ||
    localStorage.wowFlowLang !== language
  ) {
    localStorage.wowFlowLang = language;
  }

  dispatch(actions.updateUserAfterPolling(user));

  const token = getToken();

  updateLocaleStorage(user, token);
};
