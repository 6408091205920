export const API_TAG_TYPES_AUTOCOMPLETE = ['Templates'];

export const INIT_QUERY = {
  page: '1',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
};

export const SUPPORTED_PARAMS = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  page: {
    defaultValue: '1',
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  category_ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'name',
    label: 'name',
  },
];

export const TEXT_MAX_LENGTH = 255;

export const INFINITE_SCROLL_INITIAL_STATE = {
  query: {
    page: '1',
    paginate_by: '10',
  },
  itemsList: [],
  hasMore: true,
  lastPage: null,
};

export const TEMPLATE_FORM_DEFAULT_DATA = {
  category_id: null,
  text: '',
};

export const INITIAL_QUERY_PARAMS = {
  page: '1',
  paginate_by: '20',
  sort_by: 'updated',
  sort_direction: 'asc',
};

export const TEMPLATES_ROUTE_CONFIG = {
  pathname: '/dashboard/templates/autocomplete',
  search: '?page=1&paginate_by=20&search=&sort_by=name&sort_direction=asc',
};

export const EMPTY_SCREEN_SETTINGS = {
  icon: 'icon-dock',
  keys: {
    title: 'no_text_templates',
    text: 'no_text_templates_text',
  },
};
