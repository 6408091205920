import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  getIsPreviewFilterDirty,
  getPreviewFilter,
} from '../../../../selectors';
import Modal from '../../../../../Modal';
import { WowLabelText } from '../../../../../WowBasicElements';
import { FiltersWrapper } from '../../styles';
import usePreview from './hooks/usePreview';
import ModalFooterWrapper from '../../../../../ModalFooterWrapper';
import usePreviewAction from './hooks/usePreviewAction';
import Space from '../../../../../Space';
import TextArea from '../../../../../TextArea';
import { INPUT_MAX_LENGTH } from '../../constants';

const ModalPreview = ({ isOpen }) => {
  const previewFilter = useSelector(getPreviewFilter);
  const isDirty = useSelector(getIsPreviewFilterDirty);

  const { filters, renderFilter, handleClose } = usePreview({ isOpen });

  const { handleChangeDescription, handleSave, isLoading } = usePreviewAction();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={previewFilter?.name || ''}
      maxHeight="750px"
      dataTestId="modalSaveFilters"
      contentStyleOverride={{ overflow: 'visible' }}
    >
      {previewFilter?.description ? (
        <>
          <WowLabelText bold>
            <FormattedMessage id="description" />
          </WowLabelText>
          <TextArea
            onChange={(e) => handleChangeDescription(e.target.value)}
            value={previewFilter?.description ?? ''}
            maxLength={INPUT_MAX_LENGTH}
          />
          <Space height="20" />
        </>
      ) : null}

      <FiltersWrapper>{filters.map(renderFilter)}</FiltersWrapper>

      <Space height="30" />
      <ModalFooterWrapper
        handleAction={handleSave}
        isLoading={isLoading}
        handleCancel={handleClose}
        isActionDisabled={isLoading || !isDirty}
        actionButtonKey="button_save_changes"
      />
    </Modal>
  );
};

export default ModalPreview;
