import { useEffect } from 'react';
import {
  profileApi,
  useLazyUserOftenDetailsQuery,
  useSwitchClientMutation,
} from '../service';
import { useState } from 'react';
import { useCallback } from 'react';
import { getToken, localStorageKey, saveData } from '../../../../redux/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getIsLoadingSwitchClient } from '../selectors';
import { internalRoutePaths } from '../../../../config/config';
import { getUser } from '../../../../redux/user/selectors';
import { actions } from '../../../../redux/user/actions';
import { getNewUser } from '../header/components/ProfileMenu/utils';
import {
  setIsLoadingSwitchClient,
  setNotificationsUnreadCount,
} from '../slice';

const useUserCheck = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedInUser = useSelector(getUser);
  const isLoadingSwitchClient = useSelector(getIsLoadingSwitchClient);
  const [switchClient] = useSwitchClientMutation();

  const [initUserOftenDetails, { data: userOftenDetails }] =
    useLazyUserOftenDetailsQuery();

  useEffect(() => {
    const intervalId = setInterval(() => {
      //NOTE > we replace cache here due to RTK query behavior
      dispatch(
        profileApi.util.updateQueryData(
          'userOftenDetails',
          undefined,
          (draft) => {
            if (draft)
              Object.keys(draft).forEach((key) => {
                delete draft[key];
              });
          }
        )
      );

      initUserOftenDetails();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [initUserOftenDetails, dispatch]);

  const [isVisibleUserChangeInfoModal, setIsVisibleUserChangeInfoModal] =
    useState();

  const saveUser = useCallback(
    (userData) => {
      const token = getToken();

      const localData = {
        token,
        user: {
          ...(userData ? userData : {}),
        },
      };

      localStorage.setItem('wowFlowLang', localData.user?.language);

      dispatch(actions.updateUserAfterPolling(localData.user));

      saveData(localData, localStorageKey);
    },
    [dispatch]
  );

  //#region update bell unread notifications counter
  useEffect(() => {
    if (!userOftenDetails || !Object.keys(userOftenDetails).length) {
      return;
    }
    const notificationsUnreadCount =
      userOftenDetails?.total_unread_notifications;
    dispatch(setNotificationsUnreadCount(notificationsUnreadCount));
  }, [userOftenDetails, dispatch]);
  //#endregion

  useEffect(() => {
    //if organisation is changed we show modal with actions
    if (
      userOftenDetails &&
      Object.keys(userOftenDetails).length &&
      loggedInUser?.client?.id !== userOftenDetails?.client?.id
    ) {
      setIsVisibleUserChangeInfoModal(true);
    } else {
      //NOTE: added due to MOB switch back and forth
      if (loggedInUser?.client?.id === userOftenDetails?.client?.id) {
        setIsVisibleUserChangeInfoModal(false);
      }
    }

    //if language is changed we update user
    if (
      userOftenDetails?.language &&
      loggedInUser?.language !== userOftenDetails?.language
    ) {
      saveUser({ ...loggedInUser, language: userOftenDetails?.language });
    }
  }, [userOftenDetails, loggedInUser, dispatch, saveUser]);

  //update lang

  const handleGetBackToCurrentClient = async () => {
    if (isLoadingSwitchClient) return;

    const response = await switchClient({
      client_id: loggedInUser?.client?.id,
    });

    if (response?.data?.success) {
      saveUser(loggedInUser);
      setIsVisibleUserChangeInfoModal(false);
    }
  };

  const handleSwitchClient = () => {
    setIsVisibleUserChangeInfoModal(false);
    if (!userOftenDetails) return;
    dispatch(setIsLoadingSwitchClient(true));
    dispatch(
      getNewUser(() => {
        history.replace(internalRoutePaths.ISSUES);
        history.go(0);
      })
    );
  };

  return {
    handleGetBackToCurrentClient,
    handleSwitchClient,
    isVisibleUserChangeInfoModal,
  };
};

export default useUserCheck;
