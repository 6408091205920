import styled from 'styled-components/macro';

import {
  outline,
  darkerGray,
  white,
  priorityColors,
  darkGray,
  newBorder,
  newRed,
} from '../../../../../../../../config/colors';
import { breakpoint } from '../../../../../../../../styles/styledComponents/media-breakpoints';

export const IssueFormContentDetails = styled.div`
  .menu {
    width: unset;
    max-width: 300px;
  }

  .menu--position-change {
    position: absolute;
    top: 40px;
    left: 0px;

    ul {
      padding: 10px;
      li {
        padding: 0;
        margin: 0.2rem 0;
        text-transform: unset;
        min-width: 170px;
        border-bottom: unset;
        border-radius: 6px;
        padding: 5px;
        letter-spacing: 0;
        font-size: 14px;
        color: ${darkerGray};

        * {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 85%;
        }

        &.selected {
          background-color: #39c0ff25;
          color: ${priorityColors['priority.low']};
          margin-right: 0;
          width: 100%;
        }
      }
    }
  }
`;

export const IssueFormContentRight = styled.div`
  flex: 40%;
  max-width: 40%;
  border-left: 1px solid ${outline};
  background-color: ${white};
  border-bottom-right-radius: 8px;

  @media only screen and ${breakpoint.sm} {
    max-width: 100%;
    margin-top: 50px;
    border-left: none;
  }
`;

export const DateInputWrapper = styled.div`
  display: flex;
  color: ${darkGray};
  border: 1px solid ${newBorder};
  align-items: center;
  border-radius: 6px;
  & input {
    color: ${darkGray};
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    outline: 0;
    width: 125px;
    border-radius: 6px;
    margin-right: 5px;
  }
`;

export const NotifyPublicReporterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-start;
`;

export const SmallLabel = styled.div`
  font-size: 12px;
  margin-right: 10px;
`;

export const Asterisk = styled.span`
  color: ${newRed};
`;
