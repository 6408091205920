import { FormattedMessage } from 'react-intl';
import Space from '../../../../../../components_new/Space';
import {
  WowLabelText,
  WowLink,
} from '../../../../../../components_new/WowBasicElements';
import {
  CtaWrapper,
  Footer,
  InputWrapper,
  SectionLeft,
  Title,
} from '../../styles';
import { Dropdown } from '../../../../../../components_new/Dropdown';
import Button from '../../../../../../components_new/Button';
import { userPillGray } from '../../../../../../config/colors';
import useNumberOfEmployess from './hooks/useNumberOfEmployees';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';

const NumberOfEmployees = () => {
  const {
    formatedOptions,
    selected,
    isLoading,
    handleChange,
    handleContinue,
    handleSkip,
  } = useNumberOfEmployess();

  return (
    <SectionLeft>
      <Title>
        <FormattedMessage id="how_many_team_members" />
      </Title>
      <InputWrapper>
        <WowLabelText bold>
          <FormattedMessage id="choose_a_number" />
        </WowLabelText>
        <Dropdown
          options={formatedOptions}
          onChange={handleChange}
          borderRadius="4px"
          value={selected}
        />
      </InputWrapper>
      <Space height="30" />
      <Footer>
        <CtaWrapper data-testid="ctaWrapper">
          <Button
            onClick={handleContinue}
            size="small"
            color="blue"
            disabled={!selected || isLoading}
          >
            <FormattedMessage id="continue" />
          </Button>
          {isLoading ? <LoadingSpinner small /> : null}
        </CtaWrapper>
        <Space width="25" />
        <WowLink color={userPillGray} size="13" onClick={handleSkip}>
          <FormattedMessage id="skip" />
        </WowLink>
      </Footer>
    </SectionLeft>
  );
};

export default NumberOfEmployees;
