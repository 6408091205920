import React, { forwardRef, useState } from 'react';
import { usePopper } from 'react-popper';

import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';

import { MenuItem, MenuItems, MenuWithSearch } from '../Menu';
import {
  Button,
  DropdownIcon,
  ClearIcon,
  Icon,
  StyledDropdown,
} from './styles';
import { FormattedMessage } from 'react-intl';

const DropdownWithSearch = forwardRef(
  (
    {
      icon,
      onChange,
      options,
      value,
      disabled,
      customDropdownViewOptionsFilter,
      clearable,
      dropdownTestId,
      itemTestId,
    },
    forwardedRef
  ) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    });

    const { ref, isVisible, setIsVisible } = useVisible(false);
    const [search, setSearch] = useState('');

    useKeyPress('Escape', () => setIsVisible(false));

    const handleClick = () => {
      if (disabled) {
        return;
      }

      setIsVisible(!isVisible);
    };

    const handleChange = (value) => () => {
      setIsVisible(false);

      onChange(value);
    };

    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    };

    const handleClear = (e) => {
      if (disabled) {
        return;
      }

      e.stopPropagation();

      onChange({ value: null });
    };

    const selected = options.find((option) => option.value === value);

    const dropdownOptions = customDropdownViewOptionsFilter
      ? customDropdownViewOptionsFilter(options)
      : options;

    return (
      <StyledDropdown ref={ref}>
        <Button
          type="button"
          onClick={handleClick}
          ref={setReferenceElement}
          data-testid={dropdownTestId}
        >
          <Icon className={`icon ${icon}`} />
          {selected ? selected.label : <FormattedMessage id="select" />}
          {selected && clearable ? (
            <ClearIcon onClick={handleClear} mainAccent />
          ) : null}
          {!disabled ? <DropdownIcon mainAccent /> : null}
        </Button>

        {isVisible ? (
          <MenuWithSearch
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            value={search}
            onChange={handleSearchChange}
          >
            <MenuItems>
              {dropdownOptions
                .filter(({ label, reference_number }) => {
                  // TODO: When dev gets merged remove this hack with reference_number
                  // and use async dropdown isntead
                  return (
                    label.toLowerCase().includes(search.toLowerCase()) ||
                    reference_number?.includes(search.toLowerCase())
                  );
                })
                .map((option) => (
                  <MenuItem
                    onClick={handleChange(option)}
                    selected={selected && option.value === selected.value}
                    tabIndex={0}
                    key={option.value}
                    data-testid={itemTestId}
                    mainAccent
                  >
                    {option.label}
                    {selected && option.value === selected.value ? (
                      <i className="icon icon-check" />
                    ) : null}
                  </MenuItem>
                ))}
            </MenuItems>
          </MenuWithSearch>
        ) : null}
      </StyledDropdown>
    );
  }
);

export default DropdownWithSearch;
