import { addRouteParams } from '../services/EntityServicePaged';

export const selectWorkspace = (workspaces, workspaceId) => {
  if (workspaceId === null && workspaces.length > 0) {
    return workspaces[0];
  }

  return workspaces.find((w) => w.id === workspaceId);
};

export const getEntityLoadObject = (payload) => ({
  entityType: payload.entityType,
  entityId: payload.entityId,
  requestType: payload.reqType,
  isLoading: true,
  time: new Date(),
});

export const getEntityErrorObject = (payload) => ({
  entityType: payload.entityType,
  entityId: payload.entityId,
  requestType: payload.reqType,
  error: payload.error,
  time: new Date(),
});

export const workspaceState = (workspace, editMode, childrenFilter) => ({
  ...workspace,
  editMode,
  userSearch: false,
  childrenFilter,
});

export const filterLoadingObjects = (state, payload) =>
  state.filter(
    (l) =>
      l.entityType !== payload.entityType && l.entityId !== payload.entityId
  );

export const getPagedEntityStructure = () => ({
  current_page: null,
  data: [],
  first_page_url: null,
  from: null,
  last_page: null,
  last_page_url: null,
  next_page_url: null,
  path: null,
  per_page: null,
  prev_page_url: null,
  to: null,
  total: null,
});

export const getResponseData = (data, name) => {
  let temp = data[name];

  if (temp) {
    return temp;
  }

  // Removes plural "s"
  temp = data[name.slice(0, -1)];

  if (temp) {
    return temp;
  }

  return null;
};

export const getCollectionKey = (entityType, parameters) => {
  return addRouteParams('', entityType, parameters);
};

export const getSingleEnityCollectionKey = (entityType, parameters) => {
  const id = parameters && parameters.id ? parameters.id : null;

  return id;
};
