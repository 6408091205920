import React from 'react';
import styled from 'styled-components/macro';

import {
  darkerGray,
  filesGrayTransparent,
  hardWhite,
  outline,
} from '../../../config/colors';
import { RenderIf } from '../../RenderIf';

const DocumentsList = ({
  list,
  onRemove,
  onDocumentClick,
  variant,
  disabled,
  documentsBackgroundColor,
  documentItemMarginBottom,
  documentsFontColor,
  documentMarginBottom,
  attachmentPermissions,
}) => {
  const handleDocumentClick = (document, index) => () => {
    onDocumentClick(document, index);
  };
  return (
    <DocumentsListStyled
      variant={variant}
      documentItemMarginBottom={documentItemMarginBottom}
      documentMarginBottom={documentMarginBottom}
    >
      {list.map((document, index) => {
        const permissionsPassed =
          attachmentPermissions && attachmentPermissions[document?.id]
            ? attachmentPermissions[document.id]
            : null;

        let allowDelete = !disabled;
        if (permissionsPassed) {
          if (permissionsPassed.allowedToDelete) allowDelete = true;
          else allowDelete = false;
        }

        return (
          <Row
            key={document.tempId || document.id}
            variant={variant}
            documentsBackgroundColor={documentsBackgroundColor}
            documentsFontColor={documentsFontColor}
            // used for css manipulations in legacy galery
            className="document-row"
          >
            <Info
              onClick={handleDocumentClick(document, index)}
              variant={variant}
            >
              <Icon
                icon={document.icon?.name}
                color={document.icon?.color}
                variant={variant}
              />
              <span>{document.name}</span>
            </Info>
            <RenderIf if={allowDelete}>
              <PillRemoveButton onClick={onRemove(document)} variant={variant}>
                <i className="icon icon-close" />
              </PillRemoveButton>
            </RenderIf>
          </Row>
        );
      })}
    </DocumentsListStyled>
  );
};

export default DocumentsList;

const Icon = styled.i.attrs(({ icon }) => ({
  className: `icon ${icon}`,
}))`
  font-size: ${({ variant }) => (variant === 'lite' ? '26px' : '28px')};
  color: ${({ color }) => color};
  padding-right: 10px;
`;

const Info = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  ${({ variant }) =>
    variant === 'lite'
      ? `
        flex: 0 1 auto;
        color: ${filesGrayTransparent};
        `
      : undefined}
`;

const Row = styled.div`
  background-color: ${({ variant, documentsBackgroundColor }) =>
    documentsBackgroundColor
      ? documentsBackgroundColor
      : variant === 'lite'
      ? undefined
      : hardWhite};
  display: flex;
  align-items: center;
  justify-content: ${({ variant }) =>
    variant === 'lite' ? 'flex-start' : 'space-between'};
  border-radius: 99999px;
  padding: ${({ variant, documentsBackgroundColor }) =>
    documentsBackgroundColor ? '0px' : variant === 'lite' ? '0px' : '0px 14px'};
  ${({ documentsFontColor }) =>
    documentsFontColor ? `color: ${documentsFontColor};` : null};
`;

const DocumentsListStyled = styled.div`
  margin-bottom: ${({ documentMarginBottom }) =>
    documentMarginBottom || '28px'};

  ${({ variant }) =>
    variant === 'lite' ? 'display: flex; flex-direction: column;' : null};

  > * {
    margin-bottom: ${({ variant, documentItemMarginBottom }) =>
      documentItemMarginBottom
        ? documentItemMarginBottom
        : variant === 'lite'
        ? '2px'
        : '12px'};
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

// TODO: Extract, it is also used in ModalSelect
const PillRemoveButton = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  outline: none;
  color: ${darkerGray};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ variant }) =>
    variant === 'lite'
      ? `background-color: ${outline}; margin-left: 10px; font-size: 12px; width: 20px; height: 20px;`
      : null};

  &:hover,
  &:focus {
    background-color: ${outline};
  }
  width: 24px;
  height: 24px;
`;
