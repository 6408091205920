import PropTypes from 'prop-types';
import { Wrapper, Text } from './styles';
import { WowIcon } from '../../WowBasicElements';
import { outline } from '../../../config/colors';
import Space from '../../Space';

const EmptyTab = ({ icon, text }) => {
  return (
    <Wrapper data-testid="emptyTabWrapper">
      <WowIcon
        className={`icon ${icon}`}
        size="72"
        color={outline}
        spaceRight="0"
        data-testid="icon"
      />
      <Space height="15" />
      <Text>{text}</Text>
    </Wrapper>
  );
};

EmptyTab.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default EmptyTab;
