/* eslint no-unused-vars: 0 */

import {
  authGET,
  authReq,
  apiCallWithFormData,
  jsonAuthReq,
} from './ApiService';
import { config } from './config';
import { entities } from './../config/config';

const prefix = `${config.URL}/api/${config.API_VERSION}`;
const workspaceRoute = `${prefix}/${config.API.WORKSPACES}`;
const issuesRoute = `${prefix}/${config.API.ISSUES}`;
const usersRoute = `${prefix}/${config.API.USERS}`;
const categoriesRoute = `${prefix}/${config.API.CATEGORY}`;
const languageRoute = `${prefix}/public/${config.API.LANGUAGE}`;
const clientRoute = `${prefix}/client`;
const autocompleteRoute = `${prefix}/${config.API.AUTOCOMPLETE}`;

const appendId = (rootRoute, entityId) =>
  !entityId || isNaN(Number(entityId)) ? rootRoute : `${rootRoute}/${entityId}`;
const appendStr = (rootRoute, str) =>
  !str || str === '' ? rootRoute : `${rootRoute}/${str}`;
const appendSearch = (rootRoute, searchTerm) =>
  !searchTerm || searchTerm === ''
    ? rootRoute
    : `${rootRoute}?search=${searchTerm}`;

export const getRequestRoute = (entityType, entityId, searchTerm) => {
  switch (entityType) {
    case entities.WORKSPACE.name:
      if (searchTerm) {
        return appendSearch(workspaceRoute, searchTerm);
      } else {
        return appendId(workspaceRoute, entityId);
      }
    case entities.ISSUE.name:
      if (searchTerm) {
        return appendSearch(issuesRoute, searchTerm);
      } else {
        return appendId(issuesRoute, entityId);
      }
    case entities.USERS.name:
      return appendId(usersRoute, entityId);
    case entities.CATEGORY.name:
      return appendId(categoriesRoute, entityId);
    case entities.LANGUAGE.name:
      return appendStr(languageRoute, entityId);
    case entities.CLIENT.name:
      return appendId(clientRoute, entityId);
    case entities.TEMPLATE.name:
      return appendId(autocompleteRoute, entityId);
    case entities.WORKSPACE_FORM.name:
      return `${appendId(workspaceRoute, entityId)}/form`;
    default:
      throw new Error('Entity does not exist');
  }
};

const getRequestContentType = (entityType, requestType) => {
  if (
    entityType === entities.ISSUE.name &&
    requestType === config.REQ_TYPES.POST
  ) {
    return config.CONTENT_TYPES.MULTIPART;
  }

  return config.CONTENT_TYPES.APPLICATION_JSON;
};

export const getEntity = (token, entityType, entityId) => {
  return authGET(
    getRequestRoute(entityType, entityId),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const searchEntity = (token, entityType, searchTerm) => {
  return authGET(
    getRequestRoute(entityType, undefined, searchTerm),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const entityRequest = (
  token,
  entityType,
  entityId,
  requestType,
  data
) => {
  return authReq(
    getRequestRoute(entityType, entityId),
    requestType,
    getRequestContentType(entityType, requestType),
    token,
    data
  );
};

export const commentRequest = (token, issueId, requestType, data) => {
  return jsonAuthReq(
    `${prefix}/${config.API.ISSUES}/${issueId}/${config.API.COMMENT}`,
    requestType,
    config.CONTENT_TYPES.APPLICATION_JSON,
    token,
    data
  );
};

export const galleryCommentRequest = (token, issueId, requestType, data) => {
  return jsonAuthReq(
    `${prefix}/${config.API.ISSUES}/${issueId}/${config.API.ATTACHMENT_COMMENT}`,
    requestType,
    config.CONTENT_TYPES.APPLICATION_JSON,
    token,
    data
  );
};

export const multipartEntityRequest = (
  method,
  token,
  entityType,
  entityId,
  data
) => {
  return apiCallWithFormData(
    method,
    getRequestRoute(entityType, entityId),
    data,
    token,
    config.CONTENT_TYPES.MULTIPART
  );
};

export const categoryRequest = async (method, token, data, id) => {
  return authReq(
    getRequestRoute(entities.CATEGORY.name, id),
    method,
    config.CONTENT_TYPES.APPLICATION_JSON,
    token,
    data
  );
};

export const clientRequest = (method, token, data, id) => {
  return authReq(
    getRequestRoute(entities.CLIENT.name, id),
    method,
    config.CONTENT_TYPES.APPLICATION_JSON,
    token,
    data
  );
};
