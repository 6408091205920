import { SUPPORTED_TYPES } from './constants';

const _isValidType = (value) => {
  return (
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'boolean'
  );
};

const _isNoneEmptyPrimitiveArray = (value) => {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((item) => _isValidType(item))
  );
};

const validateValuesType = (values) => {
  const isValid = Object.values(values).every(
    (value) => _isValidType || _isNoneEmptyPrimitiveArray(value)
  );

  if (!isValid)
    throw new Error(
      `Unsupported type. Use one of the following [${SUPPORTED_TYPES.map(
        (item) => item.name
      ).join(', ')}]`
    );
};

const validateAllowedParams = (query, supportedParams) => {
  const onlyAllowedQueryParams = {};

  Object.keys(query)
    .filter((key) => supportedParams[key])
    .map((key) => {
      const allowedValue = supportedParams[key]?.allowedValues
        ? supportedParams[key].allowedValues.includes(query[key]?.toString())
        : true;

      return (onlyAllowedQueryParams[key] = allowedValue
        ? query[key]
        : supportedParams[key].defaultValue);
    });

  return onlyAllowedQueryParams;
};

export { validateValuesType, validateAllowedParams };
