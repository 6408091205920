export const actionStrings = {
  LOAD_CONFIG_SUCCESS: 'LOAD_CONFIG_SUCCESS',
  LOAD_CONFIG_ERROR: 'LOAD_CONFIG_ERROR',
  LOAD_COLOURS: 'LOAD_COLOURS',
  UPDATE_PERSISTED_FILTERS: 'UPDATE_PERSISTED_FILTERS',
  ADD_TO_PERSISTED_FILTERS: 'ADD_TO_PERSISTED_FILTERS',
  UPDATE_ONBOARDING_PROGRESS_SUCCESS: 'UPDATE_ONBOARDING_PROGRESS_SUCCESS',
};

export const loadConfigSuccess = (data) => ({
  type: actionStrings.LOAD_CONFIG_SUCCESS,
  payload: data,
});

export const loadColours = (data) => ({
  type: actionStrings.LOAD_COLOURS,
  payload: data,
});

export const updatePersistedFilters = (data, targetKey) => ({
  type: actionStrings.UPDATE_PERSISTED_FILTERS,
  payload: {
    data,
    targetKey,
  },
});

export const addToPersistedFilters = (data, targetKey) => ({
  type: actionStrings.ADD_TO_PERSISTED_FILTERS,
  payload: {
    data,
    targetKey,
  },
});

export const updateOnboardingProgress = (data) => ({
  type: actionStrings.UPDATE_ONBOARDING_PROGRESS_SUCCESS,
  payload: data,
});
