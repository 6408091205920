import styled from 'styled-components/macro';
import { themeColour } from '../../helpers/theme';
import {
  gray,
  offWhite,
  white,
  outline,
  boxShadow,
  redButtonColor,
} from '../../config/colors';
import { breakpoint } from '../../styles/styledComponents/media-breakpoints';

/* eslint-disable */
export const TimelineItemWrapper = styled.li`
  position: relative;
  margin-bottom: 0;


  &::before {
    width: 15px;
    height: 15px;
    margin-left: -15px;
    content: "";
    display: inline-block;
    background-color: white;
    border: 3px solid ${themeColour};
    border-radius: 100%;

    box-shadow: 0 0 0.1px 0 rgba(0, 0, 0, 0.2), inset 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.5);
  }
}
`;
/* eslint-enable */
export const TimelineItemTitleWrapper = styled.div`
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  width: 100%;

  ${({ isUserActivity }) =>
    !isUserActivity &&
    `
    justify-content: space-between;
  `}

  time {
    color: ${gray};
    font-size: 12px;
  }
`;
export const TimelineTitle = styled.h4`
  display: inline;
  margin: 0;

  ${({ isUserActivity }) =>
    !isUserActivity &&
    `
    margin-left: 1rem;
  `}

  &::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 1rem;
    border-left: 1px solid ${outline};
    height: 130%;
    width: 1px;

    @media only screen and ${breakpoint.xs} {
      left: -10px;
    }
  }
`;
export const TimelineMessage = styled.div`
  margin-left: 1rem;
  border-radius: 6px;
  opacity: 0.73;
  width: 100%;
  min-height: 40px;
  margin-top: 0.5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 14px;

  ${({ hasBoxShadow }) =>
    hasBoxShadow &&
    `
    box-shadow: 0 2px 10px 0 ${boxShadow};
  `}

  background-color: ${({ wrongLocation, hasBoxShadow }) =>
    wrongLocation ? redButtonColor : hasBoxShadow ? offWhite : white};

  ${({ wrongLocation, gpsDisabled }) =>
    wrongLocation &&
    !gpsDisabled &&
    `
    cursor:pointer;
    `}

  @media only screen and ${breakpoint.xs} {
    margin-left: 0;
  }
`;
export const MessageText = styled.p`
  color: ${({ wrongLocation }) => (wrongLocation ? white : '#456279')};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-left: 1rem;
  padding-right: 0.5rem;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const CommentItem = styled(TimelineItemWrapper)`
  margin-right: 2rem;
  margin-left: 1.5rem;

  &::before {
    width: 40px;
    height: 40px;
    border: unset;
    box-shadow: unset;
    margin-left: -28px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 15px;
    background-image: url(${({ url }) => url});
    z-index: 10;
  }

  @media only screen and ${breakpoint.xs} {
    margin-left: 0;
    margin-right: 1rem;

    &::before {
      width: 35px;
      height: 35px;
    }
  }
`;
export const CommentItemTitleWrapper = styled(TimelineItemTitleWrapper)`
  width: 95%;
`;
export const CommentMessage = styled(TimelineMessage)`
  position: relative;
  top: 0;
  left: 10px;
  width: 95%;
`;
export const CommentTitle = styled(TimelineTitle)`
  height: 100%;
  top: 55px;

  @media only screen and ${breakpoint.xs} {
    font-size: 12px;
  }
`;

export const CommentedSpan = styled.span`
  height: 100%;
  top: 55px;
  margin-left: 4px;
`;
export const CommentTime = styled.span`
  color: ${gray};
  font-size: 12px;
`;

export const DirectionArrow = styled.i`
  color: white;
  margin-right: 15px;
`;
