import { useIntl } from 'react-intl';
import { useMemo, forwardRef } from 'react';

import { getPrioritiesList, getOptionsWithIntl } from '../../../../../../utils';
import { colorHelper } from '../../../../../../../../../../config/color-helper';

import { Dropdown } from '../../../../../../../../../../components_new/Dropdown';

const PriorityItem = forwardRef((props, ref) => {
  const intl = useIntl();
  const priorities = useMemo(() => getPrioritiesList(), []);
  const option = priorities.find((option) => option.value === props.value);

  return (
    <Dropdown
      {...props}
      options={getOptionsWithIntl(intl, priorities)}
      color={colorHelper[option?.key]}
      icon="icon-priority"
      onChange={(value) => props.onChange(value.value)}
      dropdownTestId="issuePriorityDropdown"
      itemTestId="issuePriorityItem"
    />
  );
});

export default PriorityItem;
