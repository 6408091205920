import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import dict from '../../config/multilang';

import { AvatarCircle } from '../avatar-circle/avatar-circle.component';
import { Input } from '../form/input/input';

import './new-comment.component.scss';

const defaultText = {
  altText: (name) => `${name} profile image`,
  name: 'comment',
};
class NewCommentBase extends Component {
  handleChange(e) {
    const { value, name } = e.target;

    const newState = {
      [name]: value,
    };

    // TODO: validate form inputs
    const formValid = true;
    const formErrorMessages = {};

    this.setState({
      ...newState,
      ...formValid,
      formErrorMessages,
    });
  }

  onClick = () => {
    if (
      this.state[defaultText.name] &&
      this.state[defaultText.name].length > 0
    ) {
      this.props.onSubmit(this.state[defaultText.name].trim());
      // clear input form on submit
      this.setState({
        ...this.state,
        comment: '',
      });
    }
  };

  render() {
    return (
      <div
        className={`new-comment${this.props.inGallery ? ' in-gallery' : ''}`}
      >
        <AvatarCircle
          image={{
            img: this.props.user?.profile_image,
            alt: defaultText.altText(this.props.user?.full_name),
          }}
        />
        <Input
          name={defaultText.name}
          placeholder={this.props.intl.formatMessage({
            id: 'write_a_comment',
            defaultMessage: dict.write_a_comment,
          })}
          noBorder={true}
          handleChange={this.handleChange.bind(this)}
          displaySendButton={
            !!this.state &&
            !!this.state[defaultText.name] &&
            !!this.state[defaultText.name].length > 0
          }
          newCommentOnClick={this.onClick}
          enterToSubmit={true}
          value={this.state && this.state.comment ? this.state.comment : ''}
          enableAutocomplete={true}
        />
      </div>
    );
  }

  static propTypes = {
    user: PropTypes.shape({
      full_name: PropTypes.string,
      profile_image: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onIssueEdit: PropTypes.func,
  };
}

export const NewComment = injectIntl(NewCommentBase);
