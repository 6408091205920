import { FormattedMessage } from 'react-intl';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';
import Button from '../../../../../../components_new/Button';
import Space from '../../../../../../components_new/Space';
import {
  ModalFooter,
  ModalFooterButtonWrapper,
} from '../../../../../../components_new/WowModalElements';

const Footer = ({ canSubmit, isLoading, handleClose }) => {
  return (
    <ModalFooter>
      <ModalFooterButtonWrapper>
        <Button
          type="submit"
          color="green"
          appearance="contained"
          size="small"
          disabled={!canSubmit || isLoading}
          data-testid="submitButton"
        >
          <FormattedMessage id="duplicate_issue" />
        </Button>
        {isLoading ? <LoadingSpinner size="small" /> : null}
      </ModalFooterButtonWrapper>
      <Space width="20" />
      <ModalFooterButtonWrapper>
        <Button
          type="button"
          onClick={handleClose}
          color="red"
          appearance="text"
          size="small"
          data-testid="cancelButton"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ModalFooterButtonWrapper>
    </ModalFooter>
  );
};

export default Footer;
