import { useMemo } from 'react';

import { getCurrentTimeMarkerPosition } from '../../../DailyTimeline/utils';
import { HoursBorder } from './styles';

const HoursBorderSection = ({
  timelineHours,
  eachHourHeightPxBox,
  shouldShowMarkerTime,
}) => {
  const hoursBorderSections = useMemo(() => {
    const elems = [];
    for (let i = 0; i < timelineHours; i++) {
      elems.push(
        <HoursBorder
          height={i === timelineHours ? 'auto' : eachHourHeightPxBox}
          currentHourMarkerTopValue={
            shouldShowMarkerTime
              ? getCurrentTimeMarkerPosition({
                  eachHourHeightPxBox,
                })
              : 0
          }
        />
      );
    }
    return elems;
  }, [timelineHours, eachHourHeightPxBox, shouldShowMarkerTime]);

  return hoursBorderSections;
};

export default HoursBorderSection;
