import styled from 'styled-components/macro';
import { IssueDetailsLabel } from '../../../../components_new/WowDetailsPage';
import { newRed, newBorder, darkerGray, gray } from '../../../../config/colors';
import { ItemPreviewTitle } from '../../../../components/wowlist/ItemPreview';

export const Notices = styled.div`
  padding-bottom: 30px;

  > * {
    margin-bottom: 12px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export const NoticeContent = styled.div`
  ${({ textColor }) => (textColor ? `color: ${textColor}` : null)};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NoticeText = styled.div`
  font-weight: bold;
  display: flex;
`;

export const NoticeControllsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SmallTextNotice = styled.span`
  margin: 0 4px;
  font-weight: normal;
`;

export const NoticeButton = styled.button.attrs({ type: 'button' })`
  color: ${({ color }) => color ?? newRed};
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

export const DatePickerWrapper = styled.div`
  background: white;
  border-radius: 6px;
  margin: -4px 6px;

  position: relative;
  overflow: visible;

  .icon {
    position: absolute;
    left: 5px;
    z-index: 5;
  }

  input {
    color: ${darkerGray};
    border: 1px solid ${gray};
    border-radius: 6px;
    height: 30px;
    font-size: 14px;
    text-indent: 25px;
    width: auto;
    display: inline-flex;
    max-width: ${({ width }) => (width ? width + 'px' : '120px')};

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
`;

export const SectionDivider = styled.div`
  padding-bottom: 8px;
  margin-bottom: 24px;
  border-bottom: 1px solid ${newBorder};
`;

export const SectionTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: ${darkerGray};
  text-transform: uppercase;
  padding-top: 12px;
`;

export const IssueDetailsModalLabel = styled(IssueDetailsLabel)`
  align-self: flex-start;
  margin-top: 6px;
`;

export const DropdownText = styled.span`
  text-transform: uppercase;
`;

export const ActionButtonsWrapper = styled.div`
  margin-right: 16px;
`;

export const IncludeInTitle = styled.span`
  font-size: ${({ size }) => (size ? size + 'px' : '24px')};
`;

// TODO: refactor this in new component
// and remove margin-left
export const IconWithTooltip = styled.i.attrs({
  className: 'icon icon-important-round',
})`
  color: ${gray};
  margin-left: 12px;
`;

export const TitlePreview = styled.div`
  background-color: #edebfe;
  border-radius: 5px;
  font-size: 14px;
  color: ${darkerGray} !important;
  padding: 1px 5px;
  margin-top: 5px;

  span {
    font-weight: bold;
  }
`;

export const AddItemsButtonWrapper = styled.div`
  position: relative;
  padding-right: 15px;
`;

export const AssetsWithDifferentWorkspacesInfo = styled.div`
  display: flex;
  word-break: break-all;
  color: ${darkerGray};
  font-size: 14px;
  line-height: 1;
`;

export const TitlePlaceholder = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  color: ${darkerGray};
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    cursor: text;
  }
`;

export const StaticTitleWrapper = styled.div`
  display: grid;
`;

export const StyledItemPreviewTitle = styled(ItemPreviewTitle)`
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
