import * as React from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import {
  gradientBlue,
  gray,
  greenButtonColor,
  white,
  darkerGray,
  purple500,
} from '../../config/colors';
import { mainAccent, secondaryAccent } from '../../helpers/theme';

export default forwardRef(
  (
    {
      children,
      appearance = APPEARANCE.text,
      color = COLOR.secondaryAccent,
      translationKey = 'add_new',
      icon = 'icon-plus',
      ...props
    },
    forwardedRef
  ) => {
    return (
      <StyledButton
        appearance={appearance}
        color={color}
        hasIcon={icon}
        ref={forwardedRef}
        {...props}
      >
        {icon ? <Icon icon={icon} /> : null}
        {children ?? <FormattedMessage id={translationKey} />}
      </StyledButton>
    );
  }
);

const Icon = styled.i.attrs(({ icon }) => ({ className: `icon ${icon}` }))`
  height: 10px;
  width: 10px;
  font-size: 11px;
  padding-left: 2px;
  margin-right: 7px;
`;

export const StyledButton = styled.button.attrs({ type: 'button' })`
  font-family: 'nunitosans', 'Roboto', sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-weight: normal;
  outline: none;
  white-space: nowrap;

  ${({ disabled }) => (disabled ? 'pointer-events: none' : null)};

  ${({ appearance }) =>
    appearance === APPEARANCE.text ? textButtonStyles : containedButtonStyles}

  display: flex;
  align-items: center;
  &:disabled {
    background: ${gray};
    color: ${white};
  }
`;

const APPEARANCE = {
  text: 'text',
  contained: 'contained',
};

const COLOR = {
  blue: 'blue',
  green: 'green',
  gray: 'gray',
  white: 'white',
  purple: 'purple',
  mainAccent: 'mainAccent',
  secondaryAccent: 'secondaryAccent',
};

const COLOR_VALUES = {
  green: greenButtonColor,
  gray: darkerGray,
  blue: gradientBlue,
  white: white,
  purple: purple500,
  mainAccent: mainAccent,
  secondaryAccent: secondaryAccent,
};

const textButtonStyles = css`
  display: block;
  background: none;
  color: ${({ color }) => COLOR_VALUES[color]};
`;

const containedButtonStyles = css`
  color: white;
  background-color: ${({ color }) => COLOR_VALUES[color]};

  border-radius: 9999px;
  padding: 5px 14px 5px ${({ hasIcon }) => (hasIcon ? '8px' : '14px')};
  height: ${({ hasicon }) => (hasicon ? 'auto' : '22px')};
  line-height: 0px;
`;
