import styled from 'styled-components/macro';
import { WowDisplayFlex } from '../../../../../../components_new/WowBasicElements';
import { breakPoints } from '../../../../../../config/config';

export const NameWrapper = styled(WowDisplayFlex)`
  align-items: flex-start;
  margin-top: 35px;

  input {
    margin-bottom: 25px;
  }

  @media (max-width: ${breakPoints.tabletWidth}px) {
    flex-direction: column;
    margin-top: 25px;

    input {
      margin-bottom: 20px;
    }
  }
`;
