import styled from 'styled-components/macro';
import {
  newBorder,
  white,
  darkerGray,
  gradientBlue,
  lighterBlue,
} from '../../config/colors';

export const Wrapper = styled.form`
  background-color: ${white};
  margin: 36px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(55, 70, 95, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 400px;
  /* grid-template-rows: auto 1.8fr 0.2fr; */
  grid-template-areas:
    'top top top top'
    'top1 top1 top1 top1'
    'left left left right';

  ${({ isIssueSubtask }) =>
    isIssueSubtask
      ? `
    margin-top: 50px;
    position: relative;
    &:after {
      border-radius: 8px;
      display: block;
      content: '';
      width: 100%;
      position: absolute;
      top: -15px;
      left: 0;
      height: 100px;
      background-color: ${white};
      box-shadow: 0 2px 10px 0 rgba(55, 70, 95, 0.1);
      transform: scale(0.95);
      z-index: -10;
  `
      : ''}
`;

export const Top = styled.div`
  grid-area: top;
  margin-left: 36px;
  padding-top: 24px;
  padding-bottom: 12px;
  padding-right: 36px;

  ${({ noBorder }) =>
    !noBorder ? `border-bottom: 1px solid ${newBorder}` : null};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Left = styled.div`
  grid-area: left;
  border-right: 1px solid ${newBorder};
  padding: 13px 36px 0 36px;
`;

export const Right = styled.div`
  grid-area: right;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 24px;
`;

export const ParentFeature = styled.button`
  all: unset;
  cursor: pointer;
  padding-bottom: 8px;
`;

export const ParentFeatureIcon = styled.i`
  font-size: 21px;
  color: ${gradientBlue};
  padding-right: 4px;
`;

export const ParentFeatureText = styled.span`
  color: ${darkerGray};
`;

export const FeatureId = styled.span`
  color: ${lighterBlue};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '3px')};
  font-size: 12px;
  font-weight: 600;
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const FeatureDetailsRow = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'flex-start'};
  padding: ${({ spaceY }) => (spaceY ? spaceY + 'px' : '14px')}
    ${({ spaceX }) => (spaceX ? spaceX + 'px' : '26px')};

  ${({ noBorder }) =>
    !noBorder
      ? `
      border-bottom: 1px solid ${newBorder}
      `
      : ''};

  ${({ noBottomSpace }) =>
    noBottomSpace
      ? `
        padding-bottom: 0
        `
      : ''};

  color: ${darkerGray};

  ${({ fullWidthChild }) => {
    return fullWidthChild
      ? `
          > div {
            width: 100%;
          }
        `
      : null;
  }}
`;

export const IssueDetailsLabel = styled.span`
  font-size: 16px;
  width: ${({ width }) => width || '140px'};
  word-break: break-word;
`;

export const FeatureDetailsAction = styled.span`
  max-width: ${({ maxWidth }) => maxWidth || '200px'};

  ${({ responsive }) =>
    responsive
      ? `@media (min-width: 1300px) {
          max-width: calc(100% - 140px);
        }`
      : ''}
`;

export const MenuWrapper = styled.div`
  height: 100%;
  width: 250px;
  position: absolute;
  right: 0px;
`;

export const ReferenceNumberWrapper = styled.label`
  display: flex;
  align-items: center;
  input {
    font-weight: 600;
  }
`;

export const ReferenceNumberText = styled.span`
  white-space: nowrap;
  margin-right: 2px;
  color: ${darkerGray};
  font-size: 16px;
  font-weight: 600;
`;
