/* eslint-disable no-unused-vars */
import { NotificationManager } from 'react-notifications';

import {
  selectAttachment as selectAttachmentAction,
  attachmentState,
  addAttachments,
  updateThumbnailPresence,
  addAttachment,
  selectAttachment,
  confirmChanges,
  removeAttachment,
  changeDescription,
  undoChanges,
  changeSort,
  addLocalIds,
  changeDeadline,
  changePriority,
} from './actions';
import {
  getAttachments,
  getSelectedImage,
  canNavigateNext,
  canNavigatePrev,
} from './selectors';
import { actions as appActions } from '../app/actions';
import { getToken } from '../auth/selectors';
import { getGalleryUser } from '../user/selectors';
import { transformAttachment, checkIfResourceExists } from './utils';
import { PDF } from '../../components/upload-area/upload-helper';
import { getLocalLink } from '../../services/IssueService';
import { config } from '../../services/config';
import { jsonRequest } from '../service/action-creator';
import { isNil } from 'lodash-es';

export const selectImage = (imageInfo) => (dispatch) => {
  dispatch(selectAttachmentAction(imageInfo));
};

export const addAttachmentsForIssue = (issue) => async (dispatch, getState) => {
  if (isNil(issue) || isNil(issue.attachments)) {
    return;
  }

  const attachments = [];
  const token = getToken(getState());

  for (let index = 0; index < issue.attachments.length; index++) {
    const att = issue.attachments[index];

    if (att.mime_type === PDF) {
      try {
        att.url = await getLocalLink(att.url);

        attachments.push(
          transformAttachment({
            att,
            isThumbnailPresent: false,
            index,
            attachmentState,
          })
        );
      } catch {}

      continue;
    }

    const isThumbnailPresent = await checkIfResourceExists(
      att.web_mob_thumbnail_url,
      token
    );

    attachments.push(
      transformAttachment({
        att,
        isThumbnailPresent,
        index,
        attachmentState,
      })
    );
  }

  // TODO enable when carousel component will support
  // checking for missing thumbnails.
  // checkForMissingThumbnails(dispatch, attachments, token, issue.id);
  dispatch(addAttachments(attachments, issue.id));
};

const checkForMissingThumbnails = async (
  dispatch,
  attachments,
  token,
  hostId
) => {
  const missing = attachments.filter((x) => !x.thumbnailPresent);

  if (missing.length > 0) {
    let hasMissingAtt = false;

    for (let i = 0; i < missing.length; i++) {
      const att = missing[i];
      att.thumbnailPresent = await checkIfResourceExists(
        att.thumbnailUrl,
        token
      );
      hasMissingAtt = hasMissingAtt || !att.thumbnailPresent;

      if (att.thumbnailPresent) {
        dispatch(updateThumbnailPresence(att.index, true, hostId));
      }
    }

    if (hasMissingAtt) {
      setTimeout(() => {
        checkForMissingThumbnails(
          dispatch,
          missing.filter((x) => !x.thumbnailPresent),
          token,
          hostId
        );
      }, 1000);
    }
  }
};

export const onUpload =
  (hostId) =>
  ({ file, fileSource, thumbnailUrl }) =>
  (dispatch, getState) => {
    const attachment = {
      url: fileSource,
      thumbnailUrl: thumbnailUrl,
      originalDescription: '',
      description: '',
      mimeType: file.type,
      fileName: file.fileName || file.name,
      showAvatar: true,
      index: 0,
      originalIndex: -1,
      state: attachmentState.NEW,
      originalState: attachmentState.DELETED,
      user: getGalleryUser(getState()),
      file: file,
      allowedToEdit: true,
      allowedToDelete: true,
    };

    dispatch(addAttachment(attachment, hostId));
    dispatch(selectAttachment(attachment, hostId));
  };

export const onHandlePopupConfirm = (onGalleryChangedCb) => (dispatch) => {
  dispatch(confirmChanges());
  dispatch(appActions.toggleGalleryPopup());

  if (onGalleryChangedCb) {
    onGalleryChangedCb();
  }
};

export const onHandleRemoveItem = (galleryId) => (dispatch, getState) => {
  const attachmentsBeforeChange = getAttachments(getState(), galleryId);
  const selectedAttachment = getSelectedImage(getState(), galleryId);
  const indexToRemove = selectedAttachment.index;
  dispatch(removeAttachment(indexToRemove, galleryId));

  const allAttachments = getAttachments(getState(), galleryId);

  // The last attachment has been removed
  if (attachmentsBeforeChange.size === 1) {
    // TODO Actually it is not ok to confirm
    // changes when user deletes last attachment
    // Comments are welcome.
    dispatch(onHandlePopupConfirm());

    return;
  }

  // There is no attachments to the right. Select previous.
  if (attachmentsBeforeChange.size === indexToRemove + 1) {
    dispatch(
      selectAttachment(allAttachments.get(indexToRemove - 1), galleryId)
    );
  }
};

export const onHandleDescriptionChange =
  (galleryId) => (event) => (dispatch, getState) => {
    const description = event.target.value;

    const selectedAttachment = getSelectedImage(getState(), galleryId);

    if (selectedAttachment) {
      dispatch(
        changeDescription(selectedAttachment.index, description, galleryId)
      );
      dispatch(confirmChanges());
    }
  };

export const onPopupCancel = () => (dispatch) => {
  dispatch(appActions.toggleGalleryPopup());
};

export const onNavigateNext = (galleryId) => (dispatch, getState) => {
  if (canNavigateNext(getState(), galleryId)) {
    const selectedImage = getSelectedImage(getState(), galleryId);
    const allAttachments = getAttachments(getState(), galleryId);

    dispatch(
      selectAttachment(allAttachments.get(selectedImage.index + 1), galleryId)
    );

    return true;
  }

  return false;
};

export const onNavigatePrev = (galleryId) => (dispatch, getState) => {
  if (canNavigatePrev(getState(), galleryId)) {
    const selectedImage = getSelectedImage(getState(), galleryId);
    const allAttachments = getAttachments(getState(), galleryId);

    dispatch(
      selectAttachment(allAttachments.get(selectedImage.index - 1), galleryId)
    );

    return true;
  }

  return false;
};

export const onSortEnd =
  (
    {
      oldIndex,
      newIndex,
      issueId,
      sortErrorLabel,
      onlyLocalSort,
      refetchIssue,
    },
    galleryId
  ) =>
  async (dispatch, getState) => {
    if (onlyLocalSort) {
      dispatch(addLocalIds(galleryId));
    }

    dispatch(changeSort(oldIndex, newIndex, galleryId));

    if (onlyLocalSort) {
      return;
    }

    try {
      const url = `/${config.API.ISSUES}/${issueId}`;

      const allAttachments = getAttachments(getState(), galleryId);

      const attachment = allAttachments
        .filter((item) => {
          if (oldIndex < newIndex) {
            return item.index >= oldIndex && item.index <= newIndex;
          } else {
            return item.index <= oldIndex && item.index >= newIndex;
          }
        })
        .map((item) => ({ id: item.id, index: item.index }));

      const result = await dispatch(
        jsonRequest(config.REQ_TYPES.PUT, url, { attachment })
      );

      if (!result.success) {
        NotificationManager.error(sortErrorLabel);
        dispatch(undoChanges(galleryId));
        throw new Error();
      } else {
        refetchIssue();
      }
    } catch (error) {
      NotificationManager.error(sortErrorLabel);
      dispatch(undoChanges(galleryId));
    }
  };

export const onDeadlineChange = (date, id) => (dispatch, getState) => {
  const selectedAttachment = getSelectedImage(getState(), id);

  dispatch(changeDeadline(selectedAttachment.index, date, id));
};

export const onPriorityChange = (priorityId, id) => (dispatch, getState) => {
  const selectedAttachment = getSelectedImage(getState(), id);

  dispatch(changePriority(selectedAttachment.index, priorityId, id));
};
