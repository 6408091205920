export const config = {
  // URL: 'https://wowflow.org',
  //URL: 'https://staging.wowflow.org',
  URL: process.env.REACT_APP_API_URL || '',

  API_VERSION: process.env.REACT_APP_API_VERSION || 'v4',

  API: {
    LOGIN: 'auth/login',
    PASSWORD_CHANGE: 'auth/forgot_password',
    PASSWORD_RESET: 'auth/reset_password',
    WORKSPACES: 'workspace',
    ISSUES: 'issue',
    USERS: 'users',
    COMMENT: 'comment',
    ATTACHMENT_COMMENT: 'attachment_comment',
    CATEGORY: 'category',
    LANGUAGE: 'language',
    USER: 'user',
    QR_CODE: 'qr_code',
    USER_ACTIVITY: 'activity_log',
    AUTOCOMPLETE: 'template',
    PROCESS_NO: 'process_number',
    SURVEY: 'survey',
  },

  CONTENT_TYPES: {
    APPLICATION_JSON: 'application/json',
    MULTIPART: 'multipart/form-data',
  },

  REQ_TYPES: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
  },
};

export const REQUEST_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
};
