import { objectMap } from '../../../../../../utils/utility';

export const EXPORT_TYPES = {
  standard: 'standard',
  custom: 'custom',
};

export const PDF_EXPORT_DEFAULT_VALUES = {
  [EXPORT_TYPES.standard]: {
    export_type: EXPORT_TYPES.standard,
    include_subtasks: false,
    send_email_copy: false,
    email_addresses: [],
  },
  [EXPORT_TYPES.custom]: {
    export_type: EXPORT_TYPES.custom,
    include_activity_log: true,
    include_comments: false,
    include_description: true,
    include_details: true,
    include_header: true,
    include_title: true,
    include_media: true,
    include_ws_reference_number: true,
    send_email_copy: false,
    email_addresses: [],
    media: [],
    include_checklists_on_report: false,
    include_forms: true,
  },
};

const _parseCustomPropsFromSettins = (type, settings) => {
  return {
    include_header:
      settings?.include_header_pdf ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_header,

    include_activity_log:
      settings?.include_history_log ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_activity_log,

    include_title:
      settings?.include_issue_title ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_title,

    include_ws_reference_number:
      settings?.include_ws_ref_num_pdf ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_ws_reference_number,

    include_details:
      settings?.include_issue_details_pdf ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_details,

    include_forms:
      settings?.include_client_forms ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_forms,

    include_description:
      settings?.include_description_pdf ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_description,

    include_checklists_on_report:
      settings?.include_checklists_on_report ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_checklists_on_report,

    include_comments:
      settings?.include_comments_on_report ??
      PDF_EXPORT_DEFAULT_VALUES[type].include_comments,
  };
};

export const getDefaultValues = (issue, type, settings) => {
  const entries = issue.attachments.map((att) => {
    const key = att.id;
    let value = {};
    value.include = true;

    if (att.description) {
      value.include_description = true;
    }

    return [key, value];
  });
  const media = Object.fromEntries(entries);
  return {
    ...PDF_EXPORT_DEFAULT_VALUES[type],
    ...(type === 'custom' ? _parseCustomPropsFromSettins(type, settings) : {}),
    include_media:
      issue.attachments.length < 1
        ? false
        : PDF_EXPORT_DEFAULT_VALUES[type].include_media,
    media,
  };
};

export const transformExportPdfData = (data) => {
  const {
    export_type,
    include_subtasks,
    send_email_copy,
    email_addresses = [],
    ...customData
  } = data;

  const mappedEmailAddresses =
    send_email_copy && email_addresses.length
      ? email_addresses.map((item) => item?.value).join(';')
      : '';

  if (export_type === 'standard') {
    return {
      export_type: export_type,
      include_subtasks: include_subtasks ? 1 : 0,
      send_email_copy: send_email_copy ? 1 : 0,
      email_addresses: mappedEmailAddresses,
    };
  }

  const { media, ...otherCustomData } = customData;

  const transformedOtherCustomData = objectMap(otherCustomData, (v) =>
    v ? 1 : 0
  );

  const transformedMedia = Object.keys(media)
    .filter((item) => media[item].include)
    .map((item) => {
      let transformedItem = {
        id: Number(item),
      };
      if (media[item].include_description) {
        transformedItem.include_description = 1;
      } else {
        transformedItem.include_description = 0;
      }

      return transformedItem;
    });

  return {
    export_type,
    ...transformedOtherCustomData,
    media: otherCustomData.include_media ? transformedMedia : [],
    send_email_copy: send_email_copy ? 1 : 0,
    email_addresses: mappedEmailAddresses,
  };
};
