import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../redux/utils';
import qs from 'query-string';
import { BASE_URL } from '../../../config/constants';
import { flattenArray } from './utils';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      /* headers.set('Content-Type', `application/json`); */
      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    'Users',
    'User',
    'WorkspaceRoles',
    'UserActivity',
    'JobPositions',
    'WorkspacesShort',
    'ExternalUsers',
  ],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'users/', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),

    getUsersFilterMixCollabs: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'users/filter-mix-collabs', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
    }),

    getUsersFilterMix: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'users/filter-mix', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
    }),

    getUsersByWorkspaceAccess: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'users/list-by-workspace-access', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
    }),

    getUser: builder.query({
      query: (id) => {
        const url = qs.stringifyUrl(
          { url: `user/${id ?? 'me'}` },
          { skipEmptyString: true, skipNull: true }
        );

        return url;
      },
      providesTags: (result) =>
        result
          ? [{ type: 'User', id: result.id }]
          : [{ type: 'User', id: 'LIST' }],
    }),

    getWorkspaces: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl({ url: 'workspace/short', query: params });

        return url;
      },
      providesTags: [{ type: 'WorkspacesShort', id: 'LIST' }],
    }),

    getWorkspaceRoles: builder.query({
      query: (id) => {
        const url = `/user/${id}/workspace_roles`;

        return url;
      },
      transformResponse: (response) => flattenArray(response),
      providesTags: [{ type: 'WorkspaceRoles', id: 'LIST' }],
    }),

    changeWorkspaceRoles: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `user/${id}/workspace_roles`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Users', id },
        { type: 'User', id },
        { type: 'WorkspaceRoles', id: 'LIST' },
      ],
    }),

    getJobPositions: builder.query({
      query: () => {
        const url = `/users/job_positions`;

        return url;
      },
      providesTags: [{ type: 'JobPositions', id: 'LIST' }],
    }),

    addUserToALlWorkspcaces: builder.mutation({
      query(data) {
        const { id, params } = data;

        const url = qs.stringifyUrl(
          { url: `user/${id}/all_ws`, query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );
        return {
          url,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Users', id },
        { type: 'User', id },
        { type: 'WorkspaceRoles', id: 'LIST' },
      ],
    }),
    createNewUser: builder.mutation({
      query(body) {
        return {
          url: `users`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    editUser: builder.mutation({
      query(data) {
        const { id, body } = data;

        return {
          url: `user/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Users', id },
        { type: 'Users', id: 'LIST' },
        { type: 'User', id: 'LIST' },
        { type: 'User', id },
      ],
    }),

    updateProfileImage: builder.mutation({
      query(data) {
        const { id, image } = data;

        const form = new FormData();
        form.append('attachments[0][file]', image);
        form.append('_method', 'PUT');

        return {
          url: `user/${id}`,
          method: 'POST',
          body: form,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Users', id },
        { type: 'Users', id: 'LIST' },
        { type: 'User', id: 'LIST' },
        { type: 'User', id },
      ],
    }),
    activateMultipleUsers: builder.mutation({
      query(body) {
        return {
          url: `/users/activate`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    archiveMultipleUsers: builder.mutation({
      query(body) {
        return {
          url: `/users/archive`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    resendInviteMultipleUsers: builder.mutation({
      query(body) {
        return {
          url: `/users/resend_invite`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    addUsersToWorkspaces: builder.mutation({
      query(body) {
        return {
          url: `/users/add_workspaces`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),

    getUserActivity: builder.query({
      query(data) {
        const { userId, ...params } = data;

        const url = qs.stringifyUrl(
          { url: `/user/${userId}/activity_log`, query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
      transformResponse: (response) => ({
        ...response,
        hasMore: response.current_page !== response.last_page,
      }),
      providesTags: [{ type: 'UserActivity', id: 'LIST' }],
    }),

    getUserPresence: builder.query({
      query(data) {
        const { userId, activity_type, ...params } = data;

        const url = qs.stringifyUrl(
          { url: `/workspace/user/${userId}/presence/logs`, query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
      transformResponse: (response) => ({
        ...response.records,
        hasMore: response.records.current_page !== response.records.last_page,
        total_time_spent: response.total_time_spent,
      }),
      providesTags: [{ type: 'UserActivity', id: 'LIST' }],
    }),
    requestUserLocation: builder.mutation({
      query(id) {
        return {
          url: `/user/${id}/request_location`,
          method: 'POST',
        };
      },
    }),
    createMultipleUsers: builder.mutation({
      query(body) {
        return {
          url: `users/create-multiple`,
          method: 'POST',
          body,
        };
      },
    }),
    getIfUsersExists: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: `/users/check-existing`, query: params },
          { skipEmptyString: true, skipNull: true }
        );

        return url;
      },
    }),
    getExternalReporters: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: `/users/external-reporters`, query: params },
          { skipEmptyString: true, skipNull: true }
        );

        return url;
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetUsersFilterMixCollabsQuery,
  useLazyGetUsersFilterMixQuery,
  useLazyGetUsersByWorkspaceAccessQuery,
  useGetUserQuery,
  useUpdateProfileImageMutation,
  useAddUserToALlWorkspcacesMutation,

  useGetWorkspacesQuery,
  useChangeWorkspaceRolesMutation,
  useGetWorkspaceRolesQuery,

  useGetJobPositionsQuery,
  useGetUserActivityQuery,
  useGetUserPresenceQuery,

  useCreateNewUserMutation,
  useEditUserMutation,
  useActivateMultipleUsersMutation,
  useArchiveMultipleUsersMutation,
  useResendInviteMultipleUsersMutation,
  useAddUsersToWorkspacesMutation,
  useRequestUserLocationMutation,
  useCreateMultipleUsersMutation,
  useLazyGetIfUsersExistsQuery,
  useLazyGetExternalReportersQuery,
} = usersApi;
