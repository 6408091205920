import dayjs from 'dayjs';

const MAX_HOURS = 23.99972222222222;

export const getFullDayTimelineData = (
  timelineItems = [],
  additionalParams = {}
) => {
  const { eachHourHeightPxBox, startDayTime } = additionalParams;

  const returnData = {
    timelineData: [],
  };

  const endDayTime = new Date(startDayTime);
  endDayTime.setHours(23, 59, 59);

  const calcuateDimension = eachHourHeightPxBox;

  timelineItems.forEach((timelineItem, index) => {
    const left = 1;

    const data = {
      data: { ...timelineItem, title: null, fullDay: true },
      id: timelineItem?.id ?? null,
      left: index === 0 ? left : left + index * 11,
      top: 0,
      height: 50,
      width: 10,
    };

    if (timelineItem?.due_date || timelineItem?.start_date) {
      if (timelineItem?.start_date) {
        const startDate = new Date(timelineItem.start_date * 1000);

        const msFromStartDate = startDate.getTime() - startDayTime.getTime();

        if (msFromStartDate) {
          const hours = msFromStartDate / 3600000; //convert into hours. This might be decimal. These hours are how much hours from axis (on timeline).
          const calculatedValue = hours * calcuateDimension;
          data.top = calculatedValue <= 0 ? 0 : calculatedValue; //This is actually how many pixels we should move from the given axis.
        }

        if (timelineItem?.due_date) {
          const dueDate = new Date(timelineItem.due_date * 1000);
          const msBetweenIssueDates =
            dueDate.getTime() -
            (dayjs(startDayTime).isSame(startDate, 'day')
              ? startDate.getTime()
              : startDayTime.getTime());
          const msBetweenStartDateEndDayDate =
            endDayTime.getTime() - startDate.getTime();

          if (msBetweenIssueDates) {
            const hours = msBetweenIssueDates / 3600000;
            const hoursBetweenStartDateEndDayDate =
              msBetweenStartDateEndDayDate / 3600000;
            const finalHours =
              hours > hoursBetweenStartDateEndDayDate &&
              dayjs(endDayTime).isSame(startDate, 'day')
                ? hoursBetweenStartDateEndDayDate
                : hours > MAX_HOURS
                ? MAX_HOURS
                : hours;
            data.height = finalHours * calcuateDimension;
          }
        }
      } else if (timelineItem?.due_date) {
        const dueDate = new Date(timelineItem.due_date * 1000);
        const msFromEndDate = dueDate.getTime() - startDayTime.getTime();
        if (msFromEndDate) {
          const hours = msFromEndDate / 3600000; //convert into hours. This might be decimal. These hours are how much hours from axis (on timeline).
          const calculatedValue = hours * calcuateDimension - calcuateDimension;
          data.top = calculatedValue <= 0 ? 0 : calculatedValue; //This is actually how many pixels we should move from the given axis.
        }
      }
    }

    returnData.timelineData.push(data);
  });

  if (timelineItems.length > 2) {
    //Means we need to group.
    const timelineItems = JSON.parse(JSON.stringify(returnData.timelineData));
    returnData.timelineData = [];
    let group;
    timelineItems.forEach((timelineItem, index) => {
      if (index === 0) {
        returnData.timelineData.push(timelineItem);
      } else {
        if (!group) {
          group = {
            ...timelineItem,
            id: 1,
            data: {
              ...timelineItem.data,
              issues: [timelineItem.data],
              box: true,
              id: 1,
            },
          };
        } else {
          group.data.issues.push(timelineItem.data);
          group.data.issues.forEach((item) => {
            if (!item || !item.data) return;
            if (item.data.start_date > group.data.start_date) {
              group.data.start_date = item.data.start_date;
              if (item.data.top) group.top = item.data.top;
            }
            if (item.data.due_date < group.data.due_date) {
              group.data.due_date = item.data.due_date;
              if (item.data.height) group.height = item.data.height;
            }
          });
        }
      }
    });
    if (group) returnData.timelineData.push(group);
  }

  return returnData;
};
