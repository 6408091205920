import { useSelector } from 'react-redux';
import { filtersSelector, filtersDataSelector } from '../selectors';
import { setFilters, setFiltersData } from '../slice';

const useFilters = ({ target }) => {
  const filters = useSelector(filtersSelector(target));
  const filtersData = useSelector(filtersDataSelector(target));
  return {
    filters,
    setFilters,
    filtersData,
    setFiltersData,
  };
};

export default useFilters;
