import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  padding: 0 30px 30px;
  flex-grow: 1;
`;

export const Page = styled.div`
  display: flex;
  min-height: 100%;
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  margin: 0rem 0 2rem 0;
`;

export const CardsItem = styled.div`
  width: 100%;
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.06);
  padding: 20px 20px 15px;
  background-color: white;
`;
