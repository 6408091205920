import styled from 'styled-components/macro';
import {
  darkerGray,
  gradientBlue,
  newBorder,
  purple500,
  userPillGray,
} from '../../../../config/colors';
import { breakPoints } from '../../../../config/config';
import { WowDisplayFlex } from '../../../../components_new/WowBasicElements';

export const Wrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height ?? '100%'};
  background-color: white;
  padding: 85px;
  position: relative;
  min-height: 100%;

  @media (max-width: ${breakPoints.tabletWidth}px) {
    padding: 30px 20px 20px 20px;
    min-height: 100%;
    height: auto;
  }

  button {
    :disabled {
      :hover {
        cursor: auto;
      }
      color: #8895a5;
    }
  }
`;

export const LogoImage = styled.img`
  height: 30px;
  margin-bottom: 40px;
  @media (max-width: ${breakPoints.tabletWidth}px) {
    margin-bottom: 25px;
  }
`;

export const Title = styled.div`
  color: ${darkerGray};
  font-size: 23px;
  font-weight: bold;
`;

export const SubTitle = styled.div`
  color: ${userPillGray};
  font-size: 15px;
  letter-spacing: 0px;
  margin-top: 5px;
`;

export const Sections = styled.div`
  position: relative;
  height: calc(100% + 9px);
  @media (max-width: ${breakPoints.tabletWidth}px) {
    position: unset;
  }
`;

export const SectionLeft = styled.div`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : '685px')};
`;

export const InputWrapper = styled.div`
  max-width: 330px;
  margin-top: 40px;
  button {
    height: 38px;
    color: ${darkerGray};
    font-size: 16px;
  }

  @media (max-width: ${breakPoints.tabletWidth}px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

export const SectionRight = styled.div`
  box-shadow: 0px 2px 10px #37465f26;
  border-radius: 10px 0 0 0;
  right: -85px;
  position: absolute;
  width: calc(100% - 350px); //TMP
  height: 100%;
  top: 0;
  padding: 35px 0 0 35px;
  @media (max-width: ${breakPoints.tabletWidth}px) {
    display: none;
  }
`;

export const ProgressBar = styled.div`
  width: 210px;
  height: 7px;
  border-radius: 8px;
  background-color: ${newBorder};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: ${({ width }) => width ?? 0};
    height: 100%;
    left: 0;
    background: transparent
      linear-gradient(180deg, ${purple500} 0%, ${gradientBlue} 100%) 0% 0%
      no-repeat padding-box;
  }
`;

export const Footer = styled(WowDisplayFlex)`
  @media (max-width: ${breakPoints.tabletWidth}px) {
    box-shadow: 0px 0px 10px #37465f26;
    width: 100%;
    position: fixed;
    padding: 20px;
    left: 0;
    bottom: 0;
    flex-direction: column;

    button {
      width: 100%;
    }

    span {
      margin-top: 20px;
    }

    > div {
      width: 100%;
    }

    div > div {
      left: auto;
      top: 50%;
    }
  }
`;

export const CtaWrapper = styled.div`
  position: relative;
  display: inline-block;

  div {
    right: 0;
    left: auto;
    top: 50%;
  }
`;
