import { useEffect } from 'react';

const useKeyPress = (key, callback) => {
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === key) {
        e.preventDefault();

        callback();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [callback, key]);
};

export default useKeyPress;
