import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import isNumber from 'lodash/isNumber';

import { validationModalMessageWithSize } from '../../../views/internal/workspaces/workspace/Documents/components/AddFiles/utils';

const MODE = {
  VALIDATION_PER_FILE: 'VALIDATION_PER_FILE',
  VALIDATION_ALL_FILES: 'VALIDATION_ALL_FILES',
};

const useUploadWithAlerts = ({
  validatorPerFile,
  validatorForFiles,
  getValidationMessage = validationModalMessageWithSize,
  uploadFiles = () => null,
  customMaxFileSize,
  updateLoadProgress = () => null,
} = {}) => {
  const mode = validatorPerFile
    ? MODE.VALIDATION_PER_FILE
    : MODE.VALIDATION_ALL_FILES;

  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  // #region logic used for modals when some files are larger
  const localInvalidFilesRef = useRef([]);
  const [isOpenValidationInfoModal, setIsOpenValidationInfoModal] = useState();
  const [validationInfoModalMessage, setValidationInfoModalMessage] =
    useState('');
  const localValidFilesRef = useRef([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState();
  // #endregion

  const parseAllFiles = async (files) => {
    const [incoming, filesTooBig] = await validatorForFiles(
      files,
      intl,
      customMaxFileSize
    );
    localValidFilesRef.current = incoming;
    localInvalidFilesRef.current = filesTooBig;
  };

  const parseFilesSequentially = (files = []) => {
    for (const file of files) {
      if (validatorPerFile(file)) {
        localValidFilesRef.current.push(file);
      } else {
        localInvalidFilesRef.current.push(file);
      }
    }
  };

  const onDrop = async (files) => {
    setLoading(true);
    updateLoadProgress(true);
    // case when validation all files
    if (mode === MODE.VALIDATION_ALL_FILES) {
      await parseAllFiles(files);
    } else {
      // validation per single file
      // used to be compatible with old gallery
      parseFilesSequentially(files);
    }

    setValidationInfoModalMessage(
      getValidationMessage(
        intl,
        localInvalidFilesRef.current,
        false,
        isNumber(customMaxFileSize)
          ? customMaxFileSize / 1e6
          : customMaxFileSize
      )
    );

    // check is there any invalid files
    const validFiles = localValidFilesRef.current;
    const invalidFiles = localInvalidFilesRef.current;

    if (invalidFiles.length && !validFiles.length) {
      setIsOpenValidationInfoModal(true);
      return;
    }

    if (invalidFiles.length && validFiles.length) {
      setIsOpenConfirmModal(true);
      return;
    }
    setLoading(false);
    updateLoadProgress(false);
    handleChange();
  };

  const handleChange = () => {
    // trigger upload with provided valid files
    uploadFiles(localValidFilesRef.current);
    localInvalidFilesRef.current = [];
    localValidFilesRef.current = [];
  };

  // #region modal invalid info
  const handleValidationInfoModalClose = (e) => {
    // stop propagtion to trigger open of file chooser twice
    e.stopPropagation();
    localInvalidFilesRef.current = [];
    setIsOpenValidationInfoModal(false);
    updateLoadProgress(false);
    setLoading(false);
  };

  const handleConfirmModalClose = (e) => {
    // stop propagtion to trigger open of file chooser twice
    e.stopPropagation();
    localInvalidFilesRef.current = [];
    localValidFilesRef.current = [];
    updateLoadProgress(false);
    setLoading(false);
    setIsOpenConfirmModal(false);
  };

  const handleConfimAction = (e) => {
    // stop propagtion to trigger open of file chooser twice
    e.stopPropagation();
    setIsOpenConfirmModal(false);
    updateLoadProgress(false);
    setLoading(false);
    handleChange();
  };

  return {
    onDrop,
    handleValidationInfoModalClose,
    handleConfirmModalClose,
    handleConfimAction,

    isOpenConfirmModal,
    isOpenValidationInfoModal,
    validationInfoModalMessage,
    loading,

    localInvalidFiles: localInvalidFilesRef.current,
  };
};

export default useUploadWithAlerts;
