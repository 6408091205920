import { forwardRef } from 'react';
import { StyledTextArea } from './styles';
import { CharactersCount } from '../WowBasicElements';
import { SIZE } from '../Modal';

export const TextArea = forwardRef(
  (
    {
      size = SIZE.big,
      maxLength = 300,
      displayCharacterCount = false,
      ...props
    },
    ref
  ) => {
    const { value } = props;

    return (
      <>
        <StyledTextArea
          size={size}
          ref={ref}
          maxLength={maxLength}
          {...props}
        />
        {displayCharacterCount && (
          <CharactersCount>
            {' '}
            {value ? value.length : 0}/{maxLength}{' '}
          </CharactersCount>
        )}
      </>
    );
  }
);

export default TextArea;
