import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { filtersAdvancedGray, outline, white } from '../../config/colors';
import dict from '../../config/multilang';
import { themeColour } from '../../helpers/theme';

const Button = styled.button`
  font-size: 12px;
  border-radius: 20px;
  padding: 6px 14px;
  cursor: pointer;
`;

const InactiveButton = styled(Button)`
  color: ${filtersAdvancedGray};
  background-color: transparent;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${outline};
  }
`;

const ActiveButton = styled(Button)`
  background: ${themeColour};
  color: ${white};
`;

const Icon = styled.i.attrs({ className: 'icon icon-filters' })`
  color: ${({ isFiltered, ...restProps }) =>
    isFiltered ? white : themeColour(restProps)};
  margin-right: 6px;
  font-size: 14px;
`;

const IconClose = styled.i.attrs({ className: 'icon icon-close' })`
  color: ${white};
  margin-left: 8px;
`;

const AdvancedFilterButton = ({
  onClick,
  advancedFiltersCount,
  onClearAdvancedFilters,
  labelKey,
}) => {
  const intl = useIntl();

  const isFiltered = advancedFiltersCount > 0;

  if (isFiltered) {
    return (
      <ActiveButton
        onClick={onClick}
        mainAccent
        data-testid="filtersButtonWithCount"
      >
        <Icon isFiltered={isFiltered} />
        {advancedFiltersCount}
        <IconClose onClick={onClearAdvancedFilters} />
      </ActiveButton>
    );
  }

  return (
    <InactiveButton onClick={onClick}>
      <Icon isFiltered={isFiltered} mainAccent />

      {intl.formatMessage({
        id: labelKey || 'advanced',
        defaultMessage: dict.advanced,
      })}
    </InactiveButton>
  );
};

export default AdvancedFilterButton;
