import styled from 'styled-components/macro';
import {
  darkGray,
  gradientBlue,
  hardWhite,
  purple500,
} from '../../config/colors';
import { WowDisplayFlex } from '../WowBasicElements';

export const Wrapper = styled.div`
  flex-grow: ${({ flexGrow }) => flexGrow ?? '1'};
  padding-left: ${({ pLeft }) => (pLeft ? pLeft + 'px' : '0')} !important;
`;

export const InfoMessageWrapper = styled(WowDisplayFlex)`
  border-radius: 8px;
  background-color: ${hardWhite};
  padding: 15px 25px;
  align-items: flex-start;
  margin: 25px 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const SubHeaderWrapper = styled(WowDisplayFlex)`
  margin: 15px 0;
  justify-content: flex-end;
`;

export const SubHeaderButton = styled.button`
  display: flex;
  padding: 7px 10px;
  align-items: flex-start;
  align-items: center;
  background: ${purple500};
  border-radius: 16px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: white;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
`;

// #region search input
export const SearchWrapper = styled.div`
  background-color: ${hardWhite};
  display: flex;
  border-radius: 4px;
  padding: 6px 8px;
  margin-bottom: 20px;
`;

export const SearchInput = styled.input.attrs({ type: 'text' })`
  outline: none;
  width: 100%;
  color: ${darkGray};
  background-color: transparent;
  font-size: 15px;
`;

export const SearchIcon = styled.i.attrs({ className: 'icon icon-zoom' })`
  margin-right: 8px;
  color: ${darkGray};
`;

// #endregion

// #region modal content
export const Content = styled.div`
  margin-bottom: 10px;
  height: ${({ height }) => height ?? '300px'};
`;

export const LoaderWrapper = styled.div`
  position: relative;
  padding-top: 15px;

  div:first-child {
    position: relative;
    width: 20px;
  }
`;

export const NoItems = styled.div`
  padding: 0 15px 15px 15px;
  text-align: center;
  padding-top: 15px;
`;

// #endregion

export const AddItemsWrapper = styled.div`
  overflow: auto;
  max-height: ${({ height }) => height + 'px' ?? '450px'};
`;

export const StyledDropdownWrapper = styled.div`
  margin-bottom: 20px;
  button {
    height: 38px;
  }
`;

export const ContactButton = styled.button`
  width: 100%;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${gradientBlue};
  font-size: 14px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;
