import { Scrollbar } from 'react-scrollbars-custom';

import { WowIcon } from '../../../../../components_new/WowBasicElements';
import ScrollTrack from './components/ScrollTrack';

import { Wrapper, ThumbDraggIcon, ChildrenWrapper } from './styles';

const CalendarMainScrollableContainer = ({
  childrenWidth,
  scrollLeft,
  setScrollLeft,
}) => {
  return (
    <Wrapper>
      <Scrollbar
        noScrollY={true}
        scrollLeft={scrollLeft <= 0 ? null : scrollLeft}
        onUpdate={(e) => setScrollLeft(e?.scrollLeft ?? 0)}
        thumbXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className="__customCalendarScrollThumbStyles"
              >
                <ThumbDraggIcon>
                  <WowIcon
                    className="icon-sort-drag"
                    color={'#fff'}
                    size="18"
                    spaceRight="0"
                    spaceLeft="0"
                  />
                </ThumbDraggIcon>
              </div>
            );
          },
        }}
        trackXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <ScrollTrack {...restProps} ref={elementRef} />;
          },
        }}
        thumbYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <span {...restProps} ref={elementRef} style={{ opacity: 0 }} />
            );
          },
        }}
        trackYProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <span {...restProps} ref={elementRef} style={{ opacity: 0 }} />
            );
          },
        }}
      >
        <ChildrenWrapper width={childrenWidth}></ChildrenWrapper>
      </Scrollbar>
    </Wrapper>
  );
};

export default CalendarMainScrollableContainer;
