import { FormattedMessage } from 'react-intl';
import Button from '../../../Button';
import {
  ModalFooter,
  ModalFooterButtonWrapper,
} from '../../../WowModalElements';
import Space from '../../../Space';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';

//TO DO > global use
const ModalFooterWrapper = (props) => {
  const { handleAction, handleCancel, isActionDisabled, isLoading } = props;

  return (
    <ModalFooter>
      <ModalFooterButtonWrapper>
        <Button
          type="button"
          onClick={handleCancel}
          color="red"
          appearance="text"
          size="small"
          data-testid="cancelButton"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ModalFooterButtonWrapper>
      <Space width="20" />
      <ModalFooterButtonWrapper>
        <Button
          type="button"
          color="green"
          onClick={handleAction}
          appearance="contained"
          size="small"
          disabled={isActionDisabled || isLoading}
          data-testid="actionButton"
        >
          <FormattedMessage id="done" />
        </Button>
        {isLoading ? <LoadingSpinner small /> : null}
      </ModalFooterButtonWrapper>
    </ModalFooter>
  );
};

export default ModalFooterWrapper;
