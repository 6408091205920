import { DEFAULT_PROFILE_IMAGE } from '../../../../config/constants';
import Tooltip from '../../../Tooltip';
import { WowTooltipWrapper } from '../../../WowBasicElements';
import { Pill, PillImage, PillLabel, PillRemoveButton, Pills } from './styles';

const Selected = (props) => {
  const { items, tooltipConfig, lowOpacity, disabled, handleRemove } = props;

  if (!items?.length) return null;

  return (
    <Pills addMargin={items.length > 1}>
      {items.map((item) => {
        const _label = item.label || item.full_name;
        return (
          <Tooltip
            key={item.id}
            tooltipContent={
              tooltipConfig?.disabled ? null : (
                <WowTooltipWrapper>
                  {tooltipConfig?.content ?? _label}
                </WowTooltipWrapper>
              )
            }
          >
            <Pill lowOpacity={lowOpacity} addMargin={items.length > 1}>
              <PillImage
                src={item.image || item.profile_image || DEFAULT_PROFILE_IMAGE}
                alt={_label}
              />
              <PillLabel data-testid="selectedOptionLabel">{_label}</PillLabel>
              {!disabled ? (
                <PillRemoveButton onClick={() => handleRemove(item.id)}>
                  <i className="icon icon-close" />
                </PillRemoveButton>
              ) : null}
            </Pill>
          </Tooltip>
        );
      })}
    </Pills>
  );
};

export default Selected;
