import { FormattedMessage } from 'react-intl';

import Button from '../../../../../../../components_new/Button';

import { Wrapper } from './styles';
import { ModalFooterButtonWrapper } from '../../../../../../../components_new/WowModalElements';
import Space from '../../../../../../../components_new/Space';
import { LoadingSpinner } from '../../../../../../../components/loading-spinner/loading-spinner.component';

const FieldsActionButtonsWrapper = ({
  disableSubmit = false,
  onClose = () => {},
  isLoading,
}) => {
  return (
    <Wrapper>
      <ModalFooterButtonWrapper>
        <Button
          disabled={disableSubmit}
          type="submit"
          size="small"
          data-testid="issueDetailsModalWrapperSaveModalButton"
        >
          <FormattedMessage id="save" />
        </Button>
        {isLoading ? <LoadingSpinner small /> : null}
      </ModalFooterButtonWrapper>
      <Space width="10" />
      <ModalFooterButtonWrapper>
        <Button
          color="red"
          onClick={onClose}
          appearance="text"
          size="small"
          data-testid="issueDetailsModalWrapperCloseModalButton"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ModalFooterButtonWrapper>
    </Wrapper>
  );
};

export default FieldsActionButtonsWrapper;
