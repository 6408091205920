import styled from 'styled-components/macro';
import { darkerGray, newBorder } from '../../../../../../config/colors';

export const Wrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${newBorder};
`;

export const Label = styled.div`
  color: ${darkerGray};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2px;
`;
