import styled from 'styled-components/macro';

import { gray, outline } from '../../../../../config/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  & .__customCalendarScrollThumbStyles {
    background-color: ${gray} !important;
    border-radius: 5px !important;
    height: 20px !important;
    position: absolute;
    top: 2.7px;
    left: 0;
  }
  & .__customCalendarScrollTrackStyles {
    background-color: ${outline} !important;
    border-radius: 15px !important;
    height: 25px !important;
    position: absolute;
    top: 0 !important;
    left: 10px !important;
  }
`;

export const ThumbDraggIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ChildrenWrapper = styled.div`
  padding-top: 35px;
  height: 100%;
  width: ${({ width }) => (width ? `${width}px` : '0')};
`;
