import qs from 'query-string';
import has from 'lodash/has';
import { getConfig } from '@adage/wow-common-utilities';

import { INIT_QUERY_PARAMS, STATUS_OPTIONS } from './constants';
import { openInNewTab } from '../../../utils/core';
import { internalRoutePaths } from '../../../config/config';

const _mapIssuePermissionsConfig = (config) => ({
  canAddAttachments: config.can_add_attachments,
  canAssignIssue: config.can_assign_issue,
  canCommentIssue: config.can_comment_issue,
  canCreateSubIssue: config.can_create_sub_issue,
  canDeleteIssue: config.can_delete_issue,
  canEditIssue: config.can_edit_issue,
  deleteOnlyMyAttachments: config.delete_only_my_attachments,
  editOnlyMyAttachments: config.edit_only_my_attachments,
  specialActionRequired: config.special_action_required,
  buttons: config.buttons,
  menu: config.menu,
  attachmentPermissions: config.attachmentPermissions,
  canEditIssueDescription: config.can_edit_issue_description,
});

const _getCollabPermissions = (data) => {
  const { issue, user } = data;

  const { collaborations, my_collaboration: myCollaboration } =
    issue.workspace ?? {};

  const permissions = {};

  if (!collaborations && !myCollaboration) {
    return permissions;
  }

  const collabortionsList = collaborations ?? [myCollaboration];
  const admins = collabortionsList.reduce(
    (acc, { admins }) => acc.concat(admins),
    []
  );
  const members = collabortionsList.reduce(
    (acc, { members }) => acc.concat(members),
    []
  );

  const allCollabUsers = [...admins, ...members];
  const { assignee_id: assigneeId } = issue;
  const loggedUserId = user.id;

  const isAssignedToCollabUser = allCollabUsers.some(
    (item) => item.id === assigneeId
  );

  //admin
  const isCollabAdmin = admins.some((item) => item.id === loggedUserId);
  const COLLAB_ADMIN_CAN_ASSIGN_WITH_STATUSES = [3, 4, 5, 6]; //deciding, declined, accepted, in progress
  const canCollabAdminAssignIssue =
    isCollabAdmin &&
    isAssignedToCollabUser &&
    COLLAB_ADMIN_CAN_ASSIGN_WITH_STATUSES.includes(issue.status?.id);

  permissions['canEditWatchersCollab'] = isCollabAdmin;

  if (myCollaboration) {
    permissions['canQuickCompleteIssue'] = false;
    permissions['canDeleteIssue'] = false;
  }

  //member
  const isCollabMember = members.some((item) => item.id === loggedUserId);
  const canCollabMemberAssignIssue =
    isCollabMember && loggedUserId === assigneeId;

  permissions['isAssigneeMandatory'] =
    isCollabAdmin || canCollabMemberAssignIssue;
  permissions['canAssignIssueCollab'] =
    canCollabAdminAssignIssue || canCollabMemberAssignIssue;

  return permissions;
};

export const calculateIssuePermissions = (data = {}) => {
  const { workspace, user, client, flowConfig, issue } = data;

  if (
    !user ||
    !Object.keys(user).length ||
    !client ||
    !Object.keys(client).length ||
    !flowConfig ||
    !Object.keys(flowConfig).length
  )
    return null;

  const selectedWorkspace = workspace || {
    managers_ids: [],
    group_managers_ids: [],
  };

  const options = { config: client.settings };
  const permissions = _mapIssuePermissionsConfig(
    getConfig(flowConfig, user, selectedWorkspace, issue, options)
  );

  //NOTE: since BE does not have flow for this, we calculate it here manually
  const isAdmin = user.role === 'admin';
  const isManager = selectedWorkspace.managers_ids?.some(
    (id) => id === user.id
  );
  const isGroupManager = selectedWorkspace.group_managers_ids?.some(
    (id) => id === user.id
  );

  const isInAvailableStatus =
    !!client?.settings?.archive_issues_statuses?.includes(issue?.status?.id) ||
    issue?.status?.id === STATUS_OPTIONS.completed.value;

  permissions['canArchiveIssue'] =
    (isAdmin || isManager || isGroupManager) &&
    isInAvailableStatus &&
    !issue?.archived;

  const canCommentArchivedIssue = issue?.archived;

  //#region collab
  const collabPermissions = _getCollabPermissions(data);
  //#endregion
  return {
    ...permissions,
    ...(canCommentArchivedIssue ? { canCommentIssue: true } : {}),
    canQuickCompleteIssue: permissions.canEditIssue,
    ...(isAdmin && issue?.archived
      ? {
          canAddAttachments: true,
          canEditIssueDescription: true,
          canDeleteIssue: true,
          canEditWorkspace: true,
          canEditCategory: true,
          canEditWatchers: true,
          canEditAssets: true,
        }
      : {}),
    ...(isAdmin
      ? {
          canDeleteIssue: true,
        }
      : {}),
    ...collabPermissions,
  };
};

export const parseInitialSearch = (
  parameters = {},
  queryString = '',
  initQueryParams
) => {
  const diff = {};
  const _initQueryParams = initQueryParams ?? INIT_QUERY_PARAMS;

  Object.keys(_initQueryParams).forEach((key) => {
    // when some of default parameter is missing
    // add to diff object
    if (!Object.keys(parameters).includes(key)) {
      diff[key] = _initQueryParams[key];
    }
  });

  const stringified = qs.stringify(diff);

  return `${queryString}${stringified && '&'}${stringified}`;
};

export const formatArrayFromQueryParam = (queryParams, key) => {
  const isSingleId = typeof queryParams[key] === 'string';
  const formatedQueryParams = !queryParams[key]
    ? []
    : isSingleId
    ? [queryParams[key]]
    : queryParams[key];

  return formatedQueryParams;
};

const checkIsObject = (value) =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

const KEYS_BLACKLIST = ['include_subtasks', 'parent_id'];

export const parseParameters = (parameters = {}) => {
  const _parsed = {};

  Object.keys(parameters).forEach((key) => {
    if (KEYS_BLACKLIST.includes(key)) return;

    // when object is key in case of issue state
    if (checkIsObject(parameters[key]) && key === 'issue_state') {
      Object.keys(parameters[key]).forEach((stateGroup) => {
        _parsed[`issue_state[${stateGroup}]`] =
          parameters[key][stateGroup]?.split?.(',') ?? [];
      });
      return;
    }

    //NOTE - due to BE settings multiselect filters are no longer {} but [];
    if (checkIsObject(parameters[key])) return;

    // check is object key
    if (Array.isArray(parameters[key])) {
      //NOTE: needed due to BE keeping both props
      const formatedKey = key.replace('_params', '');
      _parsed[formatedKey] = parameters[key].map((item) => item.id);
      return;
    }

    _parsed[key] = parameters[key];
  });

  return _parsed;
};

export const extractFiltersValues = (existingItems = [], key, parameters) => {
  // case when parameters or property is not provided return existing items
  if (!parameters || !key) return existingItems;

  const filterValue = parameters[key];
  const isFilterArray = Array.isArray(filterValue);

  if (!isFilterArray) {
    return existingItems;
  }

  const formatItem = (item) => {
    return {
      id: item.id,
      label: item.name || item.title || item.full_name,
      name: item.name,
      parent_id: item.parent_id ?? null,
      ...(item.children
        ? { children: item.children.map((child) => formatItem(child)) }
        : {}),
      ...(item.total_subws ? { total_subws: item.total_subws } : {}), //TO DO > make config as it will require different props for other options
    };
  };

  const extractedItems = filterValue.map((item) => formatItem(item));

  return [
    ...existingItems,
    // add only items that aren't existing in existing array of items
    ...extractedItems.filter?.(
      (item) =>
        !existingItems?.some?.(
          (existingItem) =>
            existingItem?.id === item.id ||
            existingItem?.children
              ?.map?.((child) => child?.id)
              ?.includes?.(item.id)
        )
    ),
  ];
};

/**
 * Helper method used to extract betweend fetched items in list and persisted
 * @param {Array} existingItems - array of existing items
 * @param {string} key - property to extract
 * @param {object} parameters - peristed parameters
 * @returns {string[]} array of ids of items that are persisted but not in the list
 */
export const getDifference = (existingItems = [], key, parameters) => {
  const filterValue = parameters[key];

  if (!parameters || !key || !Array.isArray(filterValue)) return [];

  return filterValue.filter(
    (id) =>
      !existingItems?.some?.(
        (existingItem) =>
          +existingItem?.id === +id ||
          existingItem?.children?.map?.((child) => +child?.id)?.includes?.(+id)
      )
  );
};

export const syncFiltersWithSearch = (parameters = {}, search) => {
  const retVal = { ...parameters };
  const parsedSearch = qs.parse(search);

  // go through keys
  Object.keys(retVal).forEach((key) => {
    if (key === 'issue_state') {
      if (
        ['issue_state', 'issue_state[active]', 'issue_state[archived]'].every(
          (key) => !has(parsedSearch, key)
        )
      ) {
        delete retVal[key];
        return;
      }

      if (parsedSearch.issue_state) {
        retVal.issue_state = parsedSearch.issue_state;
        return;
      }

      retVal.issue_state = {
        ...(parsedSearch?.['issue_state[active]'] && {
          active: parsedSearch?.['issue_state[active]'],
        }),
        ...(parsedSearch?.['issue_state[archived]'] && {
          archived: parsedSearch?.['issue_state[archived]'],
        }),
      };

      return;
    }

    // remove keys that are blacklisted
    if (KEYS_BLACKLIST.includes(key)) {
      delete retVal[key];
      return;
    }
  });

  return retVal;
};

export const handleShowListWithIdsFilter = (items) => {
  if (!items) return;

  const stringified = qs.stringify(
    {
      ...INIT_QUERY_PARAMS,
      ids: items.map((item) => item.id),
    },
    { arrayFormat: 'comma' }
  );

  openInNewTab(`${internalRoutePaths.ISSUES}?${stringified}`);
};

export const getUnfinishedSubtasksCounter = (issue = {}) => {
  let counter = 0;
  if (issue?.children?.length) {
    issue.children.forEach((subtask) => {
      if (subtask.status_id !== 8) counter++;
    });
  }
  return counter;
};

export const formatStatusFromQueryParams = (params) => {
  return {
    active: params?.['issue_state[active]'] ?? null,
    archived: params?.['issue_state[archived]'] ?? null,
    issue_state: Array.isArray(params?.issue_state)
      ? ''
      : params?.issue_state ?? '',
  };
};
