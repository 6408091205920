import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import Item from './Item';
import {
  EquipmentWrapper,
  SectionHeaderWithBorder,
  SectionTitle,
} from '../styles';

const AssetSection = (props) => {
  const {
    disableNavigate,
    disableDelete,
    wrapperStyle = {},
    modalClasses = {},
    showHeader = true,
    showStatistics = true,
  } = props;

  const { watch } = useFormContext();

  const asset = watch('asset');
  const asset_group = watch('asset_group');

  if (!asset && !asset_group) return '';

  return (
    <EquipmentWrapper style={wrapperStyle}>
      {showHeader ? (
        <SectionHeaderWithBorder>
          <SectionTitle>
            <FormattedMessage id={asset ? 'asset' : 'asset_group'} />
          </SectionTitle>
        </SectionHeaderWithBorder>
      ) : null}
      <Item
        item={asset || asset_group}
        isAssetGroup={asset_group}
        disableNavigate={disableNavigate}
        disableDelete={disableDelete}
        modalClasses={modalClasses}
        showStatistics={showStatistics}
      />
    </EquipmentWrapper>
  );
};

export default AssetSection;
