import dict from '../../../config/multilang';

export const categoriesFilterKeys = {
  QF_OPTION_ACTIVE: 'active',
  QF_OPTION_ARCHIVED: 'archived',
  QF_OPTION_RESTRICTED: 'user',
};

export const categoryStatuses = [
  {
    value: 'all',
    label: {
      id: 'filter_all',
      defaultMessage: dict.filter_all,
    },
  },
  {
    value: categoriesFilterKeys.QF_OPTION_ACTIVE,
    label: {
      id: 'active',
      defaultMessage: dict.activate,
    },
  },
  {
    value: categoriesFilterKeys.QF_OPTION_RESTRICTED,
    label: {
      id: 'restricted',
      defaultMessage: dict.restricted,
    },
  },
  {
    value: categoriesFilterKeys.QF_OPTION_ARCHIVED,
    label: {
      id: 'archived',
      defaultMessage: dict.archived,
    },
  },
];

export const MAX_USER_PILLS = 4;
