import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { reducers, rtkMiddlewares } from './reducers';
import { createBrowserHistory } from 'history';
import { checkForSavedData } from './utils';

const history = createBrowserHistory();

const store = configureStore({
  reducer: reducers,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...rtkMiddlewares),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
checkForSavedData(store, history);
setupListeners(store.dispatch);

export { store, history };
