import vest, { test, enforce } from 'vest';

import { isEmail } from '../../utils/validators';

enforce.extend({
  isValidEmail: (value) => isEmail(value),
});

const formValidation = vest.create((data = {}) => {
  const { first_name, last_name, email } = data;

  test('first_name', 'required_field', () => {
    enforce(first_name).isNotEmpty();
  });

  test('last_name', 'required_field', () => {
    enforce(last_name).isNotEmpty();
  });

  test('email', 'required_field', () => {
    enforce(email).isNotEmpty();
  });

  test('email', 'form_msg_email_not_valid', () => {
    enforce(email).isValidEmail();
  });
});

export { formValidation };
