import { getIn, fromJS } from 'immutable';
import { attachmentState } from './actions';
import { getGalleryId } from './utils';
import { createSelector } from 'reselect';
import {
  compressableFileTypes,
  uncompressableFileTypes,
} from '../../components/upload-area/upload-helper';

export const getAllAttachments = (state, hostId) => {
  const galleryId = getGalleryId(hostId);
  const gallery = getIn(state, ['gallery', galleryId, 'attachments']);

  return gallery ? gallery.toIndexedSeq() : null;
};

const filterAttachments = (allowedTypes) => (attachment) =>
  allowedTypes
    ? attachment.state !== attachmentState.DELETED &&
      allowedTypes.includes(attachment.mimeType)
    : attachment.state !== attachmentState.DELETED;
export const sortAttachments = (x, y) => {
  if (x.index < y.index) {
    return -1;
  }

  if (x.index > y.index) {
    return 1;
  }

  return 0;
};

export const getGalleryIdSelector = createSelector(
  (state, props) => props,
  (hostId) => getGalleryId(hostId)
);

export const getGalleryAttachments = createSelector(
  (state) => state,
  getGalleryIdSelector,
  (state, galleryId) => getIn(state, ['gallery', galleryId, 'attachments'])
);
export const getAttachmentsByType = (allowedTypes) =>
  createSelector(getGalleryAttachments, (attachments) => {
    if (!attachments) {
      return fromJS([]);
    }

    return attachments
      .filter(filterAttachments(allowedTypes))
      .sort(sortAttachments);
  });

export const getAttachments = getAttachmentsByType();
export const getImages = getAttachmentsByType(compressableFileTypes);
export const getDocuments = getAttachmentsByType(uncompressableFileTypes);

export const getSelectedImage = (state, hostId) => {
  const galleryId = getGalleryId(hostId);
  const index = getIn(state, ['gallery', galleryId, 'selectedAttachmentIndex']);
  const selectedImage = getAttachments(state, hostId).get(index);

  return selectedImage;
};

export const galleryHasNewAttachments = (state, hostId) => {
  const galleryId = getGalleryId(hostId);

  const attachments = getIn(state, ['gallery', galleryId, 'attachments']);

  if (attachments) {
    return attachments.filter((x) => x.state === attachmentState.NEW).size > 0;
  }

  return false;
};

export const galleryHasChanges = (state, hostId) => {
  const galleryId = getGalleryId(hostId);

  const attachments = getIn(state, ['gallery', galleryId, 'attachments']);

  if (attachments) {
    return attachments.filter((x) => x.state !== x.originalState).size > 0;
  }

  return false;
};

export const getIssueId = (state, hostId) => {
  const galleryId = getGalleryId(hostId);

  return getIn(state, ['gallery', galleryId, 'issue_id']);
};

export const canNavigateNext = (state, hostId) => {
  const selectedImage = getSelectedImage(state, hostId);

  if (selectedImage) {
    return selectedImage.index < getAttachments(state, hostId).size - 1;
  }

  return false;
};

export const canNavigatePrev = (state, hostId) => {
  const selectedImage = getSelectedImage(state, hostId);

  if (selectedImage) {
    return selectedImage.index > 0;
  }

  return false;
};
