import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { blockFeatureIf, parseInternalRoutes } from '../../utils';
import { TO_EXCLUDE_IF_NOT_MAIN_ORGANISATION } from '../../constants';
import { internalRoutes } from '../../../../../config/internal-routes.config';
import { getUser } from '../../../../../redux/user/selectors';

const useContentArea = () => {
  const loggedInUser = useSelector(getUser);
  const { settings, is_main: isMainOrganisation } = loggedInUser?.client ?? {};
  const isAdmin = loggedInUser?.role === 'admin';

  const blockedFeatures = useMemo(() => {
    const includeEquipment = settings?.include_equipment;
    const includeSchedules = settings?.include_schedules;
    const includeAssets = settings?.include_assets;
    const includeCollab = settings?.include_collab;
    const onlyAdminsCanSeeUsers = settings?.only_admins_see_users;

    return [
      ...blockFeatureIf(!includeEquipment, 'equipment'),
      ...blockFeatureIf(!includeSchedules, 'schedules'),
      ...blockFeatureIf(!includeAssets, 'assets'),
      ...blockFeatureIf(!includeCollab || !isAdmin, 'collab'),
      ...blockFeatureIf(onlyAdminsCanSeeUsers && !isAdmin, 'users'),
      ...(!isMainOrganisation ? TO_EXCLUDE_IF_NOT_MAIN_ORGANISATION : []),
    ];
  }, [isAdmin, settings, isMainOrganisation]);

  const parsedInternalRoutes = useMemo(
    () => parseInternalRoutes(internalRoutes, blockedFeatures),
    [blockedFeatures]
  );

  return {
    parsedInternalRoutes,
  };
};

export default useContentArea;
