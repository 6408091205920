import { useEffect, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';

import useConfig from './hooks/useConfig';
import { getCurrentTimeMarkerPosition } from '../DailyTimeline/utils';

import CalendarTimelineCardWrapper from './components/CalendarTimelineCardWrapper';
import HoursBorderSection from './components/HoursBorderSection';
import { hardWhite } from '../../../../../config/colors';

import {
  HourLabelWrapper,
  MainView,
  HoursLabel,
  HoursWrapper,
  TimelineBordersWrapper,
  TimlineItemsCardsWrapper,
  TimelineColumnWrapper,
} from './styles';

const WeeklyTimeline = (props) => {
  const {
    data = [],
    startDayTimes = [],
    handleUpdateDataItem = () => {},
    handleDeleteDataItem = () => {},
    removeItemsFromUnscheduledSidebar = () => {},
    draggableTimelineIssueRevertData,
  } = props;

  const { eachHourHeightPxBox, timelineHours, mappedCalendarData } = useConfig({
    data,
    startDayTimes,
  });

  const hoursSections = useMemo(() => {
    const elems = [];
    for (let i = 0; i < timelineHours; i++) {
      elems.push(
        <HoursLabel height={i === timelineHours ? 'auto' : eachHourHeightPxBox}>
          {i}h
        </HoursLabel>
      );
    }
    return elems;
  }, [timelineHours, eachHourHeightPxBox]);

  useEffect(() => {
    const contentElem = document.getElementsByClassName('content')?.[0];
    if (contentElem) {
      contentElem.scrollTop =
        getCurrentTimeMarkerPosition({
          eachHourHeightPxBox,
        }) - 50;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalColumns = mappedCalendarData?.length;

  return (
    <MainView>
      <HoursWrapper>
        <HourLabelWrapper>{hoursSections}</HourLabelWrapper>
      </HoursWrapper>
      {mappedCalendarData.map((item, index) => {
        return (
          <>
            <Droppable droppableId={`timelineDropableId-${index}`}>
              {(provided) => (
                <TimelineColumnWrapper
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    backgroundColor: dayjs(startDayTimes[index]).isSame(
                      new Date(),
                      'day'
                    )
                      ? hardWhite
                      : null,
                  }}
                >
                  <TimelineColumnWrapper>
                    <TimelineBordersWrapper
                      noBorder={totalColumns === index + 1}
                    >
                      <HoursBorderSection
                        timelineHours={timelineHours}
                        eachHourHeightPxBox={eachHourHeightPxBox}
                        shouldShowMarkerTime={
                          startDayTimes[index] &&
                          dayjs(startDayTimes[index]).isSame(Date.now(), 'day')
                            ? true
                            : false
                        }
                      />
                    </TimelineBordersWrapper>
                    <TimlineItemsCardsWrapper>
                      {item?.fullDayTimelineData?.length > 0 && (
                        <CalendarTimelineCardWrapper
                          timelineData={item.fullDayTimelineData}
                          handleUpdateDataItem={handleUpdateDataItem}
                          handleDeleteDataItem={handleDeleteDataItem}
                          startDayTime={
                            startDayTimes && startDayTimes?.[index]
                              ? startDayTimes[index]
                              : null
                          }
                          startDayTimes={startDayTimes}
                          fullDayItemsLength={item.fullDayTimelineData.length}
                          index={index}
                          totalColumns={totalColumns}
                        />
                      )}
                      <div
                        style={{
                          position: 'relative',
                          left: item?.fullDayTimelineData?.length
                            ? item.fullDayTimelineData.length * 12
                            : 0,
                          top: 0,
                          width: '100%',
                        }}
                      >
                        <CalendarTimelineCardWrapper
                          timelineData={item.timelineData ?? []}
                          handleUpdateDataItem={handleUpdateDataItem}
                          handleDeleteDataItem={handleDeleteDataItem}
                          removeItemsFromUnscheduledSidebar={
                            removeItemsFromUnscheduledSidebar
                          }
                          draggableTimelineIssueRevertData={
                            draggableTimelineIssueRevertData
                          }
                          startDayTime={
                            startDayTimes && startDayTimes?.[index]
                              ? startDayTimes[index]
                              : null
                          }
                          startDayTimes={startDayTimes}
                          fullDayItemsLength={
                            item?.fullDayTimelineData?.length ?? 0
                          }
                          index={index}
                          totalColumns={totalColumns}
                        />
                      </div>
                    </TimlineItemsCardsWrapper>
                  </TimelineColumnWrapper>
                </TimelineColumnWrapper>
              )}
            </Droppable>
          </>
        );
      })}
    </MainView>
  );
};

export default WeeklyTimeline;
