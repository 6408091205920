import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { userStatuses } from '../../config/config';
import noAvatar from '../../assets/img/no-avatar.jpg';

import './avatar-circle.component.scss';
import { RenderIf } from '../render-if/render-if.component';

const AvatarCircleBase = (props) => {
  // TODO: Move to parent
  // TODO: This component is only showing the image
  // TODO: Calculate style based on a state
  const activityStatus =
    props.userStatus === userStatuses.ACTIVE ? 'active' : 'inactive';
  const hasInWorkspaceProp = Object.prototype.hasOwnProperty.call(
    props,
    'isInWorkspace'
  );
  const classes = classNames('avatar-circle', props.className, {
    'avatar-circle-active': hasInWorkspaceProp && props.isInWorkspace === true,
    'avatar-circle-inactive':
      hasInWorkspaceProp && props.isInWorkspace === false,
  });

  return (
    <div className={classes}>
      <img src={props.image.img || noAvatar} alt={(props.image || {}).alt} />
      <RenderIf if={props.userStatus}>
        <div className={`icon-overlay ${activityStatus}`}></div>
      </RenderIf>
    </div>
  );
};

AvatarCircleBase.propTypes = {
  className: PropTypes.string,
  userStatus: PropTypes.string,
  image: PropTypes.object,
  isInWorkspace: PropTypes.bool,
};

export const AvatarCircle = AvatarCircleBase;
