import { useMemo } from 'react';
import { ASSIGNEE_EMAIL, MYSELF } from '../constants';
import { getAvatarInitials } from '../utils';
import useOnboarding from '../../../hooks/useOnboarding';
import { STEP_TYPES } from '../../../constants';
import useTrialRedirect from '../../../hooks/useTrialRedirect';

const useAssignIssue = ({ formMethods } = {}) => {
  const { watch, setError } = formMethods;
  const assigneEmail = watch(ASSIGNEE_EMAIL);

  const formattedInitials = useMemo(() => {
    return getAvatarInitials(assigneEmail);
  }, [assigneEmail]);

  const { handleUpdateSteps, isLoading, firstSteps } = useOnboarding();

  const { handleRedirectTo } = useTrialRedirect();

  const handleContinue = async (data) => {
    if (isLoading) return;
    const { role, assigneeEmail: _assigneEmail } = data;
    const response = await handleUpdateSteps(
      {
        stepType: STEP_TYPES.assignIssue,
        assigneeEmail: role !== MYSELF ? _assigneEmail : null,
        role,
      },
      handleRedirectTo(firstSteps.workspaceId)
    );

    const { errors } = response ?? {};

    if (errors) {
      for (const [key, value] of Object.entries(errors)) {
        setError(key, { type: 'custom', message: value?.[0] ?? 'error' });
      }
      return;
    }
  };

  const handleSkip = () => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.assignIssue,
      isSkipped: true,
    });
    handleRedirectTo(firstSteps.workspaceId);
  };

  return {
    assigneEmail,
    formattedInitials,
    handleContinue,
    handleSkip,
    isLoading,
    firstSteps,
  };
};

export default useAssignIssue;
