import React from 'react';

import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import RootView from './Root';
import { initSentry } from './config/sentry';
import { initFirebaseAnalytics } from './config/firebase';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

initSentry();
initFirebaseAnalytics();
dayjs.extend(isoWeek);

ReactDOM.render(<RootView />, document.getElementById('root'));
