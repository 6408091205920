import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from '../../../../config/constants';

export const publicReportIssueWorkspaceApi = createApi({
  reducerPath: 'publicReportIssueWorkspaceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/public`,
  }),
  tagTypes: ['PublicWorkspace', 'PublicIssue'],
  endpoints: (builder) => ({
    getPublicWorkspace: builder.query({
      query: (id) => {
        return `/workspace/${id}`;
      },
      providesTags: (result, error, params) => [
        {
          type: 'PublicWorkspace',
          id: params?.id,
        },
      ],
    }),

    reportPublicIssue: builder.mutation({
      query(payload) {
        const { wsId, attachments = [], ...rest } = payload;
        const url = '/issue';

        const formData = new FormData();

        formData.append('workspace_id', wsId);

        attachments.forEach((file, index) => {
          formData.append(`attachments[${index}][file]`, file?.file);
          formData.append(`attachments[${index}][name]`, file?.file?.name);
        });

        Object.keys(rest).forEach((key) => {
          // used condition to avoid sending as form data empty strings
          if (typeof rest[key] !== 'string' || rest[key]?.length)
            formData.append(key, rest[key]);
        });

        return {
          url,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: 'PublicIssue',
        },
      ],
    }),
  }),
});

export const { useGetPublicWorkspaceQuery, useReportPublicIssueMutation } =
  publicReportIssueWorkspaceApi;
