/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
export const emailReg =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const hasContentWithLength = (length) => (value) =>
  value && value.length >= length;
export const hasContent = (value) => value && value.length > 0;

export const isEmail = (value) => emailReg.test(value.trim());

export const emailLength = (value) => value && value.length < 1;

export const stringValidator = (value) => {
  if (!hasContent(value)) {
    return { noContent: true };
  }

  return null;
};

export const stringValidatorWithLength = (length) => (value) => {
  if (!hasContent(value)) {
    return { noContent: true };
  }

  if (!hasContentWithLength(length)(value)) {
    return { notLongEnough: true };
  }

  return null;
};

export const passwordConfirmationChecker = (password, passwordConfirm) => {
  if (!hasContent(passwordConfirm)) {
    return { noContent: true };
  }
  if (password !== passwordConfirm) {
    return { notSameAsPassword: true };
  }
};

export const emailValidator = (value, users) => {
  if (value.length < 1) {
    return { noEmailContent: true };
  } else if (!isEmail(value)) {
    return { notEmail: true };
  } else if (isAlreadyAdded(value, users)) {
    return { emailAlreadyAdded: true };
  } else {
    return null;
  }
};

export const arrayValidator = (desiredLength) => (value) => {
  return !value || value.length === 0 ? value.length >= desiredLength : false;
};

export const isAlreadyAdded = (newEmail, users) =>
  users
    ? users.find((x) => x.email.toLowerCase() === newEmail.toLowerCase())
    : false;

export const phoneValidation = (phone) => phone && phone.match(/[a-z]/i);

export const isValidDomain = (domain) => {
  const pattern = /^[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}$/;

  return pattern.test(domain);
};
