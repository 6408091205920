import { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { vestResolver } from '@hookform/resolvers/vest';
import PropTypes from 'prop-types';
import Modal, { SIZE } from '../../../../../../components_new/Modal';
import { formValidation, getInitFormData } from './utils';
import {
  WowBlock,
  WowLabelText,
} from '../../../../../../components_new/WowBasicElements';
import Input from '../../../../../../components_new/Input';
import Space from '../../../../../../components_new/Space';
import { WowCheckbox } from '../../../../../../components/wow-checkbox';
import { gradientBlue, gray } from '../../../../../../config/colors';
import { CheckboxLabel } from '../styles';
import { useDuplicateIssueMutation } from '../../../service';
import Footer from '../components/Footer';
import WorkspaceItem from '../components/WorkspaceItem';
import CopyCommentsItem from '../components/CopyCommentsItem';
import AssignToUserItem from '../components/AssignToUserItem';

const ModalDuplicate = ({
  isOpen,
  handleClose,
  issue,
  duplicateSuccessCallback,
}) => {
  const intl = useIntl();

  const formMethods = useForm({
    defaultValues: getInitFormData(intl, issue),
    resolver: vestResolver(formValidation),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, control, setValue, formState, watch } = formMethods;
  const { isValid } = formState;
  const canSubmit = isValid;
  const [hackyDropdownPadding, setHackyDropdownPadding] = useState();
  const [duplicateIssue, { isLoading }] = useDuplicateIssueMutation();
  const [availableAssignessIds, setAvailableAssignessIds] = useState();
  const hasComments = issue.number_of_comments > 0;
  const duplicateToSubtask = watch('duplicate_to_subtask');
  const copyInvolvedUsers = watch('copy_involved_users');

  useEffect(() => {
    setValue('workspace_id', duplicateToSubtask ? null : issue.workspace_id);
  }, [duplicateToSubtask, setValue, issue?.workspace_id]);

  useEffect(() => {
    if (issue?.workspace)
      setAvailableAssignessIds(issue.workspace.available_assignees_ids);
  }, [issue?.workspace]);

  const submitHandler = async (data) => {
    const response = await duplicateIssue({
      id: issue.id,
      copy_links: 1,
      ...data,
    });
    if (duplicateSuccessCallback) {
      handleClose();
      duplicateSuccessCallback(response?.data?.issue?.id);
    }
  };

  const handleWorkspaceChange = (workspace, onChange) => {
    const newAvailableAssigneesIds = workspace?.available_assignees_ids || [];

    if (copyInvolvedUsers) {
      setValue(
        'copy_involved_users',
        newAvailableAssigneesIds.some((item) => item === issue.assignee?.id)
      );
    }

    setAvailableAssignessIds(newAvailableAssigneesIds);

    onChange(workspace?.value);
  };

  const hasAssigneeWorkspaceAccess = useMemo(() => {
    return issue?.assignee?.is_public_user
      ? true
      : availableAssignessIds?.some((item) => item === issue.assignee?.id);
  }, [availableAssignessIds, issue]);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'duplicate_issue',
      })}
      isOpen={isOpen}
      onClose={handleClose}
      size={SIZE.small}
      maxHeight="725px"
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(submitHandler)}
          data-testid="modalDuplicate"
        >
          <WowLabelText>
            <FormattedMessage id="title" />
          </WowLabelText>

          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input {...field} data-testid="inputTitle" />
            )}
          />
          {!issue?.parent_id && (
            <>
              <Space height="15" />
              <Controller
                control={control}
                name="duplicate_to_subtask"
                render={({ field }) => (
                  <WowCheckbox
                    label={
                      <CheckboxLabel>
                        {intl.formatMessage({ id: 'duplicate_to_subtask' })}
                      </CheckboxLabel>
                    }
                    {...field}
                    checked={field.value}
                    size={18}
                    borderColor={gradientBlue}
                    data-testid="duplicateToSubtask"
                  />
                )}
              />
            </>
          )}
          <Space height="24" />
          <WowLabelText>
            <FormattedMessage id="workspaces" />
          </WowLabelText>
          <Controller
            name="workspace_id"
            control={control}
            disabled={duplicateToSubtask}
            render={({ field }) => {
              return (
                <WorkspaceItem
                  {...field}
                  onChange={(value) =>
                    handleWorkspaceChange(value, field.onChange)
                  }
                  setHackyDropdownPadding={setHackyDropdownPadding}
                  item={issue}
                />
              );
            }}
          />
          {/* WARNING!
              Be careful if you add any elements in this modal.
              hackyDropdownPadding exists to enlarge the modal
              so that the equipment dropdown is properly shown
          */}
          <Space height={hackyDropdownPadding ? '255' : null} />
          <WowBlock>
            <Space height="34" />
            <Controller
              control={control}
              name="copy_involved_users"
              render={({ field }) => (
                <AssignToUserItem
                  {...field}
                  borderColor={
                    !hasAssigneeWorkspaceAccess ? gray : gradientBlue
                  }
                  disabled={!hasAssigneeWorkspaceAccess}
                />
              )}
            />
          </WowBlock>
          <Space height="5" />
          <Controller
            control={control}
            name="copy_comments"
            render={({ field }) => (
              <CopyCommentsItem
                {...field}
                borderColor={!hasComments ? gray : gradientBlue}
                disabled={!hasComments}
              />
            )}
          />
          <Space height="34" />
          <Footer
            canSubmit={canSubmit}
            isLoading={isLoading}
            handleClose={handleClose}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

ModalDuplicate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  issue: PropTypes.object.isRequired,
};

export default ModalDuplicate;
