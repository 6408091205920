import { FormattedMessage } from 'react-intl';
import Space from '../../../../../components_new/Space';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
} from '../../../../../components_new/WowBasicElements';

const NoItems = () => {
  return (
    <>
      <Space height="150" />
      <WowDisplayFlex direction="column">
        <WowIcon
          size="75"
          className="icon icon-important-round"
          color="#e6edf4"
        />
        <Space height="10" />
        <WowColorizeText color="#456279" size={14}>
          <FormattedMessage id="no_available_data" />
        </WowColorizeText>
      </WowDisplayFlex>
    </>
  );
};

export default NoItems;
