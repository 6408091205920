import styled from 'styled-components/macro';
import { filtersAdvancedGray, newBorder } from '../../../../../config/colors';

export const MainView = styled.div`
  display: flex;
  padding: 0 25px;
  position: relative;
`;

export const HourLabelWrapper = styled.div`
  padding-right: 3px;
  text-align: right;
`;

export const HoursLabel = styled.div`
  color: ${filtersAdvancedGray};
  font-size: 13px;
  font-weight: 600;
  height: ${({ height }) => height + 'px' ?? 'auto'};
  position: relative;
  top: -7px;
  margin-right: 3px;
`;

export const TimelineItemsWrapper = styled.div`
  display: flex;
`;

export const TimelineBordersWrapper = styled.div`
  position: absolute;
  width: 100%;
  border-right: ${({ noBorder }) =>
    noBorder ? 'none' : `1px solid ${newBorder}`};
`;

export const TimlineItemsCardsWrapper = styled.div`
  position: relative;
`;

export const HoursWrapper = styled.div``;

export const TimelineColumnWrapper = styled.div`
  width: 100%;
  position: relative;
`;
