import styled from 'styled-components/macro';
import { darkerGray, gray, hardWhite, newBorder } from '../../config/colors';

const StyledTextArea = styled.textarea`
  font-size: 16px;
  outline: none;
  border: 1px solid ${newBorder};
  color: ${darkerGray};
  padding: 8px 12px;
  width: 100%;
  border-radius: 4px;
  min-height: 74px;
  resize: none;
  font-family: 'nunitosans', 'Roboto', sans-serif;
  background-clip: padding-box;

  :focus {
    background-color: ${hardWhite};
  }

  ::placeholder {
    color: ${gray};
  }

  &:disabled {
    background-color: white;
  }
`;

export { StyledTextArea };
