import * as Tabs from '@radix-ui/react-tabs';
import styled from 'styled-components/macro';
import { WowCheckbox } from '../../../../../../components/wow-checkbox';
import {
  filtersAdvancedGray,
  filtersBlue,
  lightererBlue,
  offWhite,
  darkerGray,
} from '../../../../../../config/colors';

export const TabsTrigger = styled(Tabs.Trigger)`
  position: relative;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 17px;
  padding: 12px 0;
  border-radius: 6px;
  background-color: #e2eaf2;
  flex: 1;
  border: 2px solid ${lightererBlue};
  cursor: pointer;

  &:hover {
    color: ${darkerGray};
    background-color: ${offWhite};
  }

  &[data-state='active'] {
    border: 0px;
    color: white;
    background-color: ${filtersBlue};
    &::after {
      content: '';
      position: absolute;
      top: 99%;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${filtersBlue};
    }
  }
`;

export const FormBody = styled.div``;

export const TabsList = styled(Tabs.List)`
  display: flex;
  justify-content: space-between;
`;

export const MediaPhotoWrapperLabel = styled.label`
  position: relative;
`;

export const MediaPhotoWrapper = styled.div`
  position: relative;
`;

export const Checkbox = styled(WowCheckbox)`
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 10;
`;

export const MediaPhotoOverlay = styled.div`
  opacity: 0.3;
  position: absolute;
  inset: 0;
  background-color: white;
  width: 246px;
  height: 152px;
  border-radius: 6px;
  z-index: 5;
`;

export const MediaDescriptionOverlay = styled(MediaPhotoOverlay)`
  width: 179px;
`;

export const MediaPhoto = styled.img`
  width: 246px;
  height: 152px;
  border-radius: 6px;
  object-fit: cover;
`;

export const MediaRow = styled.div`
  display: flex;
  ${({ isLast }) =>
    !isLast
      ? `
      border-bottom: 1px solid #DCE2E8;
      padding-bottom: 5px;
      margin-bottom: 11px;
    `
      : ''}
`;

export const MediaDescription = styled.div`
  width: 179px;
  height: 80px;
  padding-left: 36px;
  font-size: 13px;
  overflow: hidden;
  line-height: 1.5;
  color: ${filtersAdvancedGray};
`;

export const InputRow = styled.div`
  margin: 17px 0;
`;

export const CheckboxLabel = styled.span`
  color: ${darkerGray};
  margin-left: 12px;
  position: relative;
  bottom: 3.7px;
`;

export const EmailRow = styled(InputRow)`
  padding-top: 8px;
  padding-bottom: 5px;
`;
