import React from 'react';
import styled from 'styled-components/macro';
import { gray, newBorder, purple500 } from '../../config/colors';

const NotificationsTabs = ({
  tabs,
  activeTab = 0,
  onTabClick,
  customStyles,
}) => {
  const renderTabs = () =>
    tabs.map(({ icon, text }, i) => {
      const isActive = activeTab === i;
      return (
        <TabContainer
          key={i}
          onClick={(e) => {
            onTabClick(i);
          }}
          isActive={isActive}
        >
          {icon && <Icon className={`icon icon-${icon}`} isActive={isActive} />}
          {icon && text ? <Space /> : null}
          {text && <Text isActive={isActive}>{text}</Text>}
        </TabContainer>
      );
    });

  return <Container style={customStyles}>{renderTabs()}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${newBorder};
`;
const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 11px;
  cursor: pointer;
  flex: 1;
  ${({ isActive }) =>
    isActive &&
    `
  border-bottom:2px solid ${purple500};
  margin-bottom: -1px;
  padding-bottom: 10px;
  `};
`;

const Icon = styled.i`
  color: ${({ isActive }) => (isActive ? purple500 : gray)};
  font-size: 14px;
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? purple500 : gray)};
  text-transform: capitalize;
`;

const Space = styled.div`
  width: 5px;
`;

export default NotificationsTabs;
