import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Tabs from '@radix-ui/react-tabs';
import { filtersAdvancedGray } from '../../../../../config/colors';
import { UNSCHEDULED_FILTERS_TABS } from './constants';
import { WowIcon } from '../../../../../components_new/WowBasicElements';
import {
  Wrapper,
  Title,
  TabsList,
  TabsTrigger,
  HeaderWrapper,
  OuterWrapper,
} from './styles';
import { useDispatch } from 'react-redux';
import { updateUnscheduledMenuOpen } from '../../slice';
import useElementDimensions from '../../../../../hooks/useElementDimensions';

const UnscheduledSidebar = ({ noDateHookData = {}, overdueHookData = {} }) => {
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(
    UNSCHEDULED_FILTERS_TABS.NO_DATE.key
  );

  const handleUnscheduledMenuOpen = () =>
    dispatch(updateUnscheduledMenuOpen(false));

  const { height, elRef: sidabarRef } = useElementDimensions();
  const scrollMaxHeight =
    height -
    (window.innerHeight > 1700
      ? window.innerHeight / 1.7
      : window.innerHeight > 1200
      ? window.innerHeight / 1.85
      : window.innerHeight > 1150
      ? 410
      : window.innerHeight > 1100
      ? 350
      : window.innerHeight > 900
      ? 270
      : window.innerHeight > 800
      ? 215
      : 215);

  return (
    <OuterWrapper data-testid="unscheduledWrapper">
      <Wrapper ref={sidabarRef}>
        <HeaderWrapper>
          <Title>
            <FormattedMessage id="drag_to_calendar" />
          </Title>
          <WowIcon
            className="icon-close"
            color={filtersAdvancedGray}
            size="18"
            spaceRight="0"
            spaceLeft="0"
            style={{ cursor: 'pointer' }}
            onClick={handleUnscheduledMenuOpen}
            data-testid="closeButton"
          />
        </HeaderWrapper>
        <Tabs.Root
          value={currentTab}
          onValueChange={(e) => setCurrentTab(e)}
          orientation="vertical"
        >
          <TabsList>
            <TabsTrigger value={UNSCHEDULED_FILTERS_TABS.NO_DATE.key}>
              <FormattedMessage
                id={UNSCHEDULED_FILTERS_TABS.NO_DATE.titleKey}
              />
            </TabsTrigger>
            <TabsTrigger value={UNSCHEDULED_FILTERS_TABS.OVERDUE.key}>
              <FormattedMessage
                id={UNSCHEDULED_FILTERS_TABS.OVERDUE.titleKey}
              />
            </TabsTrigger>
          </TabsList>
          <Tabs.Content value={UNSCHEDULED_FILTERS_TABS.NO_DATE.key}>
            {currentTab === UNSCHEDULED_FILTERS_TABS.NO_DATE.key
              ? UNSCHEDULED_FILTERS_TABS.NO_DATE.tabComponent({
                  noDateHookData,
                  scrollMaxHeight,
                })
              : null}
          </Tabs.Content>

          <Tabs.Content value={UNSCHEDULED_FILTERS_TABS.OVERDUE.key}>
            {currentTab === UNSCHEDULED_FILTERS_TABS.OVERDUE.key
              ? UNSCHEDULED_FILTERS_TABS.OVERDUE.tabComponent({
                  overdueHookData,
                  scrollMaxHeight,
                })
              : null}
          </Tabs.Content>
        </Tabs.Root>
      </Wrapper>
    </OuterWrapper>
  );
};

export default UnscheduledSidebar;
