export const PRIORITY_VALUES = {
  URGENT: '5',
  HIGH: '4',
  NORMAL: '3',
  LOW: '2',
};

export const PRIORITY_ITEMS = [
  {
    id: 1,
    value: 'all',
    labelKey: 'all',
  },
  {
    id: 2,
    value: PRIORITY_VALUES.URGENT,
    labelKey: 'urgent',
  },
  {
    id: 3,
    value: PRIORITY_VALUES.HIGH,
    labelKey: 'high',
  },
  {
    id: 4,
    value: PRIORITY_VALUES.NORMAL,
    labelKey: 'normal',
  },
  {
    id: 5,
    value: PRIORITY_VALUES.LOW,
    labelKey: 'low',
  },
];

export const FILTER_KEY = 'priority_ids';
