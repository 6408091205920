import Collapsible from '../../../Collapsible';
import { useMemo } from 'react';
import Item from '../../../Collapsible/Item';

const Position = (props) => {
  const { value, labelKey /* filterKey */ } = props;

  const items = useMemo(() => {
    return value?.split(',').map((item) => ({ value: item, name: item }));
  }, [value]);

  return (
    <Collapsible labelKey={labelKey} count={items?.length ?? 0}>
      {items.map((item) => (
        <Item key={item} item={item} handleItemChange={(item) => {}} />
      ))}
    </Collapsible>
  );
};

export default Position;
