import { createSelector } from '@reduxjs/toolkit';

const getCalendarState = (state) => state.calendar;

export const getFilters = createSelector(
  getCalendarState,
  (state) => state.filters
);

export const getShowIncludeSubWs = createSelector(
  getCalendarState,
  (state) => state.showIncludeSubWs
);

export const getIsUnscheduledMenuOpen = createSelector(
  getCalendarState,
  (state) => state.isUnscheduledMenuOpen
);

export const getActiveView = createSelector(
  getCalendarState,
  (state) => state.activeView
);

export const getMainDraggableFiltersHiddenData = createSelector(
  getCalendarState,
  (state) => {
    const activeView = state.activeView;
    return state?.mainDraggableFilters?.hiddenData?.[activeView] ?? {};
  }
);

export const getMainDraggableFiltersHiddenWeeklyUsersData = createSelector(
  getCalendarState,
  (state) => state?.mainDraggableFilters?.hiddenData?.weeklyUsers ?? {}
);

export const getMainDraggableFiltersHiddenWeeklyWorkspacesData = createSelector(
  getCalendarState,
  (state) => state?.mainDraggableFilters?.hiddenData?.weeklyWorkspaces ?? {}
);

export const getMainDraggableFiltersClickedClearAllButton = createSelector(
  getCalendarState,
  (state) => {
    const activeView = state.activeView;
    return (
      state?.mainDraggableFilters?.clickedClearAllButton?.[activeView] ?? false
    );
  }
);

export const getUnscheduledIssueCurrentDragId = createSelector(
  getCalendarState,
  (state) => state.unscheduledIssueCurrentDragId
);

export const getCurrentActiveViewMoreModal = createSelector(
  getCalendarState,
  (state) => state.currentActiveViewMoreModal
);

export const getTimelineIssueCurrentDragId = createSelector(
  getCalendarState,
  (state) => state.timelineIssueCurrentDragId
);

export const getCurrentActiveIssueDetailsModal = createSelector(
  getCalendarState,
  (state) => state.currentActiveIssueDetailsModal
);

export const getCurrentActiveWeeklyColumnIssueDetailsModal = createSelector(
  getCalendarState,
  (state) => state.currentActiveWeeklyColumnIssueDetailsModal
);

export const getTimelineIssueDraggableElementId = createSelector(
  getCalendarState,
  (state) => state.timelineIssueDraggableElementId
);

export const getLastDailyDate = createSelector(
  getCalendarState,
  (state) => state.lastDailyDate
);

export const getModalInfo = createSelector(
  getCalendarState,
  (state) => state.modalInfo
);

export const getActionItemInfo = createSelector(
  getCalendarState,
  (state) => state.actionItemInfo
);
