import { breakPoints, DEFAULT_LANG, MOBILE_OS } from '../config/config';
import {
  verifyLanguage,
  YU_LANGS_WITH_ALIAS,
} from '../helpers/multilang.helper';
import dayjs from 'dayjs';
import { formatDistance } from 'date-fns';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import hr from 'date-fns/locale/hr';
import tr from 'date-fns/locale/tr';
import pl from 'date-fns/locale/pl';

const mapDateFnsLocale = (incomingLocale) => {
  switch (incomingLocale) {
    case 'en':
      return en;
    case 'hr':
      return hr;
    case 'tr':
      return tr;
    case 'pl':
      return pl;
    default:
      return de;
  }
};

export const isTabletWidth = (width) =>
  width >= breakPoints.tabletWidth && width <= breakPoints.desktopWidth;

export const isMobileWidth = (width) => width < breakPoints.tabletWidth;

export const isScreenSizeWidthBelow = (width) =>
  width > (window?.screen?.width || window?.innerWidth);

export const randomString = () => Math.random().toString(36).substring(7);

export const randomPasswordString = () =>
  Math.random().toString(36).substring(2, 10);

export const getDefaultLanguage = () => {
  const savedLanguage = verifyLanguage(localStorage.wowFlowLang);

  // There was a bug previously and some users may have 'undefined' saved as a string
  if (savedLanguage && savedLanguage !== 'undefined') {
    return savedLanguage;
  }
  let language = verifyLanguage(navigator.language || navigator.userLanguage);

  if (!language) {
    localStorage.wowFlowLang = DEFAULT_LANG;
    return DEFAULT_LANG;
  }

  if (YU_LANGS_WITH_ALIAS.includes(language)) {
    language = 'hr';
  }

  localStorage.wowFlowLang = language;

  return language;
};

const allWhitespace = /\s/g;
export const valueToDataTest = (value, alternative) =>
  typeof value !== 'undefined'
    ? value.slice(0, 10).replace(allWhitespace, '')
    : alternative;

export const downloadImage = async (imageUrl, imageName) => {
  const responseBlob = await fetch(imageUrl).then((resp) => resp.blob());

  return initiateDownload(responseBlob, imageName);
};
export const initiateDownload = (fileBlob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const sortByProp = (prop) => (a, b) => {
  if (a[prop] > b[prop]) {
    return 1;
  } else if (a[prop] < b[prop]) {
    return -1;
  }

  return 0;
};

export const transformAccessibilityOptions = (accessibilities, intl) => {
  if (!accessibilities) return [];

  return Object.values(accessibilities).map((acc) => ({
    id: acc.id,
    name: intl.formatMessage({
      id: acc.key,
      defaultMessage: acc.key,
    }),
  }));
};

// Converts date to timestamp in seconds
export const dateToTimestamp = (date) => {
  if (!date) return undefined;

  const timestamp = date.getTime() / 1000;

  return timestamp;
};

// Converts timestamp (in seconds) to date
export const timestampToDate = (timestamp) => {
  return timestamp ? new Date(timestamp * 1000) : undefined;
};

export const isDateBeforeToday = (date) =>
  dayjs().isAfter(timestampToDate(date));

export const arrayValueToObject = (arrayValue) => {
  return Object.fromEntries(
    arrayValue.map((eq) => {
      return [eq.id, eq];
    })
  );
};

export const truncateParentWs = (word = '') => {
  if (word.length <= 10) return word;
  return word.substring(0, 7) + '...';
};

export const customFormatDuration = (date, intl) => {
  const start = Number(date);
  const end = Date.now();

  const locale = mapDateFnsLocale(intl.locale);

  const distanceWords = formatDistance(end, start, {
    locale,
  });

  if (intl.locale === 'de' || intl.locale === 'hr') {
    return intl.formatMessage({ id: 'ago' }) + ' ' + distanceWords;
  } else {
    return distanceWords + ' ' + intl.formatMessage({ id: 'ago' });
  }
};

// Stolen from https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) return MOBILE_OS.WINDOWS_PHONE;

  if (/android/i.test(userAgent)) return MOBILE_OS.ANDROID;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    return MOBILE_OS.IOS;

  return MOBILE_OS.UNKNOWN;
};

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

// used as hack to remove search items from dropdown
// TODO: Consider better approach for places where is used
export const filterWithSearch = ({ items = [], search = '' }) => {
  // return items when there's no search
  if (!search) return items;

  const flattenedItems = items.reduce((acc, curr) => {
    if (!acc.some((item) => item.id === curr.id)) acc.push(curr);

    // used to avoid duplicates
    for (const child of curr.children || []) {
      const included = acc.some((item) => item.id === child.id);

      if (!included) {
        acc.push(child);
      }
    }
    return acc;
  }, []);

  const filteredItems = flattenedItems.filter((item) => {
    const match =
      (item.label || item.name).toLowerCase().includes(search.toLowerCase()) ||
      (item.reference_number || '')
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      String(item.id || 0) === search;

    return match;
  });

  return filteredItems;
};
