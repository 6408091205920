import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDAR_CARD_TYPES } from '../../../CalendarTimelineCard/constants';

import IssueDetailsModal from '../../../IssueDetailsModal';

import CalendarTimelineCard from '../../../CalendarTimelineCard';
import {
  getCurrentActiveIssueDetailsModal,
  getCurrentActiveWeeklyColumnIssueDetailsModal,
} from '../../../../selectors';
import {
  setCurrentActiveIssueDetailsModal,
  setCurrentActiveViewMoreModal,
  setCurrentActiveWeeklyColumnIssueDetailsModal,
  setTimelineIssueDraggableElementId,
} from '../../../../slice';
import ViewMoreModal from '../../../ViewMoreModal';
import { formatTimestampToDateAndMonth } from '../../../../../../../helpers/date.helper';
import { getPositionValues } from './utils';
import { NodesWrapper } from './styles';

const CalendarTimelineCardWrapper = (props) => {
  const {
    timelineData = [],
    handleUpdateDataItem = () => {},
    handleDeleteDataItem = () => {},
    removeItemsFromUnscheduledSidebar = () => {},
    draggableTimelineIssueRevertData,
    startDayTime,
    startDayTimes,
    fullDayItemsLength,
    index,
    totalColumns,
  } = props;

  const dispatch = useDispatch();
  const [presetModalValue, setPresetModalValue] = useState(0);
  const columnIndex = index;

  const currentActiveIssueDetailsModal = useSelector(
    getCurrentActiveIssueDetailsModal
  );
  const currentActiveWeeklyColumnIssueDetailsModal = useSelector(
    getCurrentActiveWeeklyColumnIssueDetailsModal
  );

  const handleToggleDetailsComponent = ({
    id,
    isSetup,
    presetTopValue,
    weeklyDndUpdateDropCancelTrigger,
    skipToggle,
  }) => {
    setPresetModalValue(0);
    dispatch(
      setCurrentActiveIssueDetailsModal(
        skipToggle ? id : currentActiveIssueDetailsModal === id ? null : id
      )
    );
    dispatch(
      setCurrentActiveWeeklyColumnIssueDetailsModal(
        skipToggle
          ? index
          : currentActiveWeeklyColumnIssueDetailsModal === index
          ? null
          : index
      )
    );
    if (isSetup) handleDeleteDataItem(id);
    else dispatch(setTimelineIssueDraggableElementId(id));
    if (presetTopValue) setPresetModalValue(presetTopValue);
    dispatch(setCurrentActiveViewMoreModal(null));
    if (draggableTimelineIssueRevertData?.current) {
      const existingData = timelineData.find((item) => item.id === id);
      if (existingData)
        handleUpdateDataItem(
          {
            id,
            ...existingData.data,
            ...draggableTimelineIssueRevertData.current,
          },
          { weeklyDndUpdateDropCancelTrigger }
        );
    }
  };

  return timelineData.map((data, index) => {
    const issueDetailsTopValue = presetModalValue - (data?.top ?? 0);
    const nodes = data?.nodes;

    const getCard = (data = {}) => (
      <CalendarTimelineCard
        type={
          data?.data?.box
            ? CALENDAR_CARD_TYPES.ISSUE_BOX.key
            : CALENDAR_CARD_TYPES.ISSUE.key
        }
        key={data.id ?? index}
        left={data?.left ?? 0}
        width={data?.width ?? 0}
        fullDayItemsLength={fullDayItemsLength}
        height={data?.height ?? 'auto'}
        top={data?.top ?? 0}
        item={data?.data}
        showLeftValueInPercentage={data?.showLeftValueInPercentage ?? false}
        showWidthValueInPercentage={data?.showWidthValueInPercentage ?? false}
        dataAttributeId={data?.id ?? null}
        id={data?.id}
        titleMaxLength={data?.titleMaxLength}
        workspaceTitleMaxLength={data?.workspaceTitleMaxLength}
        hours={data?.hours}
        allowHoverDetailsTrigger={false}
        detailsComponent={
          data?.data?.box ? (
            <ViewMoreModal
              onClose={handleToggleDetailsComponent}
              {...getPositionValues(columnIndex, totalColumns)}
              topValue={
                issueDetailsTopValue < 100 ? 0 : `${issueDetailsTopValue}px`
              }
              items={data?.data?.issues ?? []}
              handleUpdateDataItem={handleUpdateDataItem}
              handleDeleteDataItem={handleDeleteDataItem}
              isWeeklyBoxModal={true}
              yearString={
                startDayTime ? new Date(startDayTime).getFullYear() : null
              }
              dateString={
                startDayTime
                  ? formatTimestampToDateAndMonth(startDayTime, 'eng')
                  : null
              }
              startDayTimes={startDayTimes}
            />
          ) : (
            <IssueDetailsModal
              id={data?.id}
              overrideData={{ ...(data?.data ?? {}) }}
              isSetup={data?.data?._setup}
              onClose={handleToggleDetailsComponent}
              handleUpdateDataItem={handleUpdateDataItem}
              handleDeleteDataItem={handleDeleteDataItem}
              removeItemsFromUnscheduledSidebar={
                removeItemsFromUnscheduledSidebar
              }
              isScheduledPreparedTask={data?.data?.is_scheduled_prepared_task}
              draggableTimelineIssueRevertData={
                draggableTimelineIssueRevertData
              }
              weeklyDndUpdateDropCancelTrigger={true}
              topValue={
                issueDetailsTopValue < 100 ? 0 : `${issueDetailsTopValue}px`
              }
              {...getPositionValues(columnIndex, totalColumns)}
            />
          )
        }
        showDetailsComponent={
          currentActiveIssueDetailsModal === data.id &&
          currentActiveWeeklyColumnIssueDetailsModal === columnIndex
            ? true
            : false
        }
        handleToggleDetailsComponent={handleToggleDetailsComponent}
        draggableTimelineIssueRevertData={draggableTimelineIssueRevertData}
      />
    );

    return (
      <NodesWrapper>
        {getCard(data)}
        {nodes &&
          nodes?.length !== 0 &&
          nodes.map((data) => (
            <>
              {getCard(data)}
              {data?.nodes &&
                data?.nodes?.length !== 0 &&
                data.nodes.map((data) => getCard(data))}
            </>
          ))}
      </NodesWrapper>
    );
  });
};

export default CalendarTimelineCardWrapper;
