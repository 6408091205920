import { FormattedMessage, useIntl } from 'react-intl';
import useSave from './hooks/useSave';
import Modal from '../../../../../Modal';
import { WowLabelText } from '../../../../../WowBasicElements';
import { Asterisk } from '../../../../styles';
import Input from '../../../../../Input';
import { INPUT_MAX_LENGTH } from '../../constants';
import Space from '../../../../../Space';
import TextArea from '../../../../../TextArea';
import { FiltersWrapper } from '../../styles';
import ModalFooterWrapper from '../../../../../ModalFooterWrapper';

const ModalSave = ({ isOpen }) => {
  const intl = useIntl();

  const {
    handleClose,
    name,
    setName,
    handleSave,
    isLoading,
    description,
    setDescription,
    filters,
    renderFilter,
  } = useSave({ isOpen });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={intl.formatMessage({ id: 'save_filter' })}
      maxHeight="750px"
      dataTestId="modalSaveFilters"
      contentStyleOverride={{ overflow: 'visible' }}
    >
      <WowLabelText bold>
        <FormattedMessage id="name" />
        <Asterisk>&#42;</Asterisk>
      </WowLabelText>
      <Input
        enableFocus={false}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        maxLength={INPUT_MAX_LENGTH}
        name="name"
      />
      <Space height="30" />
      <WowLabelText bold>
        <FormattedMessage id="description" />
      </WowLabelText>
      <TextArea
        onChange={(e) => setDescription(e.target.value)}
        value={description}
        maxLength={INPUT_MAX_LENGTH}
      />
      <Space height="20" />
      <FiltersWrapper>{filters.map(renderFilter)}</FiltersWrapper>
      <Space height="30" />
      <ModalFooterWrapper
        handleAction={handleSave}
        isLoading={isLoading}
        handleCancel={handleClose}
        isActionDisabled={!name?.length}
        actionButtonKey="save"
      />
    </Modal>
  );
};

export default ModalSave;
