import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './menu-item.component.scss';
import styled from 'styled-components/macro';
import { themeColour } from '../../../helpers/theme';
import { hardWhite } from '../../../config/colors';

const SelectedIcon = styled.i`
  color: ${themeColour};
`;
const MenuListItem = styled.li`
  height: ${(props) => (props.extraHigh ? '50px' : '40px')};
  &:hover {
    background-color: ${hardWhite};
  }
`;
const MenuItemIcon = styled.i`
  color: ${themeColour};

  ${({ rotate }) =>
    rotate &&
    `
    transform: rotate(90deg);
  `}
`;
const ItemText = styled.span`
  color: ${themeColour};
`;
export const MenuItem = (props) => {
  const itemClassses = classnames('menu-item', {
    [props.className]: props.className,
    'extra-high': props.extraHigh,
    selected: props.isSelected,
  });
  const iconClasses = classnames('icon menu-item-icon', {
    [props.icon]: props.icon,
  });
  const textClasses = classnames('', {
    ellipsis: !props.showCompleteText,
    'break-text': props.showCompleteText,
  });
  const handleItemSelect = (event) => {
    // In the future we will not need event,
    // but I will leave it for now because of backward compatibility.
    props.onChangeFilter(event, props.itemKey, props.text);
  };
  const selectedIconClasses = props.isSelected ? 'icon icon-check' : '';
  const onClick = props.isFilterComponent
    ? handleItemSelect
    : () => props.onRedirect && props.onRedirect({ link: props.linkTo });

  return (
    <MenuListItem
      noTheme={props.noThemeText}
      extraHigh={props.extraHigh}
      className={itemClassses}
      onClick={onClick}
    >
      {props.icon ? (
        <MenuItemIcon
          secondaryAccent={props.secondaryAccent}
          red={props.red}
          noTheme={props.noTheme}
          className={iconClasses}
          rotate={props.rotate}
        />
      ) : null}
      <ItemText
        className={textClasses}
        mainAccent={props.noThemeText ? null : props.mainAccent}
        red={props.noThemeText ? null : props.red}
        noTheme={!!props.noThemeText}
      >
        {props.text}
      </ItemText>
      <SelectedIcon
        className={selectedIconClasses}
        mainAccent={props.mainAccent}
        red={props.red}
        noTheme={props.noTheme}
      />
    </MenuListItem>
  );
};

MenuItem.propTypes = {
  className: PropTypes.string,
  isFilterComponent: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  onRedirect: PropTypes.func,
  text: PropTypes.string,
  itemKey: PropTypes.any,
  icon: PropTypes.string,
  color: PropTypes.string,
  extraHigh: PropTypes.bool,
  showCompleteText: PropTypes.bool,
};

MenuItem.defaultProps = {
  className: '',
  isFilterComponent: false,
  onChangeFilter: undefined,
  text: '',
  extraHigh: false,
  showCompleteText: false,
};
