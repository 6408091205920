import React from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { blackThatWeNeedToName, outline, white } from '../../config/colors';
import { transformColor, transformHoverColor } from './util';

const VARIANT = {
  default: 'default',
  menuItem: 'menu-item',
  secondaryAction: 'secondary-action',
  containedButton: 'contained-button',
};

const menuItemButtonStyles = css`
  border-radius: 0px;
  padding: 12px 24px;
  width: 100%;
  border-bottom: 1px solid ${outline};

  &:last-of-type {
    border-bottom: none;
  }
`;

const getSecondaryActionStyles = (textColor) => css`
  letter-spacing: 0px;
  font-size: 14px;
  color: ${transformColor(textColor)};
`;

const menuItemIconStyles = css`
  margin-right: 24px !important;
`;

const containedButton = css`
  border-radius: 40px;
  background-color: ${transformColor()};
  font-size: 13px;
  padding: 6px 24px;
  color: ${white};
  letter-spacing: normal;

  :hover {
    background-color: ${transformHoverColor()};
  }
  :focus {
    background-color: ${transformColor()};
  }
`;

const getButtonVariantStyles = (variant, textColor) => {
  switch (variant) {
    case VARIANT.menuItem:
      return menuItemButtonStyles;
    case VARIANT.secondaryAction:
      return getSecondaryActionStyles(textColor);
    case VARIANT.containedButton:
      return containedButton;
    default:
      return null;
  }
};

const getIconVariantStyles = (variant) => {
  switch (variant) {
    case VARIANT.menuItem:
      return menuItemIconStyles;
    default:
      return null;
  }
};

// Components
const Button = styled.button.attrs({ type: 'button' })`
  font-size: 12px;
  border-radius: 20px;
  padding: 6px 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  white-space: nowrap;

  cursor: pointer;
  letter-spacing: 2px;
  color: ${blackThatWeNeedToName};
  background-color: transparent;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${outline};
  }

  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : null)};

  ${({ variant, textColor }) => getButtonVariantStyles(variant, textColor)}
`;

const Icon = styled.i.attrs(({ icon }) => ({ className: `icon ${icon}` }))`
  color: ${({ color }) => transformColor(color)} !important;
  margin-right: 6px;
  font-size: ${({ iconSize }) => (iconSize ? `${iconSize}px` : '22px')};

  ${({ variant }) => getIconVariantStyles(variant)}
`;

const ActionButton = forwardRef(
  (
    { onClick, icon, children, color, variant, uppercase, iconSize, ...props },
    ref
  ) => {
    return (
      <Button
        onClick={onClick}
        variant={variant}
        uppercase={uppercase}
        {...props}
        ref={ref}
      >
        {icon ? (
          <Icon
            icon={icon}
            color={color}
            variant={variant}
            iconSize={iconSize}
          />
        ) : null}

        {children}
      </Button>
    );
  }
);

export default ActionButton;
