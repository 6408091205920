export const ACTION_TYPE = 'confirm';
export const ACTION_BUTTON_COLOR = 'green';
export const TITLE_KEY = 'are_you_sure';

export const INPUT_TYPES = {
  SMILEY_SCALE: 'SMILEY_SCALE',
  TEXTAREA: 'TEXTAREA',
  TEN_SCALE: 'TEN_SCALE',
};

export const QUESTIONS = {
  how_much_wowflow_help: {
    key: 'how_much_wowflow_help',
    required: true,
    maximumCharacters: null,
    questionKey: 'survey_helps_at_work',
    inputType: INPUT_TYPES.SMILEY_SCALE,
    labels: {
      left: 'not_at_all',
      right: 'yes_a_lot',
    },
  },
  how_easy_is_wowflow: {
    key: 'how_easy_is_wowflow',
    required: true,
    maximumCharacters: null,
    questionKey: 'survey_easy_start',
    inputType: INPUT_TYPES.SMILEY_SCALE,
    labels: {
      left: 'not_at_all',
      right: 'very_easy',
    },
  },
  would_you_recommend_wowflow: {
    key: 'would_you_recommend_wowflow',
    required: true,
    maximumCharacters: null,
    questionKey: 'survey_recommend_wowflow',
    inputType: INPUT_TYPES.TEN_SCALE,
  },
  note: {
    key: 'note',
    required: false,
    maximumCharacters: 3000,
    questionKey: 'survey_add_to_wowflow',
    inputType: INPUT_TYPES.TEXTAREA,
  },
};
