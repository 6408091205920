import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../redux/utils';
import qs from 'query-string';
import { BASE_URL } from '../../../config/constants';
import { getEntity } from '../../../redux/entities/selectors';
import { entities } from '../../../config/config';
import { flowConfigPath } from '../../../redux/config/selectors';
import { calculateIssuePermissions } from './utils';

export const issuesApi = createApi({
  reducerPath: 'issueApi2',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      if (token) headers.set('Authorization', `Bearer ${token}`);
      headers.set('Accept', `application/json`);

      return headers;
    },
  }),
  tagTypes: [
    'Issue',
    'IssueSubtasks',
    'IssueTimelogs',
    'IssueNotes',
    'IssueActivity',
    'IssueAttachmentComments',
    'IssueChecklists',
    'UserPositions',
  ],
  endpoints: (builder) => ({
    getIssues: builder.query({
      query: (params) => {
        const url = qs.exclude(
          qs.stringifyUrl(
            { url: 'issue/', query: { include_subtasks: 0, ...params } },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['density', 'isAdvancedFilterOpen']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Issue', id })),
              { type: 'Issue', id: 'LIST' },
            ]
          : [{ type: 'Issue', id: 'LIST' }],

      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          dispatch(
            issuesApi.util.updateQueryData('getIssues', query, (issues) => {
              const state = getState();
              const data = issues?.data ?? [];
              const user = state.user.toJS();
              const client = getEntity(state, entities.CLIENT.reduxProp);
              const flowConfig = flowConfigPath(state);

              const dataWithPermissions = data.map((item) => {
                item.fetched_as_main = true;
                const permissions = calculateIssuePermissions({
                  workspace: item.workspace,
                  user,
                  client,
                  flowConfig,
                  issue: item,
                });
                return { ...item, permissions };
              });

              Object.assign(issues, { data: dataWithPermissions });
            })
          );
        } catch (e) {}
      },
    }),
    getIssueSubtasks: builder.query({
      query: (parentId) => {
        const url = qs.stringifyUrl(
          {
            url: 'issue/',
            query: {
              include_subtasks: 1,
              parent_id: parentId,
              page: 1,
              paginate_by: 10,
            },
          },
          { skipEmptyString: true, skipNull: true }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'IssueSubtasks', id })),
              { type: 'IssueSubtasks', id: 'LIST' },
            ]
          : [{ type: 'IssueSubtasks', id: 'LIST' }],
    }),
    updateIssue: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `issue/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, payload) => {
        if (payload?.shouldNotRefetch) return [];
        return [{ type: 'Issue', id: result.id }];
      },
    }),
    deleteIssue: builder.mutation({
      query(id) {
        return {
          url: `issue/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [
        { type: 'Issue', id: 'LIST' },
        { type: 'IssueSubtasks', id: 'LIST' },
      ],
    }),
    duplicateIssue: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `issue/${id}/duplicate`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [
        { type: 'Issue', id: 'LIST' },
        { type: 'IssueSubtasks', id: 'LIST' },
      ],
    }),
    issueQuickComplete: builder.mutation({
      query(ids) {
        return {
          url: `issue/quick_complete`,
          method: 'POST',
          body: {
            ids,
          },
        };
      },
      invalidatesTags: (result) => [
        ...(result.completed?.length > 0
          ? [{ type: 'Issue', id: 'LIST' }]
          : []),
        { type: 'IssueSubtasks', id: 'LIST' },
        ...result.completed.map(({ id }) => ({ type: 'Issue', id })),
      ],
    }),
    issueMultipleArchive: builder.mutation({
      query(ids) {
        return {
          url: `issue/multiple/archive`,
          method: 'POST',
          body: {
            ids,
          },
        };
      },
      invalidatesTags: (result) => [
        ...(result.archived?.length > 0 ? [{ type: 'Issue', id: 'LIST' }] : []),
      ],
    }),
    issueMultipleDelete: builder.mutation({
      query(ids) {
        return {
          url: `issue/multiple/delete`,
          method: 'POST',
          body: {
            ids,
          },
        };
      },
      invalidatesTags: () => [{ type: 'Issue', id: 'LIST' }],
    }),
    issueMultipleDuplicate: builder.mutation({
      query(payload) {
        return {
          url: `issue/multi_duplicate`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: () => [{ type: 'Issue', id: 'LIST' }],
    }),
    issueMultipleReassign: builder.mutation({
      query(payload) {
        return {
          url: `issue/multiple/reassign`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    getUserPositions: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: '/user_positions/fetch', query: { ...params } },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );
        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'UserPositions', id })),
              { type: 'UserPositions', id: 'LIST' },
            ]
          : [{ type: 'UserPositions', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetIssuesQuery,
  useGetIssueSubtasksQuery,
  useUpdateIssueMutation,
  useDeleteIssueMutation,
  useDuplicateIssueMutation,
  useIssueQuickCompleteMutation,
  useIssueMultipleArchiveMutation,
  useIssueMultipleDeleteMutation,
  useIssueMultipleDuplicateMutation,
  useIssueMultipleReassignMutation,
  useGetUserPositionsQuery,
} = issuesApi;
