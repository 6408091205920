import { useMemo, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';

import useConfig from './hooks/useConfig';
import { TYPES } from './constants';
import {
  getActiveView,
  getCurrentActiveViewMoreModal,
  getMainDraggableFiltersHiddenData,
  getCurrentActiveIssueDetailsModal,
} from '../../selectors';

import {
  WowBlock,
  WowDisplayFlex,
} from '../../../../../components_new/WowBasicElements';
import CalendarTimelineCardWrapper from './components/CalendarTimelineCardWrapper';
import TimelinePresenceCards from './components/TimelinePresenceCards';
import HoursLabelBox from './components/HoursLabelBox';

import {
  Wrapper,
  MainView,
  MainViewLeft,
  MainViewRight,
  TopTimelineLabel,
  HoursLabel,
  HoursBorder,
  TimelineItemsWrapper,
  TimelineBordersWrapper,
  TimlineItemsCardsWrapper,
  TimelinePresenceCardsWrapper,
  MainViewWrapper,
  HeaderWrapper,
  HeaderLabelWrapper,
  ViewMoreCardsWrapper,
} from './styles';
import CalendarMainScrollableContainer from '../CalendarMainScrollableContainer';
import { newBorder, darkerGray } from '../../../../../config/colors';
import { CALENDAR_VIEW_TYPES } from '../../constants';
import ViewMoreCard from './components/ViewMoreCard';
import ViewMoreModal from '../ViewMoreModal';
import { setCurrentActiveViewMoreModal } from '../../slice';

const DailyTimeline = (props) => {
  const {
    timelineWidthPx = 2700,
    defaultFullTimelineHeight = 200,
    type = TYPES.USERS.key,
    data = [],
    startDayTime,
    handleUpdateDataItem = () => {},
    handleDeleteDataItem = () => {},
    isUninitializedData,
    removeItemsFromUnscheduledSidebar = () => {},
    draggableTimelineIssueRevertData,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const hiddenData = useSelector(getMainDraggableFiltersHiddenData);
  const activeView = useSelector(getActiveView);
  const currentActiveIssueDetailsModal = useSelector(
    getCurrentActiveIssueDetailsModal
  );
  const mainViewRightElem = useRef();
  const additionalTimelineHeight = 470; //Added if issue details modal is open so it's not cutted off.
  const additionalTimelineHeightViewMoreModal = 300; //Added if view more modal is open so it's not cutted off.
  const wsPresenceAdditionalTimelineHeight = 30;
  const viewMoreCardAdditionalTimelineHeight = 30;

  const {
    eachHourWidthPxBox,
    timelineHours,
    mappedCalendarData,
    currentTimeMarkerPosition,
  } = useConfig({
    timelineWidthPx,
    data,
    startDayTime,
    activeView,
  });

  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const value = (currentTimeMarkerPosition ?? 0) / 1.43;
    setScrollLeft(value);
    // Since we want this only to be on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mainViewRight;
    const scrollHandler = (e) => setScrollLeft(e?.target?.scrollLeft ?? 0);

    window.setTimeout(() => {
      mainViewRight = mainViewRightElem.current;
      if (mainViewRight)
        mainViewRight.addEventListener('scroll', scrollHandler);
    }, 0);

    return () => {
      if (mainViewRight)
        mainViewRight.removeEventListener('scroll', scrollHandler);
    };
  }, [mappedCalendarData?.length, hiddenData]);

  useEffect(() => {
    if (mainViewRightElem.current) {
      mainViewRightElem.current.scrollLeft = scrollLeft;
    }
  }, [scrollLeft, hiddenData]);

  const hoursSections = useMemo(() => {
    const elems = [];
    for (let i = 0; i < timelineHours; i++) {
      elems.push(
        <HoursLabel
          key={i}
          width={i === timelineHours ? 'auto' : eachHourWidthPxBox}
        >
          {i}h
        </HoursLabel>
      );
    }
    return elems;
  }, [timelineHours, eachHourWidthPxBox]);

  const hoursBorderSections = useMemo(() => {
    const elems = [];
    for (let i = 0; i < timelineHours; i++) {
      elems.push(
        <HoursBorder
          key={i}
          noBorder={i === 0 || i === timelineHours}
          width={i === timelineHours ? 'auto' : eachHourWidthPxBox}
        />
      );
    }
    return elems;
  }, [timelineHours, eachHourWidthPxBox]);

  const { finalData, notEmpty } = useMemo(() => {
    const finalData = [];
    let notEmpty = false;

    mappedCalendarData.forEach((data) => {
      if (hiddenData?.[data?.cardData?.id]?.hide) return;
      notEmpty = true;
      finalData.push(data);
    });

    return {
      finalData,
      notEmpty,
    };
  }, [mappedCalendarData, hiddenData]);

  const currentActiveViewMoreModal = useSelector(getCurrentActiveViewMoreModal);
  const [
    currentActiveViewMoreModalLeftValue,
    setCurrentActiveViewMoreModalLeftValue,
  ] = useState(0);
  const handleSetCurrentActiveViewMoreModal = (id = null) => {
    dispatch(setCurrentActiveViewMoreModal(id));
    const containerScrollLeft =
      document.getElementsByClassName('ScrollbarsCustom-Scroller')?.[0]
        ?.scrollLeft ?? 0;
    if (id) setCurrentActiveViewMoreModalLeftValue(containerScrollLeft);
    else setCurrentActiveViewMoreModalLeftValue(0);
  };

  const showWsPresence = activeView === CALENDAR_VIEW_TYPES.dailyUsers;

  return (
    <MainView>
      {notEmpty ? (
        <>
          <HeaderWrapper>
            <div>
              <WowBlock style={{ width: 150 }}>
                <TopTimelineLabel>
                  {type && TYPES[type]
                    ? intl.formatMessage({ id: TYPES[type].cardTitle })
                    : ''}
                </TopTimelineLabel>
              </WowBlock>
            </div>
            <HeaderLabelWrapper>
              <div>
                <CalendarMainScrollableContainer
                  childrenWidth={timelineWidthPx}
                  scrollLeft={scrollLeft}
                  setScrollLeft={setScrollLeft}
                />
              </div>
              <div>
                <HoursLabelBox
                  hoursSections={hoursSections}
                  currentTimeMarkerPosition={currentTimeMarkerPosition}
                  scrollLeft={scrollLeft}
                  setScrollLeft={setScrollLeft}
                />
              </div>
            </HeaderLabelWrapper>
          </HeaderWrapper>
          <MainViewWrapper>
            <MainViewLeft>
              {finalData.map((data, index) => {
                let height =
                  data?.timelineFullHeight ?? defaultFullTimelineHeight;
                if (
                  data?.timelineData?.find(
                    (item) => item.id === currentActiveIssueDetailsModal
                  ) &&
                  (finalData?.length <= 3 ||
                    index === finalData.length - 1 ||
                    index === finalData.length - 2)
                )
                  height += additionalTimelineHeight;

                if (
                  data?.cardData?.id === currentActiveViewMoreModal &&
                  finalData?.length === 1
                )
                  height += additionalTimelineHeightViewMoreModal;

                if (showWsPresence)
                  height += wsPresenceAdditionalTimelineHeight;

                if (data?.showViewMoreCard)
                  height += viewMoreCardAdditionalTimelineHeight;

                return (
                  <WowBlock
                    data-testid="calendarIssueTimelineRow"
                    key={data?.cardData?.id ?? index}
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingRight: 5,
                      borderTop: `1px solid ${newBorder}`,
                      height,
                    }}
                  >
                    {type && TYPES[type] ? (
                      TYPES[type].cardComponent(data?.cardData)
                    ) : (
                      <></>
                    )}
                  </WowBlock>
                );
              })}
            </MainViewLeft>
            <MainViewRight ref={mainViewRightElem}>
              <Wrapper width={`${timelineWidthPx}px`}>
                <TimelineItemsWrapper
                  style={{ width: timelineWidthPx, overflow: 'hidden' }}
                >
                  {finalData.map((data, index) => {
                    const showViewMoreCard = data?.showViewMoreCard;
                    const showDetailsComponent =
                      currentActiveViewMoreModal === data?.cardData?.id;
                    let height =
                      data?.timelineFullHeight ?? defaultFullTimelineHeight;
                    if (
                      data?.timelineData?.find(
                        (item) => item.id === currentActiveIssueDetailsModal
                      ) &&
                      (finalData?.length <= 3 ||
                        index === finalData.length - 1 ||
                        index === finalData.length - 2)
                    )
                      height += additionalTimelineHeight;

                    if (showDetailsComponent && finalData?.length === 1)
                      height += additionalTimelineHeightViewMoreModal;

                    if (showWsPresence)
                      height += wsPresenceAdditionalTimelineHeight;

                    if (showViewMoreCard)
                      height += viewMoreCardAdditionalTimelineHeight;

                    return (
                      <div
                        style={{ height, position: 'relative' }}
                        key={data?.cardData?.id ?? index}
                      >
                        <>
                          {showWsPresence && (
                            <TimelinePresenceCardsWrapper>
                              <TimelinePresenceCards
                                data={data?.presenceTimelineData ?? []}
                                startDayTime={startDayTime}
                              />
                            </TimelinePresenceCardsWrapper>
                          )}
                          <Droppable
                            droppableId={`timelineDropableId-${data?.cardData?.id}`}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{ height: '100%' }}
                              >
                                <TimelineBordersWrapper>
                                  {hoursBorderSections}
                                </TimelineBordersWrapper>
                                <TimlineItemsCardsWrapper
                                  top={
                                    showWsPresence &&
                                    wsPresenceAdditionalTimelineHeight
                                      ? wsPresenceAdditionalTimelineHeight
                                      : 0
                                  }
                                  showBottomBorder={!showWsPresence}
                                >
                                  <CalendarTimelineCardWrapper
                                    timelineData={data.timelineData}
                                    type={type}
                                    handleUpdateDataItem={handleUpdateDataItem}
                                    handleDeleteDataItem={handleDeleteDataItem}
                                    removeItemsFromUnscheduledSidebar={
                                      removeItemsFromUnscheduledSidebar
                                    }
                                    draggableTimelineIssueRevertData={
                                      draggableTimelineIssueRevertData
                                    }
                                  />
                                </TimlineItemsCardsWrapper>
                              </div>
                            )}
                          </Droppable>
                          {showViewMoreCard && (
                            <ViewMoreCardsWrapper
                              bottom={viewMoreCardAdditionalTimelineHeight ?? 0}
                            >
                              <ViewMoreCard
                                id={data?.cardData?.id}
                                width={timelineWidthPx}
                                items={data?.viewMoreCardItems ?? []}
                                showDetailsComponent={showDetailsComponent}
                                onClick={handleSetCurrentActiveViewMoreModal}
                                detailsComponent={
                                  <ViewMoreModal
                                    id={data?.cardData?.id}
                                    items={data?.viewMoreCardItems ?? []}
                                    onClose={
                                      handleSetCurrentActiveViewMoreModal
                                    }
                                    leftValue={
                                      currentActiveViewMoreModalLeftValue
                                    }
                                    bottom={0}
                                    handleUpdateDataItem={handleUpdateDataItem}
                                    handleDeleteDataItem={handleDeleteDataItem}
                                  />
                                }
                              />
                            </ViewMoreCardsWrapper>
                          )}
                        </>
                      </div>
                    );
                  })}
                </TimelineItemsWrapper>
              </Wrapper>
            </MainViewRight>
          </MainViewWrapper>
        </>
      ) : !isUninitializedData ? (
        <WowDisplayFlex
          width="100%"
          justify="center"
          style={{ color: darkerGray, marginTop: 300 }}
        >
          {intl.formatMessage({ id: 'no_issues' })}
        </WowDisplayFlex>
      ) : (
        ''
      )}
    </MainView>
  );
};

export default DailyTimeline;
