import { useState, useCallback, useEffect, useRef } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Modal, { SIZE } from '../../../../../../components_new/Modal';
import Space from '../../../../../../components_new/Space';
import { SectionTitle } from '../../../../scheduling/schedule/styles';
import { TabsList, TabsTrigger } from './styles';
import { transformExportPdfData, EXPORT_TYPES } from './utils';
import { useExportIssueAsPdfAndDownload } from '../../hooks/useExportIssueAsPdfMutation';
import CustomTab from './tabsContent/Custom';
import StandardTab from './tabsContent/Standard';
import ActionButtons from './ActionButtons';
import ExportInProgress from './ExportInProgress';
import ExportError from './ExportError';
import { WowBlock } from '../../../../../../components_new/WowBasicElements';

const PdfExportModal = ({ toggle, issue, isPublic }) => {
  const intl = useIntl();

  const [exportType, setExportType] = useState(EXPORT_TYPES.standard);
  const refPdfExportModal = useRef();

  const {
    exportIssue,
    isLoading = false,
    isError,
    handleCancel,
  } = useExportIssueAsPdfAndDownload(isPublic, toggle);

  const submitHandler = useCallback(
    async (data) => {
      try {
        await exportIssue(
          {
            id: issue.id,
            export_type: exportType,
            ...(!isPublic
              ? transformExportPdfData(data)
              : { include_subtasks: 0, send_email_copy: 0 }),
          },
          issue.title
        );
        toggle();
      } catch (e) {}
    },
    [issue, exportIssue, exportType, toggle, isPublic]
  );

  const modalSize = window?.screen?.width < 550 ? '90%' : SIZE.medium;

  //on public page we dirrectly export pdf
  useEffect(() => {
    if (isPublic && !refPdfExportModal?.current) {
      submitHandler();
      refPdfExportModal.current = true;
    }
  }, [isPublic, submitHandler]);

  return (
    <Modal
      isOpen
      title={
        isLoading || isError
          ? ''
          : intl.formatMessage({
              id: 'export_to_pdf',
            })
      }
      onClose={toggle}
      size={modalSize}
      maxHeight="calc(100vh - 96px)"
      hideCloseIcon={isLoading || isError}
    >
      {isLoading ? (
        <ExportInProgress onCancel={handleCancel} />
      ) : isError ? (
        <ExportError onCancel={toggle} />
      ) : (
        <WowBlock data-testid="modalExportToPdf">
          <SectionTitle>
            <FormattedMessage id="format" />
          </SectionTitle>
          <Space height="8" />

          <Tabs.Root
            value={exportType}
            onValueChange={(e) => {
              setExportType(e);
            }}
            orientation="vertical"
          >
            <TabsList aria-label="Choose export option">
              <TabsTrigger value="standard" data-testid="standardTabButton">
                <FormattedMessage id="standard" />
              </TabsTrigger>
              <Space width="15" />
              <TabsTrigger value="custom" data-testid="customTabButton">
                <FormattedMessage id="custom" />
              </TabsTrigger>
            </TabsList>
            <Space height="30" />
            <Tabs.Content
              value={EXPORT_TYPES.standard}
              data-testid="standardTab"
            >
              <StandardTab
                submitHandler={submitHandler}
                issue={issue}
                renderActionButtons={() => (
                  <ActionButtons isLoading={isLoading} toggle={toggle} />
                )}
              />
            </Tabs.Content>
            <Tabs.Content value={EXPORT_TYPES.custom} data-testid="customTab">
              <CustomTab
                submitHandler={submitHandler}
                issue={issue}
                renderActionButtons={() => (
                  <ActionButtons isLoading={isLoading} toggle={toggle} />
                )}
              />
            </Tabs.Content>
          </Tabs.Root>
        </WowBlock>
      )}
    </Modal>
  );
};

PdfExportModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  issue: PropTypes.object.isRequired,
};

export default PdfExportModal;
