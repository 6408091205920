import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getInfiniteData } from './selectors';
import {
  setState,
  resetState,
  updateQueryParams,
  fetchInfiniteData,
  updateData,
  deleteItem,
  addItem,
  editItem,
} from './slice';
import { formatData as _formatData } from './utils';
import { INFINITE_SCROLL_INITIAL_STATE } from './constants';

export const useInfiniteScrollData = ({
  // idKey used in case when some data is not using `id` as identifier property
  idKey = 'id',
  entityKey,
  formatData = _formatData,
  getItems,
  customParams,
  skip = false,
  preventReset, //use within nested hooks to prevent reseting on init or clenup
}) => {
  const dispatch = useDispatch();

  const _infiniteDataState = useSelector(getInfiniteData(entityKey));

  /** `formatted` data prepared for list items */
  const _formattedData = useMemo(() => {
    return formatData({ data: _infiniteDataState?.data });
  }, [formatData, _infiniteDataState?.data]);

  /** `useEffect` hook to setup inital state of entity */
  useEffect(() => {
    if (preventReset) return;

    const _queryParams = {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
      ...customParams,
    };

    const entityState = {
      ...INFINITE_SCROLL_INITIAL_STATE,
      query: _queryParams,
      idKey,
    };

    dispatch(setState({ entityKey, entityState }));
  }, [dispatch, entityKey, customParams, preventReset, idKey]);

  // useEffect hook to reset entity state
  useEffect(() => {
    return () => {
      if (!preventReset) {
        dispatch(resetState({ entityKey }));
      }
    };
  }, [dispatch, entityKey, preventReset]);

  // when query is changed dispatch action to fetch items
  // trigger for communication with API and fetching data
  useEffect(() => {
    if (_infiniteDataState?.query && !skip) {
      dispatch(fetchInfiniteData({ entityKey, getItems }));
    }
  }, [dispatch, _infiniteDataState?.query, getItems, entityKey, skip]);

  //#region handlers
  // load more handler for the data
  const handleLoadMore = useCallback(() => {
    const page = _infiniteDataState?.query?.page ?? 1;
    dispatch(updateQueryParams({ entityKey, queryParams: { page: page + 1 } }));
  }, [_infiniteDataState?.query?.page, dispatch, entityKey]);

  const handleSearch = useCallback(
    (search) => {
      dispatch(
        updateQueryParams({ entityKey, queryParams: { page: 1, search } })
      );
      dispatch(updateData({ entityKey, data: [] }));
    },
    [dispatch, entityKey]
  );

  const handleUpdateQueryParams = useCallback(
    (queryParams) => {
      dispatch(updateQueryParams({ entityKey, queryParams }));
    },
    [dispatch, entityKey]
  );

  const handleDelete = useCallback(
    (ids, entityKeys) => {
      dispatch(
        deleteItem({
          ids,
          entityKeys: [entityKey, ...(entityKeys ? entityKeys : [])],
        })
      );
    },
    [dispatch, entityKey]
  );

  const handleAdd = useCallback(
    (items, addFirst) => {
      dispatch(addItem({ items, entityKey, addFirst }));
    },
    [dispatch, entityKey]
  );

  const handleUpdate = useCallback(
    (items) => {
      dispatch(updateData({ entityKey, data: items }));
    },
    [dispatch, entityKey]
  );

  const handleEdit = useCallback(
    (items, entityKeys) => {
      dispatch(
        editItem({
          entityKeys: [entityKey, ...(entityKeys ? entityKeys : [])],
          data: items,
        })
      );
    },
    [dispatch, entityKey]
  );

  //#endregion

  return {
    data: _formattedData,
    /** `indicator` that there are more items */
    hasMore: _infiniteDataState?.lastPage > _infiniteDataState?.query?.page,
    isLoading: _infiniteDataState?.loading,
    total: _infiniteDataState?.total,
    handleLoadMore,
    handleSearch,
    handleDelete,
    handleAdd,
    handleUpdateQueryParams,
    handleUpdate,
    handleEdit,
    query: _infiniteDataState?.query,
  };
};
