import styled from 'styled-components/macro';
import { darkerGray } from '../../../config/colors';

const InfoWrapper = styled.div`
  color: ${darkerGray};
  font-size: 14px;
`;

const AvatarBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const AvatarContainer = styled.div`
  width: 80px;
  height: 84px;
`;

const CenterAlignedTextBox = styled.div`
  text-align: center;
`;

const BoldedCenterAlignedTextBox = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 1.15em;
  color: ${darkerGray};
  letter-spacing: 0.3px;
`;

const SecondaryButton = styled.span`
  color: ${({ color }) => (color ? color : '#000')};
  cursor: pointer;
  font-size: 0.8em;
  text-align: center;
  margin-top: 20px;
`;

export {
  InfoWrapper,
  AvatarBox,
  AvatarContainer,
  CenterAlignedTextBox,
  BoldedCenterAlignedTextBox,
  SecondaryButton,
};
