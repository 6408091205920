import React from 'react';
import InfiniteScrollComponent from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';
import { HEIGHT, HAS_MORE, MAX_HEIGHT } from './constants';
import { InfiniteScrollWrapper, LoaderWrapper } from './styles';

const InfiniteScroll = ({
  children,
  handleLoadMore,
  dataLength,
  hasMore = HAS_MORE,
  height = HEIGHT,
  maxHeight = MAX_HEIGHT,
  customStyle = {},
  ...rest
}) => {
  return (
    <InfiniteScrollWrapper data-testid="infiniteScroll">
      <InfiniteScrollComponent
        dataLength={dataLength}
        next={handleLoadMore}
        hasMore={hasMore}
        height={height}
        style={{ maxHeight, ...customStyle }}
        loader={
          hasMore && dataLength > 0 ? (
            <LoaderWrapper data-testid="infiniteScrollLoader">
              <LoadingSpinner />
            </LoaderWrapper>
          ) : null
        }
        {...rest}
      >
        {children}
      </InfiniteScrollComponent>
    </InfiniteScrollWrapper>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
  hasMore: PropTypes.bool,
  height: PropTypes.number,
};

export default InfiniteScroll;
