import React from 'react';
import { Provider } from 'react-redux';

import { store } from './redux/store';
import { Routes } from './router';

window.hsConversationsSettings = {
  loadImmediately: false, // Delay the load of the chat widget
};

const RootView = () => (
  <Provider store={store}>
    <Routes></Routes>
  </Provider>
);

export default RootView;
