import { orange } from '../../../config/colors';

import { DENSITY } from '../../../config/config';
import { iconWowListItemLightGray } from '../../../config/colors';

export const INIT_QUERY_PARAMS = {
  page: 1,
  paginate_by: 20,
  sort_by: 'updated',
  sort_direction: 'desc',
};

export const ISSUE_STATUSES = {
  ALL_ACTIVE: '',
  TODO: 'TO_DO',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  COMPLETED: 'COMPLETED',
  ARCHIVED: 'archived',
  ALL: 'all',
};

export const ISSUE_ITEM_ACTION_OPTIONS = {
  DUPLICATE_ISSUE: 'DUPLICATE_ISSUE',
  EXPORT_PDF: 'EXPORT_PDF',
  QUICK_COMPLETE_ISSUE: 'QUICK_COMPLETE_ISSUE',
  ARCHIVE_ISSUE: 'ARCHIVE_ISSUE',
  DELETE_ISSUE: 'DELETE_ISSUE',
  REASSIGN_ISSUE: 'REASSIGN_ISSUE',
};

export const ACTIVE_ISSUE_STATUSES = [
  ISSUE_STATUSES.TODO,
  ISSUE_STATUSES.DECLINED,
  ISSUE_STATUSES.ACCEPTED,
  ISSUE_STATUSES.IN_PROGRESS,
  ISSUE_STATUSES.IN_REVIEW,
  ISSUE_STATUSES.COMPLETED,
];

export const ARCHIVED_ISSUE_STATUSES = [
  ISSUE_STATUSES.DECLINED,
  ISSUE_STATUSES.COMPLETED,
  ISSUE_STATUSES.IN_REVIEW,
];

export const issueStatusOptions = [
  {
    value: ISSUE_STATUSES.ALL_ACTIVE,
    label: {
      id: 'active',
    },
  },
  {
    value: ISSUE_STATUSES.TODO,
    label: {
      id: 'to_do',
    },
    parent: ISSUE_STATUSES.ALL_ACTIVE,
  },
  {
    value: ISSUE_STATUSES.ACCEPTED,
    label: {
      id: 'accepted',
    },
    parent: ISSUE_STATUSES.ALL_ACTIVE,
  },
  {
    value: ISSUE_STATUSES.DECLINED,
    label: {
      id: 'declined',
    },
    parent: ISSUE_STATUSES.ALL_ACTIVE,
  },
  {
    value: ISSUE_STATUSES.IN_PROGRESS,
    label: {
      id: 'in_progress',
    },
    parent: ISSUE_STATUSES.ALL_ACTIVE,
  },
  {
    value: ISSUE_STATUSES.IN_REVIEW,
    label: {
      id: 'in_review',
    },
    parent: ISSUE_STATUSES.ALL_ACTIVE,
  },
  {
    value: ISSUE_STATUSES.COMPLETED,
    label: {
      id: 'completed',
    },
    parent: ISSUE_STATUSES.ALL_ACTIVE,
  },
  {
    value: ISSUE_STATUSES.ARCHIVED,
    label: {
      id: 'archived',
    },
  },
  {
    value: ISSUE_STATUSES.COMPLETED,
    label: {
      id: 'completed',
    },
    parent: ISSUE_STATUSES.ARCHIVED,
  },
  {
    value: ISSUE_STATUSES.DECLINED,
    label: {
      id: 'declined',
    },
    parent: ISSUE_STATUSES.ARCHIVED,
  },
  {
    value: ISSUE_STATUSES.IN_REVIEW,
    label: {
      id: 'in_review',
    },
    parent: ISSUE_STATUSES.ARCHIVED,
  },
  {
    value: ISSUE_STATUSES.ALL,
    label: {
      id: 'filter_all',
    },
  },
];

export const SUPPORTED_PARAMS_ADVANCED_FILTER = {
  ids: {
    defaultValue: null,
    allowedValues: null,
  },
  date_from: {
    defaultValue: null,
    allowedValues: null,
  },
  date_to: {
    defaultValue: null,
    allowedValues: null,
  },
  include_sub_ws: {
    defaultValue: null,
    allowedValues: ['0', '1'],
  },
  users_any_role: {
    defaultValue: null,
    allowedValues: null,
  },
  collabs_any_role: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  assignees_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  issue_type: {
    defaultValue: null,
    allowedValues: ['issue', 'task'],
  },
  issue_state: {
    defaultValue: '',
    allowedValues: ['all'],
  },
  'issue_state[active]': {
    defaultValue: '',
    allowedValues: null,
  },
  'issue_state[archived]': {
    defaultValue: '',
    allowedValues: null,
  },
  priority_id: {
    defaultValue: null,
    allowedValues: ['5', '4', '3', '2'],
  },
  priority_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspaces: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  worked_on_by_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_group_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_system_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  reporters_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  schedule_id: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers_externals: {
    defaultValue: null,
    allowedValues: null,
  },
  watchers_companies: {
    defaultValue: null,
    allowedValues: null,
  },
  categories: {
    defaultValue: null,
    allowedValues: null,
  },
  rating: {
    defaultValue: null,
    allowedValues: ['5', '4', '3', '2', '1'],
  },
  filter_by_job_positions: {
    defaultValue: [],
    allowedValues: null,
  },
};

export const INIT_ADVANCED_FILTERS_MULTISELECT = {
  users_any_role: null,
  collabs_any_role: null,
  assignees: null,
  assignees_companies: null,
  assignees_externals: null,
  workspaces: null,
  worked_on_by: null,
  worked_on_by_companies: null,
  worked_on_by_externals: null,
  asset_ids: null,
  asset_group_ids: null,
  asset_system_ids: null,
  reporters: null,
  reporters_companies: null,
  reporters_externals: null,
  schedule_id: null,
  watchers: null,
  watchers_companies: null,
  watchers_externals: null,
  categories: null,
};

export const INIT_ADVANCED_FILTERS = {
  issue_type: null,
  issue_state: null,
  'issue_state[active]': null,
  'issue_state[archived]': null,
  priority_id: null,
  rating: null,
  ...INIT_ADVANCED_FILTERS_MULTISELECT,
};

export const EMPTY_SCREEN_SETTINGS = {
  icon: 'icon-task',
  keys: {
    title: 'what_is_issues',
    text: 'no_issues_text',
  },
};

export const DENSITY_KEY = 'density';
export const FEATURE_KEY = 'listView';
export const ADVANCED_FILTER_OPEN_KEY = 'isAdvancedFilterOpen';
export const ISSUES_LOCALSTORAGE_KEY = 'issues';

//TO DO > see if all good as we go
export const STATUS_OPTIONS = {
  todo: {
    id: 1,
    key: 'status.to_do',
    value: 3,
    color: null,
  },
  declined: {
    id: 2,
    key: 'status.declined', // ? does this exist properly
    value: 4,
    color: null,
  },
  accepted: {
    id: 3,
    key: 'status.to_do', // accepted ? //TO DO > WTH is this ??
    value: 5,
    color: null,
  },
  inProgress: {
    id: 4,
    key: 'status.in_progress',
    value: 6,
    color: orange,
  },
  inReview: {
    id: 5,
    key: 'status.in_review',
    value: 7,
    color: null,
  },
  completed: {
    id: 6,
    key: 'status.completed',
    value: 8,
    color: null,
  },
};

export const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'start_date',
    label: 'start_date',
  },
  {
    value: 'due_date',
    label: 'due_date',
  },
  {
    value: 'title',
    label: 'title',
    hideOnDropdown: true,
    infoData: {
      show: true,
      tooltipMessageKey: 'you_are_sorting_on_the_list',
      iconColor: iconWowListItemLightGray,
    },
  },
  {
    value: 'workspace',
    label: 'workspace',
  },
  {
    value: 'category',
    label: 'category',
    hideOnDropdown: true,
    infoData: {
      show: true,
      tooltipMessageKey: 'you_are_sorting_on_the_list',
      iconColor: iconWowListItemLightGray,
    },
  },
  {
    value: 'assignee',
    label: 'assignee',
    hideOnDropdown: true,
    infoData: {
      show: true,
      tooltipMessageKey: 'you_are_sorting_on_the_list',
      iconColor: iconWowListItemLightGray,
    },
  },
  {
    value: 'status',
    label: 'status',
    hideOnDropdown: true,
    infoData: {
      show: true,
      tooltipMessageKey: 'you_are_sorting_on_the_list',
      iconColor: iconWowListItemLightGray,
    },
  },
  {
    value: 'deadline',
    label: 'deadline',
    hideOnDropdown: true,
    infoData: {
      show: true,
      tooltipMessageKey: 'you_are_sorting_on_the_list',
      iconColor: iconWowListItemLightGray,
    },
  },
];

export const STATUS_ICONS = {
  'status.to_do': 'to-do',
  'status.in_review': 'review',
  'status.in_progress': 'in-progress',
  'status.completed': 'complete',
};

export const MAIN_SUPPORTED_PARAMS = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  filter_by_job_positions: {
    defaultValue: [],
    allowedValues: null,
  },
  page: {
    defaultValue: '1',
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: [
      'created',
      'updated',
      'start_date',
      'due_date',
      'title',
      'workspace',
      'category',
      'assignee',
      'status',
      'deadline',
    ],
  },
  sort_direction: {
    defaultValue: 'desc',
    allowedValues: ['asc', 'desc'],
  },
  include_subtasks: {
    defaultValue: null,
    allowedValues: ['0', '1'],
  },
  isAdvancedFilterOpen: {
    defaultValue: '0',
    allowedValues: ['0', '1'],
  },
  density: {
    defaultValue: DENSITY.comfortable,
    allowedValues: Object.values(DENSITY),
  },
  issue_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  quick_filters: {
    default: null,
    allowedValues: null,
  },
  saved_filters: {
    default: null,
    allowedValues: null,
  },
  date_events_from: {
    default: null,
    allowedValues: null,
  },
  date_events_to: {
    default: null,
    allowedValues: null,
  },
  date_events_types: {
    default: null,
    allowedValues: null,
  },
};

export const SUPPORTED_PARAMS = {
  ...MAIN_SUPPORTED_PARAMS,
  ...SUPPORTED_PARAMS_ADVANCED_FILTER,
};

export const CUSTOMER_SUPPORT_CONFIG = {
  entity: 'issues',
  videoId: 'VTd8nnStrKc',
};

export const PERSIST_KEY = 'issue';
