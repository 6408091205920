import { get, getIn } from 'immutable';
import { equipmentApi } from '../../views/internal/Equipment/service';

export const userTypes = {
  MANAGERS: 'managers',
  ADMINS: 'admins',
  USERS: 'users',
};

export const getIssue = (state) => get(state, 'issue').toJS();

export const getSelectedWorkspace = (state) => {
  const wsId = getIn(state, ['issue', 'selectedWorkspace']);
  const ws = getIn(state, ['issue', 'selectedWorkspaceObject']);

  if (ws && ws?.id === wsId) {
    return ws;
  }

  const params = {
    page: 1,
    paginate_by: '10',
    search: '',
    selected_workspaces: [wsId],
  };

  const response = equipmentApi.endpoints.getWorkspaces.select(params)(state);
  const { data } = response;

  let wsObj = null;
  if (data?.data?.length > 0) {
    const flatItems = data.data.flatMap((item) => {
      if (item.children?.length > 0) {
        return [item, ...item.children.map((childItem) => childItem)];
      } else {
        return [item];
      }
    });

    wsObj = flatItems.find((w) => {
      return Number(w.id) === Number(wsId);
    });
  }

  return wsObj;
};

export const getCurrentIssue = (state) => getIn(state, ['issue', 'current']);

export const getCurrentIssuePermissions = (state) =>
  getIn(state, ['issue', 'permissions']);

export const getCurrentStatus = (state) => {
  const currentStatus = getIn(state, ['issue', 'current', 'status']);
  if (currentStatus) {
    const flowConfig = getIn(state, ['config', 'flowConfig']);
    const currentStatusButtons = flowConfig[currentStatus.type]?.buttons;
    currentStatus.buttons = currentStatusButtons;
  }
  return currentStatus;
};

// Don't understand this.
// Why we care about issue when we are getting users for forspace.
// Also why is WS logic here instead in WS selector.
export const getWSUsers = (userType) => (state) => {
  const issue = getCurrentIssue(state);

  if (!issue) {
    return [];
  }

  const issueWS = getSelectedWorkspace(state);

  if (!issueWS) {
    return [];
  }

  return issueWS[userType];
};

export const getWSManagers = getWSUsers(userTypes.MANAGERS);
export const getWSAdmins = getWSUsers(userTypes.ADMINS);

export const isIssueFormSubmitted = (state) =>
  getIn(state, ['issue', 'isSubmitted']);
export const isIssueFormBeingSubmitted = (state) =>
  getIn(state, ['issue', 'isSubmitting']);
export const getIssueError = (state) => getIn(state, ['issue', 'error']);

export const getCurrentStatusButton = (issue) => {
  if (!issue) {
    return null;
  }

  return {
    id: issue.status.id,
    text: issue.status.name,
  };
};
