import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getToken } from '../../../../redux/utils';
import { BASE_URL } from '../../../../config/constants';

export const publicPageConfiguratorApi = createApi({
  reducerPath: 'publicPageConfiguratorApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: ['PublicPageConfigurator'],
  endpoints: (builder) => ({
    getPublicPageConfigurationTemplate: builder.query({
      query: (type) => ({ url: `public_page_configurator/templates/${type}` }),
      providesTags: (result, error, id) => [
        { type: 'PublicPageConfigurator', id },
      ],
    }),
    editPublicPageConfigurationTemplate: builder.mutation({
      query(payload) {
        const { type, ...restPayload } = payload;
        return {
          url: `public_page_configurator/${type}`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'PublicPageConfigurator', id: arg.type },
      ],
    }),
  }),
});

export const {
  useGetPublicPageConfigurationTemplateQuery,
  useEditPublicPageConfigurationTemplateMutation,
} = publicPageConfiguratorApi;
