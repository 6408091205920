import { appConfig } from '../config/config';
import { actions as authActions } from './auth/actions';
import { fetchData } from './common/flows';
import { config } from '../services/config';
import jwtDecode from 'jwt-decode';
import { loadColours } from './config/actions';
import { getLoggedUserData } from './user/action-creator';

const url = config.URL.replace('api.', '');

export const localStorageKey = `${config.URL}-${appConfig.localStorageItem}`;
export const coloursStorageKey = `${url}-colours`;

export const saveData = (data, key = localStorageKey) =>
  localStorage.setItem(key, JSON.stringify(data));
export const getData = (key) => JSON.parse(localStorage.getItem(key));
export const removeData = (key) => localStorage.removeItem(key);
export const getToken = (key = localStorageKey) => getData(key)?.token;
export const isTokenInvalid = (token) =>
  jwtDecode(token).exp < Date.now() / 1000;
export const checkForSavedData = (store, history) => {
  // Check for client colours
  const colorsData = getData(coloursStorageKey);

  if (colorsData) {
    store.dispatch(loadColours(colorsData));
  }

  // Get token data
  const savedData = getData(localStorageKey);

  if (savedData && (!savedData.token || isTokenInvalid(savedData.token))) {
    removeData(localStorageKey);
    history.push('/login');
  } else if (savedData && savedData.token && !isTokenInvalid(savedData.token)) {
    store.dispatch(authActions.loginStart());
    store.dispatch(authActions.saveLoginData(savedData));
    store.dispatch(authActions.loginSuccess());
    store.dispatch(getLoggedUserData());
    store.dispatch(fetchData());
  }
  /* Redirect to /login should be handled somewhere else */
  /* Issue mentioned by @Bobo should be done */
  /* That is, when logged out, system should handle dynamic accesses to pages */
  /* Like for example /dashboard/issues */
};
