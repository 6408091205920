import { createSlice } from '@reduxjs/toolkit';

//TO DO > see as we need all and current
const INIT_STATE = {
  /*  workspaces: {},
  issues: {},
  scheduling: {},
  equipment: {},
  equipmentGroups: {},
  equipmentExternalHolders: {},
  assets: {},
  assetGroups: {},
  assetSystems: {},
  users: {},
  checklists: {}, */
};

export const multiselectSlice = createSlice({
  name: 'multiselect',
  initialState: INIT_STATE,
  reducers: {
    setState: (state, action) => {
      const { entityKey, data } = action.payload;
      state[entityKey] = data;
    },
    updateState: (state, action) => {
      const { entityKey, data } = action.payload;
      if (state[entityKey]) {
        state[entityKey] = {
          ...state[entityKey],
          ...data,
        };
      }
    },
  },
});

export const { setState } = multiselectSlice.actions;

export default multiselectSlice.reducer;
