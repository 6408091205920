import styled from 'styled-components/macro';
import { neonGreen, orange } from '../../../../../config/colors';

export const BreakTimeWrapper = styled.div`
  background-color: ${({ breakFinish }) => (breakFinish ? neonGreen : orange)};
  position: absolute;
  right: 0;
  height: 40px;
  border-radius: 0 6px 6px 0;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
