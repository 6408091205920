import { useState } from 'react';
import { PatternFormat } from 'react-number-format';

const DatePickerTimeInput = (props) => {
  const {
    value,
    onChange = () => {},
    setDisableDoneButton = () => {},
    customInput,
  } = props;

  const [showEmptyFormatting, setShowEmptyFormatting] = useState(false);

  const onChangeHandler = (e) => {
    const value = e?.target?.value;
    if (!value || value === '' || value === 'hh:mm' || value === '--:--') {
      setDisableDoneButton(true);
      setShowEmptyFormatting(true);
    } else if (
      value.search('-') !== -1 ||
      value.search('h') !== -1 ||
      value.search('m') !== -1
    ) {
      setDisableDoneButton(true);
      setShowEmptyFormatting(false);
    } else {
      setDisableDoneButton(false);
      setShowEmptyFormatting(false);
    }
    onChange(e.target.value);
  };

  return (
    <PatternFormat
      customInput={customInput ?? null}
      format="##:##"
      mask={showEmptyFormatting ? ['-', '-', '-', '-'] : ['h', 'h', 'm', 'm']}
      allowEmptyFormatting
      value={value}
      onChange={onChangeHandler}
    />
  );
};

export default DatePickerTimeInput;
