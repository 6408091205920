import vest, { enforce, test } from 'vest';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import { transformAttachments } from '../../../../components_new/MediaInput/util';
import { formatTimestampToDateAndMonth } from '../../../../helpers/date.helper';
import {
  attachmentState,
  addAttachments,
} from '../../../../redux/gallery/actions';
import { transformAttachment } from '../../../../redux/gallery/utils';
import { FORM_DEFAULT_VALUES, STATUS_IDS } from './constants';
import { checkIsDateInThePast } from '../../Equipment/utils';
import { getLocalLink } from '../../../../services/IssueService';

export const formValidation = vest.create((data = {}, additionalParams) => {
  const { title, workspace_id, process_number, assignee_id, due_date } = data;

  test('workspace_id', 'Workspace must be selected', () => {
    enforce(workspace_id).isNotEmpty();
  });

  test('title', 'Enter longer title', () => {
    enforce(title.trim()).longerThan(1).shorterThan(256);
  });

  if (additionalParams?.params.isDueDateMandatoryValidator) {
    test('due_date', 'due date should be mandatory', () => {
      enforce(due_date).isNotEmpty();
    });
  }

  if (additionalParams?.params?.includeProcessNumberValidator) {
    test('process_number', 'process_nr_required', () => {
      enforce(process_number).isNotEmpty();
    });
    test('process_number', 'process_nr_format', () => {
      enforce(process_number).lengthEquals(6);
    });
  }

  if (
    additionalParams?.params?.isAssigneeIdMandatoryValidator &&
    !additionalParams?.params?.isIssueArchived
  ) {
    test('assignee_id', 'Assignee must be selected', () => {
      enforce(assignee_id).isNotEmpty();
    });
  }
});

/*it will preselect workspace and category/assignee*/
export const preselectWorkspace = (workspace, setValue) => {
  setValue('workspace', workspace);
  setValue('workspace_id', workspace.id);

  if (workspace?.default_category && workspace?.default_category_id) {
    const category = workspace.default_category;
    const categoryId = workspace.default_category_id;
    const availableAssigneesIds = workspace.available_assignees_ids;
    setValue('category', category);
    setValue('category_id', categoryId);
    if (
      category.default_assignee_id &&
      category.default_assignee &&
      availableAssigneesIds.includes(category.default_assignee_id)
    ) {
      setValue('assignee', category.default_assignee);
      setValue('assignee_id', category.default_assignee_id);
    }
  }
};

export const parseIssueData = (issue) => {
  return {
    id: issue.id,
    title: issue.title,
    description: issue.description || FORM_DEFAULT_VALUES.description,
    attachments: transformAttachments(issue.attachments),
    workspace: issue.workspace || issue.workspace_short_info,
    workspace_id: issue.workspace_id,
    status_id: issue.status_id || FORM_DEFAULT_VALUES.status_id,
    status: issue.status,
    checklists: [],
    priority_id: issue.priority_id,
    category_id: issue.category_id,
    category: issue.category,
    start_date: issue.start_date ? issue.start_date * 1000 : null,
    due_date: issue.due_date ? issue.due_date * 1000 : null,
    watchers: issue?.watchers?.map((w) => w.id),
    assignee: issue.assignee || {},
    assignee_id: issue.assignee_id || FORM_DEFAULT_VALUES.assignee_id,
    equipment_ids: issue.equipment_ids || FORM_DEFAULT_VALUES.equipment_ids,
    equipments: issue.equipments || FORM_DEFAULT_VALUES.equipments,
    asset_id: issue.asset_id || FORM_DEFAULT_VALUES.asset_id,
    asset: issue.asset || FORM_DEFAULT_VALUES.asset,
    asset_group_id: issue.asset_group_id || FORM_DEFAULT_VALUES.asset_group_id,
    asset_group: issue.asset_group || FORM_DEFAULT_VALUES.asset_group,
    accessibility_id:
      issue.accessibility_id || FORM_DEFAULT_VALUES.accessibility_id,
    accessibility_type: issue.accessibility_type || {},
    accessibility_user_ids: issue.accessibility_user_ids || [],
    accessibility_users: issue.accessibility_users || [],
    children: issue.children || [],
    process_number: issue.process_number,
    notify_public_reporter_completed: issue.notify_public_reporter_completed,
    client_form: issue.client_form ?? null,
    external_email_address: FORM_DEFAULT_VALUES.external_email_address,
    is_attachment_required: issue.is_attachment_required,
    links: issue.links_v2 ?? [],
  };
};

export const getPrioritiesList = (currentWs) => {
  const allPriorities = [
    {
      value: 5,
      name: 'priority.urgent',
      key: 'urgent',
    },
    {
      value: 4,
      name: 'priority.high',
      key: 'high',
    },
    {
      value: 3,
      name: 'priority.normal',
      key: 'normal',
    },
    {
      value: 2,
      name: 'priority.low',
      key: 'low',
    },
  ];

  let currentWSPriorities = null;

  if (currentWs && Array.isArray(currentWs.priorities)) {
    currentWSPriorities = currentWs.priorities;
  } else {
    currentWSPriorities = allPriorities;
  }

  return currentWSPriorities;
};

export const checkIfSameDayAsToday = (compareDate = new Date()) => {
  const today = new Date();
  const compareDateParsed = new Date(compareDate);
  return (
    compareDateParsed.getDate() === today.getDate() &&
    compareDateParsed.getMonth() === today.getMonth() &&
    compareDateParsed.getFullYear() === today.getFullYear()
  );
};

export const getDueDateData = (issue) => {
  const data = {
    shouldSnow: false,
    dueDate: null,
    isOverdue: false,
  };
  if (
    issue &&
    issue.due_date &&
    issue.status_id !== 7 &&
    issue.status_id !== 8
  ) {
    const due_date = issue.due_date;
    data.dueDate = due_date;

    const isDueDatePast = checkIsDateInThePast(
      due_date ? due_date * 1000 : new Date()
    );
    const isSameDayAsToday = checkIfSameDayAsToday(due_date * 1000);

    if (isSameDayAsToday || isDueDatePast) data.shouldSnow = true;
    data.dueDate = formatTimestampToDateAndMonth(due_date * 1000, 'eng');
    if (isDueDatePast) data.isOverdue = true;
  }
  return data;
};

export const getIssueTypesList = () => [
  { value: '', name: 'issueType.all', key: 'all' },
  { value: 'issue', name: 'issueType.issue', key: 'issues' },
  { value: 'task', name: 'issueType.task', key: 'maintenance_tasks' },
];

export const getIssueRatingsList = () => [
  { value: '', name: 'rating', key: 'rating' },
  { value: 1, name: 'rating.incomplete', key: 'rating_incomplete' },
  { value: 2, name: 'rating.satisfactory', key: 'rating_satisfactory' },
  { value: 3, name: 'rating.good', key: 'rating_good' },
  { value: 4, name: 'rating.very_good', key: 'rating_very_good' },
  { value: 5, name: 'rating.excellent', key: 'rating_excellent' },
];

export const STATUS_ICONS = {
  'status.to_do': 'icon icon-to-do',
  'status.in_review': 'icon icon-review',
  'status.in_progress': 'icon icon-in-progress',
  'status.completed': 'icon icon-complete',
};

export const getAssigneeActionData = ({
  issue = {},
  currentLoggedUser = {},
  authorization = {},
  intl,
}) => {
  const returnData = {
    redButton: {
      mutationPayload: {},
      textId: '',
      show: false,
      triggerDeclineReviewModal: false,
    },
    greenButton: {
      mutationPayload: {},
      textId: '',
      show: false,
      triggerPhotoRequiredModal: false,
      triggerMediaInput: false,
      isDisabled: false,
      textAdditional: null,
    },
    textId: '',
    show: false,
  };

  if (issue) {
    const editPermission = issue?.permissions?.length
      ? issue?.permissions?.filter((item) => item === 'edit_issue')
      : false; //This value will be used on Public Issue to check if user has permission to click action buttons.
    // If is assignee
    if (
      (issue.assignee_id &&
        currentLoggedUser &&
        issue.assignee_id === currentLoggedUser.id) ||
      editPermission
    ) {
      if (issue.status_id === 3) {
        //DECIDING STATUS
        returnData.redButton.mutationPayload.status_id = 4;
        returnData.redButton.textId = 'decline';
        returnData.redButton.show = true;

        returnData.greenButton.mutationPayload.status_id = 5;
        returnData.greenButton.textId = 'button_text_accept';
        returnData.greenButton.show = true;

        returnData.textId = 'accept_issue';
        returnData.show = true;
      }

      if (issue.status_id === 5) {
        //ACCEPTED STATUS - BUT NOT STARTED YET
        returnData.greenButton.mutationPayload.status_id = 6;
        returnData.greenButton.textId = 'start';
        returnData.greenButton.show = true;
        returnData.textId = 'start_issue';
        returnData.show = true;

        if (issue.start_date && !issue.schedule_id) {
          const today = dayjs().startOf('day');
          const startDateFormatted = dayjs(issue.start_date * 1000).startOf(
            'day'
          );
          const startNotPossible = today.isBefore(startDateFormatted);
          if (startNotPossible) {
            returnData.textId = 'issue_has_start_date_in_future';
            returnData.greenButton.textId = 'start_possible_on';

            const month = startDateFormatted.month() + 1;
            const transKey = `-${month < 10 ? '0' : ''}${month}--`;
            const date = startDateFormatted.get('date');
            const year = startDateFormatted.get('year');
            returnData.greenButton.textAdditional = ` ${intl.formatMessage({
              id: transKey,
            })} ${date}, ${year}`;
          }
          returnData.greenButton.isDisabled = startNotPossible;
        }
      }

      if (issue.status_id === 6) {
        //IN_PROGRESS STATUS
        returnData.greenButton.mutationPayload.status_id = 7;
        returnData.greenButton.textId = 'done';
        returnData.greenButton.show = true;

        returnData.textId = 'set_done';
        returnData.show = true;
      }

      if (
        issue.status_id > STATUS_IDS.ACCEPTED &&
        authorization?.isRegularUser &&
        issue?.is_attachment_required &&
        !issue?.is_assignee_attachment_uploaded
      ) {
        returnData.greenButton.textId = 'add_photo';
        returnData.textId = 'add_photo_to_finish';
        returnData.greenButton.triggerMediaInput = true;
      }
    }

    if (
      currentLoggedUser &&
      (authorization?.isAdmin || authorization?.isManager)
    ) {
      if (issue.status_id === STATUS_IDS.IN_PROGRESS) {
        returnData.greenButton.triggerPhotoRequiredModal =
          issue?.is_attachment_required &&
          !issue?.is_assignee_attachment_uploaded;
      }

      if (issue.status_id === STATUS_IDS.IN_REVIEW) {
        returnData.redButton.triggerDeclineReviewModal = true;
        returnData.redButton.textId = 'decline';
        returnData.redButton.show = true;

        returnData.greenButton.mutationPayload.status_id = 8;
        returnData.greenButton.textId = 'complete';
        returnData.greenButton.show = true;

        returnData.textId = 'issue_in_review';
        returnData.show = true;
      }
    }
  }

  return returnData;
};

export const getOptionsWithIntl = (intl, options, format = 'none') => {
  return options.map((option) => {
    let label = intl.formatMessage({ id: option.key || option.label });

    switch (format) {
      case 'uppercase':
        label = label.toUpperCase();
        break;
      case 'lowercase':
        label = label.toLowerCase();
        break;
      case 'none':
      default:
    }

    return {
      ...option,
      label,
    };
  });
};

export const formatNoteStatusMessage = (intl, pinned, edited) => {
  if (!pinned && !edited) {
    return null;
  }

  const pinnedMessage = pinned ? intl.formatMessage({ id: 'pinned' }) : '';
  const comma = pinned && edited ? ', ' : '';
  const editedMessage = edited ? intl.formatMessage({ id: 'edited' }) : '';

  const msg = `(${pinnedMessage}${comma}${editedMessage})`;

  return msg;
};

export const pageConfigMapper = (client = {}) => ({
  includeCommentsOnAttachments: !!client.include_comments_on_attachments,
  includeExportSubtasks: !!client.include_export_subtasks,
  includeExportWatchers: !!client.include_export_watchers,
  includeHistoryLog: !!client.include_history_log,
  includeIssueAddress: !!client.include_issue_address,
  includeIssueAttachmentComments: !!client.include_issue_attachment_comments,
  includeIssueAttachmentOrdering: !!client.include_issue_attachment_ordering,
  includeIssueProcessNumber: !!client.include_issue_process_number,
  includeIssueTitle: !!client.include_issue_title,
  includeTextTemplates: !!client.include_text_templates,
  includeAttachmentPriority: !!client.include_issue_attachment_priority,
  includeAttachmentDueDate: !!client.include_issue_attachment_due_date,
  isAssigneeMandatory: !!client.is_assignee_mandatory,
});

//Helper for OLD GALLERY.
export const appendGalleryFormData = (formData, attachments) => {
  let attCounter = 0;
  let delCounter = 0;

  for (let i = 0; i < attachments.length; i++) {
    const att = attachments[i];

    if (att.state === attachmentState.DELETED && att.id) {
      formData.append(`delete_attachments[${delCounter++}]`, att.id);
    } else if (att.state === attachmentState.MODIFIED) {
      formData.append(
        `attachment[${attCounter}][description]`,
        att.description
      );
      formData.append(`attachment[${attCounter}][due_date]`, att.due_date);
      formData.append(
        `attachment[${attCounter}][priority_id]`,
        att.priority_id
      );
      formData.append(`attachment[${attCounter++}][id]`, att.id);
    } else if (att.state === attachmentState.NEW) {
      formData.append(
        `attachment[${attCounter}][file]`,
        att.file,
        att.file.name
      );
      if (att.due_date) {
        formData.append(`attachment[${attCounter}][due_date]`, att.due_date);
      }
      if (att.priority_id) {
        formData.append(
          `attachment[${attCounter}][priority_id]`,
          att.priority_id
        );
      }
      formData.append(
        `attachment[${attCounter++}][description]`,
        att.description
      );
    }
  }

  return formData;
};

//Helper for OLD GALLERY.
export const addAttachmentsForIssue = async (issue, dispatch) => {
  if (!issue) return;

  const attachments = [];

  for (let index = 0; index < issue.attachments.length; index++) {
    const att = issue.attachments[index];

    if (att.mime_type === 'application/pdf') {
      try {
        const item = { ...att };
        item.url = await getLocalLink(item.url);

        attachments.push(
          transformAttachment({
            att: item,
            isThumbnailPresent: false,
            index,
            attachmentState,
          })
        );
      } catch {}

      continue;
    }

    attachments.push(
      transformAttachment({
        att,
        index,
        attachmentState,
      })
    );
  }

  dispatch(addAttachments(attachments, issue.id));
};

export const getIsFormDirty = ({ isDirty, isGalleryDirty, dirtyFields }) => {
  if (isGalleryDirty) return isDirty;
  // when gallery is not dirty
  // check are there any properties different than attachment that are dirty
  return (
    Object.keys(omit(dirtyFields, ['attachments', '_gallery-hack_'])).length > 0
  );
};
