import styled from 'styled-components/macro';
import * as Tabs from '@radix-ui/react-tabs';

import {
  newBorder,
  filtersAdvancedGray,
  gradientBlue,
  darkerGray,
} from '../../config/colors';
import WoWEmptyTab from './EmptyTab';
import { themeColour } from '../../helpers/theme';

const WowTabs = styled(Tabs.Root)`
  display: 'flex';
  flex-direction: 'column';
`;

const WowTabsList = styled(Tabs.List)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid ${newBorder};
`;

const WowTabsTrigger = styled(Tabs.Trigger)`
  letter-spacing: 1.5px;
  white-space: nowrap;
  font-size: 11px;
  text-transform: uppercase;
  flex: 1;
  text-align: center;
  flex-shrink: 0;
  padding: 20px 20px;
  color: ${filtersAdvancedGray};
  user-select: 'none';
  cursor: pointer;

  &:hover {
    color: ${darkerGray};
  }

  &[data-state='active'] {
    color: ${darkerGray};
    font-weight: 800;
    box-shadow: ${(props) =>
      `inset 0 -1px 0 0 ${themeColour(props) ?? gradientBlue}, 0 1px 0 0 ${
        themeColour(props) ?? gradientBlue
      }`};
  }
`;

const WowTabsContent = styled(Tabs.Content)`
  flex-grow: 1;
  padding-top: 30px;
`;

export { WoWEmptyTab, WowTabs, WowTabsList, WowTabsTrigger, WowTabsContent };
