import { internalRoutePaths } from './config';
//NOTE - user page creates some css issues hence not included wih lazy for now
import UserPage from '../views/internal/user/User';
import { lazy } from 'react';

const Workspaces = lazy(() => import('../views/internal/workspaces'));
const WorkspaceDetail = lazy(() =>
  import('../views/internal/workspaces/workspace/Details')
);
const WorkspaceDynamicForm = lazy(() =>
  import(
    '../views/internal/LEGACY_workspaces/workspace-dynamic-form/WorkspaceDynamicForm'
  )
);
const UsersView = lazy(() => import('../views/internal/users/users'));
const IssuesView = lazy(() => import('../views/internal/IssuesV2'));
const CategoriesView = lazy(() =>
  import('../views/internal/categories/categories.view')
);
const General = lazy(() => import('../views/internal/settings/General'));
const Issue = lazy(() => import('../views/internal/IssuesV2/Issue'));
const PDFReports = lazy(() => import('../views/internal/settings/PDFReports'));
const SchedulingView = lazy(() => import('../views/internal/scheduling'));
const EquipmentView = lazy(() => import('../views/internal/Equipment'));
const Equipment = lazy(() => import('../views/internal/Equipment/Equipment'));
const EquipmentGroupsView = lazy(() =>
  import('../views/internal/Equipment/Groups')
);
const EquipmentTypesView = lazy(() =>
  import('../views/internal/Equipment/Types')
);
const Schedule = lazy(() => import('../views/internal/scheduling/schedule'));
const Assets = lazy(() => import('../views/internal/Assets'));
const AssetsGroups = lazy(() => import('../views/internal/Assets/Groups'));
const AssetsSystems = lazy(() => import('../views/internal/Assets/Systems'));
const Asset = lazy(() => import('../views/internal/Assets/Asset'));
//NOTE - user page creates some css issues hence not included wih lazy for now
//const UserPage = lazy(() => import('../views/internal/user/User'));
const Profile = lazy(() => import('../views/internal/settings/Profile'));
const PublicForms = lazy(() =>
  import('../views/internal/settings/PublicForms')
);
const ExternalHolders = lazy(() =>
  import('../views/internal/Equipment/ExternalHolders')
);
const Calendar = lazy(() => import('../views/internal/Calendar'));
const Checklists = lazy(() => import('../views/internal/templates/Checklists'));
const Autocomplete = lazy(() =>
  import('../views/internal/templates/Autocomplete')
);
const Checklist = lazy(() =>
  import('../views/internal/templates/Checklists/Checklist')
);
const Collaborators = lazy(() => import('../views/internal/Collaborators'));
const Collaborator = lazy(() =>
  import('../views/internal/Collaborators/Collaborator')
);

export const internalRoutes = [
  // Workspaces
  {
    icon: 'icon-workspace',
    text: 'Workspaces',
    id: 'workspaces',
    link: internalRoutePaths.WORKSPACES,
    component: Workspaces,
  },
  {
    link: internalRoutePaths.WORKSPACES_ID,
    component: WorkspaceDetail,
  },
  {
    link: internalRoutePaths.WORKSPACE_CHILD,
    component: WorkspaceDetail,
  },
  {
    link: internalRoutePaths.WORKSPACES_ADD,
    component: WorkspaceDetail,
  },
  {
    link: internalRoutePaths.WORKSPACE_LEASE_INFO,
    component: WorkspaceDynamicForm,
  },
  {
    link: internalRoutePaths.WORKSPACE_MAINTENANCE_INFO,
    component: WorkspaceDynamicForm,
  },
  // LEGACY Workspace
  // {
  //   icon: 'icon-workspace',
  //   text: 'Workspaces',
  //   id: 'workspaces',
  //   link: internalRoutePaths.WORKSPACES,
  //   component: WorkspacesView,
  // },
  // {
  //   link: internalRoutePaths.WORKSPACES_ID,
  //   component: WorkspaceDetailView,
  // },
  // {
  //   link: internalRoutePaths.WORKSPACES_ADD,
  //   component: WorkspaceFormView,
  // },
  // {
  //   link: internalRoutePaths.WORKSPACES_EDIT,
  //   component: WorkspaceFormView,
  // },
  // {
  //   link: internalRoutePaths.WORKSPACE_CHILD,
  //   component: WorkspaceDetailView,
  // },
  // {
  //   link: internalRoutePaths.WORKSPACE_LEASE_INFO,
  //   component: WorkspaceDynamicForm,
  // },
  // {
  //   link: internalRoutePaths.WORKSPACE_MAINTENANCE_INFO,
  //   component: WorkspaceDynamicForm,
  // },

  // ISSUES
  {
    icon: 'icon-task',
    text: 'Issues',
    id: 'issues',
    link: internalRoutePaths.ISSUES,
    component: IssuesView,
  },
  {
    link: internalRoutePaths.ISSUES_ID,
    component: () => <Issue isEdit={true} />,
  },
  {
    link: internalRoutePaths.ISSUES_ADD,
    component: Issue,
  },

  // SCHEDULING
  {
    icon: 'icon-scheduling',
    text: 'Scheduling',
    id: 'scheduling',
    link: internalRoutePaths.SCHEDULING,
    component: SchedulingView,
    feature: 'schedules',
  },
  {
    link: internalRoutePaths.SCHEDULING_ADD,
    component: Schedule,
    feature: 'schedules',
  },
  {
    link: internalRoutePaths.SCHEDULING_ID,
    component: Schedule,
    feature: 'schedules',
  },

  // EQUIPMENT
  {
    icon: 'icon-equipment',
    text: 'Equipment',
    id: 'equipment',
    link: internalRoutePaths.EQUIPMENT,
    /* isBetaVersion: true, */
    component: EquipmentView,
    feature: 'equipment',
  },
  {
    link: internalRoutePaths.EQUIPMENT_ADD,
    component: Equipment,
    feature: 'equipment',
  },
  {
    link: internalRoutePaths.EQUIPMENT_ID,
    component: Equipment,
    feature: 'equipment',
  },

  // EQUIPMENT GROUPS
  {
    // TODO: Temporary like this. Find a way to make it beautiful in sidebar
    icon: '-',
    text: 'Groups',
    id: 'equipment-groups',
    link: internalRoutePaths.EQUIPMENT_GROUPS,
    component: EquipmentGroupsView,
    feature: 'equipment',
  },
  // EQUIPMENT TYPES
  {
    // TODO: Temporary like this. Find a way to make it beautiful in sidebar
    icon: '-',
    text: 'Types',
    id: 'equipment-types',
    link: internalRoutePaths.EQUIPMENT_TYPES,
    component: EquipmentTypesView,
    feature: 'equipment',
  },
  // EQUIPMENT EXTERNAL HOLDERS
  {
    // TODO: Temporary like this. Find a way to make it beautiful in sidebar
    icon: '-',
    text: 'External holder',
    id: 'external-holder',
    link: internalRoutePaths.EQUIPMENT_EXTERNAL_HOLDERS,
    component: ExternalHolders,
    feature: 'equipment',
  },

  // ASSETS
  {
    icon: 'icon-assets',
    text: 'Assets',
    id: 'assets',
    link: internalRoutePaths.ASSETS,
    component: Assets,
    feature: 'assets',
  },
  {
    link: internalRoutePaths.ASSETS_ADD,
    component: Asset,
    feature: 'assets',
  },
  {
    link: internalRoutePaths.ASSETS_ID,
    component: Asset,
    feature: 'assets',
  },
  // ASSETS GROUPS
  {
    // TODO: Temporary like this. Find a way to make it beautiful in sidebar
    icon: '-',
    text: 'Groups',
    id: 'assets-groups',
    link: internalRoutePaths.ASSETS_GROUPS,
    component: AssetsGroups,
    feature: 'assets',
  },
  // ASSETS TYPES
  {
    // TODO: Temporary like this. Find a way to make it beautiful in sidebar
    icon: '-',
    text: 'Systems',
    id: 'assets-systems',
    link: internalRoutePaths.ASSETS_SYSTEMS,
    component: AssetsSystems,
    feature: 'assets',
  },

  // CATEGORIES
  {
    icon: 'icon-categories',
    text: 'Categories',
    id: 'categories',
    link: internalRoutePaths.CATEGORIES,
    component: CategoriesView,
    feature: 'categories',
  },

  //COLLABORATORS
  {
    icon: 'icon-collaborators',
    text: 'Collaborators',
    id: 'collaborators',
    isBetaVersion: true,
    link: internalRoutePaths.COLLABORATORS,
    component: Collaborators,
    feature: 'collab',
  },
  {
    link: internalRoutePaths.COLLABORATORS_ID,
    component: Collaborator,
    feature: 'collab',
  },

  {
    text: 'Issue add subtask',
    link: internalRoutePaths.SUBTASK_ID,
    component: () => {},
  },
  {
    icon: 'icon-peoples',
    text: 'Users',
    id: 'users',
    link: internalRoutePaths.USERS,
    component: UsersView,
    feature: 'users',
  },
  {
    text: 'Profile',
    id: 'profile',
    link: internalRoutePaths.USER,
    component: UserPage,
  },
  {
    text: 'Edit Profile',
    id: 'edit-profile',
    link: internalRoutePaths.USER_EDIT,
    component: Profile,
  },
  {
    text: 'Profile Detail',
    id: 'profile-detail',
    link: internalRoutePaths.USER_DETAIL,
    component: UserPage,
  },

  {
    text: '-',
    id: 'calendar',
    link: internalRoutePaths.CALENDAR,
    component: Calendar,
  },

  //templates
  {
    icon: '-',
    text: 'Checklists',
    id: 'checklists',
    link: internalRoutePaths.TEMPLATES_CHECKLISTS,
    component: Checklists,
    feature: 'templates',
  },

  {
    icon: '-',
    text: 'Autocomplete',
    id: 'autocomplete',
    link: internalRoutePaths.TEMPLATES_AUTOCOMPLETE,
    component: Autocomplete,
    feature: 'templates',
  },
  {
    link: internalRoutePaths.TEMPLATES_CHECKLISTS_ID,
    component: () => <Checklist isEdit={true} />,
    feature: 'templates',
  },
  {
    link: internalRoutePaths.TEMPLATES_CHECKLISTS_ADD,
    component: Checklist,
    feature: 'templates',
  },

  //settings
  {
    icon: 'icon-settings',
    text: 'Settings',
    id: 'settings',
    link: '/dashboard/settings/general',
    component: General,
    feature: 'settings',
  },

  {
    icon: '-',
    text: 'Profile',
    id: 'profile',
    link: internalRoutePaths.PROFILE,
    component: Profile,
    feature: 'settings',
  },
  {
    icon: '-',
    text: 'PDF reports',
    id: 'pdf_reports',
    link: internalRoutePaths.PDF_REPORTS,
    component: PDFReports,
    feature: 'settings',
  },
  {
    icon: '-',
    text: 'Public Forms',
    id: 'public_forms',
    link: internalRoutePaths.PUBLIC_FORMS,
    component: PublicForms,
    feature: 'settings',
  },
];
