import styled from 'styled-components/macro';
import { WowColorizeText } from '../../../../../../components_new/WowBasicElements';

export const CustomAction = styled.div`
  padding: 20px 24px 24px;
  border-top: 1px solid #e6edf4;
`;

export const HelperText = styled(WowColorizeText)`
  display: block;
  max-width: 250px;
`;
