import { createSlice } from '@reduxjs/toolkit';
import { INIT_QUERY_PARAMS } from './constants';

const INITIAL_STATE = {
  filters: INIT_QUERY_PARAMS,
};

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = workspacesSlice.actions;

export default workspacesSlice.reducer;
