import React, { forwardRef, useState } from 'react';
import { usePopper } from 'react-popper';

import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';

import { MenuItem, MenuItems, Menu } from '../Menu';
import { Button, DropdownIcon, Icon, StyledDropdown } from './styles';
import { FormattedMessage } from 'react-intl';

const Dropdown = forwardRef(
  (
    {
      icon,
      onChange,
      options,
      value,
      color,
      size,
      disabled,
      backgroundColor = '',
      borderRadius,
      dropdownTestId,
      dropdownMenuTestId,
      itemTestId,
      labelPrefix,
      customIcon,
      hideCheckedIcon = false,
      height,
      ...rest
    },
    forwardedRef
  ) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    });

    const { ref, isVisible, setIsVisible } = useVisible(false);

    useKeyPress('Escape', () => setIsVisible(false));

    const handleClick = (e) => {
      if (disabled) {
        return;
      }

      setIsVisible(!isVisible);
    };

    const handleChange = (value) => (e) => {
      e.stopPropagation();

      setIsVisible(false);

      onChange(value);
    };

    const selected = options?.find?.((option) => option.value === value);

    /** `indicator` to show dropdown icon in case when it's not disabled or there's background color according to design */
    const showDropdownIcon = !disabled || backgroundColor;

    return (
      <StyledDropdown ref={ref} size={size}>
        <Button
          type="button"
          onClick={handleClick}
          ref={setReferenceElement}
          color={color}
          backgroundColor={backgroundColor}
          size={size}
          borderRadius={borderRadius}
          data-testid={dropdownTestId}
        >
          <div>
            {icon ? <Icon className={`icon ${icon}`} color={color} /> : null}
            {labelPrefix ? labelPrefix : null}
            {selected ? selected.label : <FormattedMessage id="select" />}
          </div>
          {showDropdownIcon
            ? customIcon || (
                <DropdownIcon isOpen={isVisible} mainAccent color={color} />
              )
            : null}
        </Button>

        {isVisible ? (
          <Menu
            ref={setPopperElement}
            style={styles.popper}
            size={size}
            {...attributes.popper}
            className="dropdown-menu"
            data-testid={dropdownMenuTestId ?? 'dropdownMenu'}
          >
            <MenuItems height={height}>
              {options?.map((option) => (
                <MenuItem
                  onClick={handleChange(option)}
                  selected={selected && option.value === selected.value}
                  tabIndex={0}
                  key={option.value}
                  data-testid={itemTestId || option.itemTestId}
                  mainAccent
                >
                  {option.label}
                  {selected &&
                  !hideCheckedIcon &&
                  option.value === selected.value ? (
                    <i className="icon icon-check" />
                  ) : null}
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        ) : null}
      </StyledDropdown>
    );
  }
);

export default Dropdown;
