import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > button {
    width: 150px;
  }
`;
export const Icon = styled.i`
  font-size: 82px;
  opacity: 0.2;
`;

export const Heading = styled.div`
  font-size: 22px;
  color: #4b5763;
  font-weight: 600;
  margin-bottom: 24px;
  text-align: center;
`;
