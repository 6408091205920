import Collapsible from '../../../Collapsible';
import usePreviewAction from '../../hooks/usePreviewAction';

const ItemsMultiselect = (props) => {
  const { filterKey, value, labelKey, labelPrefixKey } = props;

  const { handleMultiselectChange } = usePreviewAction();

  const handleItemChange = (item) => handleMultiselectChange(filterKey, item);

  return (
    <Collapsible
      labelKey={labelKey}
      count={value.length}
      items={value}
      handleItemChange={handleItemChange}
      labelPrefixKey={labelPrefixKey}
    />
  );
};

export default ItemsMultiselect;
