import styled from 'styled-components/macro';
import {
  filtersAdvancedGray,
  hardWhite,
  darkGray,
  gray,
  darkerGray,
} from '../../../../config/colors';
import MenuItem from '../../../../components_new/Menu/MenuItem';
import { Menu } from '../../../../components_new/Menu';

export const Search = styled.div`
  background-color: ${hardWhite};
  display: flex;
  border-radius: 4px;
  padding: 6px 8px;
  margin-bottom: 16px;
  margin-top: -14px;
`;

export const Input = styled.input.attrs({ type: 'text' })`
  outline: none;
  width: 100%;
  color: ${filtersAdvancedGray};
  background-color: transparent;
  font-size: 15px;
`;

export const Icon = styled.i`
  margin-right: 8px;
  color: ${gray};
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleText = styled.div`
  color: ${darkGray};
  font-size: 20px;
  padding-left: 10px;
`;

export const Content = styled.div`
  height: 480px;
  overflow-y: visible;
  margin: 10px -20px 10px 0px;
`;

export const WorkspaceOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 10px 14px 0;
  cursor: pointer;
`;

export const SubWorkspaceOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 10px 14px 0;
  margin-left: 20px;
  cursor: pointer;
`;

export const OptionLabel = styled.span`
  flex-grow: 1;
  font-size: 18px;
  margin-left: 12px;
  color: #44566c;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  > button {
    padding-left: 0px;
    padding-right: 0px;
    width: 136px;
  }
`;

export const NoItems = styled.div`
  padding: 15px 15px 15px 15px;
  text-align: center;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  margin-top: 25px;
  margin-bottom: 10px;

  div:first-child {
    position: relative;
    width: 20px;
  }
`;

export const Text = styled.span`
  white-space: nowrap;
`;

export const IconClose = styled.i.attrs({ className: 'icon icon-close' })`
  font-size: 12px;
  padding-left: 6px;
  margin-right: -4px;
`;

export const IconCheck = styled.i.attrs({ className: 'icon icon-check' })`
  font-weight: bold;
  margin-right: 10px;
`;

export const StyledMenu = styled(Menu)`
  width: auto;
  min-width: 160px;
`;
export const StyledMenuItem = styled(MenuItem)`
  text-transform: uppercase;
  font-size: 12px;
  padding: 18px 28px;
  justify-content: flex-start;
  color: ${darkerGray};
  font-weight: normal;
  letter-spacing: 2.18px;
`;
