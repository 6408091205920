import React, { useRef, useState } from 'react';
import {
  Title,
  IconsContainer,
  HeaderContainer,
  GearIcon,
  Dots,
  MenuWrapper,
  MenuContainer,
  MenuText,
  ShowUnreadContainer,
  OuterContainer,
  CancelText,
  UnreadText,
} from '../styles';
import { FormattedMessage } from 'react-intl';
import { Menu } from '../../../../../../components_new/Menu';
import ActionButton from '../../../../../../components/sticky-header/ActionButton';

const NotificationsHeader = ({
  showOnlyUnread,
  toggleShowUnread,
  goToSettings,
  handleMarkAll,
  unreadCount,
  pausedNotfs,
  handlePause,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const actionsMenuRef = useRef(null);
  return (
    <OuterContainer>
      <HeaderContainer>
        <Title>
          <FormattedMessage id="notifications" />
        </Title>
        <IconsContainer>
          <GearIcon className="icon icon-settings" onClick={goToSettings} />

          <Dots className="icon icon-dots" onClick={toggleMenu} />
        </IconsContainer>
        {showMenu && (
          <MenuContainer>
            <MenuWrapper>
              <Menu
                ref={actionsMenuRef}
                size="responsive"
                position="right"
                distanceX={55}
                distanceY={60}
              >
                {unreadCount && unreadCount > 0 ? (
                  <ActionButton
                    variant="menu-item"
                    onClick={() => {
                      toggleMenu();
                      handleMarkAll();
                    }}
                  >
                    <MenuText>
                      <FormattedMessage id="mark_all_read" />
                    </MenuText>
                  </ActionButton>
                ) : null}
                {!showOnlyUnread && (
                  <ActionButton
                    variant="menu-item"
                    onClick={() => {
                      toggleMenu();
                      toggleShowUnread();
                    }}
                  >
                    <MenuText>
                      <FormattedMessage id="show_unread_only" />
                    </MenuText>
                  </ActionButton>
                )}
                {!pausedNotfs && (
                  <ActionButton
                    variant="menu-item"
                    onClick={() => {
                      toggleMenu();
                      handlePause();
                    }}
                  >
                    <MenuText>
                      <FormattedMessage id="pause_1h" />
                    </MenuText>
                  </ActionButton>
                )}
              </Menu>
            </MenuWrapper>
          </MenuContainer>
        )}
      </HeaderContainer>
      {showOnlyUnread && (
        <ShowUnreadContainer smallBottom={pausedNotfs}>
          <UnreadText>
            <FormattedMessage id="showing_only_unread" />
          </UnreadText>
          <CancelText onClick={toggleShowUnread}>
            <FormattedMessage id="cancel" />
          </CancelText>
        </ShowUnreadContainer>
      )}
      {pausedNotfs && (
        <ShowUnreadContainer>
          <UnreadText>
            <FormattedMessage id="notifications_paused" />.
          </UnreadText>
          <CancelText onClick={handlePause}>
            <FormattedMessage id="cancel" />
          </CancelText>
        </ShowUnreadContainer>
      )}
    </OuterContainer>
  );
};

export default NotificationsHeader;
