import { useIntl } from 'react-intl';
import { Fragment } from 'react';
import Modal from '../../../Modal';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowLink,
} from '../../../WowBasicElements';
import { FormattedMessage } from 'react-intl';
import Fields from '../Fields';
import ModalFooterWrapper from '../ModalFooterWrapper';
import useAdd from '../../hooks/useAdd';
import { AddItemsWrapper, ContactButton } from '../../styles';
import Space from '../../../Space';
import { DEFAULT_USER, MODE } from '../../constants';
import {
  useCreateMultipleUsersMutation,
  useLazyGetIfUsersExistsQuery,
} from '../../../../views/internal/users/service';
import { darkerGray } from '../../../../config/colors';
import InfoMessage from '../InfoMessage';

const ModalAddUser = (props) => {
  const { isOpen, onClose, workspaceIds, mode, handleAddSuccessCallback } =
    props;

  const intl = useIntl();

  const [getIfUsersExists] = useLazyGetIfUsersExistsQuery();

  const {
    users,
    canSubmit,
    errors,
    showContactWowflow,
    handleAdd,
    handleReset,
    handleChange,
    handleValidateUserEmail,
    handleDelete,
    formatNewUsersPayload,
    handleSetActive,
  } = useAdd(DEFAULT_USER, getIfUsersExists);

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const [createMultipleUsers, { isLoading }] = useCreateMultipleUsersMutation();

  const handleSave = async () => {
    const payload = {
      new_users: formatNewUsersPayload(),
      workspace_ids: workspaceIds,
    };

    const response = await createMultipleUsers(payload);
    if (response?.data?.success) {
      handleAddSuccessCallback(response.data.new_users ?? []);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      hasTitleIcon
      iconClass="icon-arrowleft"
      title={intl.formatMessage({ id: 'add_user' })}
      maxHeight="750px"
      dataTestId="modalAddUsers"
    >
      <AddItemsWrapper>
        {users.map((item) => {
          const { id, fields } = item;
          return (
            <Fragment key={id}>
              <Fields
                {...item}
                fields={showContactWowflow ? fields.slice(0, 1) : fields}
                handleChange={(field) => handleChange(id, field)}
                handleValidateUserEmail={() => handleValidateUserEmail(item)}
                errors={errors}
                handleDelete={handleDelete}
                handleSetActive={handleSetActive}
              />
            </Fragment>
          );
        })}
      </AddItemsWrapper>
      {mode === MODE.multi ? (
        <WowLink fWeight="700" uppercase onClick={handleAdd}>
          + <FormattedMessage id="add" />
        </WowLink>
      ) : null}

      {showContactWowflow ? (
        <>
          <InfoMessage>
            <WowDisplayFlex direction="column">
              <WowColorizeText size="14" color={darkerGray}>
                <FormattedMessage id="email_connected_to_existing_wowflow_client" />
              </WowColorizeText>
              <Space height="15" />
              <WowColorizeText size="14" color={darkerGray}>
                <FormattedMessage id="contact_wowflow_for_more_information" />
              </WowColorizeText>
            </WowDisplayFlex>
          </InfoMessage>

          <Space height="50" />

          <ContactButton
            type="button"
            onClick={() => (window.location = 'mailto:hello@wowflow.com')}
          >
            <FormattedMessage id="contact_wowflow" />
          </ContactButton>
        </>
      ) : (
        <>
          <Space height="20" />
          <ModalFooterWrapper
            handleCancel={handleClose}
            isActionDisabled={!canSubmit}
            handleAction={handleSave}
            isLoading={isLoading}
          />
        </>
      )}
    </Modal>
  );
};

export default ModalAddUser;
