import React from 'react';
import styled from 'styled-components/macro';
import { galleryBackdrop } from '../../../config/colors';
import { useCloseOnEsc } from '../../../hooks/useCloseOnEsc';

export const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 995;
`;

export const ModalBackdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 996;
  background: ${galleryBackdrop};
`;

export const GalleryModal = (props) => {
  useCloseOnEsc(props.toggleGalleryPopup, props.isOpen);

  const closeModal = () => {
    props.onRequestClose();
  };

  const { children } = props;

  if (!props.isOpen) {
    return null;
  }

  return (
    <ModalWrapper>
      <ModalBackdrop onClick={closeModal} />
      <Modal>{children}</Modal>
    </ModalWrapper>
  );
};
