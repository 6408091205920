import styled from 'styled-components/macro';
import { breakpoint } from '../../styles/styledComponents/media-breakpoints';

export const SliderWrapper = styled.div`
  margin: ${({ wrapperMargin }) =>
    wrapperMargin ? `${wrapperMargin};` : '0 2rem 0 2rem;'}
  height: 200px;
  position: relative;

  ${({ isSubtaskPopup }) =>
    isSubtaskPopup &&
    `
  margin: 0;
`}

  @media only screen and ${breakpoint.xs} {
    margin: 0 1rem;
  }
`;

export const DocumentListAndAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => (props.$isPublic ? '0px' : '0px 32px')};

  button {
    margin-left: 0px;
  }
`;
export const GalleryLoaderPlaceholder = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`;

export const DocumentListWrapper = styled.div`
  width: 100%;
  flex: 1;

  .document-row {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
