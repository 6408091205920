import { useIntl } from 'react-intl';
import { useFormContext, Controller } from 'react-hook-form';

import PriorityItem from '../../../../../IssuesV2/Issue/components/AddEditWrapper/components/SectionRight/components/PriorityItem';
import StartDateItem from '../../../../../IssuesV2/Issue/components/AddEditWrapper/components/SectionRight/components/StartDateItem';
import DueDateItem from '../../../../../IssuesV2/Issue/components/AddEditWrapper/components/SectionRight/components/DueDateItem';
import AssigneeItem from '../../../../../IssuesV2/Issue/components/AddEditWrapper/components/SectionRight/components/AssigneeItem';

import { Wrapper, FieldRow, InputFieldLabel, InputFieldBox } from './styles';
import { futureTaskIssueModalCalendar } from '../../../../../../../config/colors';
import useAuthorization from '../../../../../IssuesV2/hooks/useAuthorization';

const FieldsWrapper = ({ workspace, isFutureReadOnlyTask }) => {
  const intl = useIntl();
  const { control, watch } = useFormContext();
  const assignee = watch('assignee');
  const { isAdmin } = useAuthorization();

  return (
    <Wrapper>
      <FieldRow>
        <InputFieldLabel>
          {intl.formatMessage({ id: 'priority' })}
        </InputFieldLabel>
        <InputFieldBox data-testid="calendarIssueDetailsPriorityDropdown">
          <Controller
            name="priority_id"
            control={control}
            render={({ field }) => {
              return (
                <PriorityItem
                  {...field}
                  disabled={isFutureReadOnlyTask}
                  backgroundColor={
                    isFutureReadOnlyTask ? futureTaskIssueModalCalendar : ''
                  }
                />
              );
            }}
          />
        </InputFieldBox>
      </FieldRow>
      <FieldRow>
        <InputFieldLabel>{intl.formatMessage({ id: 'start' })}</InputFieldLabel>
        <InputFieldBox data-testid="calendarIssueDetailsStartDate">
          <Controller
            name="start_date"
            control={control}
            render={({ field }) => {
              return (
                <StartDateItem
                  {...field}
                  disabled={isFutureReadOnlyTask}
                  popperPlacement="top-end"
                  isAdmin={isAdmin}
                />
              );
            }}
          />
        </InputFieldBox>
      </FieldRow>
      <FieldRow>
        <InputFieldLabel>
          {intl.formatMessage({ id: 'deadline' })}
        </InputFieldLabel>
        <InputFieldBox data-testid="calendarIssueDetailsDueDate">
          <Controller
            name="due_date"
            control={control}
            render={({ field }) => {
              return (
                <DueDateItem
                  {...field}
                  disabled={isFutureReadOnlyTask}
                  popperPlacement="top-end"
                  isAdmin={isAdmin}
                />
              );
            }}
          />
        </InputFieldBox>
      </FieldRow>
      <FieldRow>
        <InputFieldLabel>
          {intl.formatMessage({ id: 'assignee' })}
        </InputFieldLabel>
        <InputFieldBox data-testid="calendarIssueDetailsAssignee">
          <Controller
            name="assignee_id"
            render={({ field }) => {
              return (
                <AssigneeItem
                  {...field}
                  disabled={isFutureReadOnlyTask}
                  options={assignee ? [assignee] : null}
                  hideWsAvailableAssigneesIds={false}
                  workspace={workspace}
                  lowOpacity={isFutureReadOnlyTask}
                />
              );
            }}
          />
        </InputFieldBox>
      </FieldRow>
    </Wrapper>
  );
};

export default FieldsWrapper;
