import { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DropdownWithSearchAndInfiniteScroll } from '../../../../../../components_new/Dropdown';
import useSkipQuery from '../../../../../../hooks/useSkipQuery';
import { useWorkspaces } from '../../../../Equipment/hooks'; //TO DO > lift

const WorkspaceItem = forwardRef(
  ({ disabled, item, setHackyDropdownPadding, ...rest }, ref) => {
    const { control } = useFormContext();
    const [getOptionsWorkspaces, setGetOptionsWorkspaces] = useState(false);
    const { skipQuery: skipQueryWorkspaces } = useSkipQuery({
      removeSkip: getOptionsWorkspaces,
    });

    const {
      scrollState: workspaceDropdownState,
      handleLoadMore: handleLoadMoreWorkspaces,
      handleSearch: handleSearchWorkspaces,
      isLoading: isLoadingWorkspaces,
      mergeStateItems: mergeStateItemsWorkspaces,
    } = useWorkspaces(null, skipQueryWorkspaces);

    useEffect(() => {
      if (item?.workspace) {
        const { id, name } = item?.workspace;
        mergeStateItemsWorkspaces({
          data: [{ id, name }],
        });
      }
    }, [item, mergeStateItemsWorkspaces]);

    return (
      <DropdownWithSearchAndInfiniteScroll
        {...rest}
        size="responsive"
        options={workspaceDropdownState.itemsList}
        control={control}
        handleLoadMore={handleLoadMoreWorkspaces}
        hasMore={workspaceDropdownState.hasMore}
        handleSearch={handleSearchWorkspaces}
        isLoading={isLoadingWorkspaces}
        handleGet={() => setGetOptionsWorkspaces(true)}
        onModalOpen={() => setHackyDropdownPadding(true)}
        onModalClose={() => setHackyDropdownPadding(false)}
        maxHeight={200}
        disabled={disabled}
        dataTestId="workspaceDropdown"
        menuItemsDataTestId="workspaceMenuItem"
        placeholder={disabled ? item?.workspace?.name : null}
      />
    );
  }
);

export default WorkspaceItem;
