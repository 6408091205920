import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { compareTimestamp } from '../../../helpers/date.helper';
import { parseReturnPeriod } from './Equipment/utils';

dayjs.extend(isBetween);

const skipGetRequestWithQueryParams = (queryParams) => {
  return !queryParams || Object.keys(queryParams).length === 0 ? true : false;
};

const calculateArchiveItemsCount = (item) => {
  const targetList = ['type', 'group'];
  let count = 0;

  for (let type of targetList) {
    count += item[type]?.statistics[`equipment_in_${type}`] || 0;
  }

  return count;
};

const formatArrayFromQueryParam = (queryParams, key) => {
  const isSingleId = typeof queryParams[key] === 'string';
  const formatedQueryParams = !queryParams[key]
    ? []
    : isSingleId
    ? [queryParams[key]]
    : queryParams[key];

  return formatedQueryParams;
};

const getDateNowTimestampStarOfDay = () => {
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate.getTime();
};

const shouldShowReservations = (reservations) =>
  reservations?.some((item) =>
    compareTimestamp(
      item.reservation_start_date,
      getDateNowTimestampStarOfDay()
    )
  );

const isReservedOrInBufferToday = (_reservations = []) => {
  const today = dayjs();
  let reservations = _reservations;

  reservations = _reservations.map((r) => {
    if (!r.buffer_days_reservations?.value) {
      return r;
    }

    const bufferStartDay = dayjs(r.reservation_start_date).subtract(
      r.buffer_days_reservations.value,
      'day'
    );

    return {
      ...r,
      reservation_start_date: bufferStartDay,
    };
  });

  const value = reservations?.some((reservation) => {
    const start = dayjs(reservation.reservation_start_date);

    const end = dayjs(reservation.return_at);

    const isBetween = today.isBetween(start, end, 'day', '[]');

    return isBetween;
  });

  return value;
};

const checkIsDateInThePast = (date) => {
  const today = dayjs().startOf('day');
  const formatPassedDate = dayjs(date).startOf('day');
  const isInThePast = formatPassedDate.isBefore(today, 'day');

  return isInThePast;
};

const formatReturnAt = (defaultReturnPeriod, maxReturnDate) => {
  if (!defaultReturnPeriod) {
    return dayjs();
  }

  const returnDate = parseReturnPeriod(defaultReturnPeriod);

  if (!maxReturnDate) return returnDate;

  const formatedMaxReturnDate = dayjs(maxReturnDate).startOf('day');
  const formatedReturnDate = returnDate
    .startOf('day')
    .isAfter(formatedMaxReturnDate)
    ? dayjs(maxReturnDate)
    : returnDate;

  return formatedReturnDate;
};

export const getReservationWithActionsRequired = (reservations) => {
  let reservation;

  reservation = reservations.find((r) => {
    const startDate = r.reservation_start_date;
    const endDate = r.return_at;

    return (
      dayjs().isSame(startDate, 'day') || dayjs().isBetween(startDate, endDate)
    );
  });

  return reservation;
};

export const getPendingRequestsCount = (pendingRequests) => {
  if (!pendingRequests) return;
  return Object.keys(pendingRequests)
    .map((item) => pendingRequests[item].length)
    .reduce((a, b) => a + b);
};

export {
  skipGetRequestWithQueryParams,
  calculateArchiveItemsCount,
  formatArrayFromQueryParam,
  shouldShowReservations,
  getDateNowTimestampStarOfDay,
  isReservedOrInBufferToday,
  checkIsDateInThePast,
  formatReturnAt,
};
