import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getToken } from '../../../redux/utils';
import { BASE_URL } from '../../../config/constants';

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,

    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: ['Clients'],
  endpoints: (builder) => ({
    getClient: builder.query({
      query: (id) => ({ url: `client/${id}` }),
      providesTags: (result, error, id) => [{ type: 'Clients', id }],
    }),

    editClient: builder.mutation({
      query(payload) {
        const { id, ...restPayload } = payload;
        return {
          url: `client/${id}`,
          method: 'PUT',
          body: restPayload,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Clients', id: arg.id },
      ],
    }),
  }),
});

export const { useEditClientMutation, useGetClientQuery } = clientApi;
