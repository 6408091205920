import PropTypes from 'prop-types';
import { WowColorizeText } from '../../../../../../WowBasicElements';
import { darkerGray } from '../../../../../../../config/colors';
import { WowCheckbox } from '../../../../../../../components/wow-checkbox';
import { CollapsibleItem } from '../styles';

const Item = (props) => {
  const { item, handleItemChange } = props;

  return (
    <CollapsibleItem>
      <WowColorizeText color={darkerGray} size="12" fWeight="600">
        {item.name || item.title || item.full_name}
      </WowColorizeText>
      <WowCheckbox checked={true} onChange={() => handleItemChange(item)} />
    </CollapsibleItem>
  );
};

Item.propTypes = {
  item: PropTypes.object.isRequired,
  handleItemChange: PropTypes.func.isRequired,
};

export default Item;
