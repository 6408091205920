export const ISSUE_STATUSES = {
  DRAFT: 'DRAFT',
  TODO: 'TO_DO',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  COMPLETED: 'COMPLETED',
  ARCHIVED: 'archived',
  ALL: 'all',
  ALL_ACTIVE: 'ALL_ACTIVE',
  ALL_ARCHIVED: 'ALL_ARCHIVED',
};

export const ACTIVE_ISSUE_STATUSES = [
  ISSUE_STATUSES.DRAFT,
  ISSUE_STATUSES.TODO,
  ISSUE_STATUSES.DECLINED,
  ISSUE_STATUSES.ACCEPTED,
  ISSUE_STATUSES.IN_PROGRESS,
  ISSUE_STATUSES.IN_REVIEW,
  ISSUE_STATUSES.COMPLETED,
];

export const ARCHIVED_ISSUE_STATUSES = [
  ISSUE_STATUSES.DECLINED,
  ISSUE_STATUSES.COMPLETED,
  ISSUE_STATUSES.IN_REVIEW,
];

export const ACTIVE_ISSUE_STATUSES_OPTIONS = [
  {
    id: 1,
    value: ISSUE_STATUSES.ALL_ACTIVE,
    labelKey: 'active',
    checkboxKey: 'all',
    isBold: true,
  },
  {
    id: 2,
    value: ISSUE_STATUSES.DRAFT,
    labelKey: 'draft',
  },
  {
    id: 3,
    value: ISSUE_STATUSES.TODO,
    labelKey: 'to_do',
  },
  {
    id: 4,
    value: ISSUE_STATUSES.ACCEPTED,
    labelKey: 'accepted',
  },
  {
    id: 5,
    value: ISSUE_STATUSES.DECLINED,
    labelKey: 'declined',
  },
  {
    id: 6,
    value: ISSUE_STATUSES.IN_PROGRESS,
    labelKey: 'in_progress',
  },
  {
    id: 7,
    value: ISSUE_STATUSES.IN_REVIEW,
    labelKey: 'in_review',
  },
  {
    id: 8,
    value: ISSUE_STATUSES.COMPLETED,
    labelKey: 'completed',
  },
];

export const ARHIVED_ISSUE_STATUSES_OPTIONS = [
  {
    id: 1,
    value: ISSUE_STATUSES.ALL_ARCHIVED,
    labelKey: 'archived',
    checkboxKey: 'all',
    isBold: true,
  },
  {
    id: 2,
    value: ISSUE_STATUSES.DECLINED,
    labelKey: 'declined',
  },
  {
    id: 3,
    value: ISSUE_STATUSES.IN_REVIEW,
    labelKey: 'in_review',
  },
  {
    id: 4,
    value: ISSUE_STATUSES.COMPLETED,
    labelKey: 'completed',
  },
];
