import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOnboardingProgress } from '../../../../../../../redux/config/selectors';
import useOnboarding from '../../../hooks/useOnboarding';
import { FEATURES } from '../constants';
import { STEP_TYPES } from '../../../constants';

const useFeatures = ({ formMethods } = {}) => {
  const onboardingProgress = useSelector(getOnboardingProgress);
  const { setValue, watch, formState } = formMethods;
  const { isDirty } = formState;
  const selected = watch(FEATURES);

  const formatedOptions = useMemo(() => {
    const options = onboardingProgress?.features ?? [];

    return options.map((item, index) => ({ id: index, ...item }));
  }, [onboardingProgress?.features]);

  const handleChange = (data) => {
    const { name } = data ?? {};

    const isSelected = selected.includes(name);

    const newValues = isSelected
      ? selected.filter((item) => item !== name)
      : [...selected, name];

    setValue(FEATURES, newValues, { shouldDirty: true });
  };

  const { handleUpdateSteps, isLoading } = useOnboarding();

  const handleContinue = (data) => {
    if (isLoading) return;
    const { features, goals } = data;
    handleUpdateSteps({
      stepType: STEP_TYPES.features,
      features,
      ...(goals.length && { goals }),
    });
  };

  const handleSkip = () => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.features,
      isSkipped: true,
    });
  };

  return {
    formatedOptions,
    selected,
    handleChange,
    handleContinue,
    handleSkip,
    isLoading,
    isDisabled: !isDirty || isLoading,
  };
};

export default useFeatures;
