import { FormattedMessage } from 'react-intl';
import ButtonAdd from '../../../../../components_new/ButtonAdd';
import Space from '../../../../../components_new/Space';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
} from '../../../../../components_new/WowBasicElements';
import { darkerGray, gradientBlue } from '../../../../../config/colors';
import { PrintWrapper } from '../../../workspaces/workspace/ModalQrCodeTimelog/styles';

const Footer = ({ hasMore, handleLoadMore, handlePrint }) => {
  return (
    <>
      <WowDisplayFlex justify="space-between">
        <WowDisplayFlex>
          {hasMore ? (
            <ButtonAdd
              appearance="contained"
              onClick={handleLoadMore}
              icon={null}
            >
              <FormattedMessage id="show_more" />
            </ButtonAdd>
          ) : null}
        </WowDisplayFlex>

        <PrintWrapper>
          <WowDisplayFlex onClick={handlePrint}>
            <WowIcon
              size="20"
              className="icon icon-print-1"
              color={gradientBlue}
              spaceRight="7"
            />
            <WowColorizeText size="14" color={darkerGray}>
              <FormattedMessage id="print" />
            </WowColorizeText>
          </WowDisplayFlex>
        </PrintWrapper>
      </WowDisplayFlex>
      <Space height="50" />
    </>
  );
};

export default Footer;
