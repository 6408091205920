import { formatTimestampToDateAndMonthAndYear } from '../helpers/date.helper';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';

export const parseNotificationsByDate = (notifications = [], lang) => {
  const parsedNotifications = {};
  notifications.forEach((item) => {
    const day = formatTimestampToDateAndMonthAndYear(item.created_at, lang);
    const dayName = `${day}`;

    if (parsedNotifications[dayName]) {
      parsedNotifications[dayName].push(item);
    } else {
      parsedNotifications[dayName] = [item];
    }
  });

  return parsedNotifications;
};
export const groupNotifications = (items, intl) => {
  const notifications = items.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  const groupedNotificationsToday = groupBy(notifications, (notification) =>
    dayjs(notification.created_at).isSame(dayjs(), 'day')
  );
  const todayNotifications = groupedNotificationsToday['true'] || [];
  const otherNotifications = groupedNotificationsToday['false'] || [];

  const groupedNotificationsWeek = groupBy(otherNotifications, (notification) =>
    dayjs(notification.created_at).isSame(dayjs(), 'week')
  );

  const weekNotifications = groupedNotificationsWeek['true'] || [];
  const restNotifications = groupedNotificationsWeek['false'] || [];

  const todaySection = {
    data: todayNotifications,
    title: intl.formatMessage({
      id: 'today',
    }),
  };
  const weekSection = {
    data: weekNotifications,
    title: intl.formatMessage({
      id: 'this_week',
    }),
  };
  const restSection = {
    data: restNotifications,
    title: intl.formatMessage({
      id: 'older',
    }),
  };
  return [todaySection, weekSection, restSection];
};

export const checkIfAllNotificationsRead = (notifications) => {
  const count = notifications?.reduce(
    (accumulator, currentValue) =>
      currentValue.read_at ? accumulator : accumulator + 1,
    0
  );

  return count;
};
