import { forwardRef } from 'react';
import { StyledInput } from './styles';
import { WowColorizeText } from '../WowBasicElements';
import { newRed } from '../../config/colors';
import { FormattedMessage } from 'react-intl';

const Input = forwardRef((props, ref) => {
  const { errorKey, enableFocus = true } = props;
  return (
    <>
      <StyledInput
        ref={ref}
        enableFocus={enableFocus}
        data-testid={`${props.name}Input` ?? 'input'}
        {...props}
      />
      {errorKey && props.error ? (
        <WowColorizeText color={newRed} size="12" data-testid="inputError">
          <FormattedMessage id={errorKey} />
        </WowColorizeText>
      ) : null}
    </>
  );
});

export default Input;
