import { STEP_TYPES } from '../../../constants';
import useOnboarding from '../../../hooks/useOnboarding';
import { ISSUE_TITLE } from '../constants';
import useTrialRedirect from '../../../hooks/useTrialRedirect';

const useCreateIssue = ({ formMethods } = {}) => {
  const { watch } = formMethods;
  const issueTitle = watch(ISSUE_TITLE);

  const { handleUpdateSteps, isLoading, firstSteps } = useOnboarding();

  const { handleRedirectTo } = useTrialRedirect();

  const handleContinue = (data) => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.createIssue,
      ...data,
    });
  };

  const handleSkip = () => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.createIssue,
      isSkipped: true,
    });

    handleRedirectTo(firstSteps.workspaceId);
  };

  return {
    issueTitle,
    isLoading,
    handleContinue,
    handleSkip,
    firstSteps,
  };
};

export default useCreateIssue;
