import styled from 'styled-components/macro';

import { boxShadow, white, boxShadowDarker } from '../../config/colors';

const Menu = styled.div`
  position: absolute;
  top: ${({ distanceY }) => (distanceY ? distanceY + 'px' : '40px')};
  width: ${({ size, hardcodedWidth }) =>
    hardcodedWidth ? hardcodedWidth : size === 'responsive' ? '100%' : '200px'};
  background-color: ${white};
  border-radius: 10px;
  /* box-shadow: 0 4px 20px 0 ${boxShadow}; */
  box-shadow: 0px 2px 10px ${boxShadowDarker};
  z-index: 100;
  overflow-y: hidden;
  right: ${({ position, distanceX }) =>
    position === 'right' ? (distanceX ? distanceX + 'px' : '0px') : 'auto'};
`;

export default Menu;
