import { matchPath } from 'react-router';
import { SECTIONS } from '../../../../helpers/sections';
import { TO_EXCLUDE_IF_NOT_MAIN_ORGANISATION } from '../constants';

export const filterSectionsByPermissions = (
  {
    include_schedules,
    include_equipment,
    include_assets,
    include_collab,
    only_admins_see_users,
    can_member_see_templates,
  } = {},
  isAdmin,
  isMainOrganisation
) => {
  const blockedFeatures = [];

  if (!include_schedules) blockedFeatures.push('scheduling');
  if (!include_equipment) blockedFeatures.push('equipment');
  if (!include_assets) blockedFeatures.push('assets');
  if (!include_collab || !isAdmin) blockedFeatures.push('collaborators');
  if (only_admins_see_users && !isAdmin) blockedFeatures.push('users');
  if (!can_member_see_templates && !isAdmin) blockedFeatures.push('templates');

  if (!isMainOrganisation)
    blockedFeatures.push(...TO_EXCLUDE_IF_NOT_MAIN_ORGANISATION);

  return SECTIONS.filter((section) => !blockedFeatures.includes(section.id));
};

export const getIsActiveSection = (url, route) => matchPath(url, route);
