import vest, { enforce, test } from 'vest';
import { INPUT_MAX_LENGTH } from '../../constants';
import { ISSUE_TITLE } from './constants';

export const formValidation = vest.create((data = {}) => {
  const { issueTitle } = data;

  test(ISSUE_TITLE, `TMP_issue_title_not_empty`, () => {
    enforce(issueTitle).isNotEmpty();
  });

  test(ISSUE_TITLE, `TMP_issue_title_max_length`, () => {
    enforce(issueTitle).shorterThanOrEquals(INPUT_MAX_LENGTH);
  });
});
