import { forwardRef } from 'react';
import { InputWrapper } from './styles';
import { WowIcon } from '../../../../../WowBasicElements';

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <InputWrapper onClick={onClick} ref={ref}>
    {value}

    <WowIcon className="icon icon-calendar" size="15" spaceRight="0" />
  </InputWrapper>
));

export default CustomInput;
