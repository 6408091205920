import { FormattedMessage, useIntl } from 'react-intl';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { UNSCHEDULED_FILTERS_TABS } from '../../constants';
import { transformAndFormatDate } from '../../../../../../../helpers/date.helper';
import { statusColors } from '../../../../../../../config/colors';

import { LoadingSpinner } from '../../../../../../../components/loading-spinner/loading-spinner.component';
import {
  Counter,
  Sort,
} from '../../../../../../../components_new/StickyHeader';

import { DataItemWithDueDateWrapper, DataItemDate } from './styles';
import {
  DataItemStatus,
  DataItemLabel,
  TabWrapper,
  SpinnerWrapper,
  SmallFiltersWrapper,
  DataWrapper,
} from '../../styles';
import InfiniteScroll from '../../../../../../../components_new/InfiniteScroll';
import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../../../components_new/WowBasicElements';
import { droppableElementStyles } from '../../../../utils';

const OverdueTabContent = ({ overdueHookData, scrollMaxHeight }) => {
  const { scrollState, handleLoadMore, isProcessing, handleCustomQuery } =
    overdueHookData;

  const intl = useIntl();
  const unscheduledIssueCurrentDragId = useSelector(
    (state) => state?.calendar?.unscheduledIssueCurrentDragId
  );
  const { sort_by: sortBy, sort_direction: sortDirection } = scrollState.query;
  const overdueItemsCount = scrollState.itemsList.length;

  const handleOnOrderChange = () => {
    handleCustomQuery({
      sort_direction: sortDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleOnSortChange = (value) => {
    handleCustomQuery({
      sort_by: value,
    });
  };

  return (
    <TabWrapper data-testid="overdueTabContent">
      <SmallFiltersWrapper>
        <Counter number={overdueItemsCount} />
        <Sort
          onChange={handleOnSortChange}
          onOrderChange={handleOnOrderChange}
          options={UNSCHEDULED_FILTERS_TABS?.OVERDUE?.sortOptions.map(
            (sortOption) => ({
              value: sortOption.value,
              label: intl.formatMessage({
                id: sortOption.label,
              }),
            })
          )}
          order={sortDirection}
          selectedOption={sortBy}
          menuPosition="right"
        />
      </SmallFiltersWrapper>
      <DataWrapper>
        {overdueItemsCount === 0 ? (
          isProcessing ? (
            <SpinnerWrapper>
              <LoadingSpinner size="small" />
            </SpinnerWrapper>
          ) : (
            <WowDisplayFlex>
              <WowColorizeText size={14}>
                <FormattedMessage id="no_available_data" />
              </WowColorizeText>
            </WowDisplayFlex>
          )
        ) : (
          <Droppable droppableId="overdueDroppableId" isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <InfiniteScroll
                  dataLength={scrollState.itemsList.length}
                  handleLoadMore={handleLoadMore}
                  hasMore={scrollState.hasMore}
                  height={-1}
                  maxHeight={scrollMaxHeight ?? 550}
                  customStyle={{ paddingRight: '10px' }}
                >
                  {scrollState.itemsList.map((item, index) => {
                    return (
                      <Draggable
                        key={String(item.id)}
                        draggableId={`overdue-${String(item.id)}`}
                        index={index}
                        isDragDisabled={
                          unscheduledIssueCurrentDragId ? true : false
                        }
                      >
                        {(provided, snapshot) => (
                          <DataItemWithDueDateWrapper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            style={droppableElementStyles(
                              provided?.draggableProps?.style ?? {},
                              snapshot ?? {}
                            )}
                          >
                            <WowDisplayFlex justify="space-between" flex="1">
                              <WowDisplayFlex flex="1">
                                <DataItemStatus
                                  backgroundColor={
                                    statusColors[item?.status?.name] ?? '#000'
                                  }
                                />
                                <DataItemLabel>{item?.title}</DataItemLabel>
                              </WowDisplayFlex>
                              <DataItemDate>
                                {transformAndFormatDate(Date.now())}
                              </DataItemDate>
                            </WowDisplayFlex>
                          </DataItemWithDueDateWrapper>
                        )}
                      </Draggable>
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
          </Droppable>
        )}
      </DataWrapper>
    </TabWrapper>
  );
};

export default OverdueTabContent;
