export const BETA_LABEL = 'beta';
export const ALPHA_LABEL = 'alpha';
export const API_TAG_TYPES_PROFILE = [
  'Notifications',
  'Notification',
  'UserStatistics',
  'UserOftenDetails',
];

export const ID_KEYS = {
  mainHeader: 'mainHeader',
  avatar: 'avatar',
  menuToggler: 'menuToggler',
  menuWrapper: 'menuWrapper',
  organisationWrapper: 'organisationWrapper',
  notificationIcon: 'notificationIcon',
  mainMenu: 'mainMenu',
};

export const TO_EXCLUDE_IF_NOT_MAIN_ORGANISATION = [
  'equipment',
  'users',
  'categories',
  'templates',
  'settings',
];
