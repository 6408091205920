import { useFormContext } from 'react-hook-form';
import { RAW_ISSUE_STATUSES } from '../../../../../../../constants';

const useAssigneeItem = () => {
  const { setValue } = useFormContext();

  const handleOnChangeCallback = ({ value, workspace, issue, items } = {}) => {
    const _isInReview = issue?.status_id === 7;
    const isAdmin = workspace?.admins_ids?.includes(value);
    const isManager = workspace?.managers_ids?.includes(value);
    const isGroupManager = workspace?.group_managers_ids?.includes(value);

    const isAdminOrManager = isAdmin || isManager || isGroupManager;

    const _statusId = _isInReview && isAdminOrManager ? 7 : value ? 3 : 2;

    setValue('status_id', _statusId);

    if (RAW_ISSUE_STATUSES[_statusId])
      setValue('status', RAW_ISSUE_STATUSES[_statusId]);

    setValue('assignee', value && items ? items[0] : null);

    setValue('assignee_id', value ?? '', {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return {
    handleOnChangeCallback,
  };
};

export default useAssigneeItem;
