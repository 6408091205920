import {
  WS_ISSUE_LIST,
  WORKSPACE_LIST,
  FAVORITE_WORKSPACES,
  USER_LIST,
  CATEGORY_LIST,
  SUB_WS_LIST,
  ISSUE_LIST,
  ISSUE_CATEGORY,
  AUTOCOMPLETE_LIST,
} from './filters';

export const actionStrings = {
  FILTER_ENTITY: 'FILTER_ENTITY',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SET_FILTERS: 'SET_FILTERS',
  TOGGLE_ADVANCED_FILTERS: 'TOGGLE_ADVANCED_FILTERS',
};

export const filterEntity = (filterName, entityType, parameters) => ({
  type: actionStrings.FILTER_ENTITY,
  payload: {
    filterName,
    entityType,
    parameters,
  },
});

export const setFilter = ({ filterName = '', parameters = [] }) => ({
  type: actionStrings.SET_FILTERS,
  payload: {
    filterName,
    parameters,
  },
});

export const setFilterFor = (filterName = '') => (parameters = {}) => {
  return {
    type: actionStrings.SET_FILTERS,
    payload: {
      filterName,
      parameters,
    },
  };
};

export const clearFilter = () => ({
  type: actionStrings.CLEAR_FILTER,
  payload: {},
});
export const toggleAdvancedFilters = () => ({
  type: actionStrings.TOGGLE_ADVANCED_FILTERS,
});

export const setWorkspaceListFilter = setFilterFor(WORKSPACE_LIST);
export const setFavoriteWorkspaceFilter = setFilterFor(FAVORITE_WORKSPACES);
export const setUsersFilter = setFilterFor(USER_LIST);
export const setCategoryFilter = setFilterFor(CATEGORY_LIST);
export const setIssueCategoryFilter = setFilterFor(ISSUE_CATEGORY);
export const setWsIssueFilter = setFilterFor(WS_ISSUE_LIST);
export const setSubWsFilter = setFilterFor(SUB_WS_LIST);
export const setIssueListFilter = setFilterFor(ISSUE_LIST);
export const setAutocompleteTemplateFilter = setFilterFor(AUTOCOMPLETE_LIST);
