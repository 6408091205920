import dayjs from 'dayjs';

import {
  CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD,
  WEEKLY_ITEMS_OVERLAP,
  COLUMN_NUMBER,
} from './constants';

export const getCurrentTimeMarkerPosition = (params = {}) => {
  const { eachHourWidthPxBox, eachHourHeightPxBox } = params;

  let value = 0;

  // Calculating .left or .top for current time marker in timeline.
  const msFromStartDate =
    new Date().getTime() - new Date(dayjs().startOf('day')).getTime();
  if (msFromStartDate) {
    const hours = msFromStartDate / 3600000;
    value = hours * (eachHourWidthPxBox || eachHourHeightPxBox);
  }

  return value;
};

export const getTimelineData = (items = [], additionalParams = {}) => {
  const {
    eachHourWidthPxBox,
    isWeeklyTimeline,
    eachHourHeightPxBox,
    startDayTime = new Date(),
  } = additionalParams;

  const calcuateDimension = isWeeklyTimeline
    ? eachHourHeightPxBox
    : eachHourWidthPxBox;

  const returnData = {
    timelineData: [],
    timelineFullHeight: 200,
    showViewMoreCard: false,
    viewMoreCardItems: [],
  };

  const resolveMissingDates = (item = {}) => {
    const timeAdded = 3600; //unix one hour.
    const returnDates = {
      start_date: item.start_date ?? 0,
      due_date: item.due_date ?? 0,
    };
    if (!item) return returnDates;
    if (!item.due_date && item.start_date) {
      returnDates.due_date = item.start_date + timeAdded;
    }
    if (item.due_date && !item.start_date) {
      returnDates.start_date = item.due_date - timeAdded;
    }
    return returnDates;
  };

  const timelineItems = JSON.parse(JSON.stringify(items ?? [])).sort((a, b) => {
    if (
      a?.start_date &&
      b?.start_date &&
      a?.start_date === b?.start_date &&
      a?.title < b?.title
    ) {
      return 1;
    } else if (
      a?.start_date &&
      b?.start_date &&
      a?.start_date === b?.start_date &&
      a?.title > b?.title
    ) {
      return -1;
    } else if (
      resolveMissingDates(a).start_date > resolveMissingDates(b).start_date
    ) {
      return -1;
    } else if (
      resolveMissingDates(a).start_date < resolveMissingDates(b).start_date
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  // Calculate X axis (from left) or y axis (from top) in case of weekly.
  let calendarPreviewDataThresholdCounter = 0;
  timelineItems.forEach((timelineItem) => {
    const data = {
      data: timelineItem,
      id: timelineItem?.id ?? null,
      left: 0,
      top: 0,
      height: 100,
      width: isWeeklyTimeline ? 0 : 200,
      titleMaxLength: 60,
      workspaceTitleMaxLength: 20,
      hours: 0,
      showInCalendar: true,
    };
    if (
      !isWeeklyTimeline &&
      calendarPreviewDataThresholdCounter >
        CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD
    ) {
      returnData.showViewMoreCard = true;
      data.showInCalendar = false;
      returnData.viewMoreCardItems.push(data);
    } else {
      const coeff = 1000 * 60 * 5; // _preset value time movement threshold.
      if (timelineItem?.due_date || timelineItem?.start_date) {
        delete timelineItem._presetLeftPosition;
        delete timelineItem._presetTopPosition;
        if (timelineItem?.start_date) {
          const startDate = new Date(timelineItem.start_date * 1000);
          const msFromStartDate = startDate.getTime() - startDayTime.getTime();
          if (msFromStartDate) {
            const hours = msFromStartDate / 3600000; //convert into hours. This might be decimal. These hours are how much hours from axis (on timeline).
            const calculatedValue = hours * calcuateDimension;
            data[isWeeklyTimeline ? 'top' : 'left'] =
              calculatedValue <= 0 ? 0 : calculatedValue; //This is actually how many pixels we should move from the given axis.
          }
          if (timelineItem?.due_date) {
            const dueDate = new Date(timelineItem.due_date * 1000);
            const msBetweenIssueDates = dueDate.getTime() - startDate.getTime();
            const hours = msBetweenIssueDates / 3600000;
            data.hours = hours;
            data[isWeeklyTimeline ? 'height' : 'width'] =
              hours * calcuateDimension;

            if (!isWeeklyTimeline) {
              if (hours >= 2 && hours <= 3) {
                data.height = 45;
                data.workspaceTitleMaxLength = 12;
                if (hours < 2.5) {
                  data.titleMaxLength = 20;
                } else if (hours < 3) {
                  data.titleMaxLength = 28;
                  data.workspaceTitleMaxLength = 15;
                } else if (hours <= 3.5) {
                  data.titleMaxLength = 40;
                }
              } else if (hours > 3) {
                data.height = 28;
                data.titleMaxLength = hours * 7.3;
                data.workspaceTitleMaxLength = hours * 2;
                if (hours > 6) {
                  data.titleMaxLength = hours * 10;
                  data.workspaceTitleMaxLength = hours * 2.5;
                }
              }
            }
          }
        } else if (timelineItem?.due_date) {
          const dueDate = new Date(timelineItem.due_date * 1000);
          const msFromEndDate = dueDate.getTime() - startDayTime.getTime();
          if (msFromEndDate) {
            const hours = msFromEndDate / 3600000; //convert into hours. This might be decimal. These hours are how much hours from axis (on timeline).
            const calculatedValue =
              hours * calcuateDimension - calcuateDimension;
            data[isWeeklyTimeline ? 'top' : 'left'] =
              calculatedValue <= 0 ? 0 : calculatedValue; //This is actually how many pixels we should move from the given axis.
          }
        }
      } else if (timelineItem?._presetLeftPosition) {
        data.left = timelineItem._presetLeftPosition;

        //Resolving start_date
        const hours = data.left / calcuateDimension;
        data.data.start_date =
          (Math.round((startDayTime.getTime() + hours * 3600000) / coeff) *
            coeff) /
          1000;
        data.data.due_date = null;
      } else if (timelineItem?._presetTopPosition) {
        data.top = timelineItem._presetTopPosition;

        //Resolving start_date
        const hours = data.top / calcuateDimension;
        data.data.start_date =
          (Math.round((startDayTime.getTime() + hours * 3600000) / coeff) *
            coeff) /
          1000;
        data.data.due_date = null;
      }
      if (isWeeklyTimeline) {
        // Handle no due_date case when start_date is above 23h (so it does not break through calend wrapper).
        const fullTimelineItemHeight = data.top + data.height;
        const timelineHeight = (calcuateDimension + 1) * 24;
        if (fullTimelineItemHeight > timelineHeight) {
          data.height -= fullTimelineItemHeight - timelineHeight;
        }
      }
    }
    calendarPreviewDataThresholdCounter++;
    returnData.timelineData.push(data);
  });

  returnData.timelineData = returnData.timelineData.sort(
    (a, b) =>
      a[isWeeklyTimeline ? 'top' : 'left'] +
      b[isWeeklyTimeline ? 'top' : 'left']
  );

  // Calculate Y axis (from top)
  const calculateTopAxis = () => {
    for (let i = 0; i < returnData.timelineData.length; i++) {
      const currentData = returnData.timelineData[i];
      if (!currentData?.showInCalendar) continue;
      const currentDataFirstWidthEnd = currentData.left;
      const currentDataSecondWidthEnd = currentData.left + currentData.width;
      const currentDataFirstHeightEnd = currentData.top;
      const currentDataSecondHeightEnd = currentData.top + currentData.height;
      const timelineFullHeight = currentData.top + currentData.height;
      if (returnData.timelineFullHeight < timelineFullHeight)
        returnData.timelineFullHeight = timelineFullHeight;
      if (!currentData) continue;
      for (let y = 0; y < returnData.timelineData.length; y++) {
        const currentComparingData = returnData.timelineData[y];
        if (!currentComparingData || !currentComparingData.showInCalendar)
          continue;
        const currentComparingDataFirstWidthEnd = currentComparingData.left;
        const currentComparingDataSecondWidthEnd =
          currentComparingData.left + currentComparingData.width;
        const currentComparingDataFirstHeightEnd = currentComparingData.top;
        const currentComparingDataSecondHeightEnd =
          currentComparingData.top + currentComparingData.height;
        if (
          currentData.id &&
          currentComparingData.id &&
          currentData.id !== currentComparingData.id &&
          ((currentDataFirstWidthEnd >= currentComparingDataFirstWidthEnd &&
            currentDataFirstWidthEnd <= currentComparingDataSecondWidthEnd) ||
            (currentDataSecondWidthEnd >= currentComparingDataFirstWidthEnd &&
              currentDataSecondWidthEnd <=
                currentComparingDataSecondWidthEnd)) &&
          ((currentDataFirstHeightEnd >= currentComparingDataFirstHeightEnd &&
            currentDataFirstHeightEnd <= currentComparingDataSecondHeightEnd) ||
            (currentDataSecondHeightEnd >= currentComparingDataFirstHeightEnd &&
              currentDataSecondHeightEnd <=
                currentComparingDataSecondHeightEnd))
        ) {
          //This means they are overlapping. Set .top here and repeat process again.
          returnData.timelineData[i].top += 12;
          returnData.timelineFullHeight = 200;
          return calculateTopAxis();
        }
      }
    }
  };

  const calculateGrouping = () => {
    //Get groupings..

    const calendarEntries = JSON.parse(JSON.stringify(returnData.timelineData));

    // Initial Sort
    calendarEntries.sort((a, b) => {
      if (
        resolveMissingDates(a.data).start_date <
        resolveMissingDates(b.data).start_date
      ) {
        return -1;
      } else if (
        resolveMissingDates(a.data).start_date >
        resolveMissingDates(b.data).start_date
      ) {
        return 1;
      } else {
        return 0;
      }
    });

    const getMaxDepth = (items, maxDepth) => {
      if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          if (items[i]) {
            if (items[i].level > maxDepth) {
              maxDepth = items[i].level;
            }
            maxDepth = getMaxDepth(items[i].nodes, maxDepth);
          }
        }
      }
      return maxDepth;
    };

    const checkNodes = (issue, nodes, parentlevel = 0) => {
      const level = parentlevel + 1;
      let overlap = false;

      for (let k = 0; k < nodes.length; k++) {
        if (
          resolveMissingDates(issue?.data).start_date <=
            resolveMissingDates(nodes[k]?.data).due_date &&
          resolveMissingDates(issue?.data).start_date >=
            resolveMissingDates(nodes[k]?.data).start_date
        ) {
          // add a node item
          if (nodes[k].nodes.length > 0) {
            // there are already nodes
            nodes[k].nodes = checkNodes(
              issue,
              JSON.parse(JSON.stringify(nodes[k].nodes)),
              level
            );
          } else {
            //Start grouping if we need.
            if (level === COLUMN_NUMBER - 1) {
              const entry = issue;
              const entryData = issue?.data;
              const entryStartDate = resolveMissingDates(entryData).start_date;
              const entryDueDate = resolveMissingDates(entryData).due_date;
              const topValue = entry.top;
              const heightValue = entry.height;
              if (nodes[k]?.data?.box) {
                nodes[k].data.issues.push(entryData);
                if (
                  !nodes[k].start_date ||
                  entryStartDate < nodes[k].start_date
                ) {
                  nodes[k].start_date = entryStartDate;
                  nodes[k].top = topValue;
                }
                if (!nodes[k].due_date || entryDueDate > nodes[k].due_date) {
                  nodes[k].due_date = entryDueDate;
                  nodes[k].height = heightValue;
                }
              } else {
                const id = k;
                nodes[k] = {
                  ...entry,
                  data: {
                    id,
                    box: true,
                    issues: [
                      entryData,
                      JSON.parse(JSON.stringify(nodes[k]?.data ?? {})),
                    ],
                    start_date: entryStartDate,
                    due_date: entryDueDate,
                  },
                  id,
                  top: topValue,
                  height: heightValue,
                  start_date: entryStartDate,
                  due_date: entryDueDate,
                  nodes: [],
                  level,
                };
              }
            } else {
              // add a first item
              issue.level = level + 1;
              nodes[k].nodes.push(issue);
            }
            issue.nodes = [];
          }
          overlap = true;
          break;
        }
      }
      if (!overlap) {
        // if there is no overlap add it as a new issue and push it to result
        issue.level = level;
        issue.nodes = [];
        nodes.push(issue);
      }
      return nodes;
    };

    const setUpItems = (item, previous, counter, itemsoverlap) => {
      const columns = 0;
      item.maxDepth = getMaxDepth(item.nodes, columns);
      //this 3 here is grouping that will depend later on. Customize this with width of the screen.
      item.columns = Math.max(
        Math.min(item.maxDepth, COLUMN_NUMBER) - item.level + 1,
        1
      );
      if (item.columns === 1) {
        itemsoverlap = 0;
      }
      item.width =
        (100 - previous) / item.columns +
        itemsoverlap -
        itemsoverlap / item.columns;
      item.showWidthValueInPercentage = true;
      item.showLeftValueInPercentage = true;
      item.left = previous;
      item.next = item.width + previous - itemsoverlap;
      item.index = counter;
    };

    const resultSetup = (result, previous, counter, itemsoverlap) => {
      counter++;
      setUpItems(result, previous, counter, itemsoverlap);
      for (let i = 0; i < result.nodes.length; i++) {
        counter = resultSetup(
          result.nodes[i],
          result.next,
          counter,
          itemsoverlap
        );
      }
      return counter;
    };

    // start from the scratch
    const result = [];

    // start looping through all issues and building a tree
    for (let i = 0; i < calendarEntries.length; i++) {
      // now the initial item is there lets populate either result or check its nodes
      // if there are more items check if you have to add a node to an existing one or add a new one
      let overlap = false;
      for (let j = 0; j < result.length; j++) {
        // check if you have to add an issue to the existing node or add a new one to the result
        if (
          resolveMissingDates(calendarEntries[i]?.data).start_date <=
            resolveMissingDates(result[j]?.data).due_date &&
          resolveMissingDates(calendarEntries[i]?.data).start_date >=
            resolveMissingDates(result[j]?.data).start_date
        ) {
          // in case there is a overlap check the node
          // all new items starting between start and end of the closest parent are added to its node
          if (result[j].nodes.length > 0) {
            // there are already nodes
            // items need to be cloned in order not to pullute the tree
            result[j].nodes = checkNodes(
              calendarEntries[i],
              JSON.parse(JSON.stringify(result[j].nodes)),
              0
            );
          } else {
            // add a first item with nodes
            calendarEntries[i].level = 1;
            calendarEntries[i].nodes = [];
            result[j].nodes.push(calendarEntries[i]);
          }
          overlap = true;
          break;
        }
      }
      if (!overlap) {
        // if there is no overlap add it as a new issue and push it to result
        // google magic
        calendarEntries[i].level = 0;
        calendarEntries[i].nodes = [];
        result.push(calendarEntries[i]);
      }
    }

    // this is relevant for defining with, margins and index of absolute calenderitems in JS
    // it returns a counter for the index and sets up the items adding additional fields
    let counter = 0;
    for (let i = 0; i < result.length; i++) {
      counter = resultSetup(result[i], 0, counter, WEEKLY_ITEMS_OVERLAP);
    }

    if (result.length) returnData.timelineData = result;
  };

  if (isWeeklyTimeline) {
    calculateGrouping();
  } else {
    calculateTopAxis();
  }

  return returnData;
};

export const getPresenceTimelineData = (
  timelineItems = [],
  additionalParams = {}
) => {
  const { eachHourWidthPxBox, startDayTime = new Date() } = additionalParams;

  const calcuateDimension = eachHourWidthPxBox;

  const returnData = {
    presenceTimelineData: [],
  };

  // Calculate X axis (from left)
  timelineItems.forEach((timelineItem) => {
    const data = {
      data: timelineItem,
      id: timelineItem?.id ?? null,
      left: 0,
      width: 0,
    };
    if (timelineItem.logged_at) {
      const startDate = new Date(timelineItem.logged_at);
      const msFromStartDate = startDate.getTime() - startDayTime.getTime();
      if (msFromStartDate) {
        const hours = msFromStartDate / 3600000; //convert into hours. This might be decimal. These hours are how much hours from axis (on timeline).
        const calculatedValue = hours * calcuateDimension;
        data.left = calculatedValue <= 0 ? 0 : calculatedValue; //This is actually how many pixels we should move from the given axis.
      }
      if (msFromStartDate <= 0) data.left = 0;
      if (timelineItem.logout_at) {
        const dueDate = new Date(timelineItem.logout_at);
        const msBetweenDates =
          dueDate.getTime() -
          (msFromStartDate <= 0 ? startDayTime.getTime() : startDate.getTime());
        const hours = msBetweenDates / 3600000;
        data.width = hours * calcuateDimension;
        if (hours < 0) return;
      } else {
        const msBetweenDates =
          Date.now() -
          (msFromStartDate <= 0 ? startDayTime.getTime() : startDate.getTime());
        const hours = msBetweenDates / 3600000;
        const calculatedValue = hours * calcuateDimension;
        data.width = calculatedValue;
      }
    }
    returnData.presenceTimelineData.push(data);
  });

  returnData.presenceTimelineData = returnData.presenceTimelineData.sort(
    (a, b) => a.left + b.left
  );

  return returnData;
};
