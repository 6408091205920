import { useMemo } from 'react';

import { getTimelineData } from '../../DailyTimeline/utils';
import { getFullDayTimelineData } from '../utils';

const timelineHours = 24;

const useConfig = (props = {}) => {
  const { timelineHeightPx = 2100, data = [], startDayTimes = [] } = props;

  const eachHourHeightPxBox = Math.round(timelineHeightPx / timelineHours);

  const mappedCalendarData = useMemo(() => {
    const finalData = data.map((item, index) => {
      const returnData = {
        timelineData: [],
      };

      const fullDayItemsLength = item.full_day?.length;

      if (fullDayItemsLength) {
        const { timelineData: fullDayTimelineData } = getFullDayTimelineData(
          item.full_day ?? [],
          { eachHourHeightPxBox, startDayTime: startDayTimes[index] }
        );
        returnData['fullDayTimelineData'] = fullDayTimelineData;
      }

      if (item.standard_issues?.length) {
        const { timelineData } = getTimelineData(item.standard_issues, {
          eachHourHeightPxBox,
          isWeeklyTimeline: true,
          startDayTime: startDayTimes[index],
          fullDayItemsLength,
        });

        returnData.timelineData = timelineData;
      }

      return returnData;
    });

    return finalData ?? [];
  }, [data, eachHourHeightPxBox, startDayTimes]);

  return {
    eachHourHeightPxBox,
    timelineHours,
    mappedCalendarData,
  };
};

export default useConfig;
