import styled from 'styled-components/macro';
import {
  darkerGray,
  darkGray,
  filtersAdvancedGray,
  newBorder,
  purple500,
} from '../../../../../../config/colors';

export const Title = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${darkerGray};
  margin-left: 20px;
  margin-top: 21px;
  margin-bottom: 15px;
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  position: relative;
`;

export const ImageType = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 13px;
  position: absolute;
  background-color: ${darkGray};
  z-index: 100;
  bottom: 0;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TypeIcon = styled.i`
  color: white;
  font-size: 13px;
  width: 12px;
  height: 17px;
`;

export const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  margin-right: 24px;
  margin-left: 20px;
`;

export const ItemContentContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: row;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const TimeStamp = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: ${purple500};
  font-weight: 600;
`;

export const Text = styled.span`
  display: block;
  font-size: 15px;
  color: ${filtersAdvancedGray};
`;
export const TextContainer = styled.div`
  width: 75%;
`;
export const DotContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid ${newBorder};
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
`;

export const Dot = styled.div`
  background-color: ${purple500};
  width: 10px;
  height: 10px;
  border-radius: 5px;
`;
