import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getConfigPersistedFilters } from '../../../redux/config/selectors';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { setEntity, setLatestSavedFilter } from '../slice';
import {
  updatePersistedFilters,
  addToPersistedFilters,
} from '../../../redux/config/actions';
import { getLatestSavedFilter } from '../selectors';

const useFilters = ({ entity, skip } = {}) => {
  const dispatch = useDispatch();
  const { search: searchQuery } = useLocation();

  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, entity),
    isEqual
  );

  const latestSavedFilter = useSelector(getLatestSavedFilter(entity));

  useEffect(() => {
    if (!entity || skip) return;

    dispatch(setEntity(entity));
  }, [dispatch, entity, skip]);

  //NOTE: if there is no saved filter query params in store we should add it
  //this is needed to compare if save filters button should be shown
  useEffect(() => {
    if (!latestSavedFilter && !skip && entity && searchQuery?.length > 1) {
      dispatch(setLatestSavedFilter({ queryString: searchQuery?.slice(1) }));
    }
  }, [latestSavedFilter, skip, entity, searchQuery, dispatch]);

  const handleMultiselectChange = (key, item, includeChildren) => {
    const PARAMS_SUFFIX = '_params';

    const persistedFiltersKey = `${key}${PARAMS_SUFFIX}`;
    const mutatedParameters = { ...(persistedFilters.parameters ?? {}) };

    const mutatedArray = [...(mutatedParameters[persistedFiltersKey] ?? [])];

    const index = mutatedArray.findIndex(
      (currentItem) => currentItem.id === item.id
    );

    if (index !== -1) {
      mutatedArray.splice(index, 1);
    } else {
      mutatedArray.push({ ...item, type: key });
      if (includeChildren && item.children?.length) {
        item.children.forEach((child) => {
          const isChildInArray = mutatedArray.some(
            (currentItem) => currentItem.id === child.id
          );
          if (!isChildInArray) mutatedArray.push({ ...child, type: key });
        });
      }
    }

    if (mutatedArray.length === 0) {
      delete mutatedParameters[persistedFiltersKey];
    } else {
      mutatedParameters[persistedFiltersKey] = mutatedArray;
    }

    dispatch(
      updatePersistedFilters(
        {
          ...mutatedParameters,
          page: 1,
        },
        entity
      )
    );
  };

  const handleSingleSelectChange = (key, value) => {
    const mutatedParameters = { ...(persistedFilters.parameters ?? {}) };

    if (value === 'all') {
      if (key in mutatedParameters) delete mutatedParameters[key];
    } else {
      mutatedParameters[key] = value;
    }

    const page = key === 'page' ? value : 1;

    dispatch(updatePersistedFilters({ ...mutatedParameters, page }, entity));
  };

  const handleFilterReset = (keys) => {
    const mutatedParameters = { ...(persistedFilters.parameters ?? {}) };

    for (const key of keys) {
      if (key in mutatedParameters) {
        delete mutatedParameters[key];
        dispatch(updatePersistedFilters(mutatedParameters, entity));
      }
    }
  };

  const formatSelectedParams = useCallback((selected) => {
    const idObject = selected.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {});
    return idObject;
  }, []);

  const formatStringFilter = (currentString, value) => {
    const splitValues = currentString.split(',');
    const newValues = new Set(splitValues.filter((val) => val.trim() !== ''));

    if (newValues.has(value)) {
      newValues.delete(value);
    } else {
      newValues.add(value);
    }

    const updatedValues = Array.from(newValues);
    const newString = updatedValues.join(',');

    return newString;
  };

  const handleSelectMultipleAsString = (key, value) => {
    const currentString = persistedFilters.parameters[key] ?? '';
    const newString = formatStringFilter(currentString, String(value));

    const mutatedParameters = { ...(persistedFilters.parameters ?? {}) };

    if (!newString.length) {
      delete mutatedParameters[key];
    } else {
      mutatedParameters[key] = newString;
    }

    dispatch(updatePersistedFilters(mutatedParameters, entity));
  };

  const sortDirection = persistedFilters?.parameters?.sort_direction;
  const sortBy = persistedFilters?.parameters?.sort_by;

  const setSortBy = (value) => handleSingleSelectChange('sort_by', value);

  const setSortDirection = () => {
    const order = sortDirection === 'asc' ? 'desc' : 'asc';
    handleSingleSelectChange('sort_direction', order);
  };

  const setPage = (value) => handleSingleSelectChange('page', value);
  const setPaginateBy = (value) =>
    handleSingleSelectChange('paginate_by', value);

  const setSearch = useCallback(
    (value) => {
      dispatch(addToPersistedFilters({ search: value, page: 1 }, entity));
    },
    [dispatch, entity]
  );

  const page = persistedFilters?.parameters?.page;
  const paginateBy = persistedFilters?.parameters?.paginate_by;
  const search = persistedFilters?.parameters?.search;

  return {
    handleMultiselectChange,
    handleFilterReset,
    persistedFilters,
    formatSelectedParams,
    handleSelectMultipleAsString,
    handleSingleSelectChange,
    formatStringFilter,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    page,
    setPage,
    paginateBy,
    setPaginateBy,
    search,
    setSearch,
    latestSavedFilter,
  };
};

export default useFilters;
