/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  token_not_provided: 'Token wurde nicht bereitgestellt',
  token_invalid: 'Token ungültig',
  token_expired: 'Token ist abgelaufen',
  user_not_found: 'Benutzer wurde nicht gefunden',
  the_email_field_is_required_: 'Das E-Mail-Feld ist erforderlich.',
  the_password_field_is_required_: 'Das Passwortfeld ist erforderlich.',
  could_not_create_token: 'Token konnte nicht erstellt werden',
  workspaces: 'Arbeitsbereiche',
  workspace: 'Arbeitsbereich',
  no_: 'Nr.',
  search: 'Suche',
  created_by: 'Erstellt von',
  on: 'am',
  at: 'um',
  admin: 'Administrator',
  users: 'Benutzer',
  add_new: 'Einfügen',
  sorty_by: 'Sortiere nach',
  status: 'Status',
  date: 'Datum',
  assigned_to: 'Zugewiesen an',
  last_update: 'Zuletzt aktualisiert',
  by: 'von',
  to_do: 'Offen',
  finished: 'Erledigt',
  reviewed: 'Abgenommen',
  rated: 'Bewertet',
  priority: 'Priorität',
  urgent: 'Dringend',
  high: 'Hoch',
  medium: 'Mittel',
  low: 'Niedrig',
  documented: 'Dokumentiert',
  category: 'Kategorie',
  view_more: 'mehr anzeigen',
  issues: 'Meldungen',
  due_date: 'Fällig am',
  watchers: 'Beobachter',
  subtasks: 'Sub-Aufgaben',
  write_a_comment: 'Kommentar eingeben',
  settings: 'Einstellungen',
  welcome_back: 'Willkommen zurück',
  username: 'Benutzername',
  password: 'Passwort',
  sign_in: 'Anmelden',
  remember_me: 'Passwort speichern',
  forgot_password_: 'Passwort vergessen?',
  favorite_workspaces: 'Favoriten',
  subtitle_or_description: 'Untertitel oder Beschreibung',
  take_photo: 'Bild aufnehmen',
  add_from_gallery: 'Aus der Galerie auswählen',
  record_audio: 'Audio aufnehmen',
  record_video: 'Video aufnehmen',
  text_issue: 'Text Meldung',
  search_for_workspace_tasks_comments_people_etc_:
    'Suche nach Arbeitsbereich, Meldung, Kommentaren, Benutzer etc.',
  new_workspace: 'Neuer Arbeitsbereich',
  workspace_name: 'Name vom Arbeitsbereich',
  cancel: 'Abbrechen',
  create: 'Erstellen',
  profile: 'Profil',
  profile_details: 'Profil Details',
  company: 'Unternehmen',
  position: 'Position',
  description: 'Beschreibung',
  view: 'Ansicht',
  done: 'Fertig',
  name: 'Name',
  surname: 'Nachname',
  new_password: 'Neues Passwort',
  repeat: 'Passwort wiederholen',
  recieve_email_notifications: 'Email Benachrichtigungen bekommen',
  yes: 'Ja',
  no: 'Nein',
  application_language: 'App Sprache',
  photos: 'Bilder',
  save: 'Speichern',
  clear_all: 'Alle löschen',
  e_n_g_l_e_s_k_i_n_a_z_i_v: 'Dragos Mali Pametni Brijac Naziv',
  members: 'Mitglieder',
  button_text_accept: 'Akzeptieren',
  button_text_deny: 'Ablehnen',
  button_text_start: 'Start',
  button_text_done: 'Erledigt',
  button_text_complete: 'Freigeben',
  phone: 'Telefonnummer',
  active: 'Aktiv',
  paused: 'pausiert',
  confirm_password: 'Passwort bestätigen',
  push_notifications: 'Push-Benachrichtigungen',
  for_your_device: 'Für Ihr Mobiles oder Tablet Gerät',
  change_profile_photo: 'Profilbild ändern',
  photo_formats_and_size: 'JPG, GIF oder PNG bis max. 800 kB',
  '120_by_120': 'minimale Auflösung von 120 x 120 pixel erforderlich',
  edit_profile: 'Profil bearbeiten',
  not_specified: 'nicht angegeben',
  latest_activity: 'letzte Aktivität',
  show_more: 'mehr anzeigen',
  report: 'Bericht',
  reports: 'Berichte',
  define_pdf_name: 'Name der PDF Datei definieren',
  optional_elements: 'Elemente für die Benennung der PDF Datei',
  daily_summary_report:
    'Bericht aller täglich erstellten und geänderten Meldungen',
  daily_issues_email: 'Email für PDF Bericht der täglichen Meldungen',
  if_none_specified:
    'Falls nicht angegeben, wird standard Email Adresse verwendet',
  include_pictures_description: 'Beschreibung der Bilder einfügen',
  issue_history_log: 'Verlauf der Meldung',
  categories: 'Kategorien',
  create_category: 'Kategorie erstellen',
  edit_category_name: 'Kategorie bearbeiten',
  invite_a_new_user: 'Neuen Benutzer einfügen',
  password_generated_automatically:
    'Passwort wird automatisch erstellt. Eingabe eines Passworts ist auch möglich.',
  send: 'senden',
  log_out: 'Abmelden',
  log_in: 'Einloggen',
  description_is_too_short:
    'Description is too short - must be at least 10 characters',
  add_title: 'Titel eingeben',
  last_updated_on: 'zuletzt aktualisiert am',
  type_description: 'Beschreibung eingeben',
  select_workspace: 'Select Workspace',
  reporter: 'Auftraggeber',
  add_subtask: 'Subaufgabe erstellen',
  title: 'Titel',
  subtask_title: 'Titel der Sub-Aufgabe',
  cant_issue_before_ws:
    'Meldung ohne Arbeitsbereich kann nicht erstellt werden',
  drop_files_to_upload: 'Daten zum Hochladen hierher ziehen',
  or_click_here: 'oder hier klicken',
  image_is_too_big:
    'Die Datei ist zu groß. Die maximale Größe beträgt 2 MB für eine Datei und 20 MB für alle Dateien in der Meldung.',
  file_type_not_allowed: 'Dieser Datentyp ist nicht erlaubt',
  'uploaded files': 'Uploaded Files',
  uploaded_files: 'Hochgeladene Dateien',
  add_workspace_name: 'Name des Arbeitsbereiches eingeben',
  managers: 'Die Manager',
  manager: 'Der Manager',
  group_managers: 'Die Group Manager',
  completed: 'Abgeschlossen',
  _password_generated_automatically:
    'Das Passwort wird automatisch generiert. Direkte Eingabe des Passworts is auch möglich.',
  qr_code: 'QR Code',
  help: 'Hilfe',
  profile_detail: 'Profil Details',
  button_text_to_do: 'Meldung rücksetzen',
  'No_issues_empty workspace': 'Keine Meldungen derzeit vorhanden',
  in_Workspace: 'im',
  no_results_found: 'Keine Suchergebnisse gefunden',
  delete_issue: 'Löschen',
  set_category_input_field_empty: 'Kategorie setzen',
  set_due_date_input_field_empty: 'Fälligkeit setzen',
  discard: 'Abbrechen',
  reclaim_issue: 'Meldung rücksetzen',
  empty_workspace: 'Keine Meldungen derzeit vorhanden',
  en: 'English',
  de: 'Deutsch',
  yu: 'Serbisch, Kroatisch, Bosnisch',
  '-01—': 'Jänner',
  '-02—': 'Februar ',
  '-03—': 'März',
  '-04—': 'April',
  '-05—': 'Mai',
  '-06—': 'Juni',
  '-07—': 'Juli',
  '-08—': 'August',
  '-09—': 'September',
  '-10—': 'Oktober',
  '-11—': 'November',
  '-12—': 'Dezember ',
  issue_commented: 'kommentiert',
  issue_assignee_id: 'Zugewiesen',
  pic_comment_start_typing: '',
  '-01--': 'Jänner',
  '-02--': 'Februar ',
  '-03--': 'März',
  '-04--': 'April',
  '-05--': 'Mai',
  '-06--': 'Juni',
  '-07--': 'Juli',
  '-08--': 'August',
  '-09--': 'September',
  '-10--': 'Oktober',
  '-11--': 'November',
  '-12--': 'Dezember ',
  email_profile: 'Email',
  pl: 'Polnisch',
  reference_number: 'Referenznummer',
  ref_num_abb: 'Ref. Num:',
  token_not_found: 'Token nicht gefunden',
  activity_log: 'Verlauf',
  comment_log: 'Kommentare',
  daily_issues_pdf: 'Alle täglichen Meldungen erfasst in einem PDF Bericht',
  rating: 'Bewertung',
  rating_incomplete: 'Unvollständig',
  rating_satisfactory: 'Befriedigend',
  rating_good: 'Gut',
  rating_very_good: 'Sehr Gut',
  rating_excellent: 'Excellent',
  description_rating_incomplete:
    'Das Ergebnis (Qualität oder Lieferzeit) ist nicht ausreichend',
  description_rating_satisfactory:
    'Das Ergebnis ist akzeptabel, die Qualität oder Lieferzeit nicht',
  description_rating_good:
    'Das Ergebnis ist zufiedenstellend, Qualität oder Lieferzeit können verbessert werden',
  description_rating_very_good:
    'Das Ergebnis ist sehr zufriedenstellend, kleine Details bei Qualität oder Lieferzeit können verbessert werden',
  description_rating_excellent:
    'Qualität und Lieferzeit treffen die Erwartungen bzw. sind drüber',
  pdf_loader: 'PDF Bericht wird erstellt',
  description_pdf_loader:
    'Es kann bis zur 1 Minute dauern, je nach Größe des PDF Berichts.',
  edit: 'Bearbeiten',
  edit_comment: 'Kommentar bearbeiten',
  not_rated: 'Nicht bewertet',
  duplicate_issue: 'Meldung kopieren',
  alert_delete_issue:
    'Bei Löschen der Meldung werden alle Daten verloren. Bitte bestätigen.',
  alert_delete_subtask:
    'Bei Löschen der Sub-Aufgabe werden alle Daten verloren. Bitte bestätigen.',
  archive: 'Archivieren',
  confirm: 'Bestätigen',
  deadline: 'Fristablauf',
  password_not_matching:
    'Neues Passwort und Passwort wiederholen müssen übereinstimmen.',
  print: 'Drucken',
  print_qr: 'QR Code drucken',
  invalid_qr_code: 'Der gescannte QR Code ist nicht unterstützt!',
  declined: 'Abgelehnt',
  in_review: 'In Überprüfung',
  wish_list: 'Wunschzettel',
  normal: 'Normal',
  'status.to_do': 'Offen',
  'status.declined': 'Abgelehnt',
  'status.in_progress': 'In Bearbeitung',
  'status.in_review': 'In Überprüfung',
  'status.completed': 'Abgeschlossen',
  'priority.wish_list': 'Wunschzettel',
  'priority.low': 'Niedrig',
  'priority.normal': 'Normal',
  'priority.high': 'Hoch',
  'priority.urgent': 'Dringend',
  enter_email: 'Bitte die Email Adresse des Benutzerkontos eingeben.',
  check_email: 'Überprüfen Sie den Posteingang.',
  sent_reset_link_to_email:
    'Wir haben einen Link zum Passwort Zurücksetzen gesendet an',
  not_set: 'nicht angegeben',
  '': '',
  mark_all_read: 'Alle als gelesen markieren',
  notifications: 'Benachrichtigungen',
  qr_scanlog: 'QR Code Scanlog',
  qr_scanlog_scan:
    ':user_full_name hat den QR Code in :issue_title eingescannt',
  'history_log.new.user_created_issue_title':
    ':user_full_name hat die Meldung :issue_title erstellt.',
  'history_log.new.user_accepted_issue':
    ':assignee_full_name hat die Bearbeitung der Meldung :issue_title angenommen.',
  'history_log.new.user_assigned_issue':
    ':executor_full_name hat die Meldung :issue_title zugewiesen an :assignee_full_name.',
  'history_log.new.user_commented_issue':
    ':user_full_name hat einen Kommentar zu der Meldung :issue_title hinzugefügt.',
  'history_log.new.user_declined_issue':
    ':user_full_name hat die Bearbeitung der Meldung :issue_title am abgelehnt.',
  'history_log.new.user_finished_working':
    ':assignee_full_name hat die Meldung :issue_title erledigt.',
  'history_log.new.user_rated_issue':
    ':user_full_name hat die Meldung :issue_title abgenommen.',
  'history_log.new.user_started_working':
    ':assignee_full_name hat begonnen an der Meldung :issue_title zu arbeiten.',
  'history_log.new.user_duplicated_issue':
    'Der Benutzer :user_full_name hat die Meldung :issue_title dupliziert',
  'history_log.new.user_added_attachment_to_issue':
    ':user_full_name hat :media zu der Meldung :issue_title hinzugefügt.',
  'history_log.new.user_added_multiple_attachments_to_issue':
    ':user_full_name hat :media zu der Meldung :issue_title hinzugefügt.',
  'general.num_audio': '{0} kein audio|{1} ein Audio|[2,*] :count Audios',
  'general.num_video': '{0] kein video|{1} ein Video|[2,*] :count Videos',
  'general.and': 'und',
  'general.num_picture': '{0} keine bilder|{1} ein Bild|[2,*] :count Bilder',
  'history_log.new.user_reclaimed_issue':
    ':user_full_name hat die Meldung :issue_title zurückgesetzt. ',
  'history_log.new.user_started_work_log':
    ':user_full_name hat  die Zeitaufnahme um :time in der Meldung :issue_title begonnen.',
  'history_log.new.user_ended_work_log':
    ':user_full_name hat die Zeitaufnahme um :time in der Meldung :issue_title beendet. Aktiv aufgenommene Zeit beträgt :time_worked h.',
  'activity_log.num_audio': 'kein Audio|ein Audio|:count Audios',
  'activity_log.num_video': 'kein Video|ein Video|:count Videos',
  'activity_log.num_picture': 'kein Bild|ein Bild|:count Bilder',
  user: 'Benutzer',
  no_internet: 'Keine Internetverbindung',
  activate: 'Aktivieren',
  restricted: 'Restricted',
  archived: 'Archiviert',
  pending: 'Bevorstehend',
  close: 'Schliessen',
  no_qr_code: 'Leer. Bis jetzt wurde der QR Code nicht eingescannt.',
  tr: 'Türkisch',
  initial_load: 'Ausgangsdaten werden geladen. Bitte warten.',
  no_camera_access:
    'Bilder können nicht aufgenommen werden, ohne den erlauben Zugriff auf die Kamera des Gerätes. ',
  allow_camera_access: 'Zugriff auf die Kamera erlauben.',
  'notification.issue_in_review_watchers':
    ':user_full_name hat die Meldung :issue_title erledigt.',
  'notification.issue_assigned_watchers':
    ':workspace_admin_full_name hat die Meldung :issue_title zugewiesen an :assignee_full_name.',
  'notification.issue_assigned_assignee':
    ':workspace_admin_full_name hat die Meldung :issue_title zugewiesen an Dich.',
  'notification.assignee_accepted_issue':
    ':assignee_full_name hat die Bearbeitung der Meldung :issue_title angenommen.',
  'notification.issue_in_progress_watchers':
    ':user_full_name hat begonnen an der Meldung :issue_title zu arbeiten.',
  'notification.issue_in_review_workspace_admin':
    'Bitte bewerte die Meldung :issue_title, um die Servicequalität zu gewährleisten und die Meldung als abgeschlossen zu vermerken.',
  'notification.issue_completed_watchers':
    ':user_full_name hat die Meldung :issue_title abgenommen.',
  'notification.issue_created_watchers':
    ':creator_full_name hat die Meldung :issue_title erstellt.',
  'notification.issue_added_one_attachment_watchers':
    ':user_full_name hat :media zu der Meldung :issue_title hinzugefügt.',
  'notification.issue_added_multiple_attachments_watchers':
    ':user_full_name hat :media zu der Meldung :issue_title hinzugefügt.',
  'notification.issue_due_date_assignee':
    'Die Meldung :issue_title ist in :hours h fällig. Bitte um Erledigung der Meldung oder Kontaktaufnahme mit :workspace_admin_full_name.',
  'notification.issue_comment_added_watchers':
    ':user_full_name hat einen Kommentar zu der Meldung :issue_title am :time_date um :time_time hinzugefügt.',
  'notification.issue_declined_workspace_admin':
    ':user_full_name hat die Bearbeitung der Meldung :issue_title abgelehnt.',
  'notification.issue_declined_watchers':
    ':user_full_name hat die Bearbeitung der Meldung :issue_title am abgelehnt.',
  'notification.issue_created_assignee':
    ':creator_full_name hat die Meldung :issue_title erstellt.',
  'notification.issue_due_date_workspace_admin':
    'Die Meldung :issue_title ist in :hours h fällig. :assignee_full_name wurde aufgefordert, die Meldung zu erledigen oder Dich zu kontaktieren.',
  'notification.issue_reclaimed_watchers':
    ':user_full_name hat die Meldung :issue_title zurückgesetzt.',
  login_failed: 'Einloggen fehlgeschlagen!',
  incorrect_email_password: 'Passwort oder email ist nicht gültig.',
  reset_password: 'Passwort zurücksetzen',
  back_to_sign_in: 'Zurück zum Einloggen',
  'history_log.new.user_finished_working_time_worked':
    'Die Summe der aktiv aufgenommenen Arbeitszeit ist :sum_time_worked h.',
  'history_log.new.user_removed_attachment_from_issue':
    ':user_full_name hat :media aus der Meldung :issue_title entfernt.',
  'history_log.new.user_removed_multiple_attachments_from_issue':
    ':user_full_name hat :media aus der Meldung :issue_title entfernt.',
  no_notification: 'Keine Benachrichtigungen derzeit vorhanden',
  added_by: 'Eingefügt von',
  duplicate_subtask: 'Subaufgabe kopieren',
  category_name: 'Name der Kategorie',
  category_description: 'Beschreibung der Kategorie',
  category_visibility: 'Visibility (DE)',
  category_default_assignee: 'Default assignee (DE)',
  sync_data: 'Daten werden synchronisiert',
  filter_all: 'Alle',
  filter_incomplete: 'Nicht abgeschlossen',
  password_8_characters:
    'Passwörter müssen aus mindestens acht Zeichen bestehen.',
  repeat_password_confirm: 'Passwort bestätigen muss übereinstimmen.',
  required_field: 'Das Feld ist erforderlich.',
  not_assigned: 'Nicht zugewiesen',
  generate_sub_tasks_when_qr_scanned: 'Automatisch generieren',
  user_invited: 'Eingeladen',
  the_title_field_is_required: 'Das Titel Feld ist erforderlich!',
  not_all_data_synchronised:
    'Es sind nicht alle Daten synchronisiert. Bitte zuerst alle Daten in der App bei Internetzugang aktualisieren.',
  workspace_log_out: 'Möchten Sie sich von diesem Arbeitsbereich abmelden?',
  ws_name_already_exists:
    'Der Name, den Sie diesem Arbeitsbereich geben möchten, ist bereits vorhanden.',
  close_ws_issues_in_progress:
    'Es gibt Meldungen in Bearbeitung. Bitte die Meldungen abschliessen, um diesen Arbeitsbereich zu schließen.',
  close_ws_issues_exist:
    'Vor dem Schließen des Arbeitsbereichs empfehlen wir, die Meldungen in einen anderen Arbeitsbereich zu verschieben. Durch Schließen dieses Arbeitsbereichs gehen alle Daten verloren.',
  close_ws_no_issues:
    'Durch Schließen des Arbeitsbereich werden alle Daten gelöscht . Bitte bestätigen.',
  ws_presence_show: 'Anwesenheit anzeigen',
  ws_presence_hide: 'Anwesenheit ausblenden',
  issue_missing_error: 'Hoppla. Fehler beim Laden der Meldung.',
  issue_not_existing: 'Die Meldung ist nicht mehr vorhanden.',
  sub_workspace: 'Sub-Arbeitsbereiche',
  no_sub_workspaces: 'keine Sub-Arbeitsbereiche vorhanden',
  add_sub_workspace_name: 'Name des Sub-Arbeitsbereiches eingeben',
  offline_end_of_issues_list:
    'Ende der Liste ohne Internetverbindung. Bitte online gehen, um die weiteren Meldungen auf das mobile Gerät zu laden.',
  upload_pdf: 'PDF einfügen',
  file_too_big: 'Folgende Datei(en) sind zu groß:',
  'general.num_document':
    '{0} kein Dokument|{1} ein Dokument|[2,*] :count Dokumente',
  'activity_log.num_document': 'kein Dokument|ein Dokument|:count Dokumente',
  assignee: 'Auftragnehmer',
  export_to_pdf: 'PDF der Meldung',
  no_assignee: 'Niemand ist zugewiesen',
  pdf_language: 'Sprache im PDF Bericht',
  yay_no_issues: 'Juhu! Keine Meldungen mehr zu laden.',
  form_msg_email_not_valid: 'Das E-Mail-Format ist nicht korrekt.',
  form_msg_email_already_added:
    'Ein Benutzer mit dieser Mailadresse existiert bereits.',
  'history_log.new.user_changed_workspace_in_issue':
    ':user_full_name hat die Medlung :issue_title von :old_workspace_title nach :new_workspace_title versochoben',
  'history_log.new.user_added_watchers':
    ':user_full_name hat :watcher_full_name als Beobachter der Meldung :issue_title hinzugefügt',
  'history_log.new.user_removed_watchers':
    ':user_full_name hat :watcher_full_name als Beobachter der Meldung :issue_title entfernt',
  issue_error: 'Fehlermeldung',
  max_image_count_reached:
    'Maximale Anzahl von Bildern erreicht. Bitte die Meldung speichern, um weitere Bilder hinzuzufügen.',
  date_in_past: 'Datum und Uhrzeit können nicht in der Vergangenheit liegen.',
  start_date: 'Beginn am',
  set_start_date_input_field_empty: 'Starttermin setzen',
  apply: 'Anwenden',
  updated: 'Aktualisierung',
  completion: 'Abschluss',
  creation: 'Erstellung',
  order: 'Reihung nach',
  latest_first: 'Neusten zuerst',
  oldest_first: 'Ältesten zuerst',
  status_all: 'Alle',
  results_found: 'Ergebnisse gefunden',
  reset_all_filters: 'Alle Filter rücksetzen',
  advanced: 'Erweitert',
  date_range: 'Zeitraum',
  export: 'Exportieren',
  include_sub_ws: 'Sub-Arbeitsbereiche einbeziehen',
  filters: 'Filter',
  cloud_url: 'Cloud-Ordner hinzufügen',
  cloud_url_focus: 'Cloud-Link hier einfügen… (es muss ein Link sein)',
  export_pdf_incl_subtasks: 'PDF der Meldung inkl. Sub-Aufgaben',
  time: 'Uhrzeit',
  pictures_download: 'Bilder herunterladen',
  moved_in_progress: 'Start der Arbeit begonnen von',
  accepted_by: 'Akzeptiert von',
  comment_in_review: 'Kommentar bei Bewertung',
  issue_duration: 'Dauer der Meldung',
  creation_to_completion: 'Dauer der Meldung von Erstellung bis Abschluss',
  start_to_finish: 'Dauer der Meldung von Start bis Erledigt',
  work_time_logged: 'Aufgenommene Arbeitszeit',
  start_time: 'Uhrzeit der Startzeit',
  due_time: 'Uhrzeit der Fälligkeit',
  filter_my: 'Meine',
  worked_on_by: 'Bearbeitet von',
  autocomplete: 'Autovervollst\u00e4ndigung',
  text_templates: 'Textvorlagen',
  text_templates_info:
    'Erstellen Sie Textvorlagen, die in der gesamten App verwendet werden k\u00f6nnen. ',
  edit_text_template: 'Textvorlage \u00e4ndern',
  insert_text_template: 'Textvorlage einf\u00fcgen',
  create_text_template: 'Neue Textvorlage erstellen',
  enter_text: 'Text eingeben\u2026',
  discard_draft: 'Die Meldung wird nicht gespeichert. Sind Sie sicher?',
  accepted: 'Akzeptiert',
  add_to_all_ws: 'Zu allen Arbeitsbereichen hinzfügen',
  profile_assignment: 'Profilzuweisung',
  as_user: 'als Benutzer',
  as_manager: 'als Manager',
  user_added_to_all_ws: 'Erfolgreich zu allen Arbeitsbereichen hinzugefügt.',
  notification_error_general: 'Fehler passiert, bitte versuchen Sie es erneut.',
  start_date_must_be_before_due_date:
    'Das Startdatum muss vor dem Fälligkeitsdatum liegen.',
  file_download_in_progress:
    'Bitte warten Sie, bis wir den Export für Sie generieren!',
  download: 'Herunterladen',
  add_media: 'Medien einfügen',
  format: 'Bi\u00e7im',
  include_subtasks: 'Alt g\u00f6revleri dahil et',
  include: 'Einbeziehen',
  custom: 'Benutzerdefiniert',
  standard: 'Standard',
  header: 'Kopfzeile (Firmenlogo und Datum)',
  issue_details:
    'Details der Meldung (ID, Arbeitsbereich, Zugewiesen an, Status\u2026 etc.)',
  media: 'Media',
  duplicate_to_subtask: 'Kopieren zum subaufgabe',
  copy_comments: 'Kommentare kopieren',
  assign_to_same_user: 'Denselben Personen zuweisen',
  issue: 'Meldung',
};
