import React from 'react';
import Linkify from 'react-linkify';

export const LinkifyWrapper = ({ children }) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}
          onClick={(e) => e.stopPropagation()}
        >
          {decoratedText}
        </a>
      )}
    >
      {children}
    </Linkify>
  );
};
