import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOGS_OPTIONS } from '../constants';
import useFilters from './useFilters';

const useUserLogs = () => {
  const dispatch = useDispatch();
  const [activeQueryType, setActiveQueryType] = useState(LOGS_OPTIONS[0].value);
  const handleActiveQueryTypeChange = (type) => setActiveQueryType(type);
  const isQrTimeLog = activeQueryType === LOGS_OPTIONS[2].value;
  const isQrScanLog = activeQueryType === LOGS_OPTIONS[1].value;

  const { filters, setFilters } = useFilters();

  const setMultiselectFilter = useCallback(
    (item, key) => {
      const selectedIds = filters[key];
      const isSelected = selectedIds.filter(
        (sItem) => Number(sItem.id) === Number(item.id)
      ).length;

      let newIds = [];

      if (isSelected) {
        newIds = selectedIds.filter(
          (sItem) => Number(sItem.id) !== Number(item.id)
        );
      } else {
        newIds = [...selectedIds, { id: item.id, name: item.name }];
      }

      dispatch(setFilters({ [key]: newIds, page: 1 }));
    },
    [filters, setFilters, dispatch]
  );

  const clearMultiselectFilter = useCallback(
    (key) => {
      dispatch(setFilters({ [key]: [] }));
    },
    [dispatch, setFilters]
  );

  return {
    activeQueryType,
    handleActiveQueryTypeChange,
    isQrTimeLog,
    setMultiselectFilter,
    clearMultiselectFilter,
    filters,
    isQrScanLog,
  };
};

export default useUserLogs;
