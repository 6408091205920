export const actionStrings = {
  LOAD_QRSCAN_LOG: 'LOAD_QRSCAN_LOG',
  LOAD_QRSCAN_LOG_SUCCESS: 'LOAD_QRSCAN_LOG_SUCCESS',
  LOAD_QRSCAN_LOG_ERROR: 'LOAD_QRSCAN_LOG_ERROR',
  QR_SCANLOG_LOADER: 'QR_SCANLOG_LOADER',
};

export const actions = {
  loadQrScanLog: (code) => ({
    type: actionStrings.LOAD_QRSCAN_LOG,
    payload: {
      code,
    },
  }),
  loadQrScanLogSuccess: (data) => ({
    type: actionStrings.LOAD_QRSCAN_LOG_SUCCESS,
    payload: {
      data,
    },
  }),
  loadQrScanLogError: (error) => ({
    type: actionStrings.LOAD_QRSCAN_LOG_ERRORG,
    payload: {
      error,
    },
  }),
  QrScanLogLoader: () => ({
    type: actionStrings.QR_SCANLOG_LOADER,
  }),
};
