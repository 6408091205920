import { removeData, localStorageKey, getData } from './../redux/utils';

const hasTokenExpired = (expiration) => {
  const tokenExpirationUnix = expiration * 1000;
  const now = new Date().getTime();

  return tokenExpirationUnix < now;
};
export const unauthorisedAccessError = 'Access denied!';
export const checkForUnauthorisedAccess = (resp) => {
  if (resp && resp.status && resp.status === 403) {
    const { expiration } = getData(localStorageKey);

    if (hasTokenExpired(expiration)) {
      removeData(localStorageKey);

      throw new Error(unauthorisedAccessError);
    }
  }

  return resp;
};
export const handleUnauthorisedAccess = (error) => {
  if (error && error.message === unauthorisedAccessError) {
    window.location.href = '/login';

    throw error;
  }

  throw error;
};

const getLegacyUrlRegex = (url) => new RegExp(`://(${url}).*wowflow`);

export const handleLegacyUrl = (url) => {
  if (getLegacyUrlRegex('dev').test(url)) {
    return url.replace('dev', 'api.dev');
  }

  if (getLegacyUrlRegex('staging').test(url)) {
    return url.replace('staging', 'api.staging');
  }

  if (getLegacyUrlRegex('').test(url)) {
    return url.replace('//wowflow', '//api.wowflow');
  }

  return url;
};
