import { useState } from 'react';

export const useTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return { activeTab, handleTabChange };
};

export default useTabs;
