import { FormattedMessage } from 'react-intl';
import Space from '../../../../../components_new/Space';
import Tooltip from '../../../../../components_new/Tooltip';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
  WowTooltipWrapper,
} from '../../../../../components_new/WowBasicElements';
import { limitNumberOfCharacters } from '../../../IssuesV2/Issue/components/AddEditWrapper/components/IssueTabs/Notes/utils';
import { darkGray, purple500, darkerGray } from '../../../../../config/colors';
import { internalRoutePaths } from '../../../../../config/config';
import { CardLinkWrapper } from '../../styles';
import { Avatar } from './styles';
import { openInNewTab } from '../../../../../utils/core';

//Imports for capacity statistic.
//import { WowBlock } from '../../../../../components_new/WowBasicElements';
//import { darkerGray } from '../../../../../config/colors';
//import { ProgressBar, ProgresBarInner } './styles';

const CardUser = (props) => {
  const { full_name, profile_image, id } = props;

  const DEFAULT_AVATAR_URL = 'https://www.gravatar.com/avatar?d=mp';

  const handleGoToUser = () => {
    const url = internalRoutePaths.USER_DETAIL.replace(':id', id);
    openInNewTab(url);
  };

  return (
    <WowDisplayFlex direction="column" align="flex-start">
      <WowDisplayFlex justify="flex-end" width="100%">
        <WowIcon
          className="icon icon-export"
          size="20"
          color={darkerGray}
          spaceRight="0"
        />
      </WowDisplayFlex>
      <Space height="25" />
      <Avatar src={profile_image ?? DEFAULT_AVATAR_URL} />
      <Space height="5" />
      <Tooltip
        key={id}
        tooltipContent={
          full_name?.length < 45 ? (
            ''
          ) : (
            <WowTooltipWrapper width="250">{full_name}</WowTooltipWrapper>
          )
        }
      >
        <WowColorizeText color={darkGray} size="15" fWeight="bold">
          {full_name ? limitNumberOfCharacters(full_name, 45) : ''}
        </WowColorizeText>
      </Tooltip>
      {/*
        // Uncomment when we implement user capacity statistic.
        <Space height="5" />
        <WowBlock>
          <WowColorizeText size="13" color={darkerGray}>
            {capacity ?? 0}% <FormattedMessage id="capacity" />
          </WowColorizeText>
          <Space height="3" />
          <ProgressBar>
            <ProgresBarInner width={capacity ?? 0} />
          </ProgressBar>
        </WowBlock>
        */}
      <CardLinkWrapper onClick={handleGoToUser}>
        <FormattedMessage id="profile" />
        <WowIcon
          className="icon icon-arrowupleft"
          size="15"
          color={purple500}
        />
      </CardLinkWrapper>
    </WowDisplayFlex>
  );
};

export default CardUser;
