import { useMemo } from 'react';
import Collapsible from '../../../Collapsible';
import { useIntl } from 'react-intl';
import {
  PRIORITY_ITEMS,
  PRIORITY_VALUES,
} from '../../../../../FilterPriority/constants';
import usePreviewAction from '../../hooks/usePreviewAction';

const Priority = (props) => {
  const { value, labelKey, filterKey } = props;
  const intl = useIntl();

  const selectedOptions = useMemo(() => {
    return PRIORITY_ITEMS.filter((item) => value?.includes(item.value)).map(
      (item) => ({ ...item, name: intl.formatMessage({ id: item.labelKey }) })
    );
  }, [value, intl]);

  const { handleStaticFilterMultiselectChange } = usePreviewAction();

  const handleItemChange = (item) => {
    handleStaticFilterMultiselectChange({
      filterKey,
      value: item.value,
      filterValues: PRIORITY_VALUES,
    });
  };

  return (
    <Collapsible
      labelKey={labelKey}
      count={selectedOptions.length ?? 0}
      items={selectedOptions}
      handleItemChange={handleItemChange}
    />
  );
};

export default Priority;
