import { FormattedMessage } from 'react-intl';
import {
  WowDisplayFlex,
  WowIcon,
} from '../../../../../../components_new/WowBasicElements';
import { greenButtonColor } from '../../../../../../config/colors';
import {
  TrialWrapper,
  Title,
  Paragraph,
  StyledButton,
  StyledLink,
} from './styles';
import useMobileTrial from './hooks/useMobileTrial';

const MobileTrial = () => {
  const { handleLinkClick, translationKeys, store } = useMobileTrial();

  const email = (
    <StyledLink href="mailto:hello@wowflow.com">hello@wowflow.com</StyledLink>
  );

  return (
    <TrialWrapper>
      <WowDisplayFlex direction="column" align="flex-start">
        <WowIcon
          className="icon icon-complete"
          size="60"
          color={greenButtonColor}
        />
        <Title>
          <FormattedMessage id="trial_starts_today" />
        </Title>
        <Paragraph>
          <FormattedMessage id="free_trial_details" values={{ email }} />
        </Paragraph>
        {translationKeys.paragraph ? (
          <Paragraph>
            <FormattedMessage
              id={translationKeys?.paragraph}
              values={{ store }}
            />
          </Paragraph>
        ) : null}

        {translationKeys.button ? (
          <StyledButton
            size="small"
            color="blue"
            type="button"
            onClick={handleLinkClick}
          >
            <FormattedMessage id={translationKeys?.button} />
          </StyledButton>
        ) : null}
      </WowDisplayFlex>
    </TrialWrapper>
  );
};

export default MobileTrial;
