import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOnboardingProgress } from '../../../../../../../redux/config/selectors';
import useOnboarding from '../../../hooks/useOnboarding';
import { STEP_TYPES } from '../../../constants';

const useIndustry = () => {
  const onboardingProgress = useSelector(getOnboardingProgress);

  const formatedOptions = useMemo(() => {
    const options = onboardingProgress?.industries ?? [];

    return options.map(({ name, label = '' }, index) => ({
      id: index,
      label,
      value: name,
    }));
  }, [onboardingProgress?.industries]);

  const [selected, setSelected] = useState();

  const handleChange = (option) => {
    const { value } = option;
    setSelected(value);
  };

  const { handleUpdateSteps, isLoading } = useOnboarding();

  const handleContinue = () => {
    handleUpdateSteps({
      stepType: STEP_TYPES.industry,
      industry: selected,
    });
  };

  const handleSkip = () => {
    handleUpdateSteps({
      stepType: STEP_TYPES.industry,
      isSkipped: true,
    });
  };

  return {
    formatedOptions,
    handleChange,
    selected,
    isLoading,
    handleContinue,
    handleSkip,
  };
};

export default useIndustry;
