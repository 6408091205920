import { lazy } from 'react';

const QuickFilters = lazy(() => import('./components/FilterQuick'));
const Type = lazy(() => import('./components/FilterType'));
const Status = lazy(() => import('./components/FilterStatus'));
const Workspaces = lazy(() => import('./components/FilterWorkspaces'));
const Users = lazy(() => import('./components/FilterUsers'));
const Collaborators = lazy(() => import('./components/FilterCollaborators'));
const DateRange = lazy(() => import('./components/FilterDateRange'));
const Priority = lazy(() => import('./components/FilterPriority'));
const Schedules = lazy(() => import('./components/FilterSchedules'));
const Category = lazy(() => import('./components/FilterCategory'));
const Assets = lazy(() => import('./components/FilterAssets'));
const Position = lazy(() => import('./components/FilterPosition'));
const Externals = lazy(() => import('./components/FilterExternals'));
const FilterSchedulesStatus = lazy(() =>
  import('./components/FilterSchedulesStatus')
);

const Equipment = lazy(() => import('./components/FilterEquipment'));

export const ENTITIES = {
  issue: 'issue',
  schedule: 'schedule',
  'yearly-calendar': 'yearly-calendar',
  calendar: 'calendar',
};

//TO DO > consider moving due to different entities!
const FILTER_CONFIG_SCHEDULE = [
  { id: 1, type: 'statusSchedule' },
  { id: 2, type: 'workspaces' },
  { id: 3, type: 'users' },
  { id: 4, type: 'collaborators' },
  { id: 5, type: 'priority' },
  { id: 6, type: 'category' },
  { id: 7, type: 'assets' },
  { id: 8, type: 'position' },
  { id: 9, type: 'equipment' },
];

export const FILTERS_CONFIG = {
  [ENTITIES.issue]: [
    { id: 1, type: 'quick' },
    { id: 2, type: 'type' },
    { id: 3, type: 'status' },
    { id: 4, type: 'workspaces' },
    { id: 5, type: 'users' },
    { id: 6, type: 'collaborators' },
    { id: 7, type: 'date' },
    { id: 8, type: 'priority' },
    { id: 9, type: 'schedules' },
    { id: 10, type: 'category' },
    { id: 11, type: 'assets' },
    { id: 12, type: 'position' },
    { id: 13, type: 'externals' },
  ],
  [ENTITIES.schedule]: FILTER_CONFIG_SCHEDULE,
  [ENTITIES['yearly-calendar']]: FILTER_CONFIG_SCHEDULE,
  [ENTITIES.calendar]: [
    { id: 1, type: 'type' },
    { id: 2, type: 'status' },
    { id: 3, type: 'workspaces' },
    { id: 4, type: 'users' },
    { id: 5, type: 'priority' },
    { id: 6, type: 'schedules' },
    { id: 7, type: 'category' },
    { id: 8, type: 'assets' },
    { id: 9, type: 'position' },
    { id: 10, type: 'externals' },
  ],
};

export const FILTER_COMPONENTS = {
  quick: QuickFilters,
  type: Type,
  status: Status,
  workspaces: Workspaces,
  users: Users,
  collaborators: Collaborators,
  date: DateRange,
  priority: Priority,
  schedules: Schedules,
  category: Category,
  assets: Assets,
  position: Position,
  externals: Externals,
  statusSchedule: FilterSchedulesStatus,
  equipment: Equipment,
};

export const POPER_CONFIG = {
  placement: 'bottom',
  modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
};

export const INPUT_MAX_LENGT = 100;
