import React from 'react';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
  WowTextBold,
  WowTooltipWrapper,
} from '../../../../../components_new/WowBasicElements';
import Tooltip from '../../../../../components_new/Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { darkerGray, white } from '../../../../../config/colors';
import {
  TimelineHeading,
  TimelineTextWrapperStyled,
  TimelineWrapper,
} from '../styles';
import { BreakTimeWrapper } from './styles';

const TimelineItem = ({ item, isLastItem }) => {
  const intl = useIntl();

  const {
    break_started_at: breakStart,
    break_finished_at: breakFinish,
    formatted_time: formattedTime,
    hours_paused: hoursPaused,
    hours_worked: hoursWorked,
    invalid_logout: isInvalidLogout,
  } = item;

  const isBreak = !!breakStart;
  const hours = isBreak ? hoursPaused : hoursWorked;

  return (
    <TimelineWrapper isLastItem={isLastItem}>
      <TimelineHeading>
        {isBreak ? intl.formatMessage({ id: 'break' }) : item.workspace_name}
      </TimelineHeading>
      <TimelineTextWrapperStyled size={14} isBreak={isBreak}>
        <WowDisplayFlex align="center" justify="space-between">
          {formattedTime}
          <WowDisplayFlex align="center">
            <WowTextBold size="15" fWeight="700">
              <WowColorizeText color={darkerGray}>{hours}</WowColorizeText>
            </WowTextBold>
            {isInvalidLogout ? (
              <Tooltip
                tooltipContent={
                  <WowTooltipWrapper width="150">
                    <FormattedMessage id="info.ws_logout_unproper" />
                  </WowTooltipWrapper>
                }
              >
                <WowIcon
                  className="icon-important-round"
                  color={darkerGray}
                  size="18"
                  spaceRight="15"
                  spaceLeft="15"
                  display="flex"
                />
              </Tooltip>
            ) : null}
            {isBreak ? (
              <BreakTimeWrapper breakFinish={breakFinish}>
                <WowIcon
                  className="icon-break-time"
                  color={white}
                  size="20"
                  spaceRight="15"
                  spaceLeft="15"
                  display="flex"
                />
              </BreakTimeWrapper>
            ) : null}
          </WowDisplayFlex>
        </WowDisplayFlex>
      </TimelineTextWrapperStyled>
    </TimelineWrapper>
  );
};

export default TimelineItem;
