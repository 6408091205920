import PropTypes from 'prop-types';
import {
  WowBlock,
  WowColorizeText,
} from '../../../../../../components_new/WowBasicElements';
import { darkerGray, gray } from '../../../../../../config/colors';
import { Label, Wrapper } from './styles';

const RightSectionInputPreview = (props) => {
  const { label, text, textSize, placeholder } = props;

  return (
    <Wrapper>
      {label ? <Label>{label}</Label> : null}
      <WowBlock>
        {text ? (
          <WowColorizeText color={darkerGray} size={textSize}>
            {text}
          </WowColorizeText>
        ) : placeholder ? (
          <WowColorizeText color={gray} size={textSize}>
            {placeholder}
          </WowColorizeText>
        ) : null}
      </WowBlock>
    </Wrapper>
  );
};

RightSectionInputPreview.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  textSize: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default RightSectionInputPreview;
