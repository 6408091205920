import styled from 'styled-components/macro';

import {
  boxShadow,
  darkerGray,
  hardWhite,
  outline,
  purple500,
  white,
} from '../../config/colors';
import { themeColour } from '../../helpers/theme';

export const MenuWrapper = styled.div`
  position: absolute;
  top: 40px;
  width: 200px;
  background-color: ${white};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px 0 ${boxShadow};
  z-index: 100;
`;

export const MenuItems = styled.ul`
  padding: 0px;
  margin: 0px;
  list-style: none;
`;

export const MenuItem = styled.li`
  font-size: 14px;
  font-weight: 600;
  color: ${({ selected, ...restProps }) =>
    selected ? themeColour(restProps) ?? purple500 : darkerGray};
  padding: 12px 14px;
  border-bottom: 1px solid ${outline};
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover,
  &:focus {
    background-color: ${hardWhite};
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export default MenuItem;
