import styled from 'styled-components/macro';

import {
  darkerGray,
  purple500,
  outline,
  gray,
  violet,
} from '../../../../../config/colors';

export const FormWrapper = styled.form`
  position: ${({ position }) => (position ? position : 'static')};
  z-index: 150;
  ${({ bottom }) => (bottom !== null ? `bottom: ${bottom};` : '')}
  ${({ top }) => (top !== null ? `top: ${top};` : '')}
  ${({ left }) => (left !== null ? `left: ${left};` : '')}
  ${({ right }) => (right !== null ? `right: ${right};` : '')}
`;

export const Wrapper = styled.div`
  height: auto;
  width: 410px;
  box-shadow: 3px -1px 15px 1px ${gray};
  border-radius: 10px;
  background-color: #fff;
  z-index: 100;
  @media only screen and (max-width: 1600px) {
    & {
      width: 350px;
    }
  }
`;

export const DetailsWrapper = styled.div`
  padding: 20px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100% !important;

  .image-carousel,
  .img-carousel {
    width: 100% !important;
    height: 194px !important;
  }
`;

export const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AssigneeAvatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  object-fit: cover;
  margin-right: 8px;
`;

export const StatusBox = styled.div`
  padding: 2px 4px;
  color: #fff;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#000'};
  font-size: 9px;
  margin-right: 8px;
`;

export const ScheduledIssueBox = styled.div`
  border-radius: 100%;
  background-color: ${violet};
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderDetails = styled.div`
  margin-bottom: 6px;
`;

export const IssueTitle = styled.div`
  font-size: 20px;
  color: ${darkerGray};
  cursor: pointer;
  word-break: break-all;
`;

export const WorkspaceRow = styled.div`
  display: flex;
  margin-top: 7px;
`;

export const CategoryText = styled.div`
  color: ${gray};
  font-size: 14px;
  margin-right: 15px;
  &::after {
    content: '';
    display: inline-block;
    background-color: ${darkerGray};
    width: 4.5px;
    height: 4.5px;
    border-radius: 100%;
    position: relative;
    bottom: 2px;
    left: 8px;
  }
`;

export const WorkspaceText = styled.div`
  color: ${darkerGray};
  font-size: 14px;
`;

export const StatsDetails = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const SingleStatsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  &:last-of-type: {
    margin-right: 0;
  }
`;

export const SingleStatsText = styled.div`
  font-size: 11px;
  color: ${darkerGray};
`;

export const DescriptionWrapper = styled.div`
  color: ${darkerGray};
  font-size: 16px;
  margin-bottom: 10px;
  word-break: break-all;
  & span.__readMore {
    cursor: pointer;
    color: ${purple500};
  }
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid ${outline};
`;
