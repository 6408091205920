import { ArrowButton, Arrow } from './styles';

const ToggleButton = ({ isOpen, onClick }) => {
  return (
    <ArrowButton isOpen={isOpen} onClick={onClick}>
      <Arrow />
    </ArrowButton>
  );
};

export default ToggleButton;
