export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};

export const getObjectUrl = (props) => {
  const mimeType = props.mimeType ? props.mimeType : props.mime_type;
  if (!props || !props.url) {
    return '';
  }

  const isRawFile = props.url.includes('base64');

  if (!isRawFile) {
    return props.url;
  }

  if (isRawFile) {
    const blob = b64toBlob(props.url.split('base64,')[1], mimeType);

    const value = URL.createObjectURL(blob);

    return value;
  }
};
