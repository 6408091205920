import qs from 'query-string';
import { config } from '../../../config/apiConfig';
import { baseApi } from '../../../config/rtk';

export const profileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'notification/', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Notifications', id })),
              { type: 'Notifications', id: 'LIST' },
            ]
          : [{ type: 'Notifications', id: 'LIST' }],
    }),
    togglePauseNotifications: builder.mutation({
      query(isPause) {
        return {
          url: `/notification/${isPause ? 'pause' : 'unpause'}`,
          method: 'POST',
          body: { pause_for: 1 },
        };
      },
    }),
    toggleReadNotification: builder.mutation({
      query(data) {
        const { id, isRead } = data;
        return {
          url: `/notification/${id}/${
            isRead ? 'mark_unread' : 'web/mark_read'
          }`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: 'Notifications', id: 'LIST' },
        { type: 'Notifications', id },
      ],
    }),
    readAllNotifications: builder.mutation({
      query() {
        return {
          url: '/notification/web/mark_read',
          method: 'PUT',
        };
      },
      invalidatesTags: [{ type: 'Notifications', id: 'LIST' }],
    }),
    postSurvey: builder.mutation({
      query(payload) {
        return {
          url: `${config.API.SURVEY}`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Notifications', id: 'LIST' },
      ],
    }),
    getUserStatistics: builder.query({
      query: () => {
        const url = `/user/statistics`;

        return url;
      },
      providesTags: () => [{ type: 'UserStatistics', id: 'LIST' }],
    }),
    switchClient: builder.mutation({
      query(payload) {
        return {
          url: '/user/switch_client',
          method: 'POST',
          body: payload,
        };
      },
      //invalidatesTags: () => [{ type: 'UserStatistics', id: 'LIST' }],
    }),
    userOftenDetails: builder.query({
      query: () => {
        const url = `/user/me/often_details`;

        return url;
      },
      providesTags: () => [{ type: 'UserOftenDetails', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useTogglePauseNotificationsMutation,
  useToggleReadNotificationMutation,
  useReadAllNotificationsMutation,
  usePostSurveyMutation,
  useGetUserStatisticsQuery,
  useSwitchClientMutation,
  useLazyUserOftenDetailsQuery,
} = profileApi;
