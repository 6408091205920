import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import dict from '../../../config/multilang';
import { RenderIf } from '../../render-if/render-if.component';
import GallerySingleItem from './gallery-single-item';
import styled from 'styled-components/macro';
import {
  white,
  outline,
  darkerGray,
  darkGray,
  pillGray,
  gray,
  userGray,
  filtersBlue,
} from '../../../config/colors';
import { AutocompleteTextArea } from '../../form/text-area/autocomplete-text-area';
import { breakpoint } from '../../../styles/styledComponents/media-breakpoints';
import { dateFormats } from '../../../config/config';
import { DateSelector } from '../../../components/menu-selector/Selectors/Date/date-selector.jsx';
import { DropDown } from '../../dropdown/dropdown.component';
import { colorHelper } from '../../../config/color-helper';
import { Comments } from '../comments.component';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  ${({ isInfoPanelOpened }) =>
    isInfoPanelOpened &&
    `
    width: 75%;
  `}
  position: relative;

  @media only screen and ${breakpoint.xs} {
    width: 100%;
  }
`;

const InfoPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isInfoPanelOpened }) => (isInfoPanelOpened ? '25%' : '80px')};
  min-width: ${({ isInfoPanelOpened }) =>
    isInfoPanelOpened ? '350px' : '80px'};
  height: 100%;
  background-color: ${white};

  @media only screen and ${breakpoint.xs} {
    position: absolute;
    background-color: transparent;
    width: auto;
    height: 100px;
    right: 0;
    top: 0
      ${({ isInfoPanelOpened }) =>
        !isInfoPanelOpened &&
        `
      background-color: ${white};
      width: 70%;
      height: 100%;
      box-shadow: 0 2px 10px 0 rgba(55,70,95,.1);
    `};
  }
`;

const InfoPanelTitle = styled.div`
  height: ${({ isInfoPanelOpened }) => (!isInfoPanelOpened ? 'auto' : '80px')};
  margin: 0 20px;
  border-bottom: 1px solid ${outline};
  display: flex;
  ${({ isInfoPanelOpened }) =>
    !isInfoPanelOpened &&
    `
    flex-direction: column-reverse;
    margin: 5px 0;
    border-bottom: none;
  `}
  align-items: center;

  @media only screen and ${breakpoint.xs} {
    border-bottom: none;

    ${({ isInfoPanelOpened }) =>
      !isInfoPanelOpened &&
      `
      flex-direction: row;
      justify-content: center;
      border-bottom: 1px solid ${outline};
    `}
  }
`;

const UserName = styled.span`
  color: ${darkerGray};
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and ${breakpoint.xs} {
    display: none;
  }
`;

const IconsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  ${({ isInfoPanelOpened }) =>
    !isInfoPanelOpened &&
    `
    flex-direction: column-reverse;
    margin-left: 0;
    border-bottom: 1px solid ${outline};
    padding: 5px 0;
  `}

  @media only screen and ${breakpoint.xs} {
    border-bottom: none;

    ${({ isInfoPanelOpened }) =>
      !isInfoPanelOpened &&
      `
      flex-direction: row;
    `}
  }
`;

const InfoPanelIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
  ${({ isInfoPanelOpened }) =>
    !isInfoPanelOpened &&
    `
    margin: 5px;
  `}
  i {
    display: flex;
    &:before {
      color: ${({ color }) => color};
      font-size: 22px;
    }
  }
  @media print {
    display: none !important;
  }
`;

const Description = styled.div`
  padding: 10px 10px 30px;
  border-bottom: 1px solid ${outline};
  .characters-left {
    @media print {
      display: none !important;
    }
  }

  ${({ isInfoPanelOpened }) =>
    !isInfoPanelOpened &&
    `
    display: none
  `}

  @media only screen and ${breakpoint.xs} {
    display: none
      ${({ isInfoPanelOpened }) =>
        !isInfoPanelOpened &&
        `
      display: block
    `};
  }
`;

const UserImage = styled.img`
  width: 35px;
  height: 35px;
  margin-top: 10px;
  ${({ isInfoPanelOpened }) =>
    isInfoPanelOpened &&
    `
    margin-right: 15px;
    margin-top: 0;
  `}
  border-radius: 50%;

  @media only screen and ${breakpoint.xs} {
    border-bottom: none;
    margin-right: 5px;

    ${({ isInfoPanelOpened }) =>
      !isInfoPanelOpened &&
      `
      margin-top: 0px;
    `}
  }
`;

const GallerySidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SectionLabel = styled.span`
  color: ${userGray};
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.1px;
  line-height: 20px;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid ${outline};
  padding-left: 1rem;
`;

const CommentsWrapper = styled.div`
  margin-top: auto;

  ${({ isInfoPanelOpened }) =>
    !isInfoPanelOpened &&
    `
    display: none
  `}

  @media only screen and ${breakpoint.xs} {
    display: none
      ${({ isInfoPanelOpened }) =>
        !isInfoPanelOpened &&
        `
      display: block;
    `};
  }
`;

const DateSelectorWrapper = styled.div`
  padding: 0 1rem;
  border-bottom: 1px solid ${outline};
  display: flex;
  align-items: center;

  label {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 1rem 0;
    color: ${darkerGray};
    width: 35%;
  }

  input::placeholder {
    color: ${filtersBlue};
  }

  .date-selector {
    width: auto;
    .react-datepicker__input-container {
      input {
        &:focus {
          outline: none;
        }
      }
    }
  }
`;

export const prioritiesOptions = [
  {
    text: 'no_priority',
    id: 0,
  },
  {
    text: 'urgent',
    id: 5,
  },
  {
    text: 'high',
    id: 4,
  },
  {
    text: 'normal',
    id: 3,
  },
  {
    text: 'low',
    id: 2,
  },
];

const GallerySingleView = (props) => {
  const {
    comments,
    user,
    enableOrdering,
    onPostGalleryCommentClick,
    document,
    onRemoveItem,
    canRemove,
    handleListViewToggle,
    attachmentCount,
    canNavigateNext,
    canNavigatePrev,
    navigateNext,
    navigatePrev,
    onCancel,
    canEdit,
    isPublic,
    intl,
    onDescriptionChange,
    showSaveOnDescription,
    onSaveDescriptionClick,
    isNewItem,
    onPriorityChange,
    onDeadlineChange,
    isUsingAttachmentDueDate,
    isUsingAttachmentPriority,
  } = props;

  const [showInfoPanel, setShowInfoPanel] = useState(true);
  const [description, setDescription] = useState(document.description);

  const handleSubmitComments = (comment) => {
    onPostGalleryCommentClick(document?.id)(comment);
  };

  const handleRemoveItem = () => {
    if (onRemoveItem) {
      onRemoveItem();
    }
  };

  const toggleInfoPanel = () => {
    setShowInfoPanel(!showInfoPanel);
  };

  const showComments =
    (canEdit || comments) && !isNewItem && document.state !== 'NEW';

  const translateValues = (values) =>
    values
      ? values.map(({ id, text }) => ({
          id,
          text: intl.formatMessage({
            id: text,
            defaultMessage: dict[text],
          }),
        }))
      : values;

  const defaultId = 0;

  const selectedId = document.priority_id ? document.priority_id : defaultId;

  const parsedDateValue = document.due_date * 1000;

  const colorValue = prioritiesOptions.find((x) => x.id === selectedId);

  const onCurrentDescriptionChange = (event) => {
    onDescriptionChange(event);
    setDescription(event.target.value);
  };

  return (
    <Wrapper>
      <Image isInfoPanelOpened={showInfoPanel}>
        <GallerySingleItem
          url={document.url}
          mimeType={document.mimeType ? document.mimeType : document.mime_type}
          mediaType={
            document.mediaType ? document.mediaType : document.media_type
          }
          description={document.description}
          state={document.state}
          index={document.index}
          canRemove={canRemove}
          onRemoveItem={handleRemoveItem}
          user={document.user}
          enableOrdering={enableOrdering}
          downloadUrl={document.url}
          name={
            document.fileName ? document.fileName : document.original_filename
          }
          handleListViewToggle={handleListViewToggle}
          attachmentCount={attachmentCount}
          canNavigateNext={canNavigateNext}
          canNavigatePrev={canNavigatePrev}
          navigateNext={navigateNext}
          navigatePrev={navigatePrev}
        />
      </Image>
      <InfoPanel isInfoPanelOpened={showInfoPanel}>
        <InfoPanelTitle isInfoPanelOpened={showInfoPanel}>
          <UserImage
            isInfoPanelOpened={showInfoPanel}
            src={
              document.user?.profileImageUrl
                ? document.user?.profileImageUrl
                : document.user?.profile_image
            }
          />
          <RenderIf if={showInfoPanel}>
            <UserName>
              {document.user?.full_name
                ? document.user?.full_name
                : document.user?.name}
            </UserName>
          </RenderIf>
          <IconsWrapper isInfoPanelOpened={showInfoPanel}>
            <InfoPanelIcon
              onClick={toggleInfoPanel}
              bgColor={gray}
              color={white}
            >
              <i className="icon icon-important-round" />
            </InfoPanelIcon>
            <InfoPanelIcon
              onClick={onCancel}
              bgColor={pillGray}
              color={darkGray}
            >
              <i className="icon icon-close" />
            </InfoPanelIcon>
          </IconsWrapper>
        </InfoPanelTitle>
        <GallerySidebarContent>
          {canEdit || document.description ? (
            <Description isInfoPanelOpened={showInfoPanel}>
              <AutocompleteTextArea
                isPublic={isPublic}
                name="description"
                placeholder={intl.formatMessage({
                  id: 'type_description',
                  defaultMessage: dict.type_description,
                })}
                noBorder={true}
                handleChange={onCurrentDescriptionChange}
                value={description || ''}
                maxLength={255}
                enableAutocomplete={canEdit}
                backgroundColor={white}
                showSaveButton={showSaveOnDescription}
                onSaveText={onSaveDescriptionClick}
                disabled={!canEdit}
              />
            </Description>
          ) : (
            <div></div>
          )}

          {showInfoPanel && isUsingAttachmentPriority ? (
            <div style={{ padding: 20, paddingTop: 0 }}>
              <DropDown
                name="priority_id"
                heading={intl.formatMessage({
                  id: 'priority',
                  defaultMessage: dict.priority,
                })}
                initialySelectedId={selectedId}
                type="priority"
                values={translateValues(prioritiesOptions)}
                onChange={onPriorityChange}
                disabled={!canEdit}
                color={colorHelper[colorValue.text]}
              />
            </div>
          ) : null}

          {showInfoPanel && isUsingAttachmentDueDate ? (
            <DateSelectorWrapper>
              <label>
                {intl.formatMessage({
                  id: 'deadline',
                  defaultMessage: dict.deadline,
                })}
              </label>
              <DateSelector
                disabled={!canEdit}
                value={parsedDateValue}
                onChange={onDeadlineChange}
                removable={document.due_date}
                noValueMessage={intl.formatMessage({
                  id: 'add_new',
                  defaultMessage: dict.not_specified,
                })}
                minDate={new Date()}
                format={dateFormats.STANDARD_DATE_DATEPICKER}
                doneButtonText={intl.formatMessage({
                  id: 'done',
                  defaultMessage: dict.done,
                })}
                dateFormat={dateFormats.STANDARD_DATE_DATEPICKER}
                timeFormat={dateFormats.STANDARD_TIME_DATEPICKER}
              />
            </DateSelectorWrapper>
          ) : null}

          {showComments ? (
            <CommentsWrapper isInfoPanelOpened={showInfoPanel}>
              <SectionLabel>
                {intl.formatMessage({
                  id: 'comment_log',
                  defaultMessage: 'comment_log',
                })}
              </SectionLabel>
              <Comments
                comments={comments}
                canCommentIssue={canEdit}
                user={user}
                onSubmit={handleSubmitComments}
                inGallery
              />
            </CommentsWrapper>
          ) : null}
        </GallerySidebarContent>
      </InfoPanel>
    </Wrapper>
  );
};

GallerySingleView.propTypes = {
  document: PropTypes.shape({
    url: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    description: PropTypes.string,
    canRemove: PropTypes.bool,
    state: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    user: PropTypes.shape({
      name: PropTypes.string,
      profiledocumentUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
  canNavigateNext: PropTypes.bool,
  canNavigatePrev: PropTypes.bool,
  navigateNext: PropTypes.func,
  navigatePrev: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onDescriptionChange: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
  galleryChanged: PropTypes.func,
  showSaveOnDescription: PropTypes.bool,
  onSaveDescriptionClick: PropTypes.func,
};

export default injectIntl(GallerySingleView);
