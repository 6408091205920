import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  ACTIVE_ISSUE_STATUSES,
  ACTIVE_ISSUE_STATUSES_OPTIONS,
  ARCHIVED_ISSUE_STATUSES,
  ARHIVED_ISSUE_STATUSES_OPTIONS,
} from '../../../../../../FilterStatus/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getEntity, getPreviewFilter } from '../../../../../../../selectors';
import useFilters from '../../../../../../../hooks/useFilters';
import {
  setIsPreviewFilterDirty,
  setPreviewFilter,
} from '../../../../../../../slice';

const useStatus = ({ value, filterKey } = {}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const previewFilter = useSelector(getPreviewFilter);
  const entity = useSelector(getEntity);
  const { formatStringFilter } = useFilters({ entity, skip: true });

  const formatOptions = useCallback(
    (options, values) => {
      return options
        .filter((item) => values?.includes(item.value))
        .map((item) => ({
          ...item,
          name: intl.formatMessage({ id: item.labelKey }),
        }));
    },
    [intl]
  );

  const selectedOptions = useMemo(() => {
    const activeOptions = formatOptions(
      ACTIVE_ISSUE_STATUSES_OPTIONS,
      value.active
    );
    const archivedOptions = formatOptions(
      ARHIVED_ISSUE_STATUSES_OPTIONS,
      value.archived
    );

    return {
      active: activeOptions,
      archived: archivedOptions,
      count: activeOptions.length + archivedOptions.length,
    };
  }, [formatOptions, value.active, value.archived]);

  const handleStatusChange = (statusKey, value, allStatuses) => {
    const currentString = previewFilter.params?.[filterKey]?.[statusKey] ?? '';

    const isSelectAll = !allStatuses.includes(value);
    const newString = isSelectAll
      ? allStatuses.toString() !== currentString
        ? allStatuses.toString()
        : null
      : formatStringFilter(currentString, value);

    const newParameters = {
      ...previewFilter.params,
      [filterKey]: {
        ...(previewFilter.params[filterKey] || {}),
        [statusKey]: newString?.length ? newString : null,
      },
    };

    if (
      newParameters[filterKey].active === null &&
      newParameters[filterKey].archived === null
    ) {
      delete newParameters[filterKey];
    }

    dispatch(setPreviewFilter({ ...previewFilter, params: newParameters }));
    dispatch(setIsPreviewFilterDirty(true));
  };

  const handleActiveStatusChange = (value) => {
    handleStatusChange('active', value, ACTIVE_ISSUE_STATUSES);
  };

  const handleArchivedStatusChange = (value) => {
    handleStatusChange('archived', value, ARCHIVED_ISSUE_STATUSES);
  };

  return {
    selectedOptions,
    handleActiveStatusChange,
    handleArchivedStatusChange,
  };
};

export default useStatus;
