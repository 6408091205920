import { useRef, useEffect } from 'react';

// hook used to cache all options
// generaly used for caching of select options
const useCachedOptions = ({ options, idKey = 'id', skip }) => {
  const cachedOptions = useRef({});

  // useEffect hook to save all options in cache
  useEffect(() => {
    if (options?.length && !skip) {
      options.forEach((option) => {
        // map options as key value pair
        cachedOptions.current[option[idKey]] = option;
      });
    }
  }, [options, idKey, skip]);

  return cachedOptions.current;
};

export default useCachedOptions;
