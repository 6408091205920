import React, { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AvatarCircle } from '../../../../components/avatar-circle/avatar-circle.component';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import { NoItems } from '../../../../components_new/Dropdown/styles';
import InfiniteScroll from '../../../../components_new/InfiniteScroll';

import Modal, { SIZE } from '../../../../components_new/Modal';

import { useDebounce } from '../../../../hooks/useDebounce';
import { useWorkspaces } from '../../Equipment/hooks';
import AssignToWorkspaceButton from './AssignToWorkspaceButton';
import {
  Content,
  Icon,
  Input,
  LoaderWrapper,
  OptionLabel,
  Search,
  SubWorkspaceOption,
  TitleContent,
  TitleText,
  WorkspaceOption,
} from './styles';
import {
  useChangeWorkspaceRolesMutation,
  useGetWorkspaceRolesQuery,
} from '../../users/service';
import {
  ModalFooter,
  ModalFooterButtonWrapper,
  StyledButton,
} from './../../../../components_new/WowModalElements/index';
import Space from '../../../../components_new/Space';

const Title = ({ user }) => {
  return (
    <TitleContent>
      <AvatarCircle
        key={user.id}
        image={{
          img: user.profile_image,
          alt: `${user.full_name} profile picture`,
        }}
      />
      <TitleText>
        {user.full_name}
        {' - '}
        <FormattedMessage id="workspaces" />
        {':'}
      </TitleText>
    </TitleContent>
  );
};

const WorkspacesModal = ({ isOpen, onClose, user }) => {
  const intl = useIntl();
  const [localState, setLocalState] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);
  const { data: workspaces, isFetching: isLoadingWorkspaceRoles } =
    useGetWorkspaceRolesQuery(user.id);

  const { scrollState, handleLoadMore, handleSearch, isFetching } =
    useWorkspaces();

  useEffect(() => {
    handleSearch(debouncedSearch);
  }, [debouncedSearch, handleSearch]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const [changeWorkspaceRoles, { isLoading: isLoadingChangeWorkspaceRoles }] =
    useChangeWorkspaceRolesMutation();

  const handleSave = async () => {
    try {
      await changeWorkspaceRoles({
        id: user.id,
        body: { changed_roles: localState },
      });
      onClose();
    } catch {}
  };

  const handleChange = (value) => {
    const localValue = localState.some(
      (item) => item.workspace_id === value.workspace_id
    );
    let newState = [];

    if (localValue) {
      newState = localState.map((item) =>
        item.workspace_id === value.workspace_id ? value : item
      );
    } else {
      newState = [...localState, value];
    }

    setLocalState(newState);
  };

  const items = scrollState.itemsList.filter((item) =>
    debouncedSearch.length ? true : item.parent_id === null
  );

  const getUserRole = (workspace) => {
    const localValue = localState.find(
      (item) => item.workspace_id === workspace.id
    );

    if (localValue) return localValue.workspace_role;

    const selectedWorkspace = workspaces?.find(
      (item) => item.id === workspace.id
    );

    if (selectedWorkspace) return selectedWorkspace.user_role;

    return 'none';
  };

  const handleCancel = () => {
    setLocalState([]);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={SIZE.medium}
      title={<Title user={user} />}
      maxHeight="740px"
      hasTitleIcon={false}
    >
      <Search>
        <Icon className="icon icon-zoom" />
        <Input
          placeholder={intl.formatMessage({
            id: 'search',
          })}
          onChange={handleSearchChange}
          value={search}
        />
      </Search>

      <Content>
        {items.length === 0 ? (
          isFetching || isLoadingWorkspaceRoles ? (
            <LoaderWrapper>
              <LoadingSpinner />
            </LoaderWrapper>
          ) : (
            <NoItems>
              <FormattedMessage id="no_items" />
            </NoItems>
          )
        ) : isLoadingWorkspaceRoles ? (
          <LoaderWrapper>
            <LoadingSpinner />
          </LoaderWrapper>
        ) : (
          <InfiniteScroll
            dataLength={items.length}
            handleLoadMore={handleLoadMore}
            hasMore={scrollState.hasMore}
            height={480}
            maxHeight={480}
            customStyle={{ position: 'unset' }}
          >
            {items.map((workspaceOption) => {
              const userRole = getUserRole(workspaceOption);

              return (
                <Fragment key={workspaceOption.id}>
                  <WorkspaceOption>
                    <Icon className="icon icon-workspace" />
                    <OptionLabel>{workspaceOption.name}</OptionLabel>
                    <AssignToWorkspaceButton
                      workspacePosition={userRole}
                      handleChange={handleChange}
                      workspaceId={workspaceOption.id}
                    />
                  </WorkspaceOption>
                  {workspaceOption.children.length
                    ? workspaceOption.children.map((subWorkspaceOption) => {
                        const userRole = getUserRole(subWorkspaceOption);

                        return (
                          <SubWorkspaceOption key={subWorkspaceOption.id}>
                            <Icon className="icon icon-arrowdownright" />
                            <OptionLabel>{subWorkspaceOption.name}</OptionLabel>

                            <AssignToWorkspaceButton
                              workspacePosition={userRole}
                              handleChange={handleChange}
                              workspaceId={subWorkspaceOption.id}
                            />
                          </SubWorkspaceOption>
                        );
                      })
                    : null}
                </Fragment>
              );
            })}
          </InfiniteScroll>
        )}
      </Content>
      <ModalFooter>
        <ModalFooterButtonWrapper>
          <StyledButton
            onClick={handleSave}
            size="small"
            disabled={isLoadingChangeWorkspaceRoles}
          >
            <FormattedMessage id="send" defaultMessage="Send" />
          </StyledButton>
          {isLoadingChangeWorkspaceRoles ? (
            <LoadingSpinner size="small" />
          ) : null}
        </ModalFooterButtonWrapper>
        <Space width="20" />
        <ModalFooterButtonWrapper>
          <StyledButton
            color="red"
            appearance="text"
            size="small"
            onClick={handleCancel}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </StyledButton>
        </ModalFooterButtonWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default WorkspacesModal;
