/**
 * Filter names
 */
export const WORKSPACE_LIST = 'WORKSPACE_LIST';
export const FAVORITE_WORKSPACES = 'FAVORITE_WORKSPACES';
export const USER_LIST = 'USER_LIST';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const WS_ISSUE_LIST = 'WS_ISSUE_LIST';
export const SUB_WS_LIST = 'SUB_WS_LIST';
export const ISSUE_LIST = 'ISSUE_LIST';
export const ISSUE_CATEGORY = 'ISSUE_CATEGORY';
export const AUTOCOMPLETE_LIST = 'AUTOCOMPLETE_LIST';

/**
 * Actual filter configs are stored in src\helpers\filter.js
 */
