import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/sr';
import 'dayjs/locale/hr';
import 'dayjs/locale/bs';
import 'dayjs/locale/tr';
import 'dayjs/locale/pl';
import { dateFormats } from '../config/config';
import { getLocaleForLanguage } from './multilang.helper';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export const formatTimestampToDateAndMonth = (date, language) => {
  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.DATE_AND_MONTH);
};

export const formatTimestampToDateAndMonthAndYear = (date, language) => {
  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.DATE_AND_MONTH_YEAR);
};

export const formatTimestampToNamedMonthDayAndYear = (date, language) => {
  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.NAMED_MONTH_DAY_YEAR);
};

export const formatDateToStandardFormat = (date, _language = 'en') => {
  // Hack: when language is '', it does not become 'en'
  const language = _language ? _language : 'en';

  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.STANDARD_DATE);
};

export const formatDateToHours = (date, language = 'en') => {
  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.HOURS);
};

export const formatTimestampToMonthDayYearAndTime = (date, language) => {
  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.NAMED_MONTH_DAY_YEAR_TIME);
};

export const formatTimestampFullDateAndTime = (date) => {
  return dayjs(date).format(dateFormats.DATE_MONTH_YEAR_TIME);
};

export const formatToTimestamp = (date) => {
  return dayjs(date).format(dateFormats.TIMESTAMP);
};

export const formatTimeAndDate = (date) =>
  dayjs(date).format(dateFormats.TIME_AND_DATE);

export const formatTimestampToMonth = (date, language) => {
  return dayjs(date)
    .locale(getLocaleForLanguage(language))
    .format(dateFormats.MONTH);
};

export const transformAndFormatDate = (date, language) => {
  date = new Date(date * 1000);

  return dayjs(date)
    .locale(getLocaleForLanguage(language ? language : 'en'))
    .format(dateFormats.DATE_AND_MONTH);
};

export const transformToFullFormat = (date) => {
  date = new Date(date * 1000);

  return dayjs(date).format(dateFormats.DATE_MONTH_YEAR_TIME);
};

export const formatDateToFilters = (date) => {
  return dayjs(date).format(dateFormats.STANDARD_DATE_FILTERS);
};

export const compareTimestamp = (d1, d2) => {
  const isDateEqual = !!(d1 && d2 && d1 >= d2);

  return isDateEqual;
};
