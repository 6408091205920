import { sortOrder, sortFields } from '../../config/config';

export const CACHE_LIFETIME_MS = 5 * 60 * 1000;

const dateFields = [sortFields.UPDATED_AT, sortFields.CREATED_AT];
const isDate = (sortField) => dateFields.includes(sortField);
const compareDates = (date1, date2) => {
  if (!date1) {
    return false;
  }

  if (!date2) {
    return true;
  }

  const timestamp1 = new Date(date1).getTime();
  const timestamp2 = new Date(date2).getTime();

  return timestamp1 < timestamp2;
};

export const propertyComparator = (sortField, order) => {
  return function (id1, id2) {
    let compareCondition = isDate(sortField)
      ? compareDates(id2[sortField], id1[sortField])
      : id2[sortField] < id1[sortField];

    if (order === sortOrder.DESC) {
      compareCondition = !compareCondition;
    }

    if (compareCondition) {
      return 1;
    } else {
      return -1;
    }
  };
};
