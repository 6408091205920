import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getOnboardingProgress } from '../../../../redux/config/selectors';
import { internalRoutePaths } from '../../../../config/config';
import {
  getShowOnboardingProcess,
  getShowOnboardingSuccessMobile,
} from '../selectors';

const useOnboarding = () => {
  const history = useHistory();
  const onboardingProgress = useSelector(getOnboardingProgress);
  const forceShowOnboardingProcess = useSelector(getShowOnboardingProcess);
  const showOnboardingScussessMobile = useSelector(
    getShowOnboardingSuccessMobile
  );

  const shouldShowOnboarding = useMemo(() => {
    if (!onboardingProgress || onboardingProgress.isOnboarded) return false;
    if (forceShowOnboardingProcess || showOnboardingScussessMobile) return true;

    const { steps = [], progress = {} } = onboardingProgress ?? {};

    const lastThreeSteps = steps.slice(-3);
    let countCompleted = 0;

    for (const item of lastThreeSteps) {
      const { step } = item;

      if (progress[step].isCompleted) countCompleted += 1;

      if (progress[step]?.isSkipped) {
        return false;
      }
    }

    // Show onboarding if not all the last three steps are completed
    return countCompleted !== lastThreeSteps.length;
  }, [
    forceShowOnboardingProcess,
    onboardingProgress,
    showOnboardingScussessMobile,
  ]);

  useEffect(() => {
    if (!onboardingProgress) return;

    if (shouldShowOnboarding) {
      history.push(internalRoutePaths.ONBOARDING);
    } else {
      //in case when forceShowOnboardingProcess is true and user refreshes page relocate to workspaces
      if (history?.location?.pathname === internalRoutePaths.ONBOARDING) {
        history.push(internalRoutePaths.WORKSPACES);
      }
    }
  }, [history, shouldShowOnboarding, onboardingProgress]);

  return {
    shouldShowOnboarding,
  };
};

export default useOnboarding;
