import styled from 'styled-components/macro';
import { WowLink } from '../../../components_new/WowBasicElements';
import {
  darkerGray,
  newBorder,
  outline,
  purple500,
  white,
} from '../../../config/colors';

//TO DO > as we go see if needed to lift this to sticky header folder for global use
export const TodayButton = styled.button`
  background-color: white;
  border-radius: 20px;
  border: 1px solid ${newBorder};
  padding: 6px 14px;
  text-transform: uppercase;
  color: ${darkerGray};
  font-size: 12px;
  margin: 0 10px 0 15px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonWithIcon = styled.button`
  font-size: 13px;
  border-radius: 20px;
  padding: 6px 14px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? purple500 : 'transparent')};
  text-transform: capitalize;
  color: ${({ isActive }) => (isActive ? white : darkerGray)};

  &:hover {
    background-color: ${({ isActive }) => (isActive ? purple500 : outline)};
  }
`;

export const CalendarWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgb(55 70 95 / 10%);
  background-color: white;
  padding: 25px;
  min-height: 100%;
`;

export const CalendarView = styled.div`
  display: grid;
  grid-template-columns: 110px calc(100% - 110px);
`;

export const CalendarHeaderDay = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  background-color: ${purple500};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
`;

export const CardLinkWrapper = styled(WowLink)`
  display: flex;
  padding-top: 10px;
  font-size: 14px;
  font-weight: 600;

  i:before {
    transform: rotate(90deg);
    font-size: 18px;
  }
`;
