import { getAnalytics, logEvent } from 'firebase/analytics';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/user/selectors';
import { openInNewTab } from '../../../utils/core';
import { ArrowRight, CtaButton, CtaWrapper } from '../styles';

const Video = (props) => {
  const { entity, videoId, isHeader, videoUrl } = props;
  const loggedInUser = useSelector(getUser);

  const handleOpenVideo = () => {
    const analytics = getAnalytics();

    logEvent(analytics, `wf__customer_support_${entity}_video_click`, {
      wf__user_id: loggedInUser?.id,
      wf__client_id: loggedInUser?.client?.id,
    });
    if (videoUrl) {
      openInNewTab(videoUrl);
      return;
    }
    openInNewTab(`https://www.youtube.com/watch?v=${videoId}`);
  };

  return (
    <>
      <CtaWrapper onClick={handleOpenVideo} isHeader={isHeader}>
        <CtaButton>
          <ArrowRight />
        </CtaButton>
        <FormattedMessage id="watch_video" />
      </CtaWrapper>
    </>
  );
};

Video.propTypes = {
  entity: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  isHeader: PropTypes.bool,
};

export default Video;
