import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setElementsInSecondRow,
  setIsShowMoreVisible,
  setShowMore,
} from '../slice';
import { getShowMore } from '../selectors';
import { useState } from 'react';
import { useMemo } from 'react';

const useShowMore = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const wrapperRef = useRef(null);
  const togglerRef = useRef(null);
  const showMore = useSelector(getShowMore);
  const [availableSpace, setAvailableSpace] = useState(0);

  const checkElementsInSecondRow = useCallback(() => {
    const wrapper = wrapperRef.current;

    if (wrapper) {
      dispatch(setElementsInSecondRow({}));
      const elements = Array.from(wrapper.children);
      const newElementsInSecondRow = {};

      elements.forEach((element, index) => {
        const elementRect = element.getBoundingClientRect();
        const isInSecondRow =
          elementRect.top !== elements[0].getBoundingClientRect().top;

        newElementsInSecondRow[index] = isInSecondRow;
      });

      const containerWidth = wrapper.offsetWidth;
      const visibleElements = elements.filter(
        (element, index) => !newElementsInSecondRow[index]
      );
      const visibleContentWidth = visibleElements.reduce(
        (totalWidth, child) => totalWidth + child.offsetWidth,
        0
      );
      const spaceOnTheRight =
        containerWidth - (visibleContentWidth + visibleElements.length * 15);

      const isShowMoreVisible =
        Object.values(newElementsInSecondRow).filter(
          (isInSecondRow) => isInSecondRow
        ).length > 1;
      dispatch(setIsShowMoreVisible(isShowMoreVisible));
      setAvailableSpace(spaceOnTheRight);
      dispatch(setShowMore(false));
      dispatch(setElementsInSecondRow(newElementsInSecondRow));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!wrapperRef?.current || showMore || !search?.length) return;

    const resizeObserver = new ResizeObserver(() => {
      checkElementsInSecondRow();
    });

    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkElementsInSecondRow, search?.length, showMore]);

  const [togglerWidth, setTogglerWidth] = useState(0);

  useEffect(() => {
    if (!togglerRef?.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setTogglerWidth(togglerRef.current.clientWidth);
    });

    resizeObserver.observe(togglerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    //cleanup
    return () => {
      dispatch(setShowMore(false));
      dispatch(setIsShowMoreVisible(false));
    };
  }, [dispatch]);

  const togglerPositionRight = useMemo(() => {
    return availableSpace > togglerWidth ? availableSpace - togglerWidth : 0;
  }, [availableSpace, togglerWidth]);

  return {
    wrapperRef,
    togglerRef,
    togglerWidth,
    availableSpace,
    togglerPositionRight,
  };
};

export default useShowMore;
