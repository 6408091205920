import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper,
  Section,
  Icon,
  SectionName,
  SubsectionsWrapper,
  SubsectionWrapper,
} from './styles';
import Subsection from './SubsectionItem';
import { NavLink, useLocation } from 'react-router-dom';
import { getIsActiveSection } from '../utils';
import { WowBetaLabel } from '../../../../../components_new/WowBasicElements';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../redux/user/selectors';
import { camelCase, capitalize } from 'lodash-es';

const SidebarItem = ({ item, isExpanded }) => {
  const { pathname } = useLocation();
  const loggedInUser = useSelector(getUser);
  const { settings } = loggedInUser?.client ?? {};
  const isAdmin = loggedInUser?.role === 'admin';

  const isSelected = useMemo(() => {
    return getIsActiveSection(pathname, item.route);
  }, [item.route, pathname]);

  const isExactRoute = useMemo(
    () => item.route === pathname,
    [item.route, pathname]
  );

  const route = useMemo(() => {
    if (item.id !== 'settings' && item.id !== 'templates') return item.route;

    if (item.id === 'templates')
      return item.subsections.find(
        (subsection) => subsection.id === 'checklists'
      ).route;

    if (isAdmin)
      return item.subsections.find((subsection) => subsection.id === 'general')
        .route;

    return item.subsections.find((subsection) => subsection.id === 'profile')
      .route;
  }, [isAdmin, item]);

  return (
    <>
      <Wrapper isSelected={isSelected} isExactRoute={isExactRoute} mainAccent>
        <NavLink to={route} data-testid={`${item.id}NavLink`}>
          <Section mainAccent>
            <Icon
              className={`icon ${item.icon}`}
              isSelected={isSelected}
              mainAccent
            />
            {isExpanded ? (
              <>
                <SectionName isSelected={isSelected} mainAccent>
                  <FormattedMessage id={item.id} />
                </SectionName>
                {item.isBeta ? <WowBetaLabel>BETA</WowBetaLabel> : null}
              </>
            ) : null}
          </Section>
        </NavLink>
      </Wrapper>

      {isSelected ? (
        <SubsectionsWrapper>
          {isExpanded && item.subsections
            ? item.subsections.map((subsection) =>
                (subsection.protected && !isAdmin) ||
                (subsection.protectedByKey &&
                  !settings?.[subsection.protectedByKey]) ? null : (
                  <SubsectionWrapper
                    isExactRoute={subsection.route === pathname}
                    key={subsection.id}
                  >
                    <NavLink
                      to={subsection.route}
                      key={subsection.id}
                      data-testid={camelCase(
                        `${item.id}${capitalize(subsection.id)}NavLink`
                      )}
                    >
                      <Subsection item={subsection} />
                    </NavLink>
                  </SubsectionWrapper>
                )
              )
            : null}
        </SubsectionsWrapper>
      ) : null}
    </>
  );
};

export default SidebarItem;
