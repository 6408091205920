/* eslint-disable no-prototype-builtins */
import {
  entities,
  userStatuses,
  sortOrder,
  PAGE_SIZE,
  QUERY_PARAM_CONST,
  DEFAULT_PAGE,
} from '../../config/config';
import { getIn } from 'immutable';
import {
  ROLES,
  getUserData,
  getFilterVisibilitySetting,
} from '../user/selectors';
import { createSelector } from 'reselect';

import {
  getEntityPath,
  getAllSortedEntities,
  getCollection,
  getEntityDataArray,
  getEntitiySelector,
  getCollectionData,
  isCollectionValid,
  getAllEntities,
  getKey,
  findEntitiesWithIds,
} from './coreSelectors';
import {
  WORKSPACE_LIST,
  FAVORITE_WORKSPACES,
  WS_ISSUE_LIST,
  ISSUE_LIST,
  CATEGORY_LIST,
  USER_LIST,
  ISSUE_CATEGORY,
  AUTOCOMPLETE_LIST,
} from '../filter/filters';
import { getFilter } from '../filter/selectors';
import _ from 'lodash-es';

export const getWorkspaceListParams = ({
  searchTerm = '',
  pageIndex = 0,
  isFavorite = false,
}) => ({
  page: pageIndex,
  pageSize: PAGE_SIZE,
  searchTerm,
  favouriteFilter: isFavorite
    ? QUERY_PARAM_CONST.FAVORITE
    : QUERY_PARAM_CONST.NON_FAVORITE,
});

/**
 * TODO: Move selectors to their respective folders
 *
 * workspace to workspace
 * issue to issue...
 */

export const getLanguagePath = getEntityPath(entities.LANGUAGE.reduxProp);
export const getIssuePath = getEntityPath(entities.ISSUE.reduxProp);
export const getWorkspacePath = getEntityPath(entities.WORKSPACE.reduxProp);

const transformEntityData = (entity) => {
  if (entity.hasOwnProperty('size') && entity.hasOwnProperty('toJS')) {
    return entity.toJS();
  }

  return entity;
};
export const getTranslation = createSelector(
  getLanguagePath,
  transformEntityData
);
export const getEntity = (state, entityType) => {
  const entity = state.entities.get(entityType);

  if (entity?.hasOwnProperty('size') && entity?.hasOwnProperty('toJS')) {
    return entity.toJS();
  }

  return entity;
};

export const getWorkspaceIssues = (state, id) =>
  state.entities
    .get(entities.ISSUE.reduxProp)
    .filter((i) => i.workspace_id === id);

// TODO: Improve loading mechanism, add ids
export const isEntityWithIdLoading = (state, entityType, id) =>
  state.entities
    .get('loading')
    .filter(
      (l) =>
        l.hasOwnProperty('entityId') &&
        l.entityType === entityType &&
        l.entityId === id
    );

export const getLoadingEntityStatuses = (state, entityType) =>
  state.entities
    .get('loading')
    .filter((l) => l.hasOwnProperty('entityId') && l.entityType === entityType);

export const getEntityLoadingStatus = (state, entityType) => {
  const loadings = getEntity(state, entities.LOADING.reduxProp);

  return loadings && loadings.length
    ? loadings.some((l) => l.entityType === entityType)
    : false;
};

const filterEntityType = (entityType) => (l) => l.entityType === entityType;

const isLoadingSameFilter = (entities, filter) =>
  entities.some((entity) => _.isEqual(entity.meta, filter));

export const getEntityLoadingStateByFilterValue = (
  state,
  entityType,
  filterValue
) =>
  isLoadingSameFilter(
    getEntity(state, entities.LOADING.reduxProp).filter(
      filterEntityType(entityType)
    ),
    filterValue
  );
export const getLoadingStatusForFilterName = (
  state,
  entityType,
  filterName
) => {
  const filterValue = getKey(entityType, getFilter(filterName)(state));

  return getEntityLoadingStateByFilterValue(
    state,
    entityType.name,
    filterValue
  );
};

export const getEntityById = (entityType, id) => (state) => {
  const entities = getEntity(state, entityType);

  return entities.find((e) => e.id === id);
};

export const isUserAdmin = (user) => getIn(user, ['role']) === ROLES.ADMIN;
export const onlyAdmins = (users) => users.filter(isUserAdmin);
export const getSelectedCategory = (state) =>
  getIn(state, ['entities', 'selectedCategory']);

export const getUsers = (state) =>
  getAllSortedEntities(state, {
    entityType: entities.USERS,
    sortField: 'name',
  });

export const getActiveUsers = createSelector(getUsers, (users) =>
  users.filter(({ status }) => status !== userStatuses.PAUSED)
);

export const getActiveAdmins = createSelector(getActiveUsers, onlyAdmins);

export const onlyActiveUsers = (users) =>
  users.filter(({ status }) => status === userStatuses.ACTIVE);

export const getAllWorkspaces = () => (state) => {
  return getAllSortedEntities(state, {
    entityType: entities.WORKSPACE,
    sortField: 'name',
    sortOrder: sortOrder.ASC,
  });
};

export const getEntityData = (entityType) => (state) => {
  const collection = getCollection(
    state,
    entityType,
    null,
    'updated_at',
    sortOrder.DESC
  );

  if (collection && collection.data) {
    return collection.data;
  }

  return [];
};

export const getIssues = getEntityData(entities.ISSUE);
export const getWorkspaces = getAllWorkspaces();

export const getIssuesArray = getEntityDataArray(getIssues);
export const getWorkspacesArray = getEntityDataArray(getWorkspaces);

const getIssuesData = getEntitiySelector(entities.ISSUE.reduxProp);

export const getIssuesPagination = createSelector(getIssuesData, (issues) => {
  const { data, ...rest } = issues;

  return rest;
});

export const getLoadingStates = getEntitiySelector(entities.LOADING.reduxProp);

export const isEntityLoading = (entityType) =>
  createSelector(getLoadingStates, (loadings) => {
    return loadings && loadings.length
      ? loadings.some((l) => l.entityType === entityType)
      : false;
  });

export const isWorkspaceLoading = isEntityLoading(entities.WORKSPACE.reduxProp);

export const getWsIssues = createSelector(
  getIssuesArray,
  (state, wsId) => wsId,
  (issues, wsId) => issues.filter((i) => i.workspace_id === Number(wsId))
);

export const getWorkspaceById = (workspaceId) =>
  createSelector(flattenAllWorkspaces, (workspaces) =>
    workspaces.find((workspace) => workspace.id === workspaceId)
  );

// TODO: Move into separate folder/file
export const LEGACY_getWsById = createSelector(
  getWorkspacesArray,
  (state, props = {}) => props,
  (workspaces, { wsId }) => {
    if (!workspaces || !workspaces.length) {
      return null;
    }

    const id = Number(wsId);
    const ws = workspaces.reduce((acc, ws) => {
      if (acc) {
        return acc;
      }

      if (ws.id === id) {
        return ws;
      }

      const child = ws.children
        ? ws.children.find((child) => child.id === id)
        : null;

      return child
        ? {
            ...child,
            parentId: ws.id,
            parentName: ws.name,
          }
        : child;
    }, null);

    if (!ws || (ws && !ws.name && !ws.id)) {
      return null;
    }

    return ws;
  }
);

export const getTranslations = getEntitiySelector(entities.LANGUAGE.reduxProp);

export const hasTranslations = createSelector(
  getTranslations,
  (translations) =>
    translations &&
    _.keys(translations).length &&
    _.values(translations).some((x) => x)
);

// Check about performance vs selecting once
export const getWorkspacesList = getCollectionData({
  entityType: entities.WORKSPACE,
  filterName: WORKSPACE_LIST,
});
export const getFavoriteWorkspacesList = getCollectionData({
  entityType: entities.WORKSPACE,
  filterName: FAVORITE_WORKSPACES,
});
export const getWsIssuesList = getCollectionData({
  entityType: entities.ISSUE,
  filterName: WS_ISSUE_LIST,
});
export const getIssuesList = getCollectionData({
  entityType: entities.ISSUE,
  filterName: ISSUE_LIST,
});
export const getCategoriesList = getCollectionData({
  entityType: entities.CATEGORY,
  filterName: CATEGORY_LIST,
});
export const getIssueCategoriesList = getCollectionData({
  entityType: entities.CATEGORY,
  filterName: ISSUE_CATEGORY,
});
export const getUsersList = getCollectionData({
  entityType: entities.USERS,
  filterName: USER_LIST,
});

export const isIssueListValid = isCollectionValid({
  entityType: entities.ISSUE,
  filterName: ISSUE_LIST,
});

export const isWsIssueListValid = isCollectionValid({
  entityType: entities.ISSUE,
  filterName: WS_ISSUE_LIST,
});

export const isWorkspaceListValid = isCollectionValid({
  entityType: entities.WORKSPACE,
  filterName: WORKSPACE_LIST,
});

export const isFavoriteWorkspaceListValid = isCollectionValid({
  entityType: entities.WORKSPACE,
  filterName: FAVORITE_WORKSPACES,
});

export const isCategoriesListValid = isCollectionValid({
  entityType: entities.CATEGORY,
  filterName: CATEGORY_LIST,
});

export const isUsersListValid = isCollectionValid({
  entityType: entities.USERS,
  filterName: USER_LIST,
});

export const getAutocompleteTemplatesList = getCollectionData({
  entityType: entities.TEMPLATE,
  filterName: AUTOCOMPLETE_LIST,
});

export const DEFAULT_FILTERS = {
  ISSUE: createSelector(
    [getUserData, getSelectedCategory],
    (user, category) => ({
      page: DEFAULT_PAGE,
      pageSize: PAGE_SIZE,
      userId: user.id,
      categoryId: category,
      workspace: null,
      searchTerm: '', // TODO TODO TODO TODO TODO
    })
  ),
};

export const getFilteredWorkspaces = createSelector(
  [getWorkspacesList, getFavoriteWorkspacesList],
  (workspaces, favoriteWorkspaces) =>
    _.uniqBy([...favoriteWorkspaces, ...workspaces], 'id')
);

export const mapUserForFilter = (user) => ({
  id: user.id,
  profileImageUrl: user.profile_image,
  value: `${user.first_name} ${user.last_name}`,
});
const mapUndeletableUser = (user) => ({
  ...mapUserForFilter(user),
});

export const getLoggedInUserFilter = createSelector(
  getUserData(['id', 'first_name', 'last_name', 'profile_image']),
  mapUndeletableUser
);
export const getFilterUsers = createSelector(getUsersList, (users) =>
  users.map(mapUserForFilter)
);

export const getFilterAssignees = (filter, state) => {
  const currentAssignees = filter?.assignees || [];
  // Get logged-in user as a filter object
  const userFilterItem = getLoggedInUserFilter(state);
  const onlyMyIssues = getFilterVisibilitySetting(state);
  const assigneesWithoutMe = currentAssignees.filter(
    (item) => item.id !== userFilterItem.id
  );

  const assignees = onlyMyIssues
    ? [...assigneesWithoutMe, userFilterItem]
    : assigneesWithoutMe;

  return [assignees, assigneesWithoutMe, onlyMyIssues];
};

export const getAllUnsortedWorkspaces = (state) =>
  getAllEntities(state, { entityType: entities.WORKSPACE });

export const flattenAllWorkspaces = createSelector(
  getAllUnsortedWorkspaces,
  (workspaces) =>
    _.orderBy(workspaces, ['name'], ['asc']).reduce((acc, ws) => {
      if (ws.archived || ws.deleted) return acc;
      acc = [...acc, ws];

      if (ws.children && ws.children.length) {
        acc = [
          ...acc,
          ...ws.children.map((item) => ({
            ...item,
            name: item.name,
            parentId: ws.id,
            parentName: ws.name,
          })),
        ];
      }

      return acc;
    }, [])
);

// If you find a need to touch this selector, try to remove it and use something else
export const LEGACY_flattenAllWorkspacesAndPrefixSubWsName = createSelector(
  getAllUnsortedWorkspaces,
  (workspaces) =>
    _.orderBy(workspaces, ['name'], ['asc']) // Sort ascending by workspace name
      .reduce((acc, ws) => {
        if (ws.archived || ws.deleted) return acc;
        acc = [...acc, ws];

        if (ws.children && ws.children.length) {
          acc = [
            ...acc,
            ...ws.children.map((item) => ({
              ...item,
              name: `- ${item.name}`, // TODO: This is ugly - shouldn't be done here
              parentId: ws.id,
              parentName: ws.name,
            })),
          ];
        }

        return acc;
      }, [])
);

export const getUserById = (userId) =>
  createSelector(getUsers, (users) => users.find((user) => user.id === userId));

export const getCategoryById = (categoryId) =>
  createSelector(getCategoriesList, (categories) => {
    return categories.find((category) => {
      return category.id === categoryId;
    });
  });

export const getAllIssues = (state) =>
  getAllEntities(state, { entityType: entities.ISSUE });

export const getAllUsers = (state) =>
  getAllEntities(state, { entityType: entities.USERS });

export const getIssueById = (issueId) =>
  createSelector(
    (state) => getAllIssues(state),
    (issues) => issues.find((issue) => issue.id === issueId)
  );

export const getUsersByIds = (ids) =>
  createSelector(
    (state) => getAllEntities(state, { entityType: entities.USERS }),
    (users) => findEntitiesWithIds(users, ids)
  );
