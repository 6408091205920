import EmaiActionsAcceptedModal from './components/EmaiActionsAcceptedModal';
import EmaiActionsDeclinedModal from './components/EmaiActionsDeclinedModal';

export const API_TAG_TYPES_COLLABORATORS = [
  'Collaborations',
  'CollaborationsClientInfo',
  'CollaborationWorkspaces',
  'CollaborationUsers',
  'CollaborationListNames',
  'CollaborationAssetSystems',
];

export const EMPTY_SCREEN_SETTINGS = {
  icon: 'icon-collaborators',
  keys: {
    title: 'no_collaborations',
    text: 'no_collaborations_text',
    actionButton: 'invite_collaborator',
  },
};

export const STATUS_OPTIONS = [
  {
    value: '',
    label: 'all',
  },
  {
    value: 'pending',
    label: 'pending',
  },
  {
    value: 'accepted',
    label: 'accepted',
  },
  {
    value: 'declined',
    label: 'declined',
  },
];

export const TYPE_OPTIONS = [
  {
    value: '',
    label: 'all',
  },
  {
    value: 'client',
    label: 'client',
  },
  {
    value: 'provider',
    label: 'provider',
  },
];

export const INIT_PARAMS = {
  page: '1',
  paginate_by: '20',
};

export const SUPPORTED_PARAMS = {
  page: {
    defaultValue: '1',
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  type: {
    defaultValue: '',
    allowedValues: ['client', 'provider'],
  },
  invitation_status: {
    defaultValue: '',
    allowedValues: ['pending', 'accepted', 'declined'],
  },
  search: {
    defaultValue: '',
    allowedValues: null,
  },
};

export const PERSIST_FILTERS_KEY = 'collaboration';

export const ACTION_MODALS = {
  accepted: (props) => <EmaiActionsAcceptedModal {...props} />,
  declined: (props) => <EmaiActionsDeclinedModal {...props} />,
};
