import { forwardRef } from 'react';
import styled from 'styled-components/macro';
import {
  darkerGray,
  gradientBlue,
  newBorder,
  white,
} from '../../config/colors';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  /** Hide checkbox visually but remain accessible to screen readers. */
  /** Source: https://polished.js.org/docs/#hidevisually */

  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  top: 0;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${({ size }) => (size ? size + 'px' : '22px')};
  height: ${({ size }) => (size ? size + 'px' : '22px')};
  background: ${(props) =>
    props.checked ? (props.color ? props.color : gradientBlue) : white};
  border: ${({ checked, checkedBorderColor, borderColor }) =>
    checked && checkedBorderColor
      ? `1.5px solid ${checkedBorderColor}`
      : checked
      ? ''
      : `2px solid ${borderColor ?? newBorder}`};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius + 'px' : '5px'};
  cursor: pointer;
  padding: ${({ iconPadding }) => (iconPadding ? iconPadding : '0')};
  position: relative;
  &:after {
    display: ${({ shadowColor }) => (shadowColor ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ shadowColor }) => (shadowColor ? shadowColor : '')};
    width: ${({ size }) => (size ? size + 'px' : '22px')};
    height: ${({ size }) => (size ? size + 'px' : '22px')};
    z-index: -1;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius + 'px' : '5px'};
    transform: ${({ shadowScale }) =>
      shadowScale ? 'scale(' + shadowScale + ')' : 'scale(1.33)'};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Label = styled.label`
  /* display: flex;
  align-content: center;
  justify-items: center;

  font-size: 14px;
  color: ${darkerGray};

  > * {
    margin-right: 8px;
  } */
  width: ${({ size }) => (size ? size + 'px' : '22px')};
  height: ${({ size }) => (size ? size + 'px' : '22px')};
`;

export const WowCheckbox = forwardRef(
  (
    {
      className,
      checked,
      size,
      label,
      borderRadius,
      iconPadding,
      shadowColor,
      shadowScale,
      borderColor,
      checkedBorderColor,
      color,
      name,
      ...props
    },
    ref
  ) => {
    return (
      <Label
        className={className}
        size={size}
        data-testid={name ?? 'checkboxLabel'}
      >
        <HiddenCheckbox checked={checked} name={name} {...props} />
        <StyledCheckbox
          checked={checked}
          size={size}
          borderRadius={borderRadius}
          iconPadding={iconPadding}
          shadowColor={shadowColor}
          shadowScale={shadowScale}
          borderColor={borderColor}
          checkedBorderColor={checkedBorderColor}
          color={color}
        >
          {/* TODO: Consider replacing "Icon" with real icon */}
          <Icon viewBox="0 0 22 22">
            <polyline points="17 6 8 14 5 10" />
          </Icon>
        </StyledCheckbox>
        {label ? label : null}
      </Label>
    );
  }
);
