import { forwardRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NoticeWithIcon } from '../../../../../components_new/Notice';
import Space from '../../../../../components_new/Space';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowTextBold,
  WowTextItalic,
} from '../../../../../components_new/WowBasicElements';
import { darkerGray } from '../../../../../config/colors';
import Footer from '../components/Footer';
import NoItems from '../components/NoItems';
import useFilters from '../hooks/useFilters';
import useQrTimelog from '../hooks/useQrTimelog';
import TimelineItem from './TimelineItem';

const QrCodeTimelog = forwardRef(({ handlePrint }, forwardedRef) => {
  const intl = useIntl();
  const { filters } = useFilters();
  const { scrollState, handleLoadMore, handleCustomQuery, totalTime } =
    useQrTimelog();

  useEffect(() => {
    handleCustomQuery({
      page: 1,
      workspace_ids: filters.workspace_ids.map((item) => item.id),
    });
  }, [handleCustomQuery, filters.workspace_ids]);

  if (scrollState.itemsList.length === 0) {
    return <NoItems />;
  }

  return (
    <>
      <div ref={forwardedRef}>
        <NoticeWithIcon variant="info" icon="icon-important-round">
          <FormattedMessage id="info.qr_time_log" />
        </NoticeWithIcon>
        <Space height="30" />
        <WowDisplayFlex justify="flex-end">
          <WowTextBold size="20">{totalTime}</WowTextBold>
          <WowColorizeText size="20" color={darkerGray}>
            {' '}
            {intl.formatMessage({ id: 'total' }).toLowerCase()}
          </WowColorizeText>
        </WowDisplayFlex>
        <WowDisplayFlex justify="end">
          <WowTextItalic>
            <WowColorizeText size="13" color={darkerGray}>
              *<FormattedMessage id="break_excluded" />
            </WowColorizeText>
          </WowTextItalic>
        </WowDisplayFlex>
        <Space height="20" />
        {scrollState.itemsList.map((item, index) => (
          <TimelineItem
            key={item.id}
            item={item}
            isLastItem={
              !scrollState.hasMore && index === scrollState.itemsList.length - 1
            }
          />
        ))}
      </div>
      <Footer
        hasMore={scrollState.hasMore}
        handleLoadMore={handleLoadMore}
        handlePrint={handlePrint}
      />
    </>
  );
});

export default QrCodeTimelog;
