import styled from 'styled-components/macro';
import { darkerGray, newRed } from '../../config/colors';
import Button from '../Button';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

export const InfoWrapper = styled.div`
  color: ${darkerGray};
  padding-bottom: 30px;
  font-size: 14px;
`;

export const StyledSecondaryActionButton = styled(Button)`
  color: ${newRed};

  &:hover {
    color: ${newRed};
  }
`;
