import { FormattedMessage } from 'react-intl';
import { ModalFooter, ModalFooterButtonWrapper } from '../WowModalElements';
import Button from '../Button';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';

const ModalFooterWrapper = (props) => {
  const {
    handleAction,
    handleCancel,
    isActionDisabled,
    isLoading,
    actionButtonKey,
  } = props;

  return (
    <ModalFooter justify>
      <ModalFooterButtonWrapper>
        <Button
          type="button"
          onClick={handleCancel}
          color="red"
          appearance="text"
          size="small"
          data-testid="cancelButton"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ModalFooterButtonWrapper>

      <ModalFooterButtonWrapper>
        <Button
          type="button"
          color="green"
          onClick={handleAction}
          appearance="contained"
          size="small"
          disabled={isActionDisabled || isLoading}
          data-testid="actionButton"
        >
          <FormattedMessage id={actionButtonKey ?? 'done'} />
        </Button>
        {isLoading ? <LoadingSpinner small /> : null}
      </ModalFooterButtonWrapper>
    </ModalFooter>
  );
};

export default ModalFooterWrapper;
