import styled from 'styled-components/macro';
import { white, grayThatWeNeedToName } from '../../config/colors';
import { DENSITY } from '../../config/config';

export const WowListItem = styled.div`
  border-radius: 10px;

  background-color: ${({ selected }) =>
    selected ? grayThatWeNeedToName : white};

  // TODO: Update according to design guidelines.
  // (Make shadow variations that can be reused)
  box-shadow: ${({ selected }) =>
    selected ? null : '0 2px 10px 0 rgba(55, 70, 95, 0.1)'};
`;

export const WowList = styled.div`
  box-shadow: ${({ density }) =>
    density === DENSITY.table ? '0 2px 10px 0 rgba(55, 70, 95, 0.1)' : null};

  /*if cards view*/
  ${({ isCardsView }) =>
    isCardsView
      ? `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 24px;
    grid-column-gap: 24px;
  `
      : null};

  /* Applies style to every WowListItem descendant of WowList */
  ${WowListItem} {
    margin-top: ${({ density }) =>
      density === DENSITY.comfortable
        ? '25px'
        : density === DENSITY.table
        ? '0'
        : '10px'};
  }

  /* Applies style to every WowListItem descendant of WowList */
  ${WowListItem}:first-child {
    margin-top: 0px;
  }
`;
