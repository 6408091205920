import { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { WowCheckbox } from '../../../../../../components/wow-checkbox';
import { gradientBlue } from '../../../../../../config/colors';
import { CheckboxLabel } from '../styles';

const AssignToUserItem = forwardRef((props, ref) => {
  const intl = useIntl();

  return (
    <WowCheckbox
      {...props}
      label={
        <CheckboxLabel>
          {intl.formatMessage({ id: 'assign_to_same_user' })}
        </CheckboxLabel>
      }
      checked={props.value}
      size={18}
      borderColor={props.borderColor ?? gradientBlue}
      data-testid="assignToUser"
    />
  );
});

export default AssignToUserItem;
