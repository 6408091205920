import { useEffect, useRef } from 'react';

import TimelineHoursMarker from '../../../TimelineHoursMarker';

import { Wrapper, HourLabelWrapper, InnerWrapper } from './styles';

const HoursLabel = ({
  hoursSections,
  currentTimeMarkerPosition,
  scrollLeft,
  setScrollLeft,
}) => {
  const wrapperElem = useRef();

  useEffect(() => {
    if (wrapperElem.current) wrapperElem.current.scrollLeft = scrollLeft;
  }, [scrollLeft]);

  useEffect(() => {
    const handler = (e) => setScrollLeft(e?.target?.scrollLeft ?? 0);

    const el = wrapperElem.current;
    el.addEventListener('scroll', handler);

    return () => {
      if (el) el.removeEventListener('scroll', handler);
    };
  }, [setScrollLeft]);

  return (
    <Wrapper ref={wrapperElem}>
      <InnerWrapper>
        <HourLabelWrapper>{hoursSections}</HourLabelWrapper>
        <TimelineHoursMarker left={currentTimeMarkerPosition} />
      </InnerWrapper>
    </Wrapper>
  );
};

export default HoursLabel;
