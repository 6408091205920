import { useState, useCallback } from 'react';

const useInfiniteScrollData = (
  initialState,
  getResetState = () => {},
  customLabel,
  disabledIds = []
) => {
  const [scrollState, setScrollState] = useState(initialState);

  const formatListItems = (data) => {
    return data.map((item) => {
      return {
        ...item,
        label: item[customLabel] || item.name || item.full_name || item.title,
        value: item.id,
        image: item.profile_image,
        disabled: disabledIds.includes(item.id),
        children: item.children?.map((child) => {
          return {
            ...child,
            label: child.name,
            value: child.id,
            image: item.profile_image,
            disabled: disabledIds.includes(item.id),
          };
        }),
      };
    });
  };

  const handleStateUpdate = useCallback(
    (fetchedData, overrideItemsList, forceInitOverride) => {
      setScrollState((prevState) => {
        const diffBetweenOldAndNewItems = forceInitOverride
          ? formatListItems(fetchedData?.data || [])
          : parseFetchedData(
              overrideItemsList ? [] : prevState.itemsList,
              fetchedData
            );

        const itemsList = [
          /* ...prevState.itemsList, */ // old items
          ...diffBetweenOldAndNewItems,
        ];

        const newState = {
          ...prevState,
          itemsList,
          lastPage: fetchedData?.last_page,
          hasMore: overrideItemsList
            ? true
            : prevState.query.page < fetchedData?.last_page
            ? true
            : false,
          totalItems: fetchedData?.total,
        };

        return newState;
      });
    },
    // TODO: Investigate why is the array empty and refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // TODO: Change skipTransformItemsForDropdown to transformItemsForDropdown
  // (By default reuturn whole objects)
  const mergeStateItems = useCallback(
    (newItems, skipTransformItemsForDropdown) => {
      setScrollState((prevState) => ({
        ...prevState,
        itemsList: [
          /* ...prevState.itemsList, */
          ...parseFetchedData(
            prevState.itemsList,
            newItems,
            skipTransformItemsForDropdown
          ),
        ],
      }));
    },

    // TODO: Investigate why is the array empty and refactor
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const parseFetchedData = (
    itemsList,
    newData,
    skipTransformItemsForDropdown
  ) => {
    //TO DO - rethink
    //in initial list first replace values from new ones if item is in the new lists as well. need this due to edit.
    const parseOldItems = itemsList.map((itm) => ({
      ...itm,
      ...newData?.data.find((item) => item.id === itm.id && item),
    }));

    //get IDs from parseOldItems
    const currentItemsIds = new Set(parseOldItems.map((item) => item.id));

    //get only unique new items
    const newItems =
      newData?.data?.filter?.((item) => !currentItemsIds.has(item.id)) ?? [];

    const parsedItems = [...parseOldItems, ...(newItems ? newItems : [])].map(
      (item) => {
        if (
          !scrollState.skipTransformItemsForDropdown &&
          !skipTransformItemsForDropdown
        ) {
          return {
            ...item,
            label:
              item[customLabel] || item.name || item.full_name || item.title,
            value: item.id,
            image: item.profile_image,
            disabled: disabledIds.includes(item.id),
            children: item?.children?.map?.((child) => {
              return {
                ...child,
                label: child.name,
                value: child.id,
                image: item.profile_image,
                disabled: disabledIds.includes(item.id),
              };
            }),
          };
        }

        return item;
      }
    );

    //add sort which is neeeded when adding/editing

    return newData ? parsedItems : [];
  };

  const handleLoadMore = () => {
    setScrollState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        page:
          prevState.query.page + 1 > prevState.lastPage
            ? prevState.query.page
            : prevState.query.page + 1,
      },
      hasMore: prevState.query.page + 1 > prevState.lastPage ? false : true,
    }));
  };

  const handleLoadMoreWithPaginateBy = useCallback((value) => {
    setScrollState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        page:
          prevState.query.page + 1 > prevState.lastPage
            ? prevState.query.page
            : prevState.query.page + 1,
        paginate_by: value ?? 50,
      },
      hasMore: prevState.query.page + 1 > prevState.lastPage ? false : true,
    }));
  }, []);

  const handleSearch = useCallback((value) => {
    setScrollState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        page: 1,
        search: value,
      },
      itemsList: value ? [] : prevState.itemsList,
    }));
  }, []);

  const handleCustomQuery = useCallback((value, preserveItemsList) => {
    setScrollState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        page: 1,
        ...value,
      },
      itemsList: preserveItemsList ? prevState.itemsList : [],
    }));
  }, []);

  const resetQuery = useCallback(() => {
    setScrollState((prevState) => {
      return {
        ...prevState,
        query: {
          ...prevState.query,
          page: 1,
          search: '',
        },
      };
    });
  }, []);

  const handleCustomQueryWithReset = useCallback(
    (value = {}) => {
      const state = getResetState();
      if (state) {
        setScrollState(() => ({
          ...state,
          query: {
            ...state.query,
            ...value,
            page: 1,
          },
        }));
      }
    },
    [getResetState]
  );

  const deleteManuallyFromListItems = useCallback(
    (ids) => {
      if (!ids) return;

      setScrollState((prevState) => {
        return {
          ...prevState,
          itemsList: prevState.itemsList.filter(
            (item) => !ids.includes(item.id)
          ),
        };
      });
    },
    [setScrollState]
  );

  const updateManuallyListItems = useCallback(
    (items) => {
      if (!items) return;

      setScrollState((prevState) => {
        return {
          ...prevState,
          itemsList: items,
        };
      });
    },
    [setScrollState]
  );

  return {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleLoadMoreWithPaginateBy,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    handleCustomQuery,
    deleteManuallyFromListItems,
    resetQuery,
    handleCustomQueryWithReset,
    updateManuallyListItems,
  };
};

export default useInfiniteScrollData;
