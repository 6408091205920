import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import dict from '../../../../config/multilang';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import {
  LogoImage,
  LogoLoader,
  RightSide,
  LoginMessage,
  CalendarButton,
  HeaderInnerContainer,
  CalendarLabel,
} from './headerStyles';
import './header.component.scss';
import { Btn } from '../../../../components/btn/btn.component';
import { getQueryParams } from '../../Equipment/selectors';
import { updateMultipleFilters } from '../../Equipment/slice';
import { INIT_QUERY } from '../../Equipment/constants';
import { WowIcon } from '../../../../components_new/WowBasicElements';
import { purple500 } from '../../../../config/colors';
import { internalRoutePaths } from '../../../../config/config';
import { BETA_LABEL } from '../constants';
import SupportActions from './components/SupportActions';
import useCalendarAppContainer from '../hooks/useCalendarAppContainer';
import { getCalendarPemission } from '../../../../redux/user/selectors';
import ProfileMenu from './components/ProfileMenu';

export default function Header(props) {
  const { clientLoading, logo, history, logout, isPublic, loginMessage } =
    props;

  const t = useIntl();
  const dispatch = useDispatch();

  const navigateToLogin = () => {
    history.push('/login');
  };

  const isCalendarActive = useSelector(getCalendarPemission);
  const calendarAppContainerStyle = useCalendarAppContainer();

  //used to redirect to equipment listing
  const equipmentListingQueryParams = useSelector(getQueryParams('listing'));
  const handleRedirectToEquipmentListing = (data, key) => {
    if (!data) return;

    //BE is not consistent with data hence check for both name and title!
    const {
      equipment_group_id: id,
      equipment_group_name: name,
      equipment_group_title: title,
      type,
    } = data;

    dispatch(
      updateMultipleFilters({
        query: {
          ...INIT_QUERY,
          [key]: `${id}`,
        },
        target: 'listing',
        filters: {
          group_ids: [],
          type_ids: [],
          manager_ids: [],
          workspace_ids: [],
          holder_ids: [],
          [key]: [
            {
              id,
              label: name || title,
              value: id,
              image: null,
              name: name || title,
            },
          ],
        },
      })
    );

    const stringified = qs.stringify({
      ...INIT_QUERY,
      [key]: id,
      isAdvancedFilterOpen: 1,
      density: equipmentListingQueryParams.density,
    });
    const config = {
      pathname: '/dashboard/equipment',
      search: stringified,
    };

    if (type && type === 'group_equipment_handout_extend_requested') {
      config['state'] = {
        notifications: {
          groupEquipmentHandoutExtendRequested: data,
        },
      };
    }

    //quick fix when accesed from the same page `/dashboard/equipment`
    //TO DO
    setTimeout(() => {
      history.push(config);
    }, 200);
  };

  const handleGoToCalendar = () => {
    const isOnCalendarPage = history?.location?.pathname.includes('calendar');
    if (!isOnCalendarPage) history.push(internalRoutePaths.CALENDAR);
  };

  return (
    <div className="header" data-testid="mainHeader">
      <HeaderInnerContainer
        {...(calendarAppContainerStyle
          ? { style: calendarAppContainerStyle }
          : {})}
      >
        <div className="header__logo">
          {clientLoading ? (
            <LogoLoader>
              <LoadingSpinner small />
            </LogoLoader>
          ) : (
            <LogoImage src={logo} alt="Aplication logo" />
          )}
        </div>
        {isPublic ? (
          <>
            {!loginMessage ? null : (
              <RightSide>
                <LoginMessage>{loginMessage}</LoginMessage>
                <Btn
                  name={t.formatMessage({
                    id: 'log_in',
                    defaultMessage: dict.log_in,
                  })}
                  className="primary"
                  wide={true}
                  active="green"
                  onClick={navigateToLogin}
                  noTheme
                  green
                  hover
                  hoverFullButton
                  hoverDarken
                />
              </RightSide>
            )}
          </>
        ) : (
          <div className="header__menu">
            {isCalendarActive ? (
              <CalendarButton
                className="mainHeaderCalendarButton"
                onClick={handleGoToCalendar}
              >
                <CalendarLabel>{BETA_LABEL}</CalendarLabel>
                <WowIcon
                  className="icon icon-calendar"
                  size="20"
                  color={purple500}
                  spaceRight="7"
                />
                <FormattedMessage id="calendar" />
              </CalendarButton>
            ) : null}

            <SupportActions />

            <ProfileMenu
              logout={logout}
              handleRedirectToEquipmentListing={
                handleRedirectToEquipmentListing
              }
            />
          </div>
        )}
      </HeaderInnerContainer>
    </div>
  );
}
