import styled from 'styled-components/macro';
import * as Tabs from '@radix-ui/react-tabs';

import {
  darkerGray,
  filtersAdvancedGray,
  gradientBlue,
} from '../../../../config/colors';

export const StyledTabs = styled(Tabs.Root)`
  display: flex;
  flex-direction: column;
`;

export const StyledList = styled(Tabs.List)`
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid #dce2e8;
`;

export const StyledTrigger = styled(Tabs.Trigger)`
  letter-spacing: 1.5px;
  white-space: nowrap;
  font-size: 11px;
  text-transform: uppercase;
  flex: 1;
  flex-shrink: 0;
  padding: 12px 20px;
  color: ${filtersAdvancedGray};
  user-select: 'none';
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: ${darkerGray};
  }

  &[data-state='active'] {
    color: ${darkerGray};
    box-shadow: inset 0 -1px 0 0 ${gradientBlue}, 0 1px 0 0 ${gradientBlue};
  }
`;
