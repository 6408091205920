import React from 'react';
import PropTypes from 'prop-types';

import './menu.component.scss';
import { MenuWrapper, MenuItems } from './menuStyle';

const menuClasses = (props) =>
  `menu ${props.className !== 'menu' ? props.className : ''} ${
    props.extraWide ? 'extra-wide' : ''
  } ${props.scroll ? 'menu-scroll' : ''}`;

export const Menu = (props) => (
  <MenuWrapper
    className={menuClasses(props)}
    extraWide={props.extraWide}
    sm={props.sm}
    xs={props.xs}
    style={props.style}
  >
    <MenuItems>{props.children}</MenuItems>
  </MenuWrapper>
);

Menu.propTypes = {
  className: PropTypes.string,
  extraWide: PropTypes.bool,
  scroll: PropTypes.bool,
};

Menu.defaultProps = {
  className: '',
  extraWide: false,
  scroll: false,
};
