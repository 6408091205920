import { authGET, jsonAuthReq } from './ApiService';
import { config } from './config';
import { entities } from './../config/config';

/* eslint-disable max-len */
const issuesRoute = (page, pageSize, searchTerm) =>
  `${config.URL}/api/${config.API_VERSION}/${config.API.ISSUES}?page=${page}&paginate_by=${pageSize}&search=${searchTerm}`;
const issuesForWorkspaceRoute = (workspaceId, page, pageSize, userId) =>
  `${config.URL}/api/${config.API_VERSION}/${config.API.ISSUES}?workspace_id=${workspaceId}&page=${page}&paginate_by=${pageSize}`;
const issuesForCategoryRoute = (categoryID, page, pageSize) =>
  `${config.URL}/api/${config.API_VERSION}/${config.API.ISSUES}?category_id=${categoryID}&page=${page}&paginate_by=${pageSize}`;
const entityBaseRoute = (entity) =>
  `${config.URL}/api/${config.API_VERSION}/${entity.toLowerCase()}?`;
/* eslint-enable */

const getRequestRoute = (entityType, page, pageSize, searchTerm) => {
  switch (entityType) {
    case entities.ISSUE.name:
      return issuesRoute(page, pageSize, searchTerm);
    default:
      throw new Error('Entity does not exist');
  }
};

export const addRouteParams = (route, entityType, parameters) => {
  if (!parameters) {
    return route;
  }

  switch (entityType) {
    case entities.ISSUE.name:
    case entities.WORKSPACE.name:
      if (parameters.page) {
        route = route.concat(`page=${parameters.page}&`);
      }

      if (parameters.pageSize) {
        route = route.concat(`paginate_by=${parameters.pageSize}&`);
      }

      if (parameters.searchTerm) {
        route = route.concat(`search=${parameters.searchTerm}&`);
      }

      if (parameters.workspaceId) {
        route = route.concat(`workspace_id=${parameters.workspaceId}&`);
      }

      if (parameters.categoryId) {
        route = route.concat(`category_id=${parameters.categoryId}&`);
      }

      if (parameters.statusFilter) {
        if (entityType !== entities.WORKSPACE.name) {
          let filters = parameters.statusFilter.split(',');
          if (filters.length > 1) {
            filters = filters.filter((item) => item.length > 0);
          }
          route = route.concat(`issue_state=${filters.join(',')}&`);
        }
      }
      if (parameters.issueType) {
        route = route.concat(`issue_type=${parameters.issueType}&`);
      }

      if (parameters.priority) {
        route = route.concat(`priority_id=${parameters.priority}&`);
      }

      if (parameters.sortField) {
        route = route.concat(`sort_by=${parameters.sortField}&`);
      }

      if (parameters.sortOrder) {
        route = route.concat(`sort_direction=${parameters.sortOrder}&`);
      }

      if (parameters.favouriteFilter) {
        route = route.concat(
          `filter_ws=${parameters.statusFilter || parameters.favouriteFilter}&`
        );
      }

      if (parameters.rating) {
        route = route.concat(`rating=${parameters.rating}&`);
      }

      if (parameters.categories && parameters.categories.length > 0) {
        route = route.concat(
          `categories=${parameters.categories.map((x) => x.id).join(',')}&`
        );
      }

      if (parameters.reporters && parameters.reporters.length > 0) {
        route = route.concat(
          `reporters=${parameters.reporters.map((x) => x.id).join(',')}&`
        );
      }

      if (parameters.assignees && parameters.assignees.length > 0) {
        route = route.concat(
          `assignees=${parameters.assignees.map((x) => x.id).join(',')}&`
        );
      }

      if (parameters.workspaces && parameters.workspaces.length > 0) {
        route = route.concat(
          `workspaces=${parameters.workspaces.map((x) => x.id).join(',')}&`
        );
      }

      if (
        parameters.scheduling_issues &&
        parameters.scheduling_issues.length > 0
      ) {
        route = route.concat(
          `schedule_id=${parameters.scheduling_issues
            .map((x) => x.id)
            .join(',')}&`
        );
      }

      if (parameters.asset_ids && parameters.asset_ids.length > 0) {
        route = route.concat(
          `asset_ids=${parameters.asset_ids.map((x) => x.id).join(',')}&`
        );
      }

      if (parameters.includeSubWs) {
        route = route.concat(`include_sub_ws=${parameters.includeSubWs}&`);
      }

      if (parameters.watchers && parameters.watchers.length > 0) {
        route = route.concat(
          `watchers=${parameters.watchers.map((x) => x.id).join(',')}&`
        );
      }

      if (parameters.workers && parameters.workers.length > 0) {
        route = route.concat(
          `worked_on_by=${parameters.workers.map((x) => x.id).join(',')}&`
        );
      }

      if (parameters.startDate) {
        route = route.concat(`date_from=${parameters.startDate}&`);
      }

      if (parameters.endDate) {
        route = route.concat(`date_to=${parameters.endDate}&`);
      }

      break;
    case entities.CATEGORY.name:
    case entities.USERS.name:
    case entities.TEMPLATE.name:
      if (!parameters) {
        return route;
      }

      if (parameters.page) {
        route = route.concat(`page=${parameters.page}&`);
      }

      if (parameters.searchTerm) {
        route = route.concat(`search=${parameters.searchTerm}&`);
      }

      if (parameters.statusFilter) {
        route = route.concat(`filter_status=${parameters.statusFilter}&`);
      }

      if (parameters.categoryId) {
        route = route.concat(`sort_by_category_id[0]=${parameters.categoryId}`);
      }

      break;
    default:
      throw new Error('Entity does not exist');
  }

  return route;
};

export const buildRequestRoute = (entityType, parameters) => {
  const route = entityBaseRoute(entityType);

  return addRouteParams(route, entityType, parameters);
};

export const getEntityPaged = (token, entityType, page, pageSize) => {
  return authGET(
    getRequestRoute(entityType, page, pageSize, ''),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const getEntityForWorkspacePaged = (
  token,
  workspaceId,
  page,
  pageSize,
  userId
) => {
  return jsonAuthReq(
    issuesForWorkspaceRoute(workspaceId, page, pageSize, userId),
    'GET',
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const getEntityForCategoryPaged = (
  token,
  categoryId,
  page,
  pageSize
) => {
  return authGET(
    issuesForCategoryRoute(categoryId, page, pageSize, ''),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const searchEntityPaged = (
  token,
  entityType,
  page,
  pageSize,
  searchTerm
) => {
  return authGET(
    getRequestRoute(entityType, page, pageSize, searchTerm),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const getEntityGeneric = (token, entityType, parameters) => {
  return authGET(
    buildRequestRoute(entityType, parameters),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};
