import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import dict from '../../config/multilang';
import {
  acceptedFileTypes,
  acceptedFileTypesArr,
  compressImage,
  compressThumb,
  MAX_SIZE,
  MAX_PDF_SIZE,
  shouldCompress,
  compressableFileTypes,
  uncompressableFileTypes,
} from './upload-helper';

import ModalInformationInvalidFiles from '../../components_new/MediaInput/components/ModalInformationInvalidFiles';
import useUploadWithAlerts from '../../components_new/MediaInput/hooks/useUploadWithAlerts';

import './upload-area.component.scss';

const isImageTooBig = (file) =>
  compressableFileTypes.includes(file.type) && file.size > MAX_SIZE;
const isDocumentTooBig = (file, customPdfMaxSize) => {
  if (customPdfMaxSize) {
    return (
      uncompressableFileTypes.includes(file.type) &&
      file.size > customPdfMaxSize
    );
  }

  return (
    uncompressableFileTypes.includes(file.type) && file.size > MAX_PDF_SIZE
  );
};

const isFileTooBig = (file, customPdfMaxSize) =>
  isImageTooBig(file) || isDocumentTooBig(file, customPdfMaxSize);

const UploadArea = (props) => {
  const verifyFile = (file) => {
    if (!file) {
      return false;
    }
    if (isFileTooBig(file, props.customPdfMaxSize)) {
      return false;
    }
    if (!acceptedFileTypesArr.includes(file.type)) {
      return false;
    }
    return true;
  };

  const uploadFile = (file, currentIndex, lastIndex) => {
    const isVerified = verifyFile(file);

    if (isVerified) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.addEventListener(
        'load',
        async () => {
          let loadedFile = file;
          let fileResult = reader.result;
          let thumbResult = '';

          if (shouldCompress(file)) {
            const { file: compressedFile, result: compressedResult } =
              await compressImage(file, reader.result);
            const { result: compressedThumbResult } = await compressThumb(
              compressedFile,
              compressedResult
            );

            loadedFile = compressedFile;
            fileResult = compressedResult;
            thumbResult = compressedThumbResult;
          }

          if (props.onUpload) {
            props.onUpload({
              file: loadedFile,
              fileSource: fileResult,
              thumbnailUrl: thumbResult,
              currentIdex: currentIndex,
              lastIndex: lastIndex,
            });
          }
        },
        false
      );
    }
  };

  const uploadFiles = (files) => {
    for (const [index, file] of files.entries()) {
      uploadFile(file, index, files.length - 1);
    }
  };

  const {
    onDrop,
    handleConfimAction,
    handleConfirmModalClose,
    handleValidationInfoModalClose,
    isOpenConfirmModal,
    isOpenValidationInfoModal,
    validationInfoModalMessage,
    localInvalidFiles,
  } = useUploadWithAlerts({
    uploadFiles,
    validatorPerFile: verifyFile,
    customMaxFileSize: props.customPdfMaxSize ?? MAX_PDF_SIZE,
  });

  const onCancel = (e) => {};

  return (
    <>
      <Dropzone
        onDrop={onDrop}
        onFileDialogCancel={onCancel}
        accept={acceptedFileTypes}
        disabled={props.disabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames(
                'dropzone',
                { dropzone: !props.buttonOnly },
                { 'dropzone--isActive': isDragActive },
                { 'upload-area': !props.noContent },
                { 'no-background': props.noContent },
                { 'disabled-clicks': props.disabled },
                { 'button-only': props.buttonOnly }
              )}
              ref={props.triggerUploadRefElement}
            >
              <input {...getInputProps()} />
              {props.noContent && props.children}
              {!props.noContent && (
                <div className="upload-area__container">
                  <i className="icon icon-upload upload-area__container__icon" />
                  <h4 className="upload-area__container__heading">
                    <FormattedMessage
                      id="drop_files_to_upload"
                      defaultMessage={dict.drop_files_to_upload}
                    />
                  </h4>
                  <p className="upload-area__container__paragraph">
                    <FormattedMessage
                      id="or_click_here"
                      defaultMessage={dict.or_click_here}
                    />
                  </p>
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
      <ModalInformationInvalidFiles
        localInvalidFiles={localInvalidFiles}
        validationInfoModalMessage={validationInfoModalMessage}
        // confirm part of props
        isOpenConfirmModal={isOpenConfirmModal}
        handleConfirmModalClose={handleConfirmModalClose}
        handleConfimAction={handleConfimAction}
        // when everything is invalid props of that modal
        isOpenValidationInfoModal={isOpenValidationInfoModal}
        handleValidationInfoModalClose={handleValidationInfoModalClose}
      />
    </>
  );
};

export default injectIntl(UploadArea);
