import styled from 'styled-components/macro';
import { darkerGray } from '../../../../../../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpinnerWrapper = styled.div`
  position: relative;
  margin-top: 65px;
`;

export const InProgress = styled.div`
  font-size: 22px;
  text-align: center;
  margin-top: 70px;
`;

export const PleaseWait = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: ${darkerGray};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 10px;
`;
