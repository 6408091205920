export const INFINITE_SCROLL_INITIAL_STATE = {
  query: {
    page: 1,
    paginate_by: 10,
  },
  data: [],
  lastPage: null,
  loading: false,
  total: 0,
};

export const ERRORS = {
  getItems: "The 'getItems' must be a function.",
  queryParams: "The 'queryParams' must be provided.",
};
