import { isObject } from 'lodash-es';

const SUPPORTED_TYPES = [
  { name: 'number' },
  { name: 'string' },
  { name: 'boolean' },
];

const _isValidType = (value) =>
  SUPPORTED_TYPES.some((type) => typeof value === type.name);

const _isNoneEmptyPrimitiveArray = (value) =>
  Array.isArray(value) && value.length > 0 && value.every(_isValidType);

export const validateValuesType = (values) => {
  const isValid = Object.values(values).every(
    _isValidType || _isNoneEmptyPrimitiveArray
  );

  if (!isValid) {
    throw new Error(
      `Unsupported type. Use one of the following [${SUPPORTED_TYPES.map(
        (item) => item.name
      ).join(', ')}]`
    );
  }
};

export const validateAllowedParams = (query, supportedParams) =>
  Object.entries(query).reduce((result, [key, value]) => {
    if (supportedParams[key]) {
      const allowedValues = supportedParams[key].allowedValues;
      const defaultValue = supportedParams[key].defaultValue;

      if (!allowedValues || allowedValues.includes(value.toString())) {
        result[key] = value;
      } else {
        result[key] = defaultValue;
      }
    }
    return result;
  }, {});

export const parseParameters = (parameters = {}, blacklisted = []) => {
  const parsedParameters = {};

  Object.entries(parameters).forEach(([key, value]) => {
    if (blacklisted.includes(key)) return;

    if (key === 'issue_state' && isObject(value)) {
      Object.entries(value).forEach(([stateGroup, stateValue]) => {
        parsedParameters[`issue_state[${stateGroup}]`] =
          stateValue?.split?.(',') ?? [];
      });
    } else if (Array.isArray(value)) {
      const formattedKey = key.replace('_params', '');
      parsedParameters[formattedKey] = value.map((item) => item.id);
    } else if (!isObject(value)) {
      parsedParameters[key] = value;
    }
  });

  return parsedParameters;
};

export const getMinimumParamsDifference = (currentParams, defaultParams) => {
  const diff = {};

  for (const key in defaultParams) {
    if (!(key in currentParams)) {
      diff[key] = defaultParams[key];
    }
  }

  return diff;
};
