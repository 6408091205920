import styled from 'styled-components/macro';
import { purple500, pdfBlue } from '../../../../../../../config/colors';

export const Card = styled.div`
  font-size: 12px;
  overflow: hidden;
  padding: 3px 6px 0;
  background-color: ${({ isLoggedIn }) => (isLoggedIn ? pdfBlue : purple500)};
  border-radius: 5px;
  height: 20px;
  color: #fff;
  position: absolute;
  z-index: 99;
  top: 0;
  width: ${({ width }) => (width ? `${width}px` : '0')};
  left: ${({ left }) => (left ? `${left}px` : '0')};
  top: 5px;
`;
