import React, { useState, useEffect, forwardRef, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { usePopper } from 'react-popper';

import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';
import { ThemedIcon } from '../../helpers/theme';
import { MenuWithSearch, MenuItem, SubMenuItem } from '../Menu';
import { NoItems, LoaderWrapper, MenuWrapper } from './styles';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';
import InfiniteScroll from '../InfiniteScroll';
import { useDebounce } from '../../hooks/useDebounce';
import { purple500, darkerGray, yellow, gray } from '../../config/colors';
import { limitNumberOfCharacters } from '../../utils/core';
import { WowIcon, WowTooltipWrapper } from '../WowBasicElements';
import Tooltip from '../Tooltip';

import useCachedOptions from '../../hooks/useCachedOptions';

const DropdownMultipleWithSearch = forwardRef(
  (
    {
      options,
      isLoading,
      size,
      handleLoadMore,
      hasMore,
      handleSearch,
      handleChange,
      selected = [],
      messageKey,
      paramKey,
      handleClear,
      handleGet,
      label,
      dropdownToggleButtonTestId,
      optionsTestId,
      customButton,
      menuWidth,
      menuHeight,
      menuPosition = 'right',
      removeSearch,
      onMenuToggle = () => {},
      // cache
      withCache,
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [search, setSearch] = useState('');
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    });

    const cachedOptions = useCachedOptions({
      options,
      skip: !withCache,
    });

    const { ref: refUseVisible, isVisible, setIsVisible } = useVisible(false);

    useKeyPress('Escape', () => setIsVisible(false));

    const handleDropdownToggle = () => {
      setIsVisible(!isVisible);
      onMenuToggle(!isVisible);
    };

    const debouncedSearch = useDebounce(search, 1000);

    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    };

    useEffect(() => {
      handleSearch(debouncedSearch);
    }, [debouncedSearch, handleSearch]);

    const getSelectedLabel = () => {
      let selectedWs;
      const selectedId = isNaN(Number(selected[0]))
        ? selected[0]
        : Number(selected[0]);

      const _options = [
        ...options,
        ...(withCache ? Object.values(cachedOptions) : []),
      ];
      for (const ws of _options) {
        selectedWs =
          Number(ws.id) === Number(selectedId)
            ? ws
            : ws?.children?.find(
                (wsChild) => Number(wsChild.id) === Number(selectedId)
              );
        if (selectedWs) break;
      }
      return selectedWs?.label;
    };

    useEffect(() => {
      if (isVisible && handleGet) handleGet();
    }, [isVisible, handleGet]);

    const customButtons = React.isValidElement(customButton)
      ? React.cloneElement(customButton, { handleClick: handleDropdownToggle })
      : null;

    return (
      <Wrapper ref={refUseVisible} className="dropdown-search-wrapper">
        {customButtons ?? (
          <ButtonWrapper>
            <Button
              onClick={handleDropdownToggle}
              ref={setReferenceElement}
              data-testid={dropdownToggleButtonTestId}
            >
              <FormattedMessage id={messageKey} />
              {selected.length > 0 ? (
                <Selected>
                  :{' '}
                  {label ? (
                    <>{limitNumberOfCharacters(label, 20)}</>
                  ) : (
                    limitNumberOfCharacters(getSelectedLabel(), 20)
                  )}{' '}
                  {selected.length > 1 ? `+ ${selected.length - 1}` : null}
                </Selected>
              ) : null}
              {selected && selected.length === 0 && (
                <ThemedIcon mainAccent className="icon icon-arrowdown-02" />
              )}
            </Button>

            {selected && selected.length > 0 && (
              <ThemedIcon
                className="icon icon-close"
                onClick={handleClear}
                mainAccent
              />
            )}
          </ButtonWrapper>
        )}

        {isVisible ? (
          <MenuWrapper
            width={menuWidth}
            position={menuPosition}
            className="dropdown-menu-wrapper"
            removeSearch={removeSearch}
          >
            <MenuWithSearch
              ref={setPopperElement}
              style={{
                ...styles.popper,
                ...(menuPosition === 'right' ? { left: 'auto', right: 0 } : {}),
              }}
              {...attributes.popper}
              value={search}
              onChange={handleSearchChange}
              size={size || menuWidth}
              removeSearch={removeSearch}
            >
              {options.length === 0 ? (
                isLoading ? (
                  <LoaderWrapper>
                    <LoadingSpinner />
                  </LoaderWrapper>
                ) : (
                  <NoItems>
                    <FormattedMessage id="no_items" />
                  </NoItems>
                )
              ) : (
                <InfiniteScroll
                  dataLength={options.length}
                  handleLoadMore={handleLoadMore}
                  hasMore={hasMore}
                  height={menuHeight}
                >
                  {options.map((option) => {
                    return (
                      <Fragment
                        key={
                          option.parent_id && option.children
                            ? `${option.value}_lifted_child`
                            : option.value
                        }
                      >
                        <MenuItem
                          onClick={() => handleChange(option, paramKey)}
                          selected={
                            selected.includes(String(option.value)) ||
                            selected.includes(Number(option.value))
                          }
                          tabIndex={0}
                          data-testid={optionsTestId}
                          mainAccent
                        >
                          {option?.is_favorite && (
                            <Tooltip
                              tooltipContent={
                                <WowTooltipWrapper width="150">
                                  <FormattedMessage id="favorite_workspace" />
                                </WowTooltipWrapper>
                              }
                            >
                              <WowIcon
                                className="icon-star"
                                size="20"
                                color={yellow}
                                spaceRight="5"
                                spaceLeft="0"
                              />
                            </Tooltip>
                          )}
                          {option.image ? (
                            <OptionPhoto src={option.image} />
                          ) : null}
                          <LabelWrapper>{option.label}</LabelWrapper>
                          {selected.includes(String(option.value)) ||
                          selected.includes(Number(option.value)) ? (
                            <i className="icon icon-check" />
                          ) : null}
                        </MenuItem>
                        {option.children?.length > 0 ? (
                          <>
                            {option.children.map((child) => {
                              return (
                                <SubMenuItem
                                  key={child.value}
                                  onClick={() => handleChange(child, paramKey)}
                                  selected={
                                    selected &&
                                    selected.find(
                                      (item) => Number(item) === child.value
                                    )
                                  }
                                  tabIndex={0}
                                  mainAccent
                                >
                                  {child?.is_favorite && (
                                    <Tooltip
                                      tooltipContent={
                                        <WowTooltipWrapper width="150">
                                          <FormattedMessage id="favorite_workspace" />
                                        </WowTooltipWrapper>
                                      }
                                    >
                                      <WowIcon
                                        className="icon-star"
                                        size="20"
                                        color={yellow}
                                        spaceRight="5"
                                        spaceLeft="0"
                                      />
                                    </Tooltip>
                                  )}
                                  <LabelWrapper>{child.name}</LabelWrapper>
                                  {selected &&
                                  selected.find(
                                    (item) => Number(item) === child.value
                                  ) ? (
                                    <i className="icon icon-check" />
                                  ) : null}
                                </SubMenuItem>
                              );
                            })}
                          </>
                        ) : null}
                      </Fragment>
                    );
                  })}
                </InfiniteScroll>
              )}
            </MenuWithSearch>
          </MenuWrapper>
        ) : null}
      </Wrapper>
    );
  }
);

export default DropdownMultipleWithSearch;

const Wrapper = styled.div`
  margin-left: 26px;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  i.icon-close {
    color: ${gray};
    margin-top: 2px;

    &:hover {
      cursor: pointer;
      color: ${purple500};
    }
  }
`;

const Button = styled.button`
  padding: 6px 14px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  color: ${darkerGray};
  position: relative;

  &:hover,
  &:focus {
    outline: none;
    background-color: #e6edf4;
  }
`;

const LabelWrapper = styled.div`
  width: 100%;
`;

const OptionPhoto = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Selected = styled.span`
  color: ${purple500};
`;
