import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import useFilters from './useFilters';
import { useMemo } from 'react';
import { updatePersistedFilters } from '../../../redux/config/actions';

const useFiltersReset = (entity, initQueryParams) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { latestSavedFilter } = useFilters({ entity, skip: true });

  const shouldShowResetFilters = useMemo(() => {
    if (!latestSavedFilter || !search) return false;

    const mutatedSearchParams = { ...qs.parse(search, { parseNumbers: true }) };

    //NOTE we need to exclude params that are not actual filter eg. page, paginage_by, sort_by, sort_direction
    for (const key of Object.keys(initQueryParams)) {
      if (key in mutatedSearchParams) delete mutatedSearchParams[key];
    }

    return Object.keys(mutatedSearchParams).length > 0;
  }, [search, latestSavedFilter, initQueryParams]);

  const resetFilters = () => {
    const preservedInitParamsValues = {};
    const searchParams = qs.parse(search, { parseNumbers: true });

    for (const key of Object.keys(initQueryParams)) {
      preservedInitParamsValues[key] =
        searchParams[key] || initQueryParams[key];
    }

    dispatch(updatePersistedFilters(preservedInitParamsValues, entity));
  };

  return {
    shouldShowResetFilters,
    resetFilters,
  };
};

export default useFiltersReset;
