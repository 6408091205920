import React from 'react';
import { Wrapper, Icon } from './styles';

export const Notice = (props) => {
  return <Wrapper {...props} />;
};

export const NoticeWithIcon = (props) => {
  return (
    <Notice variant={props.variant} {...props}>
      <Icon className={`icon ${props.icon}`} color={props.color} />
      {props.children}
    </Notice>
  );
};

export default Notice;
