import { useDispatch } from 'react-redux';
import { useUpdateStepsStatusMutation } from '../../../service';
import { updateOnboardingProgress } from '../../../../../../../redux/config/actions';
import { setShowOnboardingProcess } from '../../../../slice';

const useOnboardingBanner = () => {
  const dispatch = useDispatch();
  const [updateStepsStatus, { isLoading }] = useUpdateStepsStatusMutation();

  const handleDismiss = async () => {
    if (isLoading) return;
    const response = await updateStepsStatus({ isDismissed: true });
    const { data } = response ?? {};
    if (data) dispatch(updateOnboardingProgress(data));
  };

  const handleShowOnboarding = () => dispatch(setShowOnboardingProcess(true));

  return {
    handleDismiss,
    isLoading,
    handleShowOnboarding,
  };
};

export default useOnboardingBanner;
