import styled from 'styled-components/macro';

import {
  filtersAdvancedGray,
  darkGray,
  hardWhite,
  borderLighterGray,
} from '../../../../../config/colors';

export const ListWrapper = styled.div`
  max-height: 300px;
  overflow-x: scroll;
  padding: 0 5px;
`;

export const ListItem = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const ListItemText = styled.div`
  font-size: 16px;
  position: relative;
  bottom: 1px;
  color: ${darkGray};
`;

export const PillImage = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 9999px;
  object-fit: cover;
  min-width: 20px;
  margin-right: 15px;
`;

export const IconBox = styled.div`
  border-radius: 9999px;
  object-fit: cover;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${borderLighterGray};
  background-color: ${hardWhite};
  padding: 5px;
  & i {
    color: ${filtersAdvancedGray};
    font-size: 20px;
  }
`;
