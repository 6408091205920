import { FormattedMessage } from 'react-intl';
import Space from '../../../../../components_new/Space';
import Tooltip from '../../../../../components_new/Tooltip';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
  WowTooltipWrapper,
} from '../../../../../components_new/WowBasicElements';
import { limitNumberOfCharacters } from '../../../IssuesV2/Issue/components/AddEditWrapper/components/IssueTabs/Notes/utils';
import { darkerGray, darkGray, purple500 } from '../../../../../config/colors';
import { internalRoutePaths } from '../../../../../config/config';
import { CardLinkWrapper } from '../../styles';
import { openInNewTab } from '../../../../../utils/core';

const CardWorkspace = (props) => {
  const { id, name, statistics } = props;

  const handleGoToWorkspace = () => {
    const url = internalRoutePaths.WORKSPACES_ID.replace(':id', id);
    openInNewTab(url);
  };

  return (
    <WowDisplayFlex direction="column" align="flex-start">
      <Tooltip
        key={id}
        tooltipContent={
          name?.length < 45 ? (
            ''
          ) : (
            <WowTooltipWrapper width="250">{name}</WowTooltipWrapper>
          )
        }
      >
        <WowColorizeText color={darkGray} size="16" fWeight="bold">
          {name ? limitNumberOfCharacters(name, 45) : ''}
        </WowColorizeText>
      </Tooltip>
      <Space height="5" />
      <WowDisplayFlex>
        <WowIcon className="icon icon-task" size="14" />
        <WowColorizeText color={darkerGray} size="14">
          {statistics?.completed ?? 0} / {statistics?.total ?? 0}
        </WowColorizeText>
      </WowDisplayFlex>
      <CardLinkWrapper onClick={handleGoToWorkspace}>
        <FormattedMessage id="details" />
        <WowIcon
          className="icon icon-arrowupleft"
          size="15"
          color={purple500}
        />
      </CardLinkWrapper>
    </WowDisplayFlex>
  );
};

export default CardWorkspace;
