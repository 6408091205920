import styled from 'styled-components/macro';
import { newBorder, pdfBlue } from '../../../../../../../config/colors';

export const HoursBorder = styled.div`
  height: ${({ height }) => height + 'px' ?? 'auto'};
  border-top: 1px solid ${newBorder};
  width: 100%;
  ${({ currentHourMarkerTopValue }) =>
    currentHourMarkerTopValue
      ? `
    &::before {
      position: absolute;
      content: "";
      width: 0; 
      height: 0; 
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid ${pdfBlue};
      top: ${currentHourMarkerTopValue - 7}px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      border-top: 1px dashed ${pdfBlue};
      top: ${currentHourMarkerTopValue}px;
      z-index: 5;
    }
  `
      : ''};
`;
