import { randomPasswordString } from '../../../utils/utility';

export const modalViews = {
  default: 'default',
  users: 'users',
  workspaces: 'workspaces',
  invite: 'invite',
  usersAdded: 'usersAdded',
  archived: 'archived',
  activated: 'activated',
  noRecordsExport: 'noRecordsExport',
};

export const INIT_PARAMS = {
  page: '1',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
};

export const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'name',
    label: 'name',
  },
];

export const SUPPORTED_PARAMS = {
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  filter_status: {
    defaultValue: '',
    allowedValues: null,
  },
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  workspace_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  job_positions: {
    defaultValue: null,
    allowedValues: null,
  },
  sort_by: {
    defaultValue: 'name',
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
};

export const createUserDefaultValues = {
  email: '',
  first_name: '',
  last_name: '',
  password: randomPasswordString(),
};

export const CUSTOMER_SUPPORT_CONFIG = {
  entity: 'users',
  videoId: 'sRH_Rb7Nec0',
};
