import { internalRoutePaths } from '../../../../config/config';

export const API_TAG_TYPES_CHECKLIST_TEMPLATES = [
  'ChecklistTemplates',
  'ChecklistTemplate',
];

export const EMPTY_SCREEN_CHECKLISTS = {
  icon: 'icon-dock',
  keys: {
    title: 'no_checklists',
    text: 'no_checklists_text',
  },
};

export const INIT_QUERY_PARAMS = {
  page: '1',
  paginate_by: '20',
  sort_by: 'updated',
  sort_direction: 'desc',
};

export const SUPPORTED_PARAMS = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  page: {
    defaultValue: '1',
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  categories: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'name',
    label: 'name',
  },
];

export const CHECKLISTS_ROUTE_CONFIG = {
  pathname: internalRoutePaths.TEMPLATES_CHECKLISTS,
  search: '?page=1&paginate_by=20&sort_by=name&sort_direction=asc',
};

export const PERSIST_FILTERS_KEY = 'checklist-template';
