import styled from 'styled-components/macro';

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;

  /* This should probably be a blog post 
  but following code is basically replacing flex gap */
  > * {
    margin-left: 8px;
  }

  > *:first-child {
    margin-left: 0px;
  }

  @media (max-width: 1500px) {
    > * {
      margin-left: 16px;
    }
  }

  @media (max-width: 1620px) {
    > * {
      margin-left: 10px;
    }
  }

  @media (max-width: 1350px) {
    > * {
      margin-left: 4px;
    }
  }

  .status-dropdown-menu {
    width: 200px;
  }
`;

export default HeaderSection;
