import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import { formatArrayFromQueryParam } from '../utils';
import {
  updateFilters,
  removeFromFilters,
  updateMultipleFilters,
} from '../slice';
import qs from 'query-string';
import { isEqual } from 'lodash';
import {
  formatMultiselectIdsWithChildren,
  formatMultiselectItemsWithChildren,
  setFiltersPersist,
} from '../../../../utils/filters';
import { openInNewTab } from '../../../../utils/core';
import { INIT_QUERY as INIT_QUERY_EQUIPMENT_LISTING } from '../constants';

const useFilters = (INIT_QUERY, SUPPORTED_PARAMS, defaultQuery) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleFilterPersist = (values) =>
    setFiltersPersist('equipmentListing', values);

  const { queryParams, updateQueryParams } = useSearchQuery(
    INIT_QUERY,
    SUPPORTED_PARAMS
  );

  const handlePageChange = (value) => {
    updateQueryParams({
      ...queryParams,
      page: value,
    });
  };

  const handlePaginateByChange = (value) => {
    updateQueryParams({
      ...queryParams,
      paginate_by: value,
      page: 1,
    });
  };

  const handleSearchChange = useCallback(
    (value) => {
      updateQueryParams({
        ...queryParams,
        search: value,
        page: 1,
      });
    },
    [queryParams, updateQueryParams]
  );

  const handleFilterChange = (target, filter) => (value) => {
    const query = updateQueryParams({
      ...queryParams,
      [filter]: value,
      page: 1,
    });

    dispatch(
      updateFilters({
        query,
        target,
        key: filter,
        items: value,
      })
    );
  };

  const handleSortByChange = (value, target) => {
    const key = 'sort_by';

    const query = updateQueryParams({
      ...queryParams,
      [key]: value,
    });

    dispatch(
      updateFilters({
        query,
        target,
        key,
        items: value,
      })
    );
  };

  const handleUpdateMultipleFilters = (filters, target) => {
    const query = updateQueryParams({
      ...queryParams,
      ...filters,
    });

    dispatch(
      updateMultipleFilters({
        query,
        target,
        filters,
      })
    );
  };

  const handleOrderChange = (target) => {
    const newOrder = queryParams.sort_direction === 'asc' ? 'desc' : 'asc';
    const key = 'sort_direction';
    const query = updateQueryParams({
      ...queryParams,
      [key]: newOrder,
    });

    dispatch(
      updateFilters({
        query,
        target,
        key,
        items: newOrder,
      })
    );
  };

  const handleFilterArrayChange = (
    option,
    target,
    key,
    selectedFilters,
    skipRemove,
    shouldIncludeSubWs
  ) => {
    const formatedQueryParams = formatArrayFromQueryParam(queryParams, key);

    const isSelected = formatedQueryParams.includes(String(option.id));

    let query;
    let parseSelectedItems = [];

    const newIds = formatMultiselectIdsWithChildren(
      formatedQueryParams,
      option,
      shouldIncludeSubWs
    );
    const newItems = formatMultiselectItemsWithChildren(
      selectedFilters[key],
      option,
      shouldIncludeSubWs
    );

    if (isSelected) {
      //we can manually skip remove. useful when pointing to filters from another page.
      parseSelectedItems = !skipRemove ? [...newItems] : selectedFilters[key];

      query = updateQueryParams({
        ...queryParams,
        [key]: !skipRemove ? newIds : formatedQueryParams,
        page: 1,
        include_sub_ws: shouldIncludeSubWs,
      });
    } else {
      query = updateQueryParams({
        ...queryParams,
        [key]: newIds,
        page: 1,
        include_sub_ws: shouldIncludeSubWs,
      });

      parseSelectedItems = [...newItems];
    }

    //save to redux
    const newFilters = {
      [key]: parseSelectedItems,
      include_sub_ws: shouldIncludeSubWs,
    };

    dispatch(
      updateMultipleFilters({
        query,
        target,
        filters: newFilters,
      })
    );
  };

  const clearFilters = useCallback(
    (passedQuery, target, values = {}) => {
      const query = updateQueryParams({
        ...passedQuery,
      });

      dispatch(
        removeFromFilters({
          query,
          target,
          values,
        })
      );
    },
    [updateQueryParams, dispatch]
  );

  const toggleCheckbox = (isChecked, target, workspaceIds) => {
    const newFilters = {
      include_sub_ws: isChecked,
      ...(workspaceIds ? { workspace_ids: workspaceIds } : {}),
    };

    const query = updateQueryParams({
      ...queryParams,
      ...newFilters,
    });

    dispatch(
      updateMultipleFilters({
        query,
        target,
        filters: newFilters,
      })
    );
  };

  //TO DO - refactor
  const navigateToRouteWithFilter = (
    item,
    target,
    key,
    selectedFilters,
    passedQueryParams,
    manualQueryParams,
    targetBlank
  ) => {
    const query = {
      ...INIT_QUERY_EQUIPMENT_LISTING,
      [key]: item.id,
      isAdvancedFilterOpen: 1,
      density: passedQueryParams.density,
      ...(manualQueryParams || {}),
    };

    const formatedFilterData = [
      {
        id: item.id,
        label: item.name,
        value: item.id,
        image: item.profile_image,
        name: item.name,
      },
    ];

    dispatch(
      updateMultipleFilters({
        query,
        target,
        filters: {
          group_ids: [],
          type_ids: [],
          manager_ids: [],
          workspace_ids: [],
          holder_ids: [],
          [key]: formatedFilterData,
        },
      })
    );

    const stringified = qs.stringify(query);

    //TO DO - refactor as par of filter persis
    const PATHNAME = '/dashboard/equipment';
    if (targetBlank) {
      handleFilterPersist({
        [key]: formatedFilterData,
      });
      const formatedUrl = `${PATHNAME}?${stringified}`;
      openInNewTab(formatedUrl);
    } else {
      history.push({
        pathname: PATHNAME,
        search: stringified,
      });
    }
  };

  const handleUiElementChange = (target, key, value) => {
    const query = updateQueryParams({
      ...queryParams,
      [key]: value,
    });

    dispatch(
      updateFilters({
        query,
        target,
        key,
        items: value,
      })
    );
  };

  const filtersChanged = useMemo(() => {
    return !isEqual(defaultQuery, queryParams);
  }, [defaultQuery, queryParams]);

  return {
    queryParams,
    handleSearchChange,
    handleFilterChange,
    handleSortByChange,
    handleOrderChange,
    handleFilterArrayChange,
    clearFilters,
    toggleCheckbox,
    handlePageChange,
    handlePaginateByChange,
    navigateToRouteWithFilter,
    handleUiElementChange,
    handleUpdateMultipleFilters,
    filtersChanged,
    includeSubWs: queryParams?.include_sub_ws === 'true',
  };
};

export default useFilters;
