import { issuesApi } from '../../../../../../service';

export const checklistsApi = issuesApi.injectEndpoints({
  endpoints: (builder) => ({
    getChecklists: builder.query({
      query: (issueId) => {
        const url = `issue/${issueId}/checklists`;

        return url;
      },
      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'IssueChecklists', id })),
              { type: 'IssueChecklists', id: 'LIST' },
            ]
          : [{ type: 'IssueChecklists', id: 'LIST' }];
      },
    }),
    createChecklist: builder.mutation({
      query(payload) {
        const { id, ...restPayload } = payload;
        return {
          url: `issue/${id}/checklists`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: ({ issue_id }) => [{ type: 'Issue', id: issue_id }],
    }),
    updateChecklist: builder.mutation({
      query(payload) {
        const { id, checklistId, ...restPayload } = payload;
        return {
          url: `issue/${id}/checklists/${checklistId}`,
          method: 'PUT',
          body: restPayload,
        };
      },
      invalidatesTags: ({ issue_id }) => [{ type: 'Issue', id: issue_id }],
    }),
    deleteChecklist: builder.mutation({
      query(payload) {
        const { id, checklistId } = payload;
        return {
          url: `issue/${id}/checklists/${checklistId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ({ issue_id }) => [{ type: 'Issue', id: issue_id }],
    }),
    saveChecklistToTemplate: builder.mutation({
      query(checklistId) {
        return {
          url: `checklist-templates/save-checklist-as-template/${checklistId}`,
          method: 'POST',
        };
      },
      invalidatesTags: ({ issue_id }) => [{ type: 'Issue', id: issue_id }],
    }),
    assignChecklistFromTemplate: builder.mutation({
      query(payload) {
        const { id, checklistTemplateId } = payload;
        return {
          url: `issue/${id}/assign-checklist-from-template/${checklistTemplateId}`,
          method: 'POST',
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Issue', id }],
    }),
    createChecklistItem: builder.mutation({
      query(payload) {
        const { id, checklistId, ...restPayload } = payload;
        return {
          url: `issue/${id}/checklist/${checklistId}/items`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: (result, error, payload) => {
        if (payload.attachments?.length > 0) return [];
        return [{ type: 'Issue', id: result.issue_id ?? payload?.id }];
      },
    }),
    updateChecklistItem: builder.mutation({
      query(payload) {
        const {
          id,
          checklistId,
          checklistItemId,
          title,
          description,
          status_id,
        } = payload;
        return {
          url: `issue/${id}/checklist/${checklistId}/items/${checklistItemId}`,
          method: 'PUT',
          body: {
            title,
            description,
            ...(status_id ? { status_id } : {}),
          },
        };
      },
      invalidatesTags: (result, error, payload) => {
        if (payload.attachments?.length > 0) return [];
        return [{ type: 'Issue', id: result.issue_id }];
      },
    }),
    deleteChecklistItem: builder.mutation({
      query(payload) {
        const { id, checklistId, checklistItemId, ...restPayload } = payload;
        return {
          url: `issue/${id}/checklist/${checklistId}/items/${checklistItemId}`,
          method: 'DELETE',
          body: restPayload,
        };
      },
      invalidatesTags: ({ issue_id }) => [{ type: 'Issue', id: issue_id }],
    }),
    convertChecklistItem: builder.mutation({
      query(payload) {
        const { checklistItemId, ...restPayload } = payload;
        return {
          url: `convert-checklist-item-to-issue-or-subtask/${checklistItemId}`,
          method: 'POST',
          body: restPayload,
        };
      },
      invalidatesTags: ({ issue_id }) => [{ type: 'Issue', id: issue_id }],
    }),
    uploadItemAttachments: builder.mutation({
      query(payload) {
        const { id, checklistId, checklistItemId, formData } = payload;

        return {
          url: `issue/${id}/checklist/${checklistId}/items/${checklistItemId}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Issue', id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetChecklistsQuery,
  useCreateChecklistMutation,
  useDeleteChecklistMutation,
  useUpdateChecklistMutation,
  useSaveChecklistToTemplateMutation,
  useAssignChecklistFromTemplateMutation,
  useCreateChecklistItemMutation,
  useUpdateChecklistItemMutation,
  useDeleteChecklistItemMutation,
  useConvertChecklistItemMutation,
  useUploadItemAttachmentsMutation,
} = checklistsApi;
