import { createSelector } from '@reduxjs/toolkit';

const getListFiltersState = (state) => state.listFilters;

export const getEntity = createSelector(
  getListFiltersState,
  (state) => state.entity
);

export const getElementsInSecondRow = createSelector(
  getListFiltersState,
  (state) => state.elementsInSecondRow
);

export const getShowMore = createSelector(
  getListFiltersState,
  (state) => state.showMore
);

export const getIsSaveModalOpen = createSelector(
  getListFiltersState,
  (state) => state.isSaveModalOpen
);

export const getIsPreviewModalOpen = createSelector(
  getListFiltersState,
  (state) => state.isPreviewModalOpen
);

export const getQuickFilters = (type) =>
  createSelector(getListFiltersState, (state) => state.quickFilters[type]);

export const getLatestSavedFilter = (entity) =>
  createSelector(
    getListFiltersState,
    (state) => state.latestSavedFilter[entity]
  );

export const getAssetsSearch = createSelector(
  getListFiltersState,
  (state) => state.assetsSearch
);

export const getPreviewFilter = createSelector(
  getListFiltersState,
  (state) => state.previewFilter
);

export const getIsPreviewFilterDirty = createSelector(
  getListFiltersState,
  (state) => state.isPreviewFilterDirty
);

export const getIsShowMoreVisible = createSelector(
  getListFiltersState,
  (state) => state.isShowMoreVisible
);
