export const actionStrings = {
  TOGGLE_POPUP: 'TOGGLE_POPUP',
  TOGGLE_GALLERY_POPUP: 'TOGGLE_GALLERY_POPUP',
  TOGGLE_QR_POPUP: 'TOGGLE_QR_POPUP',
  TOGGLE_ARE_YOU_SURE_POPUP: 'TOGGLE_ARE_YOU_SURE_POPUP',
  TOGGLE_EXPORT_TO_PDF_POPUP: 'TOGGLE_EXPORT_TO_PDF_POPUP',
  TOGGLE_VISIBILITY_POPUP: 'TOGGLE_VISIBILITY_POPUP',
  CLOSE_VISIBILITY_POPUP: 'CLOSE_VISIBILITY_POPUP',
  SET_FORCE_SHOW_SURVEY_MODAL: 'SET_FORCE_SHOW_SURVEY_MODAL',
};

export const actions = {
  togglePopup: () => ({
    type: actionStrings.TOGGLE_POPUP,
  }),
  toggleGalleryPopup: () => ({
    type: actionStrings.TOGGLE_GALLERY_POPUP,
  }),
  toggleQrPopup: () => ({
    type: actionStrings.TOGGLE_QR_POPUP,
  }),
  toggleAreYouSurePopup: () => ({
    type: actionStrings.TOGGLE_ARE_YOU_SURE_POPUP,
  }),
  toggleExportToPdfPopup: () => ({
    type: actionStrings.TOGGLE_EXPORT_TO_PDF_POPUP,
  }),
  toggleVisibilityPopup: () => ({
    type: actionStrings.TOGGLE_VISIBILITY_POPUP,
  }),
  closeVisibilityPopup: () => ({
    type: actionStrings.CLOSE_VISIBILITY_POPUP,
  }),
  setForceSurveyShowModal: (payload) => ({
    type: actionStrings.SET_FORCE_SHOW_SURVEY_MODAL,
    payload,
  }),
};
