import { useState, useEffect } from 'react';
import useWindowSizeDebounced from './../../../../../hooks/useWindowSizeDebounced';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsAdvancedFilterOpen as getIsScheduleFilterOpen } from '../../../scheduling/selectors';
import { getAdvancedFiltersState as getIsIssuesFilterOpen } from '../../../../../redux/filter/selectors';

const useSidebarOpen = () => {
  const { search } = useLocation();
  const [isOpen, setIsOpen] = useState(true);

  const isAdvancedFilterOpen = new URLSearchParams(search).get(
    'isAdvancedFilterOpen'
  );

  //temprary until both schedules and issues start using isAdvancedFilterOpen url parameter
  const isScheduleFilterOpen = useSelector(getIsScheduleFilterOpen);
  const isIssueFilterOpen = useSelector(getIsIssuesFilterOpen);
  const { width } = useWindowSizeDebounced();

  const toggleSidebar = () => setIsOpen(!isOpen);

  const isSmallScreen = width < 1368;

  useEffect(() => {
    if (isSmallScreen) {
      return setIsOpen(false);
    } else {
      return setIsOpen(true);
    }
  }, [isSmallScreen, setIsOpen]);

  useEffect(() => {
    if (!isSmallScreen) return;

    if (
      !!Number(isAdvancedFilterOpen) ||
      isScheduleFilterOpen ||
      isIssueFilterOpen
    ) {
      return setIsOpen(false);
    }
  }, [
    setIsOpen,
    isSmallScreen,
    isAdvancedFilterOpen,
    isScheduleFilterOpen,
    isIssueFilterOpen,
  ]);

  return { isOpen, toggleSidebar };
};

export default useSidebarOpen;
