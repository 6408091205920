import { useSelector } from 'react-redux';
import { filtersSelector } from '../selectors';
import { setFilters } from '../slice';

const useFilters = () => {
  const filters = useSelector(filtersSelector());
  return {
    filters,
    setFilters,
  };
};

export default useFilters;
