import * as React from 'react';
import { LoadingSpinner } from '../loading-spinner/loading-spinner.component';
import { GalleryLoaderPlaceholder } from './gallery.styles';

export const GalleryLoader = (props) => {
  if (!props.galleryLoading) {
    return null;
  }

  return (
    <GalleryLoaderPlaceholder>
      <LoadingSpinner />
    </GalleryLoaderPlaceholder>
  );
};
