import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getClientState } from '../../users/selectors';
import { useRequestUserLocationMutation } from '../../users/service';

const useRequestUserLocation = () => {
  const { id } = useParams();
  const { settings } = useSelector(getClientState);
  const { request_user_location: requestUserLocation } = settings ?? {};

  const [reguestUserLocation, { isLoading: isLoadingRequestUserLocation }] =
    useRequestUserLocationMutation();

  const handleRequestUserLocation = async () => {
    if (!id) return;
    await reguestUserLocation(id);
  };

  return {
    isLoadingRequestUserLocation,
    handleRequestUserLocation,
    requestUserLocation,
  };
};

export default useRequestUserLocation;
