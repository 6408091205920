import { actions } from './actions';
import {
  loginUser,
  requestPasswordChange as requestPasswordChangeRequest,
  resetPassword,
} from './../../services/AuthService';
import { setDefaultValues as calendarSetSliceDefaultValues } from '../../views/internal/Calendar/slice';
import { saveData, localStorageKey, removeData } from '../utils';
import { shouldRememberUser } from './selectors';

export const login = (email, password) => (dispatch) => {
  dispatch(actions.loginStart());

  return loginUser(email, password)
    .then((response) => {
      dispatch(actions.saveLoginData(response));
      saveData(response, localStorageKey);

      return response;
    })
    .catch((error) => {
      dispatch(actions.loginError(error));

      throw error;
    });
};

export const logout = () => (dispatch, getState) => {
  dispatch(actions.logout());

  dispatch(calendarSetSliceDefaultValues());

  const rememberMe = shouldRememberUser(getState());

  if (!rememberMe) {
    removeData(localStorageKey);
  }

  return true;
};

export const requestPasswordChange = (email) => (dispatch) => {
  dispatch(actions.passwordChangeStart());

  return requestPasswordChangeRequest(email)
    .then((response) => {
      dispatch(actions.passwordChangeToken(response));

      return response;
    })
    .catch((error) => {
      return false;
    });
};

export const changePassword = (token, email, password) => (dispatch) => {
  dispatch(actions.passwordResetStart());

  return resetPassword(token, email, password)
    .then((response) => {
      dispatch(actions.passwordResetSuccess(response));

      return response;
    })
    .catch((error) => {
      dispatch(actions.passwordResetError(error));

      return false;
    });
};

export const setAuthErrorMessage = (error) => (dispatch) =>
  dispatch(actions.loginError(error));
