import React from 'react';
import PropTypes from 'prop-types';

import './checkmark-button.component.scss';

export const CheckMarkButton = (props) => (
  <div
    className="checkmark-button"
    onClick={props.onClick}
    data-testid="submitButton"
  >
    <i className="icon icon-check icon-check-flex" />
  </div>
);

CheckMarkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
