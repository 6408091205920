import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { STEP_TYPES } from '../../../constants';
import useOnboarding from '../../../hooks/useOnboarding';
import { getUser } from '../../../../../../../redux/user/selectors';
import { FORM_DEFAULT_VALUES } from '../constants';

const useWelcome = ({ formMethods } = {}) => {
  const { handleUpdateSteps, isLoading } = useOnboarding();
  const { reset } = formMethods;
  const loggedInUser = useSelector(getUser);

  const handleContinue = (data) => {
    if (isLoading) return;
    handleUpdateSteps({
      stepType: STEP_TYPES.welcome,
      ...data,
    });
  };

  //prefill company name based on email
  useEffect(() => {
    if (loggedInUser) {
      const itemsSplitByAt = loggedInUser.email?.split('@') ?? [];
      const company = itemsSplitByAt[1].split('.')[0];
      reset({ ...FORM_DEFAULT_VALUES, company });
    }
  }, [loggedInUser, reset]);

  return {
    handleContinue,
    isLoading,
  };
};

export default useWelcome;
