import styled from 'styled-components/macro';
import { darkerGray, iconGray } from '../../../../../../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.i`
  position: relative;
  margin-top: 15px;
  width: 100%;
  text-align: center;
  font-size: 80px;
  color: ${iconGray};
`;

export const ErrorHeader = styled.div`
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
`;

export const ErrorMessage = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: ${darkerGray};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const SecondaryButton = styled.span`
  color: ${({ color }) => (color ? color : '#000')};
  cursor: pointer;
  font-size: 0.8em;
  text-align: center;
  margin-top: -10px;
`;
