import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 100%;
  word-break: break-all;
`;

export const CardWrapper = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '200px')};
  cursor: pointer;
  color: #fff;
  font-size: 12px;
`;

export const CardPadding = styled.div`
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? `${paddingLeft}px` : '0'};
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : '0'};
  position: absolute;
  left: ${({ left, showLeftValueInPercentage }) =>
    left ? `${left}${showLeftValueInPercentage ? '%' : 'px'}` : '0px'};
  top: ${({ top }) => (top ? `${top}px` : '0px')};
  width: ${({ width, showWidthValueInPercentage }) =>
    width ? `${width}${showWidthValueInPercentage ? '%' : 'px'}` : '100%'};
  &:hover {
    z-index: 100;
  }
`;
