import { useEffect } from 'react';
import { ESC_KEY } from '../config/config';

export const useCloseOnEsc = (handler, shouldRegister) => {
  useEffect(() => {
    if (!shouldRegister) {
      return;
    }
    const onKeyDown = (e) => {
      if (e && e.keyCode === ESC_KEY) {
        handler();
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRegister]);
};
