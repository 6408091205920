import { createSelector } from '@reduxjs/toolkit';

const getIssuesState = (state) => state.issues;

export const selectListViewValue = (target) =>
  createSelector(getIssuesState, (state) => state.listView[target]);

export const getSelectedItems = (target) =>
  createSelector(getIssuesState, (state) => state[target].selectedItems);

export const filtersSelector = () =>
  createSelector(getIssuesState, (state) => state.filters);
