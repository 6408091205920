import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from '../render-if/render-if.component';
import './image-carousel.component.scss';
import { JPEG } from '../upload-area/upload-helper';
import styled from 'styled-components/macro';
import { gray, white } from '../../config/colors';
import { themeColour } from '../../helpers/theme';

import { getFileIcon } from '../../components_new/MediaInput/util';
import { WowIcon } from '../../components_new/WowBasicElements';

const defaultImage = {
  // eslint-disable-next-line max-len
  url: 'https://cidco-smartcity.niua.org/wp-content/uploads/2017/08/No-image-found.jpg',
  type: JPEG,
  alt: 'thumbnail',
};
const initState = {
  selected: 0,
};
const MAX_NUMBER_OF_IMAGES_IN_CAROUSEL = 6;
const DocumentPreview = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${gray};
`;

const ActiveImageNav = styled.div`
  background: ${(props) => (props.isActive ? themeColour(props) : white)};
`;

export const ThumbnailItem = ({ image, onImageClick }) => {
  const icon = getFileIcon(image.type);
  return (
    <RenderIf
      if={icon.name === 'icon-photo'}
      then={() => (
        <img src={image.url} alt={image.alt} onClick={onImageClick} />
      )}
      else={() => (
        <DocumentPreview
          onClick={() => onImageClick({ target: { src: image.url } })}
        >
          {!!icon.name && (
            <WowIcon
              color={white}
              className={icon.name}
              size="36"
              spaceRight="0"
              spaceLeft="0"
            />
          )}
        </DocumentPreview>
      )}
    />
  );
};
export default class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = initState;
  }

  selectImage = (index) => {
    const len = this.props.images ? this.props.images.length - 1 : 0;
    let selected = index;

    if (index > len) {
      selected = 0;
    }

    if (index < 0) {
      selected = len;
    }

    this.setState({
      selected,
    });
  };

  selectImageNav = (index) => () => this.setState({ selected: index });

  render() {
    const image =
      this.props.images && this.props.images[this.state.selected]
        ? this.props.images[this.state.selected]
        : defaultImage;

    return (
      <div className="img-carousel" data-test="img-carousel">
        <ThumbnailItem image={image} onImageClick={this.props.onImageClick} />

        {this.props.images && this.props.images.length > 1 ? (
          <React.Fragment>
            <div className="img-carousel__nav">
              {this.props.images
                .slice(0, MAX_NUMBER_OF_IMAGES_IN_CAROUSEL)
                .map((image, index) => (
                  <ActiveImageNav
                    secondaryAccent
                    isActive={this.state.selected === index}
                    key={`image-${index}`}
                    onClick={this.selectImageNav(index)}
                    className="nav-unit"
                  />
                ))}
            </div>
            <div className="img-carousel__arrow left-arrow">
              <i
                className={'icon icon-arrowright-02'}
                onClick={() => this.selectImage(this.state.selected - 1)}
              />
            </div>
            <div className="img-carousel__arrow right-arrow">
              <i
                className={'icon icon-arrowright-02'}
                onClick={() => this.selectImage(this.state.selected + 1)}
              />
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

ImageCarousel.propTypes = {
  images: PropTypes.array,
  onImageClick: PropTypes.func,
};
