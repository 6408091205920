import styled from 'styled-components/macro';

export const OuterWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
  bottom: 30px;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  z-index: 100;
`;
