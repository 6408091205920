import * as React from 'react';
import styled from 'styled-components/macro';
import { darkerGray, outline, purple500, white } from '../../config/colors';
import { DENSITY } from '../../config/config';

const DensityButton = styled.button`
  width: 32px;
  height: 32px;
  margin-left: 4px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  color: ${({ active }) => (active ? purple500 : darkerGray)};
  /* padding: 6px 8px; */
  /* padding: 4px; */

  &:focus,
  &:hover,
  &:focus-within {
    outline: none;
    background-color: ${white};
  }
`;

const DensityWrapper = styled.div`
  padding: 2px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  color: ${darkerGray};
  display: flex;
  align-items: center;
  ${DensityButton}:first-child {
    margin-left: 0px;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: ${outline};
  }
`;

const Density = ({ value, onChange }) => {
  const handleDensitySelect = (value) => () => {
    onChange(value);
  };

  return (
    <DensityWrapper>
      <DensityButton
        css={`
          font-size: 18px;
          line-height: 20px;
        `}
        active={value === DENSITY.comfortable}
        onClick={handleDensitySelect(DENSITY.comfortable)}
      >
        <i className="icon icon-view-block" />
      </DensityButton>
      <DensityButton
        css={`
          font-size: 20px;
          line-height: 20px;
        `}
        active={value === DENSITY.compact}
        onClick={handleDensitySelect(DENSITY.compact)}
      >
        <i className="icon icon-view-list-density" />
      </DensityButton>
    </DensityWrapper>
  );
};

export default Density;
