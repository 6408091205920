import styled from 'styled-components/macro';
import { userGray } from '../../../../config/colors';

export const InfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;

  > div:last-child {
    border-bottom: 0;
  }
`;

export const UserAssignmentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  .menu-wrapper {
    > div {
      margin-left: 0;
    }
  }
`;

export const UserAssignmentTitle = styled.h2`
  color: ${userGray};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 28px;
`;

export const TooltipWrapper = styled.span`
  width: max-content;
`;
