import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getSelectedItems } from '../../redux/multiselect/selectors';
import { updateSelectedItems } from '../../redux/multiselect/slice';
import { arrayValueToObject } from '../../utils/utility';
import { MULTISELECT_FEATURES } from './constants';

const useMultiselect = (items, featureKey) => {
  const { pathname } = useLocation();

  const featureName = useMemo(() => {
    return featureKey ?? MULTISELECT_FEATURES[pathname];
  }, [pathname, featureKey]);

  const dispatch = useDispatch();
  const selectedItems = useSelector(getSelectedItems(featureName));

  const selectedItemsCount = Object.keys(selectedItems).length;
  const hasSelectedItems = selectedItemsCount !== 0;

  const selectItem = useCallback(
    (item) => {
      let newSelectedItems = { ...selectedItems };

      if (selectedItems[item.id] === undefined) {
        newSelectedItems[item.id] = item;
      } else {
        delete newSelectedItems[item.id];
      }

      dispatch(
        updateSelectedItems({ target: featureName, items: newSelectedItems })
      );
    },
    [dispatch, selectedItems, featureName]
  );

  const selectAllItems = () =>
    dispatch(
      updateSelectedItems({
        target: featureName,
        items: arrayValueToObject(items),
      })
    );
  const deselectAllItems = useCallback(() => {
    dispatch(updateSelectedItems({ target: featureName, items: {} }));
  }, [dispatch, featureName]);

  const toggleSelectAllItems = () => {
    if (selectedItemsCount && selectedItemsCount === items.length) {
      deselectAllItems();
      return;
    }

    selectAllItems();
  };

  const preselectItems = useCallback(
    (items) =>
      dispatch(
        updateSelectedItems({
          target: featureName,
          items: arrayValueToObject(items),
        })
      ),
    [dispatch, featureName]
  );

  return {
    selectedItems,
    selectedItemsCount,
    selectItem,
    hasSelectedItems,
    selectAllItems,
    deselectAllItems,
    toggleSelectAllItems,
    preselectItems,
  };
};

export default useMultiselect;
