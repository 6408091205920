export const getDefaultUnscheduledModalConfirmData = () => ({
  isOpen: false,
  handleAction: () => {},
  intlValues: {},
  titleKey: ' ',
});

export const getDefaultUnscheduledModalData = () => ({
  isOpen: false,
  intlValues: {},
});
