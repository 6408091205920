import { getToken } from '../auth/selectors';
import { config } from '../../services/config';
import {
  checkForUnauthorisedAccess,
  handleUnauthorisedAccess,
} from './../../services/helper';
import { REQUEST_METHODS } from '../../config/apiConfig';

export const authHeader = (authToken) => ({
  Authorization: `Bearer ${authToken}`,
});
const toJSON = (resp) => resp.json();

const bodylessReqs = [REQUEST_METHODS.GET, REQUEST_METHODS.HEAD];
export const jsonRequest = (method, requestUrl, body = null) => async (
  dispatch,
  getState
) => {
  const token = getToken(getState());
  const baseUrl = `${config.URL}/api/${config.API_VERSION}`;
  const url = `${baseUrl}${requestUrl}`;
  const base =
    body && !bodylessReqs.includes(method)
      ? { body: JSON.stringify(body) }
      : {};

  return fetch(url, {
    ...base,
    method,
    headers: {
      'Content-Type': config.CONTENT_TYPES.APPLICATION_JSON,
      ...authHeader(token),
    },
  })
    .then(checkForUnauthorisedAccess)
    .then(toJSON)
    .catch(handleUnauthorisedAccess);
};
