import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getEntity } from '../../../../../selectors';
import { setIsSaveModalOpen, setLatestSavedFilter } from '../../../../../slice';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSaveFilterMutation } from '../../../../../service';
import useFilters from '../../../../../hooks/useFilters';
import { FILTER_COMPONENTS } from '../contants';
import Space from '../../../../../../Space';
import {
  FILTER_LABEL_KEYS,
  FILTER_LABEL_PREFIX_KEYS,
} from '../../../constants';

const useSave = ({ isOpen }) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);

  const handleClose = useCallback(
    () => dispatch(setIsSaveModalOpen(false)),
    [dispatch]
  );
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [saveFilters, { isLoading }] = useSaveFilterMutation();

  const handleSave = async () => {
    if (isLoading) return;
    const queryString = search.slice(1);
    const payload = {
      filter_type: entity,
      name,
      description,
      query_string: queryString,
    };
    const response = await saveFilters(payload);
    if (response?.data) {
      dispatch(setLatestSavedFilter({ queryString }));
      handleClose();
    }
  };

  const { persistedFilters } = useFilters({
    entity,
    skip: true,
  });

  const renderFilter = useCallback((filter, index) => {
    const { key, ...restProps } = filter;
    const FilterComponent = FILTER_COMPONENTS[key];
    return FilterComponent ? (
      <Fragment key={key}>
        {index > 0 && <Space height="20" />}
        <FilterComponent index={index} {...restProps} />
      </Fragment>
    ) : null;
  }, []);

  const filters = useMemo(() => {
    const parameters = persistedFilters?.parameters ?? {};
    const filters = [];

    for (const key of Object.keys(parameters)) {
      const formattedKey = key.replace('_params', '');

      if (key in FILTER_COMPONENTS) {
        filters.push({
          key,
          value: parameters[key],
          filterKey: formattedKey,
          labelKey: FILTER_LABEL_KEYS[key],
          labelPrefixKey: FILTER_LABEL_PREFIX_KEYS[key] ?? null,
        });
      } else if (key === 'date_events_from') {
        //NOTE - this is due to BE having different keys for date range
        const _filterKey = 'date';
        filters.push({
          key: _filterKey,
          value: {
            from: parameters[key],
            to: parameters['date_events_to'],
            types: parameters['date_events_types'],
          },
          filterKey: _filterKey,
          labelKey: FILTER_LABEL_KEYS[_filterKey],
        });
      }
    }
    return filters;
  }, [persistedFilters?.parameters]);

  useEffect(() => {
    if (!filters.length && isOpen) {
      handleClose();
    }
  }, [filters, handleClose, isOpen]);

  return {
    handleClose,
    name,
    setName,
    filters,
    renderFilter,
    handleSave,
    isLoading,
    description,
    setDescription,
  };
};

export default useSave;
