import { internalRoutePaths } from '../../config/config';

export const MULTISELECT_FEATURES = {
  [internalRoutePaths.WORKSPACES]: 'workspaces',
  [internalRoutePaths.ISSUES]: 'issues',
  [internalRoutePaths.SCHEDULING]: 'scheduling',
  [internalRoutePaths.EQUIPMENT]: 'equipment',
  [internalRoutePaths.EQUIPMENT_GROUPS]: 'equipmentGroups',
  [internalRoutePaths.EQUIPMENT_EXTERNAL_HOLDERS]: 'equipmentExternalHolders',
  [internalRoutePaths.ASSETS]: 'assets',
  [internalRoutePaths.ASSETS_GROUPS]: 'assetsGroups',
  [internalRoutePaths.ASSETS_SYSTEMS]: 'assetsSystems',
  [internalRoutePaths.USERS]: 'users',
  [internalRoutePaths.TEMPLATES_CHECKLISTS]: 'checklists',
};
