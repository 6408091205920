const INIT_QUERY = {
  search: '',
  page: '1',
  paginate_by: '20',
  status: 'all',
  sort_by: 'name',
  sort_direction: 'asc',
};

const SUPPORTED_PARAMS = {
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  status: {
    defaultValue: 'all',
    allowedValues: ['all', 'active', 'archived'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  manager_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspace_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  reserved_for_user_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

const FORM_DEFAULT_VALUES = {
  name: '',
  description: '',
  manager_ids: [],
  bind_group_to_workspace: false,
  workspace_id: null,
};

export const TARGET_PAGE = 'groups';

export { INIT_QUERY, SUPPORTED_PARAMS, FORM_DEFAULT_VALUES };
