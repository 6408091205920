import React from 'react';
import styled from 'styled-components/macro';
import { hardWhite } from '../../config/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${hardWhite};
  z-index: ${({ zIndex }) => zIndex ?? 150};
  padding: 0 30px;
  ${({ column }) => (column ? 'flex-direction: column;' : null)};
`;

export const ListStickyHeaderSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListStickyHeaderOuterSectionWrapper = styled.div`
  width: 100%;
`;

const StickyHeader = ({ children, zIndex }) => {
  return (
    <Wrapper data-testid="stickyHeader" id="sticky-header" zIndex={zIndex}>
      {children}
    </Wrapper>
  );
};

export default StickyHeader;
