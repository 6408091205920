import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { RTK_HOOK_CONFIG } from '../../../../config/constants';
import {
  useGetCalendarWeeklyQuery,
  useGetCalendarWeeklyUsersQuery,
  useGetCalendarWeeklyWorkspacesQuery,
} from '../service';
import { setDefaultHiddenData } from '../slice';
import { getActiveView } from '../selectors';
import { CALENDAR_VIEW_TYPES } from '../constants';

const useWeeklyCalendar = ({
  customRTKConfig,
  queryParams,
  weeklyBodyAdditionalParams = {},
  persistedFilters,
  skipGetCalendarWeeklyData,
  skipGetCalendarWeeklyUsers,
  currentPage,
  skipGetCalendarWeeklyWorkspaces,
} = {}) => {
  const dispatch = useDispatch();
  const activeView = useSelector(getActiveView);

  const calendarWeeklyQueryParams = useMemo(() => {
    const params = JSON.parse(JSON.stringify(queryParams));
    delete params.calendar_search;

    return params;
  }, [queryParams]);

  const {
    data: calendarWeeklyFetchData,
    isFetching: isFetchingCalendarWeekly,
    isUninitialized: isUninitializedCalendarWeekly,
    refetch: refetchCalendarWeeklyFetchData,
  } = useGetCalendarWeeklyQuery(
    { ...calendarWeeklyQueryParams, ...weeklyBodyAdditionalParams },
    customRTKConfig || {
      ...RTK_HOOK_CONFIG,
      skip: !persistedFilters || skipGetCalendarWeeklyData,
    }
  );

  const [calendarWeeklyData, setCalendarWeeklyData] = useState([]);
  const handleSetCalendarWeeklyData = (data = [], hardReset = false) => {
    setCalendarWeeklyData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };

  useEffect(() => {
    if (calendarWeeklyFetchData?.length) {
      handleSetCalendarWeeklyData(calendarWeeklyFetchData, true);
    }
  }, [calendarWeeklyFetchData, isFetchingCalendarWeekly]);

  const [calendarWeeklyUsersData, setCalendarWeeklyUsersData] = useState([]);
  const handleSetCalendarWeeklyUsersData = (data = [], hardReset = false) => {
    setCalendarWeeklyUsersData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };
  useEffect(() => {
    if (activeView === CALENDAR_VIEW_TYPES.weeklyUsers) {
      dispatch(setDefaultHiddenData(calendarWeeklyUsersData));
    }
  }, [calendarWeeklyUsersData, dispatch, activeView]);
  const {
    data: calendarWeeklyUsersFetchData,
    isFetching: isFetchingCalendarWeeklyUsers,
    isUninitialized: isUninitializedCalendarWeeklyUsers,
  } = useGetCalendarWeeklyUsersQuery(
    { ...queryParams, page: currentPage ?? 1, paginate_by: 25 },
    customRTKConfig || {
      ...RTK_HOOK_CONFIG,
      skip: !persistedFilters || skipGetCalendarWeeklyUsers,
    }
  );
  const calendarWeeklyUsersLastPage = calendarWeeklyUsersFetchData?.last_page;
  const calendarWeeklyUsersCurrentPage =
    calendarWeeklyUsersFetchData?.current_page;
  useEffect(() => {
    let hardReset = false;
    if (calendarWeeklyUsersCurrentPage === 1) hardReset = true;
    handleSetCalendarWeeklyUsersData(
      calendarWeeklyUsersFetchData?.data,
      hardReset
    );
  }, [calendarWeeklyUsersCurrentPage, calendarWeeklyUsersFetchData?.data]);

  const [calendarWeeklyWorkspacesData, setCalendarWeeklyWorkspacesData] =
    useState([]);
  const handleSetCalendarWeeklyWorkspacesData = (
    data = [],
    hardReset = false
  ) => {
    setCalendarWeeklyWorkspacesData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };
  useEffect(() => {
    if (activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) {
      dispatch(setDefaultHiddenData(calendarWeeklyWorkspacesData));
    }
  }, [calendarWeeklyWorkspacesData, dispatch, activeView]);
  const {
    data: calendarWeeklyWorkspacesFetchData,
    isFetching: isFetchingCalendarWeeklyWorkspaces,
    isUninitialized: isUninitializedCalendarWeeklyWorkspaces,
  } = useGetCalendarWeeklyWorkspacesQuery(
    { ...queryParams, page: currentPage ?? 1, paginate_by: 25 },
    customRTKConfig || {
      ...RTK_HOOK_CONFIG,
      skip: !persistedFilters || skipGetCalendarWeeklyWorkspaces,
    }
  );
  const calendarWeeklyWorkspacesLastPage =
    calendarWeeklyWorkspacesFetchData?.last_page;
  const calendarWeeklyWorkspacesCurrentPage =
    calendarWeeklyWorkspacesFetchData?.current_page;
  useEffect(() => {
    let hardReset = false;
    if (calendarWeeklyWorkspacesCurrentPage === 1) hardReset = true;
    handleSetCalendarWeeklyWorkspacesData(
      calendarWeeklyWorkspacesFetchData?.data,
      hardReset
    );
  }, [
    calendarWeeklyWorkspacesCurrentPage,
    calendarWeeklyWorkspacesFetchData?.data,
  ]);

  const handleDeleteWeeklyDataItem = useCallback((params = {}) => {
    const { id } = params;
    const mutateCalendarWeeklyDataCallback = (prevState) => {
      const mutatedState = JSON.parse(JSON.stringify([...prevState]));
      for (let i = 0; i < mutatedState.length; i++) {
        const del = (day) => {
          day = day.filter((item) => !item.id || item.id !== id);
          if (day?.box && day?.issues?.length) {
            for (let k = 0; k < day.issues.length; k++) {
              day.issues = day.issues.filter((item) => item.id !== id);
            }
          }
          return day;
        };
        const dayItem = mutatedState[i];
        if (dayItem?.standard_issues?.length)
          dayItem.standard_issues = del(dayItem.standard_issues);
        if (dayItem?.full_day?.length)
          dayItem.full_day = del(dayItem?.full_day);
      }
      return mutatedState;
    };
    setCalendarWeeklyData(mutateCalendarWeeklyDataCallback);
  }, []);

  const handleUpdateWeeklyDataItem = useCallback(
    (params = {}) => {
      const { data, additionalParams } = params;

      const start_date = data?.start_date ?? null;
      const due_date = data?.due_date ?? null;
      const id = data?.id;
      let triggerRefetch = false;

      if (!id) return;
      if (
        (start_date === null && due_date === null) ||
        (start_date && due_date && !dayjs(start_date).isSame(due_date, 'day'))
      )
        return handleDeleteWeeklyDataItem({ id });

      const mutatedState = JSON.parse(JSON.stringify([...calendarWeeklyData]));

      let oldData;

      for (
        let dayItemIndex = 0;
        dayItemIndex < (mutatedState?.length ?? 0);
        dayItemIndex++
      ) {
        if (oldData) break;
        for (
          let itemIndex = 0;
          itemIndex <
          (mutatedState[dayItemIndex]?.standard_issues?.length ?? 0);
          itemIndex++
        ) {
          if (oldData) break;
          if (
            mutatedState?.[dayItemIndex]?.standard_issues?.[itemIndex]?.id ===
            id
          ) {
            oldData = mutatedState[dayItemIndex].standard_issues[itemIndex];
            continue;
          }
          if (
            mutatedState?.[dayItemIndex]?.standard_issues?.[itemIndex]?.box &&
            mutatedState?.[dayItemIndex]?.standard_issues?.[itemIndex]?.issues
              ?.length
          ) {
            for (
              let boxIssuesIndex = 0;
              boxIssuesIndex <
              mutatedState[dayItemIndex].standard_issues[itemIndex].length;
              boxIssuesIndex++
            ) {
              if (oldData) break;
              if (
                mutatedState?.[dayItemIndex]?.standard_issues?.[itemIndex]
                  ?.issues?.[boxIssuesIndex] === id
              ) {
                oldData =
                  mutatedState[dayItemIndex].standard_issues[itemIndex].issues[
                    boxIssuesIndex
                  ];
                continue;
              }
            }
          }
        }
      }

      if (
        !additionalParams.weeklyDndUpdateDropCancelTrigger &&
        (oldData?.start_date !== start_date || oldData?.due_date !== due_date)
      ) {
        triggerRefetch = true;
      } else {
        if (
          additionalParams.weeklyDndUpdateDropCancelTrigger &&
          data._changingTimelineDays
        ) {
          triggerRefetch = true;
        } else {
          Object.assign(oldData, data);
        }
      }
      if (triggerRefetch) refetchCalendarWeeklyFetchData();
      else setCalendarWeeklyData(mutatedState);
    },
    [
      handleDeleteWeeklyDataItem,
      refetchCalendarWeeklyFetchData,
      calendarWeeklyData,
    ]
  );

  const handleNewWeeklyItemSetup = useCallback(
    ({ data = {}, additionalParams = {} }) => {
      const {
        id,
        presetData = {},
        presetPosition,
        timelineIndex,
        oldTimelineIndex,
      } = data;

      const mutateCalendarWeeklyDataCallback = (prevState) => {
        const mutatedState = JSON.parse(JSON.stringify([...prevState]));

        if (additionalParams?.dndTimelineUpdate) {
          const setupData = {
            _presetTopPosition: presetPosition,
            _changingTimelineDays: additionalParams.changingTimelineDays,
            start_date: null,
            due_date: null,
          };
          if (!additionalParams.changingTimelineDays) {
            if (mutatedState[timelineIndex]) {
              mutatedState[timelineIndex].standard_issues.forEach(
                (item, itemIndex) => {
                  if (item?.id === id) {
                    mutatedState[timelineIndex].standard_issues[itemIndex] = {
                      ...mutatedState[timelineIndex].standard_issues[itemIndex],
                      ...setupData,
                    };
                  } else {
                    if (item?.box && item?.issues?.length) {
                      item.issues.forEach((issue, issueIndex) => {
                        if (issue?.id === data?.id) {
                          mutatedState[timelineIndex].standard_issues[
                            itemIndex
                          ].issues[issueIndex] = {
                            ...mutatedState[timelineIndex].standard_issues[
                              itemIndex
                            ].issues[issueIndex],
                            ...setupData,
                          };
                        }
                      });
                    }
                  }
                }
              );
            }
          } else {
            let currentTimelineData = {};
            if (mutatedState[oldTimelineIndex]) {
              if (!mutatedState[oldTimelineIndex].standard_issues) {
                if (Array.isArray(mutatedState[oldTimelineIndex]))
                  mutatedState[oldTimelineIndex] = {};
                mutatedState[oldTimelineIndex].standard_issues = [];
              }
              mutatedState[oldTimelineIndex].standard_issues.forEach(
                (item, itemIndex) => {
                  if (item?.id === id) {
                    currentTimelineData =
                      mutatedState[oldTimelineIndex].standard_issues[itemIndex];
                    mutatedState[oldTimelineIndex].standard_issues.splice(
                      itemIndex,
                      1
                    );
                  } else {
                    if (item?.box && item?.issues?.length) {
                      item.issues.forEach((issue, issueIndex) => {
                        if (issue?.id === data?.id) {
                          currentTimelineData =
                            mutatedState[oldTimelineIndex].standard_issues[
                              itemIndex
                            ].issues[issueIndex];
                          mutatedState[oldTimelineIndex].standard_issues[
                            itemIndex
                          ].issues.splice(issueIndex, 1);
                        }
                      });
                    }
                  }
                }
              );
            }
            if (
              mutatedState[timelineIndex] &&
              !mutatedState[timelineIndex].standard_issues
            ) {
              if (Array.isArray(mutatedState[timelineIndex]))
                mutatedState[timelineIndex] = {};
              mutatedState[timelineIndex].standard_issues = [];
            }
            mutatedState[timelineIndex].standard_issues.push({
              ...currentTimelineData,
              ...setupData,
            });
          }
        } else {
          const preparedData = {
            ...presetData,
            id,
            _setup: true,
          };
          preparedData._presetTopPosition = presetPosition;
          if (
            mutatedState[timelineIndex] &&
            !mutatedState[timelineIndex].standard_issues
          ) {
            if (Array.isArray(mutatedState[timelineIndex]))
              mutatedState[timelineIndex] = {};
            mutatedState[timelineIndex].standard_issues = [];
          }
          mutatedState[timelineIndex].standard_issues.unshift(preparedData);
        }

        return mutatedState;
      };
      setCalendarWeeklyData(mutateCalendarWeeklyDataCallback);
    },
    []
  );

  return {
    calendarWeeklyData,
    isFetchingCalendarWeekly,
    isUninitializedCalendarWeekly,
    setCalendarWeeklyData,
    handleDeleteWeeklyDataItem,
    handleUpdateWeeklyDataItem,
    handleNewWeeklyItemSetup,
    calendarWeeklyUsersLastPage,
    calendarWeeklyUsersCurrentPage,
    calendarWeeklyWorkspacesLastPage,
    calendarWeeklyWorkspacesCurrentPage,
    calendarWeeklyUsersFetchData,
    isFetchingCalendarWeeklyUsers,
    isUninitializedCalendarWeeklyUsers,
    calendarWeeklyWorkspacesFetchData,
    isFetchingCalendarWeeklyWorkspaces,
    isUninitializedCalendarWeeklyWorkspaces,
    calendarWeeklyUsersData,
    calendarWeeklyWorkspacesData,
  };
};

export default useWeeklyCalendar;
