import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import Tooltip from '../../../../../components_new/Tooltip';
import {
  WowDisplayFlex,
  WowIcon,
  WowTextBold,
  WowTooltipWrapper,
} from '../../../../../components_new/WowBasicElements';
import { white } from '../../../../../config/colors';
import showDirections from '../../../../../utils/showDirections';
import {
  TimelineHeading,
  TimelineTextWrapperStyled,
  TimelineWrapper,
} from '../styles';
import Space from './../../../../../components_new/Space/index';

const TimelineItem = ({ item, isLastItem }) => {
  if (!item.linkable_text) return null;

  const { parameters } = item.linkable_text;
  const locationWrong = parameters.filter((item) => item.name === 'location');
  const isLocationWrong = locationWrong.length === 1;
  const gpsDisabled = parameters.some(
    (param) => param.value === 'gps_disabled'
  );

  //fix this when we get tabs
  const options = [
    'equipment_item_name',
    'issue_title',
    'schedule_title',
    'item_name',
  ];
  const title = parameters.find((item) => options.includes(item.name));
  const isLink = isLocationWrong && !gpsDisabled;

  const handleOpenLink = () => {
    if (!isLink) return;

    showDirections(
      locationWrong[0].value.origin_location,
      locationWrong[0].value.scan_location
    );
  };

  return (
    <TimelineWrapper isLastItem={isLastItem} onClick={handleOpenLink}>
      <TimelineHeading>{title?.value}</TimelineHeading>

      <TimelineTextWrapperStyled
        shouldBeMarked={isLocationWrong || gpsDisabled}
        size={14}
        isLink={isLink}
      >
        <WowDisplayFlex align="center" justify="space-between">
          <WowDisplayFlex style={{ flexShrink: 1 }}>{item.text}</WowDisplayFlex>

          <WowDisplayFlex style={{ flexShrink: 0 }}>
            <WowTextBold fWeight={700}>
              {dayjs(item.created_at).format('DD/MM/YYYY')}
            </WowTextBold>
            <Space width={5} />
            {dayjs(item.created_at).format('HH:mm')}
            {isLocationWrong && !gpsDisabled ? (
              <WowIcon
                className="icon-directions"
                color={white}
                size="15"
                spaceRight="15"
                spaceLeft="15"
                display="flex"
              />
            ) : null}

            {gpsDisabled ? (
              <Tooltip
                tooltipContent={
                  <WowTooltipWrapper width="150">
                    <FormattedMessage id="Hover_scan_without_gps_allowance" />
                  </WowTooltipWrapper>
                }
              >
                <WowIcon
                  className="icon-important-round"
                  color={white}
                  size="18"
                  spaceRight="15"
                  spaceLeft="15"
                  display="flex"
                />
              </Tooltip>
            ) : null}
          </WowDisplayFlex>
        </WowDisplayFlex>
      </TimelineTextWrapperStyled>
    </TimelineWrapper>
  );
};

export default TimelineItem;
