import styled from 'styled-components/macro';
import {
  darkerGray,
  gray,
  hardWhite,
  newBorder,
  newRed,
} from '../../config/colors';

const StyledInput = styled.input`
  font-size: 16px;

  outline: none;
  border: 1px solid ${({ error }) => (error ? newRed : newBorder)};
  ${({ noBorder }) => (noBorder ? `border: 0px;` : undefined)};
  color: ${darkerGray};

  border-radius: 4px;
  width: ${({ width }) => width || '100%'};
  padding: 8px 12px 6px 12px;

  /* solves top shadow/line issue with iOS */
  background-clip: padding-box;

  ${({ enableFocus }) =>
    enableFocus
      ? ` :focus {
    background-color: ${hardWhite};
  }`
      : null}

  ::placeholder {
    color: ${gray};
  }

  &:disabled {
    background-color: white;
  }
`;

export { StyledInput };
