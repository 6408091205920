import Space from '../../Space';
import {
  InfoWrapper,
  AvatarBox,
  AvatarContainer,
  CenterAlignedTextBox,
  BoldedCenterAlignedTextBox,
  SecondaryButton,
} from './styles';
import { ButtonWrappers } from '../styles';
import Avatar from '../../Avatar/Avatar';
import { purple } from '../../../config/colors';
import Button from '../../../components_new/Button';
import CeoAvatar from './assets/ceo-avatar.png';
import { FormattedMessage } from 'react-intl';

export const Start = (props) => {
  const { enterButtonAction = () => {}, remindButtonAction = () => {} } = props;

  return (
    <>
      <Space height="10" />
      <AvatarBox>
        <AvatarContainer>
          <Avatar imageUrl={CeoAvatar} />
        </AvatarContainer>
      </AvatarBox>
      <Space height="20" />
      <InfoWrapper>
        <CenterAlignedTextBox>
          <FormattedMessage id={'survey_introduction'} />
        </CenterAlignedTextBox>
      </InfoWrapper>
      <Space height="10" />
      <InfoWrapper>
        <BoldedCenterAlignedTextBox>
          Drazen Ivanis, Founder
        </BoldedCenterAlignedTextBox>
      </InfoWrapper>
      <Space height="30" />
      <ButtonWrappers>
        <Button
          color="green"
          appearance="contained"
          size="small"
          style={{ width: 350 }}
          onClick={enterButtonAction}
        >
          <FormattedMessage id={'take_survey'} />
        </Button>
      </ButtonWrappers>
      <ButtonWrappers>
        <SecondaryButton color={purple} onClick={remindButtonAction}>
          <FormattedMessage id={'remind_me_later'} />
        </SecondaryButton>
      </ButtonWrappers>
    </>
  );
};

export default Start;
