import React, { useEffect, useState, forwardRef, Fragment } from 'react';
import { usePopper } from 'react-popper';

import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';

import { MenuItem, MenuWithSearch } from '../Menu';
import {
  Button,
  DropdownIcon,
  ClearIcon,
  Icon,
  StyledDropdown,
  NoItems,
  LoaderWrapper,
  MenuWrapper,
} from './styles';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from '../InfiniteScroll';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';
import { useDebounce } from '../../hooks/useDebounce';
import {
  WowBlock,
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
  WowTooltipWrapper,
} from '../WowBasicElements';
import { darkerGray, gray, newRed } from '../../config/colors';
import Tooltip from '../Tooltip';
import CustomFormattedMessage from '../CustomFormattedMessage';

const DropdownWithSearchAndInfiniteScrollWorkspaceBound = forwardRef(
  (
    {
      icon,
      onChange,
      options,
      value,
      disabled,
      customDropdownViewOptionsFilter,
      clearable,
      handleLoadMore,
      hasMore,
      handleSearch,
      isLoading,
      size,
      maxHeight,
      onModalOpen,
      onModalClose,
      handleGet,
      selectedWorkspaceId,
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
      // strategy: fixed ? 'fixed' : undefined,
    });

    const { ref: refUseVisible, isVisible, setIsVisible } = useVisible(false);

    React.useEffect(() => {
      if (!isVisible && onModalClose) {
        onModalClose();
      }
    }, [isVisible, onModalClose]);

    useEffect(() => {
      if (isVisible && handleGet) handleGet();
    }, [isVisible, handleGet]);

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);

    useKeyPress('Escape', () => {
      setIsVisible(false);
    });

    const handleClick = () => {
      if (disabled) {
        return;
      }

      if (!isVisible && onModalOpen) {
        onModalOpen();
      }

      setIsVisible((value) => !value);
    };

    const handleChange = (value, isNotBoundable) => () => {
      if (isNotBoundable) return;
      setIsVisible(false);

      onChange(value);
      setSearch('');
    };

    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    };

    const handleClear = (e) => {
      e.stopPropagation();

      onChange({ value: null });
      setSelected(null);
    };

    const [selected, setSelected] = useState();

    useEffect(() => {
      const valueInOptions = options.find((option) => option.id === value);

      setSelected(valueInOptions);
    }, [options, value]);

    const dropdownOptions = customDropdownViewOptionsFilter
      ? customDropdownViewOptionsFilter(options)
      : options;

    useEffect(() => {
      handleSearch(debouncedSearch);
    }, [debouncedSearch, handleSearch]);

    useEffect(() => {
      if (isVisible) setSearch('');
    }, [isVisible]);

    useEffect(() => {
      if (
        selectedWorkspaceId &&
        selected?.workspace &&
        selected?.workspace.id !== selectedWorkspaceId
      ) {
        onChange({ value: null });
        setSelected(null);
      }
    }, [selectedWorkspaceId, selected, onChange]);

    return (
      <StyledDropdown ref={refUseVisible} size={size}>
        <Button
          type="button"
          onClick={handleClick}
          ref={setReferenceElement}
          size={size}
        >
          {icon && <Icon className={`icon ${icon}`} />}
          {selected ? selected.label : <FormattedMessage id="select" />}
          {clearable && selected && !disabled ? (
            <ClearIcon onClick={handleClear} size={size} />
          ) : null}

          {!disabled ? <DropdownIcon size={size} /> : null}
        </Button>

        {isVisible ? (
          <MenuWrapper width={200}>
            <MenuWithSearch
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              value={search}
              onChange={handleSearchChange}
              size={size}
            >
              {dropdownOptions.length === 0 ? (
                isLoading ? (
                  <LoaderWrapper>
                    <LoadingSpinner />
                  </LoaderWrapper>
                ) : (
                  <NoItems>
                    <FormattedMessage id="no_items" />
                  </NoItems>
                )
              ) : (
                <InfiniteScroll
                  dataLength={dropdownOptions.length}
                  handleLoadMore={handleLoadMore}
                  hasMore={hasMore}
                  height={maxHeight || -1}
                >
                  {dropdownOptions.map((option) => {
                    const isNotBoundable =
                      option.workspace &&
                      selectedWorkspaceId &&
                      option.workspace.id !== selectedWorkspaceId;
                    const isSelected =
                      selected && option.value === selected.value;
                    return (
                      <Fragment key={option.value}>
                        <MenuItem
                          onClick={handleChange(option, isNotBoundable)}
                          selected={isSelected}
                          tabIndex={0}
                          key={option.value}
                        >
                          <WowDisplayFlex flex="1">
                            <WowBlock>
                              <WowColorizeText
                                color={isNotBoundable ? gray : darkerGray}
                              >
                                {option.label}
                              </WowColorizeText>
                            </WowBlock>
                            {isNotBoundable && !isSelected ? (
                              <Tooltip
                                tooltipContent={
                                  <WowTooltipWrapper width={250}>
                                    <CustomFormattedMessage
                                      id="only_items_from_same_workspace"
                                      values={{
                                        workspaceName: `"${option.workspace.name}"`,
                                      }}
                                    />
                                  </WowTooltipWrapper>
                                }
                              >
                                <WowIcon
                                  className="icon icon-important-round"
                                  size="18"
                                  spaceRight="0"
                                  mRight="-1"
                                  color={newRed}
                                />
                              </Tooltip>
                            ) : null}
                            {isSelected ? (
                              <i className="icon icon-check" />
                            ) : null}
                          </WowDisplayFlex>
                        </MenuItem>
                      </Fragment>
                    );
                  })}
                </InfiniteScroll>
              )}
            </MenuWithSearch>
          </MenuWrapper>
        ) : null}
      </StyledDropdown>
    );
  }
);

export default DropdownWithSearchAndInfiniteScrollWorkspaceBound;
