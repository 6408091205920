import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import {
  CALENDAR_PERSIST_KEYS,
  CALENDAR_VIEW_TYPES,
  INIT_QUERY_PARAMS,
  SUPPORTED_PARAMS,
} from '../constants';
import useWeeklyCalendar from './useWeeklyCalendar';
import useDailyCalendar from './useDailyCalendar';
import { formatArrayFromQueryParam } from '../../IssuesV2/utils';
import { getConfigPersistedFilters } from '../../../../redux/config/selectors';
import { SUPPORTED_PARAMS_ADVANCED_FILTER } from '../../IssuesV2/constants';
import { formatDateForQuery, formatStartDateFromQueryParam } from '../utils';
import {
  handleJobPositionHiddenData,
  setLastDailyDate,
  setTimelineIssueCurrentDragId,
  setTimelineIssueDraggableElementId,
  setUnscheduledIssueCurrentDragId,
} from '../slice';
import { updatePersistedFilters } from '../../../../redux/config/actions';
import useQueryParams from '../../../../hooks/useQueryParams';

const useCalendar = ({
  customRTKConfig,
  calendarView = CALENDAR_VIEW_TYPES.dailyUsers,
  weeklyBodyAdditionalParams = {},
} = {}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const persistedFilters = useSelector(
    (state) => getConfigPersistedFilters(state, CALENDAR_PERSIST_KEYS.CALENDAR),
    isEqual
  );

  const paramsConfig = useMemo(() => {
    const _blacklisted = [
      'include_subtasks',
      'manageable_users',
      'workspace_ids',
    ];

    if (
      calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
      calendarView === CALENDAR_VIEW_TYPES.weeklyUsers
    ) {
      _blacklisted.push('assignees_params');
    }

    if (
      calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces ||
      calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
    ) {
      _blacklisted.push('workspaces_params');
    }

    return {
      init: persistedFilters?.parameters,
      defaultInit: INIT_QUERY_PARAMS,
      supported: SUPPORTED_PARAMS,
      blacklisted: _blacklisted,
    };
  }, [persistedFilters?.parameters, calendarView]);

  const { queryParams } = useQueryParams(paramsConfig);

  //#region count of active filters
  const [activeAdvancedFiltersCount, setActiveAdvancedFiltersCount] =
    useState(0);

  useEffect(() => {
    const count = Object.keys(queryParams).filter((item) =>
      Object.keys(SUPPORTED_PARAMS_ADVANCED_FILTER).includes(item)
    ).length;

    setActiveAdvancedFiltersCount(count);
  }, [queryParams]);
  //#endregion;

  const [skipGetCalendarDailyUsers, setSkipGetCalendarDailyUsers] =
    useState(true);

  const [
    skipGetCalendarDailyWorkspacesData,
    setSkipGetCalendarDailyWorkspacesData,
  ] = useState(true);

  const [skipGetCalendarWeeklyUsers, setSkipGetCalendarWeeklyUsers] =
    useState(true);

  const [skipGetCalendarWeeklyWorkspaces, setSkipGetCalendarWeeklyWorkspaces] =
    useState(true);

  const [skipGetCalendarWeeklyData, setSkipGetCalendarWeeklyData] =
    useState(true);

  const {
    calendarWeeklyData,
    isFetchingCalendarWeekly,
    isUninitializedCalendarWeekly,
    handleDeleteWeeklyDataItem,
    handleUpdateWeeklyDataItem,
    handleNewWeeklyItemSetup,
    calendarWeeklyUsersLastPage,
    calendarWeeklyUsersCurrentPage,
    calendarWeeklyWorkspacesLastPage,
    calendarWeeklyWorkspacesCurrentPage,
    calendarWeeklyUsersFetchData,
    isFetchingCalendarWeeklyUsers,
    isUninitializedCalendarWeeklyUsers,
    calendarWeeklyWorkspacesFetchData,
    isFetchingCalendarWeeklyWorkspaces,
    isUninitializedCalendarWeeklyWorkspaces,
    calendarWeeklyUsersData,
    calendarWeeklyWorkspacesData,
  } = useWeeklyCalendar({
    customRTKConfig,
    queryParams,
    persistedFilters,
    currentPage,
    skipGetCalendarWeeklyWorkspaces,
    skipGetCalendarWeeklyUsers,
    skipGetCalendarWeeklyData,
    weeklyBodyAdditionalParams,
  });

  const {
    calendarDailyUsersData,
    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarData,
    handleDeleteDailyDataItem,
    handleUpdateDailyDataItem,
    handleNewDailyItemSetup,
    calendarDailyUsersCalendarData,
    calendarDailyUsersLastPage,
    isFetchingCalendarDailyUsers,
    isUninitializedCalendarDailyUsers,
    handleMoveToCalendarDailyUsersData,
    calendarDailyWorkspacesLastPage,
    isFetchingCalendarDailyWorkspaces,
    isUninitializedCalendarDailyWorkspaces,
    handleMoveToCalendarDailyWorkspacesData,
    isLoadingFirstTimeCalendarDailyUsers,
    isLoadingFirstTimeCalendarDailyWorkspaces,
  } = useDailyCalendar({
    customRTKConfig,
    currentPage,
    queryParams,
    persistedFilters,
    skipGetCalendarDailyUsers,
    skipGetCalendarDailyWorkspacesData,
  });

  const [allowToSetSkipOnWeeklyData, setAllowToSetSkipOnWeeklyData] =
    useState(false);

  useEffect(() => {
    setCurrentPage(1);
    if (calendarView === CALENDAR_VIEW_TYPES.dailyUsers) {
      setSkipGetCalendarDailyUsers(false);
    } else if (calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
      setSkipGetCalendarDailyWorkspacesData(false);
    } else if (calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) {
      setSkipGetCalendarWeeklyWorkspaces(false);
    } else if (calendarView === CALENDAR_VIEW_TYPES.weeklyUsers) {
      setSkipGetCalendarWeeklyUsers(false);
    }
  }, [calendarView]);

  const _updatePersistedFilters = useCallback(
    (parameters) => {
      dispatch(
        updatePersistedFilters(parameters, CALENDAR_PERSIST_KEYS.CALENDAR)
      );
    },
    [dispatch]
  );

  const updateSameStartDateEndDate = useCallback(
    (date) => {
      const mutatedParameters = {
        ...(persistedFilters?.parameters ?? {}),
        start_date: date,
        end_date: date,
      };
      _updatePersistedFilters(mutatedParameters);
      setCurrentPage(1);
    },
    [_updatePersistedFilters, persistedFilters?.parameters]
  );

  const updateStartDateEndDate = useCallback(
    (start_date, end_date) => {
      const mutatedParameters = {
        ...(persistedFilters?.parameters ?? {}),
        start_date,
        end_date,
      };

      _updatePersistedFilters(mutatedParameters);
    },
    [persistedFilters?.parameters, _updatePersistedFilters]
  );

  const updateStartDate = useCallback(
    (date) => {
      const mutatedParameters = {
        ...(persistedFilters?.parameters ?? {}),
        start_date: date,
      };

      _updatePersistedFilters(mutatedParameters);
    },
    [_updatePersistedFilters, persistedFilters?.parameters]
  );

  useEffect(() => {
    if (
      (calendarView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) &&
      !allowToSetSkipOnWeeklyData
    ) {
      const date = queryParams?.start_date ?? new Date();
      const startWeek = new Date(dayjs(date).startOf('isoWeek'));
      const endWeek = new Date(dayjs(date).startOf('isoWeek'));
      endWeek.setDate(endWeek.getDate() + 7);
      updateStartDateEndDate(
        formatDateForQuery(startWeek),
        formatDateForQuery(endWeek)
      );
      window.setTimeout(() => setAllowToSetSkipOnWeeklyData(true), 0);
    }
  }, [
    queryParams?.start_date,
    setAllowToSetSkipOnWeeklyData,
    allowToSetSkipOnWeeklyData,
    calendarView,
    updateStartDateEndDate,
  ]);

  useEffect(() => {
    if (
      calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
      calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    ) {
      dispatch(setLastDailyDate(queryParams?.start_date));
    }
  }, [calendarView, dispatch, queryParams?.start_date]);

  const updateCalendarSearchValue = useCallback(
    (value) => {
      setCurrentPage(1);
      const mutatedParameters = {
        ...(persistedFilters?.parameters ?? {}),
        calendar_search: value,
      };

      if (!value) delete mutatedParameters.calendar_search;
      _updatePersistedFilters(mutatedParameters);
    },
    [_updatePersistedFilters, persistedFilters?.parameters]
  );

  const loadMore = useCallback(() => {
    const mutatedParameters = {
      ...(persistedFilters?.parameters ?? {}),
    };
    _updatePersistedFilters(mutatedParameters);
    setCurrentPage((prevState) => prevState + 1);
  }, [_updatePersistedFilters, persistedFilters?.parameters]);

  const handleDeleteDataItem = useCallback(
    (id) => {
      if (!id) return;
      dispatch(setUnscheduledIssueCurrentDragId(null));
      dispatch(setTimelineIssueCurrentDragId(null));
      dispatch(setTimelineIssueDraggableElementId(null));
      if (
        calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        handleDeleteDailyDataItem({ calendarView, id });
      } else if (
        calendarView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        handleDeleteWeeklyDataItem({ id });
      }
    },
    [
      calendarView,
      dispatch,
      handleDeleteDailyDataItem,
      handleDeleteWeeklyDataItem,
    ]
  );

  const handleUpdateDataItem = useCallback(
    (data, additionalParams = {}) => {
      if (!data) return;
      dispatch(setUnscheduledIssueCurrentDragId(null));
      dispatch(setTimelineIssueCurrentDragId(null));
      if (
        !data.assignee_id &&
        data.id &&
        calendarView !== CALENDAR_VIEW_TYPES.weeklyUsers &&
        calendarView !== CALENDAR_VIEW_TYPES.weeklyWorkspaces &&
        calendarView !== CALENDAR_VIEW_TYPES.dailyWorkspaces
      )
        return handleDeleteDataItem(data.id);
      if (
        calendarView === CALENDAR_VIEW_TYPES.dailyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        handleUpdateDailyDataItem({ data, additionalParams, calendarView });
      } else if (
        calendarView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        calendarView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        handleUpdateWeeklyDataItem({ data, additionalParams });
      }
    },
    [
      calendarView,
      dispatch,
      handleDeleteDataItem,
      handleUpdateDailyDataItem,
      handleUpdateWeeklyDataItem,
    ]
  );

  const handleNewItemSetup = useCallback(
    (data = {}, additionalParams = {}) => {
      const { id, timelineIndex, timelineId, activeView } = data;
      if (
        !id ||
        ((activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
          activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) &&
          typeof timelineIndex === 'undefined') ||
        ((activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
          activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces) &&
          !timelineId)
      )
        return;
      if (
        activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
        activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
      ) {
        handleNewDailyItemSetup({ data, additionalParams });
      } else if (
        activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
        activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
      ) {
        handleNewWeeklyItemSetup({ data, additionalParams });
      }
    },
    [handleNewDailyItemSetup, handleNewWeeklyItemSetup]
  );

  useEffect(() => {
    const filterByJobPosition = formatArrayFromQueryParam(
      queryParams,
      'filter_by_job_positions'
    );
    if (filterByJobPosition?.length) {
      dispatch(handleJobPositionHiddenData(filterByJobPosition));
    }
  }, [calendarDailyUsersData, calendarWeeklyUsersData, queryParams, dispatch]);

  return {
    queryParams,

    handleUpdateDataItem,
    handleDeleteDataItem,
    handleNewItemSetup,

    calendarDailyUsersData,
    calendarDailyUsersCalendarData,
    calendarDailyUsersLastPage,
    isFetchingCalendarDailyUsers,
    isUninitializedCalendarDailyUsers,
    isLoadingFirstTimeCalendarDailyUsers,
    handleMoveToCalendarDailyUsersData,

    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarData,
    calendarDailyWorkspacesLastPage,
    isFetchingCalendarDailyWorkspaces,
    isUninitializedCalendarDailyWorkspaces,
    isLoadingFirstTimeCalendarDailyWorkspaces,
    handleMoveToCalendarDailyWorkspacesData,

    calendarWeeklyData,
    isFetchingCalendarWeekly,
    isUninitializedCalendarWeekly,
    calendarWeeklyUsersLastPage,
    calendarWeeklyUsersCurrentPage,
    calendarWeeklyWorkspacesLastPage,
    calendarWeeklyWorkspacesCurrentPage,
    calendarWeeklyUsersFetchData,
    isFetchingCalendarWeeklyUsers,
    isUninitializedCalendarWeeklyUsers,
    calendarWeeklyWorkspacesFetchData,
    isFetchingCalendarWeeklyWorkspaces,
    isUninitializedCalendarWeeklyWorkspaces,
    calendarWeeklyUsersData,
    calendarWeeklyWorkspacesData,
    setSkipGetCalendarWeeklyData,
    updateSameStartDateEndDate,
    updateStartDateEndDate,
    updateStartDate,
    updateEndDate: updateStartDate,
    updateCalendarSearchValue,
    loadMore,
    activeAdvancedFiltersCount,
    page: currentPage,
    startDate: formatStartDateFromQueryParam(queryParams?.start_date),
    calendar_search: formatArrayFromQueryParam(queryParams, 'calendar_search'),
  };
};

export default useCalendar;
