import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const { REACT_APP_ENV, REACT_APP_SENTRY_DSN_KEY } = process.env;

const ENABLED_FOR = ['production', 'staging'];

export const initSentry = () => {
  const isEnabledForEnv = ENABLED_FOR.includes(REACT_APP_ENV);

  if (!isEnabledForEnv) return;

  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN_KEY,
    attachStacktrace: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    environment: REACT_APP_ENV,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
  });
};
