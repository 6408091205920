import React, { useState, useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { internalRoutePaths } from '../../../config/config';
import { white, autocompleteGray, purple500 } from '../../../config/colors';
import {
  OptionList,
  AutocompleteOption,
} from '../../../components/select/multiselect/OptionList';
import { useOnClickOutside } from '../../../hooks/useClickOutside';
import { RenderIf } from '../../../components/render-if/render-if.component';
import {
  DOWN_ARROW_KEY,
  UP_ARROW_KEY,
  TAB_KEY,
  RIGHT_ARROW_KEY,
  LEFT_ARROW_KEY,
  ESC_KEY,
  ENTER_KEY,
} from '../../../config/config';
import {
  getCursorPosition,
  getCaretCoordinates,
  getTextBeforeCursor,
  getTextAfterCursor,
} from './input-helpers';
import { lineHeight } from '../../../components/form/text-area/autocomplete-text-area';
import {
  getAutocompleteTemplatesList,
  getLoadingStatusForFilterName,
} from '../../../redux/entities/selectors';
import { fetchEntityFromCacheWithFilter } from '../../../redux/entities/action-creators';
import { entities } from '../../../config/entitiesConfig';
import { AUTOCOMPLETE_LIST } from '../../../redux/filter/filters';
import { secondaryAccent } from '../../../helpers/theme';

const ShowSuggestionsButton = styled.div`
  background-color: ${secondaryAccent ?? purple500};
  border-radius: 8px;
  padding: 2px 7px;
  color: ${white};
  font-size: 14px;
  cursor: pointer;
  width: 30px;
  height: 22px;
  text-align: center;
`;

const AutocompleteTextWrapper = styled.div`
  position: absolute;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: ${(props) => props.width - 2}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top + 2}px;
  left: ${(props) => props.left + 2}px;
  display: ${(props) => (props.show ? 'block' : 'none')};
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow-wrap: break-word;
  box-sizing: content-box;
  letter-spacing: normal;
  word-spacing: normal;

  .before {
    opacity: 0;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .autocomplete {
    color: ${autocompleteGray};
  }
`;

const MIN_CHARS_TO_INVOKE_SEARCH = 3;
const noop = () => {};
const popupOffsetBottom = 5;
let items = [];

export const AutocompleteAddBase = ({
  textRef,
  enableAutocomplete,
  getAutocompleteTemplates,
  templates,
  isSmallView,
  maxLength,
  categoryId,
  triggerTextChange = () => {},
}) => {
  const [top, setTop] = useState(0);
  const [optionsOpened, toggleOptions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState('');
  const [suggestionItem, setSuggestionItem] = useState(null);
  const [isManualPopupOpen, setIsManualPopupOpen] = useState(false);
  const optionListRef = useRef(null);
  const buttonRef = useRef(null);
  const autocompleteTextWrapperRef = useRef(null);
  const intl = useIntl();

  // eslint-disable-next-line
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const selectedIndexRef = useRef(selectedIndex);
  const optionsOpenedRef = useRef(optionsOpened);
  const suggestionItemRef = useRef(suggestionItem);
  const currentWordRef = useRef(currentWord);
  const isManualPopupOpenRef = useRef(isManualPopupOpen);

  items = templates.map((template) => {
    return {
      id: template.id,
      value: template.text,
    };
  });

  useEffect(() => {
    if (enableAutocomplete) {
      textRef.current &&
        textRef.current.addEventListener('keyup', doSuggestion, false);
      textRef.current &&
        textRef.current.addEventListener('keydown', preventNavigation, false);
      textRef.current &&
        textRef.current.addEventListener('scroll', onTextScroll, false);
      document.addEventListener('keydown', closeOptions, false);
    }

    getAutocompleteTemplates(categoryId);

    return function cleanup() {
      if (enableAutocomplete) {
        textRef.current &&
          textRef.current.removeEventListener('keyup', doSuggestion, false);
        textRef.current &&
          textRef.current.removeEventListener(
            'keydown',
            preventNavigation,
            false
          );
        textRef.current &&
          // eslint-disable-next-line react-hooks/exhaustive-deps
          textRef.current.removeEventListener('scroll', onTextScroll, false);
        document.removeEventListener('keydown', closeOptions, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (optionsOpened) {
      setTop(getPopupTop());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsOpened]);

  useOnClickOutside(textRef, () => setSuggestionItemRef(null), true);

  const isSuggestionFound = () => {
    return optionsOpenedRef.current || suggestionItemRef.current;
  };

  const setIndex = (index) => {
    selectedIndexRef.current = index;
    setSelectedIndex(index);
  };

  const setOptionsOpened = (state) => {
    optionsOpenedRef.current = state;
    toggleOptions(state);
  };

  const setSuggestionItemRef = (item) => {
    suggestionItemRef.current = item;
    setSuggestionItem(item);

    if (item) {
      textRef.current.focus();
    }
  };

  const setIsManualPopupOpenRef = (isManual) => {
    isManualPopupOpenRef.current = isManual;
    setIsManualPopupOpen(isManual);
  };

  const setCurrentWordRef = (word) => {
    currentWordRef.current = word;
    setCurrentWord(word);
  };

  const setInlineItem = (suggestionsFound, word, index) => {
    setSuggestionItemRef({
      ...suggestionsFound[index],
      suggestion: suggestionsFound[index].value.slice(word.length),
    });
  };

  const doAutocomplete = (e) =>
    items.length > 0 &&
    e.keyCode !== DOWN_ARROW_KEY &&
    e.keyCode !== UP_ARROW_KEY &&
    e.keyCode !== LEFT_ARROW_KEY &&
    e.keyCode !== RIGHT_ARROW_KEY;

  const doSuggestion = (e) => {
    if (e.keyCode === ESC_KEY) {
      return;
    }

    const text = e.target.value;
    const cursorPosition = e.target.selectionStart;
    let word = getWord(text, cursorPosition);

    // Insert autocomplete text
    if (
      e.keyCode === TAB_KEY ||
      e.keyCode === RIGHT_ARROW_KEY ||
      e.keyCode === ENTER_KEY
    ) {
      if (suggestionItemRef.current != null) {
        // Inserts single inline comment
        onOptionSelect(suggestionItemRef.current);
      }

      word = '';
    }

    const suggestionsFound = findSuggestions(word);

    if (doAutocomplete(e)) {
      setCurrentWordRef(word);

      if (suggestionsFound.length > 1) {
        // Multiple suggestions found. Show list.
        setIndex(0);
        setOptionsOpened(true);
        setInlineItem(suggestionsFound, word, selectedIndex);
      } else if (suggestionsFound.length === 1) {
        // Single suggestion found. Show inline
        setOptionsOpened(false);
        setInlineItem(suggestionsFound, word, 0);
      } else {
        // Clear suggestions
        setOptionsOpened(false);
        setSuggestionItemRef(null);
      }
    }

    if (optionsOpenedRef.current) {
      if (
        e.keyCode === DOWN_ARROW_KEY &&
        suggestionsFound.length > selectedIndexRef.current + 1
      ) {
        const newIndex = selectedIndexRef.current + 1;
        setIndex(newIndex);
        setInlineItem(suggestionsFound, word, newIndex);
      }

      if (e.keyCode === UP_ARROW_KEY && selectedIndexRef.current > 0) {
        const newIndex = selectedIndexRef.current - 1;
        setIndex(newIndex);
        setInlineItem(suggestionsFound, word, newIndex);
      }
    }
  };

  const onTextScroll = (e) => {
    autocompleteTextWrapperRef.current.scroll(
      0,
      autocompleteTextWrapperRef.current.offsetTop
    );
  };

  const preventNavigation = (e) => {
    closeOptions(e);

    if (
      isSuggestionFound() &&
      (e.keyCode === DOWN_ARROW_KEY ||
        e.keyCode === UP_ARROW_KEY ||
        e.keyCode === TAB_KEY ||
        e.keyCode === ENTER_KEY)
    ) {
      e.preventDefault();
    }
  };

  const closeOptions = (e) => {
    if (e.keyCode === ESC_KEY) {
      setOptionsOpened(false);
    }
  };

  const getPopupTop = () => {
    const cursorY = isManualPopupOpenRef.current
      ? buttonRef?.current?.offsetTop
      : getCaretCoordinates(textRef.current).y;

    if (optionListRef && optionListRef.current) {
      const textTopRect = textRef.current.getBoundingClientRect();
      const optionListHeight = optionListRef.current.offsetHeight;

      if (window.innerHeight - textTopRect.bottom < optionListHeight) {
        return cursorY - optionListHeight;
      }
    }

    return cursorY + lineHeight + popupOffsetBottom;
  };

  const getSuggestions = () => {
    const suggestions = findSuggestions(currentWordRef.current);

    return suggestions.length > 0 ? suggestions : items;
  };

  const findSuggestions = (text) => {
    if (text && text.length >= MIN_CHARS_TO_INVOKE_SEARCH) {
      return items.filter((x) =>
        x.value.toLowerCase().includes(text.toLowerCase())
      );
    } else {
      return [];
    }
  };

  const getWord = (text, cursorPosition) => {
    // Search for the word's beginning and end.
    const left = text.slice(0, cursorPosition).search(/\S+$/);
    const right = text.slice(cursorPosition).search(/\s/);

    // The last word in the string is a special case.
    if (right < 0) {
      return text.slice(left);
    }

    // Return the word, using the located bounds to extract it from the string.
    const word = text.slice(left, right + cursorPosition);

    return word;
  };

  const onShowSuggestionsClick = () => {
    const popupOpened = !optionsOpened;
    setIsManualPopupOpenRef(popupOpened);
    setCurrentWordRef('');
    setOptionsOpened(popupOpened);
  };

  const getAutocompletedText = (currentText, autocompleteText) => {
    const cursorPosition = getCursorPosition(textRef.current);
    const newText = [
      currentText.slice(0, cursorPosition - currentWordRef.current.length),
      autocompleteText,
      currentText.slice(cursorPosition),
      ' ',
    ]
      .join('')
      .slice(0, maxLength);

    return newText;
  };

  // According to https://github.com/facebook/react/issues/10135
  // beacause React ignores dispatchEvent
  const setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    triggerTextChange(value);
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value'
    ).set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  };

  const onOptionSelect = (item, isSelected) => {
    // Insert autocomplete text at cursor position
    const autocompleteText = item?.value;
    const currentText = textRef.current.value;
    const newText = getAutocompletedText(currentText, autocompleteText);
    setNativeValue(textRef.current, newText);
    textRef.current.setSelectionRange(newText.length, newText.length);

    // Need to raise change event, otherwise state won't be updated
    const changeEvent = new Event('change', { bubbles: true });
    textRef.current.dispatchEvent(changeEvent);

    // scroll to cursor
    textRef.current.blur();
    textRef.current.focus();

    setIndex(-1);
    setSuggestionItemRef(null);
    setOptionsOpened(false);
    setIsManualPopupOpenRef(false);
  };

  const getAutocompleteInfo = () => {
    return {
      before: textRef.current && getTextBeforeCursor(textRef.current),
      after: textRef.current && getTextAfterCursor(textRef.current),
      autocompleteText: suggestionItem && suggestionItem.suggestion,
    };
  };

  const getTextWrapperTop = () =>
    textRef.current.nodeName === 'INPUT'
      ? textRef.current.offsetTop + 6
      : textRef.current.offsetTop + 1;

  const getTextWrapperLeft = () =>
    textRef.current.nodeName === 'INPUT'
      ? textRef.current.offsetLeft + 6
      : textRef.current.offsetLeft + 1;

  return (
    <div>
      <RenderIf
        if={doAutocomplete}
        then={() => (
          <Fragment>
            <RenderIf
              if={enableAutocomplete}
              then={() => (
                <ShowSuggestionsButton
                  onClick={onShowSuggestionsClick}
                  ref={buttonRef}
                >
                  <i className="icon icon-load-autocomplete-text" />
                </ShowSuggestionsButton>
              )}
            />
            <AutocompleteTextWrapper
              ref={autocompleteTextWrapperRef}
              width={textRef.current && textRef.current.offsetWidth}
              height={textRef.current && textRef.current.offsetHeight}
              top={textRef.current && getTextWrapperTop()}
              left={textRef.current && getTextWrapperLeft()}
              show={suggestionItemRef.current !== null}
            >
              <span className="before">{getAutocompleteInfo().before}</span>
              <span className="autocomplete">
                {getAutocompleteInfo().autocompleteText}
              </span>
              {/* <span>{getAutocompleteInfo().after}</span> */}
            </AutocompleteTextWrapper>
            <div />
          </Fragment>
        )}
      />

      <OptionList
        OptionItem={AutocompleteOption}
        items={getSuggestions()}
        dropdownRef={optionListRef}
        selectedItems={[]}
        top={isSmallView ? -100 : top}
        alignRight
        optionsOpened={optionsOpened}
        onSelect={onOptionSelect}
        canUnselect
        onNavigatePage={noop}
        selectedIndex={selectedIndex}
        isSmallView={isSmallView}
        noItemsText={`+ ${intl.formatMessage({ id: 'create_text_template' })}`}
        noItemsLink={internalRoutePaths.TEMPLATES_AUTOCOMPLETE}
        noItemsBackgroundColor="#f5f7f9"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  templates: getAutocompleteTemplatesList(state),
  areTemplatesLoading: getLoadingStatusForFilterName(
    state,
    entities.TEMPLATE,
    AUTOCOMPLETE_LIST
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getAutocompleteTemplates: (categoryId) =>
    dispatch(
      fetchEntityFromCacheWithFilter(
        entities.TEMPLATE,
        AUTOCOMPLETE_LIST,
        null,
        { categoryId, page: 1 }
      )
    ),
});

export const AutocompleteAdd = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutocompleteAddBase);
