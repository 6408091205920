import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import GalleryWrapped from '../../../../../../../components/gallery/gallery-wrapped';
import { useCreateAttachmentCommentMutation } from '../../../../../IssuesV2/Issue/service';
import { useGetAttachmentCommentsQuery } from '../../../../../IssuesV2/Issue/service';

const GalleryWrapper = (props) => {
  const { issue, refetchCalendarIssue } = props;

  const issueId = issue?.id;

  const currentLoggedUser = useSelector((state) => state.user.toJS());
  const intl = useIntl();
  const [createAttachmentComment] = useCreateAttachmentCommentMutation();
  const { data: attachmentComments } = useGetAttachmentCommentsQuery(issueId, {
    skip: !issueId,
  });

  const galleryIsUsingAttachmentPriority = currentLoggedUser?.client?.settings
    ?.include_issue_attachment_priority
    ? true
    : false;
  const galleryIsUsingAttachmentDueDate = currentLoggedUser?.client?.settings
    ?.include_issue_attachment_due_date
    ? true
    : false;

  const galleryPermissons = issue?.permissions ?? {
    canAddAttachments: true,
    deleteOnlyMyAttachments: false,
    editOnlyMyAttachments: false,
  };

  return (
    <GalleryWrapped
      onGalleryChanged={() => {}}
      id={String(issueId) ?? null}
      permissions={galleryPermissons}
      intl={intl}
      isUsingAttachmentPriority={galleryIsUsingAttachmentPriority}
      isUsingAttachmentDueDate={galleryIsUsingAttachmentDueDate}
      user={{
        ...currentLoggedUser,
        id: currentLoggedUser?.id,
        profileImageUrl: currentLoggedUser?.profile_image,
        name: `${currentLoggedUser?.first_name} ${currentLoggedUser?.last_name}`,
      }}
      onPostGalleryComment={async (comment, attachment_id) => {
        const data = {
          id: issueId,
          body: {
            comment,
            attachment_id,
          },
        };
        await createAttachmentComment(data);
      }}
      issue={issue}
      issueAttachmentsComments={attachmentComments ?? []}
      enableOrdering={false}
      refetchIssue={refetchCalendarIssue}
      showOnlyPreview={true}
    />
  );
};

export default GalleryWrapper;
