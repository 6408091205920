import {
  login,
  requestPasswordChange,
  changePassword as changePasswordAction,
} from '../auth/action-creators';
import { fetchEntityFromCacheWithFilter } from '../entities/action-creators';
import { entities } from './../../config/config';
import { actions as authActions } from '../auth/actions';
import {
  setCategoryFilter,
  setUsersFilter,
  setIssueListFilter,
  setAutocompleteTemplateFilter,
} from '../filter/actions';
import { USER_LIST, CATEGORY_LIST } from '../filter/filters';
import { DEFAULT_PARAMS } from '../../helpers/filter';
import { getFilterWithUserId } from '../filter/selectors';
import { fetchConfig } from '../config/action-creators';

export const loginFlow =
  (email, password, shouldRememberUser) => async (dispatch) => {
    const { expiration, token, user, message } = await dispatch(
      login(email, password)
    );

    if (expiration && token && user) {
      if (shouldRememberUser) {
        dispatch(authActions.rememberMe());
      }

      await dispatch(fetchData());

      await dispatch(authActions.loginSuccess());

      return user;
    } else if (message) {
      throw message;
    }
  };

export const requestPwChange = (email) => (dispatch) =>
  dispatch(requestPasswordChange(email));

export const changePassword = (token, email, password) => (dispatch) =>
  dispatch(changePasswordAction(token, email, password));

//persisted filters
const PERSISTED_FILTERS = JSON.parse(
  localStorage.getItem('https://staging.wowflow.org-filters')
);
//end persisted filters

export const fetchData =
  ({ forceFetchUsers } = {}) =>
  async (dispatch, getState) => {
    dispatch(setCategoryFilter(DEFAULT_PARAMS.CATEGORY));
    dispatch(setUsersFilter(DEFAULT_PARAMS.USERS));

    dispatch(
      setIssueListFilter(
        getFilterWithUserId({
          ...DEFAULT_PARAMS.ISSUE_LIST,
          ...(PERSISTED_FILTERS?.ISSUE_LIST || {}),
        })(getState())
      )
    );
    dispatch(setAutocompleteTemplateFilter());

    await Promise.all([
      dispatch(
        fetchEntityFromCacheWithFilter(
          entities.USERS,
          USER_LIST,
          forceFetchUsers
        )
      ),
      dispatch(
        fetchEntityFromCacheWithFilter(entities.CATEGORY, CATEGORY_LIST)
      ),
      dispatch(fetchConfig()),
    ]);
  };
