import { apiCall } from './ApiService';

import { config } from './config';
import { getData, localStorageKey } from '../redux/utils';

const loginRoute = `${config.URL}/${config.API.LOGIN}`;
const requestPasswordChangeRoute = `${config.URL}/${config.API.PASSWORD_CHANGE}`;
const resetPasswordRoute = `${config.URL}/${config.API.PASSWORD_RESET}`;

export const loginUser = (email, password) => {
  const savedData = getData(localStorageKey);

  if (savedData) {
    return new Promise((resolve, reject) => resolve(savedData));
  }

  return apiCall(
    config.REQ_TYPES.POST,
    loginRoute,
    config.CONTENT_TYPES.APPLICATION_JSON,
    {
      email,
      password,
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    });
};

export const requestPasswordChange = (email) => {
  return apiCall(
    config.REQ_TYPES.POST,
    requestPasswordChangeRoute,
    config.CONTENT_TYPES.APPLICATION_JSON,
    { email }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    });
};

export const resetPassword = (token, email, password) => {
  return apiCall(
    config.REQ_TYPES.POST,
    resetPasswordRoute,
    config.CONTENT_TYPES.APPLICATION_JSON,
    { token, email, password }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    });
};
