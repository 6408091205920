import { useCallback, useEffect, useRef, useState } from 'react';

const useVisible = (initialIsVisible, additionalParams = {}) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          additionalParams?.skipHideOnElements?.length &&
          additionalParams.skipHideOnElements.find((elem) =>
            elem?.current?.contains(event.target)
          )
        )
          return;
        setIsVisible(false);
      }
    },
    [additionalParams]
  );

  const toggleVisible = () => setIsVisible(!isVisible);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { ref, isVisible, setIsVisible, toggleVisible };
};

export default useVisible;
