export const WORKSPACE_ACTIONS = 'WORKSPACE';
export const actionStrings = {
  SELECT_WORKSPACE: `${WORKSPACE_ACTIONS}/SELECT_WORKSPACE`,
  TOGGLE_EDIT_MODE: `${WORKSPACE_ACTIONS}/TOGGLE_EDIT_MODE`,
  UPDATE_USER_LIST: `${WORKSPACE_ACTIONS}/UPDATE_USER_LIST`,
  UPDATE_PRESENCE_DISPLAY: `${WORKSPACE_ACTIONS}/UPDATE_PRESENCE_DISPLAY`,
  SUBMIT_WS_FORM: `${WORKSPACE_ACTIONS}/SUBMIT_WS_FORM`,
  SUBMIT_WS_FORM_SUCCESSFUL: `${WORKSPACE_ACTIONS}/SUBMIT_WS_FORM_SUCCESSFUL`,
  SUBMIT_WS_FORM_ERROR: `${WORKSPACE_ACTIONS}/SUBMIT_WS_FORM_ERROR`,
  CLEANUP_FORM: `${WORKSPACE_ACTIONS}/CLEANUP`,
};

export const submitWsForm = () => ({
  type: actionStrings.SUBMIT_WS_FORM,
});
export const submitWsFormSuccess = (updatedWs) => ({
  type: actionStrings.SUBMIT_WS_FORM_SUCCESSFUL,
  payload: updatedWs,
});
export const submitWsFormError = (error) => ({
  type: actionStrings.SUBMIT_WS_FORM_ERROR,
  payload: error,
});
export const updateUserList = (newUsers, updatedUserType, wsId) => ({
  type: actionStrings.UPDATE_USER_LIST,
  payload: {
    newUsers,
    updatedUserType,
    wsId,
  },
});
export const updatePresenceDisplay = (showPresence) => ({
  type: actionStrings.UPDATE_PRESENCE_DISPLAY,
  payload: {
    showPresence,
  },
});
export const selectWorkspace = (workspace) => ({
  type: actionStrings.SELECT_WORKSPACE,
  payload: {
    workspace,
  },
});
export const cleanupWorkspaceForm = () => ({
  type: actionStrings.CLEANUP_FORM,
});
