import { useMemo } from 'react';
import { CALENDAR_VIEW_TYPES } from '../../../constants';

import {
  getTimelineData,
  getCurrentTimeMarkerPosition,
  getPresenceTimelineData,
} from '../utils';

const timelineHours = 24;

const useConfig = (props = {}) => {
  const { timelineWidthPx = 2700, data = [], startDayTime, activeView } = props;

  const eachHourWidthPxBox = Math.round(timelineWidthPx / timelineHours);

  const mappedCalendarData = useMemo(() => {
    const finalData = data.map((item) => {
      const returnData = {
        cardData: { ...item },
        timelineData: [],
        showViewMoreCard: false,
        viewMoreCardItems: [],
      };

      if (activeView === CALENDAR_VIEW_TYPES.dailyUsers) {
        returnData.presenceTimelineData = [];
        if (item?.presence?.length) {
          const { presenceTimelineData } = getPresenceTimelineData(
            item.presence,
            {
              eachHourWidthPxBox,
              startDayTime,
            }
          );
          returnData.presenceTimelineData = presenceTimelineData;
        }
      }

      if (item?.issues?.length) {
        const {
          timelineData,
          timelineFullHeight,
          showViewMoreCard,
          viewMoreCardItems,
        } = getTimelineData(item.issues, {
          eachHourWidthPxBox,
          startDayTime,
        });
        if (timelineData?.length) returnData.timelineData = timelineData;
        if (viewMoreCardItems?.length)
          returnData.viewMoreCardItems = viewMoreCardItems;
        if (timelineFullHeight)
          returnData.timelineFullHeight = timelineFullHeight;
        returnData.showViewMoreCard = showViewMoreCard;
      }

      return returnData;
    });

    return finalData ?? [];
  }, [data, eachHourWidthPxBox, startDayTime, activeView]);

  return {
    eachHourWidthPxBox,
    timelineHours,
    mappedCalendarData,
    currentTimeMarkerPosition: getCurrentTimeMarkerPosition({
      eachHourWidthPxBox,
    }),
  };
};

export default useConfig;
