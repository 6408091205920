import styled from 'styled-components/macro';
import { darkerGray, gradientBlue } from '../../config/colors';

export const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0px 2px 10px #37465f26;
  height: 60px;
  min-height: 60px;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  z-index: 151;
  position: relative;
`;

export const LeftSide = styled.div`
  color: ${darkerGray};
  font-size: 17px;
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  color: ${darkerGray};
  font-size: 14px;

  div:hover {
    cursor: pointer;
  }
`;

export const CtaWrapper = styled.div`
  color: ${gradientBlue};
  font-size: 15px;
  font-weight: bold;
  ${({ isHeader }) => (isHeader ? 'margin-left: 15px;' : null)};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const CtaButton = styled.div`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background-color: ${gradientBlue};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 7px solid white;
`;
