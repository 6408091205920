import React, { useRef } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import './date-selector.scss';
import styled from 'styled-components/macro';
import {
  neonGreen,
  white,
  gray,
  black,
  darkerGray,
} from '../../../../config/colors';
import { ThemedIcon, themeColour } from '../../../../helpers/theme';
import { breakpoint } from '../../../../styles/styledComponents/media-breakpoints';

const DateSelectorWrapper = styled.div`
  width: 65%;
  display: flex;
  position: relative;

  & .inactive {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input::placeholder,
        input:placeholder-shown {
          color: ${themeColour};
        }
      }
    }
  }

  .react-datepicker__input-time-container {
    width: 50%;
    margin: 5px;
    .react-datepicker-time__caption {
      display: none;
    }
    .react-datepicker-time__input {
      input {
        border: 1px solid ${gray};
        color: ${darkerGray};
        margin-left: 0;
        padding-left: 10px;
        height: 25px;
        font-size: 12px;
        border-radius: 5px;
        text-align: center;
        font-family: 'nunitosans';
        &:focus {
          outline: none;
          border: 1px solid ${black};
        }
      }
    }
  }

  @media only screen and ${breakpoint.xs} {
    width: 60%;
  }
`;

const DoneButton = styled.button`
  height: 25px;
  background-color: ${neonGreen};
  font-size: 12px;
  color: ${white};
  padding: 0 20px;
  border-radius: 5px;
  float: right;
  text-transform: uppercase;
  cursor: pointer;
  margin: 5px 15px 10px 0;
  font-weight: 600;
  outline: none;
`;

export const DateSelector = ({
  noValueMessage,
  value,
  onChange,
  disabled,
  startDate,
  removable,
  format,
  timeFormat,
  minDate,
  maxDate,
  doneButtonText,
}) => {
  const searchFilterClasses = classNames('date-selector', {
    inactive: !removable,
    disabled: disabled,
  });

  let datepickerRef = useRef(null);

  const closeDatepicker = () => {
    datepickerRef.setOpen(false);
  };

  return (
    <DateSelectorWrapper secondaryAccent>
      <div className={searchFilterClasses}>
        {removable ? (
          <ThemedIcon secondaryAccent className="icon icon-calendar" />
        ) : (
          <ThemedIcon secondaryAccent className="icon icon-plus" />
        )}
        <DatePicker
          ref={(r) => {
            datepickerRef = r;
          }}
          selected={value}
          onChange={onChange}
          readOnly={disabled}
          disabled={disabled}
          shouldCloseOnSelect={false}
          placeholderText={noValueMessage}
          startDate={startDate}
          dateFormat={format}
          showTimeInput
          minDate={minDate || startDate}
          maxDate={maxDate}
          timeFormat={timeFormat}
        >
          <DoneButton onClick={closeDatepicker}>{doneButtonText}</DoneButton>
        </DatePicker>
        {removable && !disabled ? (
          <i className="icon icon-close" onClick={() => onChange()} />
        ) : null}
      </div>
    </DateSelectorWrapper>
  );
};
