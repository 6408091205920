import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components_new/Button';
import Modal from '../../../../components_new/Modal';
import { Heading, Icon, Wrapper } from './styles';

const UserAddedToAllWorkspacesModal = ({ isOpen, onClose, success }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <Icon
          className={success ? 'icon icon-complete' : 'icon icon-sad-emoji'}
        />
        <Heading>
          {success ? (
            <FormattedMessage id="user_added_to_all_ws" />
          ) : (
            <FormattedMessage id="notification_error_general" />
          )}
        </Heading>

        <Button
          appearance="outlined"
          color="green"
          size="small"
          onClick={onClose}
        >
          <FormattedMessage id="ok" defaultMessage="Ok" />
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default UserAddedToAllWorkspacesModal;
