import { fromJS } from 'immutable';
import { actionStrings } from './actions';

const initState = fromJS({
  width: 0,
  height: 0,
  isTabletView: false,
  sidebarExpanded: false,
  popupPresented: false,
  galleryPopupPresented: false,
  qrPopupPresented: false,
  areYouSurePopupPresented: false,
  exportToPdfPopupPresented: false,
  visibilityPopupPresented: false,
  language: '',
  modalShow: false,
  selectedUsers: [],
  forceShowSurveyModal: false,
});

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.TOGGLE_POPUP:
      return state.set('popupPresented', !state.get('popupPresented'));
    case actionStrings.TOGGLE_GALLERY_POPUP:
      return state.set(
        'galleryPopupPresented',
        !state.get('galleryPopupPresented')
      );
    case actionStrings.TOGGLE_QR_POPUP:
      return state.set('qrPopupPresented', !state.get('qrPopupPresented'));
    case actionStrings.TOGGLE_ARE_YOU_SURE_POPUP:
      return state.set(
        'areYouSurePopupPresented',
        !state.get('areYouSurePopupPresented')
      );
    case actionStrings.TOGGLE_EXPORT_TO_PDF_POPUP:
      return state.set(
        'exportToPdfPopupPresented',
        !state.get('exportToPdfPopupPresented')
      );
    case actionStrings.TOGGLE_VISIBILITY_POPUP:
      return state.set(
        'visibilityPopupPresented',
        !state.get('visibilityPopupPresented')
      );
    case actionStrings.CLOSE_VISIBILITY_POPUP:
      return state.set('visibilityPopupPresented', false);
    case actionStrings.SET_FORCE_SHOW_SURVEY_MODAL:
      return state.set('forceShowSurveyModal', action.payload);
    default:
      return state;
  }
};
