import { FormattedMessage, useIntl } from 'react-intl';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { UNSCHEDULED_FILTERS_TABS } from '../../constants';
import { statusColors } from '../../../../../../../config/colors';

import { LoadingSpinner } from '../../../../../../../components/loading-spinner/loading-spinner.component';
import {
  Counter,
  Sort,
} from '../../../../../../../components_new/StickyHeader';

import {
  DataItemWrapper,
  DataItemStatus,
  DataItemLabel,
  TabWrapper,
  SpinnerWrapper,
  SmallFiltersWrapper,
  DataWrapper,
} from '../../styles';
import InfiniteScroll from '../../../../../../../components_new/InfiniteScroll';
import {
  WowColorizeText,
  WowDisplayFlex,
} from '../../../../../../../components_new/WowBasicElements';
import { droppableElementStyles } from '../../../../utils';

const NoDateTabContent = ({ noDateHookData, scrollMaxHeight }) => {
  const intl = useIntl();
  const unscheduledIssueCurrentDragId = useSelector(
    (state) => state?.calendar?.unscheduledIssueCurrentDragId
  );

  const { scrollState, handleLoadMore, isProcessing, handleCustomQuery } =
    noDateHookData;

  const { sort_by: sortBy, sort_direction: sortDirection } = scrollState.query;
  const noDateItemsCount = scrollState.itemsList.length;

  const handleOnOrderChange = () => {
    handleCustomQuery({
      sort_direction: sortDirection === 'asc' ? 'desc' : 'asc',
    });
  };

  const handleOnSortChange = (value) => {
    handleCustomQuery({
      sort_by: value,
    });
  };

  return (
    <TabWrapper data-testid="noDateTabContent">
      <>
        <SmallFiltersWrapper>
          <Counter number={noDateItemsCount} />
          <Sort
            onChange={handleOnSortChange}
            onOrderChange={handleOnOrderChange}
            options={UNSCHEDULED_FILTERS_TABS?.NO_DATE?.sortOptions.map(
              (sortOption) => ({
                value: sortOption.value,
                label: intl.formatMessage({
                  id: sortOption.label,
                }),
              })
            )}
            order={sortDirection}
            selectedOption={sortBy}
            menuPosition="right"
          />
        </SmallFiltersWrapper>

        <DataWrapper>
          {noDateItemsCount === 0 ? (
            isProcessing ? (
              <SpinnerWrapper>
                <LoadingSpinner size="small" />
              </SpinnerWrapper>
            ) : (
              <WowDisplayFlex>
                <WowColorizeText size={14}>
                  <FormattedMessage id="no_available_data" />
                </WowColorizeText>
              </WowDisplayFlex>
            )
          ) : (
            <Droppable
              droppableId="unscheduleDroppableId"
              isDropDisabled={true}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <InfiniteScroll
                    dataLength={scrollState.itemsList.length}
                    handleLoadMore={handleLoadMore}
                    hasMore={scrollState.hasMore}
                    height={-1}
                    maxHeight={scrollMaxHeight ?? 550}
                    customStyle={{ paddingRight: '10px' }}
                  >
                    {scrollState.itemsList.map((item, index) => {
                      return (
                        <Draggable
                          key={String(item.id)}
                          draggableId={`unschedule-${String(item.id)}`}
                          index={index}
                          isDragDisabled={
                            unscheduledIssueCurrentDragId ? true : false
                          }
                        >
                          {(provided, snapshot) => (
                            <DataItemWrapper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              style={droppableElementStyles(
                                provided?.draggableProps?.style ?? {},
                                snapshot ?? {}
                              )}
                            >
                              <DataItemStatus
                                backgroundColor={
                                  statusColors[item?.status?.name] ?? '#000'
                                }
                              />
                              <DataItemLabel>{item?.title}</DataItemLabel>
                            </DataItemWrapper>
                          )}
                        </Draggable>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              )}
            </Droppable>
          )}
        </DataWrapper>
      </>
    </TabWrapper>
  );
};

NoDateTabContent.propTypes = {
  noDateHookData: PropTypes.object.isRequired,
  scrollMaxHeight: PropTypes.number,
};

export default NoDateTabContent;
