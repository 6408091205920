import { authReq2 } from '../../services/ApiService';

export const getGalleryId = (hostId) => {
  if (hostId) {
    return `gallery_${hostId}`;
  }

  return 'gallery_default';
};

export const transformAttachment = ({
  att,
  isThumbnailPresent,
  index,
  attachmentState,
}) => ({
  id: att.id,
  url: att.url,
  thumbnailUrl: att.web_mob_thumbnail_url ?? att.url,
  thumbnailPresent: true, // for now, change when resolve comment below
  originalDescription: att.description, // so we can undo changes
  description: att.description,
  mimeType: att.mime_type,
  mediaType: att.media_type,
  showAvatar: att.user !== null,
  fileName: att.original_filename,
  index: index,
  originalIndex: index,
  state: attachmentState.ORIGINAL,
  originalState: attachmentState.ORIGINAL,
  allowedToEdit: true,
  allowedToDelete: true,
  due_date: att.due_date,
  priority_id: att.priority_id,
  user: {
    profileImageUrl: att.user ? att.user.profile_image : null,
    name: att.user ? `${att.user.first_name} ${att.user.last_name}` : null,
    full_name: att.user ? att.user.full_name : null,
  },
});

export const checkIfResourceExists = async (url, token) => {
  try {
    const response = await authReq2(url, 'HEAD', '', token);

    return response.ok;
  } catch {
    return false;
  }
};
