import styled from 'styled-components/macro';
import { pdfBlue } from '../../../../../config/colors';

export const Marker = styled.div`
  position: absolute;
  top: 25px;
  left: ${({ left }) => (left ? `${left - 10}px` : '0px')};
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent ${pdfBlue} transparent;
  border-style: solid;
  width: 0;
  height: 0;
  transform: rotate(180deg);
  z-index: 200;
`;
