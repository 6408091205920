import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RTK_HOOK_CONFIG } from '../../../../config/constants';
import { CALENDAR_VIEW_TYPES } from '../constants';
import {
  useGetCalendarDailyUsersQuery,
  useGetCalendarDailyWorkspacesQuery,
} from '../service';
import { setDefaultHiddenData } from '../slice';
import { getActiveView } from '../selectors';

const useDailyCalendar = ({
  customRTKConfig,
  currentPage,
  queryParams,
  persistedFilters,
  skipGetCalendarDailyUsers,
  skipGetCalendarDailyWorkspacesData,
} = {}) => {
  const dispatch = useDispatch();

  const {
    data: calendarDailyUsersFetchData,
    isFetching: isFetchingCalendarDailyUsers,
    isUninitialized: isUninitializedCalendarDailyUsers,
    isLoading: isLoadingFirstTimeCalendarDailyUsers,
  } = useGetCalendarDailyUsersQuery(
    { ...queryParams, page: currentPage, paginate_by: 25 },
    customRTKConfig || {
      ...RTK_HOOK_CONFIG,
      skip: !persistedFilters || skipGetCalendarDailyUsers,
    }
  );

  const activeView = useSelector(getActiveView);

  const [calendarDailyUsersData, setCalendarDailyUsersData] = useState([]);
  const [calendarDailyUsersCalendarData, setCalendarDailyUsersCalendarData] =
    useState([]);

  useEffect(() => {
    if (activeView === CALENDAR_VIEW_TYPES.dailyUsers) {
      dispatch(setDefaultHiddenData(calendarDailyUsersData));
    }
  }, [calendarDailyUsersData, dispatch, activeView]);

  const [
    calendarDailyUsersCalendarDataInitialSet,
    setCalendarDailyUsersCalendarDataInitialSet,
  ] = useState(false);
  const handleSetCalendarDailyUsersData = (data = [], hardReset = false) => {
    setCalendarDailyUsersData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };
  const handleMoveToCalendarDailyUsersData = () => {
    setCalendarDailyUsersCalendarData((prevState) => {
      const newState = [...prevState];
      calendarDailyUsersData.forEach((item) => {
        if (!newState.find((innerItem) => innerItem.id === item.id)) {
          newState.push(item);
        }
      });
      return newState;
    });
  };
  const calendarDailyUsersLastPage = calendarDailyUsersFetchData?.last_page;
  const calendarDailyUsersCurrentPage =
    calendarDailyUsersFetchData?.current_page;
  useEffect(() => {
    let hardReset = false;
    if (calendarDailyUsersCurrentPage === 1) hardReset = true;
    handleSetCalendarDailyUsersData(
      calendarDailyUsersFetchData?.data,
      hardReset
    );
  }, [calendarDailyUsersCurrentPage, calendarDailyUsersFetchData]);

  useEffect(() => {
    if (
      !calendarDailyUsersCalendarDataInitialSet &&
      calendarDailyUsersCalendarData?.length === 0 &&
      calendarDailyUsersFetchData?.data?.length
    ) {
      setCalendarDailyUsersCalendarData(calendarDailyUsersFetchData.data);
      setCalendarDailyUsersCalendarDataInitialSet(true);
    }
  }, [
    calendarDailyUsersCalendarDataInitialSet,
    calendarDailyUsersFetchData,
    calendarDailyUsersCalendarData,
  ]);

  useEffect(() => {
    if (
      calendarDailyUsersCurrentPage !== 1 &&
      calendarDailyUsersCalendarDataInitialSet
    ) {
      setCalendarDailyUsersCalendarData((prevState) => {
        const newState = [...prevState];
        calendarDailyUsersData.forEach((item) => {
          if (!newState.find((outerItem) => outerItem.id === item.id))
            newState.push(item);
        });
        return newState;
      });
    }
  }, [
    calendarDailyUsersCurrentPage,
    calendarDailyUsersCalendarDataInitialSet,
    calendarDailyUsersData,
  ]);

  const {
    data: calendarDailyWorkspacesFetchData,
    isFetching: isFetchingCalendarDailyWorkspaces,
    isUninitialized: isUninitializedCalendarDailyWorkspaces,
    isLoading: isLoadingFirstTimeCalendarDailyWorkspaces,
  } = useGetCalendarDailyWorkspacesQuery(
    { ...queryParams, page: currentPage, paginate_by: 25 },
    customRTKConfig || {
      ...RTK_HOOK_CONFIG,
      skip: !persistedFilters || skipGetCalendarDailyWorkspacesData,
    }
  );

  const [calendarDailyWorkspacesData, setCalendarDailyWorkspacesData] =
    useState([]);
  const [
    calendarDailyWorkspacesCalendarData,
    setCalendarDailyWorkspacesCalendarData,
  ] = useState([]);
  useEffect(() => {
    if (activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
      dispatch(setDefaultHiddenData(calendarDailyWorkspacesData));
    }
  }, [calendarDailyWorkspacesData, dispatch, activeView]);
  const [
    calendarDailyWorkspacesCalendarDataInitialSet,
    setCalendarDailyWorkspacesCalendarDataInitialSet,
  ] = useState(false);
  const handleSetCalendarDailyWorkspacesData = (
    data = [],
    hardReset = false
  ) => {
    setCalendarDailyWorkspacesData((prevState) =>
      hardReset ? data : [...prevState].concat(data)
    );
  };
  const handleMoveToCalendarDailyWorkspacesData = () => {
    return setCalendarDailyWorkspacesCalendarData((prevState) => {
      const newState = [...prevState];
      calendarDailyWorkspacesData.forEach((item) => {
        if (!newState.find((innerItem) => innerItem.id === item.id)) {
          newState.push(item);
        }
      });
      return newState;
    });
  };
  const calendarDailyWorkspacesLastPage =
    calendarDailyWorkspacesFetchData?.last_page;
  const calendarDailyWorkspacesCurrentPage =
    calendarDailyWorkspacesFetchData?.current_page;
  useEffect(() => {
    let hardReset = false;
    if (calendarDailyWorkspacesCurrentPage === 1) hardReset = true;
    handleSetCalendarDailyWorkspacesData(
      calendarDailyWorkspacesFetchData?.data,
      hardReset
    );
  }, [calendarDailyWorkspacesCurrentPage, calendarDailyWorkspacesFetchData]);

  useEffect(() => {
    if (
      !calendarDailyWorkspacesCalendarDataInitialSet &&
      calendarDailyWorkspacesCalendarData?.length === 0 &&
      calendarDailyWorkspacesFetchData?.data?.length
    ) {
      setCalendarDailyWorkspacesCalendarData(
        calendarDailyWorkspacesFetchData.data
      );
      setCalendarDailyWorkspacesCalendarDataInitialSet(true);
    }
  }, [
    calendarDailyWorkspacesCalendarDataInitialSet,
    calendarDailyWorkspacesFetchData,
    calendarDailyWorkspacesCalendarData,
  ]);

  useEffect(() => {
    if (
      calendarDailyWorkspacesCurrentPage !== 1 &&
      calendarDailyWorkspacesCalendarDataInitialSet
    ) {
      setCalendarDailyWorkspacesCalendarData((prevState) => {
        const newState = [...prevState];
        calendarDailyWorkspacesData.forEach((item) => {
          if (!newState.find((outerItem) => outerItem.id === item.id))
            newState.push(item);
        });
        return newState;
      });
    }
  }, [
    calendarDailyWorkspacesCurrentPage,
    calendarDailyWorkspacesCalendarDataInitialSet,
    calendarDailyWorkspacesData,
  ]);

  const handleDeleteDailyDataItem = useCallback((params = {}) => {
    const { id, calendarView } = params;
    if (calendarView === CALENDAR_VIEW_TYPES.dailyUsers) {
      const mutateCalendarDailyUsersDataCallback = (prevState) => {
        const mutatedState = JSON.parse(JSON.stringify([...prevState]));
        for (const [userIndex, userValue] of mutatedState.entries()) {
          if (userValue?.issues?.length) {
            mutatedState[userIndex].issues = userValue.issues.filter(
              (issue) => issue.id !== id
            );
          }
        }
        return mutatedState;
      };
      setCalendarDailyUsersData(mutateCalendarDailyUsersDataCallback);
      setCalendarDailyUsersCalendarData(mutateCalendarDailyUsersDataCallback);
    } else if (calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
      const mutateCalendarDailyWorkspacesDataCallback = (prevState) => {
        const mutatedState = JSON.parse(JSON.stringify([...prevState]));
        for (const [workspaceIndex, workspaceValue] of mutatedState.entries()) {
          if (workspaceValue?.issues?.length) {
            mutatedState[workspaceIndex].issues = workspaceValue.issues.filter(
              (issue) => issue.id !== id
            );
          }
        }
        return mutatedState;
      };
      setCalendarDailyWorkspacesData(mutateCalendarDailyWorkspacesDataCallback);
      setCalendarDailyWorkspacesCalendarData(
        mutateCalendarDailyWorkspacesDataCallback
      );
    }
  }, []);

  const handleUpdateDailyDataItem = useCallback((params = {}) => {
    const { data, additionalParams, calendarView } = params;
    if (calendarView === CALENDAR_VIEW_TYPES.dailyUsers) {
      const mutateCalendarDailyUsersDataCallback = (prevState) => {
        const mutatedState = JSON.parse(JSON.stringify([...prevState]));
        let breakLoop = false;
        for (const [userIndex, userValue] of mutatedState.entries()) {
          if (breakLoop) break;
          if (userValue?.issues?.length) {
            for (const [issueIndex, issueValue] of userValue.issues.entries()) {
              if (data.id === issueValue?.id) {
                mutatedState[userIndex].issues[issueIndex] = {
                  ...mutatedState[userIndex].issues[issueIndex],
                  ...data,
                };
                breakLoop = true;
                break;
              }
            }
          }
        }
        if (!additionalParams.sameAssignee && data.assignee_id) {
          if (additionalParams.oldAssignee) {
            const index = mutatedState
              .map((item) => item.id)
              .indexOf(additionalParams.oldAssignee);
            if (index !== -1) {
              mutatedState[index].issues = mutatedState[index].issues.filter(
                (item) => item.assignee_id === additionalParams.oldAssignee
              );
            }
          }
          if (
            !additionalParams.unscheduledSave &&
            !additionalParams.updateFromViewMoreModal
          ) {
            const index = mutatedState
              .map((item) => item.id)
              .indexOf(data.assignee_id);
            if (index !== -1) mutatedState?.[index]?.issues?.push(data);
          }
        }
        return mutatedState;
      };
      setCalendarDailyUsersData(mutateCalendarDailyUsersDataCallback);
      setCalendarDailyUsersCalendarData(mutateCalendarDailyUsersDataCallback);
    } else if (calendarView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
      const mutateCalendarDailyWorkspacesDataCallback = (prevState) => {
        const mutatedState = JSON.parse(JSON.stringify([...prevState]));
        let breakLoop = false;
        for (const [workspaceIndex, workspaceValue] of mutatedState.entries()) {
          if (breakLoop) break;
          if (workspaceValue?.issues?.length) {
            for (const [
              issueIndex,
              issueValue,
            ] of workspaceValue.issues.entries()) {
              if (data.id === issueValue?.id) {
                mutatedState[workspaceIndex].issues[issueIndex] = {
                  ...mutatedState[workspaceIndex].issues[issueIndex],
                  ...data,
                };
                breakLoop = true;
                break;
              }
            }
          }
        }
        return mutatedState;
      };
      setCalendarDailyWorkspacesData(mutateCalendarDailyWorkspacesDataCallback);
      setCalendarDailyWorkspacesCalendarData(
        mutateCalendarDailyWorkspacesDataCallback
      );
    }
  }, []);

  const handleNewDailyItemSetup = useCallback(
    ({ data = {}, additionalParams = {} }) => {
      const {
        id,
        presetData = {},
        presetPosition,
        timelineId,
        activeView,
      } = data;

      const mutateStateCallback = (prevState) => {
        const mutatedState = JSON.parse(JSON.stringify([...prevState]));

        const targetIndex = mutatedState
          .map((item) => item.id)
          .indexOf(timelineId);
        if (targetIndex !== -1) {
          if (additionalParams?.dndTimelineUpdate) {
            if (mutatedState[targetIndex]?.issues?.length) {
              const issueTargetIndex = mutatedState[targetIndex]?.issues
                .map((item) => item.id)
                .indexOf(id);
              if (issueTargetIndex !== -1) {
                mutatedState[targetIndex].issues[issueTargetIndex] = {
                  ...mutatedState[targetIndex].issues[issueTargetIndex],
                  _presetLeftPosition: presetPosition,
                  start_date: null,
                  due_date: null,
                };
              }
            }
          } else {
            const preparedData = {
              ...presetData,
              id,
              _setup: true,
            };
            preparedData._presetLeftPosition = presetPosition;
            mutatedState[targetIndex].issues.unshift(preparedData);
          }
        }

        return mutatedState;
      };

      if (activeView === CALENDAR_VIEW_TYPES.dailyUsers) {
        setCalendarDailyUsersData(mutateStateCallback);
        setCalendarDailyUsersCalendarData(mutateStateCallback);
      } else if (activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces) {
        setCalendarDailyWorkspacesData(mutateStateCallback);
        setCalendarDailyWorkspacesCalendarData(mutateStateCallback);
      }
    },
    []
  );

  const queryParamsRef = useRef();
  useEffect(() => {
    const newQueryParams = JSON.parse(JSON.stringify(queryParams));
    delete newQueryParams.calendar_search;
    queryParamsRef.current = JSON.stringify(newQueryParams);
  }, [queryParams]);

  const queryParamsOldValue = useRef(null);

  useEffect(() => {
    if (
      calendarDailyUsersCalendarDataInitialSet &&
      queryParamsRef.current !== queryParamsOldValue.current &&
      activeView === CALENDAR_VIEW_TYPES.dailyUsers
    ) {
      queryParamsOldValue.current = queryParamsRef.current
        ? queryParamsRef.current
        : JSON.stringify(queryParamsRef.current);
      setCalendarDailyUsersCalendarData(calendarDailyUsersData);
    }
  }, [
    calendarDailyUsersData,
    calendarDailyUsersCalendarDataInitialSet,
    activeView,
  ]);

  useEffect(() => {
    if (
      calendarDailyWorkspacesCalendarDataInitialSet &&
      queryParamsRef.current !== queryParamsOldValue.current &&
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    ) {
      queryParamsOldValue.current = queryParamsRef.current
        ? queryParamsRef.current
        : JSON.stringify(queryParamsRef.current);
      setCalendarDailyWorkspacesCalendarData(calendarDailyWorkspacesData);
    }
  }, [
    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarDataInitialSet,
    activeView,
  ]);

  return {
    calendarDailyUsersData,
    calendarDailyUsersLastPage,
    isFetchingCalendarDailyUsers,
    isUninitializedCalendarDailyUsers,
    isLoadingFirstTimeCalendarDailyUsers,
    calendarDailyUsersCalendarData,
    handleMoveToCalendarDailyUsersData,
    setCalendarDailyUsersData,
    setCalendarDailyUsersCalendarData,

    calendarDailyWorkspacesData,
    calendarDailyWorkspacesCalendarData,
    calendarDailyWorkspacesLastPage,
    isFetchingCalendarDailyWorkspaces,
    isUninitializedCalendarDailyWorkspaces,
    isLoadingFirstTimeCalendarDailyWorkspaces,
    handleMoveToCalendarDailyWorkspacesData,
    setCalendarDailyWorkspacesData,
    setCalendarDailyWorkspacesCalendarData,

    handleDeleteDailyDataItem,
    handleUpdateDailyDataItem,
    handleNewDailyItemSetup,
  };
};

export default useDailyCalendar;
