import styled from 'styled-components/macro';

import { newRed } from '../../../../../../../config/colors';

export const DataItemWithDueDateWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  justify-content: space-between;
`;

export const DataItemDate = styled.div`
  font-size: 12px;
  color: ${newRed};
`;
