import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { userStatuses } from '../../config/config';
import dict from '../../config/multilang';
import styled from 'styled-components/macro';
import { redButtonColor, darkerGray } from '../../config/colors';
import { themeColour } from '../../helpers/theme';

export const getUserStatus = (user) => {
  if (user.status === userStatuses.ACTIVE && !user.last_login_on) {
    return userStatuses.PENDING;
  }

  return user.status;
};

export const getStatusTranslation = (status, props) => {
  let key;
  switch (status) {
    case userStatuses.ACTIVE:
      key = 'active';
      break;
    case userStatuses.PAUSED:
      key = 'archived';
      break;
    case userStatuses.PENDING:
      key = 'user_invited';
      break;
    default:
      return status;
  }

  return props.intl.formatMessage({
    id: key,
    defaultMessage: dict[key],
  });
};

const StatusBody = styled.div`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  line-height: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 11px;
  height: 22px;
  min-width: 100px;
  max-width: max-content;
  padding-left: 10px;
  padding-right: 10px;

  &.active {
    color: ${themeColour};
    border-color: ${themeColour};
  }

  &.inactive {
    color: ${redButtonColor};
    border-color: ${redButtonColor};
  }

  &.pending {
    color: ${darkerGray};
    border-color: ${darkerGray};
  }
`;
const UserStatus = (props) => {
  const status = getUserStatus(props.user);

  const containerClasses = classnames('user-status', {
    active: status === userStatuses.ACTIVE,
    inactive: status === userStatuses.PAUSED,
    pending: status === userStatuses.PENDING,
  });

  return (
    <StatusBody colourSecondary className={containerClasses}>
      <span>{getStatusTranslation(status, props)}</span>
    </StatusBody>
  );
};

UserStatus.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.string.isRequired,
    last_login_on: PropTypes.string,
  }).isRequired,
};

export default injectIntl(UserStatus);
