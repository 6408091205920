const HEIGHT = 200;
const MAX_HEIGHT = 300;
const HAS_MORE = true;

const MOCK_ITEMS = [
  {
    id: 1,
    value: 'v1',
  },
  {
    id: 2,
    value: 'v2',
  },
];

const MOCK_ITEMS_LOAD_MORE = [
  {
    id: 3,
    value: 'v3',
  },
  {
    id: 4,
    value: 'v4',
  },
];

export { HEIGHT, HAS_MORE, MOCK_ITEMS, MOCK_ITEMS_LOAD_MORE, MAX_HEIGHT };
