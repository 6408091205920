import React from 'react';

import { StyledTabs, StyledList, StyledTrigger } from './styles';
import { TABS } from '../../constants';

const Tabs = ({ active, handleChange }) => {
  return (
    <>
      <StyledTabs value={active} onValueChange={(value) => handleChange(value)}>
        <StyledList>
          {TABS.map((tab) => {
            return (
              <StyledTrigger value={tab.value} key={tab.value}>
                {tab.label}
              </StyledTrigger>
            );
          })}
        </StyledList>
      </StyledTabs>
    </>
  );
};

export default Tabs;
