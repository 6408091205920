import vest, { test, enforce } from 'vest';
import { emailReg } from '../../../../../../utils/validators';
import { EXPORT_TYPES } from './utils';

const formValidation = vest.create((data = {}) => {
  const { email_addresses, send_email_copy, export_type } = data;

  if (send_email_copy && email_addresses.length) {
    email_addresses.forEach((item) => {
      const value = item.value;
      test(`email_addresses`, 'Should be long enough', () => {
        enforce(value).longerThan(2);
      });

      test(`email_addresses`, "Can't be too long", () => {
        enforce(value).shorterThan(256);
      });

      test(`email_addresses`, 'Should be proper email', () => {
        enforce(value).matches(emailReg);
      });
    });
  }

  //at least 1 checkbox needs to be selected
  if (export_type === EXPORT_TYPES.custom) {
    let counterSelected = 0;
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('include_') && value) ++counterSelected;
    }
    test(`counterSelected`, 'At least 1 checkbox has to be selected', () => {
      enforce(counterSelected).greaterThan(0);
    });
  }
});

export default formValidation;
