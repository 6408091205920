import React, { useMemo } from 'react';
import SidebarItem from './SidebarItem/SidebarItem';
import { Wrapper, Space, LoaderWrapper } from './styles';
import ToggleButton from './ToggleButton';
import { useSelector } from 'react-redux';
import { filterSectionsByPermissions } from './utils';
import useMouseHover from './hooks/useMouseHover';
import useSidebarOpen from './hooks/useSidebarOpen';
import useWindowSizeDebounced from '../../../../hooks/useWindowSizeDebounced';
import { ID_KEYS } from '../constants';
import { getIsLoadingSwitchClient } from '../selectors';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import { getUser } from '../../../../redux/user/selectors';

const Sidebar = () => {
  const { isOpen, toggleSidebar } = useSidebarOpen();
  const { isHover, ...handlers } = useMouseHover(false);

  const loggedInUser = useSelector(getUser);
  const isAdmin = loggedInUser?.role === 'admin';
  const { settings, is_main: isMainOrganisation } = loggedInUser?.client ?? {};
  const { width, height } = useWindowSizeDebounced();
  const isLoadingSwitchClient = useSelector(getIsLoadingSwitchClient);

  const sections = useMemo(() => {
    if (!settings) return [];
    return filterSectionsByPermissions(settings, isAdmin, isMainOrganisation);
  }, [settings, isAdmin, isMainOrganisation]);

  return (
    <>
      <Space sidebarHovered={isHover} isOpen={isOpen} />
      <Wrapper
        isOpen={isOpen}
        isHover={isHover}
        {...handlers}
        data-testid={ID_KEYS.mainMenu}
        id={ID_KEYS.mainMenu}
        paddingBottom={height < 780 ? 100 : 0}
      >
        <div>
          {isLoadingSwitchClient ? (
            <LoaderWrapper>
              <LoadingSpinner small />
            </LoaderWrapper>
          ) : (
            sections.map((item) => (
              <SidebarItem
                item={item}
                key={item.id}
                isExpanded={isOpen || isHover}
              />
            ))
          )}
        </div>
        {width < 2000 && (
          <ToggleButton
            isOpen={isOpen}
            onClick={() => {
              handlers.onMouseLeave();
              toggleSidebar();
            }}
          />
        )}
      </Wrapper>
    </>
  );
};

export default Sidebar;
