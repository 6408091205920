import {
  RTK_HOOK_CONFIG,
  NOTIFICATIONS_INFINITE_SCROLL_INITIAL_STATE,
} from '../constants';
import { useGetNotificationsQuery } from '../../../service';
import { useEffect, useCallback } from 'react';
import useInfiniteScrollData from '../../../../Equipment/hooks/useInfiniteScrollData';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setNotificationsUnreadCount } from '../../../slice';

const useNotifications = () => {
  const dispatch = useDispatch();

  const {
    scrollState,
    handleLoadMore,
    handleStateUpdate,
    handleCustomQuery,
    handleLoadMoreWithPaginateBy,
    updateManuallyListItems,
  } = useInfiniteScrollData({
    ...NOTIFICATIONS_INFINITE_SCROLL_INITIAL_STATE,
    id: 'notifications',
    skipTransformItemsForDropdown: true,
  });

  const toggleShowOnlyUnread = useCallback(() => {
    if (scrollState.query.only_unread) {
      handleCustomQuery({ only_unread: undefined });
    } else {
      handleCustomQuery({ only_unread: true });
    }
  }, [scrollState, handleCustomQuery]);

  const {
    data: response,
    isFetching,
    refetch,
  } = useGetNotificationsQuery(scrollState.query, {
    ...RTK_HOOK_CONFIG,
    skip: false,
  });

  useEffect(() => {
    const _forceInitOverride = response?.current_page === 1;
    handleStateUpdate(response, null, _forceInitOverride);
  }, [response, handleStateUpdate]);

  useEffect(() => {
    if (!scrollState.query.category) {
      dispatch(setNotificationsUnreadCount(response?.total_unread));
    }
  }, [scrollState, response, dispatch]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.refetchNotifications) {
      refetch();
      history.replace({ state: {} });
    }
  }, [history, location, refetch]);

  return {
    scrollState,
    isFetching,
    refetch,

    toggleShowOnlyUnread,
    showOnlyUnread: scrollState.query.only_unread,
    handleLoadMore,
    handleCustomQuery,
    handleLoadMoreWithPaginateBy,
    updateManuallyListItems,
  };
};

export default useNotifications;
