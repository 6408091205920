import { useState, useEffect, useMemo } from 'react';

import { useGetWorkspacesQuery } from '../service';
import {
  INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
} from '../../../../config/constants';

import useInfiniteScrollData from '../hooks/useInfiniteScrollData';

const useWorkspaces = (customParams, skipFetching, onFirstLoad) => {
  const {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    resetQuery,
    handleCustomQuery,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    id: 'workspaces',
  });

  const params = useMemo(() => {
    return { ...scrollState.query, ...customParams };
  }, [scrollState?.query, customParams]);

  const {
    data: fetchedData,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetWorkspacesQuery(params, {
    ...RTK_HOOK_CONFIG,
    skip: skipFetching || false,
  });

  useEffect(() => {
    const _forceInitOverride = fetchedData?.current_page === 1;
    handleStateUpdate(fetchedData, null, _forceInitOverride);
  }, [fetchedData, handleStateUpdate]);

  const [loadState, setLoadState] = useState('before-first-load');
  useEffect(() => {
    if (
      loadState === 'before-first-load' &&
      isSuccess &&
      scrollState?.itemsList?.length > 0
    ) {
      if (onFirstLoad) {
        onFirstLoad(scrollState);
        setLoadState('loaded');
      }
    }
  }, [isFetching, isSuccess, loadState, onFirstLoad, scrollState]);

  return {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    isFetching,
    isLoading,
    mergeStateItems,
    resetQuery,
    handleCustomQuery,
  };
};

export default useWorkspaces;
