import { forwardRef, useEffect, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import { CALENDAR_CARD_TYPES } from './constants';
import {
  getActiveView,
  getCurrentActiveIssueDetailsModal,
  getTimelineIssueDraggableElementId,
} from '../../selectors';

import { Wrapper, CardWrapper, CardPadding } from './styles';
import { droppableElementStyles } from '../../utils';
import { CALENDAR_VIEW_TYPES } from '../../constants';

const CalendarTimelineCard = forwardRef((props, forwardedRef) => {
  const {
    type = CALENDAR_CARD_TYPES.ISSUE.key,
    item = {},
    detailsComponent,
    dataAttributeId,
    left,
    width,
    height,
    top,
    showDetailsComponent,
    handleToggleDetailsComponent,
    titleMaxLength,
    workspaceTitleMaxLength,
    hours,
    id,
    draggableTimelineIssueRevertData,
    showLeftValueInPercentage,
    showWidthValueInPercentage,
    fullDayItemsLength,
    allowHoverDetailsTrigger,
  } = props;

  useEffect(() => {
    if (item?._setup && item?.id && !showDetailsComponent)
      handleToggleDetailsComponent({ id: item?.id });
  }, [handleToggleDetailsComponent, item, showDetailsComponent]);

  const hoverTimeoutId = useRef();
  const triggerHover = () => {
    if (!allowHoverDetailsTrigger) return;
    if (hoverTimeoutId.current) window.clearTimeout(hoverTimeoutId.current);
    hoverTimeoutId.current = window.setTimeout(
      () => handleCardClick({ skipToggle: true }),
      500
    );
  };
  const cancelHover = () => {
    if (hoverTimeoutId.current) window.clearTimeout(hoverTimeoutId.current);
  };

  const timelineIssueDraggableElementId = useSelector(
    getTimelineIssueDraggableElementId
  );
  const currentActiveIssueDetailsModal = useSelector(
    getCurrentActiveIssueDetailsModal
  );

  const activeView = useSelector(getActiveView);

  const handleCardClick = (additionalParams = {}) => {
    if (
      currentActiveIssueDetailsModal === item?.id ||
      draggableTimelineIssueRevertData?.current
    )
      return;

    const { skipToggle } = additionalParams;

    const containerScrollLeft =
      document.getElementsByClassName('ScrollbarsCustom-Scroller')?.[0]
        ?.scrollLeft ?? 0;
    const containerScrollTop =
      document.getElementsByClassName('content')[0].scrollTop ?? 0;
    handleToggleDetailsComponent({
      id: item?.id,
      isSetup: false,
      ...(activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
        ? { presetTopValue: containerScrollTop }
        : { presetLeftValue: containerScrollLeft }),
      skipToggle,
    });
  };

  let calculatedLeft = left ?? null;
  if (!item.fullDay && fullDayItemsLength) {
    if (showLeftValueInPercentage) {
      if (left !== 0) calculatedLeft = calculatedLeft - calculatedLeft * 0.1;
    }
  }

  let calculatedWidth = width ?? null;
  if (!item.fullDay && fullDayItemsLength) {
    if (showLeftValueInPercentage) {
      if (width !== 0)
        calculatedWidth = calculatedWidth - calculatedWidth * 0.2;
    }
  }

  return (
    <Draggable
      key={String(id)}
      draggableId={`timeline-${String(id)}`}
      index={id}
      isDragDisabled={
        item?.box ||
        timelineIssueDraggableElementId !== id ||
        item?._setup ||
        item.fullDay ||
        item?.status_id === 0
      }
    >
      {(provided, snapshot) => (
        <CardPadding
          paddingRight={
            activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
            activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
              ? 3
              : 0
          }
          paddingLeft={
            activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
            activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
              ? 2.5
              : 0
          }
          left={calculatedLeft}
          top={top ?? null}
          width={calculatedWidth}
          showLeftValueInPercentage={showLeftValueInPercentage}
          showWidthValueInPercentage={showWidthValueInPercentage}
        >
          <Wrapper
            {...(dataAttributeId
              ? { 'data-calendar_timeline_card_attribute-_id': dataAttributeId }
              : {})}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            data-start_date={item?.start_date ?? ''}
            data-due_date={item?.due_date ?? ''}
            style={droppableElementStyles(
              provided?.draggableProps?.style ?? {},
              snapshot ?? {}
            )}
          >
            <CardWrapper
              width={showWidthValueInPercentage ? null : width ?? null}
              height={height ?? null}
              onClick={handleCardClick}
              data-testid="calendarTimelineCard"
              onMouseOver={triggerHover}
              onMouseLeave={cancelHover}
            >
              {CALENDAR_CARD_TYPES[type]
                ? CALENDAR_CARD_TYPES[type].component({
                    item,
                    titleMaxLength,
                    workspaceTitleMaxLength,
                    hours,
                  })
                : ''}
            </CardWrapper>
            {showDetailsComponent && detailsComponent}
          </Wrapper>
        </CardPadding>
      )}
    </Draggable>
  );
});

export default CalendarTimelineCard;
