import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper,
  InnerWrapper,
  LogoWrapper,
  Card,
  Title,
  CtaButton,
} from './styles';
import Logo from '../../../assets/img/logo-wowflow.svg';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowIcon,
} from '../../../components_new/WowBasicElements';
import { newBorder, newGrayVariant } from '../../../config/colors';
import Space from '../../../components_new/Space';

class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //eslint-disable-next-line
    console.log(error);
    //eslint-disable-next-line
    console.log(errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Wrapper>
          <InnerWrapper>
            <LogoWrapper>
              <img src={Logo} alt="" />
            </LogoWrapper>
            <Card>
              <WowDisplayFlex justify="center">
                <WowIcon
                  className=" icon-sad-emoji"
                  size="70"
                  spaceRight="0"
                  color={newBorder}
                />
              </WowDisplayFlex>
              <WowDisplayFlex justify="center">
                <Title>
                  <FormattedMessage id="we_are_working_on_it" />
                </Title>
              </WowDisplayFlex>
              <WowDisplayFlex justify="center" direction="column">
                <WowColorizeText color={newGrayVariant} size="14">
                  <FormattedMessage id="error_boundary_message" />
                </WowColorizeText>
                <Space height="15" />
                <WowColorizeText color={newGrayVariant} size="14">
                  <FormattedMessage id="please_refresh" />
                </WowColorizeText>
                <Space height="40" />
                <CtaButton
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <FormattedMessage id="reload" />
                </CtaButton>
              </WowDisplayFlex>
            </Card>
          </InnerWrapper>
        </Wrapper>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.any,
  shouldThrowError: PropTypes.bool,
};

export default ErrorBoundary;
