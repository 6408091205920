import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../redux/utils';
import qs from 'query-string';
import { BASE_URL } from '../../../config/constants';

export const calendarApi = createApi({
  reducerPath: 'calendarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      if (token) headers.set('Authorization', `Bearer ${token}`);
      headers.set('Accept', `application/json`);

      return headers;
    },
  }),
  tagTypes: [
    'CalendarDailyWorkspaces',
    'CalendarDailyUsers',
    'CalendarIssueDetails',
    'CalendarWeekly',
    'CalendarUnscheduled',
    'CalendarOverdue',
    'CalendarScheduledTaskDetails',
    'CalendarWeeklyUsers',
    'CalendarWeeklyWorkspaces',
    'CalendarWeeklyUsersCount',
    'CalendarWeeklyWorkspacesCount',
    'ScheduledTaskFuturePreview',
    'CalendarYearlySchedules',
  ],
  endpoints: (builder) => ({
    getCalendarDailyWorkspaces: builder.query({
      query: (params) => {
        const query = { include_subtasks: 0, ...params };
        if (query?.start_date) query.end_date = query.start_date;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/workspaces',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarDailyWorkspaces',
                id,
              })),
              { type: 'CalendarDailyWorkspaces', id: 'LIST' },
            ]
          : [{ type: 'CalendarDailyWorkspaces', id: 'LIST' }],
    }),
    getCalendarDailyUsers: builder.query({
      query: (params) => {
        const query = {
          include_subtasks: 0,
          manageable_users: '1',
          ...params,
        };
        if (query?.start_date) query.end_date = query.start_date;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/users',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarDailyUsers',
                id,
              })),
              { type: 'CalendarDailyUsers', id: 'LIST' },
            ]
          : [{ type: 'CalendarDailyUsers', id: 'LIST' }],
    }),
    getCalendarIssue: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `issue/${id}`;
        return url;
      },
      providesTags: (result) =>
        result ? [{ type: 'CalendarIssueDetails', id: result.id }] : [],
    }),
    getCalendarWeekly: builder.query({
      query: (params) => {
        const query = JSON.parse(
          JSON.stringify({
            include_subtasks: 0,
            ...params,
          })
        );
        if (query.workspace_ids) {
          query.workspaces = query.workspace_ids;
          delete query.workspace_ids;
        }
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/weekly/full_days',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarWeekly',
                id,
              })),
              { type: 'CalendarWeekly', id: 'LIST' },
            ]
          : [{ type: 'CalendarWeekly', id: 'LIST' }],
    }),
    updateCalendarIssue: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `issue/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result) => [
        { type: 'CalendarIssueDetails', id: result.id },
      ],
    }),
    getCalendarUnscheduled: builder.query({
      query: (params) => {
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/unscheduled',
              query: { include_subtasks: 0, ...params },
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['start_date', 'end_date']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarUnscheduled',
                id,
              })),
              { type: 'CalendarUnscheduled', id: 'LIST' },
            ]
          : [{ type: 'CalendarUnscheduled', id: 'LIST' }],
    }),
    getCalendarOverdue: builder.query({
      query: (params) => {
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/overdue',
              query: { include_subtasks: 0, ...params },
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['start_date', 'end_date']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarOverdue',
                id,
              })),
              { type: 'CalendarOverdue', id: 'LIST' },
            ]
          : [{ type: 'CalendarOverdue', id: 'LIST' }],
    }),
    getCalendarScheduledTask: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `scheduled_tasks/${id}`;
        return url;
      },
      providesTags: (result) =>
        result ? [{ type: 'CalendarScheduledTaskDetails', id: result.id }] : [],
    }),
    updateCalendarScheduledTask: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `scheduled_tasks/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result) => [
        { type: 'CalendarScheduledTaskDetails', id: result.id },
      ],
    }),
    getCalendarWeeklyUsers: builder.query({
      query: (params) => {
        const query = {
          sort_by: 'name',
          sort_direction: 'asc',
          include_statistics: 0,
          manageable_users: '1',
          filter_status: 'active',
          ...params,
        };
        if (query.calendar_search) query.search = query.calendar_search;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'users',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarWeeklyUsers',
                id,
              })),
              { type: 'CalendarWeeklyUsers', id: 'LIST' },
            ]
          : [{ type: 'CalendarWeeklyUsers', id: 'LIST' }],
    }),
    getCalendarWeeklyWorkspaces: builder.query({
      query: (params) => {
        const query = {
          sort_by: 'name',
          sort_direction: 'asc',
          permission: 'edit_workspace',
          ...params,
        };
        if (query.calendar_search) query.search = query.calendar_search;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'workspace/flat',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarWeeklyWorkspaces',
                id,
              })),
              { type: 'CalendarWeeklyWorkspaces', id: 'LIST' },
            ]
          : [{ type: 'CalendarWeeklyWorkspaces', id: 'LIST' }],
    }),
    getCalendarWeeklyUsersEventCount: builder.query({
      query: (params) => {
        const query = {
          include_subtasks: 0,
          ...params,
        };
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/weekly/users_count',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarWeeklyUsersCount',
                id,
              })),
              { type: 'CalendarWeeklyUsersCount', id: 'LIST' },
            ]
          : [{ type: 'CalendarWeeklyUsersCount', id: 'LIST' }],
    }),
    getCalendarWeeklyWorkspacesEventCount: builder.query({
      query: (params) => {
        const query = {
          include_subtasks: 0,
          ...params,
        };
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/weekly/workspaces_count',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          []
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CalendarWeeklyWorkspacesCount',
                id,
              })),
              { type: 'CalendarWeeklyWorkspacesCount', id: 'LIST' },
            ]
          : [{ type: 'CalendarWeeklyWorkspacesCount', id: 'LIST' }],
    }),
    getScheduledTaskFuturePreview: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `schedule/future_preview/${id}`;
        return url;
      },
      providesTags: (result) =>
        result ? [{ type: 'ScheduledTaskFuturePreview', id: result.id }] : [],
    }),

    // yearly calendar schedules
    getCalendarYearlySchedules: builder.query({
      query: (params) => {
        const query = {
          ...params,
        };

        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/yearly',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['start_date', 'end_date']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ scheduleId: id }) => ({
                type: 'CalendarYearlySchedules',
                id,
              })),
              { type: 'CalendarYearlySchedules', id: 'LIST' },
            ]
          : [{ type: 'CalendarYearlySchedules', id: 'LIST' }],
    }),
    getCalendarYearlyEvents: builder.query({
      query: (params) => {
        const query = {
          ...params,
        };

        if (params.start_date)
          query.year = new Date(params.start_date).getFullYear();

        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: 'calendar/yearly/events',
              query,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['start_date', 'end_date']
        );

        return url;
      },
    }),
  }),
});

export const {
  useGetCalendarDailyUsersQuery,
  useGetCalendarDailyWorkspacesQuery,
  useGetCalendarIssueQuery,
  useGetCalendarWeeklyQuery,
  useUpdateCalendarIssueMutation,
  useGetCalendarUnscheduledQuery,
  useGetCalendarOverdueQuery,
  useGetCalendarScheduledTaskQuery,
  useUpdateCalendarScheduledTaskMutation,
  useGetCalendarWeeklyUsersQuery,
  useGetCalendarWeeklyWorkspacesQuery,
  useGetCalendarWeeklyUsersEventCountQuery,
  useGetCalendarWeeklyWorkspacesEventCountQuery,
  useGetScheduledTaskFuturePreviewQuery,
  useLazyGetCalendarYearlySchedulesQuery,
  useGetCalendarYearlyEventsQuery,
} = calendarApi;
