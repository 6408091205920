const _TYPEOF = {
  function: 'function',
  string: 'string',
};

/**
 * Timeout with promise.
 * @public
 * @param  {Number}      delay       Delay in miliseconds.
 * @returns {Promise}                Promise.
 */
export const timeout = (delay) => {
  if (isNaN(delay)) throw new Error('`delay` should be a valid number.');

  return new Promise((res) => setTimeout(res, delay));
};

/**
 * Open url in a new tab.
 * @public
 * @param   {String}      url      Valid url.
 * @returns {void}
 */
export const openInNewTab = (url) => {
  if (!url) throw new Error('`url` must be provided.');
  const _win = window.open(url, '_blank', 'noopener,noreferrer');
  if (_win) _win.opener = null;
};

/**
 * Limits number of characters.
 * @public
 * @param   {String}      str      Any text that should be limited.
 * @param   {Number}      limit    Number of characters.
 * @returns {void}
 */
export const limitNumberOfCharacters = (str, limit) => {
  if (!str) return;
  return str.length > limit
    ? str.substring(0, limit - 3) + '...'
    : str.substring(0, limit);
};

/**
 * Checks if the type of a value matches the specified type.
 *
 * @param {*} value - The value to be checked.
 * @param {string} type - The type to compare against.
 * @returns {boolean} Returns `true` if the value's type matches the specified type, otherwise `false`.
 */
const _isTypeOf = (value, type) => {
  return typeof value === type;
};

/**
 * Checks if a value is a function.
 *
 * @param {any}           value    The value to check.
 * @returns {boolean}              Returns `true` if the value is a function.
 */
export const isFunction = (value) => _isTypeOf(value, _TYPEOF.function);

/**
 * Checks if a value is a string.
 *
 * @param {any} value               The value to check.
 * @returns {boolean}               Returns `true` if the value is a string.
 */
export const isString = (value) => _isTypeOf(value, _TYPEOF.string);

/**
 * Combine arrays of objects without duplicateds
 *
 * @param {array}          arrays   Arrays
 * @returns {array}                 Rerurns unique array of objects
 */
export const combineArrays = (...arrays) => {
  const combinedMap = new Map();

  for (const arr of arrays) {
    for (const obj of arr) {
      const id = obj.id;
      if (!combinedMap.has(id)) {
        combinedMap.set(id, { ...obj });
      } else {
        combinedMap.set(id, { ...combinedMap.get(id), ...obj });
      }
    }
  }

  return Array.from(combinedMap.values());
};
