import dayjs from 'dayjs';

export const shouldResetSelectedWorkspaces = (
  currentRole,
  selectedRole,
  isAdmin
) => {
  if (isAdmin) return false;

  if (currentRole === 'user' && selectedRole !== 'user') return true;

  if (currentRole === 'manager' && selectedRole === 'group_manager')
    return true;
};

export const flattenArray = (array) => {
  return array.reduce((acc, item) => {
    acc = [...acc, item, ...item.children];
    return acc;
  }, []);
};

export const getPositionsDropdownOptions = (positions) =>
  positions.map((position) => ({
    value: position,
    label: position,
    id: position,
  }));

export const formatDate = (date) => {
  if (!date) return;

  return dayjs(date).format('YYYY-MM-DD');
};
