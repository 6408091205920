import styled from 'styled-components/macro';
import { WowDisplayFlex } from '../../../../../WowBasicElements';
import { darkerGray, newBorder } from '../../../../../../config/colors';

export const DateInputsWrapper = styled(WowDisplayFlex)`
  padding: 10px 0 10px 0;
  ${({ borderBottom }) =>
    borderBottom &&
    `
        border-bottom: 1px solid ${newBorder};
    `};
  border-top: 1px solid ${newBorder};
`;

export const InputWrapper = styled.div`
  background-color: white;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  color: ${darkerGray};
  display: flex;
  padding: 8px 9px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  min-width: 120px;
`;

export const CheckboxesGroupWrapper = styled(WowDisplayFlex)`
  padding: 10px 0;
  justify-content: flex-start;

  & > div {
    margin-right: 10px;
  }
`;
