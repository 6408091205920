import { createSelector } from '@reduxjs/toolkit';
import { checklistsApi } from './schedule/components/Checklists/service';

const getSchedulingState = (state) => state.scheduling;

export const getSchedulingStatus = createSelector(
  getSchedulingState,
  (state) => state.status
);

export const getSchedulingIssues = createSelector(
  getSchedulingState,
  (state) => state.data
);

export const getSchedulesCount = createSelector(
  getSchedulingIssues,
  (issues) => issues.length
);

export const getAdvancedFilterState = createSelector(
  getSchedulingState,
  (state) => state.isAdvancedFilterOpen
);

export const getItemDensity = createSelector(
  getSchedulingState,
  (state) => state.itemDensity
);

export const getSchedulingIssuesIds = createSelector(
  getSchedulingIssues,
  (issues) => issues.map((issue) => issue.id)
);

export const getScheduleById = (id) =>
  createSelector(getSchedulingIssues, (issues) =>
    issues.find((issue) => issue.id === id)
  );

export const getSchedulingFilter = createSelector(
  getSchedulingState,
  (state) => state.filter
);

export const getIsBudgetEditingActive = createSelector(
  getSchedulingState,
  (state) => state.budget.isEditingActive
);

export const getBudgetEditingId = createSelector(
  getSchedulingState,
  (state) => state.budget.id
);

export const getSchedulingPagination = createSelector(
  getSchedulingState,
  (state) => state.pagination
);

export const getIsAdvancedFilterOpen = createSelector(
  getSchedulingState,
  (state) => state.isAdvancedFilterOpen
);

export const getAdvancedFilterCount = createSelector(
  getSchedulingFilter,
  (filter) => {
    const keys = [
      'categories',
      'assignees',
      'workspaces',
      'watchers',
      'replacements',
      'assets',
      'equipment',
    ];
    let count = 0;

    keys.forEach((key) => {
      if (filter[key] && filter[key].length > 0) {
        count++;
      }
    });

    return count;
  }
);

export const getSelectedItems = createSelector(
  getSchedulingState,
  (state) => state.selectedItems
);

export const selectIsAddChecklistFormOpen = createSelector(
  getSchedulingState,
  (state) => state.isAddChecklistFormOpen
);

const selectChecklistsResult = (id) =>
  checklistsApi.endpoints.getChecklists.select(id);

export const selectChecklists = (id) =>
  createSelector(
    selectChecklistsResult(id),
    (checklistsResult) => checklistsResult?.data
  );

export const selectAssetsViewType = createSelector(
  getSchedulingState,
  (state) => state.assetsViewType
);

export const selectDisableAssets = createSelector(
  getSchedulingState,
  (state) => state.disableAssets
);
