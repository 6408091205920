import { useCallback } from 'react';
import { useInfiniteScrollData } from '../../../../hooks/useInfinite';
import { useLazyGetUsersByWorkspaceAccessQuery } from '../service';

const useUsersByWorkspaceAccessInfinite = ({
  skip,
  customParams,
  preventReset,
  preferCacheValue,
  entityKey,
} = {}) => {
  const [getUsersByWorkspaceAccess] = useLazyGetUsersByWorkspaceAccessQuery();

  const getItems = useCallback(
    async (params) => {
      if (skip) return;

      const result = await getUsersByWorkspaceAccess(
        params,
        preferCacheValue
      ).unwrap();

      const {
        in_workspace: inWorkspace = [],
        not_in_workspace: notInWorkspace = [],
      } = result?.data ?? {};

      const formattedData = [
        ...inWorkspace,
        ...notInWorkspace.map((item) => {
          return {
            ...item,
            isNotInWorkspace: true,
          };
        }),
      ];

      return { ...result, data: formattedData };
    },
    [skip, getUsersByWorkspaceAccess, preferCacheValue]
  );

  const {
    data,
    hasMore,
    isLoading,
    handleLoadMore,
    total,
    handleSearch,
    handleDelete,
    query,
    handleUpdateQueryParams,
    handleAdd,
  } = useInfiniteScrollData({
    entityKey,
    getItems,
    skip,
    preventReset,
    customParams,
  });

  return {
    data,
    isLoading,
    handleLoadMore,
    hasMore,
    handleUpdateQueryParams,
    query,
    total,
    handleDelete,
    handleSearch,
    handleAdd,
  };
};

export default useUsersByWorkspaceAccessInfinite;
