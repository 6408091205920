import styled from 'styled-components/macro';
import { darkerGray } from '../../config/colors';

const InfiniteScrollWrapper = styled.div`
  position: relative;
  color: ${darkerGray};

  i.icon-check:before {
    font-weight: bold;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;

  div:first-child {
    position: relative;
    width: 20px;
  }
`;

export { LoaderWrapper, InfiniteScrollWrapper };
