import { FormattedMessage } from 'react-intl';
import CustomerSupportVideo from '../CustomerSupport/components/Video';
import Space from '../Space';
import { Wrapper, Icon, Title, Text, Button, ButtonIcon } from './styles';

// We show different empty screen depending on if user filtered data
// Therefore, "mode" can be "all" or "filtered"
const EmptyScreen = ({
  mode,
  icon,
  keys = {},
  canCreateNew,
  onCreateNew,
  customerSupportConfig,
}) => {
  const { title, text, actionButton } = keys;

  if (mode === 'filtered') {
    return (
      <Wrapper data-testid="emptyScreen">
        {icon ? <Icon icon={icon} /> : null}

        <Title data-testid="emptyScreenTitle">
          <FormattedMessage id="no_results_found_filters" />
        </Title>
      </Wrapper>
    );
  }

  // mode === 'all
  return (
    <Wrapper data-testid="emptyScreen">
      {icon ? <Icon icon={icon} /> : null}

      {title ? (
        <Title data-testid="emptyScreenTitle">
          <FormattedMessage id={title} />
        </Title>
      ) : null}

      {text ? (
        <Text data-testid="emptyScreenText">
          <FormattedMessage id={text} defaultMessage="" />
        </Text>
      ) : null}

      {customerSupportConfig ? (
        <CustomerSupportVideo
          entity={customerSupportConfig.entity}
          videoId={customerSupportConfig.videoId}
        />
      ) : null}

      {canCreateNew ? (
        <>
          <Space height="25" />
          <Button
            onClick={onCreateNew}
            type="button"
            data-testid="actionButton"
          >
            <ButtonIcon />
            <FormattedMessage
              id={actionButton ?? 'add_new'}
              defaultMessage="Add New"
            />
          </Button>
        </>
      ) : null}
    </Wrapper>
  );
};

export default EmptyScreen;
