import { actions } from './actions';
import { getToken } from './../auth/selectors';
import {
  getEntity,
  searchEntity,
  entityRequest,
  multipartEntityRequest,
  categoryRequest,
  clientRequest,
} from './../../services/EntityService';
import {
  getEntityPaged,
  searchEntityPaged,
  getEntityForWorkspacePaged,
  getEntityForCategoryPaged,
  getEntityGeneric,
} from './../../services/EntityServicePaged';
import { config } from '../../services/config';
import { entities } from '../../config/config';
import {
  downloadFile,
  downloadFileWithCancelation,
} from '../../services/ApiService';
import { getUserLanguage, getUserData } from '../user/selectors';
import { YU, YU_LANGS } from '../../helpers/multilang.helper';
import { getCollectionKey } from '../redux.helpers';
import isEmpty from 'lodash/isEmpty';
import {
  getCollection,
  getCurrentFilter,
  getEntitiesByIdsFromAll,
} from './coreSelectors';
import { getFilter } from '../filter/selectors';
import { setFilterFor, setUsersFilter } from '../filter/actions';
import { USER_LIST } from '../filter/filters';
import { initiateDownload } from '../../utils/utility';
import { formatDateToStandardFormat } from '../../helpers/date.helper';
import { getEntityLoadingStateByFilterValue } from './selectors';

// Started to export these function one by one
// It makes importing code a lot easier
export const fetchEntity = (entityType) => async (dispatch, getState) => {
  try {
    dispatch(actions.loadEntity(entityType));

    const response = await getEntity(getToken(getState()), entityType);

    dispatch(actions.loadEntitySuccess(entityType, response));

    return response;
  } catch (error) {
    dispatch(actions.loadEntityError(entityType, error));
  }
};
export const fetchWorkspaces = fetchEntity(entities.WORKSPACE.name);
export const fetchUsers = fetchEntity(entities.USERS.name);

export const actionCreator = {
  fetchEntity: (entityType) => async (dispatch, getState) => {
    dispatch(actions.loadEntity(entityType));

    return getEntity(getToken(getState()), entityType)
      .then((response) => {
        dispatch(actions.loadEntitySuccess(entityType, response));

        return response;
      })
      .catch((error) => actions.loadEntityError(entityType, error));
  },

  fetchEntityPaged: (entityType, page, pageSize) => (dispatch, getState) => {
    dispatch(actions.loadEntityPaged(entityType, page, pageSize));

    return getEntityPaged(getToken(getState()), entityType, page, pageSize)
      .then((response) => {
        dispatch(actions.loadEntitySuccess(entityType, response));

        return response;
      })
      .catch((error) => actions.loadEntityError(entityType, error));
  },

  searchEntity: (entityType, searchTerm) => (dispatch, getState) => {
    dispatch(actions.loadEntity(entityType));

    return searchEntity(getToken(getState()), entityType, searchTerm)
      .then((response) => {
        dispatch(actions.loadEntitySuccess(entityType, response));

        return response;
      })
      .catch((error) => actions.loadEntityError(entityType, error));
  },

  searchEntityPaged:
    (entityType, searchTerm, page, pageSize) => (dispatch, getState) => {
      dispatch(actions.loadEntityPaged(entityType, page, pageSize));

      return searchEntityPaged(
        getToken(getState()),
        entityType,
        page,
        pageSize,
        searchTerm
      )
        .then((response) => {
          dispatch(actions.loadEntitySuccess(entityType, response));

          return response;
        })
        .catch((error) => actions.loadEntityError(entityType, error));
    },

  fetchEntityWithId: (entityType, entityId) => (dispatch, getState) => {
    dispatch(actions.loadEntity(entityType, entityId));

    // TODO should use entityRequest as soon as fix entities reducer TODO :)
    return getEntity(getToken(getState()), entityType, entityId)
      .then((response) => {
        dispatch(actions.updateEntity(entityType, response, entityId));

        return response;
      })
      .catch((error) => {
        dispatch(actions.loadEntityError(entityType, error));
      });
  },

  fetchEntityWithIdWithoutPagination:
    (entityType, entityId) => (dispatch, getState) => {
      dispatch(actions.loadEntity(entityType, entityId));

      // TODO should use entityRequest as soon as fix entities reducer TODO :)
      return getEntity(getToken(getState()), entityType, entityId)
        .then((response) => {
          dispatch(actions.updateSingleEntity(entityType, response));
          dispatch(actions.entityLoaded(entityType));

          return response;
        })
        .catch((error) => {
          dispatch(actions.loadEntityError(entityType, error));
        });
    },

  entityRequest:
    (entityType, entityId, reqType, data) => (dispatch, getState) => {
      dispatch(actions.entityRequest(reqType, data));

      return entityRequest(
        getToken(getState()),
        entityType,
        entityId,
        reqType,
        data
      )
        .then((resp) => {
          dispatch(actions.entityRequestSuccess(entityType, entityId, resp));

          return resp;
        })
        .catch((e) => {
          dispatch(actions.entityRequestError(entityType, entityId, e));
        });
    },

  // Async/await
  multipartEntityRequest:
    (method, entityType, entityId, data) => (dispatch, getState) => {
      dispatch(actions.entityRequest(method, data || entityId));

      return new Promise((resolve, reject) => {
        multipartEntityRequest(
          method,
          getToken(getState()),
          entityType,
          entityId,
          data
        )
          .then((resp) => resp.json())
          .then((resp) => {
            if (!resp.success) {
              const error = 'error';

              if (error) {
                throw new Error(error);
              }
            }

            if (entityType === 'ISSUE') {
              if (method === 'DELETE') {
                dispatch(actions.invalidateCollection(entities.ISSUE));
              } else {
                dispatch(actions.updateInAll(entities.ISSUE, resp.issue));
              }
            } else {
              dispatch(actions.entityRequestSuccess(method, entityId, resp));
            }

            resolve(resp);
          })
          .catch((e) => {
            dispatch(
              actions.entityRequestError(entityType, entityId, e.message)
            );
            resolve({
              success: false,
              error: e.message,
            });
          });
      });
    },

  fetchIssueForWorkspacePaged:
    (workspaceId, page, pageSize, userId) => (dispatch, getState) => {
      dispatch(actions.loadEntityPaged(entities.ISSUE.name, page, pageSize));

      return getEntityForWorkspacePaged(
        getToken(getState()),
        workspaceId,
        page,
        pageSize,
        userId
      )
        .then((response) => {
          dispatch(actions.loadEntitySuccess(entities.ISSUE.name, response));

          return response;
        })
        .catch((error) => actions.loadEntityError(entities.ISSUE.name, error));
    },

  fetchIssueForCategoryPaged:
    (categoryId, page, pageSize) => (dispatch, getState) => {
      dispatch(actions.loadEntityPaged(entities.ISSUE.name, page, pageSize));

      return getEntityForCategoryPaged(
        getToken(getState()),
        categoryId,
        page,
        pageSize
      )
        .then((response) => {
          dispatch(actions.loadEntitySuccess(entities.ISSUE.name, response));

          return response;
        })
        .catch((error) => actions.loadEntityError(entities.ISSUE.name, error));
    },

  fetchEntityGeneric: (entityType, parameters) => (dispatch, getState) => {
    dispatch(
      actions.loadEntityPaged(entityType, parameters.page, parameters.pageSize)
    );

    return getEntityGeneric(getToken(getState()), entityType, parameters)
      .then((response) => {
        dispatch(actions.loadEntitySuccess(entityType, response));

        return response;
      })
      .catch((error) => actions.loadEntityError(entityType, error));
  },

  categoryRequest: (method, categoryId, data) => async (dispatch, getState) => {
    return categoryRequest(method, getToken(getState()), data, categoryId);
  },

  fetchCategory: () => (dispatch, getState) => {
    dispatch(actions.loadCategory());

    return categoryRequest(
      config.REQ_TYPES.GET,
      getToken(getState()),
      null,
      null
    )
      .then((response) => {
        dispatch(actions.loadCategorySuccess(response));
      })
      .catch((e) => actions.loadCategoryError(e));
  },

  fetchLang: () => async (dispatch, getState) => {
    let language = getUserLanguage(getState());

    if (YU_LANGS.includes(language)) {
      language = YU;
    }

    const languageType = entities.LANGUAGE.name;
    dispatch(actions.loadEntity(languageType));

    try {
      const response = await getEntity(null, languageType, language);
      await dispatch(actions.loadEntitySuccess(languageType, response));

      return response;
    } catch (error) {
      await dispatch(actions.loadEntityError(languageType, error));
    }
  },

  fetchClient: () => (dispatch, getState) => {
    const user = getUserData(['role', 'client_id'])(getState());

    if (user.role === 'admin') {
      const entityId = user.client_id;
      const entityType = entities.CLIENT.name;
      dispatch(actions.loadEntity(entityType));

      return getEntity(getToken(getState()), entityType, entityId)
        .then((response) => {
          dispatch(actions.loadEntitySuccess(entityType, response));

          return response;
        })
        .catch((error) => actions.loadEntityError(entityType, error));
    }
  },

  clientRequest: (method, clientId, data) => (_, getState) => {
    return clientRequest(method, getToken(getState()), data, clientId);
  },

  downloadReport:
    ({ id, fileName, fileType, requestBody, cancelationSource, isPublic }) =>
    async (_, getState) => {
      const host = `${config.URL}/api/${config.API_VERSION}${
        isPublic ? '/public' : ''
      }`;
      const action = `/${config.API.ISSUES}/${id}/download/${fileType}`;

      const response = await downloadFileWithCancelation(
        `${host}${action}`,
        getToken(getState()),
        requestBody,
        cancelationSource
      );
      const blob = await response.data;

      let fullFileName = `${fileName}.${fileType}`;

      const headers = response?.headers;
      if (headers?.['content-disposition']) {
        const splitted = headers['content-disposition'].split(';');
        const fileNameString = splitted.find(
          (item) => item.search('filename=') !== -1
        );
        if (fileNameString && fileNameString.split('=')?.[1]) {
          fullFileName = fileNameString
            .split('=')[1]
            .trim()
            .replaceAll('"', '');
        }
      }

      initiateDownload(blob, fullFileName);
    },
};

export const fetchEntityFromCacheWithFilter =
  (entityType, filterName, forceFetch, customParams) =>
  async (dispatch, getState) => {
    const state = getState();
    const filter = getFilter(filterName)(getState());
    const key = getCollectionKey(entityType.name, filter);
    const collection = getCollection(state, entityType, filter);

    const isCurrentlyLoading = getEntityLoadingStateByFilterValue(
      state,
      entityType.name,
      key
    );

    // If current filter is loading at the moment, do not refetch
    if (isCurrentlyLoading) {
      return;
    }

    if (!isEmpty(collection) && !forceFetch) {
      dispatch(actions.setCurrentParameters(entityType, filter, key));
      dispatch(setFilterFor(filterName)(collection.filter));
    } else {
      try {
        if (!forceFetch) {
          dispatch(actions.loadEntity(entityType.name, null, key));
        }

        const response = await getEntityGeneric(
          getToken(state),
          entityType.name,
          {
            ...filter,
            ...customParams,
          }
        );

        dispatch(
          actions.addToCollection(entityType, response, key, filter.parameters)
        );
        dispatch(
          setFilterFor(filterName)({
            current_page: response.current_page,
            last_page: response?.last_page,
            prev_page_url: response.prev_page_url,
            next_page_url: response.next_page_url,
            total_items: response?.total,
          })
        );
        if (!forceFetch) {
          dispatch(actions.entityLoaded(entityType.name));
        }

        return response;
      } catch (error) {
        dispatch(actions.loadEntityError(entityType.name, error));
      }
    }
  };

export const fetchEntityFromCache =
  (entityType, parameters) => async (dispatch, getState) => {
    const state = getState();
    const currentFilter = getCurrentFilter(state, entityType.reduxProp);
    const newParameters = { ...currentFilter, ...parameters };
    const collection = getCollection(state, entityType, newParameters);
    const key = getCollectionKey(entityType.name, newParameters);

    if (!isEmpty(collection)) {
      dispatch(actions.setCurrentParameters(entityType, newParameters, key));
    } else {
      dispatch(actions.loadEntity(entityType.name));

      const response = await getEntityGeneric(
        getToken(state),
        entityType.name,
        newParameters
      );

      dispatch(
        actions.addToCollection(entityType, response, key, newParameters)
      );
      dispatch(actions.entityLoaded(entityType.name));

      return response;
    }
  };

export const fetchSingleEntityFromCache =
  (entityType, id) => async (dispatch, getState) => {
    const state = getState();
    const hasEntity = getEntitiesByIdsFromAll(state, {
      entityType,
      ids: [id],
    });

    if (!hasEntity) {
      dispatch(actions.setCurrentParameters(entityType, { id }, id));
    } else {
      dispatch(actions.loadEntity(entityType.name));

      const response = await getEntity(getToken(state), entityType.name, id);

      dispatch(actions.addSingleToCollection(entityType, response, id, { id }));
      dispatch(actions.entityLoaded(entityType.name));

      return response;
    }
  };

export const updateCache = (entityType) => (dispatch, getState) => {
  dispatch(actions.invalidateCollection(entityType));
  const currentFilter = getCurrentFilter(getState(), entityType.reduxProp);
  dispatch(fetchEntityFromCache(entityType, currentFilter));
};

export const updateCacheWithFilter =
  (entityType, filterName) => (dispatch, getState) => {
    dispatch(actions.invalidateCollection(entityType));
    dispatch(fetchEntityFromCacheWithFilter(entityType, filterName));
  };

export const getUserPageUsers = () => async (dispatch, getState) => {
  const currentFilter = getFilter(USER_LIST)(getState());
  await dispatch(fetchEntityFromCacheWithFilter(entities.USERS, USER_LIST));

  await dispatch(fetchEntityFromCacheWithFilter(entities.USERS, USER_LIST));

  dispatch(setUsersFilter(currentFilter));
};

const filterExportFileName = (date, fileType) =>
  `Export_${date}_wowflow.${fileType}`;
const getFilterExportFileName = (fileType, language) =>
  filterExportFileName(
    formatDateToStandardFormat(new Date(), language),
    fileType
  );

export const exportFilters =
  ({ fileType, entityType, filterName, isSummary }) =>
  async (dispatch, getState) => {
    const host = `${config.URL}/api/${config.API_VERSION}`;
    const action = `/${config.API.ISSUES}/download/${fileType}`;

    try {
      dispatch(actions.loadEntity(entities.FILE.name));
      const state = getState();
      const filter = getFilter(filterName)(getState());
      const key = getCollectionKey(entityType.name, filter);
      const collection = getCollection(state, entityType, filter);

      if (isEmpty(collection)) {
        throw new Error('Nothing to export');
      }

      const response = await downloadFile(
        `${host}${action}?${key}${isSummary ? 'report_type=summary' : ''}`,
        getToken(getState())
      );
      const blob = await response.blob();
      const language = getUserLanguage(getState());

      initiateDownload(blob, getFilterExportFileName(fileType, language));
      dispatch(actions.entityLoaded(entities.FILE.name));
    } catch (error) {}
  };

export const exportIds =
  ({ fileType, ids }) =>
  async (dispatch, getState) => {
    const host = `${config.URL}/api/${config.API_VERSION}`;
    const action = `/${config.API.ISSUES}/download/${fileType}`;

    try {
      const response = await downloadFile(
        `${host}${action}`,
        getToken(getState()),
        {
          ids,
        }
      );

      const blob = await response.blob();
      const language = getUserLanguage(getState());

      initiateDownload(blob, getFilterExportFileName(fileType, language));
      dispatch(actions.entityLoaded(entities.FILE.name));
    } catch (error) {}
  };
