import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CheckMarkButton } from '../../checkmark-button/checkmark-button.component';
import './input.scss';
import { RenderIf } from '../../render-if/render-if.component';
import { AutocompleteAdd } from '../../../views/internal/settings/autocomplete-add';
import styled from 'styled-components/macro';

const ButtonWrapper = styled.div`
  margin-left: 4px;
`;

const onKeyPress = (e, props) => () => {
  if (
    props.enterToSubmit &&
    ((e && e.key === 'Enter') || (window.event && window.event.key === 'Enter'))
  ) {
    props.newCommentOnClick();
  }
};

export const Input = (props) => {
  const textRef = useRef(null);

  const classes = classNames('form-group', {
    'display-validation': props.displayValidation,
    'no-border': props.noBorder,
    'is-edit': props.isEdit,
    'no-label': props.noLabel,
    disabled: props.disabled,
  });

  const handleFocus = (e) => {
    if (props.onFocusText) {
      e.target.placeholder = props.onFocusText;
    }
  };

  const handleBlur = (e) => {
    if (props.onFocusText) {
      e.target.placeholder = props.onBlurText;
    }
  };

  return (
    <div className={classes}>
      <input
        data-test={`input-${props.name}`}
        data-lpignore={props.lpignore}
        id={props.name}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        required={props.required}
        disabled={props.disabled}
        readOnly={props.readOnly}
        autoFocus={props.autoFocus}
        ref={textRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={onKeyPress(window.event, props)}
        value={props.value}
        autoComplete={props.autoComplete}
        onClick={props.onClick}
      />
      <label htmlFor={props.name} className={props.className}>
        {props.labelText ? props.labelText : props.name}
      </label>
      <ButtonWrapper>
        {props.enableAutocomplete ? (
          <AutocompleteAdd textRef={textRef} enableAutocomplete />
        ) : null}
      </ButtonWrapper>
      <RenderIf
        if={props.displaySendButton}
        then={() => (
          <ButtonWrapper>
            <CheckMarkButton onClick={props.newCommentOnClick} />
          </ButtonWrapper>
        )}
      />
      <RenderIf
        if={props.errorMessage}
        then={() => (
          <span className="display-validation__error">
            {props.errorMessage}
          </span>
        )}
      />
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  noBorder: PropTypes.bool,
  displaySendButton: PropTypes.bool,
  enableAutocomplete: PropTypes.bool,
  newCommentOnClick: PropTypes.func,
  enterToSubmit: PropTypes.bool,
  changingValue: PropTypes.string,
  isEdit: PropTypes.bool,
  noLabel: PropTypes.bool,
  labelText: PropTypes.string,
  autoComplete: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  value: '',
  defaultValue: '',
  placeholder: '',
  required: false,
  disabled: false,
  readOnly: false,
  className: '',
  autoFocus: false,
  noBorder: false,
  displaySendButton: false,
  enterToSubmit: false,
  isEdit: false,
  noLabel: false,
  labelText: '',
  errorMessage: '',
};
