export const getCursorPosition = (control) => control.selectionStart;

export const getTextBeforeCursor = (control) => {
  const cursorPosition = getCursorPosition(control);

  return control.value.slice(0, cursorPosition);
};

export const getTextAfterCursor = (control) => {
  const cursorPosition = getCursorPosition(control);

  return control.value.slice(cursorPosition);
};

const styleProps = [
  'fontFamily',
  'fontSize',
  'fontWeight',
  'wordWrap',
  'whiteSpace',
  'borderLeftWidth',
  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
];

const createCopy = (textArea) => {
  const copy = document.createElement('div');
  copy.textContent = textArea.value;
  const style = getComputedStyle(textArea);

  copy.style.overflow = 'auto';
  copy.style.width = textArea.offsetWidth + 'px';
  copy.style.height = textArea.offsetHeight + 'px';
  copy.style.position = 'absolute';
  copy.style.left = textArea.offsetLeft + 'px';
  copy.style.top = textArea.offsetTop + 'px';

  styleProps.reduce((acc, key) => {
    acc[key] = style[key];

    return acc;
  }, copy.style);

  document.body.appendChild(copy);

  return copy;
};

// https://codepen.io/audinue/pen/EogPqQ
export const getCaretCoordinates = (textArea) => {
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;
  const copy = createCopy(textArea);

  if (!copy.firstChild) {
    return {
      x: 0,
      y: 0,
    };
  }

  const range = document.createRange();
  range.setStart(copy.firstChild, start);
  range.setEnd(copy.firstChild, end);
  const selection = document.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  const rect = range.getBoundingClientRect();
  document.body.removeChild(copy);
  textArea.selectionStart = start;
  textArea.selectionEnd = end;
  textArea.focus();

  return {
    x: rect.left - textArea.scrollLeft,
    y: rect.top - textArea.scrollTop,
  };
};
