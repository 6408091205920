import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import {
  PRESENCE_ALARMS_DEFAULT_VALUES,
  PRESENCE_ALARMS_MAPPER,
  REPEAT_TRANSLATIONS_KEYS,
} from './constants';
import { getHoursAndMinutes } from './ScheduleForm/PresenceAlarms/utils';

const week = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixt',
  last: 'last',
};

// This function returns a list of days in a week
// depending on a locale you provide
function getDayNames(locale = 'en', format = 'long') {
  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: format,
    timeZone: 'UTC',
  });
  const days = [1, 2, 3, 4, 5, 6, 7].map((day) => {
    const dd = day < 10 ? `0${day}` : day;
    return new Date(`2017-01-${dd}T00:00:00+00:00`);
  });

  return days.map((date) => formatter.format(date));
}

export const prepareDataBeforeRequest = (data) => {
  if (!data) return data;

  const mutatedData = { ...data };

  if (mutatedData.seasonal_break_type === 'NO') {
    mutatedData.seasonal_break_start_date = null;
    mutatedData.seasonal_break_end_date = null;
  }

  if (!mutatedData.time_limits) {
    const timeLimits = {};
    for (const item of PRESENCE_ALARMS_MAPPER) {
      timeLimits[item.dataKey] = null;
    }
    mutatedData.time_limits = timeLimits;
  }

  return mutatedData;
};

export const getRepeatOnForMonths = (intl, date) => {
  const locale = getDateLocaleForCurrentLanguage(intl);

  const parsedDate = new Date(date);
  const dayOfWeek = getDayNames(locale)[parsedDate.getDay()];
  const dayOfMonth = parsedDate.getDate();
  const weekOfMonth = getWeekOfMonth(parsedDate);
  const weekOfMonthString = week[weekOfMonth];

  return [
    {
      label: `${intl.formatMessage({
        id: 'day',
      })} ${dayOfMonth} ${intl.formatMessage({
        id: 'every',
      })} ${intl.formatMessage({
        id: 'month',
      })}`,

      value: 0,
    },
    {
      label: `${intl.formatMessage({
        id: 'every',
      })} ${intl.formatMessage({
        id: weekOfMonthString,
      })} ${dayOfWeek}`,
      value: 1,
    },
  ];
};

export const getRepeatOnForMonthsReverse = (intl, startDate, repeatOn) => {
  const locale = getDateLocaleForCurrentLanguage(intl);

  const parsedDate = new Date(startDate);
  const dayOfWeek = getDayNames(locale)[parsedDate.getDay()];
  const dayOfMonth = parsedDate.getDate();
  const weekOfMonth = getWeekOfMonth(parsedDate);
  const weekOfMonthString = week[weekOfMonth];

  if (isEqual(repeatOn?.value, [0])) {
    return { dayOfMonth };
  } else {
    return { weekOfMonthString, dayOfWeek };
  }
};

export const getDaysFromNumbers = (intl, incomingDayNumbers, format) => {
  const dayNumbers = incomingDayNumbers || [];

  const locale = getDateLocaleForCurrentLanguage(intl);

  const daysStringsArray = dayNumbers.map((dayNumber) => {
    const dayName = getDayName(locale, dayNumber, format);

    return dayName;
  });

  return daysStringsArray.join(', ');
};

const getDayName = (locale, dayNumber, format) => {
  return getDayNames(locale, format)[dayNumber];
};

export const getDayNameFirstLetter = (intl, dayNumber) => {
  const locale = getDateLocaleForCurrentLanguage(intl);

  return getDayName(locale, dayNumber).charAt(0).toUpperCase();
};

export const getDayOfMonth = (date) => {
  const parsedDate = new Date(date);
  return parsedDate.getDate();
};

export const getWeekAndDayOfMonth = (date, intl) => {
  const locale = getDateLocaleForCurrentLanguage(intl);

  const parsedDate = new Date(date);
  const dayNumberInWeek = parsedDate.getDay();
  const dayName = getDayName(locale, dayNumberInWeek);

  const weekOfMonth = getWeekOfMonthString(parsedDate, intl);

  const inString = intl.formatMessage({ id: 'in' });
  const monthString = intl.formatMessage({ id: 'month' });
  return `${weekOfMonth}. ${dayName} ${inString} ${monthString}`;
};

const getWeekOfMonthString = (date, intl) => {
  const weekOfMonth = getWeekOfMonth(date);

  if (isNaN(weekOfMonth)) return '';

  return intl.formatMessage({
    id: weekOfMonth,
  });
};

const getWeekOfMonth = (date, exact) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstWeekday = new Date(year, month, 1).getDay();
  const lastDateOfMonth = new Date(year, month + 1, 0).getDate();
  const offsetDate = date.getDate() + firstWeekday - 1;
  const index = 1; // start index at 0 or 1, your choice
  const weeksInMonth =
    index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7);
  const week = index + Math.floor(offsetDate / 7);

  if (exact || week < 2 + index) return week;

  return week === weeksInMonth ? 'last' : week;
};

// Use this function in combination with one of the options array
// to get label translated
// TODO: Move to global utils and use TSDoc to specify params
export const getOptionsWithIntl = (intl, options, format = 'none') => {
  return options.map((option) => {
    let label = intl.formatMessage({ id: option.key });

    switch (format) {
      case 'uppercase':
        label = label.toUpperCase();
        break;
      case 'lowercase':
        label = label.toLowerCase();
        break;
      case 'none':
      default:
    }

    return {
      ...option,
      label,
    };
  });
};

export const durationOptions = [
  {
    id: 1,
    key: 'minutes',
    value: 'MINUTES',
  },
  { id: 2, key: 'hours', value: 'HOURS' },
  { id: 3, key: 'days', value: 'DAYS' },
  { id: 4, key: 'weeks', value: 'WEEKS' },
  { id: 5, key: 'months', value: 'MONTHS' },
  { id: 6, key: 'years', value: 'YEARS' },
];

// Custom Function for durations because of single translation keys
export const getDurationOptionsWithIntl = (intl, options, value) => {
  const isPlural = Number(value) > 1;

  return options.map((option) => {
    const key = isPlural ? option.key : option.key.slice(0, -1);

    return {
      ...option,
      label: intl.formatMessage({ id: key }),
    };
  });
};

export const prioritiesOptions = [
  {
    key: 'urgent',
    value: 5,
  },

  {
    key: 'high',
    value: 4,
  },

  {
    key: 'normal',
    value: 3,
  },
  {
    key: 'low',
    value: 2,
  },
];

export const qrOptions = [
  {
    key: 'deactivated',
    value: 0,
  },
  {
    key: 'scan_once',
    value: 1,
  },
  {
    key: 'scan_twice',
    value: 2,
  },
];

export const endOptions = [
  {
    key: 'never',
    value: 'NEVER',
  },
  {
    key: 'after',
    value: 'AFTER',
  },
  {
    key: 'on',
    value: 'ON',
  },
];

export const SEASONAL_BREAK = [
  {
    key: 'no',
    value: 'NO',
  },
  {
    key: 'yes',
    value: 'YES',
  },
];

export const nextIssueOptions = [
  {
    key: 'on_start_date',
    value: 'ON_START_DATE',
  },
  {
    key: 'before_next_start_date',
    value: 'BEFORE_NEXT',
  },
  {
    key: 'after_marked_as_done',
    value: 'AFTER_DONE',
  },
  {
    key: 'after_completion',
    value: 'AFTER_COMPLETION',
  },
];

export const getDateLocaleForCurrentLanguage = (intl) => {
  const currentLang = intl.locale;
  return dateLocaleLanguages.find((l) => l === currentLang);
};

export const dateLocaleLanguages = ['de', 'hr', 'tr', 'pl'];

export const repeatEveryText = (scheduleInfo, intl, dayFormat = 'short') => {
  if (!scheduleInfo) {
    return '';
  }

  const repeatEveryType = scheduleInfo.repeat_every?.type;
  const repeatEveryValue = scheduleInfo.repeat_every?.value;
  const repeatOnValue = [...scheduleInfo.repeat_on].sort((a, b) => a - b);
  const locale = getDateLocaleForCurrentLanguage(intl);
  const dayNames = getDayNames(locale);
  const startDate = scheduleInfo.start_date;

  //of 0/Sunday is included, put it last
  if (repeatOnValue.includes(0)) {
    repeatOnValue.push(repeatOnValue.shift());
  }

  let text = null;

  const isPlural = Number(repeatEveryValue) > 1;
  const repeatEveryTypeKey = isPlural
    ? repeatEveryType.toLowerCase()
    : repeatEveryType?.slice(0, -1).toLowerCase();

  switch (repeatEveryType) {
    case 'MINUTES':
    case 'HOURS':
    case 'WEEKS': {
      const caseNumber = repeatOnValue.length > 1 ? 2 : 1;
      const workHoursStart = scheduleInfo.work_hours_start;
      const workHoursEnd = scheduleInfo.work_hours_end;

      text = intl.formatMessage({
        id: REPEAT_TRANSLATIONS_KEYS[repeatEveryTypeKey][caseNumber],
      });

      //replace all :day accourance from selected days
      for (const [index, dayNumber] of repeatOnValue.entries()) {
        const dayOfWeek =
          index === repeatOnValue.length - 1 && repeatOnValue.length > 1
            ? `${intl.formatMessage({
                id: 'general.and',
                defaultMessage: 'and',
              })} ${dayNames[dayNumber]}`
            : `${dayNames[dayNumber]}${
                index < repeatOnValue.length - 2 && repeatOnValue.length > 1
                  ? ','
                  : ''
              }`;
        text = text.replace(':day', dayOfWeek);
      }

      //replace the rest :day that were not selected
      text = text.replace(/:day,/g, '').replace(/:day/g, '');

      //replace :count
      text = text.replace(':count', repeatEveryValue);

      //replace :working_hours
      text = text.replace(
        ':working_hours',
        `${workHoursStart} - ${workHoursEnd}`
      );

      break;
    }
    case 'DAYS': {
      const repeatEveryExcludeWeekends =
        scheduleInfo.repeat_every?.exclude_weekends;
      const caseNumber =
        repeatOnValue.length > 1 && repeatEveryExcludeWeekends ? 2 : 1;

      text = intl.formatMessage({
        id: REPEAT_TRANSLATIONS_KEYS[repeatEveryTypeKey][caseNumber],
      });

      text = text.replace(':count', repeatEveryValue);

      break;
    }
    case 'MONTHS': {
      const parsedDate = new Date(startDate);
      const dayOfWeek = getDayNames(locale)[parsedDate.getDay()];
      const dayOfMonth = parsedDate.getDate();
      const weekOfMonth = getWeekOfMonth(parsedDate);
      const weekOfMonthString = week[weekOfMonth];
      const isFirstOptionSelected = repeatOnValue.includes(0);
      const caseNumber = isFirstOptionSelected ? 1 : 2;

      text = intl.formatMessage({
        id: REPEAT_TRANSLATIONS_KEYS[repeatEveryTypeKey][caseNumber],
      });

      if (isPlural) {
        text = text.replace(':count', repeatEveryValue);
      }

      if (isFirstOptionSelected) {
        text = text.replace(':count', dayOfMonth);
      } else {
        text = text
          .replace(':count', intl.formatMessage({ id: weekOfMonthString }))
          .replace(':day', dayOfWeek);
      }

      break;
    }
    case 'YEARS': {
      text = intl.formatMessage({
        id: REPEAT_TRANSLATIONS_KEYS[repeatEveryTypeKey],
      });

      if (isPlural) {
        text = text.replace(':count', repeatEveryValue);
      }

      text += ` ${dayjs(startDate).format('DD.MM')}`;

      break;
    }
    default:
      break;
  }

  return text;
};

export const formatListFilters = (filter, pagination) => {
  const {
    assignees,
    watchers,
    watchers_companies,
    watchers_externals,
    replacements,
    categories,
    workspaces,
    assets,
    equipment,
    assignees_companies,
    assignees_externals,
    asset_system_ids,
    asset_group_ids,
    ...filterRest
  } = filter;
  const { prevPage, nextPage, total, totalPages, ...paginationRest } =
    pagination;

  const _assignees = assignees.map((assigne) => assigne.id);
  const _assignees_companies = assignees_companies.map((item) => item.id);
  const _assignees_externals = assignees_externals.map((item) => item.id);
  const watchers_ids = watchers.map((watcher) => watcher.id);
  const watchers_companies_ids = watchers_companies.map((item) => item.id);
  const watchers_externals_ids = watchers_externals.map((item) => item.id);
  const categories_ids = categories.map((category) => category.id);
  const replacements_ids = replacements.map((replacement) =>
    Number(replacement.id)
  );
  const workspaces_ids = workspaces.map((workspace) => workspace.id);
  const assets_ids = assets.map((asset) => asset.id);
  const _asset_system_ids = asset_system_ids.map((system) => system.id);
  const _asset_group_ids = asset_group_ids.map((group) => group.id);
  const equipments_ids = equipment.map((eq) => eq.id);

  return {
    assignees: _assignees,
    assignees_companies: _assignees_companies,
    assignees_externals: _assignees_externals,
    watchers_ids,
    replacements_ids,
    categories_ids,
    workspaces_ids,
    watchers_companies_ids,
    watchers_externals_ids,
    assets_ids,
    asset_system_ids: _asset_system_ids,
    asset_group_ids: _asset_group_ids,
    equipments_ids,
    ...filterRest,
    ...paginationRest,
  };
};

export const getInitialTimes = (lastEndTime) => {
  let startTime = getRoundedDate(30);

  if (lastEndTime && startTime.isBefore(dayjs(lastEndTime))) {
    startTime = dayjs(lastEndTime);
  }

  let endTime = getRoundedDate(30);

  endTime = startTime.add(1, 'hours');

  return { start_time: startTime, end_time: endTime };
};

export const getRoundedDate = (minutes, d = new Date()) => {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  const roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

  return dayjs(roundedDate);
};

export const appendZeroIfSingleDigit = (time) => {
  return ('0' + time).slice(-2);
};

export const formatTime = (date) => {
  const newDate = dayjs(date);
  const hour = appendZeroIfSingleDigit(newDate.get('hour'));
  const minute = appendZeroIfSingleDigit(newDate.get('minute'));

  return `${hour}:${minute}`;
};

export const formatTimeSlotIntoDate = ({ start_time, end_time }) => {
  return {
    start_time: dayjs()
      .hour(getHour(start_time))
      .minute(getMinutes(start_time)),
    end_time: dayjs().hour(getHour(end_time)).minute(getMinutes(end_time)),
  };
};

const getHour = (time) => {
  return Number(time.split(':')[0]);
};

const getMinutes = (time) => {
  return Number(time.split(':')[1]);
};

export const getIsEndedSchedule = (schedule) => {
  if (!schedule) return false;

  const {
    paused,
    seasonal_break_active,
    next_issue_scheduled_at,
    end,
    statistics,
  } = schedule;

  const noNextCreationDate =
    !paused && !seasonal_break_active && next_issue_scheduled_at === null;

  const allTasksCreated =
    end.type === 'AFTER' && Number(end.value) === statistics.created;

  return noNextCreationDate || allTasksCreated;
};

export const formatPresenceAlarmsData = (timeLimits, isPresenceEnabled) => {
  if (!timeLimits) return {};

  const data = {
    ...PRESENCE_ALARMS_DEFAULT_VALUES,
    presence_alarm_enabled: isPresenceEnabled,
  };
  for (const item of PRESENCE_ALARMS_MAPPER) {
    const value = timeLimits[item.dataKey];
    if (value !== null) {
      const { hours, minutes } = getHoursAndMinutes(value);
      data[item.key] = true;
      data[`${item.key}_hours`] = hours;
      data[`${item.key}_minutes`] = minutes;
    }
  }

  return data;
};
