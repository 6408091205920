import { useSelector } from 'react-redux';
import { getEntity } from '../../../../../../selectors';
import useFilters from '../../../../../../hooks/useFilters';
import { useMemo } from 'react';
import Collapsible from '../../../Collapsible';
import { useIntl } from 'react-intl';

const Type = (props) => {
  const { value, labelKey, filterKey } = props;

  const intl = useIntl();
  const entity = useSelector(getEntity);

  const { handleSingleSelectChange } = useFilters({ entity, skip: true });

  const _value = useMemo(() => {
    const LABEL_KEYS = {
      issue: 'issues',
      task: 'time_schedule',
    };

    return [
      { id: 1, name: intl.formatMessage({ id: LABEL_KEYS[value] }), value },
    ];
  }, [intl, value]);

  const handleItemChange = (item) => {
    const newValue = item.value === value ? 'all' : item.value;
    handleSingleSelectChange(filterKey, newValue);
  };

  return (
    <Collapsible
      labelKey={labelKey}
      count={1}
      items={_value}
      handleItemChange={handleItemChange}
    />
  );
};

export default Type;
