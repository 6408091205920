import styled from 'styled-components/macro';

export const FilterActionButton = styled.button`
  display: flex;
  height: 37px;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ color }) => (color ? color : 'white')};
  background-color: white;
  box-shadow: -1px 2px 5.1px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
`;
