import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { internalRoutePaths } from '../../../../config/config';
import { CALENDAR_VIEW_TYPES } from '../../Calendar/constants';
import { getActiveView } from '../../Calendar/selectors';

const useCalendarAppContainer = () => {
  const history = useHistory();
  const activeView = useSelector(getActiveView);

  if (history?.location?.pathname === internalRoutePaths.CALENDAR) {
    const style = {
      width: '100%',
    };
    if (
      activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
    ) {
      style.maxWidth = '3500px';
    }
    return style;
  }

  return null;
};

export default useCalendarAppContainer;
