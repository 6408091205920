import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
//import axios from "axios";
import { getToken } from '../../redux/utils';
import { BASE_URL } from '../constants';
import { tagTypes } from './tagTypes';

//export const api = axios.create();

//TO DO > we can switch to axios and use built in interceptors as well

/* const _prepareHeaders = () => {

  const headers = {
    'Accept': 'application/json'
  };

  const token = getToken();
  
  if(token) headers['Authorization'] = `Bearer ${token}`;

  return headers;

};

const _axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers }) => {

    const _headers = headers ?? _prepareHeaders();

    try {

      const result = await api({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: _headers,
      });

      return { data: result.data };

    }catch(axiosError){

      //TO DO > see about error format as we go
      return {
        error: axiosError,
      };

    };

  }; */

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: tagTypes,
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) headers.set('Authorization', `Bearer ${token}`);
      headers.set('Accept', `application/json`);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
