import { createSelector } from '@reduxjs/toolkit';
import { assetsApi } from './service';

const getAssetsState = (state) => state.assets;

export const filtersSelector = (target) =>
  createSelector(getAssetsState, (state) => state.filters[target]);

export const filtersDataSelector = (target) =>
  createSelector(getAssetsState, (state) => state.filtersData[target]);

const selectAssetResult = (id) => assetsApi.endpoints.getAsset.select(id);

export const selectAsset = (id) =>
  createSelector(selectAssetResult(id), (assetsResult) => assetsResult?.data);

export const getIsAdvancedFilterOpen = createSelector(
  getAssetsState,
  (state) => state.filters.assets.isAdvancedFilterOpen
);
