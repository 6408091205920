import { CALENDAR_CARD_TYPES } from '../CalendarTimelineCard/constants';

import CardUser from '../CardUser';
import CardWorkspace from '../CardWorkspace';

export const TYPES = {
  USERS: {
    key: 'USERS',
    cardTitle: 'users',
    cardComponent: (props) => <CardUser {...props} />,
    timelineCardType: CALENDAR_CARD_TYPES.ISSUE.key,
  },
  WORKSPACES: {
    key: 'WORKSPACES',
    cardTitle: 'workspaces',
    cardComponent: (props) => <CardWorkspace {...props} />,
    timelineCardType: CALENDAR_CARD_TYPES.ISSUE.key,
  },
};

export const CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD = 20;
export const CALENDAR_FULL_DAY_BARS_WIDTH = 35;
export const WEEKLY_ITEMS_OVERLAP = 10;
export const COLUMN_NUMBER = 3;
