export const FILTER_PERSIST_KEY = 'workspace';

export const INIT_QUERY_PARAMS = {
  page: 1,
  paginate_by: 20,
  sort_by: 'name',
  sort_direction: 'asc',
};

export const INIT_QUERY_PARAMS_SUB_WSS = {
  page: '1',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
  filter_ws: null,
  search: '',
};

export const INIT_QUERY_PARAMS_FAVOURITES = {
  filter_ws: 'favourite',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
  include_subws: 0,
};

export const INIT_QUERY_PARAMS_ACTIVE = {
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
};

export const SUPPORTED_PARAMS = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  archived: {
    defaultValue: '0',
    allowedValues: ['0', '1'],
  },
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },

  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'name'],
  },

  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  include_subws: {
    defaultValue: 0,
    allowedValues: [0, 1],
  },
  session_durations: {
    defaultValue: null,
    allowedValues: null,
  },
};

// STUPID CONSTANTS
// TODO: Change !!!

const workspaceFilterKeys = {
  OPTION_ALL: '',
  OPTION_ARCHIVED: 'archived',
};

export const workspaceStatuses = [
  {
    value: workspaceFilterKeys.OPTION_ALL,
    label: {
      id: 'filter_all',
    },
  },
  {
    value: workspaceFilterKeys.OPTION_ARCHIVED,
    label: {
      id: 'archived',
    },
  },
];

//

export const workspaceSortFieldFilterKeys = {
  SORT_FIELD_OPTION_UPDATED: 'updated',
  SORT_FIELD_OPTION_CREATED: 'created',
  SORT_FIELD_OPTION_TITLE: 'name',
};

export const workspaceSortOptions = [
  {
    value: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_UPDATED,
    label: {
      id: 'updated',
    },
  },

  {
    value: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_CREATED,
    label: {
      id: 'creation',
    },
  },
  {
    value: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_TITLE,
    label: {
      id: 'name',
    },
  },
];

export const PRINT_LOGS_STYLES = `
  @page { size: auto; } @page { margin: 75px 50px 150px 50px; } @media print { body { -webkit-print-color-adjust: exact; } .infinite-scroll-component{
    height: 100% !important;
    max-height: 100% !important;
  }}
`;

export const CUSTOMER_SUPPORT_CONFIG = {
  entity: 'workspaces',
  videoId: 'GYBc3PpUKqg',
};
