import { createSlice } from '@reduxjs/toolkit';
import { DENSITY } from '../../../config/constants';

import { ISSUES_LOCALSTORAGE_KEY } from './constants';

const INIT_STATE = {
  filters: {},
  listView: {
    selectedItems: {},
    isAdvancedFilterOpen: 0,
    density: DENSITY.comfortable,
    ...(JSON.parse(localStorage.getItem(ISSUES_LOCALSTORAGE_KEY)) ?? {}),
  },
  createFormUnsavedData: null, //used to preserve unsaved create form data when user eg clicks on asset, equipment
  isAddChecklistFormOpen: false, //used to show/hide add checklist form
  isFillDynamicFormFieldsModalOpen: false,
  isAddLinksActive: false, //used to show add links modal
  showLinks: false, //used to show links section
  tabs: {
    notes: {
      pinned: [],
    },
  },
};

export const issuesSlice = createSlice({
  name: 'issues',
  initialState: INIT_STATE,
  reducers: {
    updateSelectedItems: (state, action) => {
      const { target, items } = action.payload;
      state[target] = {
        ...state[target],
        selectedItems: items,
      };
    },
    updateDensity: (state, action) => {
      state.listView.density = action.payload;
    },
    updateAdvancedFiltersMenuOpen: (state, action) => {
      state.listView.isAdvancedFilterOpen = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateCreateFormUnsavedData: (state, action) => {
      state.createFormUnsavedData = action.payload;
    },
    updateIsAddChecklistFormOpen: (state, action) => {
      state.isAddChecklistFormOpen = action.payload ?? false;
    },
    setIsFillDynamicFormFieldsModalOpen: (state, action) => {
      state.isFillDynamicFormFieldsModalOpen = action.payload ?? false;
    },
    updatePinnedNotes: (state, action) => {
      state.tabs.notes.pinned = action.payload;
    },
  },
});

export const {
  updateSelectedItems,
  updateDensity,
  updateAdvancedFiltersMenuOpen,
  setFilters,
  updateCreateFormUnsavedData,
  updateIsAddChecklistFormOpen,
  setIsFillDynamicFormFieldsModalOpen,
  updatePinnedNotes,
} = issuesSlice.actions;

export default issuesSlice.reducer;
