import React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionsMenu from '../../../../../../components/sticky-header/ActionsMenu';
import ActionButton from '../../../../../../components/sticky-header/ActionButton';
import { CustomAction, HelperText } from './styles';
import {
  WowIcon,
  WowTextBold,
} from '../../../../../../components_new/WowBasicElements';
import {
  filtersAdvancedGray,
  gradientBlue,
} from '../../../../../../config/colors';
import Space from '../../../../../../components_new/Space';
import ButtonAdd from '../../../../../../components_new/ButtonAdd';
import { SUPPORT_CONFIG, SUPPORT_TYPES } from './contants';
import { openInNewTab } from '../../../../../../utils/core';
import { getAnalytics, logEvent } from '@firebase/analytics';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../../../redux/user/selectors';

const SupportActions = () => {
  const loggedInUser = useSelector(getUser);

  const handleLogEvent = (type) => {
    const analytics = getAnalytics();
    const logKey = SUPPORT_CONFIG[type].logKey;

    logEvent(analytics, logKey, {
      wf__user_id: loggedInUser?.id,
      wf__client_id: loggedInUser?.client?.id,
    });
  };

  const handleOpenInNewTab = (type) => {
    handleLogEvent(type);

    const url = SUPPORT_CONFIG[type].url;

    openInNewTab(url);
  };

  return (
    <ActionsMenu
      icon="icon-help-questions"
      color="purple"
      position="right"
      shape="round"
      height="auto"
      callbacks={{
        handleClick: () => handleLogEvent(SUPPORT_TYPES.menu),
      }}
    >
      <ActionButton
        icon="icon-important-round"
        variant="menu-item"
        onClick={() => handleOpenInNewTab(SUPPORT_TYPES.support)}
        uppercase
      >
        <FormattedMessage id="support_center" />
      </ActionButton>
      <ActionButton
        icon="icon-video"
        variant="menu-item"
        onClick={() => handleOpenInNewTab(SUPPORT_TYPES.tutorials)}
        uppercase
      >
        <FormattedMessage id="tutorials" />
      </ActionButton>

      <CustomAction onClick={(e) => e.stopPropagation()}>
        <WowIcon color={gradientBlue} size={22} className="icon icon-pen" />
        <WowTextBold size={17}>
          <FormattedMessage id="feedback_questions" />
        </WowTextBold>

        <HelperText color={filtersAdvancedGray} size={13}>
          <FormattedMessage id="appreciate_feedback" />
        </HelperText>

        <Space height={10} />

        <ButtonAdd
          icon={null}
          translationKey="contact_us"
          appearance="contained"
          onClick={() => handleOpenInNewTab(SUPPORT_TYPES.feedback)}
        />
      </CustomAction>
    </ActionsMenu>
  );
};

export default SupportActions;
