import React from 'react';
import { useIntl } from 'react-intl';

import ModalInfo from '../../ModalInfo';
import ModalConfirm from '../../ModalConfirm';
import { WowColorizeText } from '../../WowBasicElements';
import Space from '../../Space';

import InvalidFilesList from './InvalidFilesList';

import { darkerGray } from '../../../config/colors';

const ModalInformationInvalidFiles = ({
  // confirm modal is used in case when there's some files that are valid to upload them
  isOpenConfirmModal,
  handleConfirmModalClose,
  handleConfimAction,

  // validation modal is used in case when nothing is valid
  isOpenValidationInfoModal,
  handleValidationInfoModalClose,

  // common modal props
  validationInfoModalMessage,
  localInvalidFiles,
}) => {
  const intl = useIntl();

  return (
    <>
      {isOpenValidationInfoModal ? (
        <ModalInfo
          isOpen={isOpenValidationInfoModal}
          handleAction={handleValidationInfoModalClose}
          handleClose={handleValidationInfoModalClose}
          icon="icon-important-round"
          intlValues={{}}
        >
          <>
            <WowColorizeText size="15" color={darkerGray}>
              {validationInfoModalMessage}
            </WowColorizeText>
            <Space height={localInvalidFiles.length > 1 ? '5' : '25'} />
            <InvalidFilesList items={localInvalidFiles} />
            {localInvalidFiles.length > 1 ? <Space height="25" /> : null}
          </>
        </ModalInfo>
      ) : null}

      {isOpenConfirmModal ? (
        <ModalConfirm
          isOpen={isOpenConfirmModal}
          handleClose={handleConfirmModalClose}
          handleAction={handleConfimAction}
          title={intl
            .formatMessage({ id: 'count_files_cannot_be_uploaded' })
            .replace(':count', localInvalidFiles.length)}
          actionButtonColor="green"
          actionType="continue"
        >
          <WowColorizeText size="15" color={darkerGray}>
            {validationInfoModalMessage}
          </WowColorizeText>
          <InvalidFilesList items={localInvalidFiles} />
        </ModalConfirm>
      ) : null}
    </>
  );
};

export default ModalInformationInvalidFiles;
