import styled from 'styled-components/macro';
import {
  darkerGray,
  borderGray,
  black,
  purple500,
} from '../../../config/colors';
import { themeColour } from '../../../helpers/theme';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 36px;
  position: relative;
  border: 1px solid ${borderGray};
  border-radius: 6px;
  flex-wrap: wrap;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 6px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'white'};
  ${({ type }) => type === 'modal' && 'flex-direction: row-reverse;'};
  i {
    color: ${themeColour};
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 12px;
  }
`;
export const SelectedItems = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  & > div {
    margin-right: 5px;
  }
`;

export const SelectInput = styled.input`
  display: flex;
  max-width: 100%;
  min-width: 30px;
  border: none;
  outline: none;
  flex: 2;
  padding: 5px 5px 5px 10px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'white'};
  font-size: 14px;
  color: ${darkerGray};
  font-family: 'nunitosans', 'Roboto', sans-serif;
  @-moz-document url-prefix() {
    color: ${black};
  }
  @media not all and (min-resolution: 0.001dpcm) {
    color: ${black};
    &::-webkit-input-placeholder {
      color: ${darkerGray};
    }
  }
`;

export const NoItemsLinkElem = styled.div`
  cursor: pointer;
  background-color: ${purple500};
  color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
`;
