import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { internalRoutePaths } from '../../../../config/config';
import {
  FEATURE_KEY,
  INIT_ADVANCED_FILTERS_DATA,
  INIT_QUERY_PARAMS,
} from '../constants';
import useFilters from './useFilters';

const useGoToList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { setFilters, setFiltersData } = useFilters({
    target: FEATURE_KEY,
  });

  const handleGoToList = ({ item, query, dataKey } = {}) => {
    dispatch(
      setFiltersData({
        target: FEATURE_KEY,
        filterData: {
          ...INIT_ADVANCED_FILTERS_DATA,
          [dataKey]: [
            {
              id: item.id,
              name: item.name || item.full_name,
            },
          ],
        },
      })
    );

    const queryParams = {
      ...INIT_QUERY_PARAMS,
      ...query,
      isAdvancedFilterOpen: 1,
    };

    dispatch(
      setFilters({
        target: FEATURE_KEY,
        filterData: queryParams,
      })
    );

    const stringified = qs.stringify(queryParams);

    history.push({
      pathname: internalRoutePaths.ASSETS,
      search: stringified,
    });
  };

  return {
    handleGoToList,
  };
};

export default useGoToList;
