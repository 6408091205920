import styled from 'styled-components/macro';
import { useState } from 'react';
import { white, borderLighterGray, darkerGray } from '../../config/colors';

import { useDebounce } from '../../hooks/useDebounce';
import { useEffect } from 'react';
import { themeColour } from '../../helpers/theme';

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 100px;
  height: 28px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${white};
    border: 1px solid ${borderLighterGray};
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '${(props) => props.firstText}';
    height: 22px;
    width: 50px;
    left: 4px;
    bottom: 2px;
    background: ${themeColour};
    color: ${white};
    transition: 0.4s;
    z-index: 1;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider:after {
    position: absolute;
    content: '${(props) => props.secondText}';
    height: 22px;
    width: 50px;
    right: 0px;
    bottom: 2px;
    background-color: transparent;
    color: ${darkerGray};
    transition: 0.4s;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input:checked + .slider {
    background-color: none;
  }

  input:focus + .slider {
    box-shadow: none;
  }

  input:checked + .slider:before {
    content: '${(props) => props.secondText}';
    transform: translateX(40px);
  }

  input:checked + .slider:after {
    content: '${(props) => props.firstText}';
    transform: translateX(-48px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 11px;
  }

  .slider.round:after {
    border-radius: 11px;
  }
`;

const Switch = ({
  firstText,
  secondText,
  checked = false,
  onCheck = () => {},
}) => {
  const [_checked, _setChecked] = useState(checked);

  const debouncedChecked = useDebounce(_checked);

  useEffect(() => {
    _setChecked(checked);
  }, [checked]);

  useEffect(() => {
    onCheck(debouncedChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedChecked]);

  return (
    <Label firstText={firstText} secondText={secondText} secondaryAccent>
      <input
        type="checkbox"
        checked={_checked}
        onChange={() => _setChecked((prevState) => !prevState)}
      />
      <span className="slider round"></span>
    </Label>
  );
};

export default Switch;
