import qs from 'query-string';
import { baseApi } from '../../../config/rtk';

export const collaboratorsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCollaborations: builder.query({
      query: (query) => {
        const url = qs.stringifyUrl(
          {
            url: '/collaboration',
            query: { paginate_by: 10, ...query },
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Collaborations',
                id,
              })),
              { type: 'Collaborations', id: 'LIST' },
            ]
          : [{ type: 'Collaborations', id: 'LIST' }],
    }),
    getCollaborationListNames: builder.query({
      query: (query) => {
        const url = qs.stringifyUrl(
          {
            url: '/collaboration/list-names',
            query: { paginate_by: 10, ...query },
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CollaborationListNames',
                id,
              })),
              { type: 'CollaborationListNames', id: 'LIST' },
            ]
          : [{ type: 'CollaborationListNames', id: 'LIST' }],
    }),
    updateCollaboration: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `/collaboration/${id}`,
          method: 'PUT',
          body: restData,
        };
      },
      invalidatesTags: (result, error, payload) => {
        if (payload?.refetchDetails)
          return [{ type: 'Collaborations', id: result.id }];
        return [{ type: 'Collaborations', id: 'LIST' }];
      },
    }),
    uploadCollaborationAttachments: builder.mutation({
      query(data) {
        const { id, formData } = data;
        return {
          url: `/collaboration/${id}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: 'Collaborations', id: result.id },
      ],
    }),
    getCollaboration: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `/collaboration/${id}`;
        return url;
      },
      providesTags: (result) =>
        result
          ? [{ type: 'Collaborations', id: result.id }]
          : [{ type: 'Collaborations', id: 'LIST' }],
    }),
    getCollaborationWorkspaces: builder.query({
      query: (query) => {
        const { id, ...restQuery } = query;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: `/collaboration/${id}/workspaces`,
              query: { include_subws: 0, ...restQuery },
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['list']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CollaborationWorkspaces',
                id,
              })),
              { type: 'CollaborationWorkspaces', id: 'LIST' },
            ]
          : [{ type: 'CollaborationWorkspaces', id: 'LIST' }],
    }),
    getCollaborationUsers: builder.query({
      query: (query) => {
        const { id, ...restQuery } = query;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: `/collaboration/${id}/users`,
              query: restQuery,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['list']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CollaborationUsers',
                id,
              })),
              { type: 'CollaborationUsers', id: 'LIST' },
            ]
          : [{ type: 'CollaborationUsers', id: 'LIST' }],
    }),

    getCollaborationAssetSystems: builder.query({
      query: (query) => {
        const { id, ...restQuery } = query;
        const url = qs.exclude(
          qs.stringifyUrl(
            {
              url: `/collaboration/${id}/asset-systems`,
              query: restQuery,
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
          ),
          ['list']
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'CollaborationAssetSystems',
                id,
              })),
              { type: 'CollaborationAssetSystems', id: 'LIST' },
            ]
          : [{ type: 'CollaborationAssetSystems', id: 'LIST' }],
    }),

    createCollaboration: builder.mutation({
      query(data) {
        return {
          url: `/collaboration`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: () => [{ type: 'Collaborations', id: 'LIST' }],
    }),

    // collaborators client info
    getCollaboratorClientInfo: builder.query({
      query: (query) => {
        const url = qs.stringifyUrl(
          {
            url: '/user/client_info',
            query,
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
    }),
    updateCollaborationWorkspaces: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `/collaboration/${id}/workspaces`,
          method: 'PUT',
          body: restData,
        };
      },
      invalidatesTags: () => [{ type: 'CollaborationWorkspaces', id: 'LIST' }],
    }),
    updateCollaborationAssetSystems: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `/collaboration/${id}/asset-systems`,
          method: 'PUT',
          body: restData,
        };
      },
      invalidatesTags: () => [
        { type: 'CollaborationAssetSystems', id: 'LIST' },
      ],
    }),
    addCollaborationWorkspaces: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `/collaboration/${id}/workspaces/add`,
          method: 'POST',
          body: restData,
        };
      },
      invalidatesTags: () => [{ type: 'CollaborationWorkspaces', id: 'LIST' }],
    }),
    removeCollaborationWorkspaces: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `/collaboration/${id}/workspaces/remove_access`,
          method: 'PUT',
          body: restData,
        };
      },
      invalidatesTags: () => [{ type: 'CollaborationWorkspaces', id: 'LIST' }],
    }),
    removeCollaborationAssetSystems: builder.mutation({
      query(data) {
        const { id, ...restData } = data;
        return {
          url: `/collaboration/${id}/asset-systems/remove_access`,
          method: 'PUT',
          body: restData,
        };
      },
      invalidatesTags: () => [
        { type: 'CollaborationAssetSystems', id: 'LIST' },
      ],
    }),
    getCollaborationsSimple: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'collaboration/simple', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
    }),
    createMultipleCollaborators: builder.mutation({
      query(body) {
        return {
          url: `collaboration/create-multiple`,
          method: 'POST',
          body,
        };
      },
    }),
    reinviteCollaborator: builder.mutation({
      query(id) {
        return {
          url: `collaboration/${id}/reinvite`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useGetCollaborationsQuery,
  useGetCollaborationListNamesQuery,
  useLazyGetCollaborationListNamesQuery,
  useUpdateCollaborationMutation,
  useUploadCollaborationAttachmentsMutation,
  useCreateCollaborationMutation,
  useGetCollaborationQuery,
  useGetCollaborationWorkspacesQuery,
  useGetCollaborationUsersQuery,
  useGetCollaboratorClientInfoQuery,
  useLazyGetCollaboratorClientInfoQuery,
  useUpdateCollaborationWorkspacesMutation,
  useAddCollaborationWorkspacesMutation,
  useRemoveCollaborationWorkspacesMutation,
  useLazyGetCollaborationsSimpleQuery,
  useCreateMultipleCollaboratorsMutation,
  useReinviteCollaboratorMutation,
  useGetCollaborationAssetSystemsQuery,
  useUpdateCollaborationAssetSystemsMutation,
  useRemoveCollaborationAssetSystemsMutation,
} = collaboratorsApi;
