import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_QUERY_PARAMS } from './constants';

const INITIAL_STATE = {
  filters: INITIAL_QUERY_PARAMS,
};

export const autocompleteSlice = createSlice({
  name: 'autocomplete',
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = autocompleteSlice.actions;

export default autocompleteSlice.reducer;
