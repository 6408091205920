import { FormattedMessage } from 'react-intl';
import { NoticeWithIcon } from '../../../../../components_new/Notice';
import Space from '../../../../../components_new/Space';
import Footer from '../components/Footer';
import NoItems from '../components/NoItems';
import useActivity from '../hooks/useActivity';
import TimelineItem from '../Activity/TimelineItem';
import { forwardRef } from 'react';
import { TABS } from './constants';
import Tabs from '../components/Tabs';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';

const QrCodeScanlog = forwardRef(({ type, handlePrint }, forwardedRef) => {
  const {
    scrollState,
    handleLoadMore,
    handleCustomQuery,
    isFetching,
    isLoading,
    category,
  } = useActivity({
    customParams: {
      activity_type: type,
      category: TABS[0].value,
    },
  });

  const handleChangeCategory = (value) =>
    handleCustomQuery({ category: value });

  return (
    <>
      <div ref={forwardedRef}>
        <Tabs
          tabs={TABS}
          category={category}
          handleChangeCategory={handleChangeCategory}
        />
        <Space height={24} />
        <NoticeWithIcon variant="info" icon="icon-important-round">
          <FormattedMessage id={'info_qr_scan_log'} />
        </NoticeWithIcon>
        <Space height="34" />
        {isFetching && scrollState.itemsList.length === 0 ? (
          <LoadingSpinner />
        ) : scrollState.itemsList.length === 0 ? (
          <NoItems />
        ) : (
          scrollState?.itemsList.map((item, index) => (
            <TimelineItem
              key={item.id}
              item={item}
              isLastItem={
                !scrollState.hasMore &&
                index === scrollState.itemsList.length - 1
              }
            />
          ))
        )}
      </div>
      {!isLoading && scrollState.itemsList.length > 0 ? (
        <Footer
          hasMore={scrollState.hasMore}
          handleLoadMore={handleLoadMore}
          handlePrint={handlePrint}
        />
      ) : null}
    </>
  );
});

export default QrCodeScanlog;
