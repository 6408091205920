import styled from 'styled-components/macro';
import { darkGray, darkerGray } from '../../config/colors';
import { themeColour } from '../../helpers/theme';

export const Title = styled.h4`
  color: ${darkGray};
  font-size: 22px;
  margin-bottom: 1rem;
`;

export const Message = styled.span`
  color: ${darkerGray};
`;

export const MainContent = styled.div`
  padding: 1rem 4rem;
  text-align: center;
  > div {
    position: relative;
    top: 30%;
    > svg {
      position: relative;
    }
  }
`;

export const Modal = styled.div`
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  background: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 420px;
  height: 390px;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
`;

export const ModalBackdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 995;
  background: rgba(0, 0, 0, 0.3);
`;

export const LoaderSpin = styled.div`
  width: 80px;
  position: absolute;
  left: 50%;
  top: 46%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  ${({ small }) =>
    small &&
    `
    width: 20px;
  `}

  ${({ local }) =>
    local &&
    `
    top: 0px;
    left: 0px;
    transform: none;
    -webkit-transform: none;
    position: relative;
    display: inline-block;
  `}

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    -webkit-animation: rotate 2s linear infinite;
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: ${themeColour};
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
`;
