import { internalRoutePaths } from '../config/config';

export const SECTIONS = [
  {
    id: 'workspaces',
    icon: 'icon-workspace',
    route: internalRoutePaths.WORKSPACES,
  },
  {
    id: 'issues',
    icon: 'icon-task',
    route: internalRoutePaths.ISSUES,
  },
  {
    id: 'scheduling',
    icon: 'icon-scheduling',
    route: internalRoutePaths.SCHEDULING,
  },
  {
    id: 'equipment',
    icon: 'icon-equipment',
    route: internalRoutePaths.EQUIPMENT,
    subsections: [
      {
        id: 'groups',
        route: internalRoutePaths.EQUIPMENT_GROUPS,
      },
      {
        id: 'types',
        route: internalRoutePaths.EQUIPMENT_TYPES,
      },
      {
        id: 'external_holder',
        route: internalRoutePaths.EQUIPMENT_EXTERNAL_HOLDERS,
      },
    ],
  },
  {
    id: 'assets',
    icon: 'icon-assets',
    route: internalRoutePaths.ASSETS,
    subsections: [
      {
        id: 'groups',
        route: internalRoutePaths.ASSETS_GROUPS,
      },
      {
        id: 'systems',
        route: internalRoutePaths.ASSETS_SYSTEMS,
      },
    ],
  },
  {
    id: 'categories',
    icon: 'icon-categories',
    route: internalRoutePaths.CATEGORIES,
  },
  {
    id: 'collaborators',
    icon: 'icon-collaborators',
    isBeta: false,
    route: internalRoutePaths.COLLABORATORS,
  },
  {
    id: 'users',
    icon: 'icon-people',
    route: internalRoutePaths.USERS,
  },
  {
    id: 'templates',
    icon: 'icon-dock',
    route: internalRoutePaths.TEMPLATES,
    notSelectable: true,
    subsections: [
      {
        id: 'checklists',
        route: internalRoutePaths.TEMPLATES_CHECKLISTS,
      },
      {
        id: 'autocomplete',
        route: internalRoutePaths.TEMPLATES_AUTOCOMPLETE,
      },
    ],
  },
  {
    id: 'settings',
    icon: 'icon-settings',
    route: internalRoutePaths.SETTINGS,
    notSelectable: true,
    subsections: [
      {
        id: 'general',
        route: internalRoutePaths.GENERAL,
        protected: true,
      },
      {
        id: 'pdf_reports',
        route: internalRoutePaths.PDF_REPORTS,
        protected: true,
      },
      {
        id: 'public_forms',
        route: internalRoutePaths.PUBLIC_FORMS,
        protected: true,
      },
      {
        id: 'profile',
        route: internalRoutePaths.PROFILE,
      },
    ],
  },
];
