import React from 'react';
import {
  LoaderSpin,
  ModalWrapper,
  ModalBackdrop,
  Modal,
  MainContent,
  Title,
  Message,
} from './loading-spinner-style';

export const LoadingSpinner = (props) => (
  <LoaderSpin
    mainAccent
    small={props.small || props.size === 'small'}
    local={props.isLocal}
    data-testid="loadingSpinner"
  >
    <svg viewBox="25 25 50 50" className="circular">
      <circle
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="4"
        strokeMiterlimit="10"
        className="path"
      ></circle>
    </svg>
  </LoaderSpin>
);

export const LoadingModal = (props) => (
  <ModalWrapper>
    <ModalBackdrop />
    <Modal>
      <MainContent data-testid="loadingModal">
        <LoadingSpinner {...props} />
        <Title>{props.title}</Title>
        <Message>{props.message}</Message>
      </MainContent>
      {props.children ?? null}
    </Modal>
  </ModalWrapper>
);
