import styled from 'styled-components/macro';
import { outline, gradientBlue } from '../../../../../../../config/colors';

export const Card = styled.div`
  font-size: 12px;
  padding: 3px 6px 0;
  background-color: ${outline};
  border-radius: 5px;
  height: 20px;
  color: #fff;
  position: absolute;
  z-index: 80;
  top: 0;
  width: ${({ width }) => (width ? `${width}px` : '0')};
  left: ${({ left }) => (left ? `${left}px` : '0')};
  top: 5px;
`;

export const TextWrapper = styled.div`
  color: ${gradientBlue};
  text-align: center;
  position: relative;
  bottom: 2px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: relative;
`;
