import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import Space from '../../../../../../components_new/Space';
import {
  WowDisplayFlex,
  WowLabelText,
  WowLink,
} from '../../../../../../components_new/WowBasicElements';
import {
  CtaWrapper,
  Footer,
  ProgressBar,
  SectionLeft,
  SectionRight,
  Sections,
  SubTitle,
  Title,
} from '../../styles';
import RightSectionInputPreview from '../RightSectionInputPreview';
import Input from '../../../../../../components_new/Input';
import { INPUT_COMMON_CONFIG } from '../../constants';
import Button from '../../../../../../components_new/Button';
import { userPillGray } from '../../../../../../config/colors';
import { FORM_DEFAULT_VALUES, WORKSPACE_NAME } from './contants';
import useCreateWorkspace from './hooks/useCreateWorkspace';
import { formValidation } from './utils';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';

const CreateWorkspace = () => {
  const intl = useIntl();

  const formMethods = useForm({
    resolver: vestResolver(formValidation),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, formState, register } = formMethods;
  const { isValid } = formState;

  const { isLoading, workspaceName, handleContinue, handleSkip } =
    useCreateWorkspace({ formMethods });

  return (
    <Sections>
      <SectionLeft maxWidth="400">
        <ProgressBar width="33.33%" />
        <Space height="25" />
        <Title>
          <FormattedMessage id="lets_set_up_your_first_workspace" />
        </Title>
        <SubTitle>
          <FormattedMessage id="onboarding_whats_the_building_or_area" />
        </SubTitle>
        <Space height="40" />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleContinue)}>
            <WowDisplayFlex align="flex-start" direction="column" width="330px">
              <WowLabelText bold>
                <FormattedMessage id="name_your_workspace" />
              </WowLabelText>
              <Input
                {...register(WORKSPACE_NAME)}
                {...INPUT_COMMON_CONFIG}
                placeholder={intl.formatMessage({ id: 'eg_building' })}
              />
            </WowDisplayFlex>
            <Space height="30" />
            <Footer>
              <CtaWrapper data-testid="ctaWrapper">
                <Button
                  size="small"
                  color="blue"
                  disabled={!isValid || isLoading}
                >
                  <FormattedMessage id="continue" />
                </Button>
                {isLoading ? <LoadingSpinner small /> : null}
              </CtaWrapper>
              <Space width="25" />
              <WowLink color={userPillGray} size="13" onClick={handleSkip}>
                <FormattedMessage id="skip_for_now" />
              </WowLink>
            </Footer>
          </form>
        </FormProvider>
      </SectionLeft>
      <SectionRight data-testid="sectionRight">
        <RightSectionInputPreview
          textSize="30"
          text={workspaceName}
          placeholder={intl.formatMessage({ id: 'eg_building' })}
        />
      </SectionRight>
    </Sections>
  );
};

export default CreateWorkspace;
