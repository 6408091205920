import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '../../../../redux/app/actions';
import { getLoggedUserData } from '../../../../redux/user/action-creator';
import { actions as userActions } from '../../../../redux/user/actions';
import { surveyFirstSeenAt } from '../../../../services/SurveyService';
import { usePostSurveyMutation } from '../service';

const useSurvey = (user) => {
  const [showSurvey, setShowSurvey] = useState(false);
  const dispatch = useDispatch();
  const { forceShowSurveyModal } = useSelector((state) => state.app.toJS());
  const triggered = useRef(false);

  const hideSurvey = () => {
    dispatch(appActions.setForceSurveyShowModal(false));
    if (triggered.current) setShowSurvey(false);
  };

  const [postSurvey] = usePostSurveyMutation();
  const handleSaveSurvey = async (payload) => {
    dispatch(userActions.updateUser({ survey_completed: true }));
    return await postSurvey(payload);
  };

  useEffect(() => {
    const {
      survey_completed,
      survey_dismissed_at,
      survey_first_seen_at,
      created_at,
    } = user;
    const newUserThreshold = created_at ? new Date(created_at) : null;
    if (newUserThreshold)
      newUserThreshold.setDate(newUserThreshold.getDate() + 1);
    const now = new Date();
    const allowedShowStartTime = new Date();
    allowedShowStartTime.setHours(12, 0, 0, 0);
    const allowedShowEndTime = new Date();
    allowedShowEndTime.setHours(17, 0, 0, 0);
    const consecutiveDaysThreshold = survey_first_seen_at
      ? new Date(survey_first_seen_at)
      : null;
    let lastDismissedDate = survey_dismissed_at
      ? new Date(survey_dismissed_at)
      : null;
    if (lastDismissedDate) lastDismissedDate = lastDismissedDate.getDate();
    if (consecutiveDaysThreshold)
      consecutiveDaysThreshold.setDate(consecutiveDaysThreshold.getDate() + 12);

    if (
      false && //Hardcoded survey stop. Delete this line of code to enable survey again. (This won't stop notification show)
      user &&
      !showSurvey &&
      !survey_completed &&
      (forceShowSurveyModal ||
        (!triggered.current &&
          now >= allowedShowStartTime &&
          now <= allowedShowEndTime &&
          newUserThreshold &&
          newUserThreshold < now &&
          (!survey_first_seen_at ||
            (survey_first_seen_at &&
              consecutiveDaysThreshold &&
              now < consecutiveDaysThreshold &&
              (!lastDismissedDate || lastDismissedDate !== now.getDate())))))
    ) {
      triggered.current = true;
      setShowSurvey(true);
      if (!survey_first_seen_at) {
        const at = new Date();
        surveyFirstSeenAt(at.getTime() / 1000)
          .then((res) => {
            if (res && res.success) {
              dispatch(
                userActions.updateUser({ survey_first_seen_at: new Date() })
              );
              dispatch(getLoggedUserData());
            }
          })
          .catch(() => {});
      }
    }
  }, [user, showSurvey, forceShowSurveyModal, dispatch]);

  return [showSurvey, hideSurvey, handleSaveSurvey];
};

export default useSurvey;
