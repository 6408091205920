export const DEFAULT_PARAMS = {
  page: 1,
  paginate_by: 50,
};

export const RTK_HOOK_CONFIG = {
  refetchOnMountOrArgChange: true,
  refetchOnFocus: false,
};

export const NOTIFICATIONS_INFINITE_SCROLL_INITIAL_STATE = {
  query: {
    ...DEFAULT_PARAMS,
  },
  itemsList: [],
  hasMore: true,
  lastPage: null,
};
