import { apiCall } from './ApiService';

import { config } from './config';
import { getToken } from '../redux/utils';

export const authHeader = (authToken) => ({
  Authorization: `Bearer ${authToken}`,
});

const surveyFirstSeenAtEndpoint = `${config.URL}/api/${config.API_VERSION}/${config.API.SURVEY}/set_first_seen_at`;
const surveyDismissedAtEndpoint = `${config.URL}/api/${config.API_VERSION}/${config.API.SURVEY}/set_dismissed_at`;

export const surveyFirstSeenAt = (at) => {
  return apiCall(
    config.REQ_TYPES.POST,
    surveyFirstSeenAtEndpoint,
    config.CONTENT_TYPES.APPLICATION_JSON,
    { survey_first_seen_at: at },
    { ...authHeader(getToken()) }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    });
};

export const surveyDismissedAt = (at) => {
  return apiCall(
    config.REQ_TYPES.POST,
    surveyDismissedAtEndpoint,
    config.CONTENT_TYPES.APPLICATION_JSON,
    { dismissed_at: at },
    { ...authHeader(getToken()) }
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw new Error(response.error);
      }

      return response;
    });
};
