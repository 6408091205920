import { useDispatch, useSelector } from 'react-redux';
import { getEntity } from '../../../selectors';
import useFilters from '../../../hooks/useFilters';
import { updatePersistedFilters } from '../../../../../redux/config/actions';
import { ACTIVE_ISSUE_STATUSES, ARCHIVED_ISSUE_STATUSES } from '../constants';
import { useMemo } from 'react';

const useStatus = () => {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);
  const FILTER_KEY = 'issue_state';

  const { persistedFilters, handleFilterReset, formatStringFilter } =
    useFilters({
      entity,
      skip: true,
    });

  const handleStatusChange = (statusKey, value, allStatuses) => {
    const currentString =
      persistedFilters.parameters?.[FILTER_KEY]?.[statusKey] ?? '';
    const isSelectAll = !allStatuses.includes(value);
    const newString = isSelectAll
      ? allStatuses.toString() !== currentString
        ? allStatuses.toString()
        : null
      : formatStringFilter(currentString, value);

    const newParameters = {
      ...persistedFilters.parameters,
      [FILTER_KEY]: {
        ...(persistedFilters.parameters[FILTER_KEY] || {}),
        [statusKey]: newString?.length ? newString : null,
      },
    };

    if (
      newParameters[FILTER_KEY].active === null &&
      newParameters[FILTER_KEY].archived === null
    ) {
      delete newParameters[FILTER_KEY];
    }

    dispatch(updatePersistedFilters(newParameters, entity));
  };

  const handleActiveStatusChange = (value) => {
    handleStatusChange('active', value, ACTIVE_ISSUE_STATUSES);
  };

  const handleArchivedStatusChange = (value) => {
    handleStatusChange('archived', value, ARCHIVED_ISSUE_STATUSES);
  };

  const selectedActiveStatus =
    persistedFilters?.parameters?.[FILTER_KEY]?.active ?? '';
  const selectedArchivedStatus =
    persistedFilters?.parameters?.[FILTER_KEY]?.archived ?? '';

  const countSelected = useMemo(() => {
    const selectedActiveStatusLength =
      selectedActiveStatus?.length > 0
        ? selectedActiveStatus?.split(',')?.length
        : 0;
    const selectedArchivedStatusLength =
      selectedArchivedStatus?.length > 0
        ? selectedArchivedStatus?.split(',')?.length
        : 0;
    return selectedActiveStatusLength + selectedArchivedStatusLength;
  }, [selectedActiveStatus, selectedArchivedStatus]);

  const isResetDisabled = countSelected === 0;

  return {
    handleFilterReset,
    handleActiveStatusChange,
    handleArchivedStatusChange,
    selectedActiveStatus,
    selectedArchivedStatus,
    countSelected,
    isResetDisabled,
  };
};

export default useStatus;
