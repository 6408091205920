import styled from 'styled-components/macro';

import {
  darkerGray,
  outline,
  newRed,
  gradientBlue,
  userGray,
} from '../../config/colors';
import Button from '../Button';

export const ModalWrapper = styled.div`
  flex-grow: 1;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled.div`
  display: flex;
  position: relative;
  ${({ justify }) => (justify ? 'justify-content: space-between' : null)};

  button {
    position: relative;
  }
`;

export const ModalFooterButtonWrapper = styled.div`
  position: relative;

  ${({ width }) => (width ? `width: ${width}` : null)};

  > div {
    right: 0px;
    left: auto;
    top: 50%;
  }
`;

export const InputCounterDropdownWrapper = styled.div`
  min-width: 170px;
  display: flex;
  align-items: center;
  color: ${darkerGray};
  button {
    border-radius: 4px;
    font-size: 16px;
    min-width: 170px;
  }
`;

export const ModalDatePickerWrapper = styled.div`
  .react-datepicker {
    width: 100%;
    box-shadow: none !important;
  }

  .react-datepicker__month-container {
    width: 100%;

    .react-datepicker__month {
      padding: 18px 0;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }
  }

  .react-datepicker__input-time-container {
    padding-left: 0;
  }
`;

export const NoItems = styled.div`
  padding: 80px 0 100px 0;
  display: flex;
  justify-content: center;
  color: #456279;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
`;

export const NoItemsIcon = styled.i`
  font-size: 70px;
  color: ${outline} !important;
  line-height: 1;
  margin-bottom: 10px;
`;

export const TextErrorWrapper = styled.div`
  color: ${newRed};
  font-size: 14px;
  display: flex;
`;

export const InfoWrapper = styled.div`
  color: ${darkerGray};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '30px'};
  font-size: 14px;
`;

export const LinkWithArrowWrapper = styled.span`
  color: ${({ color }) => (color ? color : gradientBlue)};
  padding-left: ${({ spaceLeft }) => (spaceLeft ? spaceLeft + 'px' : '0px')};
  font-size: ${({ size }) => (size ? size + 'px' : 'inherit')};

  :hover {
    cursor: pointer;
  }

  i::before {
    transform: rotate(90deg);
    font-size: 18px;
    color: ${({ color }) => (color ? color : gradientBlue)};
  }
`;

export const StyledButton = styled(Button)`
  width: 136px;
`;

export const ModalHeading = styled.div`
  font-size: 22px;
  color: ${userGray};
  font-weight: 600;
`;
