import React from 'react';
import styled from 'styled-components/macro';

import { darkerGray, outline } from '../../config/colors';
import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';
import { MenuItem, MenuItems, Menu } from '../Menu';
import { themeColour } from '../../helpers/theme';

const QuickFilterWrapper = styled.div`
  position: relative;
`;

const QuickFilterButton = styled.button`
  padding: 6px 14px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  color: ${darkerGray};

  &:focus,
  &:hover {
    outline: none;
    background-color: ${outline};
  }
`;

const Selection = styled.span`
  color: ${(props) => themeColour(props)};
`;

const QuickFilterItem = ({ selected, onClick, children }) => {
  return (
    <MenuItem
      onClick={onClick}
      selected={selected}
      tabIndex={0}
      mainAccent
      data-testid="menuItem"
    >
      {children} {selected ? <i className="icon icon-check" /> : null}
    </MenuItem>
  );
};

const QuickFilter = ({ placeholder, onChange, options, value, position }) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  useKeyPress('Escape', () => setIsVisible(false));

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleChange = (value) => () => {
    setIsVisible(false);

    onChange(value);
  };

  const selected = options.find((option) => option.value === value);

  const getButtonText = () => {
    if (selected?.value === '')
      return (
        <>
          {placeholder ? `${placeholder} ` : null}
          <Selection mainAccent>
            <i className="icon icon-arrowdown-02" />
          </Selection>
        </>
      );

    return (
      <>
        {placeholder ? `${placeholder}: ` : null}
        <Selection mainAccent>
          {selected?.label} <i className="icon icon-arrowdown-02" />
        </Selection>
      </>
    );
  };

  return (
    <QuickFilterWrapper ref={ref}>
      <QuickFilterButton onClick={handleClick} data-testid="quickFilterButton">
        {getButtonText()}
      </QuickFilterButton>

      {isVisible ? (
        <Menu position={position} data-testid="menu">
          <MenuItems>
            {options.map((option) => (
              <QuickFilterItem
                onClick={handleChange(option.value)}
                key={option.value}
                selected={option.value === selected?.value}
              >
                {option.label}
              </QuickFilterItem>
            ))}
          </MenuItems>
        </Menu>
      ) : null}
    </QuickFilterWrapper>
  );
};

export default QuickFilter;
