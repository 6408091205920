import { useIntl } from 'react-intl';
import Modal from '../../../Modal';
import { Option, OptionLabel, OptionPhoto } from '../View/styles';
import { DEFAULT_PROFILE_IMAGE } from '../../../../config/constants';
import InfoMessage from '../InfoMessage';
import { WowColorizeText, WowDisplayFlex } from '../../../WowBasicElements';
import { darkerGray } from '../../../../config/colors';
import Space from '../../../Space';
import ModalFooterWrapper from '../ModalFooterWrapper';
import useAddToWorkspace from '../../hooks/useAddToWorkspace';

const ModalAddToWorkspace = (props) => {
  const {
    isOpen,
    onClose,
    handleAddSuccessCallback,
    titleKey,
    localSelectedItems,
    workspaceName,
    workspaceIds,
  } = props;

  const intl = useIntl();

  const { itemsNotInWorkspace, formattedInfoMessages, handleSave, isLoading } =
    useAddToWorkspace({
      localSelectedItems,
      workspaceName,
      workspaceIds,
      handleAddSuccessCallback,
      onClose,
    });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hasTitleIcon
      iconClass="icon-arrowleft"
      title={intl.formatMessage({ id: titleKey })}
      maxHeight="750px"
    >
      {itemsNotInWorkspace.map((item) => {
        const { id, image, label } = item;
        return (
          <Option key={id}>
            <OptionPhoto src={image || DEFAULT_PROFILE_IMAGE} />
            <OptionLabel>{label}</OptionLabel>
          </Option>
        );
      })}

      {formattedInfoMessages ? (
        <InfoMessage>
          <WowDisplayFlex direction="column">
            <WowColorizeText size="14" color={darkerGray}>
              {formattedInfoMessages.message}
            </WowColorizeText>
            <Space height="15" />
            <WowColorizeText size="14" color={darkerGray}>
              {formattedInfoMessages.secondaryMessage}
            </WowColorizeText>
          </WowDisplayFlex>
        </InfoMessage>
      ) : null}

      <Space height="20" />

      <ModalFooterWrapper
        handleCancel={onClose}
        isActionDisabled={isLoading}
        handleAction={handleSave}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default ModalAddToWorkspace;
