import { useCallback } from 'react';

const useHandlers = ({ data, handleEdit }) => {
  const handleDeleteDataItem = useCallback(
    (id, indexData, month) => {
      const item = data[indexData];
      const mutatedEvents = [...item.events];

      const eventIndex = mutatedEvents.findIndex((evt) => +evt.month === month);

      if (eventIndex !== -1) {
        const event = mutatedEvents[eventIndex];
        const filteredData = event.data.filter((item) => item.id !== id);

        mutatedEvents[eventIndex] = { ...event, data: filteredData };

        const newItem = { ...item, events: mutatedEvents };

        handleEdit([newItem]);
      }
    },
    [data, handleEdit]
  );

  const handleUpdateDataItem = useCallback(
    (issue, indexData, month) => {
      //if not quick complete action no need to update
      if (!issue?.isQuickComplete) return;

      //if quick complete update status
      const item = data[indexData];
      const mutatedEvents = [...item.events];

      const eventIndex = mutatedEvents.findIndex((evt) => +evt.month === month);

      if (eventIndex !== -1) {
        const event = mutatedEvents[eventIndex];

        const updatedData = event.data.map((item) => {
          return item.id === issue.id
            ? {
                ...item,
                displayType: issue?.status?.type,
                statusId: issue?.status?.id,
              }
            : item;
        });

        mutatedEvents[eventIndex] = { ...event, data: updatedData };

        const newItem = { ...item, events: mutatedEvents };

        handleEdit([newItem]);
      }
    },
    [data, handleEdit]
  );

  return {
    handleDeleteDataItem,
    handleUpdateDataItem,
  };
};

export default useHandlers;
