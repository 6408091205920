import { useEffect } from 'react';
import {
  INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
} from '../../../../../../config/constants';
import useInfiniteScrollData from '../../../../Equipment/hooks/useInfiniteScrollData';
import { useGetCalendarUnscheduledQuery } from '../../../service';
import {
  INFINITE_SCROLL_INITIAL_STATE_QUERY,
  UNSCHEDULED_FILTERS_TABS,
} from '../constants';

const useNoDate = ({ skipQuery, calendarQueryParams } = {}) => {
  const {
    scrollState,
    handleLoadMore,
    handleStateUpdate,
    handleCustomQuery,
    deleteManuallyFromListItems,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE_QUERY,
      sort_by: UNSCHEDULED_FILTERS_TABS.NO_DATE.sortOptions[0].value,
    },
    id: 'calendarNoDate',
    skipTransformItemsForDropdown: true,
  });

  const {
    data: fetchedData,
    isLoading,
    isFetching,
  } = useGetCalendarUnscheduledQuery(
    { ...calendarQueryParams, ...scrollState.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: skipQuery || !calendarQueryParams,
    }
  );

  //NOTE: as useInfiniteScrollData hook does not clear state properly is some cases we do it here untill hook is refactored.
  //this is needed so loader is properly shown when query params are changed
  useEffect(() => {
    if (calendarQueryParams) {
      handleCustomQuery({});
    }
  }, [calendarQueryParams, handleCustomQuery]);

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate, handleCustomQuery]);

  return {
    scrollState,
    handleLoadMore,
    isProcessing: isLoading || isFetching,
    handleCustomQuery,
    deleteManuallyFromListItems,
  };
};

export default useNoDate;
