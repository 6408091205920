import styled from 'styled-components/macro';
import {
  darkerGray,
  hardWhite,
  outline,
  lighterGray,
  lightererGray,
} from '../../../../config/colors';

export const Wrapper = styled.div`
  width: ${({ isOpen, isHover }) => {
    if (!isOpen && isHover) return '16rem';
    if (isOpen) return '16rem';
    return '4.5rem';
  }};
  background-color: #ffffff;
  top: 0;
  bottom: 0;
  padding: 100px 0 0;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : '0'};
  z-index: 152;
  border-right: 1px solid ${outline};
  transition: width 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  overflow: auto;

  ::-webkit-scrollbar {
    height: 10px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${lighterGray};
    border-radius: 100px;
    height: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${lightererGray};
  }
`;

export const ArrowButton = styled.button`
  background-color: ${hardWhite};
  border-radius: 50%;
  border: none;
  width: 32px;
  padding: 4px 7px;
  align-self: flex-end;
  transform: ${({ isOpen }) => (isOpen ? '' : 'rotate(180deg)')};
  transition: transform 0.2s;
  margin-bottom: 24px;
  margin-right: 20px;
  cursor: pointer;
`;

export const Arrow = styled.i.attrs({ className: 'icon icon-arrowleft-02' })`
  color: ${darkerGray};

  font-size: 18px;
`;

export const Space = styled.div`
  height: 100%;
  transition: width 0.4s ease;

  width: ${({ isOpen, isHover }) => {
    if (!isOpen && isHover) return '16rem';
    if (isOpen) return '16rem';
    return '4.5rem';
  }};
`;

export const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
`;
