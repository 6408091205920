import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../../../../../../components_new/Button';
import { purple } from '../../../../../../../config/colors';
import {
  Wrapper,
  Icon,
  ErrorHeader,
  ErrorMessage,
  ButtonWrapper,
  SecondaryButton,
} from './styles';

const ExportError = ({ onCancel }) => {
  const t = useIntl();

  return (
    <Wrapper data-testid="exportError">
      <Icon className="icon icon-sad-emoji" data-testid="icon" />
      <ErrorHeader data-testid="header">
        {t.formatMessage({
          id: 'export_failed_error',
        })}
      </ErrorHeader>
      <ErrorMessage data-testid="message">
        {t.formatMessage({
          id: 'export_failed_message',
        })}
      </ErrorMessage>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={onCancel}
          color="success"
          appearance="outlined"
          size="small"
          data-testid="actionButton"
        >
          <FormattedMessage id="ok" />
        </Button>
      </ButtonWrapper>
      <ButtonWrapper style={{ marginBottom: 0 }}>
        <SecondaryButton
          color={purple}
          onClick={() => (window.location = 'mailto:hello@wowflow.com')}
        >
          <FormattedMessage id={'contact_us'} />
        </SecondaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

ExportError.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ExportError;
