import { createSelector, createSlice } from '@reduxjs/toolkit';

const IS_SIDEBAR_EXPANDED_DEFAULT = window.innerWidth < 1350 ? false : true;

export const contentAreaSlice = createSlice({
  name: 'contentArea',
  initialState: {
    isSidebarExpanded: IS_SIDEBAR_EXPANDED_DEFAULT,
  },
  reducers: {
    setIsSidebarExpanded: (state, action) => {
      state.isSidebarExpanded = action.payload;
    },
  },
});

export const { setIsSidebarExpanded } = contentAreaSlice.actions;

const getContentAreaState = (state) => state.contentArea;

export const getIsSidebarExpanded = createSelector(
  getContentAreaState,
  (state) => state.isSidebarExpanded
);

export default contentAreaSlice.reducer;
