import { useCallback, useMemo } from 'react';
import Collapsible from '../../../Collapsible';
import useStatus from '../../../../../FilterStatus/hooks/useStatus';
import {
  ACTIVE_ISSUE_STATUSES_OPTIONS,
  ARHIVED_ISSUE_STATUSES_OPTIONS,
} from '../../../../../FilterStatus/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import Item from '../../../Collapsible/Item';
import { WowColorizeText } from '../../../../../../../WowBasicElements';
import Space from '../../../../../../../Space';

const Status = (props) => {
  const { value } = props;
  const intl = useIntl();

  const {
    handleActiveStatusChange,
    handleArchivedStatusChange,
    countSelected,
  } = useStatus();

  const formatOptions = useCallback(
    (options, values) => {
      return options
        .filter((item) => values?.includes(item.value))
        .map((item) => ({
          ...item,
          name: intl.formatMessage({ id: item.labelKey }),
        }));
    },
    [intl]
  );

  const selectedOptions = useMemo(() => {
    const activeOptions = formatOptions(
      ACTIVE_ISSUE_STATUSES_OPTIONS,
      value.active
    );
    const archivedOptions = formatOptions(
      ARHIVED_ISSUE_STATUSES_OPTIONS,
      value.archived
    );

    return {
      active: activeOptions,
      archived: archivedOptions,
    };
  }, [formatOptions, value.active, value.archived]);

  return (
    <Collapsible labelKey="status" count={countSelected}>
      {selectedOptions.active?.length ? (
        <WowColorizeText fWeight="700" size="14">
          <FormattedMessage id="active" />
        </WowColorizeText>
      ) : null}

      {selectedOptions.active.map((item) => (
        <Item
          key={item.id}
          item={item}
          handleItemChange={(item) => handleActiveStatusChange(item.value)}
        />
      ))}
      <Space height="10" />
      {selectedOptions.archived?.length ? (
        <WowColorizeText fWeight="700" size="14">
          <FormattedMessage id="archived" />
        </WowColorizeText>
      ) : null}
      {selectedOptions.archived.map((item) => (
        <Item
          key={item.id}
          item={item}
          handleItemChange={(item) => handleArchivedStatusChange(item.value)}
        />
      ))}
    </Collapsible>
  );
};

export default Status;
