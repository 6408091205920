export const COLORS = {
  1: '#FE332B',
  2: '#FF871F',
  3: '#FFC364',
  4: '#FFE431',
  5: '#FFEE7C',
  6: '#EFEF77',
  7: '#E2ED57',
  8: '#D7DE1F',
  9: '#C5D500',
  10: '#73D500',
};
