import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { appendFormDataWithAttachments } from '../../../../components_new/MediaInput/util';
import {
  EQUIPMENT_INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
  STATUS_OPTIONS,
} from '../constants';

import {
  useGetEquipmentListQuery,
  useGetEquipmentQuery,
  useCreateEquipmentMutation,
  useUpdateEquipmentMutation,
  useUpdateEquipmentAttachmentsMutation,
} from '../service';
import useInfiniteScrollData from './useInfiniteScrollData';

const useEquipment = (skipEquipmentSingleItem = false, params = {}) => {
  const {
    scrollState,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    handleCustomQuery,
  } = useInfiniteScrollData({
    ...EQUIPMENT_INFINITE_SCROLL_INITIAL_STATE,
    id: 'equipment',
    skipTransformItemsForDropdown: true,
  });

  const history = useHistory();
  const { id: equipmentIdFromUrl } = useParams();

  const [
    updateEquipmentAttachments,
    { isLoading: isLoadingUpdateEquipmentAttachments },
  ] = useUpdateEquipmentAttachmentsMutation();

  const [createEquipment, { isLoading: isLoadingCreateEquipment }] =
    useCreateEquipmentMutation();

  const {
    data: equipment,
    isLoading: isLoadingEquipment,
    isFetching: isFetchingEquipment,
    refetch: refetchEquipment,
  } = useGetEquipmentQuery(equipmentIdFromUrl || 1, {
    ...RTK_HOOK_CONFIG,
    skip: skipEquipmentSingleItem || !equipmentIdFromUrl,
  });

  const [updateEquipment, { isLoading: isLoadingUpdateEquipment }] =
    useUpdateEquipmentMutation();

  const _handleGoTo = (id) => {
    const route = `/dashboard/equipment/detail/${id}`;

    history.push({
      pathname: route,
      state: { fromPath: history?.location.pathname },
    });
  };

  const handleFormSubmit = async (data) => {
    const {
      attachments = [],
      return_date,
      isReturnDateMandatory,
      ...restData
    } = data;
    const formData = new FormData();

    appendFormDataWithAttachments(formData, attachments);

    if (equipmentIdFromUrl) {
      await updateEquipment({
        ...restData,
        id: equipmentIdFromUrl,
      });

      if (attachments.length > 0) {
        updateEquipmentAttachments({
          id: equipmentIdFromUrl,
          formData,
        });
      }
    } else {
      const response = await createEquipment({
        ...restData,
        status: STATUS_OPTIONS.stored.value,
      });

      if (response?.data?.success) {
        const { id } = response.data.equipment;

        if (attachments.length > 0) {
          const responseUpdateAttachments = await updateEquipmentAttachments({
            id,
            formData,
          });

          //temp due to response typo, 'sucess' instead of 'success'
          if (
            responseUpdateAttachments?.data?.success ||
            responseUpdateAttachments?.data?.sucess
          ) {
            _handleGoTo(id);
          }
        } else {
          _handleGoTo(id);
        }
      }
    }
  };

  const {
    data: fetchedData,
    isLoading,
    isFetching,
  } = useGetEquipmentListQuery(
    { ...scrollState.query },
    {
      RTK_HOOK_CONFIG,
      skip: params?.skip ? params.skip : false,
    }
  );

  useEffect(() => {
    handleStateUpdate(fetchedData);
  }, [fetchedData, handleStateUpdate]);

  //hide/show unused options such as, serial number, group etc.
  const [unusedOptions, setUnusedOptions] = useState({
    serial_number: false,
    group: false,
  });

  useEffect(() => {
    if (equipment) {
      const targetFields = ['serial_number', 'group'];
      let unusedOptionsFormated = {};

      for (let field of targetFields) {
        if (equipment[field] === null) {
          unusedOptionsFormated[field] = true;
        }
      }

      setUnusedOptions((prevState) => ({
        ...prevState,
        ...unusedOptionsFormated,
      }));
    }
  }, [equipment, setUnusedOptions]);

  const handleSetUnusedOptions = (target) => {
    setUnusedOptions((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  return {
    handleFormSubmit,
    isLoadingCreateEquipment,
    equipment,
    isLoadingEquipment,
    isFetchingEquipment,
    refetchEquipment,
    isLoadingUpdateEquipment,
    isEdit: equipmentIdFromUrl ? true : false,
    isLoadingUpdateEquipmentAttachments,
    unusedOptions,
    handleSetUnusedOptions,

    scrollState,
    handleLoadMore,
    handleSearch,
    isLoading,
    isFetching,
    handleStateUpdate,
    mergeStateItems,
    handleCustomQuery,
  };
};

export default useEquipment;
