import { useState, useEffect, useMemo } from 'react';
import {
  MOBILE_APP_LINKS,
  MOBILE_OS,
} from '../../../../../../../config/config';
import { getMobileOS } from '../../../../../../../utils/utility';
import { TRANSLATION_KEYS } from '../constants';
import { StyledLink } from '../styles';

const useMobileTrial = () => {
  const os = getMobileOS();

  const handleLinkClick = () => {
    window.setTimeout(() => {
      if (os === MOBILE_OS.ANDROID)
        window.location.href = MOBILE_APP_LINKS.ANDROID;
      if (os === MOBILE_OS.IOS) window.location.href = MOBILE_APP_LINKS.IOS;
    }, 100);
  };

  const [translationKeys, setTranslationKeys] = useState({});

  useEffect(() => {
    if (TRANSLATION_KEYS[os]) setTranslationKeys(TRANSLATION_KEYS[os]);
  }, [os]);

  const store = useMemo(() => {
    return (
      <StyledLink href={MOBILE_APP_LINKS[os.toUpperCase()]}>
        App Store
      </StyledLink>
    );
  }, [os]);

  return {
    handleLinkClick,
    translationKeys,
    store,
  };
};

export default useMobileTrial;
