import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { merge } from 'lodash-es';
import { getOnboardingProgress } from '../../../../../redux/config/selectors';
import { useUpdateStepsMutation } from '../service';
import { updateOnboardingProgress } from '../../../../../redux/config/actions';
import {
  getShowOnboardingProcess,
  getShowOnboardingSuccessMobile,
} from '../../selectors';
import { STEP_TYPES } from '../constants';
import { setShowOnboardingProcess } from '../../slice';
import { getLoggedUserData } from '../../../../../redux/user/action-creator';

const useOnboarding = () => {
  const onboardingProgress = useSelector(getOnboardingProgress);
  const dispatch = useDispatch();
  const [updateSteps, { isLoading }] = useUpdateStepsMutation();
  const forceShowOnboardingProcess = useSelector(getShowOnboardingProcess);
  const showOnboardingScussessMobile = useSelector(
    getShowOnboardingSuccessMobile
  );

  const currentStep = useMemo(() => {
    const { steps = [], progress = {} } = onboardingProgress ?? {};

    for (const item of steps) {
      const { step } = item;
      const { isSkipped, isCompleted, isForceSkipped } = progress[step];

      if (
        forceShowOnboardingProcess &&
        isSkipped &&
        !isForceSkipped &&
        !isCompleted
      )
        return step;
      if (showOnboardingScussessMobile) return STEP_TYPES.mobileTrial;

      if (!isCompleted && !isSkipped) {
        return step;
      }
    }

    return null;
  }, [
    forceShowOnboardingProcess,
    onboardingProgress,
    showOnboardingScussessMobile,
  ]);

  const formatForceSkippedHubSpotSteps = (steps, stepType) => {
    const hubSpotSteps = steps.slice(1, 4);
    const progress = {};

    const index = hubSpotSteps.findIndex((item) => item.step === stepType);

    if (index !== -1) {
      const stepsAfterForceSkipped = hubSpotSteps.slice(index);

      for (const item of stepsAfterForceSkipped) {
        progress[item.step] = { isForceSkipped: true };
      }
    }

    return { progress };
  };

  const closeOnboarding = () => {
    const { steps = [], progress = {} } = onboardingProgress ?? {};

    const mutatedProgress = { ...progress };

    for (const item of steps) {
      delete mutatedProgress[item.step].isForceSkipped;
    }

    dispatch(
      updateOnboardingProgress({
        ...onboardingProgress,
        progress: mutatedProgress,
      })
    );

    dispatch(setShowOnboardingProcess());
  };

  const handleUpdateSteps = async (data, callback) => {
    if (isLoading) return;

    try {
      const response = await updateSteps(data).unwrap();

      if (response) {
        if (data.stepType === STEP_TYPES.welcome) {
          dispatch(getLoggedUserData());
        }

        //when open from top banner
        if (forceShowOnboardingProcess) {
          const { steps = [], progress = {} } = onboardingProgress ?? {};
          const { isSkipped, stepType } = data ?? {};

          const lastThreeSteps = steps.slice(-3);

          const countLastThreeStepsCompleted = lastThreeSteps.reduce(
            (count, item) => {
              return count + (progress[item.step]?.isCompleted ? 1 : 0);
            },
            0
          );

          const lastThreeStepsCompleted =
            countLastThreeStepsCompleted === lastThreeSteps.length;

          //skip action
          if (isSkipped) {
            const isOneOfTheLastTheeeStepsSkipped = lastThreeSteps.some(
              (item) => item.step === stepType
            );

            if (isOneOfTheLastTheeeStepsSkipped || lastThreeStepsCompleted) {
              closeOnboarding();

              return;
            }

            const newOnboardingProgressWhenSkip = merge(
              {},
              response,
              formatForceSkippedHubSpotSteps(steps, stepType)
            );

            dispatch(updateOnboardingProgress(newOnboardingProgressWhenSkip));

            return;
          }

          //continue action
          if (
            (lastThreeStepsCompleted && stepType === STEP_TYPES.features) ||
            stepType === STEP_TYPES.assignIssue
          ) {
            closeOnboarding();
          }

          const newOnboardingProgressWhenContinue = merge(
            {},
            onboardingProgress,
            response
          );

          dispatch(updateOnboardingProgress(newOnboardingProgressWhenContinue));

          return;
        }

        //onboarding after signup
        if (callback) callback();
        dispatch(updateOnboardingProgress(response));
      }
    } catch (error) {
      return {
        errors: error?.data?.errors,
      };
    }
  };

  const wrapperHeight = useMemo(
    () => (currentStep === STEP_TYPES.features ? 'auto' : null),
    [currentStep]
  );

  return {
    currentStep,
    handleUpdateSteps,
    isLoading,
    firstSteps: onboardingProgress?.firstSteps,
    wrapperHeight,
  };
};

export default useOnboarding;
