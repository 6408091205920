import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import { gray, darkerGray, purple500, newBorder } from '../../config/colors';
import dict from '../../config/multilang';

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  height: 20px;
  background-color: transparent;
  color: ${darkerGray};
  font-size: 15px;
  padding: 0 18px 2px 2px;

  width: 120px;
  @media (min-width: 1200px) {
    width: 170px;
  }

  border-bottom: 2px solid;
  border-color: ${(props) => (props.value ? purple500 : newBorder)};

  &::placeholder {
    color: ${gray};
  }

  &:focus {
    outline: none;
    border-bottom-color: ${purple500};
  }
`;

const Icon = styled.i`
  position: absolute;
  right: 2px;
  color: ${gray};
`;

const HoverableIcon = styled(Icon)`
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    color: ${purple500};
  }
`;

const Search = ({ value, onChange, onClear, ...rest }) => {
  const intl = useIntl();

  const handleKeyPress = (event) => {
    event.preventDefault();

    const { key } = event;

    if (key === 'Enter') {
      onClear();
    }
  };

  return (
    <InputWrapper>
      <Input
        placeholder={intl.formatMessage({
          id: 'search',
          defaultMessage: dict.search,
        })}
        value={value}
        onChange={onChange}
        {...rest}
      />

      {!value || onClear === undefined ? (
        <Icon className="icon icon-zoom" />
      ) : (
        <HoverableIcon
          className="icon icon-close"
          onClick={onClear}
          onKeyPress={handleKeyPress}
          tabIndex={0}
          data-testid="clearButton"
        />
      )}
    </InputWrapper>
  );
};

export default Search;
