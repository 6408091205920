import styled from 'styled-components/macro';
import {
  greenButtonColor,
  redButtonColor,
  white,
  dialogConfirm,
  redButtonColorHover,
  lighterGray,
  darkGray,
  newBorder,
  purple500,
  gradientBlue,
} from '../config/colors';
import { shadeHexColor } from './colorShade';
import { coloursStorageKey, getData } from '../redux/utils';
import { isNil } from 'lodash-es';
const TRANSPARENT = 'transparent';
const SHADE_AMOUNT = -0.2;

/**
 * Applies the colur according to the theme
 * There should always be a colour in the store and in the theme
 * Pass down props to use colours:
 * * secondaryAccent - use accent colour
 * * colourSecondary - use secondary
 * * mainAccent - use main accent colour
 * * main colour is the default one
 * @param {Object} props
 */
export const themeColour = (props) => {
  if (props.disabled) {
    return lighterGray;
  }

  if (!props.isHovering && (props.hoverBorderButton || props.labelButton)) {
    return TRANSPARENT;
  }

  const hasActiveProp = !isNil(props.active);

  if (hasActiveProp && !props.active) {
    return lighterGray;
  }

  if (props.noTheme) {
    if (props.dialogConfirm) {
      return dialogConfirm;
    }

    if (props.green) {
      return props.isHovering && props.hoverDarken
        ? shadeHexColor(greenButtonColor, SHADE_AMOUNT)
        : greenButtonColor;
    }

    if (props.red) {
      return props.isHovering && props.hoverDarken
        ? redButtonColorHover
        : redButtonColor;
    }

    return props.color || darkGray;
  }

  if (props.secondaryAccent) {
    return props.isHovering && props.hoverDarken
      ? shadeHexColor(props.theme.secondaryAccent, SHADE_AMOUNT)
      : props.theme.secondaryAccent;
  }

  if (props.colourSecondary) {
    return props.isHovering && props.hoverDarken
      ? shadeHexColor(props.theme.secondary, SHADE_AMOUNT)
      : props.theme.secondary;
  }

  if (props.mainAccent || props.$mainAccent) {
    return props.theme.mainAccent;
  }

  if (props.grayAccent) {
    return newBorder;
  }

  if (Array.isArray(props.theme.main)) {
    return props.isHovering && props.reverseGradient
      ? `linear-gradient(${props.theme.main[1]}, ${props.theme.main[0]})`
      : `linear-gradient(${props.theme.main[0]}, ${props.theme.main[1]})`;
  }

  return props.isHovering
    ? shadeHexColor(props.theme.main, SHADE_AMOUNT)
    : props.theme.main;
};

export const themeWithDefault = (defaultPropName) => (props) => {
  if (defaultPropName && props[defaultPropName]) {
    return props[defaultPropName];
  }

  return themeColour(props);
};

export const ThemedIcon = styled.i`
  color: ${themeColour};
`;

export const themedFont = (props) => {
  if (props.hoverBorderButton || props.labelButton) {
    if (!isNil(props.active) && !props.active) {
      return lighterGray;
    }

    if (props.green) {
      return greenButtonColor;
    }

    if (props.red) {
      return redButtonColor;
    }

    return darkGray;
  }

  return white;
};

const savedColorsData = getData(coloursStorageKey);

export const { mainAccent = purple500, secondaryAccent = gradientBlue } =
  savedColorsData ?? {};
