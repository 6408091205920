import vest, { enforce, test } from 'vest';
import { INPUT_MAX_LENGTH } from '../../constants';

export const formValidation = vest.create((data = {}) => {
  const { firstName, lastName, company } = data;

  test('firstName', 'first name can not be empty', () => {
    enforce(firstName).isNotEmpty();
  });

  test('firstName', 'first name max 255 characthers', () => {
    enforce(firstName).shorterThanOrEquals(INPUT_MAX_LENGTH);
  });

  test('lastName', 'last name can not be empty', () => {
    enforce(lastName).isNotEmpty();
  });

  test('lastName', 'last name max 255 characters', () => {
    enforce(lastName).shorterThanOrEquals(INPUT_MAX_LENGTH);
  });

  test('company', 'company can not be empty', () => {
    enforce(company).isNotEmpty();
  });

  test('company', 'company max 255 characters', () => {
    enforce(company).shorterThanOrEquals(INPUT_MAX_LENGTH);
  });
});
