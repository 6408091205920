import { Fragment, useMemo, useState } from 'react';
import Modal from '../../../Modal';
import Fields from '../Fields';
import { WowLabelText, WowLink } from '../../../WowBasicElements';
import { FormattedMessage, useIntl } from 'react-intl';
import Space from '../../../Space';
import { Dropdown } from '../../../Dropdown';
import ModalFooterWrapper from '../ModalFooterWrapper';
import useAdd from '../../hooks/useAdd';
import { AddItemsWrapper, StyledDropdownWrapper } from '../../styles';
import {
  COLLABORATORS_PERMISSION_TYPE_OPTIONS,
  DEFAULT_COLLABORATOR,
  COLLABORATORS_PERMISSION_TYPE_VALUES,
  COLLABORATORS_PERMISSION_TYPE_INFO_KEYS,
  COLLABORATORS_PERMISSION_TYPE_ICONS,
  MODE,
} from '../../constants';
import {
  useCreateMultipleCollaboratorsMutation,
  useLazyGetCollaboratorClientInfoQuery,
} from '../../../../views/internal/Collaborators/service';
import InfoMessage from '../InfoMessage';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../redux/user/selectors';

const ModalAddCollaborator = (props) => {
  const {
    isOpen,
    onClose,
    workspaceIds,
    mode,
    handleAddSuccessCallback,
    canInviteLimitedCollaboratorsOnly,
  } = props;

  const intl = useIntl();
  const loggedInUser = useSelector(getUser);
  const isCollabEnabled = loggedInUser?.client?.settings?.include_collab;

  const [getCollaboratorClientInfo] = useLazyGetCollaboratorClientInfoQuery();

  const {
    users,
    canSubmit,
    errors,
    handleAdd,
    handleReset,
    handleChange,
    handleValidateUserEmail,
    handleDelete,
    formatNewUsersPayload,
    showCollaboratorHasAccount,
    collaboratorHasAccount,
  } = useAdd(DEFAULT_COLLABORATOR, getCollaboratorClientInfo);

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const [permissionType, setPermissionType] = useState(
    COLLABORATORS_PERMISSION_TYPE_VALUES.limited
  );

  const permissionTypeOptions = useMemo(() => {
    return COLLABORATORS_PERMISSION_TYPE_OPTIONS.map((item) => ({
      ...item,
      label: intl.formatMessage({ id: item.labelKey }),
    }));
  }, [intl]);

  const [createMultipleCollaborators, { isLoading }] =
    useCreateMultipleCollaboratorsMutation();

  const handleSave = async () => {
    const payload = {
      collab_type: permissionType,
      new_collaborations: formatNewUsersPayload(),
      workspace_ids: workspaceIds,
    };

    const response = await createMultipleCollaborators(payload);

    if (response?.data?.success) {
      const newUsers = response.data.new_collaborations.reduce((acc, item) => {
        return [...acc, ...item.users];
      }, []);

      handleAddSuccessCallback(newUsers);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      hasTitleIcon
      iconClass="icon-arrowleft"
      title={intl.formatMessage({ id: 'invite_collaborator' })}
      maxHeight="750px"
      dataTestId="modalAddCollaborators"
    >
      <WowLabelText bold>
        <FormattedMessage id="permission_type" />
      </WowLabelText>
      <StyledDropdownWrapper>
        <Dropdown
          value={permissionType}
          icon={COLLABORATORS_PERMISSION_TYPE_ICONS[permissionType]}
          options={permissionTypeOptions}
          borderRadius="4px"
          size="responsive"
          onChange={(option) => {
            const { value } = option;
            setPermissionType(value);
          }}
          disabled={
            mode === MODE.multi ||
            canInviteLimitedCollaboratorsOnly ||
            !isCollabEnabled
          }
        />
      </StyledDropdownWrapper>

      {!showCollaboratorHasAccount ? (
        <InfoMessage
          messageKey={COLLABORATORS_PERMISSION_TYPE_INFO_KEYS[permissionType]}
        />
      ) : null}

      <AddItemsWrapper height="300">
        {users.map((item) => {
          const { id } = item;
          return (
            <Fragment key={id}>
              <Fields
                {...item}
                handleChange={(field) => handleChange(id, field)}
                handleValidateUserEmail={() => handleValidateUserEmail(item)}
                errors={errors}
                handleDelete={handleDelete}
              />
              {collaboratorHasAccount[id] ? (
                <InfoMessage messageKey="not_administrator_email" />
              ) : null}
            </Fragment>
          );
        })}
      </AddItemsWrapper>

      {mode === MODE.multi ? (
        <WowLink fWeight="700" uppercase onClick={handleAdd}>
          + <FormattedMessage id="add" />
        </WowLink>
      ) : null}

      <Space height="20" />
      <ModalFooterWrapper
        handleCancel={handleClose}
        isActionDisabled={!canSubmit}
        handleAction={handleSave}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default ModalAddCollaborator;
