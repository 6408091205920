import { createSelector } from 'reselect';

const getMainState = (state) => state.main;

export const getIsLoadingSwitchClient = createSelector(
  getMainState,
  (state) => state.isLoadingSwitchClient
);

export const getNotificationsUnreadCount = createSelector(
  getMainState,
  (state) => state.notificationsUnreadCount
);

export const getShowOnboardingSuccess = createSelector(
  getMainState,
  (state) => state.showOnboardingSuccess
);

export const getShowOnboardingProcess = createSelector(
  getMainState,
  (state) => state.showOnboardingProcess
);

export const getShowOnboardingSuccessMobile = createSelector(
  getMainState,
  (state) => state.showOnboardingSuccessMobile
);

export const getIsLoadingUser = createSelector(
  getMainState,
  (state) => state.isLoadingUser
);
