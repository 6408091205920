import styled from 'styled-components/macro';
import { purple500 } from '../../../../../config/colors';

export const Avatar = styled.img`
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 100%;
`;

export const ProgressBar = styled.div`
  background-color: #e6d9fc;
  border-radius: 10px;
  width: 100%;
  height: 12px;
  padding: 3px;
`;

export const ProgresBarInner = styled.div`
  height: 6px;
  width: ${({ width }) => (width ? width + '%' : '0')};
  background-color: ${purple500};
  border-radius: 10px;
`;
