export const TASK_CREATION_OPTIONS = [
  {
    id: 1,
    key: 'for_each_asset',
    value: 'FOR EACH ASSET',
  },
  {
    id: 2,
    key: 'one_for_all_assets',
    value: 'ONE FOR ALL ASSETS',
  } /* ,
  {
    id: 3,
    key: 'subtask_per_asset',
    value: 'SUBTASK PER ASSET',
  }, */,
];

export const REPEAT_TRANSLATIONS_KEYS = {
  minute: {
    1: 'schedule_minute_every_day_single',
    2: 'schedule_minute_every_day_multiple',
  },
  minutes: {
    1: 'schedule_minute_multiple_day_single',
    2: 'schedule_minute_mulitple_day_multiple',
  },
  hour: {
    1: 'schedule_hour_every_day_single',
    2: 'schedule_hour_every_day_multiple',
  },
  hours: {
    1: 'schedule_hour_multiple_day_single',
    2: 'schedule_hour_multiple_day_multiple',
  },
  day: {
    1: 'schedule_day_every',
    2: 'schedule_day_every_no_weekend',
  },
  days: {
    1: 'schedule_day_multiple',
    2: 'schedule_day_multiple_no_weekend',
  },
  week: {
    1: 'schedule_week_every_day_single',
    2: 'schedule_week_every_day_multiple',
  },
  weeks: {
    1: 'schedule_week_multiple_day_single',
    2: 'schedule_week_multiple_day_multiple',
  },
  month: {
    1: 'schedule_month_every_day_number',
    2: 'schedule_month_every_weekday_number',
  },
  months: {
    1: 'schedule_month_multiple_day_number',
    2: 'schedule_month_multiple_weekday_number',
  },
  year: 'schedule_year_every',
  years: 'schedule_year_multiple',
};

export const PRESENCE_ALARMS_DEFAULT_VALUES = {
  presence_alarm_enabled: false,
  earlier_than_start_time: false,
  earlier_than_start_time_hours: 0,
  earlier_than_start_time_minutes: 0,
  later_than_start_time: false,
  later_than_start_time_hours: 0,
  later_than_start_time_minutes: 0,
  earlier_than_end_time: false,
  earlier_than_end_time_hours: 0,
  earlier_than_end_time_minutes: 0,
  later_than_end_time: false,
  later_than_end_time_hours: 0,
  later_than_end_time_minutes: 0,
};

export const PRESENCE_ALARMS_MAPPER = [
  {
    id: 1,
    key: 'earlier_than_start_time',
    dataKey: 'assignee_already_logged',
  },
  {
    id: 2,
    key: 'later_than_start_time',
    dataKey: 'assignee_did_not_login_yet',
  },
  {
    id: 3,
    key: 'earlier_than_end_time',
    dataKey: 'assignee_logged_out_earlier',
  },
  {
    id: 4,
    key: 'later_than_end_time',
    dataKey: 'assignee_still_logged_after_completion',
  },
];
