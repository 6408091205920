import { useEffect, useMemo, useRef } from 'react';

import { useGetUsersQuery } from '../service';

import useInfiniteScrollData from '../hooks/useInfiniteScrollData';
import {
  INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
} from '../../../../config/constants';

const useUsers = (skipQuery, customParams, forceInitOverride) => {
  const {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    handleStateUpdate,
    mergeStateItems,
    handleCustomQuery,
    updateManuallyListItems,
  } = useInfiniteScrollData({
    ...INFINITE_SCROLL_INITIAL_STATE,
    query: {
      ...INFINITE_SCROLL_INITIAL_STATE.query,
      filter_status: ['active', 'invited'],
    },
    id: 'users',
  });

  const forceItemsOverride = useRef(false);

  const params = useMemo(() => {
    return {
      ...scrollState.query,
      ...customParams,
    };
  }, [scrollState?.query, customParams]);

  const {
    data: fetchedData,
    isFetching,
    isLoading,
    refetch: refetchData,
  } = useGetUsersQuery(params, {
    ...RTK_HOOK_CONFIG,
    skip: skipQuery || false,
  });

  const refetch = () => {
    forceItemsOverride.current = true;
    refetchData();
  };

  useEffect(() => {
    const _forceInitOverride =
      forceInitOverride && fetchedData?.current_page === 1;
    handleStateUpdate(
      fetchedData,
      forceItemsOverride.current,
      _forceInitOverride
    );
    forceItemsOverride.current = false;
  }, [fetchedData, forceInitOverride, handleStateUpdate]);

  return {
    scrollState,
    parseFetchedData,
    handleLoadMore,
    handleSearch,
    isFetching,
    mergeStateItems,
    isLoading,
    handleCustomQuery,
    refetch,
    updateManuallyListItems,
  };
};

export default useUsers;
