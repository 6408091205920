import { useState } from 'react';

const useModalState = (initialValue) => {
  const [isVisible, setIsVisible] = useState(initialValue);
  const toggleModalState = () => {
    setIsVisible(!isVisible);
  };
  return { isVisible, toggleModalState };
};

export default useModalState;
