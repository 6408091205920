import { FormattedMessage } from 'react-intl';
import Space from '../../../Space';
import { WowCheckbox } from '../../../../components/wow-checkbox';
import { ButtonWrappers } from '../../styles';
import Button from '../../../Button';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import {
  ItemsBox,
  LabelBox,
  ColorWrapper,
  PointLabel,
  ColorBox,
} from './styles';
import { COLORS } from './constants';
import { COLORS as SMILEY_COLORS } from '../SmileyScale/constants';

export const TenScale = (props) => {
  const {
    value,
    onChange = () => {},
    next = () => {},
    disableNext = false,
    isLast = false,
    isProcessing,
  } = props;

  const dots = [];
  for (let i = 1; i <= 10; i++) {
    if (value === i) {
      dots.push(
        <div key={i} onClick={() => onChange(i)}>
          <ColorWrapper>
            <WowCheckbox
              borderRadius={30}
              size={30}
              checked={true}
              shadowColor={SMILEY_COLORS.purple}
              iconPadding="4px 3px 3px 3px"
              onChange={() => {}}
            />
          </ColorWrapper>
          <PointLabel>{i}</PointLabel>
        </div>
      );
    } else {
      dots.push(
        <div key={i} onClick={() => onChange(i)}>
          <ColorWrapper>
            <ColorBox color={COLORS[String(i)]} />
          </ColorWrapper>
          <PointLabel>{i}</PointLabel>
        </div>
      );
    }
  }

  return (
    <>
      <Space height="30" />
      <ItemsBox>{dots}</ItemsBox>
      <Space height="10" />
      <LabelBox>
        <div>
          <FormattedMessage id={'not_likely'} />
        </div>
        <div>
          <FormattedMessage id={'very_likely'} />
        </div>
      </LabelBox>
      <Space height="50" />
      <ButtonWrappers>
        <Button
          color="green"
          appearance="contained"
          size="small"
          style={{ width: 170 }}
          onClick={next}
          disabled={disableNext || isProcessing}
        >
          {isLast ? (
            <FormattedMessage id={'complete'} />
          ) : (
            <FormattedMessage id={'next'} />
          )}
          {isProcessing && <LoadingSpinner />}
        </Button>
      </ButtonWrappers>
      <Space height="20" />
    </>
  );
};

export default TenScale;
