import { useEffect } from 'react';
import { useParams } from 'react-router';

import {
  INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
} from '../../../../../config/constants';
import useInfiniteScrollData from '../../../Equipment/hooks/useInfiniteScrollData';
import { useGetUserPresenceQuery } from '../../../users/service';

const useQrTimelog = ({ skipQuery, customParams } = {}) => {
  const { scrollState, handleLoadMore, handleStateUpdate, handleCustomQuery } =
    useInfiniteScrollData({
      ...INFINITE_SCROLL_INITIAL_STATE,
      query: {
        page: 1,
        paginate_by: 10,
      },
      id: 'qrTimelog',
      skipTransformItemsForDropdown: true,
    });

  const { id: userIdFromUrl } = useParams();
  const {
    data: fetchedData,
    isFetching,
    isLoading,
  } = useGetUserPresenceQuery(
    { userId: userIdFromUrl, ...(customParams || {}), ...scrollState?.query },
    {
      ...RTK_HOOK_CONFIG,
      skip: !userIdFromUrl || skipQuery ? true : false,
    }
  );

  useEffect(() => {
    if (fetchedData) {
      handleStateUpdate(fetchedData);
    }
  }, [fetchedData, handleStateUpdate]);

  return {
    scrollState,
    handleLoadMore,
    isFetching,
    isLoading,
    totalTime: fetchedData?.total_time_spent,
    handleCustomQuery,
  };
};

export default useQrTimelog;
