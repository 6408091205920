import styled from 'styled-components/macro';
import { darkerGray } from '../../../../config/colors';

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 25px;
  margin-bottom: 0;
`;

export const Item = styled.li`
  color: ${darkerGray};
  font-size: 14px;
  text-align: left;
  margin-bottom: 5px;
`;
