import styled from 'styled-components/macro';
import { darkerGray, purple500 } from '../../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 50px;
  & img {
    width: 200px;
  }
`;

export const InnerWrapper = styled.div`
  width: 460px;
`;

export const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(55, 70, 95, 0.1);
  padding: 3.5rem;
  background-color: white;
  text-align: center;
`;

export const Title = styled.h1`
  color: ${darkerGray};
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const CtaButton = styled.button`
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.33px;
  padding: 12px 0px;
  border-radius: 8px;
  text-transform: uppercase;
  width: 155px;
  height: 40px;
  background-color: ${purple500};
  font-size: 12px;
  color: white;
`;
