import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { issueSingleApi } from '../../views/internal/IssuesV2/Issue/service';
import { workspaceApi } from '../../views/internal/workspaces/service';
import { getToken } from '../../redux/utils';
import { BASE_URL } from '../../config/constants';

export const translatesApi = createApi({
  reducerPath: 'translatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL + '/translate',
    prepareHeaders: (headers) => {
      const token = getToken();

      if (token) headers.set('Authorization', `Bearer ${token}`);
      headers.set('Accept', `application/json`);

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    issueTranslate: builder.mutation({
      query(params) {
        const { issueId, body } = params;
        return {
          url: `/issue/${issueId}`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted({ issueId, body }, { dispatch, queryFulfilled }) {
        try {
          // We are manually changing redux(cache) here so we don't have additional fetches on rtk side and we can cause flickering. Since we have translations in the response, we will just use it and set it in the corresponding Issue Object just like BE will return to us. At the moment of writing this feature, BE is caching translations in the "available_translations" as object of "title" and "description" keys. If they exist, there is no need for fetching new translations. If there is no translations in that object, we hit this endpoint and manually update our local cache. Next time we fetch Issue object it will be populated just like we do here.
          const { data: updatedTranslation } = await queryFulfilled;
          dispatch(
            issueSingleApi.util.updateQueryData(
              'getIssue',
              String(issueId ?? ''),
              (draft) => {
                if (updatedTranslation?.success) {
                  const updatePayload = {
                    available_translations: draft?.available_translations
                      ? JSON.parse(JSON.stringify(draft.available_translations))
                      : {},
                  };
                  if (body?.field === 'title') {
                    updatePayload.available_translations.title =
                      updatedTranslation.trans;
                  } else if (body?.field === 'description') {
                    updatePayload.available_translations.description =
                      updatedTranslation.trans;
                  }
                  Object.assign(draft, updatePayload);
                }
              }
            )
          );
        } catch {}
      },
    }),
    workspaceTranslate: builder.mutation({
      query(params) {
        const { workspaceId, body } = params;
        return {
          url: `/workspace/${workspaceId}`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(
        { workspaceId, body },
        { dispatch, queryFulfilled }
      ) {
        try {
          // We are manually changing redux(cache) here so we don't have additional fetches on rtk side and we can cause flickering. Since we have translations in the response, we will just use it and set it in the corresponding Workspace Object just like BE will return to us. At the moment of writing this feature, BE is caching translations in the "available_translations" as object of "name" and "description" keys. If they exist, there is no need for fetching new translations. If there is no translations in that object, we hit this endpoint and manually update our local cache. Next time we fetch Workspace object it will be populated just like we do here.
          const { data: updatedTranslation } = await queryFulfilled;
          dispatch(
            workspaceApi.util.updateQueryData(
              'getWorkspace',
              String(workspaceId ?? ''),
              (draft) => {
                if (updatedTranslation?.success) {
                  const updatePayload = {
                    available_translations: draft?.available_translations
                      ? JSON.parse(JSON.stringify(draft.available_translations))
                      : {},
                  };
                  if (body?.field === 'name') {
                    updatePayload.available_translations.name =
                      updatedTranslation.trans;
                  } else if (body?.field === 'description') {
                    updatePayload.available_translations.description =
                      updatedTranslation.trans;
                  }
                  Object.assign(draft, updatePayload);
                }
              }
            )
          );
        } catch {}
      },
    }),
  }),
});

export const { useIssueTranslateMutation, useWorkspaceTranslateMutation } =
  translatesApi;
