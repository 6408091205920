import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { internalRoutePaths } from '../../../../../../../config/config';
import {
  useSwitchClientMutation,
  useGetUserStatisticsQuery,
} from '../../../../service';
import { useDispatch, useSelector } from 'react-redux';
import { getNewUser } from '../utils';
import { getIsLoadingSwitchClient } from '../../../../selectors';
import { getUser } from '../../../../../../../redux/user/selectors';
import { setIsLoadingSwitchClient } from '../../../../slice';

import { parseOgranizations } from '../utils';

import { RTK_HOOK_CONFIG } from '../../../../../../../config/constants';

const useSwithClient = ({ skipQuery } = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoadingSwitchOrganisation = useSelector(getIsLoadingSwitchClient);

  const loggedUser = useSelector(getUser);

  // collab feature active
  const collabFeatureActive = !!loggedUser?.client?.settings?.include_collab;

  const [
    switchClient,
    { isLoading: isLoadingSwitchClient, isSuccess: isSuccessSwitchClient },
  ] = useSwitchClientMutation();

  const { data: userStatistics } = useGetUserStatisticsQuery(null, {
    skip: skipQuery,
    ...RTK_HOOK_CONFIG,
  });

  const [parsedOrganizations, setParsedOrganizations] = useState({
    main: {},
    current: {},
    available: [],
  });

  // useEffect hook
  // to parse available organisations
  useEffect(() => {
    if (userStatistics?.clients) {
      const { main, current, available } = parseOgranizations(
        userStatistics.clients
      );
      setParsedOrganizations({
        main,
        current,
        available,
      });
    }
  }, [userStatistics]);

  const _getDataWithRedirect = useCallback(
    async (url) => {
      const route = url ?? internalRoutePaths.ISSUES;
      history.replace(route);
      history.go(0);
    },
    [history]
  );

  const handleSwitchClient = useCallback(
    async (id, callback, url) => {
      if (isLoadingSwitchClient) return;
      const response = await switchClient({ client_id: id });
      if (response?.data?.success) {
        if (callback) callback();
        dispatch(setIsLoadingSwitchClient(true));
        dispatch(getNewUser(() => _getDataWithRedirect(url)));
      }
    },
    [_getDataWithRedirect, dispatch, isLoadingSwitchClient, switchClient]
  );

  return {
    isSuccessSwitchClient,
    isLoadingSwitchClient: isLoadingSwitchClient || isLoadingSwitchOrganisation,
    handleSwitchClient,

    collabFeatureActive,
    loggedUser,

    parsedOrganizations,
    userStatistics,
  };
};

export default useSwithClient;
