import React from 'react';
import { useDispatch } from 'react-redux';

import { CellContainer, CellItem } from '../styles';

import { gray, white } from '../../../../../../config/colors';

import { BG_COLORS, TEXT_COLORS } from '../constants';
import { setModalInfo } from '../../../slice';

const Cell = ({ cellData = {}, month, schedule, index }) => {
  const dispatch = useDispatch();
  const items = cellData.data ?? [];
  const totalItems = cellData.count ?? 0;

  const onCellItemClick = (item, e) => {
    // setShowModal(true);
    const { x, y, right } = e.target.getBoundingClientRect();
    //when more than 5 'all' we show modal with the list
    if (item.id === 'all') {
      dispatch(
        setModalInfo({
          data: {
            id: 'all',
            scheduleId: schedule?.scheduleId,
            page: 1,
            paginate_by: 150,
            month,
            index,
          },
          position: { x, y, right },
        })
      );
      return;
    }

    dispatch(
      setModalInfo({
        data: { ...item, month, index },
        position: { x, y, right },
      })
    );

    return;
  };

  const _items = totalItems > 5 ? [{ id: 'all' }] : items;

  return (
    <CellContainer>
      {_items.map((item) => (
        <CellItem
          key={item.id}
          $background={
            BG_COLORS[cellData?.blockDisplayType || item?.displayType] || gray
          }
          onClick={(e) => onCellItemClick(item, e)}
          isAll={totalItems > 5}
          color={
            TEXT_COLORS[cellData?.blockDisplayType || item?.displayType] ||
            white
          }
        >
          {item.id === 'all' ? <span>{totalItems}</span> : null}
        </CellItem>
      ))}
    </CellContainer>
  );
};

export default Cell;
