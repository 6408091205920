import React, { Suspense } from 'react';
import styled from 'styled-components/macro';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './content-area.component.scss';

import { surveyDismissedAt } from '../../../../services/SurveyService';
import { surveyWhitelistedRoutes } from '../../../../config/config';
import { getLoggedUserData } from '../../../../redux/user/action-creator';
import { actions as userActions } from '../../../../redux/user/actions';
import useSurvey from '../hooks/useSurvey';

import Survey from '../../../../components_new/Survey';
import useContentArea from './hooks/useContentArea';
import OnboardingBanner from '../Onboarding/components/OnboardingBanner';
import { getOnboardingProgress } from '../../../../redux/config/selectors';
import { getUser } from '../../../../redux/user/selectors';

const ContentAreaWrapper = styled.div`
  display: inline-block;
  /* position: absolute; */
  height: 100%;
  padding: 55px 0px 0px 0px;
  width: 100%;
`;

export const ContentArea = () => {
  const user = useSelector(getUser);
  const { forceShowSurveyModal } = useSelector((state) => state.app.toJS());
  const history = useHistory();
  const dispatch = useDispatch();
  const onboardingProgress = useSelector(getOnboardingProgress);
  const [showModal, hideSurvey, handleSaveSurvey] = useSurvey(user);

  const { parsedInternalRoutes } = useContentArea();

  return (
    <ContentAreaWrapper className="content-area">
      <div className="content">
        {!onboardingProgress?.isOnboarded ? <OnboardingBanner /> : null}
        <Suspense fallback={<></>}>
          {parsedInternalRoutes.map((i, index) => {
            return (
              <Route
                key={index}
                path={i.link}
                exact={true}
                component={i.component}
              />
            );
          })}
        </Suspense>
        {(forceShowSurveyModal ||
          (history?.location?.pathname &&
            surveyWhitelistedRoutes[history.location.pathname])) &&
          showModal && (
            <Survey
              isOpen={showModal}
              handleClose={() => {
                hideSurvey();
                const at = new Date();
                dispatch(userActions.updateUser({ survey_dismissed_at: at }));
                surveyDismissedAt(at.getTime() / 1000)
                  .then((res) => {
                    if (res && res.success) {
                      dispatch(getLoggedUserData());
                    }
                  })
                  .catch(() => {});
              }}
              handleAction={handleSaveSurvey}
              questions={[
                'how_much_wowflow_help',
                'how_easy_is_wowflow',
                'would_you_recommend_wowflow',
                'note',
              ]}
            />
          )}
      </div>
    </ContentAreaWrapper>
  );
};
