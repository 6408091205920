import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import styled from 'styled-components/macro';
import {
  MainContent,
  Title,
} from '../../../../../components/loading-spinner/loading-spinner-style';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';
import Modal from '../../../../../components_new/Modal';
import ModalInfo from '../../../../../components_new/ModalInfo';
import Space from '../../../../../components_new/Space';
import {
  WowBlock,
  WowIcon,
} from '../../../../../components_new/WowBasicElements';
import useMultiselect from '../../../../../hooks/useMultiselect';
import { issuesApi, useIssueMultipleReassignMutation } from '../../service';
import { NoticeWithIcon } from '../../../../../components_new/Notice';
import { hardWhite } from '../../../../../config/colors';
import { LinkWithArrowWrapper } from '../../../../../components_new/WowModalElements';
import ErrorMessage from './ErrorMessage';
import useReasign from './hooks/useReasign';
import { openInNewTab } from '../../../../../utils/core';
import { internalRoutePaths } from '../../../../../config/config';
import ModalUsers from '../../../../../components_new/ModalUsers';
import { addToPersistedFilters } from '../../../../../redux/config/actions';

const ModalReassign = ({ item, onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { selectedItems, deselectAllItems } = useMultiselect();
  const [issueMultipleReassign, { isLoading: isLoadingReassign, isSuccess }] =
    useIssueMultipleReassignMutation();

  const [responseData, setResponseData] = useState(null);
  const [assignee, setAssignee] = useState(null);

  const handleReassign = async (id, user) => {
    const ids = item
      ? [item.id]
      : [...new Set(Object.values(selectedItems).map((item) => item.id))];

    try {
      const response = await issueMultipleReassign({
        issue_ids: ids.join(','),
        user_id: String(id),
      });

      if (response.data) {
        setResponseData(response.data);
      }
    } catch (error) {}
  };

  const handleClose = () => {
    onClose();
    deselectAllItems();
    dispatch(issuesApi.util.invalidateTags([{ type: 'Issue', id: 'LIST' }]));
  };

  const allIssuesReassigned = useMemo(() => {
    if (item) {
      return !!responseData?.reassigned_issues;
    }
    return (
      responseData?.reassigned_issues === Object.keys(selectedItems).length
    );
  }, [responseData, selectedItems, item]);

  const handleViewAllIssues = (ids) => {
    dispatch(addToPersistedFilters({ issue_ids: ids.toString() }, 'issue'));
    openInNewTab(internalRoutePaths.ISSUES);
  };

  const {
    getMessageProps,
    workspaceIds,
    workspaceName,
    canManageUsers,
    shouldShowTabs,
  } = useReasign({
    item,
  });

  return (
    <>
      <ModalUsers
        mode="single"
        title={intl.formatMessage({ id: 'select_assignee' })}
        shouldShowTabs={shouldShowTabs}
        forceShowModal
        hideAddButton
        onClose={onClose}
        canManageUsers={canManageUsers}
        onChange={(value, rawData) => {
          if (value) handleReassign(value);
          setAssignee(rawData?.[0] ?? null);
        }}
        workspaceIds={workspaceIds}
        workspaceName={workspaceName}
        showSelected={false}
      />

      {isLoadingReassign ? (
        <Modal isOpen>
          <MainContent data-testid="loadingModal">
            <LoadingSpinner isLocal="local" />
            <Title>
              <FormattedMessage id="in_progress" />
            </Title>
          </MainContent>
        </Modal>
      ) : null}
      {isSuccess && responseData ? (
        <ModalInfo
          isOpen={isSuccess}
          handleAction={handleClose}
          handleClose={handleClose}
          icon={allIssuesReassigned ? 'icon-check' : 'icon-important-round'}
          heading={intl.formatMessage({
            id: allIssuesReassigned ? 'success_reassign' : 'reassign',
            defaultMessage: 'Reassigned successfully!',
          })}
          intlValues={{
            messageKey: 'issues_count_reassigned',
            count: responseData.reassigned_issues,
            user_name: assignee?.full_name,
          }}
        >
          {!allIssuesReassigned ? (
            <NoticeWithIcon
              icon="icon-important-round"
              variant="important"
              color="white"
              alignItems="flex-start"
              paddingV="20"
              paddingH="20"
            >
              <StyledWowBlock>
                {responseData
                  .not_reassigned_issues_because_user_is_not_part_of_ws
                  ?.length ? (
                  <>
                    <ErrorMessage
                      {...getMessageProps(
                        responseData.not_reassigned_issues_because_user_is_not_part_of_ws,
                        'multiselect_reassign_issue_user_not_in_ws',
                        'multiselect_reassign_issue_user_not_in_multiple_ws'
                      )}
                    />
                    <Space height="15" />
                    <WowBlock>
                      <LinkWithArrowWrapper
                        onClick={() =>
                          handleViewAllIssues(
                            responseData.not_reassigned_issues_because_user_is_not_part_of_ws
                          )
                        }
                        color={hardWhite}
                      >
                        <FormattedMessage id="view_all" />
                        <WowIcon className="icon icon-arrowupleft" />
                      </LinkWithArrowWrapper>
                    </WowBlock>
                    {responseData.permission_denied_for_issues?.length ||
                    responseData.statuses_not_allowed?.length ? (
                      <Space height="25" />
                    ) : null}
                  </>
                ) : null}
                {responseData.permission_denied_for_issues?.length ? (
                  <>
                    <ErrorMessage
                      {...getMessageProps(
                        responseData.permission_denied_for_issues,
                        'multiselect_reassign_issue_missing_ws_permission',
                        'multiselect_reassign_issue_missing_multiple_ws_permission'
                      )}
                    />
                    <Space height="15" />
                    <WowBlock>
                      <LinkWithArrowWrapper
                        onClick={() =>
                          handleViewAllIssues(
                            responseData.permission_denied_for_issues
                          )
                        }
                        color={hardWhite}
                      >
                        <FormattedMessage id="view_all" />
                        <WowIcon className="icon icon-arrowupleft" />
                      </LinkWithArrowWrapper>
                    </WowBlock>
                    {responseData.statuses_not_allowed?.length ? (
                      <Space height="25" />
                    ) : null}
                  </>
                ) : null}
                {responseData.statuses_not_allowed?.length ? (
                  <>
                    <ErrorMessage
                      id="multiselect_reassign_issue_wrong_status"
                      values={{
                        count: responseData.statuses_not_allowed.length,
                      }}
                    />
                    <Space height="15" />
                    <WowBlock>
                      <LinkWithArrowWrapper
                        onClick={() =>
                          handleViewAllIssues(responseData.statuses_not_allowed)
                        }
                        color={hardWhite}
                      >
                        <FormattedMessage id="view_all" />
                        <WowIcon className="icon icon-arrowupleft" />
                      </LinkWithArrowWrapper>
                    </WowBlock>
                  </>
                ) : null}
              </StyledWowBlock>
            </NoticeWithIcon>
          ) : null}
          <Space height="36" />
        </ModalInfo>
      ) : null}
    </>
  );
};

export default ModalReassign;

export const StyledWowBlock = styled(WowBlock)`
  text-align: left;
  a {
    color: white;
  }
`;
