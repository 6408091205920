import {
  darkGray,
  issueFutureTask,
  newRed,
  orange,
  statusGreen,
  white,
} from '../../../../../config/colors';
import { ISSUE_STATUSES } from '../../../IssuesV2/constants';

export const HEADINGS = [
  {
    id: 0,
    title: 'maintenance',
    isMain: true,
  },
  { id: 1, title: '-01—' },
  { id: 2, title: '-02—' },
  { id: 3, title: '-03—' },
  { id: 4, title: '-04—' },
  { id: 5, title: '-05—' },
  { id: 6, title: '-06—' },
  { id: 7, title: '-07—' },
  { id: 8, title: '-08—' },
  { id: 9, title: '-09—' },
  { id: 10, title: '-10—' },
  { id: 11, title: '-11—' },
  { id: 12, title: '-12—' },
];

export const PREDEFINED_TASK = 'PREDEFINED_TASK';

export const BG_COLORS = {
  [ISSUE_STATUSES.IN_REVIEW]: '#5748fb',
  [ISSUE_STATUSES.COMPLETED]: statusGreen,
  [ISSUE_STATUSES.IN_PROGRESS]: orange,
  PLANNED: issueFutureTask,
  TODO: darkGray,
  OVERDUE: newRed,
};

export const TEXT_COLORS = {
  [ISSUE_STATUSES.IN_REVIEW]: white,
  [ISSUE_STATUSES.COMPLETED]: white,
  [ISSUE_STATUSES.IN_PROGRESS]: white,
  PLANNED: darkGray,
  TODO: white,
  OVERDUE: white,
};

export const LIST_ID = 'yearly-list';
