import { authGET } from './ApiService';
import { config } from './config';

const activityLogRoute = (issueId) =>
  `${config.URL}/api/${config.API_VERSION}/${config.API.ISSUES}/${issueId}/activity_log`;

export const getActivityLog = (token, issueId) => {
  return authGET(
    activityLogRoute(issueId),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

const attachmentsCommentsRoute = (issueId) =>
  `${config.URL}/api/${config.API_VERSION}/${config.API.ISSUES}/${issueId}/attachment_comment`;

export const getAttachmentsComments = (token, issueId) => {
  return authGET(
    attachmentsCommentsRoute(issueId),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};

export const fetchBlob = (url) =>
  fetch(url).then((response) => response.blob());
export const getLocalLink = async (url) => {
  const blob = await fetchBlob(url);

  return URL.createObjectURL(blob);
};

const timeLogRoute = (issueId) =>
  `${config.URL}/api/${config.API_VERSION}/${config.API.ISSUES}/${issueId}/timer`;

export const getTimeLog = (token, issueId) => {
  return authGET(
    timeLogRoute(issueId),
    config.CONTENT_TYPES.APPLICATION_JSON,
    token
  );
};
