export const outline = '#e6edf4';
export const gray = '#474747';
export const darkerGray = '#291A53';
export const purple = '#9646F1';
export const gradientBlue = '#7547F7';
export const gradientPink = '#F344E4';
export const lighterBlue = '#AFB7Bf';
export const linkBlue = '#5848FA';
export const neonGreen = '#06d0b1';
export const white = '#ffffff';
export const black = '#000000';
export const newBlack = '#272e5d';
export const iconGray = '#dee2e8';
export const red = '#ff4a7a';
export const pdfRed = '#DB1700';
export const pdfBlue = '#39C0FF';
export const issueFutureTask = '#E7EDF3';
export const xslGreen = '#066E06';
export const tooltipBg = 'rgba(44, 59, 74, .9)';
export const borderPurple = '#D7D3FE';
export const lightererBlue = '#e1e5e9';
export const borderLighterGray = '#D6DDE3';
export const borderLightGray = '#ccc';
export const purple500 = '#7547F7';
export const newBorder = '#d6dde3';
export const newRed = '#E82C68';
export const newRedBg = '#F2236B';
export const newGray = '#AFC1D0';
export const newGrayLighter = '#d2dbe5';
export const newGrayDarker = '#456279';
export const newGrayEvenLighter = '#8399ab';
export const orange = '#FF871F';
export const yellow = '#FFDD00';
export const pink = '#FC3C82';
export const sectionNameGray = '#95A0AC';
export const userPillGray = '#44566C';
export const newGrayVariant = '#6C7B8A';
export const futureTaskIssueModalCalendar = '#E7EDF3';
// This is used in "selected" list item as background color
// TODO: Give better name / Rename other colors too
export const grayThatWeNeedToName = '#F7F9FC';
export const blackThatWeNeedToName = '#291A53';
export const iconWowListItemLightGray = '#474747';
export const lightPurple = '#E7DAFD';
export const calendarCardHoverColor = '#291A53';
export const darkGray = '#3f576e';
export const borderGray = '#DBE1E6';
export const datePurple = '#e6d0ef';
export const dateGray = '#425A70';
export const pillGray = '#ebedf0';
export const lighterGray = '#E7ECF2';
export const lightererGray = '#fafbfb';
export const filtersRed = '#FF4A7A';
export const filtersAdvancedGray = '#8996A6';
export const filtersBlue = '#5B47FF';
export const boxShadow = 'rgba(55, 70, 95, 0.1)';

export const violet = '#9646f1';
export const notificationPink = '#ff366e';
export const hardWhite = '#f4f6fa';
export const offWhite = '#f5f7f9';
export const autocompleteGray = '#B0C0CF';
export const userGray = '#4b5763';
export const transparentBlack = 'rgba(0, 0, 0, 0.4)';
export const confirmGreen = '#25C465';
export const galleryGray = '#BCBCBC';
export const galleryBackdrop = 'rgba(0, 0, 0, 0.84)';
export const iconBorder = 'rgba(255, 255, 255, 0.3)';
export const inputBorderGray = '#e1e5e9';
export const redButtonColor = '#fb0a62';
export const redButtonColorHover = '#da0352';
export const greenButtonColor = '#25C465';
export const buttonInactive = '#f6f6f7';
export const buttonInactiveHover = '#e9e9eb';
export const buttonInactiveText = '#acb3bf';
export const dialogConfirm = '#10a38f';
export const filesGrayTransparent = '#707E8D';
export const blackTransparent = 'rgba(0, 0, 0, .5)';
export const scrollGray = 'rgba(255, 255, 255, .5)';
export const boxShadowDarker = '#3b4a7440';
export const statusGreen = '#73D500';
export const textGreen = '#50A362';
export const buttonDisabled = '#B3C0CE';
export const lightButtonHover = '#FAFCFD';

export const priorityColors = {
  'priority.urgent': red,
  'priority.high': orange,
  'priority.normal': textGreen,
  'priority.low': '#39c0ff',
};

export const statusColors = {
  'status.to_do': '#4a5b6d',
  'status.in_review': '#5848FB',
  'status.in_progress': orange,
  'status.completed': textGreen,
  'status.draft': '#000000',
};
