import styled from 'styled-components/macro';
import {
  borderLightGray,
  borderLighterGray,
  darkGray,
  darkerGray,
  gray,
  greenButtonColor,
  lightPurple,
  newBorder,
  newGrayVariant,
  purple500,
  white,
} from '../../../../config/colors';
import { mainAccent } from '../../../../helpers/theme';

export const ButtonWrapper = styled.div`
  border-radius: 7px;
  border: 1px solid ${({ isSelected }) => (isSelected ? purple500 : newBorder)};
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  flex: 5;
  color: ${({ isSelected }) => (isSelected ? purple500 : darkGray)};

  &:hover {
    cursor: pointer;
  }
`;

export const DateInputWrapper = styled.div`
  border-radius: 7px;
  border: 1px solid ${newBorder};
  padding: 15px;
  width: 100%;
  display: flex;

  input {
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${gray};
    width: 100%;
  }

  .icon-carot {
    transform: rotate(180deg);
  }

  ${({ isActive }) =>
    isActive
      ? `
  border-color: ${purple500};

      input::-webkit-input-placeholder {
        color: ${purple500};
    }

    input::-moz-placeholder {
        color: ${purple500};
    }

    input::-ms-placeholder {
        color: ${purple500};
    }

    input::placeholder {
        color: ${purple500};
    }

    .icon-carot {
      transform: rotate(360deg);
    }
 
  `
      : null}
`;

export const DatePickerStyleResetWrapper = styled.div`
  padding-top: 5px;

  /*  */
  .react-datepicker {
    font-family: 'nunitosans', 'Roboto', sans-serif;
    font-weight: 700;
    border: none;
    font-size: 13px;
    border: 1px solid ${newBorder};
    width: 100%;
    ${({ pickerPositionTop }) =>
      pickerPositionTop ? `top: ${pickerPositionTop}` : ''}
  }

  .react-datepicker__month-container {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  }

  .react-datepicker__current-month {
    color: ${darkerGray};
    font-size: 15px;
  }

  .react-datepicker__header {
    background: transparent;
    border: none;
  }

  .react-datepicker__day,
  .react-datepicker__day:hover {
    position: relative;
    background: none;
  }

  .react-datepicker__day:hover {
    background: ${purple500};
    color: ${white};
    border-radius: 50%;
  }

  .react-datepicker__day::before {
    content: '';
  }

  .react-datepicker__day--disabled {
    color: ${borderLightGray};
  }

  .react-datepicker__day--today::before {
    content: '';

    position: absolute;
    background-color: #39c0ff;
    height: 4px;
    width: 4px;
    border-radius: 999px;
    bottom: 0px;
    left: 50%;
  }

  .react-datepicker__day--outside-month::before {
    content: none;
  }

  .react-datepicker__day-names {
    padding-top: 34px;
  }

  .react-datepicker__day-name {
    color: ${darkGray};
  }

  /*  */
  .react-datepicker-time__caption,
  .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__input-time-container,
  .react-datepicker-time__input {
    margin: 0 !important;
  }

  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input {
    width: 72px;
  }

  // Footer with time and done button
  .react-datepicker__input-time-container {
    padding: 0 24px 24px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0;
    font-size: 14px;
    margin-right: 10px;
  }

  .react-datepicker__day {
    color: ${darkGray};
    font-weight: 600;
    z-index: 1;
  }

  .react-datepicker__day-name {
    color: ${gray};
    font-weight: 400;
  }

  .react-datepicker__day:last-of-type,
  .react-datepicker__day-name:last-of-type {
    margin: 0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--range-end,
  .react-datepicker__day--range-end:hover {
    color: ${white};
  }

  .react-datepicker__day--in-range:hover {
    background-color: transparent;
  }

  .react-datepicker__day--selected::after,
  .react-datepicker__day--in-range::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 9999px;
  }

  .react-datepicker__day--selected::after {
    background-color: ${mainAccent} !important;
  }

  .react-datepicker__day--in-range::after {
    background-color: ${lightPurple};
  }

  .react-datepicker__day--in-range:hover::after {
    background-color: ${purple500};
  }

  .react-datepicker__day--range-end::after {
    background-color: ${purple500};
  }

  .react-datepicker__day--outside-month {
    color: ${gray};
  }

  .react-datepicker__day--disabled {
    color: ${borderLightGray};
  }

  .react-datepicker__month {
    margin: 0;
    padding: 18px 24px;
  }

  .react-datepicker__week {
    margin-bottom: 10px;
  }

  .react-datepicker__week:last-of-type {
    margin-bottom: 0;
  }

  // Had to change position of left and right arrow
  // because of additional padding in header
  .react-datepicker__header {
    padding-top: 24px;
  }

  .react-datepicker__navigation {
    top: 26px;
  }

  .react-datepicker__navigation--next {
    right: 20px;
  }

  .react-datepicker__navigation--previous {
    left: 21px;
  }

  .react-datepicker__close-icon::after {
    background: transparent;
    color: ${darkGray};
    font-size: 16px;
  }

  .react-datepicker__day--highlighted,
  .react-datepicker__day--highlighted-custom-calendar-weekly-datepicker {
    color: #fff;
    background-color: ${purple500};
    border-radius: 50%;
    position: relative;
  }

  .react-datepicker__day--highlighted-custom-calendar-weekly-datepicker.react-datepicker__day:hover {
    background-color: ${purple500} !important;
  }
`;

export const CheckboxGroupLabel = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${({ color }) => color || darkerGray};
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

export const CheckboxesWrapper = styled.div`
  padding: ${({ spacingY }) => (spacingY ? spacingY + 'px' : '20px')} 0;
  border-bottom: ${({ includeBorder }) =>
    includeBorder ? `1px solid ${borderLighterGray}` : 'none'};
`;

export const CheckboxesGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 0.7fr);
  grid-auto-rows: auto;
  grid-gap: 5px;
  row-gap: 20px;
`;

export const SaveButton = styled.button`
  display: flex;
  min-width: 100px;
  height: 37px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${({ disabled }) =>
    disabled ? newGrayVariant : greenButtonColor};
  color: white;
  border-radius: 7px;
  margin-bottom: 10px;
  ${({ disabled }) => (!disabled ? '&:hover {cursor: pointer};' : null)};
`;
