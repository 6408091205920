import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { hardWhite, darkerGray, gray } from '../../../config/colors';
import { AutocompleteAdd } from '../../../views/internal/settings/autocomplete-add';
import { RenderIf } from '../../render-if/render-if.component';
import { CheckMarkButton } from '../../checkmark-button/checkmark-button.component';
import { LinkifyWrapper } from '../../linkify-wrapper/linkify-wrapper.component';

export const lineHeight = 24;

const Wrapper = styled.div`
  height: 100%;
`;

const TextAreaWrapper = styled.div.attrs({ className: 'textarea-wrapper' })`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  padding: 8px;
  height: 100%;

  textarea {
    color: ${darkerGray};
    width: 100%;
    ${({ autoResize, maxHeight }) => {
      return autoResize
        ? `
        height: auto;
        max-height: ${maxHeight ?? 400}px;
        overflow-y: auto;`
        : 'height: 100%';
    }}
    background-color: ${(props) => props.backgroundColor};
    border: none;
    resize: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 400;
    line-height: ${lineHeight}px;
    font-family: 'nunitosans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    ::placeholder {
      color: ${gray};
      font-weight: 400;
    }
  }
`;

const Buttons = styled.div.attrs({ className: 'autocomplete-add-wrapper' })`
  display: flex;
  width: 100%;
  height: 25px;
`;

const Placeholder = styled.div`
  width: 100%;
`;

const CharactersLeft = styled.div`
  font-size: 12px;
  padding: 0 5px;
  color: ${gray};
  text-align: right;
`;

const ButtonWrapper = styled.div`
  margin-left: 4px;
`;
const LinkifyText = styled.div`
  white-space: pre-line;
  overflow: auto;
  ${({ autoResize, maxHeight }) => {
    return autoResize
      ? `max-height: ${maxHeight ?? 400}px;
    overflow-y: auto;`
      : null;
  }}
`;

export const AutocompleteTextArea = ({
  name,
  cols,
  rows,
  placeholder,
  required,
  disabled,
  readOnly,
  maxLength = 5000,
  autoFocus = true,
  value,
  handleChange,
  enableAutocomplete = false,
  backgroundColor = hardWhite,
  showCharactersLeft = true,
  showSaveButton = false,
  onSaveText,
  isSmallView,
  isPublic,
  categoryId,
  testId,
  autoResize = false,
  maxHeight,
  alwaysVisible,
  setShowWrapperBorder,
}) => {
  const textRef = useRef(null);
  const [charactersEntered, setCharactersEntered] = useState(0);
  const [showTextArea, setShowTextArea] = useState(alwaysVisible);

  useEffect(() => {
    setCharactersEntered(value?.length ?? 0);
  }, [value]);

  useEffect(() => {
    setTimeout(() => {
      if (showTextArea && textRef.current && autoResize) {
        textRef.current.style.height = `${textRef.current.scrollHeight}px`;
        textRef.current.scrollTop = textRef.current.scrollHeight;
      }
    }, 0);
  }, [textRef, autoResize, showTextArea]);

  const useOutsideAreaClick = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !alwaysVisible
        ) {
          setShowTextArea(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const handleTextChange = (event) => {
    if (autoResize && textRef.current) {
      textRef.current.style.height = `${textRef.current.scrollHeight}px`;
    }

    setShowTextArea(true);
    var input = event.target.value;
    setCharactersEntered(input.length);

    if (handleChange) {
      handleChange(event);
    }
  };

  const handleSaveText = () => {
    if (onSaveText) {
      onSaveText();
    }
  };

  const onFocusTextarea = (e) => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
    if (setShowWrapperBorder) setShowWrapperBorder(true);
  };

  const onBlurTextarea = (e) => {
    if (!setShowWrapperBorder) return;
    setShowWrapperBorder();
  };

  const textAreaClickHandler = () => {
    if (!isPublic) {
      setShowTextArea(true);
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAreaClick(wrapperRef);

  return (!showTextArea && value.length > 0) || readOnly ? (
    <TextAreaWrapper onClick={textAreaClickHandler}>
      <LinkifyWrapper>
        <LinkifyText autoResize={autoResize} maxHeight={maxHeight}>
          {value}
        </LinkifyText>
      </LinkifyWrapper>
    </TextAreaWrapper>
  ) : (
    <Wrapper>
      <TextAreaWrapper
        lineHeight={lineHeight}
        backgroundColor={backgroundColor}
        ref={wrapperRef}
        autoResize={autoResize}
        maxHeight={maxHeight}
      >
        <textarea
          id={name}
          cols={cols}
          rows={rows}
          name={name}
          placeholder={placeholder}
          onChange={handleTextChange}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          autoFocus={autoFocus}
          value={value}
          ref={textRef}
          onFocus={onFocusTextarea}
          onBlur={onBlurTextarea}
          data-testid={testId ?? 'autocompleteTextArea'}
        />
        <Buttons>
          <Placeholder />
          {!disabled ? (
            <AutocompleteAdd
              textRef={textRef}
              enableAutocomplete={enableAutocomplete}
              triggerTextChange={(value) =>
                handleTextChange({ target: { value } })
              }
              isSmallView={isSmallView}
              maxLength={maxLength}
              categoryId={categoryId}
            />
          ) : null}

          <RenderIf
            if={showSaveButton}
            then={() => (
              <ButtonWrapper>
                <CheckMarkButton onClick={handleSaveText} />
              </ButtonWrapper>
            )}
          />
        </Buttons>
        <Placeholder />
      </TextAreaWrapper>
      <RenderIf
        if={showCharactersLeft}
        then={() => (
          <CharactersLeft>
            {charactersEntered}/{maxLength}
          </CharactersLeft>
        )}
      />
    </Wrapper>
  );
};
