import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';
import useWindowSizeDebounced from '../../../../../../hooks/useWindowSizeDebounced';
import useCollaboratorListSimpleInfinite from '../../../../../../views/internal/Collaborators/hooks/useCollaboratorListSimpleInfinite';
import { Content, LoaderWrapper, NoItems } from '../../../../styles';
import Search from '../Search';
import SubHeader from '../SubHeader';
import InfiniteScroll from '../../../../../InfiniteScroll';
import {
  ArrowUp,
  Container,
  GroupOptionContainer,
  GroupOptionIconWrapper,
  GroupOptionLabel,
  GroupOptionPhoto,
  Option,
  OptionLabel,
  OptionPhoto,
} from '../../styles';
import { DEFAULT_PROFILE_IMAGE } from '../../../../../../config/constants';
import { WowIcon } from '../../../../../WowBasicElements';
import { gradientBlue } from '../../../../../../config/colors';
import {
  COLLABORATORS_PERMISSION_TYPE_ICONS,
  COLLABORATORS_PERMISSION_TYPE_VALUES,
} from '../../../../constants';
import TextOverflowWithTooltip from '../../../../../TextOverflowWithTooltip';
import Space from '../../../../../Space';

const Collaborators = (props) => {
  const {
    handleSelect,
    selectedItems,
    handleTriggerAddModal,
    testIds,
    isAdmin,
    search,
    setSearch,
    initQueryParams,
    canInviteLimitedCollaboratorsOnly,
  } = props;

  const { data, isLoading, handleLoadMore, hasMore, handleSearch } =
    useCollaboratorListSimpleInfinite({
      entityKey: 'collaborators',
      customParams: initQueryParams,
    });

  const { height: windowHeight } = useWindowSizeDebounced();
  const [isCollapsed, setIsCollapsed] = useState({});

  const handleIsCollapsed = (id) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      {!isAdmin && !canInviteLimitedCollaboratorsOnly ? (
        <Space height="20" />
      ) : null}

      {isAdmin || canInviteLimitedCollaboratorsOnly ? (
        <SubHeader
          buttonKey="invite_collaborator"
          handleTriggerAddModal={handleTriggerAddModal}
        />
      ) : null}
      <Search
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
      />

      <Content
        data-testid={testIds?.modalDataTestId}
        height={windowHeight > 800 ? '300px' : 'auto'}
      >
        {data.length === 0 ? (
          isLoading ? (
            <LoaderWrapper>
              <LoadingSpinner small />
            </LoaderWrapper>
          ) : (
            <NoItems>
              <FormattedMessage id="no_items" />
            </NoItems>
          )
        ) : (
          <InfiniteScroll
            dataLength={data.length}
            handleLoadMore={handleLoadMore}
            hasMore={hasMore}
            height={windowHeight > 800 ? 300 : -1}
            maxHeight={windowHeight > 800 ? 300 : 200}
          >
            {data.map((item) => {
              const { id, logo, label, users, access_type: accessType } = item;

              return (
                <Container key={id}>
                  <GroupOptionContainer onClick={() => handleIsCollapsed(id)}>
                    {logo ? (
                      <GroupOptionPhoto src={logo} />
                    ) : (
                      <GroupOptionIconWrapper>
                        <WowIcon
                          className="icon icon-collaborators"
                          size="15"
                          spaceRight="0"
                          display="flex"
                          color="white"
                        />
                      </GroupOptionIconWrapper>
                    )}

                    <TextOverflowWithTooltip tooltipContent={label}>
                      {(ref) => (
                        <GroupOptionLabel ref={ref}>
                          {label ?? ''}
                        </GroupOptionLabel>
                      )}
                    </TextOverflowWithTooltip>

                    <WowIcon
                      className={
                        COLLABORATORS_PERMISSION_TYPE_ICONS[accessType]
                      }
                      spaceLeft="0"
                      spaceRight={
                        accessType ===
                        COLLABORATORS_PERMISSION_TYPE_VALUES.limited
                          ? '5'
                          : '10'
                      }
                      size={
                        accessType ===
                        COLLABORATORS_PERMISSION_TYPE_VALUES.limited
                          ? '20'
                          : '15'
                      }
                      display="flex"
                    />

                    <ArrowUp isCollapsed={isCollapsed[id]} />
                  </GroupOptionContainer>
                  {!isCollapsed[id] &&
                    users.map((option) => {
                      const {
                        id: optionId,
                        full_name: fullName,
                        profile_image: profileImage,
                      } = option;

                      const isSelected = selectedItems.find(
                        (selectedItem) => selectedItem.id === optionId
                      );

                      return (
                        <Option
                          key={optionId}
                          onClick={() => handleSelect(option)}
                        >
                          <OptionPhoto
                            src={profileImage || DEFAULT_PROFILE_IMAGE}
                            isSelected={isSelected}
                          />
                          <TextOverflowWithTooltip tooltipContent={label}>
                            {(ref) => (
                              <OptionLabel ref={ref}>{fullName}</OptionLabel>
                            )}
                          </TextOverflowWithTooltip>
                          {isSelected ? (
                            <WowIcon
                              className="icon-check"
                              color={gradientBlue}
                              spaceRight="10"
                              spaceLeft="0"
                            />
                          ) : null}
                        </Option>
                      );
                    })}
                </Container>
              );
            })}
          </InfiniteScroll>
        )}
      </Content>
    </>
  );
};

export default Collaborators;
