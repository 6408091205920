import React from 'react';
import noAvatar from '../../assets/img/no-avatar.jpg';
import noImg from '../../assets/img/no-image.jpg';
import { Image } from './styles';

const Avatar = ({ imageUrl, imageAlt, noImage = false, radius = true }) => {
  const defaultSrc = !noImage ? noAvatar : noImg;
  return (
    <Image
      src={imageUrl || defaultSrc}
      alt={imageAlt}
      $radius={radius}
      data-testid="avatar"
    />
  );
};

export default Avatar;
