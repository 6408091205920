import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  entity: null,
  showLinks: false,
  isAddLinksActive: false,
};

export const linksSlice = createSlice({
  name: 'links',
  initialState: INIT_STATE,
  reducers: {
    setIsAddLinksActive: (state, action) => {
      state.isAddLinksActive = action.payload ?? false;
    },
    setShowLinks: (state, action) => {
      state.showLinks = action.payload ?? false;
    },
    setEntity: (state, action) => {
      state.entity = action.payload ?? null;
    },
  },
});

export const { setIsAddLinksActive, setShowLinks, setEntity } =
  linksSlice.actions;

export default linksSlice.reducer;
