import styled from 'styled-components/macro';
import { darkerGray, newBorder, outline } from '../../../config/colors';

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonToggler = styled.button`
  display: flex;
  padding: 6px 20px;
  font-size: 15px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid ${newBorder};
  background-color: white;
  color: ${darkerGray};
  font-weight: bold;
  line-height: 1;
  align-items: center;

  ${({ isMenuOpen }) =>
    isMenuOpen
      ? `background-color: ${outline}; border-color: ${outline};`
      : null};
`;
