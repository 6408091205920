export const actionStrings = {
  SELECT_NOTIFICATION: 'SELECT_NOTIFICATION',
  MARK_ALL_ASS_READ: 'MARK_ALL_ASS_READ',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  PARSE_NOTIFICATIONS_BY_DATE: 'PARSE_NOTIFICATIONS_BY_DATE',
  MARK_NOTIFICATION_AS_READ: 'MARL_NOTIFICATION_AS_READ',
};

export const markAllAsRead = (notifications, notificationClassName) => ({
  type: actionStrings.MARK_ALL_ASS_READ,
  payload: {
    notifications,
    notificationClassName,
  },
});

export const setNotifications = (notifications) => ({
  type: actionStrings.GET_NOTIFICATIONS,
  payload: {
    notifications,
  },
});

export const parseNotificationsByDate = (notifications) => ({
  type: actionStrings.PARSE_NOTIFICATIONS_BY_DATE,
  payload: {
    notifications: notifications,
  },
});

export const markNotificationAsRead = (notifications) => ({
  type: actionStrings.MARK_NOTIFICATION_AS_READ,
  payload: {
    notifications,
  },
});
