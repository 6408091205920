import styled from 'styled-components/macro';
import { HEADER_HEIGHT } from '../../config/constants';

export const MENU_WIDTH = {
  XS: 100,
  SM: 150,
  NORMAL: 200,
  EXTRA_WIDE: 290,
};
export const MENU_LEFT = {
  XS: 15,
  SM: 10,
  NORMAL: -140,
  EXTRA_WIDE: -230,
};

export const getDimension = (constants) => (props) => {
  if (props.extraWide) {
    return MENU_WIDTH.EXTRA_WIDE;
  }

  if (props.xs || props.menuWidth === 'xs') {
    return MENU_WIDTH.XS;
  }

  if (props.sm || props.menuWidth === 'sm') {
    return MENU_WIDTH.SM;
  }

  return MENU_WIDTH.NORMAL;
};
export const getMenuWidth = getDimension(MENU_WIDTH);
export const getMenuLeft = getDimension(MENU_LEFT);
export const setScroll = (props) =>
  props.scroll
    ? `
      max-height: 400px;
      overflow-y: scroll;
    `
    : '';

export const MenuItems = styled.ul`
  padding: 0px;
  margin: 0px;
`;

export const MenuWrapper = styled.div`
  position: absolute;
  top: calc(#{$ ${HEADER_HEIGHT}} - 5px);
  left: ${getMenuLeft};
  width: ${getMenuWidth}px;
  background-color: $white;
  text-align: center;
  border-radius: 10px;
  box-shadow: $box-shadow;
  z-index: 100;

  ${setScroll}
`;
