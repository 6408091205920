import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GalleryListItem } from './gallery-list-item';
import styled from 'styled-components/macro';
import {
  darkGray,
  pillGray,
  transparentBlack,
  white,
} from '../../../config/colors';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { RenderIf } from '../../render-if/render-if.component';
import { breakpoint } from '../../../styles/styledComponents/media-breakpoints';

const GalleryListWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const GalleryList = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px 0;

  @media only screen and ${breakpoint.xs} {
    padding: 60px 0;
  }
`;

const PanelIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 5px;
  position: absolute;
  i {
    display: flex;
    &:before {
      font-size: 20px;
    }
  }
`;

const CloseIcon = styled(PanelIcon)`
  right: 20px;
  top: 20px;
  background-color: ${pillGray};
  i {
    &:before {
      color: ${darkGray};
    }
  }
`;

const ListViewIcon = styled(PanelIcon)`
  left: 20px;
  bottom: 20px;
  background-color: ${transparentBlack};
  i {
    &:before {
      color: ${white};
      font-size: 18px;
    }
  }
`;

const DragList = styled.ul`
  min-width: 600px;
  max-width: 680px;
  margin: 0 auto;
  list-style-type: none;

  @media only screen and ${breakpoint.xs} {
    max-width: 100%;
    min-width: 0;
    padding: 0 10px;
  }
`;

const DragItem = styled.li`
  position: relative;
  z-index: 999;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const DragHandleWrapper = styled.span`
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: ${transparentBlack};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  left: -60px;
  i {
    &:before {
      color: ${white};
      font-size: 12px;
      width: 100%;
    }
  }
`;

const DragHandle = sortableHandle(() => (
  <DragHandleWrapper>
    <i className="icon icon-sort-drag" />
  </DragHandleWrapper>
));

const SortableItem = sortableElement(({ isPublic, value, enableOrdering }) => (
  <DragItem>
    {!isPublic && enableOrdering ? <DragHandle /> : null}
    {value}
  </DragItem>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <DragList>{children}</DragList>;
});

const RenderItems = (props) => {
  const items = props.items;

  return (
    <SortableContainer onSortEnd={props.onSortEnd} useDragHandle>
      {items.map((item, i) => {
        if (item?.mediaType === 'document') return <></>;
        return (
          <SortableItem
            key={`item-${i}`}
            index={i}
            isPublic={props.isPublic}
            enableOrdering={props.enableOrdering}
            value={
              <GalleryListItem
                key={`${item.index}-${item.url}`}
                item={item}
                id={item.id}
                url={item.url}
                name={item.fileName ? item.fileName : item.original_filename}
                mimeType={item.mimeType ? item.mimeType : item.mime_type}
                mediaType={item.mediaType ? item.mediaType : item.media_type}
                description={item.description}
                state={item.state}
                index={item.index}
                user={item.user}
                onSelectItem={props.handleShowImageDetails}
                canRemove={item.allowedToDelete}
                canEdit={item.allowedToEdit}
                canResizeItem={true}
                placeholder={props.placeholder}
                handleDescChange={props.handleDescChange}
                handleListItemRemoved={props.handleListItemRemoved}
                openMenu={props.openMenu}
                setOpenMenu={props.setOpenMenu}
                showSaveOnDescription={props.showSaveOnDescription}
                onSaveDescriptionClick={props.onSaveDescriptionClick}
              />
            }
            disabled={!item.allowedToEdit}
          />
        );
      })}
    </SortableContainer>
  );
};

export const GalleryListView = (props) => {
  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (id) => {
    if (openMenu === id) {
      setOpenMenu(null);

      return;
    }

    setOpenMenu(id);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    props.onSortEnd(oldIndex, newIndex);
  };

  return (
    <GalleryListWrapper>
      <GalleryList>
        <RenderIf
          if={props.items}
          then={() => (
            <RenderItems
              isPublic={props.isPublic}
              enableOrdering={props.enableOrdering}
              items={props.items}
              allPermissions={props.allPermissions}
              handleShowImageDetails={props.onItemSelect}
              handleListItemRemoved={props.handleListItemRemoved}
              handleDescChange={props.handleDescChange}
              galleryId={props.id}
              placeholder={props.placeholder}
              setOpenMenu={toggleMenu}
              openMenu={openMenu}
              showSaveOnDescription={props.showSaveOnDescription}
              onSaveDescriptionClick={props.onSaveDescriptionClick}
              onSortEnd={onSortEnd}
            />
          )}
        />
      </GalleryList>
      <CloseIcon onClick={props.onCancel}>
        <i className="icon icon-close" />
      </CloseIcon>
      <ListViewIcon onClick={props.handleListViewToggle}>
        <i className="icon icon-categories" />
      </ListViewIcon>
    </GalleryListWrapper>
  );
};

GalleryListView.propTypes = {
  items: PropTypes.object,
  allPermissions: PropTypes.array,
  onItemSelect: PropTypes.func,
  id: PropTypes.string,
};
