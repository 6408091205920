import React, { useEffect } from 'react';

import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner.component';
import { Wrapper, InfoWrapper } from './styles';
import useUser from './hooks/useUser';
import UserBasicInfo from './UserBasicInfo/UserBasicInfo';
import UserAdditionalInfo from './UserAdditionalInfo/UserAdditionalInfo';
import UserAssignment from './UserAssignment/UserAssignment';

//what the hell is this but whole app css breaks without it
import './user-basic-info.component.scss';
import UserLogs from './UserLogs';
import { getUser } from '../../../redux/user/selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getOnlyAdminsCanSeeUsers } from '../../../redux/config/selectors';
import { internalRoutePaths } from '../../../config/config';

const UserPage = () => {
  const history = useHistory();
  const { response: user, isLoading } = useUser();
  const loggedInUser = useSelector(getUser);
  const isAdmin = loggedInUser?.role === 'admin';
  const onlyAdminsCanSeeUsers = useSelector(getOnlyAdminsCanSeeUsers);
  const canManageUsers =
    loggedInUser?.permissions?.global.includes('can_manage_users');

  useEffect(() => {
    if (
      onlyAdminsCanSeeUsers &&
      !isAdmin &&
      user &&
      loggedInUser.id !== user.id
    ) {
      history.push(internalRoutePaths.WORKSPACES);
    }
  }, [history, isAdmin, loggedInUser, onlyAdminsCanSeeUsers, user]);

  if (!user || isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Wrapper>
      <InfoWrapper>
        <UserBasicInfo user={user} />
        <UserAdditionalInfo user={user} />
        {canManageUsers ? (
          <UserAssignment canManageUsers={canManageUsers} user={user} />
        ) : null}
      </InfoWrapper>
      <UserLogs />
    </Wrapper>
  );
};

export default UserPage;
