import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import { getToken } from '../../../redux/utils';
import { BASE_URL } from '../../../config/constants';

export const schedulesApi = createApi({
  reducerPath: 'schedulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    'Schedules',
    'Categories',
    'Budget',
    'FutureDates',
    'ScheduleChecklists',
  ],
  endpoints: (builder) => ({
    getSchedules: builder.query({
      query: (params) => {
        const { density, isAdvancedFilterOpen, arrayFormat, ...restParams } =
          params;
        const url = qs.stringifyUrl(
          { url: 'schedule', query: restParams },
          {
            skipEmptyString: true,
            skipNull: true,
            arrayFormat: arrayFormat || 'bracket',
          }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Schedules', id })),
              { type: 'Schedules', id: 'LIST' },
            ]
          : [{ type: 'Schedules', id: 'LIST' }],
    }),
    getCategories: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl({ url: 'category', query: params });

        return url;
      },
      providesTags: ['Categories'],
    }),
    getBudget: builder.query({
      query: (query) => {
        const { id, ...restParams } = query;
        const url = qs.stringifyUrl(
          { url: `schedule/${id}/budgets`, query: restParams },
          {
            skipEmptyString: true,
            skipNull: true,
          }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Budget', id })),
              { type: 'Budget', id: 'LIST' },
            ]
          : [{ type: 'Budget', id: 'LIST' }],
    }),
    setBudget: builder.mutation({
      query(data) {
        const { id, ...body } = data;

        return {
          url: `schedule/${id}/set_budget`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Budget', id: 'LIST' }],
    }),
    deleteSchedule: builder.mutation({
      query(id) {
        return {
          url: `schedule/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'Schedules', id: 'LIST' }],
    }),
    getFutureDates: builder.query({
      query: (id) => {
        const url = `schedule/${id}/future_dates`;

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'FutureDates', id })),
              { type: 'FutureDates', id: 'LIST' },
            ]
          : [{ type: 'FutureDates', id: 'LIST' }],
    }),
    scheduleMultipleReassign: builder.mutation({
      query(payload) {
        return {
          url: `schedule/multiple/reassign`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    setPresenceAlarms: builder.mutation({
      query(payload) {
        const { id, ...restData } = payload;
        return {
          url: `schedule/${id}/set_time_limits`,
          method: 'POST',
          body: restData,
        };
      },
    }),
  }),
});
export const {
  useGetSchedulesQuery,
  useLazyGetSchedulesQuery,
  useGetCategoriesQuery,
  useGetBudgetQuery,
  useSetBudgetMutation,
  useDeleteScheduleMutation,
  useGetFutureDatesQuery,
  useScheduleMultipleReassignMutation,
  useSetPresenceAlarmsMutation,
} = schedulesApi;
