import { useMemo } from 'react';
import Collapsible from '../../../Collapsible';
import { useIntl } from 'react-intl';
import usePreviewAction from '../../hooks/usePreviewAction';

const Type = (props) => {
  const { value, labelKey, filterKey } = props;

  const intl = useIntl();

  const _value = useMemo(() => {
    const LABEL_KEYS = {
      issue: 'issues',
      task: 'time_schedule',
    };

    return [
      { id: 1, name: intl.formatMessage({ id: LABEL_KEYS[value] }), value },
    ];
  }, [intl, value]);

  const { handleSingleSelectChange } = usePreviewAction();

  const handleItemChange = (item) => {
    const newValue = item.value === value ? 'all' : item.value;
    handleSingleSelectChange(filterKey, newValue);
  };

  return (
    <Collapsible
      labelKey={labelKey}
      count={1}
      items={_value}
      handleItemChange={handleItemChange}
    />
  );
};

export default Type;
