import vest, { test, enforce } from 'vest';

export const parseIssuesData = (intl, items) => {
  const itemCopy = ` (${intl.formatMessage({
    id: 'copy',
  })})`;

  const _items = Object.values(items);

  return _items.map((item) => ({ id: item.id, title: item.title + itemCopy }));
};

export const formValidation = vest.create((data = {}) => {
  const { workspace_id: workspaceId } = data;

  test('workspace', 'Title is mandatory', () => {
    enforce(workspaceId).isNotEmpty();
  });
});
