import styled from 'styled-components/macro';
import { borderLightGray, hardWhite } from '../../../../../../config/colors';

export const CollapsibleWrapper = styled.div`
  background-color: ${hardWhite};
  border-radius: 10px;
`;

export const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 14px;
  align-items: center;
  ${({ isDisabled }) =>
    !isDisabled
      ? ` &:hover {
  cursor: pointer;
}`
      : null};
`;

export const CollapsibleContent = styled.div`
  max-height: 200px;
  padding: 0 14px;
  overflow-y: auto;
`;

export const CollapsibleItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${borderLightGray};
  min-height: 44px;
  padding: 10px 0;
  align-items: center;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 15px;
  }
`;
