import { createSelector } from 'reselect';
import { usersApi } from '../../users/service';

export const selectUserResult = (id) => usersApi.endpoints.getUser.select(id);

export const selectUser = (id) =>
  createSelector(selectUserResult(id), (userResult) => userResult?.data);

const getUserState = (state) => state.profile;

export const filtersSelector = createSelector(
  getUserState,
  (state) => state.filters
);
