import qs from 'query-string';
import { issuesApi } from '../service';
import { entities } from '../../../../config/config';
import { calculateIssuePermissions } from '../utils';
import { flowConfigPath } from '../../../../redux/config/selectors';
import { getEntity } from '../../../../redux/entities/selectors';

export const issueSingleApi = issuesApi.injectEndpoints({
  endpoints: (builder) => ({
    getIssue: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `issue/${id}`;
        return url;
      },
      providesTags: (result) =>
        result
          ? [{ type: 'Issue', id: result.id }]
          : [{ type: 'Issue', id: 'LIST' }],
      async onQueryStarted(
        { id, ...patch },
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          //Attaching permissions on Issue object.    (Issue.permissions)
          const { data } = await queryFulfilled;

          dispatch(
            issueSingleApi.util.updateQueryData(
              'getIssue',
              String(data?.id ?? ''),
              (issue) => {
                const state = getState();
                const user = state.user.toJS();
                const workspace = data?.workspace;
                const client = getEntity(state, entities.CLIENT.reduxProp);
                const flowConfig = flowConfigPath(state);

                const permissions = calculateIssuePermissions({
                  workspace,
                  user,
                  client,
                  flowConfig,
                  issue,
                });

                Object.assign(issue, { permissions });
              }
            )
          );
        } catch (e) {}
      },
    }),
    createIssue: builder.mutation({
      query(body) {
        return {
          url: `issue`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        const parentId = result?.issue?.parent_id;
        return [
          { type: 'Issue', parentId },
          { type: 'Issue', id: 'LIST' },
        ];
      },
    }),
    oldGalleryUpdateIssueAttachments: builder.mutation({
      query(data) {
        const { id, formData } = data;
        return {
          url: `issue/${id}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Issue', id }],
    }),
    updateIssueAttachments: builder.mutation({
      query(data) {
        const { id, formData } = data;

        return {
          url: `issue/${id}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [{ type: 'Issue', id }],
    }),
    getPublicIssue: builder.query({
      query: ({ id, qs }) => {
        if (!id) return;
        const url = `public/issue/detail/${id}${qs}`;
        return url;
      },

      // TODO: This has not been tested yet. (Groups and types was)
      providesTags: (result) =>
        result
          ? [{ type: 'Issue', id: result.id }]
          : [{ type: 'Issue', id: 'LIST' }],
    }),
    createPublicIssueComment: builder.mutation({
      query(data) {
        const { id, ...body } = data;

        return {
          url: `public/issue/${id}/comment`,
          method: 'POST',
          body: body,
        };
      },

      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Issue', id }];
      },
    }),
    updatePublicIssueCommentAttachments: builder.mutation({
      query(data) {
        const { issueId, commentId, formData } = data;

        return {
          url: `public/issue/${issueId}/comment/${commentId}/upload`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, { issueId }) => {
        return [{ type: 'Issue', issueId }];
      },
    }),
    updatePublicIssue: builder.mutation({
      query(data) {
        const { id, body = {} } = data;
        const { token, status_id } = body;
        const qs = new URLSearchParams();
        if (token) qs.append('token', token);
        if (status_id) qs.append('status_id', status_id);
        return {
          url: `public/issue/${id}?${qs.toString()}`,
          method: 'PUT',
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [{ type: 'Issue', id }];
      },
    }),
    getTimelogs: builder.query({
      query: (id) => {
        const url = `issue/${id}/timer`;

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'IssueTimelogs', id })),
              { type: 'IssueTimelogs', id: 'LIST' },
            ]
          : [{ type: 'IssueTimelogs', id: 'LIST' }],
    }),
    getActivity: builder.query({
      query: (params) => {
        const { id, ...query } = params;

        const url = qs.stringifyUrl(
          { url: `issue/${id}/activity_log`, query },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'IssueActivity', id })),
              { type: 'IssueActivity', id: 'LIST' },
            ]
          : [{ type: 'IssueActivity', id: 'LIST' }],
    }),
    createAttachmentComment: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `issue/${id}/attachment_comment`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => [{ type: 'IssueAttachmentComments', id: 'LIST' }],
    }),
    getAttachmentComments: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `issue/${id}/attachment_comment`;
        return url;
      },
      providesTags: (result) => [
        { type: 'IssueAttachmentComments', id: 'LIST' },
      ],
    }),
    getPublicAttachmentComments: builder.query({
      query: (id) => {
        if (!id) return;
        const url = `public/issue/${id}/attachment_comment`;
        return url;
      },
      providesTags: (result) => [
        { type: 'IssueAttachmentComments', id: 'LIST' },
      ],
    }),
    publicUnsubscribeWatcher: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `public/mail/unsubscribe/${id}`,
          method: 'POST',
          body,
        };
      },
    }),
    getProcessNumberValidation: builder.query({
      query: (number) => {
        if (!number) return;
        const url = `process_number/${number}/check_exists`;
        return url;
      },
    }),
    sendDeclinedIssueNotification: builder.mutation({
      query(data) {
        const { id, body } = data;

        return {
          url: `issue/${id}/external/send_declined_issue_notification`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Issue', id },
        { type: 'Issue', id: 'LIST' },
      ],
    }),
    odooSync: builder.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `/integration/odoo-sync/${id}`,
          method: 'POST',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetIssueQuery,
  useCreateIssueMutation,
  useUpdateIssueMutation,
  useOldGalleryUpdateIssueAttachmentsMutation,
  useUpdateIssueAttachmentsMutation,
  useGetPublicIssueQuery,
  useCreatePublicIssueCommentMutation,
  useUpdatePublicIssueCommentAttachmentsMutation,
  useUpdatePublicIssueMutation,
  useGetTimelogsQuery,
  useGetActivityQuery,
  useLazyGetActivityQuery,
  useCreateAttachmentCommentMutation,
  useGetAttachmentCommentsQuery,
  useGetPublicAttachmentCommentsQuery,
  usePublicUnsubscribeWatcherMutation,
  useGetProcessNumberValidationQuery,
  useSendDeclinedIssueNotificationMutation,
  useOdooSyncMutation,
} = issueSingleApi;
