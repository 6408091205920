import { createSelector } from '@reduxjs/toolkit';
import { entities } from '../../../config/config';
import {
  flattenAllWorkspaces,
  getAllUsers,
  getEntity,
  isUserAdmin,
} from '../../../redux/entities/selectors';
import { getUser } from '../../../redux/user/selectors';

export const getClientState = (state) =>
  getEntity(state, entities.CLIENT.reduxProp);

export const getIsGroupManagerEnabled = createSelector(
  getClientState,
  ({ settings }) => settings?.include_group_managers
);

export const getIsManager = createSelector(
  getUser,
  flattenAllWorkspaces,
  (user, workspaces) => {
    return workspaces?.some((workspace) => {
      return workspace.managers_ids.some((id) => id === user.id);
    });
  }
);

export const getIsGroupManager = createSelector(
  getUser,
  flattenAllWorkspaces,
  (user, workspaces) => {
    return workspaces?.some((workspace) => {
      return workspace.group_managers_ids.some((id) => id === user.id);
    });
  }
);

export const getIsAdminUser = createSelector(
  getUser,
  getAllUsers,
  (user, allUsers) => {
    const allAdmins = allUsers.filter(isUserAdmin);

    return allAdmins.some((adminUser) => adminUser.id === user.id);
  }
);

export const getIsRegularUser = createSelector(
  getIsAdminUser,
  getIsGroupManager,
  getIsManager,
  (isAdmin, isGroupManager, isManager) =>
    !(isAdmin || isGroupManager || isManager)
);

const getUsersState = (state) => state.users;

export const usersFiltersSelector = createSelector(
  getUsersState,
  (state) => state.filters
);

export const selectCanExportTimeBreak = createSelector(
  getClientState,
  getUser,
  ({ settings }, loggedInUser) =>
    settings?.include_time_break &&
    loggedInUser?.permissions?.global.includes('can_manage_users')
);
