import { MOBILE_OS } from '../../../../../../config/config';

export const TRANSLATION_KEYS = {
  [MOBILE_OS.ANDROID]: {
    paragraph: 'experience_full_potential_play_store',
    button: 'download_on_play_store',
  },
  [MOBILE_OS.IOS]: {
    paragraph: 'experience_full_potential_app_store',
    button: 'download_on_app_store',
  },
};
