import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { getAdvancedFilterState as equipmentGetAdvancedFilterState } from '../../Equipment/selectors';
import { getIsAdvancedFilterOpen as assetsGetIsAdvancedFilterOpen } from '../../Assets/selectors';
import useWindowSizeDebounced from '../../../../hooks/useWindowSizeDebounced';
import { internalRoutePaths } from '../../../../config/config';

const useLargeScreenWidth = () => {
  const location = useLocation();

  const equipmentIsAdvancedFilterOpen = useSelector(
    equipmentGetAdvancedFilterState
  );
  const assetsIsAdvancedFilterOpen = useSelector(assetsGetIsAdvancedFilterOpen);

  const { width } = useWindowSizeDebounced();

  let largeScreenWidth;

  if (width > 2300 && location?.pathname) {
    if (
      (location.pathname === internalRoutePaths.EQUIPMENT &&
        Number(equipmentIsAdvancedFilterOpen)) ||
      (location.pathname === internalRoutePaths.ASSETS &&
        Number(assetsIsAdvancedFilterOpen))
    )
      largeScreenWidth = 2200;
  }

  return { largeScreenWidth };
};

export default useLargeScreenWidth;
