import { API_TAG_TYPES_AUTOCOMPLETE } from '../../views/internal/templates/Autocomplete/constants';
import { API_TAG_TYPES_CHECKLIST_TEMPLATES } from '../../views/internal/templates/Checklists/constants';
import { API_TAG_TYPES_COLLABORATORS } from '../../views/internal/Collaborators/constants';
import { API_TAG_TYPES_PROFILE } from '../../views/internal/main/constants';
import { API_TAG_TYPES_WORKSPACE_DOCUMENTS } from '../../views/internal/workspaces/workspace/Documents/constants';
import { API_TAG_TYPES_SCHEDULE_ASSETS } from '../../views/internal/scheduling/schedule/components/Assets/constants';

export const tagTypes = [
  ...API_TAG_TYPES_AUTOCOMPLETE,
  ...API_TAG_TYPES_CHECKLIST_TEMPLATES,
  ...API_TAG_TYPES_COLLABORATORS,
  ...API_TAG_TYPES_PROFILE,
  ...API_TAG_TYPES_WORKSPACE_DOCUMENTS,
  ...API_TAG_TYPES_SCHEDULE_ASSETS,
];
