export const matchEntity = (entity, item) => {
  if (entity.id === item.id) {
    return {
      match: true,
      item,
    };
  }

  return { match: false };
};

export const matchChildren = (entity, item) => {
  if (entity.children && entity.children.length) {
    let child;

    for (
      let childIndex = 0;
      childIndex < entity.children.length;
      childIndex++
    ) {
      child = entity.children[childIndex];

      if (child.id === item.id) {
        entity.children[childIndex] = item;

        return {
          match: true,
          item: entity,
        };
      }
    }
  }

  if (entity.id === item.parent_id) {
    entity.children =
      Array.isArray(entity.children) && entity.children.length
        ? [...entity.children, item]
        : [item];

    return {
      match: true,
      item: entity,
    };
  }

  return { match: false };
};
