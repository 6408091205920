import { actionStrings } from './actions';
import { actionStrings as authActionStrings } from '../auth/actions';
import { fromJS } from 'immutable';

const PERSISTED_FILTERS = JSON.parse(
  localStorage.getItem('https://staging.wowflow.org-filters')
);
const initState = fromJS({
  showAdvancedFilter: PERSISTED_FILTERS?.showAdvancedFilter || false,
});

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.SET_FILTERS: {
      const currentFilter = state.get(action.payload.filterName) || {};

      return state.set(action.payload.filterName, {
        ...currentFilter,
        ...action.payload.parameters,
      });
    }
    case actionStrings.CLEAR_FILTER:
      return initState;
    case authActionStrings.LOGOUT:
      return initState;
    case actionStrings.TOGGLE_ADVANCED_FILTERS:
      return state.set('showAdvancedFilter', !state.get('showAdvancedFilter'));
    default:
      return state;
  }
};
