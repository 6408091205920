import { useMemo } from 'react';
import Collapsible from '../../../Collapsible';
import { useIntl } from 'react-intl';
import {
  PRIORITY_ITEMS,
  PRIORITY_VALUES,
} from '../../../../../FilterPriority/constants';
import useStaticFilterMultiselect from '../../../../../../hooks/useStaticFilterMultiselect';

const Priority = (props) => {
  const { value, labelKey, filterKey } = props;
  const intl = useIntl();

  const { countSelected, handleFilterChange } = useStaticFilterMultiselect(
    filterKey,
    PRIORITY_VALUES
  );

  const selectedOptions = useMemo(() => {
    return PRIORITY_ITEMS.filter((item) => value?.includes(item.value)).map(
      (item) => ({ ...item, name: intl.formatMessage({ id: item.labelKey }) })
    );
  }, [value, intl]);

  return (
    <Collapsible
      labelKey={labelKey}
      count={countSelected}
      items={selectedOptions}
      handleItemChange={(item) => handleFilterChange(item.value)}
    />
  );
};

export default Priority;
