import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { vestResolver } from '@hookform/resolvers/vest';
import Space from '../../../../../../components_new/Space';
import {
  WowDisplayFlex,
  WowLabelText,
  WowLink,
} from '../../../../../../components_new/WowBasicElements';
import {
  CtaWrapper,
  Footer,
  ProgressBar,
  SectionLeft,
  SectionRight,
  Sections,
  Title,
} from '../../styles';
import Input from '../../../../../../components_new/Input';
import { INPUT_COMMON_CONFIG } from '../../constants';
import Button from '../../../../../../components_new/Button';
import { userPillGray } from '../../../../../../config/colors';
import RightSectionInputPreview from '../RightSectionInputPreview';
import { formValidation } from './utils';
import { FORM_DEFAULT_VALUES, ISSUE_TITLE } from './constants';
import useCreateIssue from './hooks/useCreateIssue';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';

const CreateIssue = () => {
  const intl = useIntl();

  const formMethods = useForm({
    resolver: vestResolver(formValidation),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, formState, register } = formMethods;
  const { isValid } = formState;

  const { isLoading, issueTitle, handleContinue, handleSkip, firstSteps } =
    useCreateIssue({
      formMethods,
    });

  return (
    <Sections>
      <SectionLeft maxWidth="400">
        <ProgressBar width="57%" />
        <Space height="25" />
        <Title>
          {intl
            .formatMessage({ id: 'what_task_you_are_working_on' })
            .replace(':workspace_name', firstSteps?.workspaceName)}
        </Title>
        <Space height="40" />
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleContinue)}>
            <WowDisplayFlex align="flex-start" direction="column" width="330px">
              <WowLabelText bold>
                <FormattedMessage id="name_your_first_task" />
              </WowLabelText>
              <Input
                {...register(ISSUE_TITLE)}
                {...INPUT_COMMON_CONFIG}
                placeholder={intl.formatMessage({ id: 'eg_change_lightbulb' })}
              />
            </WowDisplayFlex>
            <Space height="30" />
            <Footer>
              <CtaWrapper data-testid="ctaWrapper">
                <Button
                  size="small"
                  color="blue"
                  disabled={!isValid || isLoading}
                >
                  <FormattedMessage id="continue" />
                </Button>
                {isLoading ? <LoadingSpinner small /> : null}
              </CtaWrapper>
              <Space width="25" />
              <WowLink color={userPillGray} size="13" onClick={handleSkip}>
                <FormattedMessage id="skip_for_now" />
              </WowLink>
            </Footer>
          </form>
        </FormProvider>
      </SectionLeft>
      <SectionRight data-testid="sectionRight">
        <RightSectionInputPreview
          label={`${intl.formatMessage({ id: 'no_' })} ${String(
            firstSteps?.workspaceId
          )}`}
          textSize="30"
          text={firstSteps?.workspaceName}
        />
        <Space height="30" />
        <RightSectionInputPreview
          label={intl.formatMessage({ id: 'issue' })}
          textSize="20"
          text={issueTitle}
          placeholder={intl.formatMessage({ id: 'eg_change_lightbulb' })}
        />
      </SectionRight>
    </Sections>
  );
};

export default CreateIssue;
