import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import { Wrapper, Header, FiltersWrapper } from './styles';
import Title from '../../../../components_new/StickyHeader/Title';
import { QuickFilter } from '../../../../components_new/StickyHeader';
import {
  LOGS_OPTIONS,
  PRINT_PAGE_STYLES,
  PRINT_PAGE_STYLES_ACTIVITY,
  PRINT_PAGE_STYLES_QR_SCANLOG,
  PRINT_PAGE_STYLES_QR_TIMELOG,
} from './constants';
import { useWorkspaces } from '../../Equipment/hooks';
import useSkipQuery from '../../../../hooks/useSkipQuery';
import { optionsWithIntl } from '../../Equipment/Equipment/utils';
import Activity from './Activity';
import QrCodeTimelog from './QrCodeTimelog';
import { DropdownMultipleWithSearch } from '../../../../components_new/Dropdown';
import useUserLogs from './hooks/useUserLogs';
import QrCodeScanlog from './QrCodeScanlog';

const UserLogs = () => {
  const intl = useIntl();

  const [getOptionsWorkspaces, setGetOptionsWorkspaces] = useState(false);
  const { skipQuery: skipQueryWorkspaces } = useSkipQuery({
    removeSkip: getOptionsWorkspaces,
  });

  const {
    scrollState: workspacesState,
    handleLoadMore,
    handleSearch: handleWorkspaceSearch,
    isFetchingWorkspaces,
  } = useWorkspaces({}, skipQueryWorkspaces);

  const {
    filters,
    setMultiselectFilter,
    clearMultiselectFilter,
    isQrTimeLog,
    activeQueryType,
    handleActiveQueryTypeChange,
    isQrScanLog,
  } = useUserLogs();

  const activityRef = useRef();
  const handlePrintActivity = useReactToPrint({
    content: () => activityRef.current,
    pageStyle: `${PRINT_PAGE_STYLES} ${PRINT_PAGE_STYLES_ACTIVITY}`,
  });

  const qrScanlogRef = useRef();
  const handlePrintQrScanlog = useReactToPrint({
    content: () => qrScanlogRef.current,
    pageStyle: `${PRINT_PAGE_STYLES} ${PRINT_PAGE_STYLES_QR_SCANLOG}`,
  });

  const qrTimelogRef = useRef();
  const handlePrintQrTimelog = useReactToPrint({
    content: () => qrTimelogRef.current,
    pageStyle: `${PRINT_PAGE_STYLES} ${PRINT_PAGE_STYLES_QR_TIMELOG}`,
  });

  return (
    <Wrapper>
      <Header>
        <Title>
          <FormattedMessage id="user_logs" defaultMessage="User Logs" />
        </Title>
        <FiltersWrapper>
          <QuickFilter
            onChange={handleActiveQueryTypeChange}
            value={activeQueryType}
            options={optionsWithIntl(LOGS_OPTIONS, intl)}
            position="right"
          />
          {isQrTimeLog ? (
            <DropdownMultipleWithSearch
              options={workspacesState.itemsList}
              handleLoadMore={handleLoadMore}
              hasMore={workspacesState.hasMore}
              handleSearch={handleWorkspaceSearch}
              handleChange={(value) =>
                setMultiselectFilter(value, 'workspace_ids')
              }
              handleClear={() => clearMultiselectFilter('workspace_ids')}
              isLoading={isFetchingWorkspaces}
              selected={
                filters['workspace_ids']?.map((item) => String(item.id)) || []
              }
              messageKey="workspaces"
              paramKey="workspace_ids"
              handleGet={() => setGetOptionsWorkspaces(true)}
              label={filters?.workspace_ids[0]?.name}
            />
          ) : null}
        </FiltersWrapper>
      </Header>

      {isQrTimeLog ? (
        <QrCodeTimelog ref={qrTimelogRef} handlePrint={handlePrintQrTimelog} />
      ) : isQrScanLog ? (
        <QrCodeScanlog
          type={activeQueryType}
          ref={qrScanlogRef}
          handlePrint={handlePrintQrScanlog}
        />
      ) : (
        <Activity
          type={activeQueryType}
          ref={activityRef}
          handlePrint={handlePrintActivity}
        />
      )}
    </Wrapper>
  );
};

export default UserLogs;
