import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useWindowSizeDebounced from '../../../../../hooks/useWindowSizeDebounced';
import { breakPoints, internalRoutePaths } from '../../../../../config/config';
import {
  setShowOnboardingSuccess,
  setShowOnboardingSuccessMobile,
} from '../../slice';
import { getShowOnboardingProcess } from '../../selectors';

const useTrialRedirect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const windowSize = useWindowSizeDebounced();
  const forceShowOnboardingProcess = useSelector(getShowOnboardingProcess);

  const handleRedirectTo = (workspaceId) => {
    if (windowSize?.width <= breakPoints.tabletWidth) {
      dispatch(setShowOnboardingSuccessMobile(true));
      return;
    }

    if (!forceShowOnboardingProcess) {
      dispatch(setShowOnboardingSuccess(true));
    }

    const url = workspaceId
      ? internalRoutePaths.WORKSPACES_ID.replace(':id', workspaceId)
      : internalRoutePaths.WORKSPACES;

    history.push(url);
  };

  return {
    handleRedirectTo,
  };
};

export default useTrialRedirect;
