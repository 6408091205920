import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import { filtersAdvancedGray, newBorder } from '../../config/colors';
import dict from '../../config/multilang';

import Menu from './Menu';

const MenuWithSearch = React.forwardRef(
  (
    { children, value, onChange, size, style, removeSearch, autoFocus },
    ref
  ) => {
    const intl = useIntl();

    return (
      <Menu ref={ref} style={style} size={size}>
        {!removeSearch ? (
          <Header>
            <Search>
              <Icon />
              <Input
                placeholder={intl.formatMessage({
                  id: 'search',
                  defaultMessage: dict.search,
                })}
                onChange={onChange}
                value={value}
                autoFocus={autoFocus ?? false}
              />
            </Search>
          </Header>
        ) : null}

        {children}
      </Menu>
    );
  }
);

export default MenuWithSearch;

const Search = styled.div`
  border: 1px solid ${newBorder};
  display: flex;
  border-radius: 6px;
  padding: 6px 8px;
`;

const Header = styled.div`
  padding: 12px;
`;

const Input = styled.input.attrs({ type: 'text' })`
  outline: none;
  width: 100%;
  color: ${filtersAdvancedGray};
  font-size: 14px;
`;

const Icon = styled.i.attrs({ className: 'icon icon-zoom' })`
  margin-right: 8px;
  color: ${filtersAdvancedGray};
`;
