import { FormattedMessage } from 'react-intl';

import { NoticeWithIcon } from '../Notice';
import {
  NoticeContent,
  NoticeText,
  NoticeControllsWrapper,
  SmallTextNotice,
  NoticeButton,
} from './styles';

const ActionNoticeBox = (props) => {
  const {
    noticeVariant = 'important',
    icon = 'icon-important-round',
    messageColor = 'white',
    messageKey,
    submessageKey,
    showActionComponent,
    actionComponent: ActionComponent,
    triggerActionComponent: TriggerActionComponent,
    actionButtonDisable,
    actionButtonSubmitHandler = () => {},
    actionButtonKey,
  } = props;

  return (
    <NoticeWithIcon icon={icon} color={messageColor} variant={noticeVariant}>
      <NoticeContent textColor={messageColor}>
        <NoticeText>
          <FormattedMessage id={messageKey} />
          {submessageKey && (
            <SmallTextNotice>
              (<FormattedMessage id={submessageKey} />)
            </SmallTextNotice>
          )}
        </NoticeText>
        <NoticeControllsWrapper>
          {showActionComponent ? (
            ActionComponent ? (
              ActionComponent
            ) : (
              <></>
            )
          ) : TriggerActionComponent ? (
            TriggerActionComponent
          ) : (
            <></>
          )}
          <NoticeButton
            color={messageColor}
            onClick={actionButtonSubmitHandler}
            disabled={actionButtonDisable}
          >
            <FormattedMessage id={actionButtonKey ?? ' '} />
          </NoticeButton>
        </NoticeControllsWrapper>
      </NoticeContent>
    </NoticeWithIcon>
  );
};

export default ActionNoticeBox;
