import dict from '../../../../config/multilang';

export const workspaceSubSortFieldFilterKeys = {
  SORT_FIELD_OPTION_UPDATED: 'updated_at',
  SORT_FIELD_OPTION_CREATED: 'created_at',
  SORT_FIELD_OPTION_TITLE: 'name',
};
export const workspaceSortFieldFilterKeys = {
  SORT_FIELD_OPTION_UPDATED: 'updated',
  SORT_FIELD_OPTION_CREATED: 'created',
  SORT_FIELD_OPTION_TITLE: 'name',
};

export const workspacesSortDirectionFilterKeys = {
  SORT_DIRECTION_OPTION_ASC: 'asc',
  SORT_DIRECTION_OPTION_DESC: 'desc',
};

export const workspaceSortOptions = [
  {
    value: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_UPDATED,
    label: {
      id: 'updated',
      defaultMessage: dict.updated,
    },
  },

  {
    value: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_CREATED,
    label: {
      id: 'creation',
      defaultMessage: dict.creation,
    },
  },
  {
    value: workspaceSortFieldFilterKeys.SORT_FIELD_OPTION_TITLE,
    label: {
      id: 'name',
      defaultMessage: dict.name,
    },
  },
];

export const workspaceSubSortOptions = [
  {
    value: workspaceSubSortFieldFilterKeys.SORT_FIELD_OPTION_UPDATED,
    label: {
      id: 'updated',
      defaultMessage: dict.updated,
    },
  },

  {
    value: workspaceSubSortFieldFilterKeys.SORT_FIELD_OPTION_CREATED,
    label: {
      id: 'creation',
      defaultMessage: dict.creation,
    },
  },
  {
    value: workspaceSubSortFieldFilterKeys.SORT_FIELD_OPTION_TITLE,
    label: {
      id: 'name',
      defaultMessage: dict.name,
    },
  },
];
export const workspaceFilterKeys = {
  OPTION_ALL: '',
  OPTION_ARCHIVED: 'archived',
};

export const workspaceStatuses = [
  {
    value: workspaceFilterKeys.OPTION_ALL,
    label: {
      id: 'filter_all',
      defaultMessage: dict.filter_all,
    },
  },
  {
    value: workspaceFilterKeys.OPTION_ARCHIVED,
    label: {
      id: 'archived',
      defaultMessage: dict.archived,
    },
  },
];

export const getFilteredSubWSs = (
  subWSs,
  search,
  { statusFilter, sortOrder, sortField }
) => {
  const filtered = subWSs.filter(
    (subWS) =>
      ((!statusFilter && !subWS.archived) || subWS[statusFilter]) &&
      subWS.name.toLowerCase().includes(search.toLowerCase())
  );

  return filtered.sort((subWS1, subWS2) => {
    if (sortOrder === 'asc') {
      if (sortField === 'name') {
        return subWS1.name.localeCompare(subWS2.name);
      }

      return new Date(subWS1[sortField]) - new Date(subWS2[sortField]);
    }
    if (sortField === 'name') {
      return subWS2.name.localeCompare(subWS1.name);
    }
    return new Date(subWS2[sortField]) - new Date(subWS1[sortField]);
  });
};

export const compareArraysOfObjects = (oldState, newState) => {
  const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
  return arraysEqual(oldState, newState);
};

export const compareArrays = (oldState, newState) =>
  JSON.stringify(oldState) === JSON.stringify(newState);
