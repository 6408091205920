import styled from 'styled-components/macro';

import {
  darkGray,
  calendarCardHoverColor,
} from '../../../../../../../config/colors';

export const SingleRowElement = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  height: 100%;
  color: ${({ textColor }) => textColor ?? 'white'};
  ${({ increaseHeightOnHover, hoverHeight }) =>
    increaseHeightOnHover && hoverHeight
      ? `
    &:hover {
      height: ${`${hoverHeight}px`};
      z-index: 2;
    }
  `
      : ''}
  padding: 5px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? darkGray};
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  transition: 300ms ease background-color;
  ${({ showBorder }) => (showBorder ? 'border: 1px solid #fff;' : '')}
  box-sizing: border-box;
  ${({
    showTopGradient,
    showRightGradient,
    showBottomGradient,
    showLeftGradient,
    backgroundColor,
  }) =>
    showTopGradient ||
    showRightGradient ||
    showBottomGradient ||
    showLeftGradient
      ? `
    &::after {
      display: block;
      content: '';
      position:absolute;
      ${
        showTopGradient
          ? `
          width: 100%;
          height: 5px;
          top: 0;
          left: 0;
          background: linear-gradient(to top, ${
            backgroundColor ?? darkGray
          }, #fff);
        `
          : ''
      }
      ${
        showLeftGradient
          ? `
          width: 5px;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(to left, ${
            backgroundColor ?? darkGray
          }, #fff);   
        `
          : ''
      }
      ${
        showRightGradient
          ? `
          width: 5px;
          height: 100%;
          top: 0;
          right: 0;
          background: linear-gradient(to right, ${
            backgroundColor ?? darkGray
          }, #fff);
        `
          : ''
      }
      ${
        showBottomGradient
          ? `
          width: 100%;
          height: 5px;
          bottom: 0;
          left: 0;
          background: linear-gradient(to bottom, ${
            backgroundColor ?? darkGray
          }, #fff);
        `
          : ''
      }
    }
  `
      : ''}
  &:hover {
    background-color: ${calendarCardHoverColor};
    color: white;
    &::after {
      ${({
        showTopGradient,
        showRightGradient,
        showBottomGradient,
        showLeftGradient,
      }) =>
        showTopGradient ||
        showRightGradient ||
        showBottomGradient ||
        showLeftGradient
          ? `
            ${
              showTopGradient
                ? `background: linear-gradient(to top, ${calendarCardHoverColor}, #fff);`
                : ''
            }
            ${
              showLeftGradient
                ? `background: linear-gradient(to left, ${calendarCardHoverColor}, #fff);`
                : ''
            }
            ${
              showRightGradient
                ? `background: linear-gradient(to right, ${calendarCardHoverColor}, #fff);`
                : ''
            }
            ${
              showBottomGradient
                ? `background: linear-gradient(to bottom, ${calendarCardHoverColor}, #fff);`
                : ''
            }
          `
          : ''}
    }
  }
`;
