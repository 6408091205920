import styled from 'styled-components/macro';

import { gray } from '../../../../../../../config/colors';

export const Wrapper = styled.div`
  padding: 5px;
  background-color: ${gray};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
