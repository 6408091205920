import React, { useEffect, useState, forwardRef, Fragment } from 'react';
import { usePopper } from 'react-popper';

import useVisible from '../../hooks/useVisible';
import useKeyPress from '../../hooks/useKeyPress';

import { MenuItem, SubMenuItem, MenuWithSearch } from '../Menu';
import {
  Button,
  DropdownIcon,
  ClearIcon,
  Icon,
  StyledDropdown,
  NoItems,
  LoaderWrapper,
  MenuWrapper,
  LabelWrapper,
  LabelButtonWrapper,
  LabelButtonInner,
} from './styles';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from '../InfiniteScroll';
import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner.component';
import { useDebounce } from '../../hooks/useDebounce';
import Input from '../Input';
import Tooltip from '../Tooltip';
import { WowIcon, WowTooltipWrapper } from '../WowBasicElements';
import { yellow } from '../../config/colors';

import useCachedOptions from '../../hooks/useCachedOptions';

const DropdownWithSearchAndInfiniteScroll = forwardRef(
  (
    {
      icon,
      onChange,
      options,
      value,
      disabled,
      customDropdownViewOptionsFilter,
      clearable,
      handleLoadMore,
      hasMore,
      handleSearch,
      isLoading,
      size,
      maxHeight,
      onModalOpen,
      onModalClose,
      handleGet,
      removeSearch,
      withInput,
      dataTestId,
      menuItemsDataTestId,
      skipReset = false,
      buttonRefElem = null,
      limitLabelWidth,

      placeholder = null,

      // cache
      withCache,
      menuWidth = '200px',
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
      // strategy: fixed ? 'fixed' : undefined,
    });

    const { ref: refUseVisible, isVisible, setIsVisible } = useVisible(false);

    const cachedOptions = useCachedOptions({
      options,
      skip: !withCache,
    });

    React.useEffect(() => {
      if (!isVisible && onModalClose) {
        onModalClose();
      }
    }, [isVisible, onModalClose]);

    useEffect(() => {
      if (isVisible && handleGet) handleGet();
    }, [isVisible, handleGet]);

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 1000);

    useKeyPress('Escape', () => {
      setIsVisible(false);
    });

    const handleClick = () => {
      if (disabled) {
        return;
      }

      if (!isVisible && onModalOpen) {
        onModalOpen();
      }

      setIsVisible((value) => !value);
    };

    const handleChange = (value) => () => {
      setIsVisible(false);
      onChange(value);
      setSearch('');
    };

    const handleSearchChange = (e) => {
      setSearch(e.target.value);
    };

    const handleClear = (e) => {
      e.stopPropagation();
      onChange({ value: null });
    };

    const [selected, setSelected] = useState();

    useEffect(() => {
      const flatOptions = [
        ...options,
        ...(withCache ? Object.values(cachedOptions) : []),
      ].flatMap((item) => {
        if (item.children?.length > 0) {
          return [item, ...item.children.map((childItem) => childItem)];
        } else {
          return [item];
        }
      });
      const valueInOptions = flatOptions.find((option) => option.id === value);

      if (valueInOptions?.label) setInputValue(valueInOptions.label);
      setSelected(valueInOptions);
    }, [options, value, withCache, cachedOptions]);

    const dropdownOptions = customDropdownViewOptionsFilter
      ? customDropdownViewOptionsFilter(options)
      : options;

    useEffect(() => {
      handleSearch(debouncedSearch);
    }, [debouncedSearch, handleSearch]);

    useEffect(() => {
      if (isVisible && !withInput) setSearch('');
    }, [isVisible, withInput]);

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
      if (!skipReset) setInputValue('');
      if (selected?.label) setInputValue(selected.label);
    }, [selected?.label, setInputValue, skipReset]);

    return (
      <StyledDropdown ref={refUseVisible} size={size}>
        {withInput ? (
          <Input
            onClick={handleClick}
            ref={setReferenceElement}
            value={inputValue}
            onChange={(e) => {
              handleSearchChange(e);
              setInputValue(e.target.value);
              onChange({ value: e.target.value });
            }}
          />
        ) : (
          <Button
            type="button"
            onClick={handleClick}
            ref={setReferenceElement}
            size={size}
            disabled={disabled}
            data-testid="dropdownButton"
          >
            <LabelButtonWrapper ref={buttonRefElem}>
              <LabelButtonInner
                data-testid={dataTestId}
                limitLabelWidth={limitLabelWidth}
              >
                {icon && <Icon className={`icon ${icon}`} />}
                {selected
                  ? selected.label
                  : placeholder ?? <FormattedMessage id="select" />}
              </LabelButtonInner>
              <div>
                {clearable && selected && !disabled ? (
                  <ClearIcon onClick={handleClear} size={size} />
                ) : null}
                {!disabled ? <DropdownIcon size={size} /> : null}
              </div>
            </LabelButtonWrapper>
          </Button>
        )}

        {isVisible ? (
          <MenuWrapper width={menuWidth}>
            <MenuWithSearch
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              value={search}
              onChange={handleSearchChange}
              size={size}
              removeSearch={removeSearch}
            >
              {dropdownOptions.length === 0 ? (
                isLoading ? (
                  <LoaderWrapper>
                    <LoadingSpinner />
                  </LoaderWrapper>
                ) : (
                  <NoItems>
                    <FormattedMessage id="no_items" />
                  </NoItems>
                )
              ) : (
                <InfiniteScroll
                  dataLength={dropdownOptions.length}
                  handleLoadMore={handleLoadMore}
                  hasMore={hasMore}
                  height={maxHeight || -1}
                >
                  {dropdownOptions.map((option) => {
                    return (
                      <Fragment key={option.value}>
                        <MenuItem
                          onClick={handleChange(option)}
                          selected={selected && option.value === selected.value}
                          tabIndex={0}
                          key={option.value}
                          data-testid={menuItemsDataTestId}
                          mainAccent
                        >
                          {option?.is_favorite && (
                            <Tooltip
                              tooltipContent={
                                <WowTooltipWrapper width="150">
                                  <FormattedMessage id="favorite_workspace" />
                                </WowTooltipWrapper>
                              }
                            >
                              <WowIcon
                                className="icon-star"
                                size="20"
                                color={yellow}
                                spaceRight="5"
                                spaceLeft="0"
                              />
                            </Tooltip>
                          )}
                          <LabelWrapper>{option.label}</LabelWrapper>
                          {selected && option.value === selected.value ? (
                            <i className="icon icon-check" />
                          ) : null}
                        </MenuItem>
                        {option.children?.length > 0 ? (
                          <>
                            {option.children.map((child) => {
                              return (
                                <SubMenuItem
                                  key={child.value}
                                  onClick={handleChange(child)}
                                  selected={
                                    selected && child.value === selected.value
                                  }
                                  tabIndex={0}
                                  mainAccent
                                >
                                  {child?.is_favorite && (
                                    <Tooltip
                                      tooltipContent={
                                        <WowTooltipWrapper width="150">
                                          <FormattedMessage id="favorite_workspace" />
                                        </WowTooltipWrapper>
                                      }
                                    >
                                      <WowIcon
                                        className="icon-star"
                                        size="20"
                                        color={yellow}
                                        spaceRight="5"
                                        spaceLeft="0"
                                      />
                                    </Tooltip>
                                  )}
                                  <LabelWrapper>{child.name}</LabelWrapper>
                                  {selected &&
                                  child.value === selected.value ? (
                                    <i className="icon icon-check" />
                                  ) : null}
                                </SubMenuItem>
                              );
                            })}
                          </>
                        ) : null}
                      </Fragment>
                    );
                  })}
                </InfiniteScroll>
              )}
            </MenuWithSearch>
          </MenuWrapper>
        ) : null}
      </StyledDropdown>
    );
  }
);

export default DropdownWithSearchAndInfiniteScroll;
