import {
  JPEG,
  uncompressableFileTypes,
} from '../components/upload-area/upload-helper';
import { gray, gradientBlue } from '../config/colors';
import { VISIBILITY_ICONS, VISIBILITY_TYPES } from '../config/constants';

const TO_DO = 'to do';
const SUBTASK_COMPLETED_ID = 8;

export const getNumberOfCommments = (issue) => {
  return issue.number_of_comments;
};

export const getNumberOfSubtasks = (issue) => {
  return issue && issue.children ? issue.children.length : 0;
};

export const getNumberOfCompletedSubtasks = (issue) => {
  const count = [];

  if (issue && issue.children) {
    issue.children.map((child) => {
      return child.status_id === SUBTASK_COMPLETED_ID
        ? count.push(child)
        : null;
    });
  }

  return count.length;
};

export const getCurrentStatus = (issue) => {
  return issue && issue.status && issue.status.name ? issue.status.name : TO_DO;
};

export const getCurrentPriority = (issue) => {
  return issue && issue.priority && issue.priority.name
    ? issue.priority.name
    : null;
};

export const cacheBy = (issue) => issue.id;

export const getIssueAdditionalInfo = (issue, additionalInfo, prop) => {
  if (!additionalInfo || additionalInfo.length === 0) {
    return '';
  }
  const info = additionalInfo.find((a) => a.id === [issue[prop]]);

  return info ? info.name : '';
};

export const padToSix = (number) =>
  number <= 999999 ? ('#00000' + number).slice(-6) : number;

export async function createFile(url, contentType, name = 'nofilename.jpg') {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    contentType,
  };

  return new File([data], name, metadata);
}

export const getIssueFormVisibilityIcon = (visibility) => {
  if (!visibility) return;

  if (visibility.toString() === VISIBILITY_TYPES.user.id) {
    return VISIBILITY_ICONS.invisible;
  } else {
    return VISIBILITY_ICONS.visible;
  }
};

export const getIssueFormVisibilityIconColor = (visibility) => {
  if (!visibility) return;

  if (visibility.toString() === VISIBILITY_TYPES.workspace.id) {
    return gray;
  } else return gradientBlue;
};

export const getIssueItemVisibilityIcon = (visibility) => {
  if (!visibility) return;

  if (visibility.toString() === VISIBILITY_TYPES.user.id) {
    return VISIBILITY_ICONS.invisible;
  } else if (visibility.toString() === VISIBILITY_TYPES.public.id) {
    return VISIBILITY_ICONS.visible;
  } else return null;
};

export const usersByCategory = (category, users) => {
  if (category && category.accessibility_id === 3) {
    return users.filter((user) =>
      category.accessibility_user_ids.includes(user.id)
    );
  }
  return users;
};

export const getIssueImages = (issue) => {
  if (!issue?.attachments) return [];

  // Determines which image to display (full image or thumbnail).
  // Not the best solution because
  // time difference between client and server time,
  // but maybe it is good enough.
  const newImageDateDiff = 60000 * 5; // 5 minutes in miliseconds
  const clientDate = new Date();
  const issueCreatedAt = new Date(issue.created_at);
  const issueUpdatedAt = new Date(issue.updated_at);
  const isNewIssue = Math.abs(clientDate - issueCreatedAt) < newImageDateDiff;
  const isUpdatedIssue =
    Math.abs(clientDate - issueUpdatedAt) < newImageDateDiff;

  return issue.attachments.map((att) =>
    isNewIssue ||
    isUpdatedIssue ||
    uncompressableFileTypes.includes(att.mime_type)
      ? { url: att.url, type: att.mime_type, alt: '' }
      : { url: att.web_mob_thumbnail_url, type: JPEG, alt: '' }
  );
};
