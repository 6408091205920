import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { getObjectUrl } from './helper';
import './media-renderer.scss';
import noImage from '../../../assets/img/no-image.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Document = styled.object`
  width: ${(props) => (props.thumbnail ? '150px' : '100%')};
  height: ${(props) =>
    props.fullSize ? '100%' : props.thumbnail ? '150px' : '500px'};
`;
const Image = styled.img`
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
`;

const ImagePlaceholder = styled.div`
  height: auto;
  width: auto;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MediaRendererBase = (props) => {
  const contentType = props.mimeType.split('/')[0];
  switch (contentType) {
    case 'image':
      return (
        <LazyLoadImage
          placeholder={<ImagePlaceholder>...loading</ImagePlaceholder>}
          src={props.url}
          alt={props.description}
        />
      );
    case 'video':
      return (
        <video controls>
          <source src={props.url} type={props.mimeType} />
        </video>
      );
    case 'audio':
      return (
        <audio controls>
          <source src={props.url} type={props.mimeType} />
        </audio>
      );
    case 'application': {
      return (
        <Document
          fullSize={props.fullSize}
          thumbnail={props.thumbnail}
          type={props.mimeType}
          data={getObjectUrl(props)}
        />
      );
    }
    default:
      return <Image src={noImage} alt={props.description} />;
  }
};

MediaRendererBase.propTypes = {
  mimeType: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
};

const isEqual = (prevProps, nextProps) => prevProps.url === nextProps.url;

export const MediaRenderer = React.memo(MediaRendererBase, isEqual);
