export const FILTER_LABEL_KEYS = {
  issue_type: 'type',
  status: 'status',
  workspaces_params: 'workspaces',
  schedule_id_params: 'scheduling',
  asset_ids_params: 'assets',
  asset_group_ids_params: 'asset_groups',
  asset_system_ids_params: 'asset_systems',
  users_any_role_params: 'all_roles',
  assignees_params: 'assigned_to',
  worked_on_by_params: 'worked_on_by',
  watchers_params: 'watchers',
  reporters_params: 'reporter',
  collabs_any_role_params: 'all_roles',
  assignees_companies_params: 'assigned_to',
  worked_on_by_companies_params: 'worked_on_by',
  watchers_companies_params: 'watchers',
  reporters_companies_params: 'reporter',
  priority_ids: 'priority',
  categories_params: 'category',
  filter_by_job_positions: 'position',
  reporters_externals_params: 'externals',
  date: 'date',
};

export const INPUT_MAX_LENGTH = 255;

export const FILTER_LABEL_PREFIX_KEYS = {
  users_any_role_params: 'users',
  assignees_params: 'users',
  worked_on_by_params: 'users',
  watchers_params: 'users',
  reporters_params: 'users',
  collabs_any_role_params: 'collaborators',
  assignees_companies_params: 'collaborators',
  worked_on_by_companies_params: 'collaborators',
  watchers_companies_params: 'collaborators',
  reporters_companies_params: 'collaborators',
};
