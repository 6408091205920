import ReactDatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';
import { StyleResetWrapper } from '../../../../../scheduling/schedule/components/DatePicker/DatePicker';
import { getDateLocaleForCurrentLanguage } from '../../../../../scheduling/schedule/utils';

const CalendarDatePicker = (props = {}) => {
  const {
    setShowCalendarDatePicker = () => {},
    handleCalendarPick = () => {},
    currentTime = new Date(),
    highlightDates = [],
  } = props;

  const datePickerRef = useRef();
  const intl = useIntl();

  useEffect(() => {
    if (datePickerRef?.current?.setOpen) datePickerRef.current.setOpen(true);
  }, []);

  return (
    <StyleResetWrapper>
      <ReactDatePicker
        customInput={<></>}
        locale={getDateLocaleForCurrentLanguage(intl)}
        ref={datePickerRef}
        onCalendarClose={() => {
          setShowCalendarDatePicker(false);
        }}
        onChange={handleCalendarPick}
        selected={new Date(currentTime).getTime()}
        highlightDates={[
          {
            'react-datepicker__day--highlighted-custom-calendar-weekly-datepicker':
              highlightDates,
          },
        ]}
      />
    </StyleResetWrapper>
  );
};

export default CalendarDatePicker;
