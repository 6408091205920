import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AutoSizer, List } from 'react-virtualized';

import IssueDetailsModal from '../IssueDetailsModal';
import ViewMoreModal from '../ViewMoreModal';
import ActionsMenu from './components/ActionsMenu';

import {
  Content,
  HeaderWrapper,
  MainTitle,
  SelectedTitle,
  ShowMoreLoaderWrapper,
  Title,
} from './styles';

import { HEADINGS, PREDEFINED_TASK, LIST_ID } from './constants';
import useYearly from '../Yearly/hooks/useYearly';
import { LoadingSpinner } from '../../../../../components/loading-spinner/loading-spinner.component';

import useModals from './hooks/useModals';
import useHandlers from './hooks/useHandlers';
import useList from './hooks/useList';
import useActions from './hooks/useActions';

const YearlyTimeline = ({ currentTime }) => {
  // avoid changing of reference of current time if not provided on each rerender
  const _currentTime = useMemo(() => currentTime ?? new Date(), [currentTime]);

  const { formatMessage } = useIntl();

  const { data, handleLoadMore, hasMore, query, isLoading, handleEdit } =
    useYearly({
      skip: true,
      preventReset: true,
    });

  const {
    modalRef,
    previewItem,
    scrollPosition,
    updateModalPosition,
    clearModalData,
    topValue,
    leftValue,

    // view all
    isFetchingCalendarEvents,
    isLoadingCalendarEvents,
    shouldDisplayViewMore,
    viewAllData,
  } = useModals({ query });

  const {
    actionItem,
    actionRef,
    leftValue: actionLeftValue,
    topValue: actionTopValue,
    updateActionMenuPosition,
  } = useActions();

  const { handleDeleteDataItem, handleUpdateDataItem } = useHandlers({
    data,
    handleEdit,
  });

  const loaderRef = useRef();

  const onPositionChange = useCallback(
    ({ clientHeight, scrollTop }) => {
      updateModalPosition({ clientHeight, scrollTop });
      updateActionMenuPosition({ clientHeight, scrollTop });
    },
    [updateActionMenuPosition, updateModalPosition]
  );

  const { renderRow, onScroll } = useList({
    currentTime: _currentTime,
    data,
    handleLoadMore,
    hasMore,
    isLoading,
    updateModalPosition,
    updatePosition: onPositionChange,
  });

  useEffect(() => {
    if (isLoading) {
      if (loaderRef?.current) {
        loaderRef.current.scrollIntoView({
          block: 'start',
        });
      }
    }
  }, [isLoading]);

  // #endregion
  return (
    <>
      <HeaderWrapper>
        {HEADINGS.map(({ id, title, isMain }) => {
          const TitleComponent = isMain ? MainTitle : Title;
          const content = isMain
            ? formatMessage({ id: title, defaultMessage: title })
            : formatMessage({ id: title, defaultMessage: title }).slice(0, 3);

          const isCurrent = _currentTime.getMonth() + 1 === id;
          return (
            <TitleComponent key={id} style={{ cursor: 'pointer' }}>
              {isCurrent ? <SelectedTitle>{content}</SelectedTitle> : content}
            </TitleComponent>
          );
        })}
      </HeaderWrapper>
      <Content $isLoading={isLoading}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                id={LIST_ID}
                width={width}
                height={height}
                rowHeight={36}
                rowRenderer={renderRow}
                rowCount={data.length ?? 50}
                overscanRowCount={3}
                onScroll={onScroll}
              />
            );
          }}
        </AutoSizer>
      </Content>
      {isLoading ? (
        <ShowMoreLoaderWrapper ref={loaderRef}>
          <LoadingSpinner small />
        </ShowMoreLoaderWrapper>
      ) : null}
      {previewItem && !shouldDisplayViewMore && (
        <IssueDetailsModal
          id={previewItem?.id}
          overrideData={{ ...(previewItem ?? {}) }}
          handleUpdateDataItem={(data) =>
            handleUpdateDataItem(data, previewItem?.index, previewItem?.month)
          }
          handleDeleteDataItem={(id) =>
            handleDeleteDataItem(id, previewItem?.index, previewItem?.month)
          }
          topValue={topValue}
          leftValue={leftValue}
          onClose={() => {
            clearModalData();
            scrollPosition.current.scrollTop =
              document.getElementById(LIST_ID).scrollTop;
          }}
          isScheduledPreparedTask={previewItem?.model === PREDEFINED_TASK}
          ref={modalRef}
        />
      )}
      {shouldDisplayViewMore && (
        <ViewMoreModal
          ref={modalRef}
          items={viewAllData?.data ?? []}
          onClose={() => {
            clearModalData();
            scrollPosition.current.scrollTop =
              document.getElementById(LIST_ID).scrollTop;
          }}
          topValue={topValue}
          leftValue={leftValue}
          handleUpdateDataItem={(data) =>
            handleUpdateDataItem(data, previewItem?.index, previewItem?.month)
          }
          handleDeleteDataItem={(id) =>
            handleDeleteDataItem(id, previewItem?.index, previewItem?.month)
          }
          isWeeklyBoxModal
          monthString={formatMessage({
            id: `-${previewItem?.month < 10 ? '0' : ''}${previewItem?.month}—`,
          })}
          yearString={query.year}
          isLoading={isLoadingCalendarEvents || isFetchingCalendarEvents}
          detailsModalPosition={{
            leftValue: 'auto',
            rightValue: '0',
            topValue: '0',
          }}
        />
      )}
      {!!actionItem?.scheduleId && (
        <ActionsMenu
          scheduleId={actionItem.scheduleId}
          ref={actionRef}
          left={actionLeftValue}
          top={actionTopValue}
        />
      )}
    </>
  );
};

export default YearlyTimeline;
