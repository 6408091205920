import { createSlice } from '@reduxjs/toolkit';

import { DENSITY } from '../../../config/config';
import { INIT_QUERY as INIT_QUERY_LISTING } from './constants';
import { INIT_QUERY as INIT_QUERY_TYPES } from './Types/constants';
import { INIT_QUERY as INIT_QUERY_GROUPS } from './Groups/constants';
import { INIT_QUERY as INIT_QUERY_EXTERNAL_HOLDERS } from './ExternalHolders/constants';

const PERSISTED_FILTERS = JSON.parse(
  localStorage.getItem('https://staging.wowflow.org-filters')
);

const INITIAL_STATE = {
  listing: {
    isAdvancedFilterOpen: 0,
    density: DENSITY.comfortable,
    query: INIT_QUERY_LISTING,
    filters: {
      group_ids: PERSISTED_FILTERS?.equipmentListing?.group_ids || [],
      type_ids: [],
      manager_ids: [],
      workspace_ids: [],
      holder_ids: [],
      reserved_for_user_ids: [],
    },
  },
  types: {
    query: INIT_QUERY_TYPES,
    filters: {},
  },
  groups: {
    query: INIT_QUERY_GROUPS,
    isAdvancedFilterOpen: 0,
    filters: {
      manager_ids: [],
      workspace_ids: [],
      reserved_for_user_ids: [],
    },
    ungroup: {
      isUngroupSuccessModalOpen: false,
      itemsCount: null,
    },
  },
  externalHolders: {
    query: INIT_QUERY_EXTERNAL_HOLDERS,
    filters: {},
    selectedItems: {},
  },
};

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: INITIAL_STATE,
  reducers: {
    toggleAdvancedFilter: (state) => {
      state.listing.isAdvancedFilterOpen = !state.listing.isAdvancedFilterOpen;
    },
    setAdvancedFilter: (state, action) => {
      const { isOpen, target = 'listing' } = action.payload;
      state[target].isAdvancedFilterOpen = isOpen;
    },
    toggleUngroupSuccessModal: (state, action) => {
      state.groups.ungroup = {
        isUngroupSuccessModalOpen:
          !state.groups.ungroup.isUngroupSuccessModalOpen,
        itemsCount: action?.payload,
      };
    },
    updateFilters: (state, action) => {
      const { query, target, key, items } = action.payload;

      state[target] = {
        ...state[target],
        query: {
          ...query,
          search: '',
        },
        filters: {
          ...state[target].filters,
          [key]: items,
        },
      };
    },
    updateQueryParams: (state, action) => {
      const { query, target } = action.payload;
      state[target] = {
        ...state[target],
        query,
      };
    },
    updateMultipleFilters: (state, action) => {
      const { query, target, filters } = action.payload;
      state[target] = {
        ...state[target],
        query: {
          ...query,
          search: '',
        },
        filters: {
          ...state[target].filters,
          ...filters,
        },
      };
    },
    removeFromFilters: (state, action) => {
      const { query, target, values } = action.payload;

      state[target] = {
        ...state[target],
        query: {
          ...query,
          search: '',
        },
        filters: {
          ...state[target].filters,
          ...values,
        },
      };
    },
    updateSelectedItems: (state, action) => {
      const { target, items } = action.payload;
      state[target] = {
        ...state[target],
        selectedItems: items,
      };
    },
  },
});

export const {
  toggleAdvancedFilter,
  setAdvancedFilter,
  updateFilters,
  removeFromFilters,
  toggleUngroupSuccessModal,
  updateMultipleFilters,
  updateQueryParams,
  updateSelectedItems,
} = equipmentSlice.actions;

export default equipmentSlice.reducer;
