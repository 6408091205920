import { useCallback } from 'react';
import { useInfiniteScrollData } from '../../../../hooks/useInfinite';
import { useLazyGetCollaborationsSimpleQuery } from '../service';

const useCollaboratorListSimpleInfinite = ({
  skip,
  customParams,
  preventReset,
  preferCacheValue,
  entityKey,
} = {}) => {
  const [getCollaborationsSimple] = useLazyGetCollaborationsSimpleQuery();

  const getItems = useCallback(
    async (params) => {
      if (skip) return;

      const result = await getCollaborationsSimple(
        params,
        preferCacheValue
      ).unwrap();

      return result;
    },
    [skip, getCollaborationsSimple, preferCacheValue]
  );

  const {
    data,
    hasMore,
    isLoading,
    handleLoadMore,
    total,
    handleSearch,
    handleDelete,
    query,
    handleUpdateQueryParams,
  } = useInfiniteScrollData({
    entityKey,
    getItems,
    skip,
    preventReset,
    customParams,
  });

  return {
    data,
    isLoading,
    handleLoadMore,
    hasMore,
    handleUpdateQueryParams,
    query,
    total,
    handleDelete,
    handleSearch,
  };
};

export default useCollaboratorListSimpleInfinite;
