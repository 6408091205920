import { useEffect, useState } from 'react';

const useSkipQuery = ({ initSkip = true, removeSkip }) => {
  const [skipQuery, setSkipQuery] = useState(initSkip);

  useEffect(() => {
    if (removeSkip) setSkipQuery(false);
    return () => setSkipQuery(false);
  }, [removeSkip, setSkipQuery]);

  return {
    skipQuery,
  };
};

export default useSkipQuery;
