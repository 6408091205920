import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';

import { ButtonsWrapper } from '../../../../../../../components_new/ModalDelete/styles';
import Space from '../../../../../../../components_new/Space';
import Button from '../../../../../../../components_new/Button';

const ActionButtons = ({ isLoading, toggle }) => {
  const form = useFormContext();
  const { isValid } = form.formState;

  return (
    <>
      <Space height="18" />
      <ButtonsWrapper>
        <Button
          type="submit"
          appearance="contained"
          size="small"
          disabled={!isValid || isLoading}
          data-testid="submitButton"
        >
          <FormattedMessage id="export" />
        </Button>

        <Button
          onClick={toggle}
          color="red"
          appearance="text"
          size="small"
          disabled={isLoading}
          type="button"
          data-testid="cancelButton"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default ActionButtons;
