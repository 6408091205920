import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserStatus from '../../../../components/user-status/user-status.component';
import { useHistory } from 'react-router';
import useModalState from './../../../../views/internal/Equipment/hooks/useModalState';
import ModalEditUser from '../../../../components_new/ModalEditUser/ModalEditUser';
import { Wrapper } from './styles';
import { EditProfile, AvatarWrapper, Name, Email } from './styles';
import Avatar from '../../../../components_new/Avatar/Avatar';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../redux/user/selectors';
import useRequestUserLocation from '../hooks/useRequestUserLocation';

const UserBasicInfo = ({ user }) => {
  const history = useHistory();
  const loggedInUser = useSelector(getUser);
  const isAdmin = loggedInUser?.role === 'admin';

  const {
    isLoadingRequestUserLocation,
    handleRequestUserLocation,
    requestUserLocation,
  } = useRequestUserLocation();

  const canManageUsers =
    loggedInUser?.permissions?.global.includes('can_manage_users');

  const { isVisible, toggleModalState } = useModalState(false);

  const handleClick = () => {
    if (loggedInUser.id === user.id) {
      history.push('/dashboard/settings/profile');
    } else {
      toggleModalState();
    }
  };

  return (
    <>
      <Wrapper>
        <AvatarWrapper>
          <Avatar imageUrl={user.profile_image} imageAlt={user.full_name} />
        </AvatarWrapper>

        <div>
          <Name>{user.full_name}</Name>
          <Email>{user.email}</Email>
          <UserStatus user={user} />
          {canManageUsers || loggedInUser.id === user.id ? (
            <EditProfile onClick={handleClick}>
              <FormattedMessage
                id="edit_profile"
                defaultMessage="Edit Profile"
              />
            </EditProfile>
          ) : null}
          {requestUserLocation && isAdmin ? (
            <EditProfile
              onClick={handleRequestUserLocation}
              disabled={isLoadingRequestUserLocation}
            >
              <FormattedMessage id="request_update" />
            </EditProfile>
          ) : null}
        </div>
      </Wrapper>
      {isVisible ? (
        <ModalEditUser
          user={user}
          isOpen={isVisible}
          toggleModal={toggleModalState}
        />
      ) : null}
    </>
  );
};

export default UserBasicInfo;
