import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { filtersAdvancedGray } from '../../config/colors';

const StyledWrapper = styled.span`
  color: ${filtersAdvancedGray};
  font-size: 12px;
`;

const Counter = ({ number }) => {
  return (
    <StyledWrapper>
      <FormattedMessage
        id="items"
        defaultMessage="<b>{number}</b> items"
        values={{ number, b: (chunks) => <b>{chunks}</b> }}
      />
    </StyledWrapper>
  );
};

export default Counter;
