import OverdueTabContent from './components/OverdueTabContent';
import NoDateTabContent from './components/NoDateTabContent';

export const UNSCHEDULED_FILTERS_TABS = {
  OVERDUE: {
    key: 'OVERDUE',
    titleKey: 'overdue',
    tabComponent: (props) => <OverdueTabContent {...props} />,
    sortOptions: [
      {
        value: 'created',
        label: 'creation',
      },
      {
        value: 'updated',
        label: 'updated',
      },
      {
        value: 'start_date',
        label: 'start_date',
      },
      {
        value: 'due_date',
        label: 'due_date',
      },
    ],
  },
  NO_DATE: {
    key: 'NO_DATE',
    titleKey: 'no_date',
    tabComponent: (props) => <NoDateTabContent {...props} />,
    sortOptions: [
      {
        value: 'created',
        label: 'creation',
      },
      {
        value: 'updated',
        label: 'updated',
      },
    ],
  },
};

export const INFINITE_SCROLL_INITIAL_STATE_QUERY = {
  page: 1,
  paginate_by: 30,
  sort_direction: 'asc',
};
