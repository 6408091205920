import styled from 'styled-components/macro';
import { confirmGreen } from '../../../../../../config/colors';

export const Wrapper = styled.div`
  background-color: ${confirmGreen};
  box-shadow: 0px 2px 10px #37465f26;
  height: 60px;
  min-height: 60px;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  z-index: 151;
  position: relative;
  color: white;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0px;

  span {
    font-size: 17px;
    color: white;
  }
`;

export const RightSide = styled.div`
  font-size: 14px;

  > div {
    position: relative;
    :hover {
      cursor: pointer;
    }

    > div {
      left: -20px !important;
    }
  }
`;
