import styled from 'styled-components/macro';
import { darkerGray, newRed } from '../../config/colors';
import Button from '../Button';

export const InfoWrapper = styled.div`
  color: ${darkerGray};
  font-size: 14px;
  word-break: break-word;
`;

export const StyledSecondaryActionButton = styled(Button)`
  color: ${newRed};

  &:hover {
    color: ${newRed};
  }
`;
