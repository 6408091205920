import { baseApi } from '../../../../config/rtk';

export const onboardingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateSteps: builder.mutation({
      query(payload) {
        return {
          url: `/onboarding/steps`,
          method: 'PATCH',
          body: payload,
        };
      },
    }),
    updateStepsStatus: builder.mutation({
      query(payload) {
        return {
          url: `/onboarding/steps/update-status`,
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
});

export const { useUpdateStepsMutation, useUpdateStepsStatusMutation } =
  onboardingApi;
