import React from 'react';
import { LoadingSpinner } from '../../../../../../../components/loading-spinner/loading-spinner.component';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../../../../../../components_new/Button';
import {
  Wrapper,
  SpinnerWrapper,
  InProgress,
  PleaseWait,
  ButtonWrapper,
} from './styles';

const ExportInProgress = ({ onCancel }) => {
  const t = useIntl();

  return (
    <Wrapper data-testid="exportInProgress">
      <SpinnerWrapper data-testid="loader">
        <LoadingSpinner />
      </SpinnerWrapper>
      <InProgress data-testid="header">
        {t.formatMessage({
          id: 'in_progress',
        })}
      </InProgress>
      <PleaseWait data-testid="message">
        {t.formatMessage({
          id: 'please_wait_generate_export',
        })}
      </PleaseWait>
      <ButtonWrapper>
        <Button
          type="button"
          onClick={onCancel}
          color="red"
          appearance="outlined"
          size="small"
          data-testid="actionButton"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

ExportInProgress.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ExportInProgress;
