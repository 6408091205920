import React from 'react';
import { WowTabs, WowTabsTrigger } from '../../../../../components_new/WowTabs';
import { FormattedMessage } from 'react-intl';
import Space from './../../../../../components_new/Space/index';
import { TabsList } from '../styles';

const Tabs = ({ tabs, category, handleChangeCategory }) => {
  return (
    <WowTabs value={category} onValueChange={handleChangeCategory}>
      <TabsList>
        {tabs.map((tab) => (
          <WowTabsTrigger value={tab.value} key={tab.id}>
            <i className={`icon ${tab.iconLabel}`} />
            <Space inline width={8} />
            <FormattedMessage id={tab.labelKey} />
          </WowTabsTrigger>
        ))}
      </TabsList>
    </WowTabs>
  );
};

export default Tabs;
