import { FormattedMessage } from 'react-intl';
import Collapsible from '../../../Collapsible';
import Item from '../../../Collapsible/Item';
import { WowColorizeText } from '../../../../../../../WowBasicElements';
import Space from '../../../../../../../Space';
import useStatus from './hooks/useStatus';

const Status = (props) => {
  const { value, filterKey } = props;

  const {
    selectedOptions,
    handleActiveStatusChange,
    handleArchivedStatusChange,
  } = useStatus({ value, filterKey });

  return (
    <Collapsible labelKey="status" count={selectedOptions?.count}>
      {selectedOptions.active?.length ? (
        <WowColorizeText fWeight="700" size="14">
          <FormattedMessage id="active" />
        </WowColorizeText>
      ) : null}

      {selectedOptions.active.map((item) => (
        <Item
          key={item.id}
          item={item}
          handleItemChange={(item) => handleActiveStatusChange(item.value)}
        />
      ))}
      <Space height="10" />
      {selectedOptions.archived?.length ? (
        <WowColorizeText fWeight="700" size="14">
          <FormattedMessage id="archived" />
        </WowColorizeText>
      ) : null}
      {selectedOptions.archived.map((item) => (
        <Item
          key={item.id}
          item={item}
          handleItemChange={(item) => handleArchivedStatusChange(item.value)}
        />
      ))}
    </Collapsible>
  );
};

export default Status;
