import React, { useCallback, useMemo } from 'react';

import { GallerySlider } from './gallery-slider/gallery-slider.component';
import { GalleryListView } from './gallery-list-view/gallery-list-view.component';
import { GalleryModal } from './gallery-modal/gallery-modal';
import { Btn } from '../btn/btn.component';
import UploadArea from '../upload-area/upload-area.component';
import dict from '../../config/multilang';
import { RenderIf } from '../render-if/render-if.component';
import GallerySingleView from './gallery-single-view/gallery-single-view.component';

import {
  compressableFileTypes,
  PDF,
  uncompressableFileTypes,
} from '../upload-area/upload-helper';
import './gallery.scss';
import {
  DocumentListAndAction,
  SliderWrapper,
  DocumentListWrapper,
} from './gallery.styles';
import { GalleryLoader } from './gallery-loader';
import { getLocalLink } from '../../services/IssueService';
import { downloadFile } from '../../components_new/MediaInput/util';

// try to use document list
import DocumentsList from '../../components_new/MediaInput/components/DocumentsList';
import { getFileIcon } from '../../components_new/MediaInput/util';
import { hardWhite } from '../../config/colors';

const Gallery = (props) => {
  const {
    // props
    isPublic,
    user,
    enableOrdering,
    onUpload,
    permissions,
    isSubtaskPopup,
    images,
    allAttachments,
    id,
    documents,
    intl,
    isGalleryOpen: isGalleryOpenProp,
    toggleGalleryPopup,
    canNavigatePrev: canNavigatePrevProp,
    canNavigateNext: canNavigateNextProp,
    onNavigatePrev,
    onNavigateNext,
    onPopupCancel,
    galleryChanged,
    showSaveOnDescription,
    issueAttachmentsComments,
    isNewItem,
    props: oldProps,
    // fns & vars
    imagesNotLoading,
    hasImages,
    handleGalleryChanged,
    handleItemSelect,
    handleShowListView,
    hasAttachment,
    selectedDocument,
    showListView: showListViewProp,
    onHandleItemDescription,
    handleItemRemoved,
    toggleListView,
    countAttachments,
    handleSaveDescription,
    handlePostGalleryComment,
    handleListItemSelect,
    handleListItemRemoved,
    handleDragAndDropSort,
    onHandleListItemDescription,
    onPriorityChange,
    onDeadlineChange,
    prioritySelector,
    isUsingAttachmentDueDate,
    isUsingAttachmentPriority,
    customPdfMaxSize,
    triggerUploadRefElement,
    wrapperMargin,
    rawAttachments,
    showOnlyPreview,
  } = props;

  const [selectedItem, setSelectedItem] = React.useState(selectedDocument);
  const [showListView, setShowListView] = React.useState(showListViewProp);
  const [isGalleryOpen, setIsGalleryOpen] = React.useState(isGalleryOpenProp);

  React.useEffect(() => {
    setSelectedItem(selectedDocument);
  }, [selectedDocument]);
  React.useEffect(() => {
    setShowListView(showListViewProp);
  }, [showListViewProp]);
  React.useEffect(() => {
    setIsGalleryOpen(isGalleryOpenProp);
  }, [isGalleryOpenProp]);

  const canNavigateNext =
    canNavigateNextProp ||
    (selectedItem ? selectedItem.index < allAttachments?.length - 1 : false);
  const canNavigatePrev =
    canNavigatePrevProp || (selectedItem ? selectedItem.index > 0 : false);

  const navigatePrevHandler = async () => {
    if (!canNavigatePrev) return;

    if (onNavigatePrev) {
      onNavigatePrev();
    } else {
      const nextItem = allAttachments[selectedItem.index - 1]
        ? { ...allAttachments[selectedItem.index - 1] }
        : null;
      if (nextItem?.mime_type === PDF) {
        nextItem.url = await getLocalLink(nextItem.url);
      }
      setSelectedItem(nextItem);
    }
  };

  const navigateNextHandler = async () => {
    if (!canNavigateNext) return;

    if (onNavigateNext) {
      onNavigateNext();
    } else {
      const nextItem = allAttachments[selectedItem.index + 1]
        ? { ...allAttachments[selectedItem.index + 1] }
        : null;
      if (nextItem?.mime_type === PDF) {
        nextItem.url = await getLocalLink(nextItem.url);
      }
      setSelectedItem(nextItem);
    }
  };

  const itemSelectHandler = async (item) => {
    if (!item) return;
    const i = { ...item };

    const fileMimeType = i?.mime_type ?? i?.mimeType;

    // case other files than PDF download it on click
    if (
      fileMimeType !== PDF &&
      uncompressableFileTypes.includes(fileMimeType)
    ) {
      return downloadFile({ ...i, name: i?.fileName || i?.name });
    }

    const forcePdfDownload = !navigator?.pdfViewerEnabled;
    if (fileMimeType === PDF && forcePdfDownload)
      return downloadFile({ ...i, name: i?.fileName || i?.name });

    if (handleItemSelect) {
      handleItemSelect(i);
    } else {
      if (i?.mime_type === PDF) {
        i.url = await getLocalLink(i.url);
      }
      setSelectedItem(i);
      setShowListView(false);
      setIsGalleryOpen((currentValue) => !currentValue);
    }
  };

  const toggleGalleryPopupHandler = () => {
    if (toggleGalleryPopup) {
      toggleGalleryPopup();
    } else {
      setIsGalleryOpen((currentValue) => !currentValue);
    }
  };

  const showListViewHandler = async (item) => {
    if (!item) return;
    const i = { ...item };
    if (handleShowListView) {
      handleShowListView(i);
    } else {
      if (i?.mime_type === PDF) {
        i.url = await getLocalLink(i.url);
      }
      setShowListView(true);
      setSelectedItem(i);
      toggleGalleryPopupHandler();
    }
  };

  const popupCancelHandler = () => {
    if (onPopupCancel) {
      onPopupCancel();
    } else {
      setIsGalleryOpen(false);
    }
  };

  const toggleListViewHandler = () => {
    if (toggleListView) {
      toggleListView();
    } else {
      setShowListView((prev) => !prev);
    }
  };

  const listItemSelectHandler = async (item) => {
    if (!item) return;
    const i = { ...item };
    if (handleListItemSelect) {
      handleListItemSelect(i);
    } else {
      if (i?.mime_type === PDF) {
        i.url = await getLocalLink(i.url);
      }
      setSelectedItem(i);
      setShowListView(false);
    }
  };

  const transformedDocuments = useMemo(() => {
    return (documents?.toArray?.() ?? documents).map((doc) => ({
      ...doc,
      // handle different properties for mime type of files
      icon: getFileIcon(doc.mime_type ?? doc?.mimeType ?? doc?.type),
      // handle different properties for fileName
      name: doc?.fileName ?? doc?.original_filename ?? doc?.name,
    }));
  }, [documents]);

  const onRemoveDocument = useCallback(
    (document) => () => {
      if (handleListItemSelect && handleItemRemoved) {
        handleListItemSelect(document);
        handleItemRemoved();
      }
    },
    [handleListItemSelect, handleItemRemoved]
  );

  return (
    <>
      {!showOnlyPreview && (
        <>
          <GalleryLoader {...oldProps} />
          {imagesNotLoading && !hasImages ? (
            <UploadArea
              onUpload={(e) => {
                if (handleGalleryChanged) handleGalleryChanged();
                onUpload(e);
              }}
              disabled={!isNewItem && !permissions?.canAddAttachments}
              customPdfMaxSize={customPdfMaxSize}
              triggerUploadRefElement={triggerUploadRefElement}
            />
          ) : null}

          {hasImages ? (
            <SliderWrapper
              className="image-list"
              data-test="gallery-slider"
              isSubtaskPopup={isSubtaskPopup}
              wrapperMargin={wrapperMargin}
            >
              <GallerySlider
                items={images}
                onGalleryChanged={handleGalleryChanged}
                onItemSelect={itemSelectHandler}
                id={id}
                showListView={showListViewHandler}
                isSubtaskPopup={isSubtaskPopup}
              />
            </SliderWrapper>
          ) : null}

          <DocumentListAndAction $isPublic={isPublic}>
            <DocumentListWrapper>
              <DocumentsList
                list={transformedDocuments}
                disabled={isPublic}
                onDocumentClick={itemSelectHandler}
                onRemove={onRemoveDocument}
                documentsBackgroundColor={hardWhite}
                variant="normal"
              />
            </DocumentListWrapper>

            {hasAttachment ? (
              <UploadArea
                onUpload={(e) => {
                  if (handleGalleryChanged) handleGalleryChanged();
                  onUpload(e);
                }}
                noContent={true}
                customPdfMaxSize={customPdfMaxSize}
                disabled={!isNewItem && !permissions?.canAddAttachments}
                buttonOnly
              >
                {permissions?.canAddAttachments || isNewItem ? (
                  <div ref={triggerUploadRefElement}>
                    <Btn
                      secondaryAccent
                      small
                      heightAdaptible
                      className="no-margin"
                      name={`+ ${intl.formatMessage({
                        id: 'add_media',
                        defaultMessage: dict.add_media,
                      })}`}
                      adaptible
                      type="button"
                    />
                  </div>
                ) : null}
              </UploadArea>
            ) : null}
          </DocumentListAndAction>
        </>
      )}

      <GalleryModal
        isOpen={isGalleryOpen}
        toggleGalleryPopup={toggleGalleryPopupHandler}
        onRequestClose={toggleGalleryPopupHandler}
      >
        <RenderIf
          if={selectedItem && !showListView}
          then={() => (
            <GallerySingleView
              isPublic={isPublic}
              user={user}
              key={selectedItem?.id}
              canEdit={selectedItem.allowedToEdit}
              canRemove={selectedItem.allowedToDelete}
              document={selectedItem}
              canNavigatePrev={canNavigatePrev}
              canNavigateNext={canNavigateNext}
              navigatePrev={navigatePrevHandler}
              navigateNext={navigateNextHandler}
              onCancel={popupCancelHandler}
              onDescriptionChange={onHandleItemDescription}
              onRemoveItem={handleItemRemoved}
              galleryChanged={galleryChanged}
              handleListViewToggle={toggleListViewHandler}
              attachmentCount={countAttachments}
              showSaveOnDescription={showSaveOnDescription}
              onSaveDescriptionClick={handleSaveDescription}
              comments={
                issueAttachmentsComments
                  ? issueAttachmentsComments[selectedItem.id]
                  : null
              }
              onPostGalleryCommentClick={handlePostGalleryComment}
              isNewItem={isNewItem}
              onPriorityChange={onPriorityChange}
              onDeadlineChange={onDeadlineChange}
              prioritySelector={prioritySelector}
              isUsingAttachmentDueDate={isUsingAttachmentDueDate}
              isUsingAttachmentPriority={isUsingAttachmentPriority}
            />
          )}
        />
        <RenderIf
          if={hasImages && showListView}
          then={() => (
            <GalleryListView
              isPublic={isPublic}
              items={
                rawAttachments
                  ? rawAttachments
                  : images.filter((item) =>
                      compressableFileTypes.includes(
                        item.mimeType || item.mime_type
                      )
                    )
              }
              enableOrdering={enableOrdering}
              onGalleryChanged={handleGalleryChanged}
              onItemSelect={listItemSelectHandler}
              onCancel={popupCancelHandler}
              handleListViewToggle={toggleListViewHandler}
              handleListItemRemoved={handleListItemRemoved}
              onSortEnd={handleDragAndDropSort}
              placeholder={intl.formatMessage({
                id: 'type_description',
                defaultMessage: dict.type_description,
              })}
              handleDescChange={onHandleListItemDescription}
              showSaveOnDescription={showSaveOnDescription}
              onSaveDescriptionClick={handleSaveDescription}
            />
          )}
        />
      </GalleryModal>
    </>
  );
};

export default Gallery;
