import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { vestResolver } from '@hookform/resolvers/vest';
import { InputRow, CheckboxLabel } from '../../styles';
import { getDefaultValues, EXPORT_TYPES } from '../../utils';
import formValidation from '../../formValidation';
import { WowCheckbox } from '../../../../../../../../components/wow-checkbox';
import Input from '../../../../../../../../components_new/Input';
import { WowLabelText } from '../../../../../../../../components_new/WowBasicElements';
import { gradientBlue } from '../../../../../../../../config/colors';
import { useEffect } from 'react';
import Button from '../../../../../../../../components_new/ButtonAdd';

const Standard = ({ submitHandler, issue, renderActionButtons }) => {
  const intl = useIntl();

  const formMethods = useForm({
    defaultValues: getDefaultValues(issue, EXPORT_TYPES.standard),
    resolver: vestResolver(formValidation),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { control, watch, handleSubmit, setValue, getValues, trigger } =
    formMethods;

  const sendEmailCopy = watch('send_email_copy');
  const email_addresses = watch('email_addresses');
  const hasSubtasks = issue.children?.length > 0;

  useEffect(() => {
    if (sendEmailCopy) {
      setValue('email_addresses', [{ id: 1, value: '' }]);
    } else {
      setValue('email_addresses', []);
    }
  }, [sendEmailCopy, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submitHandler)}>
        {hasSubtasks ? (
          <InputRow>
            <Controller
              control={control}
              name="include_subtasks"
              render={({ field }) => {
                return (
                  <WowCheckbox
                    label={
                      <CheckboxLabel>
                        {intl.formatMessage({ id: 'include_subtasks' })}
                      </CheckboxLabel>
                    }
                    {...field}
                    checked={field.value}
                    size={18}
                    borderColor={gradientBlue}
                  />
                );
              }}
            />
          </InputRow>
        ) : null}
        <InputRow>
          <Controller
            control={control}
            name="send_email_copy"
            render={({ field }) => {
              return (
                <WowCheckbox
                  label={
                    <CheckboxLabel>
                      {intl.formatMessage({ id: 'send_copy' })}
                    </CheckboxLabel>
                  }
                  {...field}
                  checked={field.value}
                  size={18}
                  borderColor={gradientBlue}
                  data-testid="sendCopy"
                />
              );
            }}
          />
        </InputRow>
        {sendEmailCopy ? (
          <>
            {email_addresses.map((item, index) => {
              return (
                <InputRow key={item.id}>
                  <WowLabelText bold>
                    {intl.formatMessage({ id: 'email_address' })} - {item.id}
                  </WowLabelText>
                  <Controller
                    control={control}
                    name="email_addresses"
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          value={field?.value[index]?.value}
                          style={{ width: 280 }}
                          onChange={(e) => {
                            const value = e?.target?.value;
                            const email_addresses =
                              getValues('email_addresses');
                            email_addresses[index].value = value;
                            setValue('email_addresses', email_addresses);
                            trigger();
                          }}
                          data-testid="emailInput"
                        />
                      );
                    }}
                  />
                </InputRow>
              );
            })}
            {email_addresses.length <= 10 && (
              <Button
                size="small"
                appearance="text"
                color="blue"
                style={{ marginLeft: -10, marginTop: -5 }}
                onClick={() => {
                  const email_addresses = getValues('email_addresses');
                  email_addresses.push({
                    id: email_addresses.length + 1,
                    value: '',
                  });
                  setValue('email_addresses', email_addresses);
                  trigger();
                }}
                data-testid="addEmailButton"
              >
                <FormattedMessage id="add_another_email" />
              </Button>
            )}
          </>
        ) : (
          ''
        )}
        {renderActionButtons()}
      </form>
    </FormProvider>
  );
};

export default Standard;
