import { useGetUserQuery } from '../../users/service';
import { useParams } from 'react-router';
import { RTK_HOOK_CONFIG } from '../../../../config/constants';

const useUser = () => {
  const { id } = useParams();

  const { data: response, isFetching } = useGetUserQuery(id, {
    ...RTK_HOOK_CONFIG,
    skip: false,
  });

  return {
    response,
    isFetching,
  };
};

export default useUser;
