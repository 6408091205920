import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { formatTimestampFullDateAndTime } from '../../../../../../helpers/date.helper';

import Tooltip from '../../../../../../components_new/Tooltip';
import NoAvatar from '../../../../../../assets/img/no-avatar.jpg';
import { darkGray } from '../../../../../../config/colors';
import {
  Text,
  Title,
  ItemContainer,
  ItemContentContainer,
  ImageContainer,
  Image,
  ImageType,
  TypeIcon,
  TextContainer,
  TimeStamp,
  DotContainer,
  Dot,
} from './styles';
import { normalizeNotificationData } from '../utils';

const NotificationItem = ({
  data,
  index,
  title,
  onNotificationSelect,
  toggleReadNotification,
}) => {
  const intl = useIntl();
  const [isReadState, setIsReadState] = useState(
    normalizeNotificationData(data)
  );

  const dataLengthRef = useRef(data.length);

  useEffect(() => {
    if (dataLengthRef.current === data.length) return;

    setIsReadState(normalizeNotificationData(data));
    dataLengthRef.current = data.length;
  }, [data]);

  const checkNotificationConnectionToUser = (notification) => {
    const notificationData = notification.data.notification.data;

    return (
      notificationData.workspace_admin_full_name ||
      notificationData.user_full_name ||
      notificationData.assignee_full_name ||
      notificationData.creator_full_name
    );
  };

  const styleNotificationsContent = (notification) => {
    let text = notification.text;
    const creator = checkNotificationConnectionToUser(notification);
    const title = notification.data.notification.data.issue_title;

    if (creator) {
      text = text.replace(
        creator,
        `<span style=color:${darkGray};font-weight:600;font-size:15px;>` +
          creator +
          '</span>'
      );
    }
    text = text.replace(
      title,
      `<span style=color:${darkGray};font-weight:600;font-size:15px;>` +
        title +
        '</span>'
    );

    return <Text dangerouslySetInnerHTML={{ __html: text }}></Text>;
  };

  const getNotificationIcon = (item) => {
    switch (item.category) {
      case 'issue':
        return 'task';
      case 'schedule':
        return 'scheduling';
      case 'asset':
        return 'assets';
      case 'survey':
        return 'time';

      default:
        return item.category ?? 'workspace';
    }
  };

  const handleReadNotification = async (item) => {
    setIsReadState((prev) => ({ ...prev, [item.id]: !prev[item.id] }));

    toggleReadNotification(item.id, isReadState[item.id]);
  };

  return (
    <div key={index}>
      <Title>{title}</Title>
      {data.map((item) => {
        return (
          <ItemContainer key={item.id}>
            <ItemContentContainer onClick={() => onNotificationSelect(item)}>
              <ImageContainer>
                <Image
                  src={item.data.image ? item.data.image : NoAvatar}
                  alt=""
                />

                <ImageType>
                  <TypeIcon
                    className={
                      item.data.type === 'qr_scan_wrong_location'
                        ? 'icon-directions'
                        : `icon-${getNotificationIcon(item)}`
                    }
                  />
                </ImageType>
              </ImageContainer>
              <TextContainer>
                {styleNotificationsContent(item)}
                <TimeStamp>
                  {formatTimestampFullDateAndTime(item.created_at, intl.locale)}
                </TimeStamp>
              </TextContainer>
            </ItemContentContainer>
            {isReadState[item.id] ? (
              <Tooltip
                arrowProps={{ offset: 5 }}
                tooltipContent={
                  <div>
                    {intl.formatMessage({
                      id: 'mark_unread',
                    })}
                  </div>
                }
              >
                <DotContainer onClick={() => handleReadNotification(item)} />
              </Tooltip>
            ) : (
              <DotContainer onClick={() => handleReadNotification(item)}>
                <Dot />
              </DotContainer>
            )}
          </ItemContainer>
        );
      })}
    </div>
  );
};

export default NotificationItem;
