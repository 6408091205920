import styled from 'styled-components/macro';

const Space = ({ height, width, inline = false }) => {
  return <StyledSpace height={height} width={width} inline={inline} />;
};

export default Space;

const StyledSpace = styled.div`
  ${({ height }) => (height ? `height: ${height}px` : null)};
  ${({ width }) => (width ? `width: ${width}px` : null)};
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
`;
