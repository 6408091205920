import { createSelector } from '@reduxjs/toolkit';
import { checklistsApi } from './components/AddEditWrapper/components/SectionLeft/Checklists/service';
import { issueSingleApi } from './service';

const getIssuesState = (state) => state.issues;

const selectPublicIssueResult = (query) =>
  issueSingleApi.endpoints.getPublicIssue.select(query);

export const selectPublicIssue = (query) =>
  createSelector(
    selectPublicIssueResult(query),
    (issueResult) => issueResult?.data
  );

const selectIssueResult = (id) => issueSingleApi.endpoints.getIssue.select(id);

export const selectIssue = (id) =>
  createSelector(selectIssueResult(id), (issueResult) => issueResult?.data);

export const selectCreateFormUnsavedData = createSelector(
  getIssuesState,
  (state) => state.createFormUnsavedData
);

const selectChecklistsResult = (id) =>
  checklistsApi.endpoints.getChecklists.select(id);

export const selectChecklists = (id) =>
  createSelector(
    selectChecklistsResult(id),
    (checklistsResult) => checklistsResult?.data
  );

export const selectIsAddChecklistFormOpen = createSelector(
  getIssuesState,
  (state) => state.isAddChecklistFormOpen
);

export const selectPinnedNotes = createSelector(
  getIssuesState,
  (state) => state.tabs.notes.pinned
);
