import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { dateFormats } from '../../../../../../../config/config';
import {
  darkGray,
  issueFutureTask,
  newRed,
  statusColors,
} from '../../../../../../../config/colors';
import { CALENDAR_VIEW_TYPES } from '../../../../constants';
import { getActiveView } from '../../../../selectors';

import { Wrapper, SingleRowElement } from './styles';
import { WowTextBold } from '../../../../../../../components_new/WowBasicElements';

const IssueCardInfo = (props) => {
  const { item = {}, hours = 0 } = props;

  const wrapperElem = useRef();
  const contentElem = useRef();
  const intl = useIntl();

  const title = item?.title;
  const workspaceName = item?.workspace?.name ?? null;
  const assigneeName = item?.assignee?.full_name ?? null;
  let onlyStartTime, onlyDueTime, onlyDate, fullTime;

  const activeView = useSelector(getActiveView);

  if (item.due_date && !item.start_date) {
    onlyDueTime = dayjs(item.due_date * 1000).format(dateFormats.HOURS);
    onlyDate = dayjs(item.due_date * 1000).format(dateFormats.STANDARD_DATE);
  } else if (!item.due_date && item.start_date) {
    onlyStartTime = dayjs(item.start_date * 1000).format(dateFormats.HOURS);
    onlyDate = dayjs(item.start_date * 1000).format(dateFormats.STANDARD_DATE);
  } else if (item.start_date && item.due_date) {
    fullTime = `${dayjs(item.start_date * 1000).format(
      dateFormats.HOURS
    )} - ${dayjs(item.due_date * 1000).format(dateFormats.HOURS)}`;
    onlyDate = dayjs(item.start_date * 1000).format(dateFormats.STANDARD_DATE);
  }

  const isFutureTask =
    item?.is_scheduled_prepared_task || item?.status_id === 0;
  const backgroundColor = isFutureTask
    ? issueFutureTask
    : statusColors[item?.status?.name];

  const gradientsShow = {
    top: false,
    right: false,
    bottom: false,
    left: false,
  };

  if (
    activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
  ) {
    if (!item.due_date) gradientsShow.right = true;
    if (!item.start_date) gradientsShow.left = true;
  } else if (
    activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
    activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
  ) {
    if (!item.due_date) gradientsShow.bottom = true;
    if (!item.start_date) gradientsShow.top = true;
  }

  const titleElements = <span>{title}</span>;

  const onlyOneDateElements = (
    <span style={{ fontWeight: 'bold' }}>
      [
      {onlyStartTime
        ? `${intl.formatMessage({ id: 'start' })} `
        : `${intl.formatMessage({ id: 'deadline' })} `}
      {onlyStartTime || onlyDueTime}h]
    </span>
  );

  const datesElements = <span>{fullTime ? `${fullTime}` : ''} </span>;

  const workspaceElements = <WowTextBold>{workspaceName}</WowTextBold>;

  const weeklyItemEntityInfo = () => {
    if (
      (activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces && workspaceName) ||
      (activeView === CALENDAR_VIEW_TYPES.weeklyUsers && assigneeName)
    ) {
      return (
        <>
          {activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces &&
          workspaceName ? (
            <WowTextBold>{workspaceName}</WowTextBold>
          ) : activeView === CALENDAR_VIEW_TYPES.weeklyUsers && assigneeName ? (
            <WowTextBold>{assigneeName}</WowTextBold>
          ) : (
            ''
          )}
        </>
      );
    }
    return null;
  };

  let layout = <></>;

  if (
    activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
    activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces
  ) {
    layout = (
      <div>
        {!hours && (onlyStartTime || onlyDueTime) ? (
          <SingleRowElement>{onlyOneDateElements}</SingleRowElement>
        ) : (
          ''
        )}
        <SingleRowElement>{titleElements}</SingleRowElement>
        <SingleRowElement>
          {workspaceName && workspaceElements}
        </SingleRowElement>
        {hours ? (
          <SingleRowElement>{onlyDate && datesElements}</SingleRowElement>
        ) : (
          ''
        )}
      </div>
    );
  } else if (
    activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
    activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces
  ) {
    if (hours >= 0.5 || (item.due_date && item.start_date && hours < 0.5)) {
      layout = (
        <div>
          <span>
            <span>{title}</span>
            {' - '}
          </span>
          <span>{fullTime}</span>
          {weeklyItemEntityInfo() ? (
            <span>
              {' - '}
              {weeklyItemEntityInfo()}
            </span>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      layout = (
        <div>
          <span>
            {(onlyStartTime || onlyDueTime) && onlyOneDateElements}
            {' - '}
          </span>
          <span>{title}</span>
          {weeklyItemEntityInfo() ? (
            <span>
              {' - '}
              {weeklyItemEntityInfo()}
            </span>
          ) : (
            ''
          )}
        </div>
      );
    }
  }

  const isOverdue =
    item?.due_date &&
    item.status_id !== 7 &&
    item.status_id !== 8 &&
    new Date(item.due_date * 1000) < new Date()
      ? true
      : false;

  const increaseHeightOnHover =
    !item?.fullDay &&
    (activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
      activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces ||
      activeView === CALENDAR_VIEW_TYPES.dailyUsers ||
      activeView === CALENDAR_VIEW_TYPES.dailyWorkspaces);
  const [hoverHeight, setHoverHeight] = useState(null);
  const handleHoverHeight = () => {
    let result = 0;
    if (increaseHeightOnHover) {
      if (
        wrapperElem?.current?.clientHeight - 10 <
        contentElem?.current?.clientHeight
      ) {
        result = contentElem.current.clientHeight + 10;
      }
    }
    setHoverHeight(result);
  };

  const textColor = isFutureTask && !isOverdue ? darkGray : 'white';

  return (
    <Wrapper
      ref={wrapperElem}
      backgroundColor={isOverdue ? newRed : backgroundColor}
      textColor={textColor}
      showTopGradient={gradientsShow.top}
      showRightGradient={gradientsShow.right}
      showBottomGradient={gradientsShow.bottom}
      showLeftGradient={gradientsShow.left}
      increaseHeightOnHover={increaseHeightOnHover}
      hoverHeight={hoverHeight}
      showBorder={
        (activeView === CALENDAR_VIEW_TYPES.weeklyUsers ||
          activeView === CALENDAR_VIEW_TYPES.weeklyWorkspaces) &&
        !item?.fullDay
      }
      onMouseEnter={handleHoverHeight}
      onMouseLeave={() => setHoverHeight(null)}
    >
      <div ref={contentElem}>{!item?.fullDay && layout}</div>
    </Wrapper>
  );
};

export default IssueCardInfo;
