import { FormattedMessage } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';

import Modal, { SIZE } from '../../../../../components_new/Modal';
import { DEFAULT_PROFILE_IMAGE } from '../../../../../config/constants';
import { CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD } from '../WeeklyTimeline/constants';
import { limitNumberOfCharacters } from '../../../IssuesV2/Issue/components/AddEditWrapper/components/IssueTabs/Notes/utils';
import {
  ModalBody,
  ModalFooter,
  InfoWrapper,
  ModalHeading,
} from '../../../../../components_new/WowModalElements';
import { WowDisplayFlex } from '../../../../../components_new/WowBasicElements';
import Space from '../../../../../components_new/Space';
import Button from '../../../../../components_new/Button';
import {
  ListItemText,
  ListItem,
  ListWrapper,
  PillImage,
  IconBox,
} from './styles';
import { filtersAdvancedGray } from '../../../../../config/colors';
import ActionNoticeBox from '../../../../../components_new/ActionNoticeBox';
import { CALENDAR_VIEW_TYPES } from '../../constants';

const WeeklyLimitSelectBox = (props) => {
  const {
    isOpen,
    handleAction,
    handleClose,
    heading,
    description,
    rows = [],
    isFetchingEventCount,
    activeView,
  } = props;

  const [localState, setLocalState] = useState(rows);
  useEffect(() => {
    setLocalState(rows);
  }, [rows]);

  const handleSelect = (value, index) => {
    setLocalState((prevState) => {
      const mutatedState = JSON.parse(JSON.stringify([...prevState]));
      mutatedState[index].hide = value;
      return mutatedState;
    });
  };

  const { selectedTotalItemsData } = useMemo(() => {
    const data = {
      selectedItems: 0,
      selectedTotalItemsData: 0,
    };

    localState.forEach((item) => {
      if (!item.hide) {
        data.selectedItems++;
        if (item?.data?.total_calendar_events)
          data.selectedTotalItemsData += item.data.total_calendar_events;
      }
    });

    return data;
  }, [localState]);

  const disableAction =
    selectedTotalItemsData > CALENDAR_PREVIEW_DATA_LENGTH_THRESHOLD;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={SIZE.small}>
      <ModalBody>
        <ModalHeading>
          <FormattedMessage id={heading} />
        </ModalHeading>
        <Space height="5" />
        <InfoWrapper>
          {description ? <FormattedMessage id={description} /> : null}
        </InfoWrapper>
        <ListWrapper style={{ maxHeight: disableAction ? null : '245px' }}>
          {localState.map((item, index) =>
            item.hide ||
            !item.data ||
            (!item.data.full_name && !item.data.name) ? (
              ''
            ) : (
              <ListItem
                key={item.id}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <ListItem style={{ alignItems: 'center' }}>
                  {activeView === CALENDAR_VIEW_TYPES.weeklyUsers ? (
                    <PillImage
                      src={item?.data?.profile_image || DEFAULT_PROFILE_IMAGE}
                      alt={item?.data?.full_name ?? item?.data?.name ?? ''}
                    />
                  ) : (
                    <IconBox>
                      <i className="icon icon-workspace" />
                    </IconBox>
                  )}
                  <div>
                    <ListItemText>
                      {limitNumberOfCharacters(
                        item?.data?.full_name ?? item?.data?.name ?? '',
                        25
                      )}
                    </ListItemText>
                    <ListItemText
                      style={{ color: filtersAdvancedGray, fontSize: 12 }}
                    >
                      {isFetchingEventCount
                        ? ''
                        : `${item?.data?.total_calendar_events ?? 0} issues`}
                    </ListItemText>
                  </div>
                </ListItem>
                <div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSelect(true, index)}
                  >
                    <i className="icon icon-close" />
                  </div>
                </div>
              </ListItem>
            )
          )}
        </ListWrapper>
        {!disableAction && (
          <>
            <Space height="20" />
            <ActionNoticeBox
              messageKey="amount_possible"
              showActionComponent={false}
              noticeVariant="info"
              messageColor="gray"
            />
          </>
        )}
        <Space height="20" />
      </ModalBody>
      <ModalFooter>
        <WowDisplayFlex justify="space-around">
          <Button
            type="button"
            size="small"
            onClick={() => handleAction(localState)}
            disabled={disableAction}
          >
            <FormattedMessage id="done" />
          </Button>
          <Button
            type="button"
            color="red"
            size="small"
            appearance="text"
            onClick={handleClose}
          >
            <FormattedMessage id="cancel" />
          </Button>
        </WowDisplayFlex>
      </ModalFooter>
    </Modal>
  );
};

export default WeeklyLimitSelectBox;
