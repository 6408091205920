import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from '../../render-if/render-if.component';
import './slider-item.component.scss';
import { MediaRenderer } from '../media-renderer/media-renderer';
import { AvatarCircle } from '../../avatar-circle/avatar-circle.component';
import { LoadingSpinner } from '../../loading-spinner/loading-spinner.component';
import styled from 'styled-components/macro';
import { white, iconBorder } from '../../../config/colors';

const ListIcon = styled.span`
  border: 2px solid ${iconBorder};
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  i {
    color: ${white};
  }
`;

export const SliderItem = (props) => {
  const handleSelectItem = () => {
    if (props.onSelectItem) {
      props.onSelectItem(props.item);
    }
  };

  const handleShowListView = () => {
    props.showListView && props.showListView(props);
  };

  if (!props.mediaPresent) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <RenderIf if={props.mediaPresent}>
        <div className="slider-item">
          <div className="media" onClick={handleSelectItem}>
            <MediaRenderer
              mimeType={props.mimeType}
              url={props.url}
              description={props.description}
            />
          </div>
          <RenderIf if={props.canResizeItem}>
            <ListIcon onClick={handleShowListView}>
              <i className="icon icon-categories" />
            </ListIcon>
          </RenderIf>
          <RenderIf if={props.showAvatar}>
            <div className="creator-avatar">
              <AvatarCircle
                image={{
                  img: props.user?.profileImageUrl,
                  alt: props.user?.name,
                }}
              />
            </div>
          </RenderIf>
        </div>
      </RenderIf>
    </Fragment>
  );
};

export const sliderItemShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
  mediaPresent: PropTypes.bool,
  mimeType: PropTypes.string.isRequired,
  mediaType: PropTypes.string,
  index: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  description: PropTypes.string,
  showAvatar: PropTypes.bool,
  canResizeItem: PropTypes.bool,
  onSelectItem: PropTypes.func,
  user: PropTypes.shape({
    profileImageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
});

SliderItem.defaultProps = {
  mediaPresent: true,
};

SliderItem.propTypes = PropTypes.shape(sliderItemShape).isRequired;
