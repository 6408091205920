export const LOGS_OPTIONS = [
  {
    id: 1,
    value: 'issue',
    labelKey: 'activity_log',
  },
  {
    id: 2,
    value: 'qr_scanlog',
    labelKey: 'qr_scanlog',
  },
  {
    id: 3,
    value: 'qr_timelog',
    labelKey: 'QR_time_log',
  },
];

export const INIT_PARAMS = {
  page: 1,
  paginate_by: 10,
  workspace_ids: [],
  activity_type: 'issue',
};

export const SUPPORTED_PARAMS = {
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '10',
  },
  workspace_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  activity_type: {
    defaultValue: 'issue',
    allowedValues: ['issue', 'qr_scanlog', 'qr_timelog'],
  },
};

export const FEATURE_KEY = 'profile';

export const PRINT_PAGE_STYLES = `
  @page { size: auto; } @page { margin: 75px 50px 100px 50px; } @media print { body { -webkit-print-color-adjust: exact; } }
`;

export const PRINT_PAGE_STYLES_ACTIVITY = `@page { margin-bottom: 175px; }`;
export const PRINT_PAGE_STYLES_QR_SCANLOG = `@page { margin-bottom: 115px; }`;
export const PRINT_PAGE_STYLES_QR_TIMELOG = `@page { margin-bottom: 50px; }`;
