import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOnboardingProgress } from '../../../../../../../redux/config/selectors';
import useOnboarding from '../../../hooks/useOnboarding';
import { STEP_TYPES } from '../../../constants';

const useNumberOfEmployess = () => {
  const onboardingProgress = useSelector(getOnboardingProgress);

  const formatedOptions = useMemo(() => {
    const options = onboardingProgress?.numberOfEmployees?.options ?? [];

    return options.map((value, index) => ({ id: index, label: value, value }));
  }, [onboardingProgress?.numberOfEmployees?.options]);

  const [selected, setSelected] = useState();

  //init selected value
  useEffect(() => {
    if (formatedOptions?.[0]) setSelected(formatedOptions[0].value);
  }, [formatedOptions]);

  const handleChange = (option) => {
    const { value } = option;
    setSelected(value);
  };

  const { handleUpdateSteps, isLoading } = useOnboarding();

  const handleContinue = () => {
    handleUpdateSteps({
      stepType: STEP_TYPES.numberOfEmployees,
      numberOfEmployees: selected,
    });
  };

  const handleSkip = () => {
    handleUpdateSteps({
      stepType: STEP_TYPES.numberOfEmployees,
      isSkipped: true,
    });
  };

  return {
    formatedOptions,
    selected,
    handleChange,
    isLoading,
    handleContinue,
    handleSkip,
  };
};

export default useNumberOfEmployess;
