import styled from 'styled-components/macro';
import { darkerGray, gradientBlue, newBorder } from '../../config/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Icon = styled.i.attrs(({ icon }) => ({
  className: `icon ${icon}`,
}))`
  color: ${newBorder};
  font-size: 60px;
  margin-bottom: 24px;
`;

export const Title = styled.h2`
  color: ${darkerGray};
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
`;

export const Text = styled.p`
  color: ${darkerGray};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  max-width: 350px;
  margin: 0;
  margin-bottom: 24px;
`;

export const Button = styled.button`
  background-color: ${gradientBlue};
  color: white;
  font-size: 14px;
  font-weight: 400;
  border-radius: 14px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ButtonIcon = styled.i.attrs({ className: 'icon icon-plus' })`
  font-size: 10px;
  margin-right: 6px;
`;
