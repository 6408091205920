import { FormattedMessage, useIntl } from 'react-intl';
import Space from '../../../../../../components_new/Space';
import {
  CtaWrapper,
  Footer,
  InputWrapper,
  ProgressBar,
  SectionLeft,
  SectionRight,
  Sections,
  SubTitle,
  Title,
} from '../../styles';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  ASSIGNEE_EMAIL,
  FORM_DEFAULT_VALUES,
  MYSELF,
  ROLE,
  ROLES,
} from './constants';
import { vestResolver } from '@hookform/resolvers/vest';
import { formValidation } from './utils';
import useAssignIssue from './hooks/useAssignIssue';
import { Dropdown } from '../../../../../../components_new/Dropdown';
import {
  WowColorizeText,
  WowDisplayFlex,
  WowLabelText,
  WowLink,
} from '../../../../../../components_new/WowBasicElements';
import Input from '../../../../../../components_new/Input';
import { INPUT_COMMON_CONFIG } from '../../constants';
import Button from '../../../../../../components_new/Button';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';
import { darkGray, userPillGray } from '../../../../../../config/colors';
import RightSectionInputPreview from '../RightSectionInputPreview';
import {
  AssigneeAvatar,
  AssigneeLabel,
  AssigneeWrapper,
  EmailWrapper,
} from './styles';
import { useMemo } from 'react';

const AssignIssue = () => {
  const intl = useIntl();

  const formMethods = useForm({
    resolver: vestResolver(formValidation),
    defaultValues: FORM_DEFAULT_VALUES,
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, formState, register, control, watch } = formMethods;
  const { isValid, errors } = formState;

  const {
    isLoading,
    handleContinue,
    handleSkip,
    assigneEmail,
    formattedInitials,
    firstSteps,
  } = useAssignIssue({ formMethods });

  const isCustomAssigneeEmailError = errors[ASSIGNEE_EMAIL]?.type === 'custom';
  const role = watch(ROLE);
  const isRoleMyself = role === MYSELF;

  const formattedRoles = useMemo(() => {
    return ROLES.map((item) => ({
      ...item,
      label: intl.formatMessage({ id: item.label.toLowerCase() ?? '' }),
    }));
  }, [intl]);

  return (
    <Sections>
      <SectionLeft maxWidth="400">
        <ProgressBar width="92%" />
        <Space height="25" />
        <Title>
          <FormattedMessage id="congratulations_created_first_task" />
        </Title>
        <SubTitle>
          <FormattedMessage id="who_is_working_with_you" />
        </SubTitle>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleContinue)}>
            <InputWrapper>
              <WowLabelText bold>
                <FormattedMessage id="select_role" />
              </WowLabelText>

              <Controller
                control={control}
                name={ROLE}
                render={({ field }) => {
                  return (
                    <Dropdown
                      {...field}
                      options={formattedRoles}
                      borderRadius="4px"
                      onChange={(value) => field.onChange(value.value)}
                    />
                  );
                }}
              />
            </InputWrapper>
            {isRoleMyself ? null : (
              <>
                <Space height="30" />
                <EmailWrapper align="flex-start" direction="column">
                  <WowLabelText bold>
                    <FormattedMessage id="email_address" />
                  </WowLabelText>
                  <Input
                    {...register(ASSIGNEE_EMAIL)}
                    {...INPUT_COMMON_CONFIG}
                    placeholder={intl.formatMessage({ id: 'email' })}
                    error={
                      isCustomAssigneeEmailError ? errors[ASSIGNEE_EMAIL] : null
                    }
                    errorKey={
                      isCustomAssigneeEmailError
                        ? errors[ASSIGNEE_EMAIL]?.message
                        : null
                    }
                  />
                </EmailWrapper>
              </>
            )}
            <Space height="30" />
            <Footer>
              <CtaWrapper data-testid="ctaWrapper">
                <Button
                  size="small"
                  color="blue"
                  disabled={!isValid || isLoading}
                >
                  <FormattedMessage id="finish" />
                </Button>
                {isLoading ? <LoadingSpinner small /> : null}
              </CtaWrapper>
              <Space width="25" />
              <WowLink color={userPillGray} size="13" onClick={handleSkip}>
                <FormattedMessage id="skip_for_now" />
              </WowLink>
            </Footer>
          </form>
        </FormProvider>
      </SectionLeft>
      <SectionRight data-testid="sectionRight">
        <RightSectionInputPreview
          label={`${intl.formatMessage({ id: 'no_' })} ${
            firstSteps?.workspaceId
          }`}
          textSize="30"
          text={firstSteps?.workspaceName}
        />
        <Space height="30" />
        <RightSectionInputPreview
          label={intl.formatMessage({ id: 'issue' })}
          textSize="20"
          text={firstSteps?.issueTitle}
        />
        {isRoleMyself ? null : (
          <>
            <Space height="30" />
            <WowDisplayFlex direction="column" align="flex-start">
              <AssigneeLabel>
                <FormattedMessage id="assignee" />
              </AssigneeLabel>
              {assigneEmail?.length ? (
                <AssigneeWrapper>
                  <AssigneeAvatar>{formattedInitials}</AssigneeAvatar>
                  <WowColorizeText size="15" color={darkGray}>
                    {assigneEmail}
                  </WowColorizeText>
                </AssigneeWrapper>
              ) : null}
            </WowDisplayFlex>
          </>
        )}
      </SectionRight>
    </Sections>
  );
};

export default AssignIssue;
